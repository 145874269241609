import * as React from 'react';
import {
  TitlesFirstGrid,
  CardConfig,
  TextContent,
  ContainerContent,
  ContainerContentFinal,
  ButtonWrapper,
  ContainerContentFlex,
  ContainerContentText,
  TextContentFlex,
  ContainerContentTextArea,
} from './styles';
import Switch from 'react-switch';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import Button from 'components/atoms/Button';
import SimpleCheckBox from 'components/atoms/SimpleCheckBox';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Input } from 'reactstrap';
import {
  DeleteMyUserThunk,
  GetDataConfigPermissionThunk,
  PatchDataConfigPermissionThunk,
} from 'actions/Thunks/ConfigSubscribeThunk';
import toast from 'react-hot-toast';
import { AuthNavigations } from 'constants/NavigationUrls';
import { cleanState } from 'states/slices/clean.slice';
import { useHistory } from 'react-router-dom';
import { SignOut } from 'actions/ApiClient/AuthActions';

interface PropsDataConfig {
  plan: string;
  users: number;
}

const SusbcriptionForm = ({ plan, users }: PropsDataConfig): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [handleDelete, setHandleDelete] = React.useState<boolean>(false);
  const [viewPath, setViewPath] = React.useState<boolean>(false);
  const [viewCourse, setViewCourse] = React.useState<boolean>(false);
  const [downloadPdf, setDownloadPdf] = React.useState<boolean>(false);
  const [id, setId] = React.useState<number>(0);
  const [valueCheck, setValueCheck] = React.useState<number>(0);
  const [descriptionValue, setDescriptionValue] = React.useState('');

  const user = useAppSelector((state) => state.user);

  const getData = React.useCallback(async () => {
    if (user.userInfo !== null) {
      const dataPermission = await dispatch(
        GetDataConfigPermissionThunk(user.userInfo?.organization.id)
      );
      if (GetDataConfigPermissionThunk.rejected.match(dataPermission)) {
        toast.error(dataPermission.payload as string);
      } else {
        setViewPath(dataPermission.payload.accredited.view_path_ext_org);
        setViewCourse(dataPermission.payload.accredited.view_course_ext_org);
        setDownloadPdf(dataPermission.payload.accredited.download_badge_certificate_pdf);
        setId(user.userInfo?.organization.id);
      }
    }
  }, [user.userInfo, dispatch]);

  const handlePermissions = React.useCallback(
    async (dataProps: IDataConfigPermissionPatchFormat) => {
      if (user.userInfo !== null) {
        const dataPermission = await dispatch(PatchDataConfigPermissionThunk(dataProps));
        if (GetDataConfigPermissionThunk.rejected.match(dataPermission)) {
          toast.error(dataPermission.payload as string);
        }
      }
    },
    [user.userInfo, dispatch]
  );

  const deleteUser = React.useCallback(
    async (dataProps: IDeleteMyUser) => {
      const dataPermission = await dispatch(DeleteMyUserThunk(dataProps));
      if (DeleteMyUserThunk.rejected.match(dataPermission)) {
        toast.error(dataPermission.payload as string);
      } else {
        toast.success('La cuenta fue eliminada correctamente.');
        await dispatch(cleanState());
        await SignOut();
        history.push(AuthNavigations.login);
      }
    },
    [dispatch, history]
  );

  const handleViewPath = (is_public: boolean) => {
    if (user.userInfo !== null) {
      setViewPath(is_public);
      let dataProps: IDataConfigPermissionPatchFormat = {
        id: id,
        data: {
          accredited: {
            view_path_ext_org: is_public,
            view_course_ext_org: viewCourse,
            download_badge_certificate_pdf: downloadPdf,
          },
        },
      };
      handlePermissions(dataProps);
    }
  };

  const handleViewCourse = (is_public: boolean) => {
    if (user.userInfo !== null) {
      setViewCourse(is_public);
      let dataProps: IDataConfigPermissionPatchFormat = {
        id: id,
        data: {
          accredited: {
            view_path_ext_org: viewPath,
            view_course_ext_org: is_public,
            download_badge_certificate_pdf: downloadPdf,
          },
        },
      };
      handlePermissions(dataProps);
    }
  };

  const handleDownloadPdf = (is_public: boolean) => {
    if (user.userInfo !== null) {
      setDownloadPdf(is_public);
      let dataProps: IDataConfigPermissionPatchFormat = {
        id: id,
        data: {
          accredited: {
            view_path_ext_org: viewPath,
            view_course_ext_org: viewCourse,
            download_badge_certificate_pdf: is_public,
          },
        },
      };
      handlePermissions(dataProps);
    }
  };

  const handleDeleteUser = () => {
    let infodeleteUser = {
      description: {
        id: valueCheck,
        name: descriptionValue,
      },
    };
    deleteUser(infodeleteUser);
  };

  const handleDescription = (event: any) => {
    setDescriptionValue(event.target.value);
  };

  const setValues = (id: number, description: string) => {
    setValueCheck(id);
    setDescriptionValue(description);
  };

  React.useEffect(() => {
    getData();
  }, [getData]);
  return (
    <React.Fragment>
      {handleDelete === false ? (
        <CardConfig>
          <ContainerContent>
            <TitlesFirstGrid>Configuración de contenido</TitlesFirstGrid>
          </ContainerContent>
          <ContainerContentFlex>
            <TextContent>
              Permitir que los acreditados vean recursos de aprendizaje externos a mi organización
              en el catálogo.
            </TextContent>
            <div style={{ marginRight: '56px' }}>
              <Switch
                onChange={() => handleViewPath(!viewPath)}
                checked={viewPath}
                onColor={PRIMARY_COLOR}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={38}
              />
            </div>
          </ContainerContentFlex>
          <ContainerContentFlex>
            <TextContent>
              Permitir sugerencias de rutas de aprendizaje externas a mi organización en el catálogo
              para los acreditados
            </TextContent>
            <div style={{ marginRight: '56px' }}>
              <Switch
                onChange={() => handleViewCourse(!viewCourse)}
                checked={viewCourse}
                onColor={PRIMARY_COLOR}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={38}
              />
            </div>
          </ContainerContentFlex>
          <ContainerContentFlex>
            <TextContent>
              Permitir que los acreditados descarguen e impriman el certificado en PDF de sus
              insignias obtenidas.
            </TextContent>
            <div style={{ marginRight: '56px' }}>
              <Switch
                onChange={() => handleDownloadPdf(!downloadPdf)}
                checked={downloadPdf}
                onColor={PRIMARY_COLOR}
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={38}
              />
            </div>
          </ContainerContentFlex>
          <ContainerContentFlex>
            <TitlesFirstGrid>Opciones de la cuenta</TitlesFirstGrid>
          </ContainerContentFlex>
          <ContainerContentFlex>
            <TextContent>
              ¿Tienes problemas con tu cuenta? Contáctate con soporte para resolver tus dudas.
            </TextContent>
            <ButtonWrapper>
              <a href="mailto:soporte@acreditta.com?subject=Solicito%20ayuda%20con%20la%20plataforma&body=Hola%20Acreditta%2C%20tengo%20problemas%20con%20la%20plataforma%20y%20solicito%20soporte%20t%C3%A9cnico.">
                <Button label={'Soporte'} lightMode={true} widthCustom={150} />
              </a>
            </ButtonWrapper>
          </ContainerContentFlex>
          <ContainerContentFlex>
            <TextContent>
              ¿Quieres mejorar tu plan? Conoce nuestros precios y adquiere el plan que se ajuste a
              tus necesidades.
            </TextContent>
            <ButtonWrapper>
              <Button
                label={'Ver planes'}
                lightMode={true}
                widthCustom={150}
                onClick={() => window.open('https://acreditta.com/precios/', '_blank')}
              />
            </ButtonWrapper>
          </ContainerContentFlex>
          <ContainerContentFinal>
            <TextContent>
              ¿Deseas eliminar tu cuenta? No podrás recuperar tu información ya que esta acción es
              permanente e irreversible.
            </TextContent>
            <ButtonWrapper>
              <Button
                label={'Eliminar'}
                widthCustom={150}
                onClick={() => setHandleDelete(!handleDelete)}
              />
            </ButtonWrapper>
          </ContainerContentFinal>
        </CardConfig>
      ) : (
        <CardConfig>
          <ContainerContent>
            <TitlesFirstGrid>¿Deseas eliminar tu cuenta?</TitlesFirstGrid>
          </ContainerContent>
          <ContainerContentText>
            <TextContent>
              Actualmente tienes una cuenta de administrador con un plan ”<strong>{plan}</strong>” y
              <strong> tienes {users} usuarios activos.</strong>
            </TextContent>
            <TextContent>
              Al eliminar la cuenta de Acreditta tendrás <strong>30 días</strong> para deshacer esta
              acción iniciando sesión nuevamente con tus datos. Ten en cuenta que los usuarios
              cargados seguirán teniendo acceso a sus insignias obtenidas.
            </TextContent>
          </ContainerContentText>
          <ContainerContent>
            <TitlesFirstGrid>¿Por qué eliminas tu cuenta?</TitlesFirstGrid>
          </ContainerContent>
          <ContainerContent>
            <SimpleCheckBox
              widthCustom={15}
              type={'radio'}
              handleCheckElement={() => setValues(1, 'No utilizo la plataforma.')}
              isChecked={valueCheck === 1}
              onChange={() => setValues(1, 'No utilizo la plataforma.')}
            />
            <TextContentFlex>No utilizo la plataforma.</TextContentFlex>
          </ContainerContent>
          <ContainerContent>
            <SimpleCheckBox
              widthCustom={15}
              type={'radio'}
              handleCheckElement={() => setValues(2, 'No comprendo para qué sirve la plataforma.')}
              onChange={() => setValues(2, 'No comprendo para qué sirve la plataforma.')}
              isChecked={valueCheck === 2}
            />
            <TextContentFlex>No comprendo para qué sirve la plataforma.</TextContentFlex>
          </ContainerContent>
          <ContainerContent>
            <SimpleCheckBox
              widthCustom={15}
              type={'radio'}
              handleCheckElement={() => setValues(3, 'Recibo muchos correos innecesarios.')}
              isChecked={valueCheck === 3}
              onChange={() => setValues(3, 'Recibo muchos correos innecesarios.')}
            />
            <TextContentFlex>Recibo muchos correos innecesarios.</TextContentFlex>
          </ContainerContent>
          <ContainerContent>
            <SimpleCheckBox
              widthCustom={15}
              type={'radio'}
              handleCheckElement={() =>
                setValues(4, 'No estoy de acuerdo con el tratamiento de datos.')
              }
              isChecked={valueCheck === 4}
              onChange={() => setValues(4, 'No estoy de acuerdo con el tratamiento de datos.')}
            />
            <TextContentFlex>No estoy de acuerdo con el tratamiento de datos.</TextContentFlex>
          </ContainerContent>
          <ContainerContentTextArea>
            <SimpleCheckBox
              widthCustom={15}
              type={'radio'}
              handleCheckElement={() => setValues(5, '')}
              isChecked={valueCheck === 5}
              onChange={() => setValues(5, '')}
            />
            <TextContentFlex>Otra</TextContentFlex>
          </ContainerContentTextArea>
          {valueCheck === 5 && (
            <div>
              <Input
                name="text"
                type="textarea"
                value={descriptionValue}
                onChange={handleDescription}
              />
            </div>
          )}
          <ContainerContentFinal>
            <TextContent>¿Estás seguro que quieres eliminar tu cuenta?</TextContent>
            <ButtonWrapper>
              <div style={{ marginRight: '25px' }}>
                <Button
                  label={'Cancelar'}
                  lightMode={true}
                  widthCustom={150}
                  onClick={() => setHandleDelete(!handleDelete)}
                />
              </div>
              <Button label={'Eliminar'} widthCustom={150} onClick={() => handleDeleteUser()} />
            </ButtonWrapper>
          </ContainerContentFinal>
        </CardConfig>
      )}
    </React.Fragment>
  );
};

export default SusbcriptionForm;
