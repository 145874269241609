import * as React from 'react';
import { Input, InputProps, Label } from 'reactstrap';

interface InputRadioOptionProps extends InputProps {
  label: string;
}

const InputRadioOption: React.FC<InputRadioOptionProps> = ({ onChange, value, label }) => {
  return (
    <Label check>
      <Input type="radio" name="typeDocument" value={value} onChange={onChange} />
      {label}
    </Label>
  );
};

export default InputRadioOption;
