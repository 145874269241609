import * as React from 'react';
import Button from 'components/atoms/Button';
import {
  Control,
  Controller,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { emailRegex } from 'utils';
import {
  FormContainer,
  ButtonContainer,
  WarningText,
  FormGroupStyled,
  FormInputWrapper,
  WarningHeaderWrapper,
  WarningMemoLabel,
} from './styles';
import TextInput from 'components/atoms/TextInput';

interface NewFormProps {
  controlInfo: Control<INewInfoForm>;
  handleSubmitInfoForm: UseFormHandleSubmit<INewInfoForm>;
  submitNewOrganizationForm: (formInfo: INewInfoForm) => void;
  errorsInfo: Record<string, any>;
  loading: boolean;
  watch: UseFormWatch<INewInfoForm>;
  setValue: UseFormSetValue<INewInfoForm>;
}

const NewOrganizationForm: React.FC<NewFormProps> = ({
  controlInfo,
  handleSubmitInfoForm,
  errorsInfo,
  submitNewOrganizationForm,
  loading,
}) => {
  return (
    <FormContainer>
      <WarningHeaderWrapper>
        <WarningMemoLabel>
          Ingresa tus datos acontinuación para conectar diferentes oportunidades de aprendizaje y
          desarrollo de habilidades.
        </WarningMemoLabel>
        <WarningText>Tus datos personales</WarningText>
      </WarningHeaderWrapper>
      <FormGroupStyled row>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="first_name"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={50}
                withError
                label="Nombre"
              />
            )}
            name="first_name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="last_name"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={50}
                withError
                label="Apellido"
              />
            )}
            name="last_name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="email"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={50}
                withError
                label="Correo Adicional"
              />
            )}
            name="contact_email"
            rules={{
              pattern: {
                value: emailRegex,
                message: 'Email inválido',
              },
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <ButtonContainer>
        <Button
          onClick={handleSubmitInfoForm(submitNewOrganizationForm)}
          label={'Siguiente'}
          loading={loading}
          disabled={false}
        ></Button>
      </ButtonContainer>
    </FormContainer>
  );
};

export default NewOrganizationForm;
