import styles from './styles.module.css';

type PropsT = {
  text: string;
  htmlFor?: string;
};

const AcredittaFormLabel = ({ text, htmlFor }: PropsT) => {
  return (
    <label htmlFor={htmlFor || text.toLocaleLowerCase()} className={styles.myCustomLabel}>
      {text}
    </label>
  );
};

export default AcredittaFormLabel;
