import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InterestListThunk,
  SkillListEleThunk,
  SkillListThunk,
} from 'actions/Thunks/OnboardingThunk';

const ONBOARDING = 'ONBOARDING';

const initialState: IOnboarding = {
  introSeen: {
    first: false,
    second: false,
    third: false,
  },
  interests: null,
  skills: null,
  skillsList: null,
  interestSelected: [],
  skillsValidity: {
    disabled: false,
    message: 'Se deben seleccionar por lo menos 3 habilidades',
  },
};

const onboardingSlice = createSlice({
  name: ONBOARDING,
  initialState,
  reducers: {
    setSeenFirst(state) {
      return { ...state, introSeen: { ...state.introSeen, first: true } };
    },
    setSeenSecond(state) {
      return { ...state, introSeen: { ...state.introSeen, second: true } };
    },
    setSeenThird(state) {
      return { ...state, introSeen: { ...state.introSeen, third: true } };
    },
    setInsertNewInterest(state, action: PayloadAction<{ item: IInterestSelected; index: number }>) {
      let newArray = state.interestSelected.slice();
      newArray.splice(action.payload.index, 0, action.payload.item);
      return { ...state, interestSelected: newArray };
    },
    setInsertNewSkill(
      state,
      action: PayloadAction<{ item: number; index: number; interestId: number }>
    ) {
      const findInterest = state.interestSelected.find(
        (interest) => interest.id === action.payload.interestId
      );

      if (findInterest) {
        let newArray = findInterest.skills.slice();
        newArray.splice(action.payload.index, 0, action.payload.item);

        let updatedValidation = state.skillsValidity;
        const selectionSize = newArray.length;

        updatedValidation = {
          disabled: false,
          message: `Se han seleccionado ${selectionSize} habilidades de 1`,
        };

        const updateInterest = state.interestSelected.map((interest) => {
          if (interest.id !== action.payload.interestId) {
            return interest;
          }

          return {
            ...interest,
            skills: newArray,
          };
        });

        return { ...state, interestSelected: updateInterest, skillsValidity: updatedValidation };
      }
    },
    setRemoveSkill(state, action: PayloadAction<{ item: number; interestId: number }>) {
      const findInterest = state.interestSelected.find(
        (interest) => interest.id === action.payload.interestId
      );

      if (findInterest) {
        const indexSkillSelected = findInterest.skills.findIndex(
          (skill) => skill === action.payload.item
        );

        let newArray = findInterest.skills.slice();
        newArray.splice(indexSkillSelected, 1);

        let updatedValidation = state.skillsValidity;
        const selectionSize = newArray.length;

        updatedValidation = {
          disabled: false,
          message: `Se han seleccionado ${selectionSize} habilidades de 1`,
        };

        if (newArray.length !== 0) {
          const updateInterest = state.interestSelected.map((interest) => {
            if (interest.id !== action.payload.interestId) {
              return interest;
            }

            return {
              ...interest,
              skills: newArray,
            };
          });
          return { ...state, interestSelected: updateInterest, skillsValidity: updatedValidation };
        } else {
          const indexInterestSelected = state.interestSelected.findIndex(
            (interest) => interest.id === action.payload.interestId
          );

          let arrayInterestToSplice = state.interestSelected.slice();
          arrayInterestToSplice.splice(indexInterestSelected, 1);

          return {
            ...state,
            interestSelected: arrayInterestToSplice,
            skillsValidity: updatedValidation,
          };
        }
      }
    },
    setCleanSkills(state) {
      return { ...state, skills: null };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SkillListEleThunk.fulfilled, (state, { payload }) => {
      state.skillsList = payload;
    });
    builder.addCase(InterestListThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.interests = payload.results;
      }
    });
    builder.addCase(SkillListThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.skills = payload.map((skill: any) => ({ ...skill, isChecked: false }));
      }
    });
  },
});

export const {
  setSeenFirst,
  setSeenSecond,
  setSeenThird,
  setInsertNewInterest,
  setInsertNewSkill,
  setRemoveSkill,
  setCleanSkills,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
