import * as React from 'react';
import { LabelCheck } from './styles';

interface ISimpleCheckBoxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  handleCheckElement?: React.ChangeEventHandler<HTMLInputElement>;
  isChecked?: boolean;
  widthCustom?: number;
  type?: string;
  name?: string;
}

const SimpleCheckBox: React.FC<ISimpleCheckBoxProps> = ({
  value,
  handleCheckElement,
  isChecked,
  disabled,
  widthCustom,
  type,
  name,
  onClick
}) => {
  return (
    <div style={{ width: `${widthCustom}px` }}>
      <input
        onChange={handleCheckElement}
        disabled={disabled}
        type={type}
        checked={isChecked}
        value={value}
        width={widthCustom}
        name={name}
        onClick={onClick}
      />
      <LabelCheck>{value}</LabelCheck>
    </div>
  );
};

export default SimpleCheckBox;
