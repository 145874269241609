import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangeNumber,
  GetAdminData,
  GetUserData,
  GetValidatePlanPaths,
  PatchArchivedBadge,
} from 'actions/ApiClient/UserActions';
import { GetErrorMessage } from 'utils';

export const UserDataThunk = createAsyncThunk('user/info', async (_, { rejectWithValue }) => {
  try {
    const user = await GetUserData();
    return user;
  } catch (error) {
    return rejectWithValue(GetErrorMessage(error));
  }
});

export const AdminDataThunk = createAsyncThunk('admin/info', async (_, { rejectWithValue }) => {
  try {
    const admin = await GetAdminData();
    return admin;
  } catch (error) {
    return rejectWithValue(GetErrorMessage(error));
  }
});

export const GetValidatePlanPathsThunk = createAsyncThunk(
  'admin/plan',
  async (path: string, { rejectWithValue }) => {
    try {
      const plan = await GetValidatePlanPaths(path);
      return plan;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ChangeNumberThunk = createAsyncThunk(
  'admin/change-number',
  async (newNumber: any, { rejectWithValue }) => {
    try {
      const number = await ChangeNumber(newNumber);
      return number;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const PatchArchivedBadgeThunk = createAsyncThunk(
  'admin/archived-badge',
  async (data: IArchivedBadge, { rejectWithValue }) => {
    try {
      const badge = await PatchArchivedBadge(data);
      return badge;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
