import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaFormLabel from 'components/atoms/AcredittaFormLabel';
import { Field, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import UploadSignature from './UploadSignature';
import { manageErrors } from 'helpers/manage-error';
import ShowTemplates from './ShowTemplates';
import {
  storeOrganizationTemplateCertificates,
  updateOrganizationTemplateCertificates,
} from 'actions/ApiClient/certificates-pdf';
import toast from 'react-hot-toast';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import useGetCertficateTemplate from 'hooks/useGetCertficateTemplate';
import HeaderCertificates from '../components/HeaderCertificates';
import CertificateInfoText from '../components/CertificateInfoText';

interface InitialValuesI {
  footerPage: string;
}

const CertificatesOrganism = () => {
  const [initialValues, setInitialValues] = useState<InitialValuesI>({
    footerPage: '',
  });
  const [submiting, setSubmiting] = useState(false);
  const [templateSelected, setTemplateSelected] = useState<number>(0);
  const { isFetching, currentTemplate } = useGetCertficateTemplate();
  const [signatureOneValidation, setSignatureOneValidation] = useState(false);
  const [signatureTwoValidation, setSignatureTwoValidation] = useState(false);
  const [signatureOne, setSignatureOne] = useState(0);
  const [signatureTwo, setSignatureTwo] = useState(0);

  const [template, setTemplate] = useState<any>(null);

  useEffect(() => {
    if (currentTemplate.signature && currentTemplate.signature.length > 0) {
      if (currentTemplate.signature[0] !== null) {
        setSignatureOne(currentTemplate.signature[0].id);
      }

      if (currentTemplate.signature[1] !== null) {
        setSignatureTwo(currentTemplate.signature[1].id);
      }
    }
    setInitialValues((prev) => ({ ...prev, footerPage: currentTemplate.footer }));
  }, [currentTemplate]);

  const handleFinishUploadSignature = (value: number, position: number) => {
    if (position === 1) {
      setSignatureOne(value);
    } else {
      setSignatureTwo(value);
    }
  };

  const handleSubmitForm = async (values: InitialValuesI) => {
    try {
      setSubmiting(true);

      const payload = {
        footer: values.footerPage,
        signature: [signatureOne, signatureTwo],
        template: templateSelected,
      };

      if (currentTemplate.uuid !== null) {
        // editar
        await updateOrganizationTemplateCertificates(currentTemplate.uuid, payload);
      } else {
        // guardar
        await storeOrganizationTemplateCertificates(payload);
      }

      toast.success('La información se ha guardado correctamente');
      setSubmiting(false);
    } catch (e: any) {
      setSubmiting(false);
      manageErrors(e, e.response?.message);
    }
  };

  const handleImageClick = useCallback((value, template) => {
    setTemplateSelected(value);
    setTemplate(template);
  }, []);

  const validIsSignatureExist = (): boolean => {
    if (signatureOneValidation || signatureTwoValidation) {
      return true;
    }

    return false;
  };

  return (
    <>
      <HeaderCertificates />
      {isFetching ? (
        <AcredittaLoader />
      ) : (
        <>
          <ShowTemplates
            handleClick={handleImageClick}
            selectAsDefault={(value) => setTemplateSelected(value)}
            initialSelected={currentTemplate.template}
          />

          <div className="row">
            <div className="col">
              <AcredittaBox>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    handleSubmitForm(values);
                  }}
                >
                  {({ handleChange, handleBlur, values, isValid, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      {template?.json === null ? (
                        <>
                          <div className="row mb-3">
                            <div className="col-xs-12 col-sm-3 col-md-3">
                              <AcredittaFormLabel text="Firma 1" />
                              <div className="row">
                                <div className="col"></div>
                              </div>
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-9">
                              <UploadSignature
                                onFinish={handleFinishUploadSignature}
                                isInvalid={(value) => setSignatureOneValidation(value)}
                                position={1}
                                signature={
                                  currentTemplate.signature && currentTemplate.signature.length > 0
                                    ? currentTemplate.signature[0]
                                    : {}
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-xs-12 col-sm-3 col-md-3">
                              <AcredittaFormLabel text="Firma 2" />
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-9">
                              <UploadSignature
                                onFinish={handleFinishUploadSignature}
                                isInvalid={(value) => setSignatureTwoValidation(value)}
                                position={2}
                                signature={
                                  currentTemplate.signature && currentTemplate.signature.length > 0
                                    ? currentTemplate.signature[1]
                                    : {}
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-xs-12 col-sm-3 col-md-3">
                              <AcredittaFormLabel text="Pie de página" />
                            </div>
                            <div className="col-xs-12 col-sm-9 col-md-9">
                              <Field
                                as="textarea"
                                className="form-control"
                                name="footerPage"
                                value={values.footerPage}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{ height: '80px', resize: 'none' }}
                                placeholder="Agregar la información relevante para el certificado"
                              />
                            </div>
                          </div>

                          <CertificateInfoText />

                          <div className="mb-3 text-center">
                            <AcredittaButton
                              title="Guardar cambios"
                              type="submit"
                              isLoading={submiting}
                              disabled={
                                submiting ||
                                !isValid ||
                                signatureOne === 0 ||
                                signatureTwo === 0 ||
                                templateSelected <= 0 ||
                                validIsSignatureExist()
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className="text-center">
                          <AcredittaButton
                            title="Guardar cambios"
                            type="submit"
                            isLoading={submiting}
                            disabled={submiting || !isValid || templateSelected <= 0}
                          />
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              </AcredittaBox>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CertificatesOrganism;
