import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { ColStyled, ContainerNoData } from './styles';
import { Container, Row } from 'reactstrap';
import Badge from 'components/atoms/Badge';
import { GetOrganizationBadgesThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import NoDataAcre from 'components/molecules/NoDataAcre';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import { NonAuthenticatedUrls } from 'constants/NavigationUrls';

const AcreditedBadgesDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.userInfo);
  const admin = useAppSelector((state) => state.admin);

  const [urlBadge, setUrlBadge] = React.useState('');

  const getBadgesList = React.useCallback(async () => {
    const badges = await dispatch(GetOrganizationBadgesThunk(user?.cognito_id));
    if (GetOrganizationBadgesThunk.rejected.match(badges)) {
      toast(badges.payload as string);
    } else {
      setUrlBadge(`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.url_badges}/`);
    }
  }, [user, dispatch]);

  React.useEffect(() => {
    getBadgesList();
  }, [getBadgesList]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={user?.user_type.code === 'ADMIN' ? false : true}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <Container
        style={{
          marginTop: '100px',
        }}
      >
        {admin.organizationBadges !== null ? (
          admin.organizationBadges.length !== 0 ? (
            <Row>
              {admin.organizationBadges.map((badge: any, index: any) => {
                return (
                  <ColStyled xs="3" key={index}>
                    {badge.badge_template && (
                      <Badge
                        typeBadge={badge.accepted_at === null ? true : false}
                        isPublicBadge={badge.public}
                        id={badge.id}
                        name={badge.badge_template.name}
                        image={badge.badge_template.image.url}
                        company={badge.badge_template.owner.name}
                        date={badge.accepted_at}
                        dateExpired={badge.expires_at}
                        url={urlBadge}
                      />
                    )}
                  </ColStyled>
                );
              })}
            </Row>
          ) : (
            <ContainerNoData
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <NoDataAcre />
            </ContainerNoData>
          )
        ) : (
          <ContainerNoData
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10%',
            }}
          >
            <ReactLoading type="spin" color={'#002057'} height={150} width={150} />
          </ContainerNoData>
        )}
      </Container>
    </CommonPageLayout>
  );
};

export default AcreditedBadgesDashboard;
