import * as React from 'react';
import icons from 'assets/Icon';
import StyledButton from 'components/atoms/Button';
import { AccreditedRole } from 'constants/NavigationUrls';
import { useHistory } from 'react-router';
import { StepWizardProps } from 'react-step-wizard';
import { Container } from 'reactstrap';
import {
  AdminEndStepProcessContentContainer,
  AdminEndStepProcessContentWrapper,
  AdminEndStepProcessImage,
  AdminEndStepProcessImageContainer,
  AdminEndStepProcessRow,
  AdminEndStepProcessTitle,
} from './styles';

interface AdminEndStepProcessProps extends Partial<StepWizardProps> {}

const AdminEndStepProcess = (_: AdminEndStepProcessProps): JSX.Element => {
  const history = useHistory();

  const finishOnBoarding = () => {
    history.push(AccreditedRole.profile);
  };

  return (
    <Container>
      <AdminEndStepProcessRow>
        <AdminEndStepProcessContentContainer md={5} lg={5}>
          <AdminEndStepProcessContentWrapper className="d-flex align-items-center flex-column mb-3">
            <AdminEndStepProcessTitle>
              ¡Felicidades, ganaste tu primera insignia digital!
            </AdminEndStepProcessTitle>
            <br />
            <br />
            <br />
            <StyledButton label="Finalizar" onClick={finishOnBoarding} />
          </AdminEndStepProcessContentWrapper>
        </AdminEndStepProcessContentContainer>
        <AdminEndStepProcessImageContainer md={7} lg={7}>
          <AdminEndStepProcessImage>
            <icons.AcreditedProfileComplete />
          </AdminEndStepProcessImage>
        </AdminEndStepProcessImageContainer>
      </AdminEndStepProcessRow>
    </Container>
  );
};

export default AdminEndStepProcess;
