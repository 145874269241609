import ConfigureOrganizationLayout from 'components/organisms/ConfigureOrganizationProfile';
import ConfigureOrganizationProvider from './Context';

const ConfigurationOrganizationPage = () => {
  return (
    <ConfigureOrganizationProvider>
      <ConfigureOrganizationLayout />
    </ConfigureOrganizationProvider>
  );
};

export default ConfigurationOrganizationPage;
