import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AcredittaText from 'components/atoms/AcredittaText';
import './styles.css';

interface cardI {
  item: any;
}
const CardBadgeCollection = ({ item }: cardI) => {
  return (
    <div className="card-container">
      {item ? (
        <>
          <div className="card-image__url">
            <img src={item.image_url} alt="imagen de insignia" />
          </div>
          <div className="card-name">
            <AcredittaText fontWeight="bold" variant="body1">{item.name}</AcredittaText>
          </div>
        </>
      ) : (
        <AcredittaLoader />
      )}
    </div>
  );
};

export default CardBadgeCollection;
