import * as Sentry from '@sentry/react';
import { AdminUrls, API_URL } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetRecommendedCourse(page: number): Promise<any> {
  try {
    if (page === 0) {
      const recommendedCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.recommendedCourse}`
      );

      if (recommendedCourse.okay && recommendedCourse.data) {
        return recommendedCourse.data;
      } else {
        throw Error(recommendedCourse.message);
      }
    } else {
      const recommendedCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.recommendedCourse}?page=${page}`
      );

      if (recommendedCourse.okay && recommendedCourse.data) {
        return recommendedCourse.data;
      } else {
        throw Error(recommendedCourse.message);
      }
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationCourse(page: number): Promise<any> {
  try {
    if (page === 0) {
      const organizationCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.organizationCourse}`
      );

      if (organizationCourse.okay && organizationCourse.data) {
        return organizationCourse.data;
      } else {
        throw Error(organizationCourse.message);
      }
    } else {
      const organizationCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.organizationCourse}?page=${page}`
      );

      if (organizationCourse.okay && organizationCourse.data) {
        return organizationCourse.data;
      } else {
        throw Error(organizationCourse.message);
      }
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetPopularCourse(page: number): Promise<any> {
  try {
    if (page === 0) {
      const popularCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.popularCourse}`
      );

      if (popularCourse.okay && popularCourse.data) {
        return popularCourse.data;
      } else {
        throw Error(popularCourse.message);
      }
    } else {
      const popularCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.popularCourse}?page=${page}`
      );

      if (popularCourse.okay && popularCourse.data) {
        return popularCourse.data;
      } else {
        throw Error(popularCourse.message);
      }
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetInterestCourse(page: number): Promise<any> {
  try {
    if (page === 0) {
      const interestCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.interestCourse}`
      );

      if (interestCourse.okay && interestCourse.data) {
        return interestCourse.data;
      } else {
        throw Error(interestCourse.message);
      }
    } else {
      const interestCourse = await new HttpRequest().Get<any>(
        `${API_URL}${AdminUrls.interestCourse}?page=${page}`
      );

      if (interestCourse.okay && interestCourse.data) {
        return interestCourse.data;
      } else {
        throw Error(interestCourse.message);
      }
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetRecommendedPaths(): Promise<any> {
  try {
    const RecommendedPaths = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.recommendedPaths}`
    );

    if (RecommendedPaths.okay && RecommendedPaths.data) {
      return RecommendedPaths.data;
    } else {
      throw Error(RecommendedPaths.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetContentOrganization(): Promise<any> {
  try {
    const OrganizationContent = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.organizationContent}`
    );

    if (OrganizationContent.okay && OrganizationContent.data) {
      return OrganizationContent.data;
    } else {
      throw Error(OrganizationContent.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
