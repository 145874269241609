import { createSlice } from '@reduxjs/toolkit';
import {
  InterestCourseThunk,
  OrganizationCourseThunk,
  PopularCourseThunk,
  RecommendedCourseThunk,
} from 'actions/Thunks/AdminCatalogThunk';

const CATALOG = 'CATALOG';

const initialState: any = {
  interestCourseT: null,
  recommendedCourseT: null,
  organizationCourseT: null,
  popularCourseT: null,
};

const catalogCourseSlice = createSlice({
  name: CATALOG,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(InterestCourseThunk.fulfilled, (state, { payload }) => {
      state.interestCourseT = payload;
    });
    builder.addCase(RecommendedCourseThunk.fulfilled, (state, { payload }) => {
      state.recommendedCourseT = payload;
    });
    builder.addCase(OrganizationCourseThunk.fulfilled, (state, { payload }) => {
      state.organizationCourseT = payload;
    });
    builder.addCase(PopularCourseThunk.fulfilled, (state, { payload }) => {
      state.popularCourseT = payload;
    });
  },
});

export default catalogCourseSlice.reducer;
