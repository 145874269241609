import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const ExperiencesListContainer = styled.div`
  display: grid;
  grid-template-columns: 18% 18% 18% 18% 18% 10%;
  margin: 40px 20px;
`;

export const ExperienceWrapper = styled.div<{
  $selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${(props) => (props.$selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
`;

export const ExperienceName = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0px;
`;

export const ExperienceIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
  }
`;

export const ExperiencesContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-around;
`;
