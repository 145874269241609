import { getCertifatesPdfTemplateInfo } from 'actions/ApiClient/certificates-pdf';
import { manageErrors } from 'helpers/manage-error';
import { useEffect, useState } from 'react';

interface SignatureI {
  id: number;
  name: string;
  image: string;
  owner: number;
}

interface CurrentTemplateI {
  uuid: string;
  footer: string;
  is_active: false;
  organization: number;
  template: number;
  signature: SignatureI[];
}

const useGetCertficateTemplate = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<CurrentTemplateI>({
    uuid: '',
    footer: '',
    is_active: false,
    organization: 0,
    template: 0,
    signature: [],
  });

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const response = await getCertifatesPdfTemplateInfo();
        setCurrentTemplate(response.data);
        setIsFetching(false);
      } catch (e: any) {
        setIsFetching(false);
        manageErrors(e, e.response?.message, { showToast: false });
      }
    })();
  }, []);

  return { isFetching, currentTemplate };
};

export default useGetCertficateTemplate;
