import { SECONDARY_COLOR } from 'assets/styles/colors';
import styled from 'styled-components';

export const ShowHideWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const ShowHideInputWrapper = styled.div`
  width: 100%;
`;

export const ShowHideIconWrapper = styled.i`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 53%;
  right: 6%;
`;

export const ErrorMessageLabel = styled.span`
  color: ${SECONDARY_COLOR};
  font-size: 12px;
`;