import { AccreditedRole } from 'constants/NavigationUrls';
import { MenuI } from '../interfaces/menu';

export const ACREDITTED_MENU: MenuI[] = [
  { id: 1, text: 'Inicio', path: '/' },
  {
    id: 2,
    text: 'Insignias',
    path: AccreditedRole.badgesDashboard,
  },
  {
    id: 3,
    text: 'Rutas',
    path: '/',
    subItems: [
      {
        id: 1,
        text: 'Crear ruta',
        path: AccreditedRole.newPathWizz,
      },
      {
        id: 2,
        text: 'Cátalogo de cursos',
        path: AccreditedRole.catalogCourse,
      },
    ],
  },
];
