import * as React from 'react';
import CommonPageLayout from '../../../components/organisms/Layout/CommonPageLayout';
import { TitleAnalyticsBars, BarsChartContainer } from './styles';
import { AnalyticsDataContentThunk } from 'actions/Thunks/AnalyticsThunk';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import BarChart from 'components/molecules/BarChart';
import { BarChartData } from 'interfaces/analytics';
// import PieChart from './components/PieChart';

const AdminAnalytics: React.FC = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = React.useState<BarChartData>();
  const getDataAnalytics = React.useCallback(async () => {
    const response = await dispatch(AnalyticsDataContentThunk());
    if (AnalyticsDataContentThunk.rejected.match(response)) {
      toast.error(response.payload as string);
    } else setData(response.payload as BarChartData);
  }, [dispatch]);

  React.useEffect(() => {
    getDataAnalytics();
  }, [getDataAnalytics]);
  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={true}
    >
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-6">
            <div className="d-flex align-items-center justify-content-center">
              <div>{/* <PieChart totalData={3} /> */}</div>
              <div style={{ padding: 20 }}>text here</div>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex align-items-center justify-content-center">
              <div>{/* <PieChart /> */}</div>
              <div style={{ padding: 20 }}>text here</div>
            </div>
          </div>
        </div>
      </div>
      <TitleAnalyticsBars>Contenido utilizado en rutas de aprendizaje</TitleAnalyticsBars>
      <BarsChartContainer>
        {data ? (
          <BarChart id="ChartMonthlyContentUsed" data={data} />
        ) : (
          <ReactLoading type="spin" color={'black'} height={120} width={120} />
        )}
      </BarsChartContainer>
    </CommonPageLayout>
  );
};

export default AdminAnalytics;
