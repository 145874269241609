import * as React from 'react';
import AdminLearningPathPreviewDescription from 'components/molecules/AdminLearningPathPreviewDescription';
import AdminSkillsList from 'components/molecules/AdminSkillsList';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  CoursesWrappedSection,
  CoursesSectionTitle,
  CoursesCardWrapper,
  AdminCoverSection,
  RouteInfoWrapper,
  RouteSubTitle,
  RouteShareTitle,
  ButtonWrapper,
  RouteShareSectionWrapper,
} from './styles';
import AdminRouteCoursesList from 'components/molecules/AdminRouteCoursesList';
import icons from 'assets/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  UnauthDetailPathwayContentCourseThunk,
  UnauthDetailPathwayDashboardThunk,
  UnauthSelectedCourseListThunk,
} from '../../../actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';
import CoursesCardsPathwayDescription from '../../molecules/CoursesCardPathwayDescription';
import { ENVIRONMENT_URL } from 'utils';
import { AuthNavigations, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import Button from 'components/atoms/Button';
import { useHistory } from 'react-router-dom';
import BadgeListPublic from 'components/molecules/BadgeListPublic/BadgeListPublic';

interface UnauthLearningPathPreviewProps {
  pathwayId: string;
}

const UnauthLearningPathPreview = ({ pathwayId }: UnauthLearningPathPreviewProps): JSX.Element => {
  const visible = false;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const admin = useAppSelector((state) => state.admin);
  const courseSelector = useAppSelector((state) => state.courses);

  // const pathwaySkills = React.useCallback(async () => {
  //   const skills = await dispatch(DetailPathwaySkillsThunk(parseInt(pathwayId, 10)));

  //   if (DetailPathwaySkillsThunk.rejected.match(skills)) {
  //     toast.error(skills.payload as string);
  //   }
  // }, [dispatch, pathwayId]);

  const pathwayDashboard = React.useCallback(async () => {
    const dashboard = await dispatch(UnauthDetailPathwayDashboardThunk(parseInt(pathwayId, 10)));

    if (UnauthDetailPathwayDashboardThunk.rejected.match(dashboard)) {
      toast.error(dashboard.payload as string);
    }
  }, [dispatch, pathwayId]);

  // const pathwayBadges = React.useCallback(async () => {
  //   const badges = await dispatch(DetailPathwayBagdesThunk(pathwayId));

  //   if (DetailPathwayBagdesThunk.rejected.match(badges)) {
  //     toast.error(badges.payload as string);
  //   }
  // }, [dispatch, pathwayId]);

  const selectedCourses = React.useCallback(async () => {
    if (pathwayId) {
      const courses = await dispatch(UnauthSelectedCourseListThunk(parseInt(pathwayId)));

      if (UnauthSelectedCourseListThunk.rejected.match(courses)) {
        toast.error(courses.payload as string);
      }
    }
  }, [pathwayId, dispatch]);

  const contentCourse = React.useCallback(async () => {
    const contentCoursePathway = await dispatch(
      UnauthDetailPathwayContentCourseThunk(parseInt(pathwayId, 10))
    );

    if (UnauthDetailPathwayContentCourseThunk.rejected.match(contentCoursePathway)) {
      toast.error(contentCoursePathway.payload as string);
    }
  }, [dispatch, pathwayId]);

  // const getExperiencesPath = React.useCallback(async () => {
  //   if (pathwayId) {
  //     const experiences = await dispatch(LearningPathExperiencesThunk(parseInt(pathwayId)));
  //     if (LearningPathExperiencesThunk.rejected.match(experiences)) {
  //       toast.error(experiences.payload as string);
  //     }
  //   }
  // }, [pathwayId, dispatch]);

  React.useEffect(() => {
    pathwayDashboard();
    // pathwaySkills();
    selectedCourses();
    contentCourse();
    // getExperiencesPath();
    // pathwayBadges();
  }, [
    pathwayDashboard,
    // pathwaySkills,
    selectedCourses,
    contentCourse,
    // getExperiencesPath,
    // pathwayBadges,
  ]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {courseSelector.pathwayDetailGeneralContent && (
        <React.Fragment>
          <AdminCoverSection>
            <img
              src="https://acreditta-rutas.s3.amazonaws.com/static/route-cover-example.png"
              alt="imagen-de-portada"
            />
            <RouteInfoWrapper>
              <p
                style={{
                  maxWidth: '95%',
                  textAlign: 'center',
                  fontFamily: 'NotoSansJPBold',
                  fontSize: '40px',
                  lineHeight: '68px',
                  color: '#ffffff',
                }}
              >
                {courseSelector.pathwayDetailGeneralContent.name}
              </p>
              <ButtonWrapper>
                <Button label="Registrarme" widthCustom={378} onClick={() => history.push(AuthNavigations.login)}/>
              </ButtonWrapper>
              <RouteSubTitle>¿Conoces a alguien que le gustaría esta ruta?</RouteSubTitle>
              <RouteShareSectionWrapper>
                <RouteShareTitle
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${ENVIRONMENT_URL}${NonAuthenticatedUrls.path}/${pathwayId}`
                    );
                    toast('Enlace copiado');
                  }}
                >
                  ¡Compártela!
                </RouteShareTitle>
                {visible && <icons.UploadFileIcon />}
              </RouteShareSectionWrapper>
            </RouteInfoWrapper>
          </AdminCoverSection>
          <AdminLearningPathPreviewDescription
            creator={courseSelector.pathwayDetailGeneralContent.organization}
            start={courseSelector.pathwayDetailGeneralContent.start_date}
            content={courseSelector.pathwayDetailGeneralContent.course_count}
            description={courseSelector.pathwayDetailGeneralContent.description}
            duration={courseSelector.pathwayDetailGeneralContent.duration}
            users={courseSelector.pathwayDetailGeneralContent.user_count}
            level={courseSelector.pathwayDetailGeneralContent.level}
            language={courseSelector.pathwayDetailGeneralContent.language}
            methodology={courseSelector.pathwayDetailGeneralContent.methodology}
            isPrivate={courseSelector.pathwayDetailGeneralContent.is_private}
            image={courseSelector.pathwayDetailGeneralContent.photo}
          />
        </React.Fragment>
      )}
      {courseSelector.pathwayDetailSkillsContent &&
        (courseSelector.pathwayDetailSkillsContent?.length !== 0 ? (
          <AdminSkillsList skills={courseSelector.pathwayDetailSkillsContent} />
        ) : (
          <div></div>
        ))}
      {courseSelector.pathwayDetailBadges &&
        courseSelector.pathwayDetailGeneralContent &&
        (courseSelector.pathwayDetailBadges?.length !== 0 ? (
          <BadgeListPublic
            badges={courseSelector.pathwayDetailBadges.data}
            dateStart={courseSelector.pathwayDetailGeneralContent.start_date}
            pathwayId={pathwayId}
            userType={3}
          />
        ) : (
          <div>
            <br />
          </div>
        ))}
      {courseSelector.selectedCourseList && (
        <CoursesWrappedSection>
          <CoursesSectionTitle>
            {courseSelector.selectedCourseList.length} Cursos en esta ruta
          </CoursesSectionTitle>
          <CoursesCardWrapper>
            <CoursesCardsPathwayDescription
              courses={courseSelector.selectedCourseList}
              allowSelection={false}
              section={false}
            />
          </CoursesCardWrapper>
        </CoursesWrappedSection>
      )}
      {courseSelector.pathwayDetailContentCourse && (
        <AdminRouteCoursesList coursesList={courseSelector.pathwayDetailContentCourse} />
      )}
      {admin.experiencesPathListView && (
        <AdminRouteCoursesList experiencesList={admin.experiencesPathListView} />
      )}
    </CommonPageLayout>
  );
};

export default UnauthLearningPathPreview;
