import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccreditedRole, AuthenticatedUrls } from 'constants/NavigationUrls';
import OnBoarding from 'pages/OnBoarding';
import Profile from 'pages/Profile';
import EditProfile from 'pages/EditProfile';
import Terms from 'pages/Terms';
import BadgesDashboard from 'pages/Badges/Dashboard';
import BadgeDetails from 'pages/Badges/Details';
import PreviewBadges from 'pages/BadgesPreview';
import CatalogCourses from 'pages/CatalogCourses';
import LearningPathPreview from 'pages/LearningPathPreview';
import CoursePreview from 'pages/Admin/CoursePreview';
import DetailOrganizationBadge from 'pages/Badges/DetailOrganizationBadge';
import OrganizationBadges from 'pages/Badges/OrganizationDashboard';
import CreateNewPath from 'pages/Admin/CreateNewPath';
import PreviewPaths from 'pages/PathPreviewAcredited';
import CatalogPlus from 'components/organisms/CatalogPlus';
import OnboardingWizardStepsEdit from 'pages/Admin/OnboardingWizardStepsEdit';
import AdminEditPerfilInfo from 'components/organisms/AdminEditPerfilInfo';
import DetailTemplateBadge from 'pages/Badges/DetailTemplateBadge';
import BadgeTemplateInfo from 'components/organisms/BadgeTemplateInfo/BadgeTemplateInfo';
import BagdesStackableMain from 'pages/Badges/badges-stackable/BagdesStackableMain';

const AccreditedNavigation = (): JSX.Element => {
  return (
    <Switch>
      <Route path={AccreditedRole.onBoarding} component={OnBoarding} />
      <Route path={AccreditedRole.profile} component={Profile} />
      <Route path={AccreditedRole.editProfile} component={EditProfile} />
      <Route path={AuthenticatedUrls.terms} component={Terms} />
      <Route
        path={`${AccreditedRole.learningPathPreview}/:pathwayId`}
        component={LearningPathPreview}
      />
      <Route path={AccreditedRole.badgesDashboard} component={BadgesDashboard} />
      <Route path={AccreditedRole.badgeDetails} component={BadgeDetails} />
      <Route
        path={`${AccreditedRole.templateInfo}/:badge_id`}
        component={BadgeTemplateInfo}
        exact
      />
      <Route path={AccreditedRole.badgesPreview} component={PreviewBadges} />
      <Route path={AccreditedRole.catalogCourse} component={CatalogCourses} />
      <Route
        path={`${AccreditedRole.templateBadgeDetail}/:badge_id`}
        component={DetailTemplateBadge}
      />
      <Route path={`${AccreditedRole.coursePreview}/:courseId`} component={CoursePreview} />
      <Route path={AccreditedRole.organizationBadgeDetail} component={DetailOrganizationBadge} />
      <Route path={AccreditedRole.organizationBadges} component={OrganizationBadges} />
      <Route path={AccreditedRole.newPathWizz} component={CreateNewPath} />
      <Route path={AccreditedRole.pathPreview} component={PreviewPaths} />
      <Route path={AccreditedRole.catalogPlus} component={CatalogPlus} />
      <Route path={AccreditedRole.editPerfil} component={AdminEditPerfilInfo} />
      <Route path={`${AccreditedRole.editPath}/:pathwayId`} component={OnboardingWizardStepsEdit} />
      <Route
        path={`${AccreditedRole.badgesStackableDetail}/:id`}
        component={BagdesStackableMain}
        exact
      />
    </Switch>
  );
};

export default AccreditedNavigation;
