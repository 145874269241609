import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddBagdePaths,
  DeletePaths,
  GetBadge,
  GetBadgeDetail,
  GetBadgesListTemp,
  GetPaths,
  GetBadgeAutomation,
  GetBadgeOrganization,
  GetListUsersOrg,
  DeleteUsers,
  ReSendInvite,
  GetBadgesListTemplates,
  GetBadgesListTemplatesArchived,
  GetBadgeListUsers,
} from 'actions/ApiClient/AdminPaths';
import { GetErrorMessage } from 'utils';

export const ListPathThunk = createAsyncThunk(
  'paths/list-paths',
  async (_, { rejectWithValue }) => {
    try {
      const ListPaths = await GetPaths();
      return ListPaths;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DeletePathThunk = createAsyncThunk(
  'paths/delete',
  async (pathway_id: number, { rejectWithValue }) => {
    try {
      const DeletePath = await DeletePaths(pathway_id);
      return DeletePath;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListBadgesThunk = createAsyncThunk(
  'paths/list-badges',
  async (_, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadge();
      return ListBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListBadgesOrganizationThunk = createAsyncThunk(
  'paths/list-badges-org',
  async (org_id: number, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadgeOrganization(org_id);
      return ListBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailBadgesThunk = createAsyncThunk(
  'paths/detail-badges',
  async (badge_id: any, { rejectWithValue }) => {
    try {
      const DetailBadges = await GetBadgeDetail(badge_id);
      return DetailBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListBadgesAutomationThunk = createAsyncThunk(
  'paths/list-automation-badges',
  async (org_id: any, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadgeAutomation(org_id);
      return ListBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetBadgesListTemplatesThunk = createAsyncThunk(
  'paths/list-templates-badges',
  async (filter: CourseCatalogThunk, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadgesListTemplates(filter);
      return ListBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetBadgesListTemplatesArchivedThunk = createAsyncThunk(
  'paths/list-templates-badges-archived',
  async (filter: CourseCatalogThunk, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadgesListTemplatesArchived(filter);
      return ListBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetBadgeListUsersThunk = createAsyncThunk(
  'paths/list-templates-badges-users',
  async (filter: CourseCatalogThunk, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadgeListUsers(filter);
      return ListBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetListUsersOrgThunk = createAsyncThunk(
  'accounts/list-users-org',
  async (org_id: any, { rejectWithValue }) => {
    try {
      const ListUsers = await GetListUsersOrg(org_id);
      return ListUsers;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListBadgesTemplateThunk = createAsyncThunk(
  'paths/list-badges-template',
  async (_, { rejectWithValue }) => {
    try {
      const ListBadges = await GetBadgesListTemp();
      let constructBadges: ISelectOptionValueString[] = [];

      ListBadges.map((badges: IListBadgesTemp) => {
        return constructBadges.push({
          value: badges.uuid,
          label: badges.name,
        });
      });
      return constructBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AddBagdePathsThunk = createAsyncThunk(
  'admin/add',
  async (addBadgePath: IAddBadgePath, { rejectWithValue }) => {
    try {
      const addBagdes = await AddBagdePaths(addBadgePath);
      return addBagdes;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DeleteUsersThunk = createAsyncThunk(
  'admin/delete-users',
  async (cognito_id: string, { rejectWithValue }) => {
    try {
      const deleteUsers = await DeleteUsers(cognito_id);
      return deleteUsers;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DeletePathsThunk = createAsyncThunk(
  'admin/delete-paths',
  async (pathway_id: string, { rejectWithValue }) => {
    try {
      const deletePaths = await DeletePaths(pathway_id);
      return deletePaths;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ReSendInviteThunk = createAsyncThunk(
  'admin/resend',
  async (email: string, { rejectWithValue }) => {
    try {
      const deletePaths = await ReSendInvite(email);
      return deletePaths;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
