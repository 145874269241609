import { LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, CustomInput, Row } from 'reactstrap';
import styled from 'styled-components';

export const DetailsContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

export const LearningPathImageWrapper = styled.div`
  width: 200px;
  margin-bottom: 10px;
`;

export const AdditionalInformationContainer = styled.div`
  width: 100%;
  margin: 0px;
`;

export const PathCover = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const InputFileLabel = styled(Label)`
  border-radius: 8px;
  background: ${SECONDARY_COLOR};
  padding: 4px 21px;
  cursor: pointer;
  display: inline-block;
  font-family: NotoSansJPBold;
  color: ${LIGHT};
  margin-bottom: 0;
  height: 35px;

  input[type='file'] {
    display: none;
  }
  @media only screen and (max-width: 903px) {
    height: 50px;
    text-align: center;
  }
  @media ${device.mediumLaptop} {
    height: 35px;
  }
`;

export const AdditionalInformationTitle = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold;
`;

export const AdditionalInfoLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold;
  margin-top: 2px;
`;

export const PathName = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${PRIMARY_COLOR};

  @media ${device.laptops} {
    font-size: 20px;
  }
`;

export const PathIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

export const AdditionalInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0px;
  @media ${device.desktop} {
    margin-bottom: 40px;
  }
  @media ${device.laptops} {
    grid-template-columns: 35% 65%;
  }
  @media ${device.mediumLaptop} {
    grid-template-columns: 25% 75%;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  svg {
    width: 20px;
  }
`;

export const WarningImageText = styled.span`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  margin-left: 5px;
`;

export const CustomInputStyled = styled(CustomInput)`
  margin: 0px;
`;

export const RowLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const RowStyled = styled(Row)`
  margin: 0px;
  justify-content: space-between;

  @media ${device.laptops} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.mediumLaptop} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const TextInputWrapper = styled.div`
  margin: 10px 0px;
`;

export const ColStyled = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const ErrorMessageLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;
