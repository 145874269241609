import styled from 'styled-components';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import AcredittaText from '../AcredittaText';

interface Props {
  provider: string;
}

const StyleSpan = styled.span`
  font-weight: bold;
`;

const LinkPublicProfileProvider = ({ provider }: Props) => {
  return (
    <AcredittaText variant="body1" align="center">
      <a
        href={`${ENVIRONMENT_URL_BADGE}/public/organizacion/${provider}`}
        target="_blank"
        rel="noreferrer"
      >
        Emitida por: <StyleSpan>{provider}</StyleSpan>
      </a>
    </AcredittaText>
  );
};

export default LinkPublicProfileProvider;
