import * as React from 'react';
import {
  LoginContainer,
  LoginPictureContainer,
  LoginPictureWrapper,
  LoginPageContainer,
  ColLoginContainer,
  ColFormContainer,
  MainLogoIcon,
  RequestNewPasswordContainer,
  LoginTitle,
  LoginLogoPictureMainWrapper,
  LoginPictureContainerMobile,
  WrapperImageMobile,
  LoginWrapperCol,
  TitleSingUp,
  ContainerSingUp,
  TextSingUp,
  ButtonWrapper,
} from './styles';
import LoginForm from 'components/molecules/LoginForm';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { objectValidation, S3_STATIC } from 'utils';
import { LoginAction, NewPasswordRequired } from 'actions/ApiClient/AuthActions';
import toast from 'react-hot-toast';
import { AuthenticatedUrls, AuthNavigations } from 'constants/NavigationUrls';
import RequestNewPassword from 'components/molecules/RequestNewPassword';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AgreeTermsConditionsThunk } from 'actions/Thunks/AuthThunk';
import { logEvent } from 'utils/GA/analytics';
import AuthPageLayout from '../Layout/AuthPageLayout';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import { Container, Row } from 'reactstrap';
import { setAuthValid } from 'states/slices/auth.slice';
import { UserDataThunk } from 'actions/Thunks/UserThunk';
import { RouteRoleGuard } from 'utils/Routing/RoleGuard';
import Button from 'components/atoms/Button';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const responsive = useMediaQuery({ query: device.bigMobile });
  const smallMobileResponsive = useMediaQuery({ query: device.smallMobile });

  const [newPasswordRequired, setNewPasswordRequired] = React.useState<boolean>(false);
  const [userLoginInfo, setUserLoginInfo] = React.useState<LoginForm | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const admin = useAppSelector((state) => state.admin);

  const defaultValues: LoginForm = {
    email: '',
    password: '',
  };

  const getDefaultFormValues = (): LoginForm => {
    return defaultValues;
  };

  const defaultValuesNewPassword: NewPasswordForm = {
    newPassword: '',
    confirmNewPassword: '',
    termsConditions: false,
  };

  const getDefaultFormValuesNewPassword = (): NewPasswordForm => {
    return defaultValuesNewPassword;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginForm>({ ...objectValidation, defaultValues: getDefaultFormValues() });

  const {
    handleSubmit: handleSubmitNewPassword,
    control: controlNewPassword,
    formState: { errors: errorsNewPassword },
    watch: watchNewPassword,
  } = useForm<NewPasswordForm>({
    ...objectValidation,
    defaultValues: getDefaultFormValuesNewPassword(),
  });

  const userInformationNewSession = (message: string) => {
    dispatch(setAuthValid());

    dispatch(UserDataThunk())
      .then((user) => {
        if (UserDataThunk.fulfilled.match(user)) {
          if (user.payload) {
            if (!user.payload.terms_conditions) {
              history.push(AuthenticatedUrls.terms);
            } else {
              if (admin.statusEndPath !== undefined) {
                history.push(RouteRoleGuard(user.payload, admin.statusEndPath));
              }
            }
          }
        } else {
          toast.error(user.payload as string);
        }
      })
      .finally(() => {
        toast.success(message);
      });
  };

  const submitLogin = (loginInfo: LoginForm) => {
    setLoading(true);
    LoginAction(loginInfo)
      .then((res) => {
        if (res.ok) {
          userInformationNewSession(res.message);
        } else {
          if(res.message !== 'Nueva contraseña requerida.'){
            toast.error(res.message);
          }
          setUserLoginInfo(loginInfo);
          setNewPasswordRequired(true);
        }
      })
      .catch((error) => toast.error(error.message))
      .finally(() => setLoading(false));
  };

  const agreeWithTermsAndConditions = async () => {
    const terms = await dispatch(AgreeTermsConditionsThunk());
    if (AgreeTermsConditionsThunk.fulfilled.match(terms)) {
      if (terms.payload) {
        toast.success(terms.payload);
      }
    } else {
      toast.error(terms.payload as string);
    }
  };

  const submitRequiredNewPassword = (newPassword: NewPasswordForm) => {
    setLoading(true);
    if (userLoginInfo) {
      NewPasswordRequired({
        email: userLoginInfo.email,
        password: userLoginInfo.password,
        newPassword: newPassword.newPassword,
      })
        .then((res) => {
          dispatch(setAuthValid());
          dispatch(UserDataThunk())
            .then((user) => {
              if (UserDataThunk.fulfilled.match(user)) {
                // TODO: Is it necessary get user data when it's force change password? User already exists?
                agreeWithTermsAndConditions().finally(() => {
                  if (user.payload) {
                    history.push(RouteRoleGuard(user.payload, null));
                  }
                });
              } else {
                toast.error(user.payload as string);
              }
            })
            .finally(() => {
              toast.success(res);
            });
        })
        .catch((error) => toast.error(error.message))
        .finally(() => setLoading(false));
    }
  };

  const navigateRecovery = () => {
    logEvent('User', 'Forgot password');
    history.push(AuthNavigations.recovery);
  };

  const navigateSingUp = () => {
    history.push(AuthNavigations.singup);
  };

  return (
    <React.Fragment>
      <LoginPageContainer className="container-fluid ps-md-0">
        <React.Fragment>
          {!newPasswordRequired ? (
            <LoginContainer>
              {responsive && !smallMobileResponsive && (
                <LoginPictureContainerMobile sm={6}>
                  <WrapperImageMobile />
                </LoginPictureContainerMobile>
              )}
              <ColLoginContainer className="col-md-6 col-lg-6">
                <LoginWrapperCol className="d-flex align-items-center py-5">
                  <Container>
                    <Row>
                      <div className="col-md-9 col-lg-8 mx-auto">
                        <LoginLogoPictureMainWrapper>
                          <LoginPictureWrapper xs="11">
                            <MainLogoIcon src={`${S3_STATIC}login-logo.svg`} alt="Main logo" />
                          </LoginPictureWrapper>
                        </LoginLogoPictureMainWrapper>
                        <LoginTitle>Inicia Sesión</LoginTitle>
                        <ColFormContainer>
                          <LoginForm
                            control={control}
                            submitLogin={submitLogin}
                            handleSubmit={handleSubmit}
                            navigateRecovery={navigateRecovery}
                            errors={errors}
                            loading={loading}
                          />
                        </ColFormContainer>
                      </div>
                    </Row>
                  </Container>
                </LoginWrapperCol>
              </ColLoginContainer>
              {!responsive && (
                <LoginPictureContainer className="d-none d-md-flex col-md-6 col-lg-6">
                  <ContainerSingUp>
                    <TitleSingUp>Tu organización crece cuando lo hace tu gente</TitleSingUp>
                    <TextSingUp>
                      Aumenta la culminación de tus cursos en 200% con insignias verificables en
                      blockchain e impulsa el upskilling y reskilling para las nuevas necesidades de
                      tu mercado.
                    </TextSingUp>
                    <ButtonWrapper>
                      <Button label={'Regístrate ahora'} onClick={navigateSingUp} />
                    </ButtonWrapper>
                  </ContainerSingUp>
                </LoginPictureContainer>
              )}
            </LoginContainer>
          ) : (
            <LoginContainer>
              <AuthPageLayout showHeader={true} showFooter={true}>
                <RequestNewPasswordContainer>
                  <ColFormContainer>
                    <RequestNewPassword
                      controlNewPassword={controlNewPassword}
                      handleSubmitNewPassword={handleSubmitNewPassword}
                      submitRequiredNewPassword={submitRequiredNewPassword}
                      errorsNewPassword={errorsNewPassword}
                      loading={loading}
                      watch={watchNewPassword}
                    />
                  </ColFormContainer>
                </RequestNewPasswordContainer>
              </AuthPageLayout>
            </LoginContainer>
          )}
        </React.Fragment>
      </LoginPageContainer>
    </React.Fragment>
  );
};

export default Login;
