import { getBadgeDetailsData, getBadgeTemplateDetailsData } from 'actions/ApiClient/BadgesAction';
import icons from 'assets/Icon';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AcredittaText from 'components/atoms/AcredittaText';
import ShareBadgeImageButton from 'components/atoms/ShareBadgeImage/ShareBadgeImageButton';
import { BadgeDetail } from 'interfaces/badge-detail';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import { Modal } from 'reactstrap';
import { analyticsLinkShare } from 'services/analytics.service';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import ShareOnLinkedinProfileVertical from '../ModalSharedOnLinkedin/ShereOnLinkedinProfileVertical';
import './styles.css';

interface ShareBadgeModalProps {
  badgeId: string | undefined;
  badge_details: any;
  showModal: boolean;
  handleSocialModal: () => void;
  isTemplate?: boolean;
}

const ShareBadgeModal = ({
  badgeId,
  badge_details,
  showModal,
  handleSocialModal,
  isTemplate = false,
}: ShareBadgeModalProps) => {
  const [badge, setBadge] = useState({} as BadgeDetail);
  const [loading, setLoading] = useState(false);
  const publicUrl = `${ENVIRONMENT_URL_BADGE}/insignias/public`;
  const templateUrl = `${ENVIRONMENT_URL_BADGE}/badges/template`;

  const hidenSocialModal = () => {
    handleSocialModal();
  };

  useEffect(() => {
    (async () => {
      if (badgeId && isTemplate) {
        try {
          const resp = await getBadgeTemplateDetailsData(badgeId);
          const { data } = resp;
          setBadge(data.data);
        } catch (e) {
          console.error(e);
        }
      }
      if (badgeId) {
        try {
          const resp = await getBadgeDetailsData(badgeId);
          const { data } = resp;
          setBadge(data.data);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [badgeId, isTemplate]);

  const url = `${publicUrl}/${badge.id}`;

  const urlTemplate = `${templateUrl}/${badge.id}`;

  const createUrl = (type?: string): string => {
    let uri = isTemplate ? urlTemplate : url;

    if (type) {
      uri += `?utm_source=${type}&resource_type=badge&resource=${badge.id}`;
    }

    return uri;
  };

  const storeAnalyticsUtmSource = async (source: string) => {
    try {
      await analyticsLinkShare({
        resource: badge.id,
        resourceType: 'badge',
        utmSource: source,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleClick = async (type: string) => {
    await storeAnalyticsUtmSource(type);

    const uri = createUrl(type);

    switch (type) {
      case 'mail':
        let body = `¡Hola!\r\rMira el nuevo logro que obtuve en la plataforma de Acreditta\r\r${uri}`;
        let subject = 'Mira la insignia que obtuve';
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = `mailto:?subject=${subject}&body=${encodeURIComponent(body)}`;
        a.click();
        break;

      case 'linkedin_post':
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(uri)}`,
          '_blank'
        );
        break;

      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(uri)}`,
          '_blank'
        );
        break;

      case 'twitter':
        window.open(
          `http://twitter.com/share?text=Mira%20mi%20insignia%20&url=${encodeURIComponent(
            uri
          )}&hashtags=acreditta`,
          '_blank'
        );
        break;
    }
  };

  const handleCopy = async () => {
    await storeAnalyticsUtmSource('copy');
    const uri = createUrl('copy');
    navigator.clipboard.writeText(uri);
    toast.success('URL copiada al portapapeles');
  };

  return (
    <Modal
      className="modal-social__container"
      isOpen={showModal}
      toggle={hidenSocialModal}
      centered={true}
      backdrop={true}
      size="lg"
    >
      <>
        <div className="overlay" style={{ display: loading ? 'block' : 'none' }}>
          <div className="d-flex justify-content-center" style={{ height: '100%' }}>
            <AcredittaLoader color="white" text="Procesando..." />
          </div>
        </div>
        <div className="modal-social__head">
          <AcredittaText variant="h2">¡Comparte tu insignia! </AcredittaText>
          <icons.CleanSearchIcon onClick={hidenSocialModal} />
        </div>
        <div className="social-buttons__container">
          {isTemplate ? null : <ShareOnLinkedinProfileVertical badge={badge} uri={publicUrl} />}
          <button onClick={() => handleClick('linkedin_post')}>
            <LinkedinIcon
              className="linkedin-white"
              iconFillColor="#2867BC"
              bgStyle={{ fill: 'white' }}
              round={true}
              size={50}
            />
            <span>Post en Linkedin</span>
          </button>
          <button onClick={() => handleClick('twitter')}>
            <TwitterIcon round={true} size={50} />
            <span>Twitter</span>
          </button>
          <button onClick={() => handleClick('facebook')}>
            <FacebookIcon round={true} size={50} />
            <span>Facebook</span>
          </button>
          <button onClick={() => handleClick('mail')}>
            <div className="bg-grey">
              <icons.EmailBlueIcon />
            </div>
            <span>Email</span>
          </button>
          {isTemplate ? null : (
            <ShareBadgeImageButton
              className={'bg-grey'}
              badgeId={badge_details.id}
              setLoading={setLoading}
            />
          )}
        </div>
        <div id="copy-url" className="social-link__container" onClick={handleCopy}>
          <input type="text" disabled value={isTemplate ? urlTemplate : url} />
          <button>Copiar Link</button>
        </div>
      </>
    </Modal>
  );
};

export default ShareBadgeModal;
