import { useContext } from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import ConfigureOrganizationMenu from './ConfigureOrganizationMenu';
import ProfileFormOrganism from './Organization/ProfileFormOrganism';
import CertificatesOrganism from './Certificates/CertificatesOrganism';
import SusbcriptionOrganism from './Subscription/SusbcriptionOrganism';
import { ConfigureOrganizationContext } from 'pages/Admin/ConfigureOrganizationProfile/Context';
import './styles.css';

const ConfigureOrganizationLayout = () => {
  const { currentOption } = useContext(ConfigureOrganizationContext);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <div className="container">
        <ConfigureOrganizationMenu />

        {currentOption === 'profile' && <ProfileFormOrganism />}
        {currentOption === 'certificates' && <CertificatesOrganism />}
        {currentOption === 'subscription' && <SusbcriptionOrganism />}
      </div>
    </CommonPageLayout>
  );
};

export default ConfigureOrganizationLayout;
