import AcredittaLoader from 'components/atoms/AcredittaLoader';

const AcredittaFullLoader = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ width: '100%', height: '100vh', flexDirection: 'column' }}
    >
      <div>
        <img
          src="https://acreditta-rutas.s3.amazonaws.com/static/login-logo.svg"
          alt=""
          width="300"
          height="auto"
        />
      </div>
      <div className="mt-5">
        <AcredittaLoader />
      </div>
    </div>
  );
};

export default AcredittaFullLoader;
