import toast from 'react-hot-toast';
import { GetErrorMessage } from 'utils';
import styles from './index.module.css';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { logException } from 'utils/GA/analytics';
import StyledButton from 'components/atoms/Button';
import { AdminRole } from 'constants/NavigationUrls';
import useCountries from '../../../hooks/useCountries';
import InputSelect from 'components/atoms/InputSelect';
import { Form, Formik, FormikHelpers, Field } from 'formik';
import { GetUserType, updateUserData } from 'actions/ApiClient/AdminActions';
import useButtonResponsive from '../../../hooks/useButtonResponsive';
import AcredittaLoader from 'components/atoms/AcredittaLoader';

interface Values {
  country: { value: string; label: string };
  firstName: string;
  lastName: string;
  email: string;
  userType: string;
  jobTitle: string;
  textDeparment: string;
}

type Props = {
  user: any;
};

const AdminEditProfile = ({ user }: Props) => {
  const { loadOptions, countries } = useCountries();
  const [initialValues, setInitialValues] = useState<any>();
  const { buttonWidthResponsive } = useButtonResponsive();
  const [userTypes, setUserTypes] = useState<IUsertTypeList[]>([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(true);
  const { push } = useHistory();
  const { cognito_id: cognitoId } = user;

  useEffect(() => {
    setInitialValues({
      country: { value: user.accredited.country.id, label: user.accredited.country.name },
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      userType: user.accredited.user_type.id,
      jobTitle: user.accredited.job_title,
      textDeparment: user.accredited.text_department,
    });
    setLoading(false);
  }, [user]);

  useEffect(() => {
    (async () => {
      const response = await GetUserType();
      setUserTypes(response);
    })();
  }, []);

  const handleSubmit = async (values: Values, setSubmitting: any) => {
    try {
      setLoadingSubmit(true);
      const payload = {
        accredited: {
          country: values.country.value,
          user_type: Number(values.userType),
          job_title: values.jobTitle,
          text_department: values.textDeparment,
        },
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
      };

      const res: any = await updateUserData(cognitoId, payload);

      if (res.okay && res.data) {
        toast.success('Datos actualizados correctamente');
        push(AdminRole.loadUsers);
      } else {
        toast.success('Ocurrió un error al guardar');
      }
      setLoadingSubmit(false);
    } catch (err) {
      toast.error('Ocurrió un error ');
      setLoadingSubmit(false);
      Sentry.captureException(err);
      logException(GetErrorMessage(err), true);
      throw new Error(GetErrorMessage(err));
    }
  };

  const handleCancel = () => {
    push(AdminRole.loadUsers);
  };

  if (loading) {
    return <AcredittaLoader />;
  }

  return (
    <div className={`card ${styles.cardCustom}`}>
      <div className="card-body">
        <Formik
          initialValues={initialValues}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => {
            handleSubmit(values, setSubmitting);
          }}
        >
          {({ touched, errors, handleChange, handleBlur, values, setFieldValue }) => (
            <Form>
              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="firstName" className={styles['label-form']}>
                    Nombres
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Field
                    id="nombres"
                    name="nombres"
                    placeholder="John"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    className={`form-control ${styles.formControlCustom}`}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="lastName" className={styles['label-form']}>
                    Apellidos
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Field
                    id="lastName"
                    name="lastName"
                    placeholder="John"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    className={`form-control ${styles.formControlCustom}`}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="email" className={styles['label-form']}>
                    Correo
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Field
                    id="email"
                    name="email"
                    placeholder="jhon@doe.com"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                    className={`form-control ${styles.formControlCustom}`}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="country" className={styles['label-form']}>
                    País
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <InputSelect
                    onChange={(value) => {
                      setFieldValue('country', value);
                    }}
                    value={values.country}
                    loadOptions={loadOptions}
                    placeholder="Pais"
                    asyncSelect={true}
                    error={{}}
                    name="country"
                    id="country"
                    defaultOptions={countries}
                    style={{ borderRadius: 10 }}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="jobTitle" className={styles['label-form']}>
                    Cargo
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Field
                    id="jobTitle"
                    name="jobTitle"
                    placeholder="Cargo"
                    value={values.jobTitle || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${styles.formControlCustom} ${styles.formControlCustom}`}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="textDeparment" className={styles['label-form']}>
                    Departamento
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <Field
                    id="textDeparment"
                    name="textDeparment"
                    placeholder="Departamento"
                    value={values.textDeparment || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${styles.formControlCustom}`}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-12 text-sm-left col-md-3 text-md-right">
                  <label htmlFor="userType" className={styles['label-form']}>
                    Tipo de usuario
                  </label>
                </div>
                <div className="col-sm-12 col-md-9">
                  <select
                    name="userType"
                    id="userType"
                    value={values.userType}
                    className={`${styles.formControlCustom} form-control`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {userTypes.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div style={{ height: 50 }}></div>

              <div className="row ">
                <div className="col-sm-12 col-md-6">
                  <div className="d-sm-block d-md-flex justify-content-end align-content-center">
                    <StyledButton
                      label="Cancelar"
                      lightMode={true}
                      widthCustom={buttonWidthResponsive}
                      onClick={handleCancel}
                      disabled={loadingSubmit}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="d-sm-block d-md-flex justify-content-start align-content-center">
                    <StyledButton
                      type="submit"
                      label="Guardar"
                      widthCustom={buttonWidthResponsive}
                      loading={loadingSubmit}
                      disabled={loadingSubmit}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AdminEditProfile;
