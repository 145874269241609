import AcredittaText from 'components/atoms/AcredittaText';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import './styles.css';
import { organizationStatistics } from '../../../../services/user.service';
import { organizationStatisticsAdapter } from '../../../../adapters/organization-statistics';

const CardDashboardAdminInfo = () => {
  const { adminInfo, userInfo } = useAppSelector((state) => state.user);
  const [users, setUsers] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      let id = adminInfo ? adminInfo?.id : userInfo?.organization.id!;
      if (id) {
        try {
          const resp = await organizationStatistics(id!);
          let data = organizationStatisticsAdapter(resp.data);
          setTotal(data.availableUserCount);
          setUsers(data.countActiveUsers);
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [userInfo, adminInfo]);

  return (
    <div className="dashborad-info__container">
      <div>
        <AcredittaText variant="h2">{userInfo?.organization.name}</AcredittaText>
      </div>
      <div className="barra-container flex">
        <div className="barra-fondo barras">
          <div
            style={{
              width: `${Math.round((users * 100) / total)}%`,
            }}
            className="barra-value barras"
          ></div>
        </div>
      </div>
      <div className="statistics-container flex">
        <div>
          <AcredittaText fontWeight="500" color="secondary" variant="body2">
            Plan {userInfo?.organization.plan.name}
          </AcredittaText>
        </div>
        <div className="statistics__users flex">
          <AcredittaText fontWeight="bold" variant="body1">
            {users}/
          </AcredittaText>
          <AcredittaText variant="body1">{total + users}</AcredittaText>
        </div>
      </div>
    </div>
  );
};

export default CardDashboardAdminInfo;
