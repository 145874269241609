import React from 'react';

interface Props {
  id: number | string;
  badgeStackableType: string;
  handleChange: (event: any) => void;
  label: string;
}

const RadioInput = ({ id, badgeStackableType, handleChange, label }: Props) => {
  return (
    <div className="form-check form-check-inline" key={id}>
      <input
        className="form-check-input"
        type="radio"
        name="badgeStackable"
        id={`radio${id}`}
        value={id}
        checked={badgeStackableType === String(id)}
        onChange={(e) => handleChange(e.target.value)}
      />
      <label className="form-check-label" htmlFor="inlineRadio1">
        {label}
      </label>
    </div>
  );
};

export default RadioInput;
