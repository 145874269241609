import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateInterestSkills,
  InterestAction,
  InterestActionProfile,
  SkillsAction,
  SkillsListAction,
  SkillsTagListAction,
} from 'actions/ApiClient/OnboardingActions';
import { GetErrorMessage } from 'utils';

export const InterestListThunk = createAsyncThunk(
  'onboarding/interest',
  async (_, { rejectWithValue }) => {
    try {
      const interest = await InterestAction();
      return interest;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const InterestListProfileThunk = createAsyncThunk(
  'onboarding/interestProfile',
  async (_, { rejectWithValue }) => {
    try {
      const interest = await InterestActionProfile();
      return interest;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const SkillListThunk = createAsyncThunk(
  'onboarding/skills',
  async (skillSearchData: ISkillRequest, { rejectWithValue }) => {
    try {
      const { page, interestId } = skillSearchData;
      const skills = await SkillsAction(page, interestId);
      return skills;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const SkillListEleThunk = createAsyncThunk(
  'onboarding/skills-list',
  async (_, { rejectWithValue }) => {
    try {
      const skills = await SkillsListAction();
      let constructBadges: ISelectOptionValue[] = [];

      skills.map((skills: IListBadgeTemp) => {
        return constructBadges.push({
          value: skills.id,
          label: skills.name,
        });
      });
      return constructBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const SkillsTagListThunk = createAsyncThunk(
  'skills-tag-list/',
  async (tag: string, { rejectWithValue }) => {
    try {
      const skillsTag = await SkillsTagListAction(tag);
      let constructBadges: ISelectOptionValue[] = [];

      skillsTag.map((skill: ISkillsTagList) => {
        return constructBadges.push({
          value: skill.id,
          label: skill.name,
        });
      });
      return constructBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateOnboardingThunk = createAsyncThunk(
  'onboarding/create',
  async (newInterest: ICreateInterestSkills, { rejectWithValue }) => {
    try {
      const createInterest = await CreateInterestSkills(newInterest);
      return createInterest;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
