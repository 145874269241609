import AcredittaText from 'components/atoms/AcredittaText';
import icons from 'assets/Icon';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import { Auth } from 'aws-amplify';

const NewCertificateButton = () => {
  const handleOpen = async () => {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getIdToken().getJwtToken();
    const uri = `${ENVIRONMENT_URL_BADGE}/editorpdf/editor-certificados?token=${token}`;
    window.open(uri, '_blank');
  };

  return (
    <div
      className="col-xs-12 col-sm-4 col-md-4 mb-1 mt-1"
      onClick={handleOpen}
      style={{ cursor: 'pointer' }}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: '100%',
          minHeight: '285px',
          border: '2px dashed rgba(0,0,0,.4)',
          flexDirection: 'column',
          padding: '20px',
        }}
      >
        <div>
          <icons.AddCoursesIcon />
        </div>
        <AcredittaText variant="h4" color="secondary">
          Crear un certificado
        </AcredittaText>
      </div>
    </div>
  );
};

export default NewCertificateButton;
