import { BadgeResult } from 'interfaces/badge-list-response';
import { useEffect, useState } from 'react';
import { getBadgeList } from 'services/badges.service';
import { CardBadgeCarousel, CardNewBadge } from '../';

const BadgeCarousel = () => {
  const [badges, setBadges] = useState<BadgeResult[]>([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getBadgeList({ ordering: 'created_at' });
        if (resp.data.results.length > 0) {
          setBadges(resp.data.results.slice(0, 10));
        }
      } catch (e) {
        console.error(e);
      }
      setIsReady(true);
    })();
  }, []);

  if (!isReady) {
    return <></>;
  }

  return (
    <div className="row">
      <div className="col">
        {badges.length > 0 ? <CardBadgeCarousel data={badges} /> : <CardNewBadge />}
      </div>
    </div>
  );
};

export default BadgeCarousel;
