import { BLUE_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';

import { Container, Label} from 'reactstrap';
import styled from 'styled-components';

export const CourseCatalogContainer = styled(Container)`
  flex: 1;
  margin-top: 30px;
  overflow: hidden;
`;

export const CourseCatalogHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const CourseCatalogTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
  margin-top: 50px;
`;

export const CourseCatalogSubTitles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
  margin-left: 30%;
`;

export const SubtitleFirst = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
`;

export const SubtitleSecond = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
  margin-top: 30px;
  margin-left: 25%;
  cursor: pointer;
`;

export const ContainNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubtitleFirstNoData = styled(Label)`
  color: ${PRIMARY_COLOR};
  padding: 10px;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 26px;
`;

export const SubtitleSecondNoData = styled(Label)`
  color: ${BLUE_LIGHT};
  padding: 10px;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
`;

export const ContainerNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  align-items: center;
  padding: 10%;
`;

export const RouteContainerFirst = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  width: 243px;
  height: 310px;
  background: ${PRIMARY_COLOR};
  border-radius: 8px;
`;

export const TitleCard = styled.h1`
  color: white;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const MyRoutesRows = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 25px 0px;
  
`;
