export const getDataCards = (overview: any) => {
  const { accepted, pending, rejected } = overview;
  let data: any = [];
  let total = accepted.value + pending.value + rejected.value;
  data.push({
    value: `${Math.trunc((pending.value * 100) / total)}%`,
    label: 'Insignias pendientes',
  });
  data.push({
    value: `${Math.trunc((accepted.value * 100) / total)}%`,
    label: 'Insignias aceptadas',
  });
  // TODO: pending for another sprint
  // data.push({
  //   value: '0%',
  //   label: 'Insignias compartidas',
  // });

  return data;
};
