import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const ExpiredCodePageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;

  svg {
    width: 500px;
  }
`;

export const ExpiredCodePageTitle = styled(Label)`
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: ${PRIMARY_COLOR};
`;

export const ExpiredCodePageDesc = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
  max-width: 50%;
  text-align: center;
`;
export const ExpiredCodePageButtonWrapper = styled.div`
  margin: 20px 0px 0px;
`;
