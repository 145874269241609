import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Container, Label, Col, Row } from 'reactstrap';
import styled from 'styled-components';

export const CourseCatalogContainer = styled(Container)`
  flex: 1;
`;

export const CourseCatalogHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CourseCatalogTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
  margin-top: 50px;
`;

export const CourseCatalogSubTitles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
`;

export const SubtitleFirst = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;

export const SubtitleSecond = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
`;

export const RowStyled = styled(Row)`
  margin: 15px 2px;
`;

export const BarSearchContainer = styled(Col)`
  display: grid;
  grid-template-columns: 2% 98%;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  background-color: ${PRIMARY_COLOR};
  margin-bottom: 20px;

  @media ${device.laptops} {
    grid-template-columns: 4% 96%;
  }
  @media ${device.bigMobile} {
    grid-template-columns: 6% 94%;
  }
  @media ${device.smallMobile} {
    grid-template-columns: 8% 92%;
  }
`;

export const MyRoutesRows = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 25px 0px;
  @media ${device.bigMobile} {
    width: 80%;
  }
`;

export const ColStyled = styled(Col)`
  @media ${device.mediumLaptop} {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media ${device.mediumMobile} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const RouteContainerFirst = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  width: 243px;
  height: 310px;
  background: ${PRIMARY_COLOR};
  border-radius: 8px;
`;

export const TitleCard = styled.h1`
  color: white;
`;
