import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const NewIndividualBadgeContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: self-start;
  margin: 80px 20px;
`;

export const BadgeCoverIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AssignHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0px 0px 40px;
  svg {
    width: 40px;
    cursor: pointer;
  }
`;

export const AssignHeaderLabel = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin: 0px;
  margin-left: 15px;
`;
