import { Auth } from 'aws-amplify';
import { AccountsUrls, API_URL, PublicUrls } from 'constants/ApiUrls';
import { LoginException } from 'utils/CognitoConfigure/LoginException';
import { HttpRequest } from './HttpRequest';
import * as Sentry from '@sentry/react';
import { logException } from 'utils/GA/analytics';
import { GetErrorMessage } from '../../utils';
import AcredittaRequest from 'actions/requests/api';

export const LoginAction = async (user: ILogin) => {
  try {
    const loginUser = await Auth.signIn(user.email, user.password);

    if (loginUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return {
        ok: false,
        message: 'Nueva contraseña requerida.',
      };
    }
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(LoginException(error.code || ''));
  }

  return {
    ok: true,
    message: 'Se ha iniciado sesión de forma exitosa',
  };
};

export const RegisterAction = async (user: SendSingUpForm) => {
  try {
    const registerUser = await new HttpRequest().UnAuthPost<SendSingUpForm>(
      `${API_URL}${AccountsUrls.regsiterUser}`,
      user
    );

    if (registerUser.okay) {
      return 'Usuario creado con éxito';
    } else {
      throw Error(registerUser.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
};

export const CodeVerify = async (user: SingUpValidate) => {
  try {
    const registerUser = await new HttpRequest().UnAuthPost<SingUpValidate>(
      `${API_URL}${AccountsUrls.verifyUser}`,
      user
    );

    if (registerUser.okay) {
      return 'Usuario verificado con éxito';
    } else {
      throw Error(registerUser.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
};

export const RequestRestorePassword = async (email: string): Promise<string> => {
  try {
    const emailFormat = email.toLocaleLowerCase();
    // const body = {
    //   email: emailFormat,
    // };
    // const verifyEmail = await new HttpRequest().UnAuthPost<any>(
    //   `${API_URL}${PublicUrls.verifyEmail}`,
    //   body
    // );
    // if (verifyEmail.okay) {
    await Auth.forgotPassword(emailFormat);
    return 'Se ha enviado un email con el código de verificación';
    // } else {
    //   throw Error(verifyEmail.message);
    // }
  } catch (error: any) {
    Sentry.captureException(error);

    logException(error.message, true);

    throw new Error(LoginException(error.code || ''));
  }
};

export const SendEmailToResetPassword = async (UserEmail: string) => {
  try{
    const resp = await AcredittaRequest.post(`accounts/reset-password/`, { email: UserEmail });
    return resp
  }catch(error){
    Sentry.captureException(error)
    throw error;
  }
  
};

export const ConfirmPasswordChangue = async (UserData: any) => {
  try {
    const resp = await AcredittaRequest.post('accounts/change-password/', UserData);
    return resp;
  } catch (error) {
    Sentry.captureException(error)
    throw error;
  }
};

export const ConfirmRestorePassword = async (resetPass: IRecoveryPassword) => {
  try {
    await Auth.forgotPasswordSubmit(resetPass.email, resetPass.code, resetPass.password);
    return 'La contraseña se ha cambiado exitosamente, por favor inicia sesión';
  } catch (error: any) {
    Sentry.captureException(error);

    logException(error.message, true);

    throw new Error(LoginException(error.code || ''));
  }
};

export const NewPasswordRequired = async (newPassword: IRequireNewPassword) => {
  try {
    const user = await Auth.signIn(newPassword.email, newPassword.password);

    await Auth.completeNewPassword(user, newPassword.newPassword, { email: newPassword.email });
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(LoginException(error.code || ''));
  }

  return 'Nueva contraseña registrada con éxito';
};

export const SignOut = async () => {
  try {
    await Auth.signOut();
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(LoginException(error.code || ''));
  }

  return 'Se ha cerrado sesión.';
};

export const AgreeTermsAndConditions = async (terms: {
  terms_conditions: boolean;
}): Promise<string | undefined> => {
  try {
    const awsAuthInfo = await Auth.currentUserInfo();
    if (awsAuthInfo) {
      const idCognito = awsAuthInfo.attributes.sub;
      const request = await new HttpRequest().Patch<ITermsAndPrivacy>(
        `${API_URL}${AccountsUrls.terms}${idCognito}/`,
        terms
      );
      if (request.okay) {
        return 'Términos Condiciones y políticas de privacidad aceptadas';
      } else {
        throw Error(
          'Lo sentimos, ha ocurrido un error al aceptar los términos y condiciones, intente nuevamente'
        );
      }
    } else {
      throw Error('Ha ocurrido un error, usuario inválido');
    }
  } catch (err: any) {
    Sentry.captureException(err);
    throw new Error(err.message);
  }
};

// TODO: types
export async function InviteByUser(userId: string): Promise<any> {
  try {
    const invite = await new HttpRequest().PublicGet<any>(
      `${API_URL}${PublicUrls.inviteByUser}${userId}/`
    );

    if (invite.okay && invite.data) {
      return invite.data;
    } else {
      throw Error(invite.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetNumberEmployee(): Promise<any> {
  try {
    const ListEmployee = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${PublicUrls.employeeList}`
    );
    if (ListEmployee.okay && ListEmployee.data) {
      return ListEmployee.data;
    } else {
      throw Error(ListEmployee.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetSector(): Promise<any> {
  try {
    const ListSectors = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${PublicUrls.sectorList}`
    );
    if (ListSectors.okay && ListSectors.data) {
      return ListSectors.data.results;
    } else {
      throw Error(ListSectors.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
