import { ReactNode } from 'react';
import styles from './styles.module.scss';

type AcredittaBoxProps = {
  borderRadius?: string;
  boxStyle?: Object;
  children?: ReactNode;
};

const AcredittaBox = ({ borderRadius, boxStyle, children }: AcredittaBoxProps) => {
  return (
    <div className={styles.box} style={{ ...boxStyle, borderRadius }}>
      {children}
    </div>
  );
};

export default AcredittaBox;
