import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';
import { MenuI } from '../interfaces/menu';

export const ORGANIZATION_CONFIG_MENU: MenuI[] = [
  { id: 1, text: 'Usuarios de la plataforma', path: AdminRole.loadUsers, icon: true },
  { id: 2, text: 'Perfil de la organización', path: AdminRole.organizationBadges },
  { id: 3, text: 'Configuración', path: AdminRole.configureOrganization },
];

export const ACREDITTED_CONFIG_MENU: MenuI[] = [
  { id: 1, text: 'Configuración', path: AccreditedRole.editPerfil },
];
