import React from 'react';
import {
  BadgeCoverIconContainer,
  ContainerBox,
  ContainerInput,
  ContainerInputIcon,
  ContentRRSS,
  NewIndividualBadgeContainer,
} from './styles';
import { objectValidation } from 'utils';
import { useForm } from 'react-hook-form';
import CommonPageLayout from '../Layout/CommonPageLayout';
import AdminEditPerfilInfoFormData from 'components/molecules/AdminEditPerfilInfoForm';
import AdminEditPerfilInfoImageForm from 'components/molecules/AdminEditPerfilInfoImage';
import {
  ChangeImageProfileThunk,
  EditProfileFormThunk,
  GetDetailsProfileInfoThunk,
} from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';
import icons from 'assets/Icon';
import AcredittaText from 'components/atoms/AcredittaText';
import { Input } from 'reactstrap';

const AdminEditPerfilInfo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useAppSelector((state) => state.user);

  const [infoUser, setInfoUser] = React.useState<IUserProfileData>();
  const [linkedin, setLinkedin] = React.useState<string>('');
  const [facebook, setFacebook] = React.useState<string>('');
  const [instagram, setInstagram] = React.useState<string>('');
  const [twitter, setTwitter] = React.useState<string>('');

  const defaultValuesNewBadgeUpload: IFormEditProfie = {
    form: {
      first_name: '',
      last_name: '',
      phone_number: '',
      nickname: '',
      contact_email: '',
      accredited: {
        dni_type: '',
        biography: '',
        country: '',
        dni: '',
        birthdate: '',
        job_title: '',
        text_department: '',
        text_user_area: '',
        domain: '',
        url_facebook: '',
        url_twitter: '',
        url_linkedin: '',
        url_instagram: '',
      },
    },
    avatar: {
      image: null,
    },
    id: '',
  };

  const getDefaultFormValues = (): IFormEditProfie => {
    return defaultValuesNewBadgeUpload;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IFormEditProfie>({
    ...objectValidation,
    defaultValues: getDefaultFormValues(),
  });

  const submitNewBadgeTemplate = async (user_info: IFormEditProfie) => {
    if (
      user_info.form !== undefined &&
      user.userInfo?.cognito_id !== undefined &&
      infoUser !== undefined
    ) {
      let new_badge: IFormEditProfie = {
        form: {
          first_name:
            user_info.form.first_name === '' ? infoUser?.first_name : user_info.form.first_name,
          last_name:
            user_info.form.last_name === '' ? infoUser?.last_name : user_info.form.last_name,
          phone_number: user.userInfo.phone_number,
          nickname: user_info.form.nickname === '' ? infoUser?.nickname : user_info.form.nickname,
          contact_email:
            user_info.form.contact_email === ''
              ? infoUser?.contact_email
              : user_info.form.contact_email,
          accredited: {
            dni_type: null,
            biography:
              user_info.form.accredited.biography === ''
                ? infoUser?.accredited.biography
                : user_info.form.accredited.biography,
            country:
              user_info.form.accredited.country.value === ''
                ? infoUser?.accredited.country.phone_code
                : user_info.form.accredited.country.value,
            dni: null,
            birthdate: null,
            job_title: null,
            text_department: null,
            text_user_area: null,
            domain:
              user_info.form.accredited.domain === ''
                ? infoUser?.accredited.domain
                : user_info.form.accredited.domain,
            url_facebook: facebook === '' ? infoUser?.accredited.url_facebook : facebook,
            url_twitter: twitter === '' ? infoUser?.accredited.url_twitter : twitter,
            url_linkedin: linkedin === '' ? infoUser?.accredited.url_linkedin : linkedin,
            url_instagram: instagram === '' ? infoUser?.accredited.url_instagram : instagram,
          },
        },
        avatar: {
          image: null,
        },
        id: user.userInfo?.cognito_id,
      };

      if (new_badge) {
        const changeForm = await dispatch(EditProfileFormThunk(new_badge));
        if (EditProfileFormThunk.fulfilled.match(changeForm)) {
          toast.success('Se ha modificado el perfil con éxito');

          if (user.userRole === 3) {
            history.push(AccreditedRole.profile);
          } else {
            history.push(AdminRole.profile);
          }
        } else {
          toast.error(changeForm.payload as string);
        }
      }
    }

    if (user_info.avatar !== undefined && user.userInfo?.cognito_id !== undefined) {
      let new_image: IFormEditProfie = {
        form: {
          first_name: '',
          last_name: '',
          phone_number: '',
          nickname: '',
          contact_email: '',
          accredited: {
            biography: '',
            dni_type: '',
            country: '',
            dni: '',
            birthdate: '',
            job_title: '',
            text_department: '',
            text_user_area: '',
            domain: '',
            url_facebook: '',
            url_twitter: '',
            url_linkedin: '',
            url_instagram: '',
          },
        },
        avatar: {
          image: user_info.avatar.image,
        },
        id: user.userInfo?.cognito_id,
      };
      if (new_image.avatar.image !== null) {
        const changeImage = await dispatch(ChangeImageProfileThunk(new_image));
        if (ChangeImageProfileThunk.rejected.match(changeImage)) {
          toast.error(changeImage.payload as string);
        } else {
          if (user.userRole === 3) {
            history.push(AccreditedRole.profile);
          } else {
            history.push(AdminRole.profile);
          }
        }
      }
    }
  };

  const getProfileInfo = React.useCallback(async () => {
    if (user.userInfo?.cognito_id !== undefined) {
      const data = await dispatch(GetDetailsProfileInfoThunk(user.userInfo?.cognito_id));
      if (GetDetailsProfileInfoThunk.fulfilled.match(data)) {
        setInfoUser(data.payload);
      } else {
        toast.error(data.payload as string);
      }
    }
  }, [user, dispatch]);

  const handleChangeLinkedin = (event: any) => {
    setLinkedin(event.target.value);
  };

  const handleChangeTwitter = (event: any) => {
    setTwitter(event.target.value);
  };

  const handleChangeFacebook = (event: any) => {
    setFacebook(event.target.value);
  };

  const handleChangeInstagram = (event: any) => {
    setInstagram(event.target.value);
  };

  React.useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={user.userInfo?.user_type.code === 'ADMIN' ? false : true}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <div>
        {infoUser !== undefined ? (
          <NewIndividualBadgeContainer>
            <BadgeCoverIconContainer>
              <AdminEditPerfilInfoImageForm watch={watch} control={control} errors={errors} />
              <ContentRRSS>
                <AcredittaText variant="h4">Redes sociales</AcredittaText>
                <ContainerBox>
                  <ContainerInputIcon>
                    <icons.IconoLinkedin2 />
                  </ContainerInputIcon>
                  <ContainerInput>
                    <Input
                      bsSize="sm"
                      name="url_linkedin"
                      onChange={(value) => handleChangeLinkedin(value)}
                      defaultValue={infoUser.accredited.url_linkedin}
                    />
                  </ContainerInput>
                </ContainerBox>
                <ContainerBox>
                  <ContainerInputIcon>
                    <icons.IconoTwitter2 />
                  </ContainerInputIcon>
                  <ContainerInput>
                    <Input
                      bsSize="sm"
                      name="url_twitter"
                      onChange={(value) => handleChangeTwitter(value)}
                      defaultValue={infoUser.accredited.url_twitter}
                    />
                  </ContainerInput>
                </ContainerBox>
                <ContainerBox>
                  <ContainerInputIcon>
                    <icons.IconoFacebook2 />
                  </ContainerInputIcon>
                  <ContainerInput>
                    <Input
                      bsSize="sm"
                      name="url_facebook"
                      onChange={(value) => handleChangeFacebook(value)}
                      defaultValue={infoUser.accredited.url_facebook}
                    />
                  </ContainerInput>
                </ContainerBox>
                <ContainerBox>
                  <ContainerInputIcon>
                    <icons.IconoInstagram2 />
                  </ContainerInputIcon>
                  <ContainerInput>
                    <Input
                      bsSize="sm"
                      name="url_instagram"
                      onChange={(value) => handleChangeInstagram(value)}
                      // defaultValue={infoUser.first_name}
                    />
                  </ContainerInput>
                </ContainerBox>
              </ContentRRSS>
            </BadgeCoverIconContainer>
            <AdminEditPerfilInfoFormData
              controlInfo={control}
              handleSubmitForm={handleSubmit}
              errorsInfo={errors}
              submitNewBadgeForm={submitNewBadgeTemplate}
            />
          </NewIndividualBadgeContainer>
        ) : (
          <h1>cargando</h1>
        )}
      </div>
    </CommonPageLayout>
  );
};

export default AdminEditPerfilInfo;
