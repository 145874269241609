import React from 'react';

interface AcredittaAvatarProps {
  image: string;
  w?: number;
  h?: number;
  alt?: string;
}

const AcredittaAvatar = ({ image, w = 45, h = 45, alt = '' }: AcredittaAvatarProps) => {
  return <img src={image} width={w} height={h} style={{ borderRadius: '50%' }} alt={alt} />;
};

export default AcredittaAvatar;
