import icons from 'assets/Icon';
import { AdminRole } from 'constants/NavigationUrls';
import { useAppSelector } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import {
  ModalBodyStyled,
  ModalIcons,
  ModalSubTitle,
  ModalText,
  ModalTitle,
} from '../AdminPreviewBadgesTable/styles';
import './styles.css';

interface IssueBadgeProps {
  selectIssuedId: any;
  setModalIssueBadge?: any;
}

const AdminIssueBadge = ({ selectIssuedId, setModalIssueBadge }: IssueBadgeProps) => {
  const history = useHistory();

  const user = useAppSelector((state) => state.user);

  const navigationIssue = (selection: string) => {
    if (selection === 'individual') {
      history.push(`${AdminRole.badgeIssue}/${selectIssuedId}`);
    } else {
      history.push(`${AdminRole.issueMasive}/${selectIssuedId}`);
    }
  };

  return (
    <ModalBodyStyled>
      <ModalTitle>¿Qué tipo de emisión quieres hacer?</ModalTitle>
      <ModalIcons>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer',
            width: '240px',
          }}
          onClick={() => navigationIssue('masive')}
        >
          <icons.IssueMasive
            style={{
              width: '150px',
              height: '150px',
            }}
            className="boton-opcion"
          />
          <ModalSubTitle>Emisión Masiva</ModalSubTitle>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            cursor: 'pointer',
            width: '240px',
          }}
          onClick={() => navigationIssue('individual')}
        >
          <icons.IssueIndividual
            style={{
              width: '150px',
              height: '150px',
            }}
            className="boton-opcion"
          />
          <ModalSubTitle>Emisión Individual</ModalSubTitle>
        </div>
      </ModalIcons>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px',
          width: '100%',
          maxWidth: '700px',
          marginTop: '20px',
        }}
      >
        {user.userInfo !== null && (
          <ModalText>
            <strong>Recuerda</strong> que se <strong>agregarán como acreditados</strong> a todos los
            usuarios a los que les <strong>emitas</strong> una <strong>insignia</strong> y contarán
            en tu plan de suscripción actual{' '}
            <strong>{user.userInfo?.organization.plan.name}</strong>
          </ModalText>
        )}
      </div>
    </ModalBodyStyled>
  );
};

export default AdminIssueBadge;
