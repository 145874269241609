import AcredittaRequest from 'actions/requests/api';

/**
 * Get badges overview
 * @returns Promise
 * @author Jose Quintero
 */
export const getBadgeOverview = async () => {
  return await AcredittaRequest.get(`reports/badges-overview/`);
};

/**
 * Get badges templates overview
 * @returns Promise
 * @author Jose Quintero
 */
export const getBadgeTemplateOverview = async (search?: string, page = 1) => {
  let uri = `reports/badge-templates-overview/?page=${page}`;

  if (search) {
    uri += `&search=${search}`;
  }

  return await AcredittaRequest.get(uri);
};

/**
 * Get accpedted badges
 * @param {string} fromDate
 * @param {string} toDate
 * @returns Promise
 * @author Jose Quintero
 */
export const getAcceptedBadgeCount = async (fromDate = '', toDate = '') => {
  let uri = 'reports/accepted-badge-count';

  if (fromDate.length > 0 && toDate.length > 0) {
    uri += `/?from_date=${fromDate}&to_date=${toDate}`;
  }

  return await AcredittaRequest.get(uri);
};

/**
 * Get issued badges count
 * @param {string} fromDate
 * @param {string} toDate
 * @returns Promise
 * @author Jose Quintero
 */
export const getIssuedBadgeCount = async (fromDate = '', toDate = '') => {
  let uri = 'reports/issued-badge-count';

  if (fromDate.length > 0 && toDate.length > 0) {
    uri += `/?from_date=${fromDate}&to_date=${toDate}`;
  }

  return await AcredittaRequest.get(uri);
};

/**
 * Get report badge dashboard in csv format
 * @param fromDate string
 * @param toDate string
 * @returns
 */
export const exportOrganizationBadgeReport = async (fromDate?: any, toDate?: any) => {
  let uri = `reports/export/organization-badges/`;
  if (fromDate && toDate) {
    uri += `?from_date=${fromDate}&to_date=${toDate}`;
  }
  return await AcredittaRequest.get(uri);
};
