import * as React from 'react';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import icons from 'assets/Icon';
import Switch from 'react-switch';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import {
  CourseInfoWrapper,
  CourseName,
  CourseNameWrapper,
  UtilsContainer,
  UtilItemsWrapper,
  InfoWrapper,
  SwitchContainer,
  SwitchLabel,
  MandatoryLabel,
} from './styles';
import { SelectedCourseListThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';

interface CourseItemListWrapperProps {
  pathSections: ILearningPathListView;
  handleNewOrder: (path: ILearningPathCourseElement[], path_id: number) => void;
}

const CourseItemsListWrapper = ({
  pathSections,
  handleNewOrder,
}: CourseItemListWrapperProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const mediumDevice = useMediaQuery({ query: device.mediumLaptop });
  const courseSelector = useAppSelector((state) => state.courses);

  const [coursesPathOrder, setNewOrder] = React.useState<ILearningPathCourseElement[]>(
    pathSections.path_element
  );

  const setNewPositions = (newOrder: ILearningPathCourseElement[]) => {
    let helper: ILearningPathCourseElement[] = [];
    newOrder.map((item, index) => {
      item = { ...item, position: index + 1 };
      return (helper = [...helper, item]);
    });
    handleNewPathOrder(helper);
  };

  const handleRLDDChange = (newOrder: ILearningPathCourseElement[]) => {
    setNewPositions(newOrder);
  };

  const handleCourseStatus = (course: ILearningPathCourseElement, index: number) => {
    let helper: ILearningPathCourseElement[] = [];
    course = { ...course, is_required: !course.is_required };
    coursesPathOrder.map((_, indexCourse) => {
      if (indexCourse === index) {
        return (helper = [...helper, course]);
      } else {
        return (helper = [...helper, coursesPathOrder[indexCourse]]);
      }
    });
    handleNewPathOrder(helper);
  };

  const moveDownCourse = (index: number) => {
    if (index + 1 < coursesPathOrder.length) {
      let helper: ILearningPathCourseElement[] = [];
      coursesPathOrder.map((_, indexCourse) => {
        if (indexCourse === index) {
          return (helper = [...helper, coursesPathOrder[indexCourse + 1]]);
        } else if (indexCourse === index + 1) {
          return (helper = [...helper, coursesPathOrder[indexCourse - 1]]);
        } else {
          return (helper = [...helper, coursesPathOrder[indexCourse]]);
        }
      });
      setNewPositions(helper);
    }
  };

  const moveTopCourse = (index: number) => {
    if (index > 0) {
      let helper: ILearningPathCourseElement[] = [];
      coursesPathOrder.map((_, indexCourse) => {
        if (indexCourse === index) {
          return (helper = [...helper, coursesPathOrder[indexCourse - 1]]);
        } else if (indexCourse === index - 1) {
          return (helper = [...helper, coursesPathOrder[indexCourse + 1]]);
        } else {
          return (helper = [...helper, coursesPathOrder[indexCourse]]);
        }
      });
      setNewPositions(helper);
    }
  };

  const handleNewPathOrder = (newOrder: ILearningPathCourseElement[]) => {
    setNewOrder(newOrder);
    handleNewOrder(newOrder, pathSections.id);
  };

  const initListCourseSelected = React.useCallback(async () => {
    if (courseSelector.pathwayCreated) {
      const courseSelectedList = await dispatch(
        SelectedCourseListThunk(courseSelector.pathwayCreated.id)
      );
      if (SelectedCourseListThunk.rejected.match(courseSelectedList)) {
        toast.error(courseSelectedList.payload as string);
      }
    }
  }, [courseSelector.pathwayCreated, dispatch]);

  React.useEffect(() => {
    initListCourseSelected();
  }, [initListCourseSelected]);

  return (
    <div>
      {courseSelector.selectedCourseList?.length !== 0 ? (
        <div>
          <MandatoryLabel>Obligatorio</MandatoryLabel>
          <RLDD
            cssClasses="list-container"
            items={coursesPathOrder}
            itemRenderer={(courseItem: ILearningPathCourseElement, index) => {
              return (
                <div key={index}>
                  <CourseInfoWrapper key={index}>
                    <CourseNameWrapper>
                      <div style={{width: '20px', display: 'flex', justifyContent: 'center'}}>
                        <icons.MoveIcon />
                      </div>
                      <CourseName>{courseItem.name}</CourseName>
                    </CourseNameWrapper>
                    <SwitchContainer>
                      {!mediumDevice && <SwitchLabel> No</SwitchLabel>}
                      <Switch
                        onChange={() => handleCourseStatus(courseItem, index)}
                        checked={courseItem.is_required}
                        onColor={PRIMARY_COLOR}
                        uncheckedIcon={false}
                        height={20}
                        width={38}
                      />
                      {!mediumDevice && <SwitchLabel> Si</SwitchLabel>}
                    </SwitchContainer>
                    {!mediumDevice && (
                      <InfoWrapper>
                        <icons.PlaySmallButton />
                        <span>{courseItem.session} Módulos</span>
                      </InfoWrapper>
                    )}
                    <UtilsContainer>
                      <UtilItemsWrapper onClick={() => moveTopCourse(index)} disabled={index === 0}>
                        <icons.ArrowUpIcon />
                        <span>Subir</span>
                      </UtilItemsWrapper>
                      <UtilItemsWrapper
                        onClick={() => moveDownCourse(index)}
                        disabled={index + 1 === coursesPathOrder.length}
                      >
                        <icons.ArrowDownIcon />
                        <span>Bajar</span>
                      </UtilItemsWrapper>
                    </UtilsContainer>
                  </CourseInfoWrapper>
                </div>
              );
            }}
            onChange={handleRLDDChange}
          />
        </div>
      ) : (
        <div>
          <ReactLoading type="spin" color={'black'} height={80} width={80} />
        </div>
      )}
    </div>
  );
};

export default CourseItemsListWrapper;
