import * as React from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import AdminCreateDnD from '../AdminCreateDnD';
import AdminCreateNewLearningPath from '../AdminCreateNewLearningPath';
import AdminOrganizeLearningPath from '../AdminOrganizeLearningPath';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { useAppSelector } from 'hooks/redux';
import AdminCoursesUploadSection from '../AdminCoursesUploadSection';
import AdminCatalogNewPath from '../AdminCatalogNewPath';

const AdminCreateNewPathWizzard = (): JSX.Element => {
  const [wizardInstance, setWizardInstance] = React.useState<Partial<StepWizardChildProps>>();
  // const [currentStep, setCurrentStep] = React.useState<number>(1);
  const user = useAppSelector((state) => state.user);

  const onNextStep = () => {
    if (wizardInstance && wizardInstance.nextStep) {
      wizardInstance.nextStep();
      // if (wizardInstance.currentStep) setCurrentStep(wizardInstance.currentStep);
    }
  };

  const persistStep = React.useCallback(() => {
    if (user.userInfo) {
      if (wizardInstance && wizardInstance.goToStep) {
        wizardInstance.goToStep(1);
        // setCurrentStep(1);
      }
    }
  }, [user.userInfo, wizardInstance]);

  React.useEffect(() => {
    persistStep();
  }, [persistStep]);

  return user.userRole === 3 ? (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={false}
      showMenuHeaderAcredited={true}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={true}
    >
      {user.adminInfo && user.adminInfo.own_courses ? (
        <StepWizard instance={setWizardInstance} isLazyMount={true}>
          <AdminCoursesUploadSection onNextStep={onNextStep} />
          <AdminCatalogNewPath onNextStep={onNextStep} />
          <AdminCreateDnD onNextStep={onNextStep} />
          <AdminOrganizeLearningPath onNextStep={onNextStep} />
          <AdminCreateNewLearningPath onNextStep={onNextStep} />
        </StepWizard>
      ) : (
        <StepWizard instance={setWizardInstance} isLazyMount={true}>
          <AdminCatalogNewPath onNextStep={onNextStep} />
          <AdminCreateDnD onNextStep={onNextStep} />
          <AdminOrganizeLearningPath onNextStep={onNextStep} />
          <AdminCreateNewLearningPath onNextStep={onNextStep} />
        </StepWizard>
      )}
    </CommonPageLayout>
  ) : (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {user.adminInfo && user.adminInfo.own_courses ? (
        <StepWizard instance={setWizardInstance} isLazyMount={true}>
          <AdminCoursesUploadSection onNextStep={onNextStep} />
          <AdminCatalogNewPath onNextStep={onNextStep} />
          <AdminCreateDnD onNextStep={onNextStep} />
          <AdminOrganizeLearningPath onNextStep={onNextStep} />
          <AdminCreateNewLearningPath onNextStep={onNextStep} />
        </StepWizard>
      ) : (
        <StepWizard instance={setWizardInstance} isLazyMount={true}>
          <AdminCatalogNewPath onNextStep={onNextStep} />
          <AdminCreateDnD onNextStep={onNextStep} />
          <AdminOrganizeLearningPath onNextStep={onNextStep} />
          <AdminCreateNewLearningPath onNextStep={onNextStep} />
        </StepWizard>
      )}
    </CommonPageLayout>
  );
};

export default AdminCreateNewPathWizzard;
