import { Label } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { LIGHT, SECONDARY_COLOR, PRIMARY_COLOR } from 'assets/styles/colors';

export const UploadCoursesOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 60px 0px;
`;

export const UploadCoursesOptionWrapper = styled.div<{
  $selected: boolean;
}>`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: ${(props) => (props.$selected ? `${SECONDARY_COLOR}` : `${PRIMARY_COLOR}`)};
  svg {
    margin: 5px;
    cursor: pointer;
  }
`;

export const UploadCoursesOptions = styled(Label)<{
  $selected: boolean;
}>`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => (props.$selected ? `${SECONDARY_COLOR}` : `${PRIMARY_COLOR}`)};
  cursor: pointer;
`;

export const UploadSelectedType = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 35px;
  line-height: 48px;
  color: ${PRIMARY_COLOR};
  width: 70%;
  text-align: center;
  margin-bottom: 30px;
`;

export const CoursesUploadContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;
  margin: 60px 0px;
`;

export const CoursesUploadTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CoursesUploadTypeFormWrapper = styled.div`
  width: 50%;
`;

export const CourseCoverIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CourseImageWrapper = styled.div`
  margin-bottom: 10px;
`;

export const CourseImage = styled.img`
  width: 350px;
  height: auto;
  object-fit: cover;
  @media ${device.desktop} {
    width: 250px;
  }
`;

export const WarningImageText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  margin-left: 5px;
  @media ${device.mediumMobile} {
    font-size: 11px;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 400px;
  svg {
    width: 20px;
  }
  @media ${device.mediumMobile} {
    width: 300px;
  }
  @media ${device.smallMobile} {
    width: 230px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const InputFileLabel = styled.label`
  width: 320px;
  height: 44px;
  border-radius: 8px;
  background: ${SECONDARY_COLOR};
  padding: 4px 21px;
  cursor: pointer;
  display: inline-block;
  font-family: NotoSansJPBold;
  color: ${LIGHT};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='file'] {
    display: none;
  }
  @media ${device.mediumMobile} {
    width: 300px;
  }
  @media ${device.smallMobile} {
    width: 230px;
  }
`;

export const ErrorMessageLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;
