import icons from 'assets/Icon';
import TextInput from 'components/atoms/TextInput';
import TextPasswordInput from 'components/atoms/TextPasswordInput';
import * as React from 'react';
import { Control, Controller, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import { PasswordForm, PasswordFormGroup, PasswordSubTitle } from './styles';
import '../RequestEmailRecovery/styles.css';
import AcredittaText from 'components/atoms/AcredittaText';
import { obscureEmail } from 'helpers';
import PasswordChecklist from 'react-password-checklist';

interface PasswordRecoveryProps {
  handleSubmit: UseFormHandleSubmit<IRecoveryPassword>;
  onSubmitConfirmPassword: (user: IRecoveryPassword) => Promise<void>;
  control: Control<IRecoveryPassword>;
  errors: Record<string, any>;
  loading: boolean;
  watch: UseFormWatch<IRecoveryPassword>;
  email?: string;
}

const PasswordRecovery: React.FC<PasswordRecoveryProps> = ({
  handleSubmit,
  onSubmitConfirmPassword,
  control,
  errors,
  loading,
  watch,
  email = '',
}) => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [passwordValid, setPasswordValid] = React.useState(false);

  return (
    <PasswordForm onSubmit={handleSubmit(onSubmitConfirmPassword)}>
      <PasswordFormGroup row>
        <AcredittaText align="center" variant="h1">
          Restablecer Contraseña
        </AcredittaText>
        <PasswordSubTitle for="exampleEmail" sm={6}>
          Ingresa el código de verificación enviado al correo {obscureEmail(email)}, Este código
          estará activo por 5 minutos únicamente.
        </PasswordSubTitle>
        <div className="input-container">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextInput
                label="Código de Verificación"
                icon={<icons.PasswordIcon />}
                type="number"
                name="code"
                placeholder="Ingresar código"
                value={value}
                onChange={(value) => onChange(value)}
                error={errors}
              />
            )}
            name="code"
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="input-container">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextPasswordInput
                label="Nueva Contraseña"
                icon={<icons.PasswordIcon />}
                type="password"
                name="password"
                placeholder="Ingresar constraseña"
                value={value}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  const { target } = evt;
                  onChange(evt);
                  setPassword(target.value);
                }}
                error={errors}
              />
            )}
            name="password"
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="input-container" style={{ margin: '10px auto', width: '100%' }}>
          <PasswordChecklist
            rules={['minLength', 'number', 'capital', 'match']}
            minLength={8}
            value={password}
            valueAgain={passwordConfirm}
            onChange={(isValid) => setPasswordValid(isValid)}
            messages={{
              minLength: 'La contraseña tiene más de 8 caracteres.',
              number: 'La contraseña tiene un número.',
              capital: 'La contraseña tiene una letra mayúscula.',
              match: 'Las contraseñas coinciden.',
            }}
          />
        </div>
        <div className="input-container">
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextPasswordInput
                label="Repetir Contraseña"
                icon={<icons.PasswordIcon />}
                type="password"
                name="confirmPassword"
                placeholder="Confirmar contraseña"
                value={value}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  const { target } = evt;
                  onChange(evt);
                  setPasswordConfirm(target.value);
                }}
                error={errors}
              />
            )}
            name="confirmPassword"
            rules={{
              required: true,
              validate: (value) => value === watch('password') || 'Las contraseñas no coinciden',
            }}
          />
        </div>
      </PasswordFormGroup>
      <div className="button-container">
        <button
          disabled={!passwordValid}
          style={{
            backgroundColor: !passwordValid ? 'rgba(0,0,0,0.2)' : '',
            cursor: !passwordValid ? 'not-allowed' : 'pointer',
          }}
        >
          Enviar
        </button>
      </div>
    </PasswordForm>
  );
};

export default PasswordRecovery;
