import StyledButton from 'components/atoms/Button';
import { S3_APP_GLOBAL } from 'constants/index';
import { AuthenticatedUrls } from 'constants/NavigationUrls';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import PublicLayout from '../Layout/PublicLayout';
import {
  ButtonWrapper,
  ImageMainWrapper,
  ImageNotFound,
  ImageNotFoundWrapper,
  MainNotFoundTitle,
  NotFoundContainer,
  TextLabelNotFound,
} from './styles';

const NotFound = (): JSX.Element => {
  const history = useHistory();

  const navigationBackRoot = () => {
    history.push(AuthenticatedUrls.root);
  };

  return (
    <PublicLayout>
      <Container>
        <NotFoundContainer>
          <MainNotFoundTitle>Algo ha salido mal</MainNotFoundTitle>
          <TextLabelNotFound>
            Parece que la página que estas buscando no existe, verifica la dirección o regresa a la
            página de inicio
          </TextLabelNotFound>
          <ButtonWrapper>
            <StyledButton label="Inicio" widthCustom={150} onClick={navigationBackRoot} />
          </ButtonWrapper>
          <ImageMainWrapper>
            <ImageNotFoundWrapper>
              <ImageNotFound
                src={`${S3_APP_GLOBAL}NotFound.svg`}
                alt={`${S3_APP_GLOBAL}NotFound.svg`}
              />
            </ImageNotFoundWrapper>
          </ImageMainWrapper>
        </NotFoundContainer>
      </Container>
    </PublicLayout>
  );
};

export default NotFound;
