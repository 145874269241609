import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Container, Label } from 'reactstrap';
import styled from 'styled-components';

export const CourseCatalogContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export const CourseCatalogHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CourseCatalogSubTitles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
`;

export const SubtitleFirst = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;
