import * as Sentry from '@sentry/react';
import { AdminUrls, API_URL, PublicUrls } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetListRoutes(): Promise<any> {
  try {
    const listRoutes = await new HttpRequest().Get<any>(`${API_URL}${PublicUrls.listMyRoutes}`);

    if (listRoutes.okay && listRoutes.data) {
      return listRoutes.data;
    } else {
      throw Error(listRoutes.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UploadEvidence(info: IUploadevidence): Promise<string> {
  try {
    const formData = new FormData();
    if (info.file) formData.append('file', info.file);
    if (info.path) formData.append('path', info.path.toString());
    if (info.description) formData.append('description', info.description);
    const upload = await new HttpRequest().PostForm<string>(
      `${API_URL}${PublicUrls.uploadEvidence}`,
      formData
    );

    if (upload.okay) {
      return 'Esta en revision su documento.';
    } else {
      throw Error(upload.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function ChangeStatusPath(uuid: string, status: string): Promise<string> {
  try {
    const statusData = {
      status: status,
    };
    const changeStatus = await new HttpRequest().Patch(
      `${API_URL}${PublicUrls.uploadEvidence}${uuid}/`,
      statusData
    );

    if (changeStatus.okay && changeStatus.data) {
      return 'Se ha modificado el status ';
    } else {
      throw Error(changeStatus.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetStatusEvidence(id: string): Promise<any> {
  try {
    const evidence = await new HttpRequest().Get<any>(
      `${API_URL}${PublicUrls.statusEvidence}${id}`
    );

    if (evidence.okay && evidence.data) {
      return evidence.data;
    } else {
      throw Error(evidence.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetAcceptBadge(uuid: string): Promise<any> {
  try {
    const accept = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.acceptBadge}${uuid}`);

    if (accept.okay && accept.data) {
      return accept.data;
    } else {
      throw Error(accept.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function PostEditBadge(content: IContentInfo): Promise<any> {
  try {
    let data = {
      is_public: content.info,
    };
    const publicBadge = await new HttpRequest().Patch<any>(
      `${API_URL}${AdminUrls.isPublicBadge}${content.uuid}/`,
      data
    );

    if (publicBadge.okay && publicBadge.data) {
      return publicBadge.data;
    } else {
      throw Error(publicBadge.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetReminderIndividual(uuid: string): Promise<string> {
  try {
    const reminderIndividual = await new HttpRequest().Get<string>(
      `${API_URL}${AdminUrls.getReminderIndividual}${uuid}`
    );

    if (reminderIndividual.okay && reminderIndividual.data) {
      return reminderIndividual.data;
    } else {
      throw Error(reminderIndividual.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function PostReminderMasive(uuid: string): Promise<string> {
  try {
    let data = {
      badge_template: uuid,
    };
    const reminderMasive = await new HttpRequest().Post<string>(
      `${API_URL}${AdminUrls.postReminderMasive}`,
      data
    );

    if (reminderMasive.okay && reminderMasive.data) {
      return reminderMasive.data;
    } else {
      throw Error(reminderMasive.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetRenewBadge(uuid: string): Promise<string> {
  try {
    const renewBadge = await new HttpRequest().Get<string>(
      `${API_URL}${AdminUrls.getRenewBadge}${uuid}`
    );

    if (renewBadge.okay && renewBadge.data) {
      return renewBadge.data;
    } else {
      throw Error(renewBadge.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
