import * as React from 'react';
import { DropZoneContainer } from './styles';
import DropZonePath from './DropZonePath';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  addDefaultDragAndDropSelection,
  restorePathwayResultCounter,
} from '../../../states/slices/course.slice';

const AdminDnDBoardPathway = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const courseSelector = useAppSelector((state) => state.courses);

  React.useEffect(() => {
    if (courseSelector.pathwayCreated) {
      const defaultSelection: IPathwayDragAndDrop[] = [
        {
          order: 1,
          pathway_id: courseSelector.pathwayCreated.id,
          path_element: [],
        },
        {
          order: 2,
          pathway_id: courseSelector.pathwayCreated.id,
          path_element: [],
        },
        {
          order: 3,
          pathway_id: courseSelector.pathwayCreated.id,
          path_element: [],
        },
      ];
      dispatch(addDefaultDragAndDropSelection(defaultSelection));
      dispatch(restorePathwayResultCounter());
    }
  }, [courseSelector.pathwayCreated, dispatch]);

  return (
    <DropZoneContainer>
      <DropZonePath pathOrder={0} />
      <DropZonePath pathOrder={1} />
      <DropZonePath pathOrder={2} />
    </DropZoneContainer>
  );
};

export default AdminDnDBoardPathway;
