import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';

interface LabelTextDivProps {
  show: boolean;
  position: 'left' | 'right';
}

const LabelTextDiv = ({ show, position }: LabelTextDivProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 20,
        left: position === 'left' ? 0 : 'none',
        right: position === 'right' ? 0 : 'none',
        width: '600px',
        zIndex: 999,
        display: show ? 'block' : 'none',
      }}
    >
      <AcredittaBox boxStyle={{ padding: 10 }}>
        <AcredittaText variant="body1" color="secondary">
          (*) Un archivo <b>.csv</b> te permite realizar la emisión de tus insignias de forma
          masiva. Recuerda que este archivo de texto debe estar delimitado por comas y debe contene
          hasta 5.000 filas. Asegurate de que el archivo <b>.csv</b> esté en el formato requerido y
          que se completen todos los campos obligatorios para subir los datos correctamente.
        </AcredittaText>
      </AcredittaBox>
    </div>
  );
};

export default LabelTextDiv;
