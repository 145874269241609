import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SkeletonWrapper } from './styles';

const SkeletonSkills: React.FC = () => {
  return (
    <React.Fragment>
      <SkeletonWrapper>
        <Skeleton height={40} count={6} />
      </SkeletonWrapper>
    </React.Fragment>
  );
};

export default SkeletonSkills;
