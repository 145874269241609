import React from 'react';
import {
  UploadSelectedType,
  CoursesUploadTypeWrapper,
  WarningImageText,
  CourseImage,
  CourseImageWrapper,
  CourseCoverIconContainer,
  WarningContainer,
  ButtonWrapper,
  InputFileLabel,
  ErrorMessageLabel,
  AdditionalInfoWrapper,
  AdditionalInfoLabel,
  RowStyled,
  ColStyled,
  RowLabel,
  InputSelectContainer,
  FormInputWrapper,
  FormLabelSimpleWrapper,
  FormLabelSimple,
} from './styles';
import icons from 'assets/Icon';
import { Controller } from 'react-hook-form';
import { FormText, Input } from 'reactstrap';
import toast from 'react-hot-toast';
import { S3_ADMIN } from 'constants/index';
import { ImageValidation } from 'utils';
import { UseFormWatch, Control } from 'react-hook-form';
import SimpleCheckBox from 'components/atoms/SimpleCheckBox';
import { GetModalityListThunk, LearningPathLevelsThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import TextInput from 'components/atoms/TextInput';

interface AdminUploadImageInfoCourseProps {
  watch: UseFormWatch<IUploadNewCourse>;
  control: Control<IUploadNewCourse>;
  errors: Record<string, any>;
  onSelectModality: (type: number) => void;
  onSelectLevel: (type: number) => void;
}

const AdminUploadImageInfoCourse = ({
  watch,
  control,
  errors,
  onSelectModality,
  onSelectLevel,
}: AdminUploadImageInfoCourseProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const file = watch().image;

  const admin = useAppSelector((state) => state.admin);

  const [courseType, setCourseType] = React.useState<ISelectOptionValue[] | null>(null);
  const [modalityType, setModality] = React.useState<number | null>(null);
  const [levelType, setLevel] = React.useState<any>(null);
  const [costType, setCostType] = React.useState<number | null>(null);

  const cost = [
    { label: 'Pago', value: 1 },
    { label: 'Gratis', value: 2 },
  ];

  const getModalities = React.useCallback(async () => {
    const modalities = await dispatch(GetModalityListThunk());
    if (GetModalityListThunk.rejected.match(modalities)) {
      toast.error(modalities.payload as string);
    } else {
      setCourseType(modalities.payload);
    }
  }, [dispatch]);

  const getLevels = React.useCallback(async () => {
    const levels = await dispatch(LearningPathLevelsThunk());
    if (LearningPathLevelsThunk.rejected.match(levels)) {
      toast.error(levels.payload as string);
    }
  }, [dispatch]);

  const selectedPicture = React.useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    } else {
      return `${S3_ADMIN}NoProfilePictureIcon.svg`;
    }
  }, [file]);

  const setCourseModality = (modality: number) => {
    setModality(modality);
    onSelectModality(modality);
  };

  const setLevelType = (level: number) => {
    setLevel(level);
    onSelectLevel(level);
  };

  React.useEffect(() => {
    getModalities();
    getLevels();
  }, [getModalities, getLevels]);

  return (
    <CoursesUploadTypeWrapper>
      <UploadSelectedType>Carga un Curso Individual</UploadSelectedType>
      <CourseCoverIconContainer>
        <CourseImageWrapper>
          <CourseImage src={selectedPicture} alt="imagen-organizacion" />
        </CourseImageWrapper>
        <Controller
          control={control}
          render={({ field: { onChange } }) => (
            <ButtonWrapper>
              <InputFileLabel>
                <Input
                  name="photo"
                  onChange={async (value) => {
                    const files = value.target.files;
                    const fileList: File[] = Array.prototype.slice.call(files);

                    if (await ImageValidation(fileList)) {
                      return onChange(fileList[0]);
                    } else {
                      toast.error(
                        'La imagen debe cumplir con los criterios de formato y dimensión'
                      );
                    }
                  }}
                  type="file"
                  accept="image/*"
                />
                <span>Cargar Portada</span>
              </InputFileLabel>
              {errors['image']?.type && (
                <ErrorMessageLabel>{errors['image']?.message}</ErrorMessageLabel>
              )}
            </ButtonWrapper>
          )}
          name="image"
        />
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>
            Imagen formato PNG/JPG con dimensión máxima de 1200x1200 pixeles
          </WarningImageText>
        </WarningContainer>
      </CourseCoverIconContainer>
      <FormLabelSimpleWrapper>
        <FormLabelSimple for="labels">Información Adicional</FormLabelSimple>
      </FormLabelSimpleWrapper>
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Modalidad</AdditionalInfoLabel>
        <RowStyled>
          {courseType &&
            courseType.map((type, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => setCourseModality(type.value)}
                      isChecked={modalityType === type.value}
                      onChange={() => setCourseModality(type.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="modality"
                />
                <RowLabel>{type.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Nivel</AdditionalInfoLabel>
        <RowStyled>
          {admin.levelPath &&
            admin.levelPath.map((level, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => setLevelType(level.value)}
                      isChecked={levelType === level.value}
                      onChange={() => setLevelType(level.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="level"
                />
                <RowLabel>{level.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Costo</AdditionalInfoLabel>
        <RowStyled>
          {cost &&
            cost.map((costItem, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => setCostType(costItem.value)}
                      isChecked={costType === costItem.value}
                      onChange={() => setCostType(costItem.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="type_cost"
                />
                <RowLabel>{costItem.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      {costType === 1 && (
        <FormInputWrapper>
          <InputSelectContainer>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="cost"
                  placeholder="Indica costo (Dólares Americanos)"
                  value={value ? value : ''}
                  onChange={(value) => onChange(value)}
                  error={errors}
                  height={38}
                  withError
                />
              )}
              name="cost"
              rules={{
                validate: {
                  checkHours: (v) => (v && v > 0) || 'El costo debe ser mayor a cero',
                },
              }}
            />
          </InputSelectContainer>
          <FormText>El valor de los cursos es en dólares Americanos.</FormText>
        </FormInputWrapper>
      )}
    </CoursesUploadTypeWrapper>
  );
};

export default AdminUploadImageInfoCourse;
