import styled from 'styled-components';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';

export const AdminDnDCardCourseWrapper = styled.div<{ $opacity: number }>`
  background: #ffffff;
  box-shadow: 0 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  width: 240px;
  flex: 0 0 auto;
  margin-right: 30px;
  cursor: move;
  float: left;
  opacity: ${({ $opacity }) => $opacity};
`;

export const AdminDnDCardTitle = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  color: ${PRIMARY_COLOR};
`;

export const AdminDnDCardFooter = styled.div`
  border-radius: 0 0 8px 8px;
  background-color: #003da6;
  border-color: #003da6;
  height: 19px;
  padding-left: 10px;
`;

export const AdminDnDCardFooterLabel = styled(Label)`
  font-size: 12px;
  font-family: NotoSansJPBold, sans-serif;
  color: white;
  display: flex;
  align-self: center;
`;

export const AdminDnDCardLabel = styled(Label)`
  font-size: 14px;
  margin-bottom: 0;
`;

export const AdminDndCardIconWrapper = styled.div`
  margin-right: 4px;
  width: 20px;
  display: flex;
  align-self: center;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const AdminDndCardTimeWrapper = styled.div`
  display: flex;
`;
