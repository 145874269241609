import ProfileInfo from 'components/molecules/ProfileInfo';
// import RoutesProgressInfo from 'components/molecules/RoutesProgressInfo';
import MyBadgesAchievement from 'components/molecules/MyBadgesAchievement';
import MyBadges from 'components/molecules/MyBadges';
import MySkills from 'components/molecules/MySkills';
import MyRoutes from 'components/molecules/MyRoutes';
import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import MyRoutesAdmin from 'components/molecules/MyRoutesAdmin';
import toast from 'react-hot-toast';
import { GetDetailsProfileInfoThunk } from 'actions/Thunks/AdminThunk';
import MyCollections from 'components/molecules/myCollections/MyCollections';

const Profile: React.FC = () => {
  const dispatch = useAppDispatch();

  const [infoUser, setInfoUser] = React.useState<IUserProfileData>();

  const user = useAppSelector((state) => state.user);
  const admin = useAppSelector((state) => state.admin);

  const getProfileInfo = React.useCallback(async () => {
    if (user.userInfo?.cognito_id !== undefined) {
      const data = await dispatch(GetDetailsProfileInfoThunk(user.userInfo?.cognito_id));
      if (GetDetailsProfileInfoThunk.fulfilled.match(data)) {
        setInfoUser(data.payload);
      } else {
        toast.error(data.payload as string);
      }
    }
  }, [user, dispatch]);

  React.useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={user.userRole === 3 ? false : true}
      showMenuHeaderAcredited={user.userRole === 3 ? true : false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {user.userInfo &&
        infoUser &&
        (user.userRole === 3 ? (
          <React.Fragment>
            <ProfileInfo
              name={user.userInfo.first_name + ' ' + user.userInfo.last_name}
              avatar={user.userInfo.avatar}
              countPath={user.userInfo.path_count}
              countBadges={admin.organizationBadges?.length}
              biography={infoUser?.accredited.biography}
              linkedin={infoUser.accredited.url_linkedin}
              facebook={infoUser.accredited.url_facebook}
              twitter={infoUser.accredited.url_twitter}
              instagram={infoUser.accredited.url_instagram}
            />
            <MyCollections />
            <MyBadges />
            <MyRoutes />
            <MySkills />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ProfileInfo
              name={user.userInfo.first_name + ' ' + user.userInfo.last_name}
              avatar={user.userInfo.avatar}
              countPath={user.userInfo.path_count}
              countBadges={admin.organizationBadges?.length}
              biography={infoUser?.accredited.biography}
              linkedin={infoUser.accredited.url_linkedin}
              facebook={infoUser.accredited.url_facebook}
              twitter={infoUser.accredited.url_twitter}
              instagram={infoUser.accredited.url_instagram}
            />
            
            <MyBadgesAchievement />
            <MyRoutesAdmin />
          </React.Fragment>
        ))}
    </CommonPageLayout>
  );
};

export default Profile;
