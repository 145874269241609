import AcredittaText from 'components/atoms/AcredittaText';
import styles from '../Certificates/styles.module.css';

const CertificateInfoText = () => {
  return (
    <div className="mb-3 row">
      <div className="col-9 offset-md-3">
        <AcredittaText variant="body1" color="secondary">
          <span className={styles.spanText}>*</span> La{' '}
          <span className={styles.spanText}>descripción</span> del certificado será la misma{' '}
          <span className={styles.spanText}>de la insignia</span>.
        </AcredittaText>
        <AcredittaText variant="body1" color="secondary">
          <span className={styles.spanText}>*</span> Ten en cuenta que el nombre del receptor, el
          nombre de la insignia, la fecha de emisión y expiración, la imagen de la insignia y el
          código QR se <span className={styles.spanText}>actualizarán automaticamente</span> al
          emitir la insignia.
        </AcredittaText>
      </div>
    </div>
  );
};

export default CertificateInfoText;
