import * as React from 'react';
import icons from 'assets/Icon';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import {
  ProgressMenuContainer,
  ItemMenuWrapper,
  ItemMenuTitle,
  MenuWrapper,
  IconWrapper,
  RectangleWrapper,
} from './styles';

interface ProgressMenuProps {
  selected: number;
  uploadOwnCourses: boolean;
}

interface IMenuItems {
  step: number;
  item: string;
  icon: JSX.Element;
}

const ProgressMenu = ({ selected, uploadOwnCourses }: ProgressMenuProps): JSX.Element => {
  const responsiveIcons = useMediaQuery({ query: device.mediumLaptop });
  const responsiveItemsName = useMediaQuery({ query: device.mobile });

  const menuItemsOwnCourses: IMenuItems[] = [
    {
      step: 1,
      item: 'Ingresa tus Datos',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.OnboardingTres />,
    },
    {
      step: 2,
      item: 'Selección de Intereses',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.OnboardingDos />,
    },
    {
      step: 3,
      item: 'Gana una Insignia',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.OnboardingUno />,
    },
  ];

  return (
    <ProgressMenuContainer>
      {menuItemsOwnCourses &&
        menuItemsOwnCourses.map((item, itemIndex) => {
          return (
            <MenuWrapper key={itemIndex}>
              <ItemMenuWrapper>
                <IconWrapper $selected={selected === item.step}>{item.icon}</IconWrapper>
                {!responsiveItemsName && (
                  <ItemMenuTitle $selected={selected === item.step}>{item.item}</ItemMenuTitle>
                )}
              </ItemMenuWrapper>
              {itemIndex < menuItemsOwnCourses.length - 1 && <RectangleWrapper />}
            </MenuWrapper>
          );
        })}
    </ProgressMenuContainer>
  );
};

export default ProgressMenu;
