import SimpleCheckBox from 'components/atoms/SimpleCheckBox';
import SkeletonSkills from 'components/atoms/SkeletonSkills';
import { useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { SkillSelection, SkillContainer, ContentWrapper, SelectSkillWrapper } from './styles';

interface SelectSkillProps {
  interestContent: IInterest;
  onSelectSkill: (indexSkill: number, id: number) => void;
}

const SelectSkill: React.FC<SelectSkillProps> = ({ interestContent, onSelectSkill }) => {
  const onboarding = useAppSelector((state) => state.onboarding);

  const onClickCheckBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    id: number
  ) => {
    onSelectSkill(index, id);
  };

  const isChecked = React.useCallback(
    (skillSelected: number) => {
      if (onboarding.interestSelected.length !== 0) {
        const selected = onboarding.interestSelected.find(
          (interest) => interest.id === interestContent.id
        );

        if (selected) {
          return selected.skills.some((skill) => skill === skillSelected);
        }
      }
      return false;
    },
    [interestContent.id, onboarding.interestSelected]
  );

  return (
    <React.Fragment>
      <ContentWrapper>
        <SkillSelection>Selecciona mínimo 1 habilidad en {interestContent.name}</SkillSelection>
      </ContentWrapper>
      {onboarding.skills ? (
        <React.Fragment>
          <SkillContainer>
            {onboarding.skills.map((skill, index) => (
              <SelectSkillWrapper key={index}>
                <SimpleCheckBox
                  type={'checkbox'}
                  value={skill.name}
                  handleCheckElement={(e) => onClickCheckBox(e, index, skill.id)}
                  isChecked={isChecked(skill.id)}
                />
              </SelectSkillWrapper>
            ))}
          </SkillContainer>
        </React.Fragment>
      ) : (
        <SkillContainer>
          <SkeletonSkills />
        </SkillContainer>
      )}
    </React.Fragment>
  );
};

export default SelectSkill;
