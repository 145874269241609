import ApexCharts from 'apexcharts';
import { FC, useEffect } from 'react';
import { BarChartConfig, BarChartData } from 'interfaces/analytics';
import { getBarChartMonthsSorted } from 'helpers/AnalyticsCharts/charts';

const BarChart: FC<{ id: string; data?: BarChartData; config?: BarChartConfig }> = ({
  id,
  data,
  config,
}) => {
  const chartId = `${id}-barchart`;
  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '40%',
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: config?.legend?.show ?? true,
      inverseOrder: true,
      position: 'top',
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      show: false,
    },
    labels: getBarChartMonthsSorted(),
    colors: [
      '#E94F4F',
      '#FF8CFA',
      '#8EC000',
      '#5593FE',
      '#F9B233',
      '#40FF5D',
      '#6E26FF',
      '#5B6636',
      '#4C8AE6',
      '#E6CA35',
    ],
    series: data,
  };

  useEffect(() => {
    const chartElement = document.querySelector(`#${chartId}`);
    if (chartElement) chartElement.innerHTML = '';

    if (!data || data.length === 0) return;
    const chart = new ApexCharts(chartElement, options);
    chart.render();
    // eslint-disable-next-line
  }, [data]);

  if (!data) return <div>❌ No se proporcionaron datos para el gráfico</div>;

  return <div style={{ width: '50%' }} id={chartId} />;
};

export default BarChart;
