import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const ModalContainer = styled(Modal)`
  max-width: 700px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  background-color: white;
  height: auto;
  padding: 20px;
  h5 {
    color: ${PRIMARY_COLOR};
    font-family: NotoSansJPBold;
    font-size: 30px;
    line-height: 22px;
    margin: 20px 0px;
  }
  span {
    color: ${PRIMARY_COLOR} !important;
  }

  @media ${device.mediumMobile} {
    padding: 10px;
    h5 {
      font-size: 24px;
      line-height: 20px;
    }
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  margin: 0px 30px;
  background-color: white;
  padding: 0px 20px;
  svg {
    cursor: pointer;
  }
  @media ${device.mediumMobile} {
    padding: 0px;
  }
`;

export const ModalFooterStyled = styled(ModalFooter)``;

export const ButtonWrapper = styled.div`
  margin: 10px 0px;
`;

export const ErrosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 10px 0px;
`;

export const ErrorLabel = styled(Label)`
  color: ${SECONDARY_COLOR};
`;
