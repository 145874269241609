import * as React from 'react';
import OrganizationBadgesComponent from 'components/organisms/OrganizationBadgesDashboard';

const OrganizationBadges = (): JSX.Element => {
  return (
    <React.Fragment>
      <OrganizationBadgesComponent unauthorized={true} showMenuHeader={false} />
    </React.Fragment>
  );
};

export default OrganizationBadges;
