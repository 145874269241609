import * as React from 'react';
import icons from 'assets/Icon';
import { Input } from 'reactstrap';
import {
  NewOrganizationDetailsFormContainer,
  OrganizationIconContainer,
  WarningContainer,
  WarningImageText,
  ErrorMessageLabel,
  OrganizationImageWrapper,
  OrganizationImage,
  InputFileLabel,
  ButtonWrapper,
} from './styles';
import { S3_ADMIN } from 'constants/index';
import { Control, Controller, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import { ImageValidation } from 'utils';
import toast from 'react-hot-toast';

interface NewDetailsFormProps {
  controlBasicInfo: Control<INewInfoForm>;
  errorsBasicInfo: Record<string, any>;
  handleSubmitBasicInfoForm: UseFormHandleSubmit<INewInfoForm>;
  watch: UseFormWatch<INewInfoForm>;
}

const NewOrganizationDetailsForm: React.FC<NewDetailsFormProps> = ({
  controlBasicInfo,
  errorsBasicInfo,
  watch,
}) => {
  const file = watch().avatar;

  const selectedPicture = React.useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    } else {
      return `${S3_ADMIN}Imagen_Perfil_Predeterminada.png`;
    }
  }, [file]);

  return (
    <NewOrganizationDetailsFormContainer>
      <OrganizationIconContainer>
        <OrganizationImageWrapper>
          <OrganizationImage src={selectedPicture} alt="imagen-perfil-acredidato" />
        </OrganizationImageWrapper>
        <Controller
          control={controlBasicInfo}
          render={({ field: { onChange } }) => (
            <ButtonWrapper>
              <InputFileLabel>
                <Input
                  name="photo"
                  onChange={async (value) => {
                    const files = value.target.files;
                    const fileList: File[] = Array.prototype.slice.call(files);

                    if (await ImageValidation(fileList)) {
                      return onChange(fileList[0]);
                    } else {
                      toast.error(
                        'La imagen debe cumplir con los criterios de formato y dimensión'
                      );
                    }
                  }}
                  type="file"
                  accept="image/*"
                />
                <span>Cargar Foto</span>
              </InputFileLabel>
              {errorsBasicInfo['image']?.type && (
                <ErrorMessageLabel>{errorsBasicInfo['image']?.message}</ErrorMessageLabel>
              )}
            </ButtonWrapper>
          )}
          name="avatar"
        />
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>
            Imagen formato PNG/JPG con dimensión máxima de 1200x1200 pixeles
          </WarningImageText>
        </WarningContainer>
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>
            Esta imagen será la foto de tu perfil como acredittado
          </WarningImageText>
        </WarningContainer>
      </OrganizationIconContainer>
    </NewOrganizationDetailsFormContainer>
  );
};

export default NewOrganizationDetailsForm;
