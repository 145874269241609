import {
  GetContentOrganizationThunk,
  GetRecommendedPathsThunk,
  PopularCourseThunk,
} from 'actions/Thunks/AdminCatalogThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  SubtitleFirst,
  SubtitleSecond,
  ContainNoData,
  SubtitleFirstNoData,
  SubtitleSecondNoData,
  ButtonWrapper,
  ContainerNoData,
  MyRoutesRows,
  RouteContainerFirst,
  TitleCard,
} from './styles';
import toast from 'react-hot-toast';
import AdminUploadNewIndividualCourse from '../AdminUploadNewIndividualCourse';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import AdminCourseCatalog from '../AdminCourseCatalogSeeMore';
import {
  BarSearchContainer,
  IconFilterWrapper,
  RowStyled,
  SearchIconContainer,
} from '../AdminCatalogNewPath/styles';
import icons from 'assets/Icon';
import { useForm, Controller } from 'react-hook-form';
import { objectValidation } from 'utils/index';
import TextInput from 'components/atoms/TextInput';
import Pagination from 'components/molecules/Pagination';
import CoursesCards from 'components/molecules/CoursesCardsUnselect';
import CourseFilters from 'components/molecules/CourseFilters';
import { defaultCourseSelection } from '../../../states/slices/course.slice';
import {
  AdminCourseCatalogFilterOptionsThunk,
  AdminCourseCatalogThunk,
} from 'actions/Thunks/AdminThunk';
import { useTour } from '@reactour/tour';
import ButtonCom from 'components/atoms/ButtonCom';
import ReactLoading from 'react-loading';
import { AdminRole } from 'constants/NavigationUrls';
import Route from 'components/atoms/Path';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import CourseCard from 'components/atoms/CourseCardCatalog';
import { Col } from 'reactstrap'

const AdminHomeCourseCatalog = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [uploadNewCourse, setUploadNewCourse] = React.useState<boolean>(false);
  const [toggleFilter, setToggleFilter] = React.useState<boolean>(false);
  const [seeMore, setSeeMore] = React.useState<boolean>(false);
  const [seeMoreData, setSeeMoreData] = React.useState<any>();
  const [dataPaths, setDataPaths] = React.useState<any>(null);
  const [dataContent, setDataContent] = React.useState<any>(null);
  const [courseRecomended, setCourseRecomended] = React.useState<any>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [stateFilter, setStateFilter] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState(
    localStorage.getItem('tour-catalog') === 'true' ? false : true
  );
  const [wordSearch, setWordSearch] = React.useState<string>('');

  const admin = useAppSelector((state) => state.admin);
  const user = useAppSelector((state) => state.user);

  const defaultValuesSearch: SearchBarForm = {
    search: '',
  };

  const getDefaultFormSearch = (): SearchBarForm => {
    return defaultValuesSearch;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<SearchBarForm>({
    ...objectValidation,
    defaultValues: getDefaultFormSearch(),
  });

  const keywords = watch().search;

  const getCoursesData = React.useCallback(async () => {
    const popular = await dispatch(PopularCourseThunk(0));
    if (PopularCourseThunk.rejected.match(popular)) {
      toast.error(popular.payload as string);
    } else {
      setCourseRecomended(popular.payload);
    }
  }, [dispatch]);

  const getContentOrganization = React.useCallback(async () => {
    const RecommendedPaths = await dispatch(GetRecommendedPathsThunk());
    if (GetRecommendedPathsThunk.rejected.match(RecommendedPaths)) {
      toast.error(RecommendedPaths.payload as string);
    } else {
      setDataPaths(RecommendedPaths.payload);
    }
    const OrganizationContent = await dispatch(GetContentOrganizationThunk());
    if (GetContentOrganizationThunk.rejected.match(OrganizationContent)) {
      toast.error(OrganizationContent.payload as string);
    } else {
      setDataContent(OrganizationContent.payload);
    }
  }, [dispatch]);

  const menuFilterOptionItems = React.useCallback(async () => {
    const menuFilter = await dispatch(AdminCourseCatalogFilterOptionsThunk());

    if (AdminCourseCatalogFilterOptionsThunk.rejected.match(menuFilter)) {
      toast.error(menuFilter.payload as string);
    }
  }, [dispatch]);

  const listCourseCatalog = React.useCallback(async () => {
    const courses = await dispatch(AdminCourseCatalogThunk({}));
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  }, [dispatch]);

  const handleNewCourseSubmit = () => {
    setUploadNewCourse(false);
    history.go(0);
  };

  const handleSeeMore = (data: any) => {
    setSeeMoreData(data);
    setSeeMore(true);
  };

  const handleUserKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(handleSearch)();
      reset();
    }
  };

  const openTour = () => {
    setModal(!modal);
    setIsOpen(true);
    localStorage.setItem('tour-catalog', 'true');
  };

  const closeTour = () => {
    setModal(!modal);
    localStorage.setItem('tour-catalog', 'true');
  };

  const handleSearch = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: keywords,
      })
    );
    setWordSearch(keywords);
    setStateFilter(true);
    setToggleFilter(false);

    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleSearchClear = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: '',
      })
    );
    setStateFilter(false);

    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleSearchPage = async (page: number) => {
    setCurrentPage(page);
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        page: page,
      })
    );
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
    reset();
  };

  const { setIsOpen } = useTour();

  const toggleFilterHandler = () => {
    setToggleFilter(!toggleFilter);
  };

  React.useEffect(() => {
    listCourseCatalog();
    menuFilterOptionItems();
    getCoursesData();
    getContentOrganization();

    dispatch(defaultCourseSelection());
  }, [dispatch, listCourseCatalog, getContentOrganization, menuFilterOptionItems, getCoursesData]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      selectMenuHeader={'catalog'}
      showCommon={true}
    >
      {!uploadNewCourse ? (
        !seeMore ? (
          <CourseCatalogContainer>
            <Modal centered fullscreen="xl" size="xl" isOpen={modal} backdrop={true}>
              <div>
                <icons.Imagen_Bienvenida />
              </div>
              <ModalBody>¿Te gustaría una breve guía por la plataforma?</ModalBody>
              <ModalFooter>
                <Button onClick={() => closeTour()}>No</Button>{' '}
                <Button color="danger" onClick={() => openTour()}>
                  Si
                </Button>
              </ModalFooter>
            </Modal>
            <RowStyled>
              <BarSearchContainer sm="12">
                <IconFilterWrapper onClick={toggleFilterHandler}>
                  <icons.FilterIcon />
                </IconFilterWrapper>
                <div>
                  <Controller
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        name="search"
                        error={errors}
                        height={35}
                        placeholder="¿Qué cursos buscas hoy?"
                        value={value}
                        onChange={onChange}
                        onKeyPress={handleUserKeyPressSearch}
                      />
                    )}
                    name="search"
                  />
                  <SearchIconContainer onClick={handleSubmit(handleSearchClear)}>
                    <icons.CleanSearchIcon />
                  </SearchIconContainer>
                </div>
              </BarSearchContainer>
            </RowStyled>
            {toggleFilter && <CourseFilters showFileterResults={() => setStateFilter(true)} />}
            {admin.courseCatalog && admin.courseCatalog.results ? (
              <React.Fragment>
                {stateFilter === true ? (
                  admin.courseCatalog.results.length !== 0 ? (
                    <Col>
                      <CoursesCards courses={admin.courseCatalog.results} />
                      <Pagination
                        currentPage={currentPage}
                        totalCount={admin.courseCatalog.count}
                        pageSize={20}
                        onPageChange={(page: number) => handleSearchPage(page)}
                      />
                    </Col>
                  ) : (
                    <ContainerNoData
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5%',
                      }}
                    >
                      <ContainNoData>
                        <icons.Icono_Busqueda />
                        <SubtitleFirstNoData>No encontramos resultados</SubtitleFirstNoData>
                        <SubtitleSecondNoData>
                          Buscamos "{wordSearch}" y no hemos encontrado elementos relacionados
                        </SubtitleSecondNoData>
                        <ButtonWrapper>
                          <ButtonCom
                            label={'Borrar búsqueda'}
                            widthCustom={300}
                            onClick={handleSubmit(handleSearchClear)}
                          />
                        </ButtonWrapper>
                      </ContainNoData>
                    </ContainerNoData>
                  )
                ) : (
                  <div>
                    {dataPaths !== null ? (
                      <div>
                        <CourseCatalogHeaderTitles>
                          <CourseCatalogSubTitles>
                            <SubtitleFirst>Rutas Recomendadas</SubtitleFirst>
                            <SubtitleSecond onClick={() => handleSeeMore('paths')}>
                              {/* Ver más + */}
                            </SubtitleSecond>
                          </CourseCatalogSubTitles>
                        </CourseCatalogHeaderTitles>
                        <MyRoutesRows>
                          {dataPaths.results.map((route: any, indexRoute: any) => {
                            return (
                              <Col key={indexRoute}>
                                <Link to={`${AdminRole.learningPathPreview}/${route.id}`}>
                                  <Route
                                    name={route.name}
                                    image={route.photo}
                                    date={route.duration}
                                    courses={route.course_count}
                                    skills={route.skill_count}
                                    start_date={route.start_date}
                                    badge_count={route.course_count}
                                    organization_name={route.organization_name}
                                    duration={route.duration}
                                  />
                                </Link>
                              </Col>
                            );
                          })}
                        </MyRoutesRows>
                      </div>
                    ) : (
                      <ContainerNoData>
                        <ReactLoading type="spin" color={PRIMARY_COLOR} height={80} width={80} />
                      </ContainerNoData>
                    )}
                    {dataContent !== null ? (
                      <div>
                        <CourseCatalogSubTitles>
                          <SubtitleFirst></SubtitleFirst>
                          <SubtitleSecond onClick={() => handleSeeMore('organizationCourseT')}>
                            {/* Ver más + */}
                          </SubtitleSecond>
                        </CourseCatalogSubTitles>
                        <MyRoutesRows>
                          <Col >
                            <RouteContainerFirst>
                              {user.userInfo !== null && (
                                <TitleCard>
                                  Contenido de {user.userInfo.organization.name}
                                </TitleCard>
                              )}
                            </RouteContainerFirst>
                          </Col>
                          {dataContent.results.map((route: any, indexRoute: any) => {
                            return (
                              <Col  key={indexRoute}>
                                <Link to={`${AdminRole.learningPathPreview}/${route.id}`}>
                                  <Route
                                    name={route.name}
                                    image={route.photo}
                                    date={route.duration}
                                    courses={route.course_count}
                                    skills={route.skill_count}
                                    start_date={route.start_date}
                                    badge_count={route.course_count}
                                    organization_name={route.organization_name}
                                    duration={route.duration}
                                  />
                                </Link>
                              </Col>
                            );
                          })}
                        </MyRoutesRows>
                      </div>
                    ) : (
                      <ContainerNoData>
                        <ReactLoading type="spin" color={PRIMARY_COLOR} height={80} width={80} />
                      </ContainerNoData>
                    )}
                    {courseRecomended !== null ? (
                      <div>
                        <CourseCatalogHeaderTitles>
                          <CourseCatalogSubTitles>
                            <SubtitleFirst>Cursos Populares</SubtitleFirst>
                            <SubtitleSecond onClick={() => handleSeeMore('popularCourseT')}>
                              Ver más +
                            </SubtitleSecond>
                          </CourseCatalogSubTitles>
                        </CourseCatalogHeaderTitles>
                        {/* <CourseCatalogSubTitles>

                          <SubtitleFirst>Cursos Populares</SubtitleFirst>
                          <SubtitleSecond onClick={() => handleSeeMore('popularCourseT')}>
                            Ver más +
                          </SubtitleSecond>
                        </CourseCatalogSubTitles> */}
                        <MyRoutesRows>
                          {courseRecomended.results.map((course: any, indexRoute: any) => {
                            return (
                              <Col  key={indexRoute}>
                                <Link to={`${AdminRole.coursePreview}/${course.id}`}>
                                  <CourseCard
                                    name={course.name}
                                    image={course.image_url}
                                    company={course.provider_name}
                                    start={course.start_date}
                                    score={course.score}
                                    skills={course.skill_count}
                                    duration={course.duration}
                                    price={course.cost}
                                    companyColor={'003DA6'}
                                  />
                                </Link>
                              </Col>
                            );
                          })}
                        </MyRoutesRows>
                      </div>
                    ) : (
                      <ContainerNoData>
                        <ReactLoading type="spin" color={PRIMARY_COLOR} height={80} width={80} />
                      </ContainerNoData>
                    )}
                    <br />
                    <br />
                  </div>
                )}
              </React.Fragment>
            ) : (
              <ContainerNoData>
                <ReactLoading type="spin" color={'black'} height={80} width={80} />
              </ContainerNoData>
            )}
          </CourseCatalogContainer>
        ) : (
          <AdminCourseCatalog
            courses={seeMoreData}
            hideNewCourseSection={() => setSeeMore(false)}
          />
        )
      ) : (
        <AdminUploadNewIndividualCourse
          handleCourseSubmit={handleNewCourseSubmit}
          hideNewCourseSection={() => setUploadNewCourse(false)}
        />
      )}
    </CommonPageLayout>
  );
};

export default AdminHomeCourseCatalog;
