import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const ShareMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MenuItemWrapper = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => (props.$selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
  svg {
    cursor: pointer;
  }
`;

export const MenuItemWrapperContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MenuItemTitle = styled(Label)<{ $selected: boolean }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.$selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
  margin-top: 10px;
  cursor: pointer;
`;
