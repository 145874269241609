import React from 'react';
import NewOrganizationForm from 'components/molecules/AdminCreateProfile/NewForm';
import NewOrganizationDetailsForm from 'components/molecules/AdminCreateProfile/NewDetailsForm';
import { useForm } from 'react-hook-form';
import { objectValidation } from 'utils';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'hooks/redux';
import { CreateNewThunk } from 'actions/Thunks/AdminThunk';
import {
  ColStyled,
  OrganizationProfileContainer,
  OrganizationProfileSectionsContainer,
} from './styles';
import { StepWizardProps } from 'react-step-wizard';

interface AdminOrganizationProfileProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const AcreditedProfile = ({ onNextStep }: AdminOrganizationProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const defaultValuesAdditionalInfo: INewInfoForm = {
    first_name: '',
    last_name: '',
    contact_email: '',
    completed_profile: false,
    avatar: null,
  };

  const getDefaultAdditionalInfoFormValues = (): INewInfoForm => {
    return defaultValuesAdditionalInfo;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<INewInfoForm>({
    ...objectValidation,
    defaultValues: getDefaultAdditionalInfoFormValues(),
  });

  const submitNewOrganizationForm = async (formInfo: INewInfoForm) => {
    if (formInfo.avatar) {
      const formInfoRequest: ICreateNew = {
        path_info: {
          first_name: formInfo.first_name,
          last_name: formInfo.last_name,
          contact_email: formInfo.contact_email,
          completed_profile: true,
        },
        path_photo: {
          avatar: formInfo.avatar,
        },
      };
      const newOrganization = await dispatch(CreateNewThunk(formInfoRequest));

      if (CreateNewThunk.fulfilled.match(newOrganization)) {
        toast.success('Se ha modificado el perfil con éxito');
        onNextStep();
      } else {
        toast.error(newOrganization.payload as string);
      }
    } else {
      const formInfoRequest: ICreateNew = {
        path_info: {
          first_name: formInfo.first_name,
          last_name: formInfo.last_name,
          contact_email: formInfo.contact_email,
          completed_profile: true,
        },
        path_photo: {
          avatar: null,
        },
      };
      const newOrganization = await dispatch(CreateNewThunk(formInfoRequest));

      if (CreateNewThunk.fulfilled.match(newOrganization)) {
        toast.success('Se ha modificado el perfil con éxito');
        onNextStep();
      } else {
        toast.error(newOrganization.payload as string);
      }
    }
  };

  return (
    <OrganizationProfileContainer>
      <OrganizationProfileSectionsContainer>
        <ColStyled xl="7" md="6" xs="12">
          <NewOrganizationForm
            controlInfo={control}
            handleSubmitInfoForm={handleSubmit}
            errorsInfo={errors}
            submitNewOrganizationForm={submitNewOrganizationForm}
            watch={watch}
            loading={false}
            setValue={setValue}
          />
        </ColStyled>
        <ColStyled xl="5" md="6" xs="12">
          <NewOrganizationDetailsForm
            controlBasicInfo={control}
            errorsBasicInfo={errors}
            handleSubmitBasicInfoForm={handleSubmit}
            watch={watch}
          />
        </ColStyled>
      </OrganizationProfileSectionsContainer>
    </OrganizationProfileContainer>
  );
};

export default AcreditedProfile;
