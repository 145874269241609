import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import OrganizationBadgesInfo from 'components/molecules/BadgesOrganizationInfo';
import {
  BadgesInfoContainer,
  OrganizationMenuContainer,
  OrganizationMenuItem,
  OrganizationMenuItemContainer,
  OrganizationMenuItemTitle,
  OrganizationMenuSubItem,
  BadgeTemplateCol,
  RowCardsStyled,
  BarSearchContainer,
  SearchIconContainer,
  RowStyled,
} from './styles';
import { Container } from 'reactstrap';
import Badge from 'components/atoms/Badge';
import {
  GetAllBadgesTemplatesDetailUnAuthOrgThunk,
  GetSearchBadgesTemplatesDetailUnAuthOrgThunk,
} from 'actions/Thunks/UnAuthUsersThunks';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AdminRole, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import { AdminDataThunk } from 'actions/Thunks/UserThunk';
import { GetOrganizationOverviewThunk } from 'actions/Thunks/AdminThunk';
import { useForm, Controller } from 'react-hook-form';
import TextInput from 'components/atoms/TextInput';
import icons from 'assets/Icon';
import { ENVIRONMENT_URL_BADGE, objectValidation } from 'utils/index';

interface OrganizationBadgesDashboardProps {
  unauthorized: boolean;
  showMenuHeader: boolean;
}

const OrganizationBadgesDashboard = ({
  unauthorized,
}: OrganizationBadgesDashboardProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [badgeTemplate, setBadgeTemplaeteInfo] = React.useState<any>(null);
  const [organizationInfo, setOrganizationInfo] = React.useState<any>(null);
  const [urlBadge, setUrlBadge] = React.useState('');

  const user = useAppSelector((state) => state.user);

  const defaultValuesSearch: SearchBarForm = {
    search: '',
  };

  const getDefaultFormSearch = (): SearchBarForm => {
    return defaultValuesSearch;
  };

  const getAdminInfo = React.useCallback(async () => {
    const adminInfoData = await dispatch(AdminDataThunk());
    if (AdminDataThunk.rejected.match(adminInfoData)) {
      toast.error(adminInfoData.payload as string);
    }
  }, [dispatch]);

  const getUnAuthBadgesList = React.useCallback(async () => {
    const badges = await dispatch(
      GetAllBadgesTemplatesDetailUnAuthOrgThunk(user.userInfo?.organization.id)
    );
    if (GetAllBadgesTemplatesDetailUnAuthOrgThunk.rejected.match(badges)) {
      toast(badges.payload as string);
    } else {
      setBadgeTemplaeteInfo(badges.payload);
      setUrlBadge(`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.url_badges}/`);
    }
  }, [user.userInfo?.organization.id, dispatch]);

  const getOrganizationOverview = React.useCallback(async () => {
    if (user.userInfo?.organization.id) {
      const organization = await dispatch(
        GetOrganizationOverviewThunk(user.userInfo?.organization.id.toString())
      );
      if (GetAllBadgesTemplatesDetailUnAuthOrgThunk.rejected.match(organization)) {
        toast(organization.payload as string);
      } else {
        setOrganizationInfo(organization.payload);
      }
    }
  }, [user.userInfo?.organization.id, dispatch]);

  const handleUserKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>, search: string) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(() => handleSearch(search))();
    }
  };

  const handleSearch = React.useCallback(
    async (search: string) => {
      if (user.userInfo?.organization.id) {
        const info = {
          id: user.userInfo?.organization.id,
          search: search,
        };
        const badges = await dispatch(GetSearchBadgesTemplatesDetailUnAuthOrgThunk(info));
        if (GetSearchBadgesTemplatesDetailUnAuthOrgThunk.rejected.match(badges)) {
          toast(badges.payload as string);
        } else {
          setBadgeTemplaeteInfo(badges.payload);
        }
      }
    },
    [user.userInfo?.organization.id, dispatch]
  );

  const handleSearchClear = React.useCallback(async () => {
    if (user.userInfo?.organization.id) {
      const info = {
        id: user.userInfo?.organization.id,
        search: '',
      };
      const badges = await dispatch(GetSearchBadgesTemplatesDetailUnAuthOrgThunk(info));
      if (GetSearchBadgesTemplatesDetailUnAuthOrgThunk.rejected.match(badges)) {
        toast(badges.payload as string);
      } else {
        reset();
        setBadgeTemplaeteInfo(badges.payload);
      }
    }
    // eslint-disable-next-line
  }, [user.userInfo?.organization.id, dispatch]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<SearchBarForm>({
    ...objectValidation,
    defaultValues: getDefaultFormSearch(),
  });

  React.useEffect(() => {
    getAdminInfo();
    getUnAuthBadgesList();
    getOrganizationOverview();
  }, [unauthorized, getUnAuthBadgesList, getAdminInfo, getOrganizationOverview]);

  React.useEffect(() => {
    getOrganizationOverview();
  }, [user.userInfo?.organization.id, getOrganizationOverview]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <Container>
        <BadgesInfoContainer>
          {organizationInfo && (
            <OrganizationBadgesInfo
              description={organizationInfo.description}
              organization_name={organizationInfo.name}
              organization_email={organizationInfo.email}
              organization_website={organizationInfo.domain}
              organization_icon={organizationInfo.photo}
              organization_facebook={organizationInfo.url_facebook}
              organization_twitter={organizationInfo.url_linkedin}
              organization_linkedin={organizationInfo.url_twitter}
            />
          )}
        </BadgesInfoContainer>
        <OrganizationMenuContainer>
          <OrganizationMenuItemContainer>
            {organizationInfo && (
              <OrganizationMenuItem>{organizationInfo.badge_template_count}</OrganizationMenuItem>
            )}
            <OrganizationMenuItemTitle>Insignias</OrganizationMenuItemTitle>
          </OrganizationMenuItemContainer>
          <OrganizationMenuItemContainer>
            {organizationInfo && organizationInfo.domain !== 'https://example.org' && (
              <OrganizationMenuSubItem target="_blank" href={organizationInfo.domain}>
                {organizationInfo.domain}
              </OrganizationMenuSubItem>
            )}
          </OrganizationMenuItemContainer>
        </OrganizationMenuContainer>
        <RowStyled>
          <BarSearchContainer sm="12">
            <div>
              <Controller
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    name="search"
                    error={errors}
                    height={35}
                    placeholder="¿Qué insignia estás buscando?"
                    value={value}
                    onChange={onChange}
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      handleUserKeyPressSearch(e, value)
                    }
                  />
                )}
                name="search"
              />
              <SearchIconContainer onClick={handleSubmit(handleSearchClear)}>
                <icons.CleanSearchIcon />
              </SearchIconContainer>
            </div>
          </BarSearchContainer>
        </RowStyled>
        <RowCardsStyled>
          {badgeTemplate &&
            badgeTemplate &&
            badgeTemplate.data.map((badge: any, index: number) => {
              return (
                <BadgeTemplateCol key={index}>
                  {badge && (
                    <Link to={`${AdminRole.templateBadgeDetail}/${badge.id}`}>
                      <Badge
                        typeBadge={badge.accepted_at === null ? true : false}
                        isPublicBadge={badge.public}
                        id={badge.id}
                        name={badge.name}
                        image={badge.image.url}
                        company={badge.owner.name}
                        badge_organization={true}
                        nonAuth={!unauthorized}
                        date={badge.accepted_at}
                        dateExpired={badge.expires_at}
                        url={urlBadge}
                      />
                    </Link>
                  )}
                </BadgeTemplateCol>
              );
            })}
        </RowCardsStyled>
      </Container>
    </CommonPageLayout>
  );
};

export default OrganizationBadgesDashboard;
