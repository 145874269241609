import * as React from 'react';
import {
  MySkillsContainer,
  MySkillsTitle,
  MySkillsTitleContainer,
  MySkillsContainerBox,
} from 'components/molecules/MySkills/styles';
import { Col } from 'reactstrap';
import { S3_INTEREST } from 'constants/index';
import InterestCard from 'components/atoms/InterestCardProfile';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { InterestListProfileThunk, InterestListThunk } from 'actions/Thunks/OnboardingThunk';
import toast from 'react-hot-toast';

const MySkills: React.FC = () => {
  const onboarding = useAppSelector((state) => state.interestsProfile);
  const dispatch = useAppDispatch();

  const getInterestList = React.useCallback(async () => {
    const interestList = await dispatch(InterestListProfileThunk());

    if (InterestListThunk.rejected.match(interestList)) {
      toast.error(interestList.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    getInterestList();
  }, [getInterestList]);

  return (
    <MySkillsContainerBox>
      <MySkillsTitleContainer>
        <MySkillsTitle>Me Interesa</MySkillsTitle>
        <MySkillsContainer gap="0.5rem">
          {onboarding.interestsProfile !== null ? (
            <React.Fragment>
              {onboarding.interestsProfile.map((interest: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <Col lg="3" sm="6" xs="12" md="4" style={{ marginTop: 19 }}>
                      <InterestCard
                        title={interest.name}
                        image={`${S3_INTEREST}${interest.id}.svg`}
                      />
                    </Col>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ) : (
            <h1>Loading...</h1>
          )}
        </MySkillsContainer>
      </MySkillsTitleContainer>
    </MySkillsContainerBox>
  );
};

export default MySkills;
