import { Modal, ModalBody } from 'reactstrap';
import icons from '../../../assets/Icon/index';
import MessageProfileNotCompleted from '../MessageProfileNotCompleted';

type Props = {
  show: boolean;
  toogle: () => void;
};

const ModalBeforeBadgeEmit = ({ show, toogle }: Props) => {
  return (
    <Modal isOpen={show} centered size="lg">
      <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <span onClick={toogle} className="cursor-pointer">
          <icons.CloseIcon />
        </span>
      </div>
      <ModalBody style={{ background: 'white', padding: 0, marginBottom: '25px' }}>
        <MessageProfileNotCompleted />
      </ModalBody>
    </Modal>
  );
};

export default ModalBeforeBadgeEmit;
