import AcredittaText from 'components/atoms/AcredittaText';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuI } from '../../interfaces/menu';
import { MenuContext } from '../../context/MenuContextProvider';

interface Props {
  element: MenuI;
}

const ItemMenuMobile = ({ element }: Props) => {
  const [show, setShow] = useState(false);
  const { text, subItems, path } = element;
  const { toogleMenu } = useContext(MenuContext);

  return (
    <>
      {!subItems ? (
        <div className="item-menu" onClick={toogleMenu}>
          <Link to={path}>
            <AcredittaText variant="h5">{text}</AcredittaText>
          </Link>
        </div>
      ) : (
        <>
          <div className="item-menu" onClick={() => setShow(!show)}>
            <AcredittaText variant="h5">
              {text} {subItems && subItems.length > 0 && (!show ? '\u2304' : '\u2303')}
            </AcredittaText>
          </div>
          {subItems &&
            subItems.map((el) => (
              <div
                className="subitem-menu animate__animated animate__fadeIn animate_slow"
                style={{ display: show ? 'block' : 'none' }}
                onClick={toogleMenu}
                key={el.id}
              >
                <Link to={el.path}>
                  <AcredittaText variant="body1" color="secondary" fontWeight="600">
                    {el.text}
                  </AcredittaText>
                </Link>
              </div>
            ))}
        </>
      )}
    </>
  );
};

export default ItemMenuMobile;
