import icons from 'assets/Icon';
import { Progress } from 'reactstrap';
import styled from 'styled-components';

export const UploadUserProgressWrapper = styled.div`
  display: flex;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
`;

export const ProgressStyled = styled(Progress)`
  height: 20px;
  border-radius: 15px;
`;

export const CancelUploadingWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: 14px;
  cursor: pointer;
`;

export const CancelUploadingIcon = styled(icons.CloseIcon)``;
