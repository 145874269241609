import * as Sentry from '@sentry/react';
import { API_URL, IntegrationsUrls, PublicUrls } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetBadgeDetailUnAuthUser(badge_id: any): Promise<any> {
  try {
    const badgeDetail = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${IntegrationsUrls.getBadgeDetail}${badge_id}`
    );
    if (badgeDetail.okay && badgeDetail.data) {
      return badgeDetail.data;
    } else {
      throw Error(badgeDetail.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeDetailAuthUser(badge_id: any): Promise<any> {
  try {
    const badgeDetail = await new HttpRequest().Get<any>(
      `${API_URL}${IntegrationsUrls.getBadgeDetail}${badge_id}`
    );
    if (badgeDetail.okay && badgeDetail.data) {
      return badgeDetail.data;
    } else {
      throw Error(badgeDetail.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeDetailAuthUserTemp(badge_id: any): Promise<any> {
  try {
    const badgeDetail = await new HttpRequest().Get<any>(
      `${API_URL}${IntegrationsUrls.listBadges}${badge_id}`
    );
    if (badgeDetail.okay && badgeDetail.data) {
      return badgeDetail.data;
    } else {
      throw Error(badgeDetail.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeTemplateDetailUnAuthUser(badge_id: any): Promise<any> {
  try {
    const badgeTemplateDetail = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${PublicUrls.detailBadgeOrg}${badge_id}`
    );
    if (badgeTemplateDetail.okay && badgeTemplateDetail.data) {
      return badgeTemplateDetail.data;
    } else {
      throw Error(badgeTemplateDetail.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetAllBadgesTemplatesDetailUnAuthOrg(organization_id: number): Promise<any> {
  try {
    const badges = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${PublicUrls.organizationBadges}?organization_id=${organization_id}`
    );
    if (badges.okay && badges.data) {
      return badges.data;
    } else {
      throw Error(badges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationInfo(): Promise<any> {
  try {
    const organization = await new HttpRequest().UnAuthGet<any>(`${API_URL}organization/profile/`);
    if (organization.okay && organization.data) {
      return organization.data;
    } else {
      throw Error(organization.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function VerifyBadge(badge_id: string): Promise<any> {
  try {
    const badge = await new HttpRequest().PublicGet<any>(
      `${API_URL}${PublicUrls.verifyBadge}${badge_id}`
    );
    if (badge.okay && badge.data) {
      return badge.data;
    } else {
      throw Error(badge.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetSearchBadgesTemplatesDetailUnAuthOrg(info: IBadgesSearch): Promise<any> {
  try {
    const badges = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${PublicUrls.organizationBadges}?organization_id=${info.id}&search=${info.search}`
    );
    if (badges.okay && badges.data) {
      return badges.data;
    } else {
      throw Error(badges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
