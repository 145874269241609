import React, { useState } from 'react';
import Button from 'components/atoms/Button';
import {
  NewCourseFormContainer,
  NewCourseInstructionsTitle,
  FormGroupStyled,
  FormLabelSimpleWrapper,
  FormInputWrapper,
  FormLabelSimple,
  ButtonsContainer,
  InputSelectContainer,
  ButtonWrapper,
  FormGroupDatesStyled,
  FormLabelSimpleDates,
  InputWrapper,
  ErrorLabel,
  StyledDatePicker,
} from './styles';
import TextInput from 'components/atoms/TextInput';
import InputSelect from 'components/atoms/InputSelect';
import { Control, UseFormHandleSubmit, Controller, UseFormWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  CountryListThunk,
  GetLanguagesListThunk,
  ListDocumentTypesThunk,
  ListInterestOrganizationThunk,
} from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import TextInputMax from 'components/atoms/TextInputMax';
import { AdminRole } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';
import {
  CheckboxWrapper,
  ErrorMessageLabel,
  InputLabelWrapper,
} from '../AdminCreateUserCard/styles';
import InputRadioOption from 'components/atoms/InputRadioOption';
import { Col, Input } from 'reactstrap';
import { AdminDataThunk } from 'actions/Thunks/UserThunk';
import InputSelectTagsIssueBadge from 'components/atoms/InputTags/InputSelectTagsIssueBadge';
import validator from 'validator';
import AcredittaTooltip from '../../atoms/AcredittaTooltip.tsx/index';
import AcredittaText from 'components/atoms/AcredittaText';

interface AdminUploadNewCourseFormProps {
  controlInfo: Control<any>;
  handleSubmitForm: UseFormHandleSubmit<IUploadNewCourse>;
  errorsInfo: Record<string, any>;
  onCreate: (issueInfo: any) => void;
  watch: UseFormWatch<INewIssueControl>;
  submitNewIssueForm: any;
  provider?: string;
}

const AdminUploadNewBadgeForm = ({
  controlInfo,
  handleSubmitForm,
  errorsInfo,
  onCreate,
  watch,
  provider,
}: AdminUploadNewCourseFormProps): JSX.Element => {
  const [tagsSelected, setTagsSelected] = useState([]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const country = watch().country;
  const date_i = watch().start_date;

  const [documentTypeListData, setDocumentTypeListData] = React.useState<IDocumentType[] | null>(
    null
  );
  const [disabled, setDisabled] = React.useState<boolean>(true);

  const user = useAppSelector((state) => state.user);

  const handleBadgeDetail = () => {
    history.push(`${AdminRole.badgesPreview}`);
  };

  const getTagsInputValues = (values: any) => {
    setTagsSelected(values);
  };
  const handleDiabled = () => {
    setDisabled(!disabled);
  };

  const loadLanguages = () => {
    return new Promise((resolve) => {
      dispatch(GetLanguagesListThunk()).then((languages) => {
        if (GetLanguagesListThunk.fulfilled.match(languages)) {
          resolve(languages.payload);
        } else {
          toast.error(languages.payload as string);
        }
      });
    });
  };

  const loadCountries = () => {
    return new Promise((resolve) => {
      dispatch(CountryListThunk()).then((countries) => {
        if (CountryListThunk.fulfilled.match(countries)) {
          resolve(countries.payload);
        } else {
          toast.error(countries.payload as string);
        }
      });
    });
  };

  const documentTypeList = React.useCallback(
    async (country: ISelectOptionValue | null) => {
      if (country && country.value) {
        const documentList = await dispatch(ListDocumentTypesThunk(country.value));

        if (ListDocumentTypesThunk.fulfilled.match(documentList)) {
          setDocumentTypeListData(documentList.payload);
        } else {
          toast.error(documentList.payload as string);
        }
      }
    },
    [dispatch]
  );

  const getInterestOrganization = React.useCallback(async () => {
    const interests = await dispatch(ListInterestOrganizationThunk());
    if (ListInterestOrganizationThunk.rejected.match(interests)) {
      toast.error(interests.payload as string);
    }
  }, [dispatch]);

  const getAdminInfo = React.useCallback(async () => {
    const adminInfoData = await dispatch(AdminDataThunk());
    if (AdminDataThunk.rejected.match(adminInfoData)) {
      toast.error(adminInfoData.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (user.adminInfo === null) {
      getAdminInfo();
    }
    getInterestOrganization();
    documentTypeList(country);
  }, [user.adminInfo, getAdminInfo, getInterestOrganization, country, documentTypeList]);

  return (
    <NewCourseFormContainer>
      <NewCourseInstructionsTitle>
        Completa la información para emitir esta insignia
      </NewCourseInstructionsTitle>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple>Nombres *</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
              />
            )}
            name="name"
            rules={{
              required: true,
            }}
          />
          {errorsInfo.name && <ErrorLabel>Campo requerido</ErrorLabel>}
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="lastName">Apellidos *</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="lastName"
                placeholder=""
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
              />
            )}
            name="lastName"
            rules={{
              required: 'Campo requerido',
            }}
          />
          {errorsInfo.lastName && <ErrorLabel>Campo requerido</ErrorLabel>}
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="email">Correo *</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="email"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
              />
            )}
            name="email"
            rules={{
              required: 'Campo requerido',
            }}
          />
          {errorsInfo.email && <ErrorLabel>Campo requerido</ErrorLabel>}
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="country">País</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <div style={{ width: '100%' }}>
                <InputSelect
                  onChange={(value) => onChange(value)}
                  value={value}
                  loadOptions={loadCountries}
                  placeholder=""
                  asyncSelect={true}
                  error={errorsInfo}
                  name="country"
                  defaultOptions
                />
              </div>
            )}
            name="country"
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple>Identificación</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errorsInfo}
                name="document"
                type="text"
                onChange={(value) => onChange(value)}
                value={value ? value : undefined}
                height={38}
                withError
              />
            )}
            name="document"
          />
        </FormInputWrapper>
      </FormGroupStyled>
      {documentTypeListData && (
        <InputWrapper>
          <InputLabelWrapper />
          <CheckboxWrapper>
            <Controller
              control={controlInfo}
              render={({ field: { onChange } }) => (
                <React.Fragment>
                  {documentTypeListData.map((documentType, index) => (
                    <Col sm={documentTypeListData.length > 1 ? 6 : 12} key={index}>
                      <InputRadioOption
                        label={documentType.name}
                        value={documentType.id}
                        onChange={(value) => onChange(value)}
                      />
                    </Col>
                  ))}
                  {errorsInfo['typeDocument']?.type && (
                    <Col sm={12}>
                      <ErrorMessageLabel>{errorsInfo['typeDocument']?.message}</ErrorMessageLabel>
                    </Col>
                  )}
                </React.Fragment>
              )}
              name="typeDocument"
            />
          </CheckboxWrapper>
        </InputWrapper>
      )}

      <FormGroupDatesStyled style={{ zIndex: 999 }}>
        <FormLabelSimpleDates>Fecha de Emisión*</FormLabelSimpleDates>
        <div>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <StyledDatePicker
                onChange={onChange}
                value={value}
                locale="es-419"
                minDate={provider === 'Credly' ? new Date() : undefined}
              />
            )}
            name="start_date"
            rules={{
              required: 'Campo requerido',
            }}
          />
          {errorsInfo.start_date && <ErrorLabel>Campo requerido</ErrorLabel>}
        </div>
        <FormLabelSimpleDates style={{ marginRight: '15px' }}>
          <Input
            addon
            aria-label="Checkbox for following text input"
            type="checkbox"
            onChange={handleDiabled}
            style={{ marginRight: '15px' }}
          />
          Fecha de Expiración
        </FormLabelSimpleDates>
        <Controller
          control={controlInfo}
          name="end_date"
          render={({ field: { onChange, value } }) => (
            <StyledDatePicker
              disabled={disabled}
              onChange={onChange}
              value={value}
              locale="es-419"
              minDate={date_i ? date_i : new Date()}
            />
          )}
        />
      </FormGroupDatesStyled>

      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple>Evidencia</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errorsInfo}
                name="nombreEvidencia"
                type="text"
                onChange={(value) => onChange(value)}
                value={value}
                placeholder="Nombre de la evidencia"
                height={38}
                withError
              />
            )}
            name="nombreEvidencia"
          />
        </FormInputWrapper>
      </FormGroupStyled>

      <FormGroupStyled row style={{ marginBottom: 10 }}>
        <FormLabelSimpleWrapper>
          <FormLabelSimple>&nbsp;</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errorsInfo}
                name="descripcionEvidencia"
                type="textarea"
                onChange={(value) => onChange(value)}
                value={value}
                placeholder="Descripción de la evidencia"
                height={100}
                characterCounter={500}
                withError
              />
            )}
            name="descripcionEvidencia"
          />
        </FormInputWrapper>
      </FormGroupStyled>

      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple>&nbsp;</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errorsInfo}
                name="enlaceComprobacion"
                type="text"
                onChange={(value) => onChange(value)}
                value={value}
                placeholder="Enlace de comprobación de la evidencia"
                height={38}
                withError
              />
            )}
            rules={{
              validate: {
                isValidUrl: (value) => {
                  if (!value) return true;

                  return validator.isURL(value) || 'Ingrese una url valida';
                },
              },
            }}
            name="enlaceComprobacion"
          />
          {errorsInfo.enlaceComprobacion && (
            <ErrorLabel>{errorsInfo.enlaceComprobacion.message}</ErrorLabel>
          )}
        </FormInputWrapper>
      </FormGroupStyled>

      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="language">Idioma</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InputSelectContainer>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <div style={{ width: '100%' }}>
                  <InputSelect
                    onChange={(value) => onChange(value)}
                    value={value}
                    loadOptions={loadLanguages}
                    placeholder=""
                    asyncSelect={true}
                    error={errorsInfo}
                    name="language"
                    defaultOptions
                  />
                </div>
              )}
              name="language"
            />
          </InputSelectContainer>
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <AcredittaTooltip mainNode={<FormLabelSimple for="etiquetas">Etiquetas</FormLabelSimple>}>
            <AcredittaText variant="body3" color="secondary">
              Las etiquetas permiten clasificar un acreditado por medio de atributos introducir
              información adicional, hacer selecciones o filtrar usuarios por contenidos especificos
            </AcredittaText>
          </AcredittaTooltip>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InputSelectContainer>
            <InputSelectTagsIssueBadge onChangeInputValues={getTagsInputValues} />
          </InputSelectContainer>
        </FormInputWrapper>
      </FormGroupStyled>
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            label={'Cancelar'}
            lightMode={true}
            widthCustom={150}
            onClick={() => handleBadgeDetail()}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            label={'Emitir'}
            widthCustom={150}
            onClick={handleSubmitForm((template_form) => {
              onCreate({
                ...template_form,
                tags: tagsSelected.map((tag: any) => tag.id),
              });
            })}
          />
        </ButtonWrapper>
      </ButtonsContainer>
    </NewCourseFormContainer>
  );
};

export default AdminUploadNewBadgeForm;
