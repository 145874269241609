import icons from 'assets/Icon';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CustomSearchDiv = styled.div`
  background-color: #002057;
  border-radius: 6px;
`;

interface Props {
  onChangeInput: (value: string) => void;
}

const AcredittaSearchInput = ({ onChangeInput }: Props) => {
  const [value, setValue] = useState('');
  const timeRef = useRef(null) as any;

  useEffect(() => {
    timeRef.current = setTimeout(() => {
      onChangeInput(value);
    }, 800);

    return () => {
      clearTimeout(timeRef.current);
    };
    // eslint-disable-next-line
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <CustomSearchDiv>
      <div className="col-sm-12">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ padding: '10px' }}>
            <icons.FilterIcon style={{ width: 22, height: 22 }} />
          </div>
          <div style={{ flex: '1 auto', padding: '10px 0' }}>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                style={{ borderLeft: 0, borderRight: 0 }}
                placeholder="Buscar..."
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </CustomSearchDiv>
  );
};

export default AcredittaSearchInput;
