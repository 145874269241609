import styled from 'styled-components';
import { Col, Container, Label, Row } from 'reactstrap';
import { BLUE_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const CoursesCatalogContainer = styled(Container)`
  @media ${device.laptops} {
    max-width: 90%;
  }
`;

export const ContainNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubtitleFirstNoData = styled(Label)`
  color: ${PRIMARY_COLOR};
  padding: 10px;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 26px;
`;

export const SubtitleSecondNoData = styled(Label)`
  color: ${BLUE_LIGHT};
  padding: 10px;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const SubtitleSecond = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
`;

export const ContainerNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CoursesSectionCatalogContainer = styled(Row)``;

export const CourseCatalogHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CourseCatalogSubTitles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
`;

export const TitleWrapper = styled(Col)`
  text-align: center;
  margin: 20px 0;
`;

export const Title = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  @media ${device.bigMobile} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const BarSearchContainer = styled(Col)`
  display: grid;
  grid-template-columns: 2% 98%;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  background-color: ${PRIMARY_COLOR};
  margin-bottom: 20px;

  @media ${device.laptops} {
    grid-template-columns: 4% 96%;
  }
  @media ${device.bigMobile} {
    grid-template-columns: 6% 94%;
  }
  @media ${device.smallMobile} {
    grid-template-columns: 8% 92%;
  }
`;

export const ColStyled = styled(Col)`
  margin-top: 30px;
`;

export const RowStyled = styled(Row)`
  margin: 15px 2px;
`;

export const SearchIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 18px;
  svg {
    width: 15px;
  }
`;

export const IconFilterWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
`;

export const SubtitleFirst = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
  cursor: pointer;
`;
