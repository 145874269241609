import * as React from 'react';
import AdminSkillsList from 'components/molecules/AdminSkillsList';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  AdminCoverSection,
  RouteInfoWrapper,
  RouteSubTitle,
  RouteShareTitle,
  ButtonWrapper,
  RouteShareSectionWrapper,
  CourseContentTitle,
  CourseItemTitle,
  CourseSessionTime,
  CourseItemContainer,
  CourseItemContainerGroup,
} from './styles';
import AdminLearningCoursePreviewDescription from '../../molecules/AdminLearningCoursePreviewDescription';
import { DescriptionCourseThunk } from 'actions/Thunks/DescriptionCourseThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { toast } from 'react-hot-toast';
import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';

interface AdminCoursePreviewProps {
  courseId: string;
}

const AdminCoursePreview = ({ courseId }: AdminCoursePreviewProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user.userInfo);

  const userRol = useAppSelector((state) => state.user.userRole);
  const dataCourse = useAppSelector((state) => state.coursePreview);

  const getCourseData = React.useCallback(async () => {
    const courseData = await dispatch(DescriptionCourseThunk(parseInt(courseId)));
    if (DescriptionCourseThunk.rejected.match(courseData)) {
      toast.error(courseData.payload as string);
    }
  }, [courseId, dispatch]);

  const copy = () => {
    navigator.clipboard.writeText(dataCourse.coursePreview.url);
    toast.success('Link del curso copiado');
  };

  React.useEffect(() => {
    getCourseData();
  }, [getCourseData]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showMenuHeaderAcredited={user?.user_type.code === 'ADMIN' ? false : true}
      showCommon={true}
    >
      {dataCourse.coursePreview !== null ? (
        <React.Fragment>
          <AdminCoverSection>
            <img
              src="https://acreditta-rutas.s3.amazonaws.com/static/Imagen-banner-curso.jpg"
              alt="imagen-de-portada"
            />
            <RouteInfoWrapper>
              <p
                style={{
                  maxWidth: '95%',
                  textAlign: 'center',
                  fontFamily: 'NotoSansJPBold',
                  fontSize: '40px',
                  lineHeight: '68px',
                  color: '#ffffff',
                }}
              >
                {dataCourse.coursePreview.name}
              </p>
              <ButtonWrapper>
                {userRol !== 3 ? (
                  <a href={dataCourse.coursePreview.url} target="_blank" rel="noopener noreferrer">
                    <Button label="Ver este curso" widthCustom={378} />
                  </a>
                ) : (
                  <a href={dataCourse.coursePreview.url} target="_blank" rel="noopener noreferrer">
                    <Button label="Regístrate ahora" widthCustom={378} />
                  </a>
                )}
              </ButtonWrapper>
              <RouteSubTitle>¿Conoces a alguien que le gustaría este curso?</RouteSubTitle>
              <RouteShareSectionWrapper>
                <RouteShareTitle onClick={copy}>
                  ¡Compártelo!
                  <icons.UploadFileIcon />
                </RouteShareTitle>
              </RouteShareSectionWrapper>
            </RouteInfoWrapper>
          </AdminCoverSection>
          {dataCourse.coursePreview.image === null ? (
            <AdminLearningCoursePreviewDescription
              provider={dataCourse.coursePreview.provider}
              start={dataCourse.coursePreview.start_date}
              content={dataCourse.coursePreview.cost}
              description={dataCourse.coursePreview.description}
              duration={dataCourse.coursePreview.duration}
              users={dataCourse.coursePreview.user_count}
              level={dataCourse.coursePreview.level}
              language={dataCourse.coursePreview.language}
              methodology={dataCourse.coursePreview.modality}
              isPrivate={dataCourse.coursePreview.is_private}
              image={dataCourse.coursePreview.image_url}
            />
          ) : (
            <AdminLearningCoursePreviewDescription
              provider={dataCourse.coursePreview.provider}
              start={dataCourse.coursePreview.start_date}
              content={dataCourse.coursePreview.cost}
              description={dataCourse.coursePreview.description}
              duration={dataCourse.coursePreview.duration}
              users={dataCourse.coursePreview.user_count}
              level={dataCourse.coursePreview.level}
              language={dataCourse.coursePreview.language}
              methodology={dataCourse.coursePreview.modality}
              isPrivate={dataCourse.coursePreview.is_private}
              image={dataCourse.coursePreview.image}
            />
          )}
          <AdminSkillsList skills={dataCourse.coursePreview.skill} />
          <CourseContentTitle>Contenido del curso</CourseContentTitle>

          <CourseItemContainer>
            <Card>
              <CardBody>
                <ListGroup numbered>
                  {dataCourse.coursePreview.content.map((content: any, index: any) => (
                    <ListGroupItem key={index}>
                      <CourseItemContainerGroup>
                        <div>
                          <CourseItemTitle>{content.name}</CourseItemTitle>
                        </div>
                        <div>
                          {content.duration !== null ? (
                            <CourseSessionTime>
                              Tiempo Total: <span>{content.duration}</span>
                            </CourseSessionTime>
                          ) : (
                            <CourseSessionTime>
                              Tiempo Total: <span>Proximamente</span>
                            </CourseSessionTime>
                          )}
                        </div>
                      </CourseItemContainerGroup>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </CourseItemContainer>
        </React.Fragment>
      ) : (
        <h4>Loading..</h4>
      )}
    </CommonPageLayout>
  );
};

export default AdminCoursePreview;
