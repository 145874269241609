import {
  CardDashboardAdminInfo,
  CardMostViewedBadge,
  CardShareBadgeResume,
  BadgeOverview,
  BadgeCarousel,
} from 'components/atoms/HomeDashboard';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import AcredittaButton from 'components/atoms/AcredittaButton';
import { useHistory } from 'react-router-dom';
import { AdminRole } from '../../constants/NavigationUrls';
import { useEffect, useState } from 'react';
import { getShareData } from '../../services/analytics.service';
import {
  FacebookLogoPng,
  LinkedinLogoPng,
  MailLogoPng,
  TwitterLogoPng,
} from 'components/atoms/HomeDashboard/components/SocialsLogo';

interface ShareDataResumeI {
  id: number;
  total: any;
  network: string;
  icon: () => JSX.Element;
}

function createShareData(data: any): ShareDataResumeI[] {
  const { linkedin_post, facebook, twitter, mail } = data;
  return [
    { id: 1, total: linkedin_post, network: 'Linkedin', icon: LinkedinLogoPng },
    { id: 2, total: twitter, network: 'Twitter', icon: TwitterLogoPng },
    { id: 3, total: facebook, network: 'Facebook', icon: FacebookLogoPng },
    { id: 4, total: mail, network: 'Correo', icon: MailLogoPng },
  ];
}

const HomeDashboardPage = () => {
  const { push } = useHistory();
  const [shareDataResume, setShareDataResume] = useState<ShareDataResumeI[]>([]);
  const [totalShares, setTotalShares] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getShareData();
        const data = resp.data.results[0];
        setTotalShares(data.total_shares);
        setShareDataResume(createShareData(data));
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <div className="container">
        <div className="row">
          <div className="col col-md-6">
            {/* Resume info */}
            <CardDashboardAdminInfo />
          </div>
          <div className="col col-md-6 text-right">
            <div
              className="d-flex justify-content-end align-items-center"
              style={{ height: '100%' }}
            >
              <AcredittaButton
                onPress={() => push(AdminRole.createBadge)}
                title="Crear insignia"
                type="button"
              />
            </div>
          </div>
        </div>

        {/* Resume badges */}
        <BadgeOverview totalShares={totalShares} />

        {/* Carousel */}
        <BadgeCarousel />

        <div className="row mt-4">
          <div className="col col-md-6" style={{ padding: '20px 0' }}>
            {/* Badge more viewed */}
            <CardMostViewedBadge />
          </div>

          <div className="col col-md-6" style={{ marginTop: '15px' }}>
            <div className="row">
              {shareDataResume.map(({ id, icon, network, total }) => (
                <div className="col col-md-6" key={id}>
                  {/* Shared resume in socials networks */}
                  <CardShareBadgeResume socialNetwork={network} icon={icon} total={total} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '100px' }} />
    </CommonPageLayout>
  );
};

export default HomeDashboardPage;
