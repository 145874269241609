import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { StepWizardProps } from 'react-step-wizard';
import Button from 'components/atoms/Button';
import {
  Step,
  StepSection,
  ButtonContainer,
  InterestContainer,
  ModalBodySelect,
  ModalFooterSkills,
  ModalButtonWrapper,
} from './styles';
import {
  StepContainer,
  StepTitle,
  StepSubtitle,
} from 'components/molecules/OnBoardingSteps/CommonStyles/styles';
import {
  setCleanSkills,
  setInsertNewInterest,
  setInsertNewSkill,
  setRemoveSkill,
} from 'states/slices/onboarding.slice';
import {
  CreateOnboardingThunk,
  InterestListThunk,
  SkillListThunk,
} from 'actions/Thunks/OnboardingThunk';
import { S3_INTEREST } from 'constants/index';
import toast from 'react-hot-toast';
import SkeletonInterest from 'components/atoms/SkeletonInterest';
import { Col, Modal } from 'reactstrap';
import InterestCard from 'components/atoms/InterestCard';
import SelectSkill from 'components/molecules/SelectSkill';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';

interface Step1Props extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const Step1: React.FC<Step1Props> = ({ onNextStep }) => {
  const onboarding = useAppSelector((state) => state.onboarding);
  const dispatch = useAppDispatch();

  const responsive = useMediaQuery({ query: device.smallMobile });

  const [activeItem, setActiveItem] = React.useState<number | null>(null);
  const [interestName, setInterestName] = React.useState<IInterest | null>(null);
  const [modal, setModal] = React.useState(false);

  const selectInterest = async (index: number, interest: IInterest) => {
    setModal(!modal);
    setActiveItem(activeItem === index ? null : index);
    setInterestName(interest);

    if (!(activeItem !== index)) {
      dispatch(setCleanSkills());
    } else {
      const skills = await dispatch(SkillListThunk({ page: 1, interestId: interest.id }));

      if (SkillListThunk.rejected.match(skills)) {
        toast.error(skills.payload as string);
      }
    }
  };

  const onSelectSkill = (indexSkill: number, id: number) => {
    if (interestName !== null && activeItem !== null) {
      const matchInterest = onboarding.interestSelected.find((item) => item.id === interestName.id);

      if (matchInterest) {
        const findInterest = onboarding.interestSelected.find(
          (interest) => interest.id === interestName.id
        );

        if (findInterest) {
          const indexSkillsMatch = findInterest.skills.indexOf(id);

          if (indexSkillsMatch === -1) {
            dispatch(
              setInsertNewSkill({ index: indexSkill, item: id, interestId: interestName.id })
            );
          } else {
            dispatch(setRemoveSkill({ item: id, interestId: interestName.id }));
          }
        }
      } else {
        const interest: IInterestSelected = { id: interestName.id, skills: [] };
        dispatch(setInsertNewInterest({ index: activeItem, item: interest }));
        dispatch(setInsertNewSkill({ index: indexSkill, item: id, interestId: interestName.id }));
      }
    }
  };

  const saveUserOnBoarding = async () => {
    if (onboarding.interestSelected.length !== 0) {
      const interestSelected: ICreateInterestSkills = { interests: onboarding.interestSelected };

      const createOnBoarding = await dispatch(CreateOnboardingThunk(interestSelected));
      if (CreateOnboardingThunk.fulfilled.match(createOnBoarding)) {
        if (createOnBoarding.payload) {
          toast.success(createOnBoarding.payload);
          onNextStep();
        }
      } else {
        toast.error(createOnBoarding.payload as string);
      }
    } else {
      toast.error('Para continuar es necesario seleccionar al menos una categoría.');
    }
  };

  const getInterestList = React.useCallback(async () => {
    const interestList = await dispatch(InterestListThunk());

    if (InterestListThunk.rejected.match(interestList)) {
      toast.error(interestList.payload as string);
    }
  }, [dispatch]);

  const responsiveButtonWrapper = React.useMemo(() => {
    if (!responsive) {
      return 150;
    }

    return undefined;
  }, [responsive]);

  React.useEffect(() => {
    getInterestList();
  }, [getInterestList]);

  const checkSelection = React.useCallback(
    (interest: number) => {
      if (onboarding.interestSelected.length !== 0) {
        return onboarding.interestSelected.some((select) => {
          return select.id === interest;
        });
      }
      return false;
    },
    [onboarding.interestSelected]
  );

  return (
    <StepContainer>
      <Step>
        <StepTitle>¿Cúales son tus intereses?</StepTitle>
        <StepSubtitle>
          Elige mínimo 1 interés y 1 habilidad para personalizar la plataforma según tus metas.
        </StepSubtitle>
        <StepSection>Mis Intereses</StepSection>
        <InterestContainer gap="0.5rem">
          {onboarding.interests ? (
            <React.Fragment>
              {onboarding.interests.map((interest, index) => (
                <React.Fragment key={index}>
                  <Col lg="3" sm="6" xs="12" md="4" style={{ marginTop: 19 }}>
                    <InterestCard
                      onSelect={() =>
                        selectInterest(index, { id: interest.id, name: interest.name })
                      }
                      selected={checkSelection(interest.id)}
                      title={interest.name}
                      image={`${S3_INTEREST}${interest.id}.svg`}
                    />
                  </Col>
                </React.Fragment>
              ))}
              <Modal isOpen={modal} toggle={() => setModal(!modal)} centered={true}>
                {interestName && (
                  <React.Fragment>
                    <ModalBodySelect>
                      <SelectSkill onSelectSkill={onSelectSkill} interestContent={interestName} />
                    </ModalBodySelect>
                    <ModalFooterSkills>
                      <ModalButtonWrapper $withMargin>
                        <Button
                          label="Cancelar"
                          lightMode={true}
                          widthCustom={responsiveButtonWrapper}
                          onClick={() => setModal(!modal)}
                        />
                      </ModalButtonWrapper>
                      <ModalButtonWrapper>
                        <Button
                          label="Aceptar"
                          widthCustom={responsiveButtonWrapper}
                          onClick={() => setModal(!modal)}
                        />
                      </ModalButtonWrapper>
                    </ModalFooterSkills>
                  </React.Fragment>
                )}
              </Modal>
            </React.Fragment>
          ) : (
            <SkeletonInterest />
          )}
        </InterestContainer>
        <ButtonContainer>
          <Button
            onClick={saveUserOnBoarding}
            label="Siguiente"
            disabled={onboarding.interestSelected.length === 0}
          />
        </ButtonContainer>
      </Step>
    </StepContainer>
  );
};

export default Step1;
