import { CardContainer } from './styles';
import icons from 'assets/Icon';
import { RatingView } from 'react-simple-star-rating';
import { formatDate, formatPrice } from 'utils';
import {
  CourseTitle,
  CourseTextItem,
  CardInfoWrapper,
  CoursePrice,
  ImageWrapper,
  CardImage,
  CourseTitleWrapper,
  CompanyNameWrapper,
  CompanyName,
  RatingWrapper,
} from './styles';
import * as React from 'react';

interface CourseCardProps {
  name: string;
  image: string;
  company: string;
  start: Date | null;
  score: number | null;
  duration: string;
  skills: number;
  price: number | null;
  companyColor: string;
}

const CourseCard = ({
  name,
  image,
  company,
  start,
  score,
  skills,
  duration,
  price,
  companyColor,
}: CourseCardProps): JSX.Element => {
  return (
    <CardContainer>
      <ImageWrapper>
        <CardImage src={image} alt={`${name}-img`} />
        <CompanyNameWrapper $backgroundColor={companyColor}>
          <CompanyName>{company}</CompanyName>
        </CompanyNameWrapper>
      </ImageWrapper>
      <CourseTitleWrapper>
        <CourseTitle>{name}</CourseTitle>
      </CourseTitleWrapper>
      {score ? (
        <RatingWrapper>
          <RatingView ratingValue={score} size={20} />
          <CourseTextItem>{score}</CourseTextItem>
        </RatingWrapper>
      ) : (
        <RatingWrapper>
          
        </RatingWrapper>
      )}
      <CardInfoWrapper>
        <div>
          {start && (
            <div>
              <icons.CalendarIcon />
              <CourseTextItem>Inicio: {formatDate(start)}</CourseTextItem>
            </div>
          )}
          <div>
            <icons.Icono_Duracion />
            {duration ? (
              <CourseTextItem>Duración: {duration} </CourseTextItem>
            ) : (
              <CourseTextItem>Duración: A tu ritmo </CourseTextItem>
            )}
          </div>
          <div>
            <icons.SkillsIcon />
            <CourseTextItem>{skills} Habilidades</CourseTextItem>
          </div>
        </div>
        <CoursePrice>
          {price ? (
            <React.Fragment>
              ${formatPrice(price)} <br /> USD
            </React.Fragment>
          ) : (
            <React.Fragment>¡Gratis!</React.Fragment>
          )}
        </CoursePrice>
      </CardInfoWrapper>
    </CardContainer>
  );
};

export default CourseCard;
