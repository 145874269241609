import * as React from 'react';
import BadgeDetail from 'components/organisms/BadgeDetail';
import { useLocation } from 'react-router-dom';

const DetailOrganizationBadge = (): JSX.Element => {
  let url = useLocation().pathname;

  return (
    <React.Fragment>
      <BadgeDetail
        badge_id={url.substring(url.lastIndexOf('/') + 1)}
        organization_badge={true}
        showHeaderMenu={true}
      />
    </React.Fragment>
  );
};

export default DetailOrganizationBadge;
