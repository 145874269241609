import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';

const useButtonResponsive = () => {
  const responsive = useMediaQuery({ query: device.smallMobile });

  const buttonWidthResponsive = useMemo(() => {
    if (!responsive) {
      return 151;
    }

    return undefined;
  }, [responsive]);

  return { buttonWidthResponsive };
};

export default useButtonResponsive;
