import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Col, Container, Label } from 'reactstrap';
import styled from 'styled-components';

export const CourseCardsContainer = styled(Container)`
  margin: 40px 0px 0px;
`;

export const CourseCardContainer = styled(Col)`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  margin: 10px;
  min-height: 100px;
`;

export const CourseTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  padding: 0px 10px;
  margin-top: 20px;
`;

export const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
`;

export const DurationTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 15px;
    color: #da291c;
  }
`;

export const DurationTitle = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
  margin: 0px 5px;
`;

export const Duration = styled.span`
  font-family: NotoSansJPBold;
  font-size: 12px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
`;

export const ProviderWrapper = styled.div`
  background: #003da6;
  border-radius: 0px 0px 8px 8px;
  font-family: NotoSansJPBold;
  font-size: 12px;
  line-height: 22px;
  color: #ffffff;
  padding: 0px 10px;
`;
