import * as React from 'react';
import {
  CoursesCatalogContainer,
  BarSearchContainer,
  RowStyled,
  SearchIconContainer,
  IconFilterWrapper,
} from './styles';
import CoursesCards from 'components/molecules/CoursesCards';
import icons from 'assets/Icon';
import TextInput from 'components/atoms/TextInput';
import CourseFilters from 'components/molecules/CourseFilters';
import Pagination from 'components/molecules/Pagination';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  AdminCourseCatalogFilterOptionsThunk,
  AdminCourseCatalogThunk,
} from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { objectValidation } from 'utils/index';
import { defaultCourseSelection } from '../../../states/slices/course.slice';
import AdminUploadNewIndividualCourse from 'components/organisms/AdminUploadNewIndividualCourse';
import CommonPageLayout from '../Layout/CommonPageLayout';

interface SearchBarForm {
  search: string;
}

const CatalogPlus = (): JSX.Element => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [toggleFilter, setToggleFilter] = React.useState<boolean>(false);
  const [uploadNewCourse, setUploadNewCourse] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const courses = useAppSelector((state) => state.catalogCourses);

  const defaultValuesSearch: SearchBarForm = {
    search: '',
  };

  const getDefaultFormSearch = (): SearchBarForm => {
    return defaultValuesSearch;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<SearchBarForm>({
    ...objectValidation,
    defaultValues: getDefaultFormSearch(),
  });

  const keywords = watch().search;

  const listCourseCatalog = React.useCallback(async () => {
    const courses = await dispatch(AdminCourseCatalogThunk({}));
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  }, [dispatch]);

  const toggleFilterHandler = () => {
    setToggleFilter(!toggleFilter);
  };

  const menuFilterOptionItems = React.useCallback(async () => {
    const menuFilter = await dispatch(AdminCourseCatalogFilterOptionsThunk());

    if (AdminCourseCatalogFilterOptionsThunk.rejected.match(menuFilter)) {
      toast.error(menuFilter.payload as string);
    }
  }, [dispatch]);

  const handleSearch = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: keywords,
      })
    );

    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleUserKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(handleSearch)();
      reset();
    }
  };

  const handleSearchPage = async (page: number) => {
    setCurrentPage(page);
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        page: page,
      })
    );
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
    reset();
  };

  const handleNewCourseSubmit = () => {
    setUploadNewCourse(false);
  };

  React.useEffect(() => {
    listCourseCatalog();
    menuFilterOptionItems();
    dispatch(defaultCourseSelection());
  }, [dispatch, listCourseCatalog, menuFilterOptionItems]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={false}
      showMenuHeaderAcredited={true}
    >
      <div>
        {!uploadNewCourse ? (
          <CoursesCatalogContainer>
            <RowStyled>
              <BarSearchContainer sm="12">
                <IconFilterWrapper onClick={toggleFilterHandler}>
                  <icons.FilterIcon />
                </IconFilterWrapper>
                <div>
                  <Controller
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextInput
                        name="search"
                        error={errors}
                        height={35}
                        placeholder="Buscar contenido..."
                        value={value}
                        onChange={onChange}
                        onKeyPress={handleUserKeyPressSearch}
                      />
                    )}
                    name="search"
                  />
                  <SearchIconContainer onClick={handleSubmit(handleSearch)}>
                    <icons.SearchIcon />
                  </SearchIconContainer>
                </div>
              </BarSearchContainer>
            </RowStyled>
            {toggleFilter && <CourseFilters />}
            {courses.interestCourseT ? (
              <React.Fragment>
                <CoursesCards courses={courses.interestCourseT} />
                <Pagination
                  currentPage={currentPage}
                  totalCount={courses.interestCourseT.length}
                  pageSize={20}
                  onPageChange={(page: number) => handleSearchPage(page)}
                />
              </React.Fragment>
            ) : (
              <div>Loading...</div>
            )}
          </CoursesCatalogContainer>
        ) : (
          <AdminUploadNewIndividualCourse
            handleCourseSubmit={handleNewCourseSubmit}
            hideNewCourseSection={() => setUploadNewCourse(false)}
          />
        )}
      </div>
    </CommonPageLayout>
  );
};

export default CatalogPlus;
