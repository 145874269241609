import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const WelcomePageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px;

  svg {
    width: 600px;
  }
`;

export const WelcomePageTitle = styled(Label)`
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: ${PRIMARY_COLOR};
`;

export const WelcomePageDesc = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
`;
export const WelcomePageButtonWrapper = styled.div`
  margin: 20px 0px 0px;
`;
