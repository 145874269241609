import * as Sentry from '@sentry/react';
import { AdminUrls, API_URL, IntegrationsUrls } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetPaths(): Promise<any> {
  try {
    const listPath = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.listPaths}`);
    if (listPath.okay && listPath.data) {
      return listPath.data;
    } else {
      throw Error(listPath.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DeletePaths(pathway_id: any): Promise<any> {
  try {
    const disable = {
      is_active: false,
    };
    const listPath = await new HttpRequest().Patch(
      `${API_URL}${AdminUrls.deletePaths}${pathway_id}/`,
      disable
    );
    if (listPath.okay && listPath.data) {
      return listPath;
    } else {
      throw Error(listPath.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DeleteUsers(cognito_id: any): Promise<any> {
  try {
    const disable = {
      is_active: false,
    };
    const deleteUser = await new HttpRequest().Patch(
      `${API_URL}${AdminUrls.deleteUsers}${cognito_id}/`,
      disable
    );
    if (deleteUser.okay && deleteUser.data) {
      return deleteUser;
    } else {
      throw Error(deleteUser.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadge(): Promise<any> {
  try {
    const listBadges = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.listBadgesT}`);
    if (listBadges.okay && listBadges.data) {
      return listBadges.data;
    } else {
      throw Error(listBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeOrganization(org_id: any): Promise<any> {
  try {
    const listBadges = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.listBadgesT}${org_id}/`
    );
    if (listBadges.okay && listBadges.data) {
      return listBadges.data;
    } else {
      throw Error(listBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeDetail(badge_id: any): Promise<any> {
  try {
    const listBadgesDetail = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.listBadgesT}${badge_id}/`
    );
    if (listBadgesDetail.okay && listBadgesDetail.data) {
      return listBadgesDetail.data;
    } else {
      throw Error(listBadgesDetail.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeAutomation(org_id: any): Promise<any> {
  try {
    const listBadges = await new HttpRequest().Get<any>(
      `${API_URL}${IntegrationsUrls.listBadges}?organization_id=${org_id}`
    );
    if (listBadges.okay && listBadges.data) {
      return listBadges.data.data;
    } else {
      throw Error(listBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgesListTemplates(
  filter: CourseCatalogThunk
): Promise<IBadgeListTemplate> {
  try {
    let listBadges: IHttpResponse<IBadgeListTemplate>;
    if (filter.page) {
      listBadges = await new HttpRequest().Get<IBadgeListTemplate>(
        `${API_URL}${AdminUrls.listBadgesTemplates}?page=${filter.page}`
      );
    } else {
      listBadges = await new HttpRequest().Get<IBadgeListTemplate>(
        `${API_URL}${AdminUrls.listBadgesTemplates}`
      );
    }
    if (listBadges.okay && listBadges.data) {
      return listBadges.data;
    } else {
      throw Error(listBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgesListTemplatesArchived(
  filter: CourseCatalogThunk
): Promise<IBadgeListTemplate> {
  try {
    let listBadges: IHttpResponse<IBadgeListTemplate>;
    if (filter.page) {
      listBadges = await new HttpRequest().Get<IBadgeListTemplate>(
        `${API_URL}${AdminUrls.listBadgesTemplatesArchived}?page=${filter.page}`
      );
    } else {
      listBadges = await new HttpRequest().Get<IBadgeListTemplate>(
        `${API_URL}${AdminUrls.listBadgesTemplatesArchived}`
      );
    }
    if (listBadges.okay && listBadges.data) {
      return listBadges.data;
    } else {
      throw Error(listBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeListUsers(filter: CourseCatalogThunk): Promise<IBadgeListUsers> {
  try {
    let listBadges: IHttpResponse<IBadgeListUsers>;
    if (filter.page) {
      listBadges = await new HttpRequest().Get<IBadgeListUsers>(
        `${API_URL}${AdminUrls.listBadgesUsers}?uuid=${filter.search}&page=${filter.page}`
      );
    } else {
      listBadges = await new HttpRequest().Get<IBadgeListUsers>(
        `${API_URL}${AdminUrls.listBadgesUsers}?uuid=${filter.search}`
      );
    }
    if (listBadges.okay && listBadges.data) {
      return listBadges.data;
    } else {
      throw Error(listBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetListUsersOrg(org_id: any): Promise<any> {
  try {
    const listUsers = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.loadUsers}?organization_id=${org_id}`
    );
    if (listUsers.okay && listUsers.data) {
      return listUsers.data;
    } else {
      throw Error(listUsers.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgesListTemp(): Promise<any> {
  try {
    const listBagdesTemp = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.listBadgesTemplate}`
    );
    if (listBagdesTemp.okay && listBagdesTemp.data) {
      return listBagdesTemp.data;
    } else {
      throw Error(listBagdesTemp.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function AddBagdePaths(addBadgePath: IAddBadgePath): Promise<string> {
  try {
    const create = await new HttpRequest().Post<any>(
      `${API_URL}${AdminUrls.addBadge}`,
      addBadgePath
    );

    if (create.okay) {
      return 'Insignias Asignada Correctamente';
    } else {
      throw Error(create.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function ReSendInvite(email: string): Promise<string> {
  try {
    const body = {
      email: email,
    };
    const create = await new HttpRequest().Post<any>(
      `${API_URL}${AdminUrls.resendInvitation}`,
      body
    );

    if (create.okay) {
      return 'Insignias Asignada Correctamente';
    } else {
      throw Error(create.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
