import { dowloadCertificatePdf } from 'actions/ApiClient/certificates-pdf';
import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { manageErrors } from 'helpers/manage-error';
import useGetCertificatePdf from 'hooks/useGetCertificatePdf';
import { useState } from 'react';

interface Props {
  badgeId: string;
}

const DownloadCertificatePdf = ({ badgeId }: Props) => {
  const [loading, setLoading] = useState(false);
  const { showButton, isFetching } = useGetCertificatePdf(badgeId);

  const getCertificatePdf = async () => {
    try {
      setLoading(true);
      const resp: any = await dowloadCertificatePdf(badgeId);
      const blob = new Blob([resp.data], { type: 'application/pdf' });
      window.open(URL.createObjectURL(blob), '_blank');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      manageErrors(e);
    }
  };

  return (
    <div className="mt-3">
      {isFetching && <AcredittaLoader width={22} height={22} isTextDisabled={true} />}

      {!isFetching && showButton && (
        <AcredittaButton
          onPress={getCertificatePdf}
          title="Descargar certificado"
          type="button"
          isLoading={loading}
          disabled={loading}
        />
      )}
    </div>
  );
};

export default DownloadCertificatePdf;
