import styled from 'styled-components';
import { BLUE_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { FormGroup, Label, Input } from 'reactstrap';
import DatePicker from 'react-date-picker';

export const NewCourseFormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const NewCourseInstructionsTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
`;

export const FormLabelSimple = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media ${device.mediumMobile} {
    flex-direction: column-reverse;
  }
`;

export const InfoText = styled(Label)`
  font-size: 14px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin-bottom: 20px;
  margin-top: 1px;
`;

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;
  display: flex;
  margin: 0;
  margin-bottom: 22px;
  @media ${device.laptops} {
    flex-direction: column;
  }
`;

export const FormLabelSimpleWrapper = styled.div`
  width: 20%;
  text-align: end;
  padding-right: 10px;
  margin: 0;

  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormDateLabelSimpleWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  margin: 0;
  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormInputWrapper = styled.div`
  width: 80%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const InputDate = styled(Input)`
  height: 20px;
`;

export const SwitchContainer = styled.div`
  width: '100%';
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 20px;
`;

export const InputSelectContainer = styled.div`
  width: 100%;
`;

export const SwitchLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin: 5px 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const SelectedSkillsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 130px;
`;

export const FormMoreInfoLabel = styled.div`
  width: 100%;
`;

export const MoreInfoLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: ${SECONDARY_COLOR};
  width: 100%;
  cursor: pointer;
`;

export const FormLabelSimpleDates = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
  text-align: end;
  padding-right: 5px;
`;

export const FormGroupDatesStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% 30% 25% 25%;
  margin: 30px 0px;
`;

export const SessionsContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
  margin: 10px 0px;
`;

export const SessionContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
`;

export const FormLabelSession = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: #53565a;
  margin-bottom: 0px;
  padding-bottom: 23px;
`;

export const FormLabelSessionWrapper = styled.div`
  width: 100%;
  text-align: end;
  padding-right: 10px;
  margin: 0;
`;

export const SessionsDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const SesionAddButtonWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DeleteSessionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    width: 15px;
  }
`;

export const SesionAddButton = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: #da291c;
  cursor: pointer;
`;

export const DeleteSessionButton = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  margin: 5px;
`;

export const SesionAddButtonTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  padding-left: 3%;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin: 20px 0;

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const ErrorLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height: 38px;
`;
