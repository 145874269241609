import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetDescriptionCourse } from 'actions/ApiClient/DescriptionCourseActions';
import { GetErrorMessage } from 'utils';

export const DescriptionCourseThunk = createAsyncThunk(
  'admin/descriptioncourse',
  async (id: number, { rejectWithValue }) => {
    try {
      const descriptionCourse = await GetDescriptionCourse(id);
      return descriptionCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
