import React, { useEffect, useState } from 'react';
import TextInput from '../TextInput';
import validator from 'validator';

interface EstandarInputsI {
  onChangeStandardList: any;
  standardList: any;
  setStandardList: any;
  i: number;
}
const EstandarInputs = ({
  onChangeStandardList,
  standardList,
  setStandardList,
  i,
}: EstandarInputsI) => {
  const [errors, setErrors] = useState({});

  const handleChangeStandard = (e: any, index: number) => {
    const { name, value } = e.target;
    const list = [...standardList];
    if (name === 'url') {
      if (validator.isURL(value)) {
        list[index].url = value.includes('https://') ? value : 'https://' + value;
        setErrors({});
        return
      }
      setErrors({
        url: {
          type: 'invalid',
          message: 'Debes ingresar una página web valida',
        },
      });
      list[index].url = value;
      return
    } else {
      list[index].name = value;
    }
    setStandardList(list);
    
  };

  useEffect(() => {
    onChangeStandardList(standardList);
    // eslint-disable-next-line
  }, [standardList])
  

  return (
    <>
      <TextInput
        name="estándar"
        placeholder="Nombre de la norma  o estándar"
        onChange={(e) => handleChangeStandard(e, i)}
        error={errors}
        height={40}
        value={standardList[i].name}
        maxLength={255}
      />
      <TextInput
        name="url"
        placeholder="www."
        onChange={(e) => handleChangeStandard(e, i)}
        error={errors}
        height={40}
        value={standardList[i].url}
      />
    </>
  );
};

export default EstandarInputs;
