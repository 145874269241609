import styled from 'styled-components';

type Color = '#02B148' | '#F9B233' | '#DA291C';

interface Props {
  text: string;
  color: Color;
}

const CustomSpan = styled.span`
  margin-left: 10px;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 24px;
`;

const BadgeResume = ({ text, color }: Props) => {
  return (
    <div style={{ width: '100%', margin: '10px auto' }}>
      <CustomSpan style={{ backgroundColor: color }}></CustomSpan>
      <span> {text}</span>
    </div>
  );
};

export default BadgeResume;
