import { getBadgeOverview } from 'actions/ApiClient/analytics';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { BadgeOverviewResponse } from 'interfaces/badge-overview';
import { useEffect, useState } from 'react';
import CardDashboardIssue from '../card-dashboard-issue/CardDashboardIssue';

interface Props {
  totalShares?: number;
}

const BadgeOverview = ({ totalShares }: Props) => {
  const [loadingResume, setLoadingResume] = useState(false);
  const [badgesOverview, setBadgesOverview] = useState({
    accepted: { value: 0, label: 'Aceptadas' },
    pending: { value: 0, label: 'Pendientes' },
    rejected: { value: 0, label: 'Rechazadas' },
  } as BadgeOverviewResponse);

  useEffect(() => {
    (async () => {
      try {
        setLoadingResume(true);
        const resp = await getBadgeOverview();
        setBadgesOverview(resp.data);
        setLoadingResume(false);
      } catch (e) {
        setLoadingResume(false);
        console.error(e);
      }
    })();
  }, []);

  return (
    <>
      {loadingResume && <AcredittaLoader />}
      {!loadingResume && badgesOverview && (
        <div className="row mt-5 mb-5">
          <div className="col-12 col-md-3 mb-2">
            <CardDashboardIssue
              data={badgesOverview.accepted.value + badgesOverview.pending.value}
              variant={'emitidas'}
            />
          </div>
          <div className="col-12 col-md-3 mb-2">
            <CardDashboardIssue data={badgesOverview.accepted.value} variant="aceptadas" />
          </div>
          <div className="co-12 col-md-3 mb-2">
            {/* TODO: set this value dinamic */}
            <CardDashboardIssue data={totalShares || 0} variant={'compartidas'} />
          </div>
          <div className="col-12 col-md-3 mb-2">
            <CardDashboardIssue data={badgesOverview.pending.value} variant={'pendientes'} />
          </div>
        </div>
      )}
    </>
  );
};

export default BadgeOverview;
