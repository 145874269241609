import * as React from 'react';
import {
  BadgeDetailsContainer,
  BadgeInfoWrapper,
  BadgeName,
  BadgeNameWrapper,
  ItemWrapper,
  ItemTitle,
  ItemDesc,
  SkillContainer,
  RequirementsWrapper,
  MoreInfoWrapper,
  SkillsSectionWrapper,
  ShareBadgeContainer,
} from './styles';
import icons from 'assets/Icon';
import BadgeDetailsResumenInfo from 'components/molecules/BadgeDetailsResumenInfo';
import ShareBadgeTemp from 'components/molecules/ShareBadgeTemp';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { DetailBadgesUserTempThunk } from 'actions/Thunks/UnAuthUsersThunks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { NonAuthenticatedUrls } from 'constants/NavigationUrls';
import { ENVIRONMENT_URL } from 'utils';

interface BadgeDetailsProps {
  badge_id: string;
  organization_badge?: boolean;
  unauthorized?: boolean;
  showHeaderMenu?: boolean;
}

const BadgeDetails = ({
  badge_id,
  organization_badge,
  unauthorized,
  showHeaderMenu,
}: BadgeDetailsProps): JSX.Element => {
  const [showShareScreen, setShowShareScreen] = React.useState<boolean>(false);
  const [userBadgeTemplate, setUserBadgeTemplateInfo] = React.useState<any>(null);

  const user = useAppSelector((state) => state.user.userInfo);

  const dispatch = useAppDispatch();

  const getBadgeUnAuthUser = React.useCallback(async () => {
    const badge = await dispatch(DetailBadgesUserTempThunk(badge_id));
    if (DetailBadgesUserTempThunk.rejected.match(badge)) {
      toast.error(badge.payload as string);
    } else {
      setUserBadgeTemplateInfo(badge.payload);
    }
  }, [badge_id, dispatch]);

  React.useEffect(() => {
    getBadgeUnAuthUser();
  }, [getBadgeUnAuthUser]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={user?.user_type.id === 3 ? false : true}
      showMenuHeaderAcredited={user?.user_type.id === 3 ? true : false}
      showCommon={true}
    >
      {userBadgeTemplate !== null ? (
        <BadgeDetailsContainer>
          <BadgeDetailsResumenInfo
            badge_details={{
              name: userBadgeTemplate.data?.name,
              image: userBadgeTemplate.data?.image.url,
              issuer: userBadgeTemplate.data?.owner.name,
              details: {
                type: userBadgeTemplate.data?.type_category,
                cost: userBadgeTemplate.data?.cost,
                level: userBadgeTemplate.data?.level,
                issue_date: userBadgeTemplate.data?.updated_at,
                duration: userBadgeTemplate.data?.duration,
                duration_type: userBadgeTemplate.data?.duration_type,
              },
            }}
            badge_url={`${ENVIRONMENT_URL}${NonAuthenticatedUrls.badges}/${userBadgeTemplate.data?.id}`}
            organization_badge={true}
          />
          {!showShareScreen ? (
            <BadgeInfoWrapper>
              <BadgeNameWrapper>
                <BadgeName>{userBadgeTemplate.data?.name}</BadgeName>
              </BadgeNameWrapper>
              <ItemWrapper>
                <ItemTitle>Descripción</ItemTitle>
                <ItemDesc>{userBadgeTemplate.data?.description}</ItemDesc>
              </ItemWrapper>
              <ItemWrapper>
                <ItemTitle>Habilidades</ItemTitle>
                <SkillsSectionWrapper>
                  {userBadgeTemplate.data?.skills &&
                    userBadgeTemplate.data?.skills.map((skill: string, index: number) => {
                      return (
                        <SkillContainer key={index}>
                          <div>
                            <icons.EmptyHexagonIcon />
                          </div>
                          <ItemDesc>{skill}</ItemDesc>
                        </SkillContainer>
                      );
                    })}
                </SkillsSectionWrapper>
              </ItemWrapper>
              <ItemWrapper>
                <ItemTitle>Requisitos</ItemTitle>
                {userBadgeTemplate.data?.badge_template_activities &&
                  userBadgeTemplate.data?.badge_template_activities.map(
                    (requirement: { title: string }, index: number) => {
                      return (
                        <RequirementsWrapper key={index}>
                          <div>
                            <icons.CheckIcon />
                          </div>
                          <ItemDesc>{requirement.title}</ItemDesc>
                        </RequirementsWrapper>
                      );
                    }
                  )}
              </ItemWrapper>
              <ItemWrapper>
                <ItemTitle>Información Adicional</ItemTitle>
                {userBadgeTemplate.data.additional_info && (
                  <MoreInfoWrapper>
                    <a
                      href={`${
                        userBadgeTemplate.data.additional_info.includes('https:/')
                          ? userBadgeTemplate.data.additional_info
                          : 'https://' + userBadgeTemplate.data.additional_info
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <icons.URLIcon />
                      <ItemDesc>{userBadgeTemplate.data.additional_info}</ItemDesc>
                    </a>
                  </MoreInfoWrapper>
                )}
              </ItemWrapper>
            </BadgeInfoWrapper>
          ) : (
            <ShareBadgeContainer>
              <ShareBadgeTemp
                badge_details={userBadgeTemplate}
                closeShareSection={() => setShowShareScreen(false)}
              />
            </ShareBadgeContainer>
          )}
        </BadgeDetailsContainer>
      ) : (
        <h1>Loading...</h1>
      )}
    </CommonPageLayout>
  );
};

export default BadgeDetails;
