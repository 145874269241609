import { PRIMARY_COLOR } from 'assets/styles/colors';
import { NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const MenuContainer = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  @media ${device.desktop} {
    display: none;
  }
`;

export const LateralMenuContainer = styled.div`
  background-color: #f7f7f7;
  height: 70vw;
`;

export const ProfileImage = styled.img`
  margin: 20px 10px;
`;

export const NavItemStyled = styled(NavItem)`
  display: flex;
  align-items: center;
  margin: 10px 20px;
`;

export const NavLinkStyled = styled(NavLink)`
  font-size: 16px;
  line-height: 19px;
  font-family: NotoSansJPBold;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;
