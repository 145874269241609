import icons from 'assets/Icon';

export const OPTIONS = [
  {
    id: '1',
    name: 'Organización',
    slug: 'profile',
    borderLeft: false,
    IconOption: icons.BuildingNonCircle,
  },
  {
    id: '2',
    name: 'Certificados',
    slug: 'certificates',
    borderLeft: true,
    IconOption: icons.IconoCertificado,
  },
  {
    id: '3',
    name: 'Suscripción',
    slug: 'subscription',
    borderLeft: true,
    IconOption: icons.Subscription,
  },
];
