import { BLUE_LIGHT, PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const TitlesFirstGrid = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;

export const TextContent = styled(Label)`
  color: ${BLUE_LIGHT};
  font-family: NotoSansJP, sans-serif;
  font-size: 16px;
`;

export const TextContentFlex = styled(Label)`
  color: ${BLUE_LIGHT};
  font-family: NotoSansJP, sans-serif;
  font-size: 16px;
  margin-left: 20px;
`;

export const CardConfig = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 250px;
  background: white
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 42px;
  margin-bottom: 10px;
`;

export const ContainerContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerContentTextArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerContentFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  border-bottom: 1px solid #ebebeb;
`;

export const ContainerContentFinal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 75px;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
`;
