import styled, { css } from 'styled-components';

export const LayoutContainer = styled.div<{ $footer?: boolean }>`
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => (props.$footer ? '#f7f7f7' : 'white')};
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`;

export const AuthLayoutContainer = styled.div<{ margin?: boolean }>`
  background-color: white;
  width: 100%;
  height: auto;
  ${(props) =>
    props.margin &&
    css`
      padding-bottom: 2.75em;
    `}
`;
