import AssignRoutesAssignRoutes from 'components/organisms/AssignRoutesAssignRoutes';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const AssignRoutes: React.FC = () => {
  let url = useLocation().pathname;

  return <AssignRoutesAssignRoutes routeID={url.substring(url.lastIndexOf('/') + 1)} />;
};

export default AssignRoutes;
