import * as React from 'react';
import { CommonLayoutContainer, LayoutContainer } from './styles';
import LateralMenu from 'components/molecules/LateralMenu';
import FooterContainer from '../../Footer';
import DashboardHeader from '../../Header';
import MessageProfileNotCompleted from 'components/molecules/MessageProfileNotCompleted';
import { useAppSelector } from '../../../../hooks/redux';
import AcredittaPlatformMessage from 'components/atoms/AcredittaPlatformMessage';
import { AcredittaNavbar } from 'components/atoms/Navbar';

interface CommonPageLayoutProps {
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  childrenMargin?: boolean;
  showMenuHeader?: boolean;
  showMenuHeaderAcredited?: boolean;
  showCommonMenu?: boolean;
  showTopMenu?: boolean;
  selectMenuHeader?: string;
  showCommon?: boolean;
  showOnboarding?: boolean;
}

const CommonPageLayout: React.FC<CommonPageLayoutProps> = ({
  children,
  showFooter,
  showHeader,
  childrenMargin,
  showMenuHeader,
  showMenuHeaderAcredited,
  showOnboarding,
  showCommon,
  showCommonMenu,
}) => {
  const { adminInfo, userInfo } = useAppSelector((state) => state.user);
  const timeRef = React.useRef(null as any);
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    timeRef.current = setTimeout(() => setShow(false), 6000);
    return () => {
      clearTimeout(timeRef.current);
    };
  }, []);

  return (
    <LayoutContainer $footer={showFooter}>
      {false && (
        <DashboardHeader
          showMenu={showMenuHeader}
          showMenuAcredited={showMenuHeaderAcredited}
          showCommon={showCommon}
          showOnboarding={showOnboarding}
        />
      )}
      <AcredittaNavbar />

      <main>
        {!adminInfo?.is_completed && userInfo?.user_type.code === 'ADMIN' && show && (
          <AcredittaPlatformMessage>
            <MessageProfileNotCompleted />
          </AcredittaPlatformMessage>
        )}
        <CommonLayoutContainer margin={childrenMargin} showCommonMenu={showCommonMenu}>
          {showCommonMenu && <LateralMenu showTopMenu={false} />}
          {children}
        </CommonLayoutContainer>
      </main>
      {showFooter && <FooterContainer />}
    </LayoutContainer>
  );
};

export default CommonPageLayout;
