import {
  FormContainer,
  FieldTitle,
  FormGroupStyled,
  FormGroupDescriptionStyled,
  NewLearningExperienceContainer,
  ButtonsContainer,
  ButtonWrapper,
  MoreInfoContainer,
  FileMoreInfoTitle,
  InputSelectContainer,
  FormInputWrapper,
} from './styles';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import DatePicker from 'react-date-picker';
import { Controller, Control, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import validator from 'validator';
import AcredittaSkillsSelector from 'components/atoms/AcredittaSkillsSelector';
import { useState } from 'react';

interface AdminNewLearningExperienceProps {
  controlInfo: Control<IInitialCreateNewLearningExperience>;
  handleSubmitInfoForm: UseFormHandleSubmit<IInitialCreateNewLearningExperience>;
  handleSubmitNewLearningExperience: (formInfo: IInitialCreateNewLearningExperience) => void;
  errors: Record<string, any>;
  onCancel: () => void;
  watch: UseFormWatch<IInitialCreateNewLearningExperience>;
}

const AdminNewLearningExperience = ({
  onCancel,
  controlInfo,
  errors,
  handleSubmitNewLearningExperience,
  handleSubmitInfoForm,
  watch,
}: AdminNewLearningExperienceProps): JSX.Element => {
  const date_i = watch().date_initial;
  const [skillsSelected, setSkillsSelected] = useState([]);

  const handleSkillsSelectorChanged = (values:any) => {
    setSkillsSelected(values);
  }

  return (
    <div>
      <NewLearningExperienceContainer>
        <FormContainer>
          <FormGroupStyled>
            <FieldTitle>Nombre</FieldTitle>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="name"
                  error={errors}
                  value={value}
                  onChange={(value) => onChange(value)}
                  height={36}
                  withError
                />
              )}
              name="name"
              rules={{
                required: 'Campo requerido',
              }}
            />
          </FormGroupStyled>
          <FormGroupStyled>
            <FieldTitle>Enlace</FieldTitle>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="url"
                  error={errors}
                  value={value}
                  onChange={(value) => onChange(value)}
                  height={36}
                  withError
                />
              )}
              name="url"
              rules={{
                required: 'Campo requerido',
                validate: {
                  checkUrl: (v) => validator.isURL(v) || 'Debes ingresar una página web valida',
                },
              }}
            />
          </FormGroupStyled>
          <FormGroupDescriptionStyled>
            <FieldTitle>Descripción</FieldTitle>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="description"
                  error={errors}
                  value={value}
                  onChange={(value) => onChange(value)}
                  height={130}
                  characterCounter={500}
                  type="textarea"
                  withError
                />
              )}
              name="description"
              rules={{
                required: 'Campo requerido',
              }}
            />
          </FormGroupDescriptionStyled>
          <FormGroupStyled row>
            <FieldTitle>Habilidades</FieldTitle>
            <FormInputWrapper>
              <InputSelectContainer>
                <AcredittaSkillsSelector 
                onChangeInputValues={handleSkillsSelectorChanged}/>
              </InputSelectContainer>
            </FormInputWrapper>
          </FormGroupStyled>
          <MoreInfoContainer>
            <FormGroupStyled>
              <FileMoreInfoTitle>Fecha de Inicio</FileMoreInfoTitle>
              <Controller
                control={controlInfo}
                name="date_initial"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    value={value}
                    locale="es-419"
                    minDate={new Date()}
                  />
                )}
              />
            </FormGroupStyled>
            <FormGroupStyled>
              <FileMoreInfoTitle>Fecha de Finalización</FileMoreInfoTitle>
              <Controller
                control={controlInfo}
                name="date_finish"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    onChange={onChange}
                    value={value}
                    locale="es-419"
                    minDate={date_i ? date_i : new Date()}
                  />
                )}
              />
            </FormGroupStyled>
            <FormGroupStyled>
              <FileMoreInfoTitle>Número de Minutos</FileMoreInfoTitle>
              <Controller
                control={controlInfo}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    name="duration"
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={errors}
                    height={36}
                    withError
                  />
                )}
                name="duration"
                rules={{
                  required: 'Campo requerido',
                  validate: {
                    checkHours: (v) => v > 0 || 'Los minutos deben ser mayor a cero',
                  },
                }}
              />
            </FormGroupStyled>
          </MoreInfoContainer>
          <ButtonsContainer>
            <ButtonWrapper>
              <Button label="Cancelar" lightMode={true} widthCustom={230} onClick={onCancel} />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                label="Agregar"
                widthCustom={230}
                onClick={handleSubmitInfoForm((formInfo) =>
                  handleSubmitNewLearningExperience({...formInfo, skills: skillsSelected})
                )}
              />
            </ButtonWrapper>
          </ButtonsContainer>
        </FormContainer>
      </NewLearningExperienceContainer>
    </div>
  );
};

export default AdminNewLearningExperience;
