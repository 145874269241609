import * as yup from 'yup';

export const editBadgeValidationSchema = yup.object({
  nombre: yup.string().required('Nombre es requerido'),
  descripcion: yup.string().required('Descripción es requerido'),
  url: yup.string().required('URL es requerida'),
  costAmount: yup.number().when('costo', {
    is: String(2),
    then: yup.number().required('Ingrese el monto'),
  }),
  requisitos: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().min(1, 'to shoort').required('Requerimiento es requerido'),
      })
    )
    .required('Requerimiento es requerido')
    .min(1, 'Al menos ingresa un requisito'),
});
