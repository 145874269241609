import * as React from 'react';
import BadgeDetail from 'components/organisms/BadgeDetail';
import { useLocation } from 'react-router-dom';

const BadgeDetailsPage = (): JSX.Element => {
  let url = useLocation().pathname;

  return (
    <React.Fragment>
      <BadgeDetail badge_id={url.substring(url.lastIndexOf('/') + 1)} showHeaderMenu={true} />
    </React.Fragment>
  );
};

export default BadgeDetailsPage;
