import * as React from 'react';
import {
  DnDContentStatusContainer,
  DnDContentStatusIcon,
  DnDContentStatusLabel,
  DnDContentStatusValues,
} from './styles';

interface AdminDnDContentStatusProps {
  icon: JSX.Element;
  status: string;
  label: string;
}

const AdminDnDContentStatus = ({
  icon,
  status,
  label,
}: AdminDnDContentStatusProps): JSX.Element => {
  return (
    <DnDContentStatusContainer>
      <DnDContentStatusIcon>{icon}</DnDContentStatusIcon>
      <DnDContentStatusValues>{status}</DnDContentStatusValues>
      <DnDContentStatusLabel>{label}</DnDContentStatusLabel>
    </DnDContentStatusContainer>
  );
};

export default AdminDnDContentStatus;
