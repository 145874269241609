import {
  REACT_APP_S3_URI,
  REACT_APP_TRACKING_ID_GOOGLE_ANALYTICS,
  REACT_APP_HUBSPOT_REGION_ID,
  REACT_APP_HUBSPOT_PORTAL_ID,
  REACT_APP_HUBSPOT_FORM_ID,
  REACT_APP_HUBSPOT_SRC,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ACREDITTA_WEB,
  REACT_APP_ENVIRONMENT_BADGE,
} from 'constants/Environments';
import validator from 'validator';
import moment from 'moment';

export const objectValidation: any = {
  mode: 'all',
  reValidateMode: 'onBlur',
  criteriaMode: 'all',
};

export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const urlRegex =
  /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,}$/;

export const S3_STATIC = `${REACT_APP_S3_URI}static/`;
export const S3_APP = `${REACT_APP_S3_URI}app/`;
export const S3_MEDIA = `${REACT_APP_S3_URI}media/`;
export const ACREDITTA_UPLOADS = `${REACT_APP_ACREDITTA_WEB}wp-content/uploads/`;

export const TRACKING_ID_GA = REACT_APP_TRACKING_ID_GOOGLE_ANALYTICS;
export const HUBSPOT_REGION_ID = REACT_APP_HUBSPOT_REGION_ID;
export const HUBSPOT_PORTAL_ID = REACT_APP_HUBSPOT_PORTAL_ID;
export const HUBSPOT_FORM_ID = REACT_APP_HUBSPOT_FORM_ID;
export const HUBSPOT_SRC = REACT_APP_HUBSPOT_SRC ? REACT_APP_HUBSPOT_SRC : '';
export const ENVIRONMENT_URL = REACT_APP_ENVIRONMENT ? REACT_APP_ENVIRONMENT : '';
export const ENVIRONMENT_URL_BADGE = REACT_APP_ENVIRONMENT_BADGE ? REACT_APP_ENVIRONMENT_BADGE : '';
/**
 * Array.prototype.indexOf() for objects
 * @param myArray
 * @param searchTerm value of the parameter
 * @param property  property of the object Ex: {foo: 1} property: foo
 */
export function arrayObjectIndexOf(myArray: any[], searchTerm: string, property: string) {
  for (let i = 0, len = myArray.length; i < len; i++) {
    if (myArray[i][property] === searchTerm) return i;
  }
  return -1;
}

// Narrow error from 'unknown' to 'Error'
export function GetErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  } else {
    return JSON.stringify(error);
  }
}

export function getHeightAndWidthFromDataUrl(
  dataURL: string
): Promise<{ height: number; width: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });
}

export async function ImageValidation(file: File[]): Promise<boolean | undefined> {
  const fileList = file[0];
  const maxDimensions = 1200;

  if (fileList) {
    const url = URL.createObjectURL(fileList);
    const dimensions = await getHeightAndWidthFromDataUrl(url);

    return (
      (fileList.type === 'image/jpeg' &&
        dimensions.height <= maxDimensions &&
        dimensions.width <= maxDimensions) ||
      (fileList.type === 'image/png' &&
        dimensions.height <= maxDimensions &&
        dimensions.width <= maxDimensions)
    );
  }
}

export function ValidURL(urlInput: string, domain?: string): string | undefined {
  if (validator.isURL(urlInput)) {
    if (validator.isURL(urlInput, { require_protocol: true })) {
      return urlInput;
    } else {
      return `http://${urlInput}`;
    }
  } else {
    if (domain) return `${domain}/${urlInput}`;
  }
}

export function RemoveSpacesLowerCase(value: string): string {
  const regexSpace = value.replace(/\s/g, '');

  return regexSpace.toLowerCase();
}

export function AcredittaDomainGenerator(domain: string): string {
  return `https://${domain}.acreditta.com`;
}

export function formatDate(myDate: Date): string {
  moment.locale('es');
  return moment(myDate).format('L');
}

export function formatDateYMD(myDate: Date): string {
  return moment(myDate).format('YYYY-MM-DD');
}

export function formatPrice(price: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .formatToParts(price)
    .map((p) => (p.type !== 'literal' && p.type !== 'currency' ? p.value : ''))
    .join('');
}

export function SerializeSelectionOption(
  item: IFilterOptionsCatalogItem[]
): IFilterSubCategories[] {
  let serializeSubCategories: IFilterSubCategories[] = [];

  item.map((subCategory) => {
    return serializeSubCategories.push({
      selected: false,
      id: subCategory.id,
      name: subCategory.name,
    });
  });

  return serializeSubCategories;
}

export function formatStringDate(date: string) {
  let year, month, day;
  var datePart = date.match(/\d+/g);

  if (datePart) {
    year = datePart[0].substring(2);
    month = datePart[1];
    day = datePart[2];
  }

  return day + '/' + month + '/' + year;
}
