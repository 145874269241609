import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetBadgeJson, GetDataRRSS, GetDataRRSSUnauth } from 'actions/ApiClient/ShareRRSS';
import { GetErrorMessage } from 'utils';

export const GetDataRRSSThunk = createAsyncThunk(
  'badge/data-rrss',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const DataRrss = await GetDataRRSS(uuid);
      return DataRrss;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetDataRRSSUnauthThunk = createAsyncThunk(
  'badge/data-rrss',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const DataRrss = await GetDataRRSSUnauth(uuid);
      return DataRrss;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetBadgeJsonThunk = createAsyncThunk(
  'badge/data-json',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const json = await GetBadgeJson(uuid);
      return json;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
