import * as React from 'react';
import icons from 'assets/Icon';
import {
  SubscriptionTitles,
  SubscriptionContainer,
  SubscriptionHeader,
  TitlesSecond,
  TitlesFirst,
  SubscriptionSubTitles,
  Icon,
  GridData,
  CardContainer,
  IconGrid,
  TitlesThirdGrid,
  TitlesFirstGrid,
  TitlesSecondGrid,
} from './styles';
import SusbcriptionForm from 'components/molecules/SubscriptionForm/SusbcriptionOrganism';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { GetDataConfigSubscribeThunk } from 'actions/Thunks/ConfigSubscribeThunk';
import toast from 'react-hot-toast';
import ModalUpgradeAccount from 'components/molecules/ModalUpgradeAccount';
import moment from 'moment';

const SusbcriptionOrganism: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  const [data, setData] = React.useState<IDataConfigSubscribe | null>(null);
  const [modal, setModal] = React.useState(false);

  const getData = React.useCallback(async () => {
    if (user.userInfo !== null) {
      const data = await dispatch(GetDataConfigSubscribeThunk(user.userInfo?.organization.id));
      if (GetDataConfigSubscribeThunk.rejected.match(data)) {
        toast.error(data.payload as string);
      } else {
        setData(data.payload);
      }
    }
  }, [user.userInfo, dispatch]);

  const handleModalUpgrade = () => {
    setModal(!modal);
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <React.Fragment>
      {user.userInfo !== null && data !== null && (
        <SubscriptionContainer>
          <SubscriptionHeader>
            <SubscriptionTitles>
              <TitlesFirst>Suscripción: </TitlesFirst>
              <TitlesFirst style={{ marginLeft: '10px' }}>
                {user.userInfo.organization.plan.name}
              </TitlesFirst>
              <Icon onClick={() => handleModalUpgrade()}>
                <icons.Imagen_Upgrade />
              </Icon>
              {modal === true && <ModalUpgradeAccount modal={setModal} />}
            </SubscriptionTitles>
            <SubscriptionSubTitles>
              <TitlesSecond>Vencimiento: </TitlesSecond>
              <TitlesSecond style={{ marginLeft: '10px' }}>
                {!data.due_date ? 'Tu plan no vence' : moment(data.due_date).format('DD/MM/YYYY')}
              </TitlesSecond>
            </SubscriptionSubTitles>
          </SubscriptionHeader>
          <GridData>
            <CardContainer>
              <IconGrid>
                <icons.IssueIcon />
              </IconGrid>
              <TitlesSecondGrid>{data.count_issued_badges}</TitlesSecondGrid>
              <TitlesFirstGrid>Emisiones</TitlesFirstGrid>
            </CardContainer>
            <CardContainer>
              <IconGrid>
                <icons.UserIcon />
              </IconGrid>
              <TitlesSecondGrid>{data.count_active_users}</TitlesSecondGrid>
              <TitlesFirstGrid>Usuarios Activos</TitlesFirstGrid>
            </CardContainer>
            <CardContainer>
              <IconGrid>
                <icons.UserIcon />
              </IconGrid>
              <TitlesThirdGrid>{data.available_users_count}</TitlesThirdGrid>
              <TitlesFirstGrid>Usuarios Disponibles</TitlesFirstGrid>
            </CardContainer>
          </GridData>
          <SusbcriptionForm
            plan={user.userInfo.organization.plan.name}
            users={data.count_active_users}
          />
        </SubscriptionContainer>
      )}
    </React.Fragment>
  );
};

export default SusbcriptionOrganism;
