import * as React from 'react';
import { AuthLayoutContainer, LayoutContainer } from './styles';
import FooterContainer from '../../Footer';
import PublicHeader from 'components/atoms/PublicHeader';

interface AuthPageLayoutProps {
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  childrenMargin?: boolean;
}

const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
  children,
  showFooter,
  showHeader,
  childrenMargin,
}) => {
  return (
    <LayoutContainer $footer={showFooter}>
      {showHeader && <PublicHeader />}
      <main style={{ backgroundColor: 'white' }}>
        <AuthLayoutContainer margin={childrenMargin}>{children}</AuthLayoutContainer>
      </main>
      {showFooter && <FooterContainer />}
    </LayoutContainer>
  );
};

export default AuthPageLayout;
