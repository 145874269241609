import { createSlice } from '@reduxjs/toolkit';

const CLEAN = 'CLEAN';

const initialState = { v1: {} };

const cleanSlice = createSlice({
  name: CLEAN,
  initialState,
  reducers: {
    cleanState() {},
  },
});

export const { cleanState } = cleanSlice.actions;
export default cleanSlice.reducer;
