import AcredittaAvatar from '../../atoms/AcredittaAvatar/index';
import ButtonActions from './ButtonActions';
import SpanBadgeStatus from './SpanBadgeStatus';

const customSortStatus = (rowA: any, rowB: any) => {
  const a = rowA.status.toLowerCase();
  const b = rowB.status.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const columns = [
  {
    name: '',
    width: '7%',
    cell: (row: any) => <AcredittaAvatar image={row.avatar} />,
  },
  {
    name: 'Nombre',
    selector: (row: any) => row.first_name + ' ' + row.last_name,
    sortable: true,
  },
  {
    name: 'Correo electrónico',
    width: '30%',
    selector: (row: any) => row.email,
    sortable: true,
  },
  {
    name: 'Emisión',
    selector: (row: any) => row.issued_at,
    sortable: true,
  },
  {
    name: 'Estatus',
    cell: (row: any) => <SpanBadgeStatus text={row.status} />,
    sortable: true,
    sortFunction: customSortStatus,
  },
  {
    name: 'Aceptación',
    width: '15%',
    selector: (row: any) => (row.accepted_at ? row.accepted_at : '-'),
    sortable: true,
  },
  {
    name: 'Acciones',
    cell: (row: any) => <ButtonActions data={row} />,
  },
];
