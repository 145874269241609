import AcredittaText from 'components/atoms/AcredittaText';
import icons from 'assets/Icon';
import './styles.css';
import AcredittaButton from 'components/atoms/AcredittaButton';

interface BadgeInfoI {
  badgeInfo: any;
  showSharemModal: () => void;
  userRol: any;
  handleMethodContact: any
}

const BadgeInfoComponent = ({ badgeInfo, showSharemModal, userRol, handleMethodContact }: BadgeInfoI) => {
  const handleClick = (url: string) => {
    window.open(url);
  };

  return (
    <>
      <div className="template-badge__info">
        <div className="template-badge__item">
          <icons.EducationIcon />
          <div className="template-badge__item__text">
            <AcredittaText fontWeight="bold" variant="body1">
              Tipo
            </AcredittaText>
            <AcredittaText variant="body1" color="secondary">
              {badgeInfo?.type_category !== null ? badgeInfo?.type_category : '---'}
            </AcredittaText>
          </div>
        </div>
        <div className="template-badge__item">
          <icons.LevelBlueIcon />
          <div className="template-badge__item__text">
            <AcredittaText fontWeight="bold" variant="body1">
              Nivel
            </AcredittaText>
            <AcredittaText variant="body1" color="secondary">
              {badgeInfo?.level !== null ? badgeInfo?.level : '---'}
            </AcredittaText>
          </div>
        </div>
        <div className="template-badge__item">
          <icons.DollarIcon />
          <div className="template-badge__item__text">
            <AcredittaText fontWeight="bold" variant="body1">
              Costo
            </AcredittaText>
            <AcredittaText variant="body1" color="secondary">
              {badgeInfo?.cost !== 0 ? 'Pago' : 'Gratis'}
            </AcredittaText>
          </div>
        </div>
      </div>
      <div className="container-template">
        <div className="container-informacion">
          <AcredittaText variant="h4">Descripción</AcredittaText>
          <AcredittaText color="secondary" variant="body1">
            {badgeInfo?.description}
          </AcredittaText>
        </div>
        <div className="container-informacion">
          <AcredittaText variant="h4">Habilidades</AcredittaText>
          <div className="habilidades-container">
            {badgeInfo?.skills.length !== 0 ? (
              badgeInfo?.skills.map((item: any, i: number) => (
                <span className="habilidad" key={i}>
                  {item}
                </span>
              ))
            ) : (
              <AcredittaText color="secondary" variant="body1">
                No hay Información disponible
              </AcredittaText>
            )}
          </div>
        </div>
        <div className="division">
          <div className="requisitos-container">
            <AcredittaText variant="h4">Requisitos</AcredittaText>
            {badgeInfo?.badge_template_activities.length !== 0 &&
              badgeInfo?.badge_template_activities.map((item: any, i: number) => (
                <div key={i} className="check_list">
                  <div className="check_list__icon">
                    <icons.CheckIcon />
                  </div>
                  <span>{item.title}</span>
                </div>
              ))}
          </div>
          <div className="estandar-container">
            <div>
              <AcredittaText variant="h4">Estándares</AcredittaText>
              {badgeInfo?.standard && badgeInfo?.standard.length > 0 ? (
                badgeInfo?.standard.map((item: any, i: number) => (
                  <div key={i} className="check_list">
                    <div className="check_list__icon">
                      <icons.CheckIcon />
                    </div>
                    <span className="link" onClick={() => handleClick(item.url)}>
                      {item.name}
                    </span>
                  </div>
                ))
              ) : (
                <div className="check_list">
                  <div className="check_list__icon">
                    <icons.CheckIcon />
                  </div>
                  <span>-</span>
                </div>
              )}
            </div>
            <div>
              <AcredittaText variant="h4">Tiempo invertido</AcredittaText>
              <div className="check_list">
                <div className="check_list__icon">
                  <icons.TimeIcon />
                </div>
                <span>{badgeInfo?.duration !== 0 ? `${badgeInfo?.duration} ${badgeInfo?.duration_type}` : '-'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-informacion">
          <AcredittaText variant="h4">Información adicional</AcredittaText>
          <div className="check_list">
            <icons.URLIcon />
            <span className="link" onClick={() => handleClick(badgeInfo?.additional_info)}>
              {badgeInfo?.additional_info}
            </span>
          </div>
        </div>
        <div className="boton-container">
          {userRol === 'ADMIN' ? (
            <AcredittaButton onPress={showSharemModal} variant="primary" title="Compartir" />
          ) : (
            <AcredittaButton onPress={handleMethodContact} variant="primary" title="Qué hacer para ganar esta insignia" />
          )}
        </div>
      </div>
    </>
  );
};

export default BadgeInfoComponent;
