import icons from 'assets/Icon';
import * as React from 'react';
import { ButtonWrapper, Container, SubtitleFirst, SubtitleSecondNoData } from './styles';
import Button from 'components/atoms/Button';
import { Link } from 'react-router-dom';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';
import { useAppSelector } from 'hooks/redux';

const NoDataPaths: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  return (
    <React.Fragment>
      <Container>
        <icons.Icono_rutas />
        <SubtitleFirst>No tienes rutas creadas</SubtitleFirst>
        <SubtitleSecondNoData>
          ¡Crea una ruta de aprendizaje y asignala a los integrantes de tu organización!
        </SubtitleSecondNoData>
        <ButtonWrapper>
          {user.userRole !== 3 ? (
            <Link to={AdminRole.newPathWizz}>
              <Button label={'Crear una ruta'} widthCustom={300} />
            </Link>
          ) : (
            <Link to={AccreditedRole.newPathWizz}>
              <Button label={'Crear una ruta'} widthCustom={300} />
            </Link>
          )}
        </ButtonWrapper>
      </Container>
    </React.Fragment>
  );
};

export default NoDataPaths;
