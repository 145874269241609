import * as React from 'react';
import CheckBox from 'components/atoms/CheckBox';
import { WarningText, LinkText } from 'components/molecules/RequestNewPassword/styles';
import { Controller, useForm } from 'react-hook-form';

import icons from 'assets/Icon';
import { policies, terms } from 'constants/TermsAndConditions';
import StyledButton from 'components/atoms/Button';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  TermsAndConditionsContainer,
  TermsAndConditions,
  TermsPageTitle,
  TermsTextContainer,
  TermsTitle,
  CheckBoxContainer,
} from 'components/organisms/Terms/styles';
import { objectValidation } from 'utils';
import { useAppDispatch } from 'hooks/redux';
import { AgreeTermsConditionsThunk } from 'actions/Thunks/AuthThunk';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AccreditedRole } from 'constants/NavigationUrls';

interface ITermsPageForm {
  terms: boolean;
}

const TermsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const defaultValuesNewPassword: ITermsPageForm = {
    terms: false,
  };

  const getDefaultFormValuesNewPassword = (): ITermsPageForm => {
    return defaultValuesNewPassword;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ITermsPageForm>({
    ...objectValidation,
    defaultValues: getDefaultFormValuesNewPassword(),
  });

  const agreeWithTermsAndConditions = async () => {
    const terms = await dispatch(AgreeTermsConditionsThunk());
    if (AgreeTermsConditionsThunk.fulfilled.match(terms)) {
      if (terms.payload) {
        toast.success(terms.payload);
        history.push(AccreditedRole.profile);
      }
    } else {
      toast.error(terms.payload as string);
    }
  };

  return (
    <CommonPageLayout showHeader={true} showFooter={false} childrenMargin={true}>
      <TermsAndConditionsContainer>
        <TermsAndConditions>
          <TermsPageTitle>Acepta para continuar</TermsPageTitle>
          <icons.TermsAndConditionsIcon />
          <CheckBoxContainer>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckBox
                  name="terms"
                  error={errors}
                  complement={
                    <React.Fragment>
                      <WarningText>He leído y acepto los </WarningText>
                      <LinkText>Términos y Condiciones</LinkText>
                      <WarningText> de uso y </WarningText>
                      <LinkText>Políticas de Privacidad</LinkText>
                    </React.Fragment>
                  }
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              name="terms"
              rules={{
                required: 'Para continuar debes aceptar los términos y condiciones',
              }}
            />
          </CheckBoxContainer>
          <StyledButton
            label="Aceptar"
            onClick={handleSubmit(agreeWithTermsAndConditions)}
            widthCustom={127}
          />
          <TermsTextContainer>
            <TermsTitle>Términos y Condiciones</TermsTitle>
            <p>{terms}</p>
          </TermsTextContainer>
          <TermsTextContainer>
            <TermsTitle>Políticas de Privacidad</TermsTitle>
            <p>{policies}</p>
          </TermsTextContainer>
        </TermsAndConditions>
      </TermsAndConditionsContainer>
    </CommonPageLayout>
  );
};

export default TermsPage;
