import * as React from 'react';
import {
  SkillsSectionTitle,
  SkillContainer,
  SkillName,
  SkillsWrapper,
  AdminSkillsListWrapper,
} from './styles';

interface AdminSkillsListProps {
  skills: IDetailPathwaySkills[];
}

const AdminSkillsList = ({ skills }: AdminSkillsListProps): JSX.Element => {
  return (
    <AdminSkillsListWrapper>
      <SkillsSectionTitle>Habilidades</SkillsSectionTitle>
      <SkillsWrapper>
        {skills &&
          skills.map((skill, index) => {
            return (
              <SkillContainer key={index}>
                <SkillName>{skill.name}</SkillName>
              </SkillContainer>
            );
          })}
      </SkillsWrapper>
    </AdminSkillsListWrapper>
  );
};

export default AdminSkillsList;
