import styled from 'styled-components';
import {
  BLUE_LIGHT,
  FONT_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY,
} from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { FormGroup, Label, Input } from 'reactstrap';
import { Theme } from 'react-select';

export const NewBadgeFormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const NewBadgeInstructionsTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
`;

export const SeeProfile = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 10px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
`;

export const FormLabelSimple = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export function styledTheme(theme: Theme) {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      primary: SECONDARY_COLOR,
      primary25: TERTIARY,
      neutral20: PRIMARY_COLOR,
      neutral30: PRIMARY_COLOR,
      neutral50: 'red',
    },
  };
}

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media ${device.mediumMobile} {
    flex-direction: column-reverse;
  }
`;

export const InfoText = styled(Label)`
  font-size: 14px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin-bottom: 20px;
  margin-top: 1px;
`;

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;
  display: flex;
  margin: 0;
  margin-bottom: 22px;

  @media ${device.laptops} {
    flex-direction: column;
  }
`;

export const FormLabelSimpleWrapper = styled.div`
  width: 20%;
  text-align: end;
  padding-right: 10px;
  margin: 0;

  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormDateLabelSimpleWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  margin: 0;
  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormInputWrapper = styled.div`
  width: 80%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const InputDate = styled(Input)`
  height: 20px;
`;

export const SwitchContainer = styled.div`
  width: '100%';
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 20px;
`;

export const InputSelectContainer = styled.div`
  width: 100%;
`;

export const SwitchLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin: 5px 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const SelectedSkillsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 130px;
`;

export const RequirementsContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
  margin: 10px 0px;
`;

export const RequirementContainer = styled.div`
  display: grid;
  grid-template-columns: 23% 95%;
  align-items: center;
`;

export const FormLabelRequirement = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const FormLabelRequirementWrapper = styled.div`
  width: 100%;
  text-align: end;
  padding-right: 10px;
  margin: 0;
`;

export const RequirementsDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const RequirementAddButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: self-start;
  justify-content: space-between;
`;

export const DeleteRequirementButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    width: 15px;
  }
`;

export const RequirementAddButton = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: #da291c;
  cursor: pointer;
`;

export const DeleteRequirementButton = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  margin: 5px;
`;

export const RequirementAddButtonTitle = styled(Label)`
  width: 23%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  text-align: end;
`;

export const InputWrapper = styled.div`
  padding-right: 10px;
`;

export const RequirementsWrapper = styled.div`
  margin-bottom: 40px;
`;

export const RequirementAddButtonWrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    margin-left: 10px;
  }
`;

export const ErrorLabel = styled.label`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;

export const MenuItemWrapperContainer = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MenuItemTitle = styled(Label)`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin-top: 10px;
`;

export const MenuItemText = styled(Label)`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${FONT_COLOR};
  margin-top: 10px;
`;
