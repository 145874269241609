import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col } from 'reactstrap';
import { SkeletonWrapper } from './styles';

const SkeletonInterest: React.FC = () => {
  return (
    <React.Fragment>
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <Col lg="3" sm="6" xs="12" md="4" key={item}>
          <SkeletonWrapper>
            <Skeleton height={170} />
          </SkeletonWrapper>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default SkeletonInterest;
