import * as React from 'react';
import Button from 'components/atoms/Button';
import {
  Control,
  Controller,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  FormContainer,
  ButtonContainer,
  WarningText,
  FormGroupStyled,
  FormInputWrapper,
  WarningHeaderWrapper,
  WarningMemoLabel,
} from './styles';
import TextInput from 'components/atoms/TextInput';
import { emailRegex } from 'utils';
import validator from 'validator';

interface NewOrganizationFormProps {
  controlInfo: Control<INewOrganizationInfoForm>;
  handleSubmitInfoForm: UseFormHandleSubmit<INewOrganizationInfoForm>;
  submitNewOrganizationForm: (formInfo: INewOrganizationInfoForm) => void;
  errorsInfo: Record<string, any>;
  loading: boolean;
  watch: UseFormWatch<INewOrganizationInfoForm>;
  setValue: UseFormSetValue<INewOrganizationInfoForm>;
}

const NewOrganizationForm: React.FC<NewOrganizationFormProps> = ({
  controlInfo,
  handleSubmitInfoForm,
  errorsInfo,
  submitNewOrganizationForm,
  loading,
}) => {
  return (
    <FormContainer>
      <WarningHeaderWrapper>
        <WarningMemoLabel>
          Ingresa los datos de la organización para conectar diferentes oportunidades de aprendizaje
          y desarrollo de habilidades.
        </WarningMemoLabel>
        <WarningText>Datos de la organización</WarningText>
      </WarningHeaderWrapper>
      <FormGroupStyled row>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="name"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={50}
                withError
                label="Nombre"
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="email"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={50}
                withError
                label="Correo"
              />
            )}
            name="email"
            rules={{
              required: 'Campo requerido',
              pattern: {
                value: emailRegex,
                message: 'Email inválido',
              },
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="domain"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={50}
                withError
                label="Página Web"
              />
            )}
            name="domain"
            rules={{
              required: 'Campo requerido',
              validate: {
                checkUrl: (v) => validator.isURL(v) || 'Debes ingresar una página web valida',
              },
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <ButtonContainer>
        <Button
          onClick={handleSubmitInfoForm(submitNewOrganizationForm)}
          label={'Siguiente'}
          loading={loading}
          disabled={false}
        ></Button>
      </ButtonContainer>
    </FormContainer>
  );
};

export default NewOrganizationForm;
