import {
  CountryListThunk,
  ListDocumentTypesThunk,
  ListUserTypeThunk,
} from 'actions/Thunks/AdminThunk';
import StyledButton from 'components/atoms/Button';
import InputRadioOption from 'components/atoms/InputRadioOption';
import InputSelect from 'components/atoms/InputSelect';
import TextInput from 'components/atoms/TextInput';
import { S3_ADMIN } from 'constants/index';
import { device } from 'constants/responsiveDevice';
import { useAppDispatch } from 'hooks/redux';
import * as React from 'react';
import { Control, Controller, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import { CardBody, Col, Form } from 'reactstrap';
import { emailRegex } from 'utils';
import {
  AlternativeWarningMessage,
  ButtonWrapper,
  ButtonWrapperChild,
  CheckboxWrapper,
  CreateUserCard,
  CreateUserCardImage,
  CreateUserWrapper,
  ErrorMessageLabel,
  InputLabelWrapper,
  InputsLabel,
  InputWrapper,
  InputWrapperMessageProfile,
  LabelText,
  MessageInfoProfileWrapperChild,
  TitleWarningMessage,
} from './styles';

interface AdminCreateUserCardProps {
  handleSubmit: UseFormHandleSubmit<ICreateUserForm>;
  control: Control<ICreateUserForm>;
  errors: Record<string, any>;
  onCreate: (formData: ICreateUserForm) => void;
  watch: UseFormWatch<ICreateUserForm>;
  onGoBack: () => void;
}

const AdminCreateUserCard: React.FC<AdminCreateUserCardProps> = ({
  control,
  errors,
  handleSubmit,
  onCreate,
  watch,
  onGoBack,
}) => {
  const dispatch = useAppDispatch();
  const country = watch().country;
  const userTypeRole = watch().userRole;
  const responsive = useMediaQuery({ query: device.smallMobile });

  const [documentTypeListData, setDocumentTypeListData] = React.useState<IDocumentType[] | null>(
    null
  );

  const loadCountries = () => {
    return new Promise((resolve) => {
      dispatch(CountryListThunk()).then((countries) => {
        if (CountryListThunk.fulfilled.match(countries)) {
          resolve(countries.payload);
        } else {
          toast.error(countries.payload as string);
        }
      });
    });
  };

  const loadUserType = () => {
    return new Promise((resolve) => {
      dispatch(ListUserTypeThunk()).then((userTypes) => {
        if (ListUserTypeThunk.fulfilled.match(userTypes)) {
          resolve(userTypes.payload);
        } else {
          toast.error(userTypes.payload as string);
        }
      });
    });
  };

  const documentTypeList = React.useCallback(
    async (country: ISelectOptionValue | null) => {
      if (country && country.value) {
        const documentList = await dispatch(ListDocumentTypesThunk(country.value));

        if (ListDocumentTypesThunk.fulfilled.match(documentList)) {
          setDocumentTypeListData(documentList.payload);
        } else {
          toast.error(documentList.payload as string);
        }
      }
    },
    [dispatch]
  );

  const ButtonWidthResponsive = React.useMemo(() => {
    if (!responsive) {
      return 151;
    }

    return undefined;
  }, [responsive]);

  React.useEffect(() => {
    documentTypeList(country);
  }, [country, documentTypeList]);

  return (
    <CreateUserCard>
      <CreateUserWrapper>
        <CreateUserCardImage top src={`${S3_ADMIN}AddSingleUser.svg`} alt="Card image cap" />
      </CreateUserWrapper>
      <CardBody>
        <TitleWarningMessage>
          Completa la siguiente información para subir un empleado a tu organización
        </TitleWarningMessage>
        <br />
        <AlternativeWarningMessage>
          Recuerda que los campos marcados con * son obligatorios
        </AlternativeWarningMessage>
        <Form>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Nombres *</InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  error={errors}
                  name="firstName"
                  type="text"
                  onChange={(value) => onChange(value)}
                  value={value}
                  height={38}
                  withError
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Apellidos *</InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  error={errors}
                  name="lastName"
                  type="text"
                  onChange={(value) => onChange(value)}
                  value={value}
                  height={38}
                  withError
                />
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Correo *</InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  error={errors}
                  name="email"
                  type="email"
                  onChange={(value) => onChange(value)}
                  value={value}
                  height={38}
                  withError
                />
              )}
              rules={{
                required: 'Campo requerido',
                pattern: {
                  value: emailRegex,
                  message: 'Email inválido',
                },
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>País </InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="country"
              render={({ field: { onChange, value } }) => (
                <div style={{ width: '100%' }}>
                  <InputSelect
                    onChange={(value) => onChange(value)}
                    value={value}
                    loadOptions={loadCountries}
                    placeholder=""
                    asyncSelect={true}
                    error={errors}
                    name="country"
                    defaultOptions
                  />
                </div>
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Identificación </InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="document"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  error={errors}
                  name="document"
                  type="text"
                  onChange={(value) => onChange(value)}
                  value={value ? value : undefined}
                  height={38}
                  withError
                />
              )}
            />
          </InputWrapper>
          {documentTypeListData && (
            <InputWrapper>
              <InputLabelWrapper />
              <CheckboxWrapper>
                <Controller
                  name="typeDocument"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <React.Fragment>
                      {documentTypeListData.map((documentType, index) => (
                        <Col sm={documentTypeListData.length > 1 ? 6 : 12} key={index}>
                          <InputRadioOption
                            label={documentType.name}
                            value={documentType.id}
                            onChange={(value) => onChange(value)}
                          />
                        </Col>
                      ))}
                      {errors['typeDocument']?.type && (
                        <Col sm={12}>
                          <ErrorMessageLabel>{errors['typeDocument']?.message}</ErrorMessageLabel>
                        </Col>
                      )}
                    </React.Fragment>
                  )}
                />
              </CheckboxWrapper>
            </InputWrapper>
          )}
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Cargo </InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="jobTitle"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  error={errors}
                  name="jobTitle"
                  type="text"
                  onChange={(value) => onChange(value)}
                  value={value ? value : undefined}
                  height={38}
                  withError
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Departamento</InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="department"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  error={errors}
                  name="department"
                  type="text"
                  onChange={(value) => onChange(value)}
                  value={value ? value : undefined}
                  height={38}
                  withError
                />
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabelWrapper>
              <InputsLabel>Tipo de Usuario *</InputsLabel>
            </InputLabelWrapper>
            <Controller
              control={control}
              name="userRole"
              render={({ field: { onChange, value } }) => (
                <div style={{ width: '100%' }}>
                  <InputSelect
                    onChange={(value) => onChange(value)}
                    value={value}
                    loadOptions={loadUserType}
                    placeholder=""
                    asyncSelect={true}
                    error={errors}
                    name="userRole"
                    defaultOptions
                  />
                </div>
              )}
              rules={{ required: 'Campo requerido' }}
            />
          </InputWrapper>
          {userTypeRole && userTypeRole.value === 1 && (
            <React.Fragment>
              <InputWrapper>
                <InputLabelWrapper>
                  <InputsLabel>Asignar un Área</InputsLabel>
                </InputLabelWrapper>
                <Controller
                  control={control}
                  name="userRoleLeaderArea"
                  render={({ field: { onChange, value } }) => (
                    <div style={{ width: '100%' }}>
                      <TextInput
                        error={errors}
                        name="userRoleLeaderArea"
                        type="text"
                        onChange={(value) => onChange(value)}
                        value={value}
                        height={38}
                        withError
                      />
                    </div>
                  )}
                  defaultValue=""
                  rules={{ required: 'Campo requerido' }}
                />
              </InputWrapper>
            </React.Fragment>
          )}
          <InputWrapperMessageProfile>
            <MessageInfoProfileWrapperChild>
              <LabelText>
                *El tipo de usuario <b>Administrador</b> tiene la capacidad de modificar datos de la
                organización, crear y emitir insignias a los usuarios acreditados, crear y asignar
                rutas o cursos y ver un análisis total de organización.
              </LabelText>
            </MessageInfoProfileWrapperChild>
          </InputWrapperMessageProfile>
          <InputWrapperMessageProfile>
            <MessageInfoProfileWrapperChild>
              <LabelText>
                *El tipo de usuario <b>Líder de Área</b> tiene la capacidad de emitir insignias a
                los usuarios acreditados, crear y asignar rutas o cursos y ver un análisis general
                del área que se le ha asignado.
              </LabelText>
            </MessageInfoProfileWrapperChild>
          </InputWrapperMessageProfile>
          <InputWrapperMessageProfile>
            <MessageInfoProfileWrapperChild>
              <LabelText>
                *El tipo de usuario <b>Acreditado</b> tiene la posibilidad de desarrollar rutas y
                cursos asignados, obtener insignias y mejorar sus habilidades.
              </LabelText>
            </MessageInfoProfileWrapperChild>
          </InputWrapperMessageProfile>
          <ButtonWrapper>
            <ButtonWrapperChild>
              <StyledButton
                label="Cancelar"
                lightMode={true}
                widthCustom={ButtonWidthResponsive}
                onClick={onGoBack}
              />
            </ButtonWrapperChild>
            <ButtonWrapperChild>
              <StyledButton
                label="Siguiente"
                widthCustom={ButtonWidthResponsive}
                onClick={handleSubmit(onCreate)}
              />
            </ButtonWrapperChild>
          </ButtonWrapper>
        </Form>
      </CardBody>
    </CreateUserCard>
  );
};

export default AdminCreateUserCard;
