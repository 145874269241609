import * as React from 'react';
import { CommonMenuContainer, NavItemStyled, NavLinkStyled, NavStyled } from './styles';

const TopPageMenu: React.FC = () => {
  return (
    <CommonMenuContainer>
      <NavStyled>
        <NavItemStyled>
          <NavLinkStyled to="#">Catálogo</NavLinkStyled>
        </NavItemStyled>
        <NavItemStyled>
          <NavLinkStyled to="#">Rutas</NavLinkStyled>
        </NavItemStyled>
        <NavItemStyled>
          <NavLinkStyled to="#">Guardados</NavLinkStyled>
        </NavItemStyled>
        <NavItemStyled>
          <NavLinkStyled to="#">Peticiones</NavLinkStyled>
        </NavItemStyled>
        <NavItemStyled>
          <NavLinkStyled to="#">Historial</NavLinkStyled>
        </NavItemStyled>
      </NavStyled>
    </CommonMenuContainer>
  );
};

export default TopPageMenu;
