import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetAnalyticDataContent } from "actions/ApiClient/AnalyticsActions";
import { GetErrorMessage } from "utils";

export const AnalyticsDataContentThunk = createAsyncThunk(
    'admin/analytics-content',
    async (_, { rejectWithValue }) => {
      try {
        const dataContent = await GetAnalyticDataContent();
        return dataContent;
      } catch (error) {
        return rejectWithValue(GetErrorMessage(error));
      }
    }
  );