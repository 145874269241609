import styled from 'styled-components';
import { Col, Form, Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const ColButtonContainer = styled(Col)`
  text-align: center;
`;

export const LinkText = styled.a`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const WarningText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const RequestNewPasswordTitle = styled(Label)`
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: ${PRIMARY_COLOR};
  font-weight: 800;
  line-height: normal;

  @media ${device.smallMobile} {
    font-size: 35px;
  }
`;

export const RequestNewPasswordContent = styled(Label)`
  text-align: center;
  margin-top: 29px;
  margin-bottom: 50px;
`;

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormMainWrapper = styled.div`
  width: 50%;

  @media ${device.laptops} {
    width: 70%;
  }

  @media ${device.mediumLaptop} {
    width: 90%;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;
