import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0 10px 30px 5px rgba(138, 149, 158, 0.15);
  border-radius: 8px;
  margin-bottom: 15px;
  cursor: pointer;
  height: 260px;
`;

export const CourseTitle = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  margin: 0 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${device.bigMobile} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const CourseTextItem = styled(Label)`
  font-size: 14px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 2px;
  margin-left: 4px;
  @media ${device.laptops} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const CoursePrice = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
  line-height: 15px;
  color: ${PRIMARY_COLOR};
  text-align: center;
`;

export const CardInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 10px;
  svg {
    margin-right: 4px;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 10px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 126px;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 1px;
`;

export const CourseTitleWrapper = styled.div`
  width: 100%;
`;

export const CompanyNameWrapper = styled.div<{
  $backgroundColor: string;
}>`
  position: absolute;
  bottom: 0;
  right: 10px;
  background-color: ${(props) =>
    props.$backgroundColor ? `#${props.$backgroundColor}` : `${PRIMARY_COLOR}`};
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px;
`;

export const CompanyName = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 12px;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
`;

export const RatingWrapper = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;
