import * as React from 'react';
import AdminPreviewBadgesUsers from 'components/organisms/AdminPreviewBadgesUsers';
import { useParams } from 'react-router-dom';

const PreviewBadgesUsers: React.FC = () => {
  let { name } = useParams<{ name: string }>();
  let { badgeId } = useParams<{ badgeId: string }>();
  return <AdminPreviewBadgesUsers badge_id={badgeId} name={name} />;
};

export default PreviewBadgesUsers;
