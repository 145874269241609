import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';

export const InputLabelWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;
  margin-right: 11px;
  width: 20%;

  @media ${device.ipad} {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const InputsLabel = styled(Label)<{ $height: number }>`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  height: ${(props) => props.$height}px;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin: 20px 0;

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const ShareBadgeEmailContainer = styled.div`
  margin: 40px 10px;
`;

export const ShareBadgeEmailTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 10px !important;
  }
`;

export const ErrorLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  margin-left: 18%;
`;
