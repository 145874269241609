import * as yup from 'yup';

export const validationSchemaProfileEdit = yup.object().shape({
  name: yup.string().required('El nombre de la organización es requerido'),
  description: yup.string().required('La descripción es requerida'),
  website: yup.string().required('El sitio web es requerido'),
  nickname: yup
    .string()
    .required('El Nombre de usuario es requerido')
    .matches(/^[A-Za-z0-9_-]+$/, 'Ingrese solo letras y números'),
  contactEmail: yup.string().email('Ingrese un correo electrónico válido').nullable(),
});
