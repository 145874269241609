import * as React from 'react';
import { useState } from 'react';
import {
  IssuerInfo,
  IssuerName,
  BadgeDetailsResumenInfoContainer,
  BadgeImageWrapper,
  BadgeResumenInfoWrapper,
  BadgeResumenItemWrapper,
  BadgeResumenItem,
  BadgeResumenItemTitle,
  BadgeResumenItemDesc,
} from './styles';
import icons from 'assets/Icon';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { useAppSelector } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import { AccreditedRole, AdminRole, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import DownloadCertificatePdf from '../DowloadCerticatedPdf';
import ModalSharedOnLinkedin from '../ModalSharedOnLinkedin';
import './styles.css';

interface BadgeDetailsResumenInfoProps {
  badge_details: IBadgeDetailsResumenInfo;
  badge_url: string;
  organization_badge?: boolean;
  badgeId?: string;
}

const BadgeDetailsResumenInfo = ({
  badge_details,
  badge_url,
  organization_badge,
  badgeId,
}: BadgeDetailsResumenInfoProps): JSX.Element => {
  const [showShareButtons, setShowShareButtons] = React.useState<boolean>(false);

  const history = useHistory();
  const user = useAppSelector((state) => state.user.userInfo);
  const userRole = useAppSelector((state) => state.user.userRole);

  // FIXME: defined but never used
  // const durations = [
  //   { label: 'Años', value: 1 },
  //   { label: 'Días', value: 2 },
  //   { label: 'Meses', value: 3 },
  //   { label: 'Horas', value: 4 },
  //   { label: 'Semanas', value: 5 },
  // ];

  // FIXME: defined but never used
  // const result = durations.filter(
  //   (duration) => duration.label === badge_details.details.duration_type.toString()
  // );

  const [showModal, setShowModal] = useState(false);

  const navigateToOrganization = () => {
    if (user) {
      if (user.user_type.code === 'ACCREDITED') {
        history.push(`${AccreditedRole.organizationBadges}`);
      } else {
        history.push(`${AdminRole.organizationBadges}`);
      }
    } else {
      history.push(`${NonAuthenticatedUrls.organization_badges}`);
    }
  };

  const handleToogleModalShareLinkedin = () => {
    setShowModal(!showModal);
  };

  return (
    <BadgeDetailsResumenInfoContainer>
      {showModal && (
        <ModalSharedOnLinkedin
          badgeId={badgeId}
          image={badge_details.image}
          badgeName={badge_details.name}
          isOpen={showModal}
          onClose={handleToogleModalShareLinkedin}
        />
      )}

      <BadgeImageWrapper
        $selected={showShareButtons}
        onMouseEnter={() => setShowShareButtons(true)}
        onMouseLeave={() => setShowShareButtons(false)}
      >
        <img src={badge_details.image} alt={`${badge_details.name}-icon`} />
        <div className="badge-container">
          <div className="badge-share__container">
            <span onClick={handleToogleModalShareLinkedin}>
              <LinkedinIcon bgStyle={{ fill: '#e2231a' }} size={42} round={true} />
            </span>
            <TwitterShareButton title="Mira mi nueva insignia!" url={badge_url}>
              <TwitterIcon bgStyle={{ fill: '#e2231a' }} size={42} round={true} />
            </TwitterShareButton>
            <FacebookShareButton quote="Mira mi nueva insignia" url={badge_url}>
              <FacebookIcon bgStyle={{ fill: '#e2231a' }} size={42} round={true} />
            </FacebookShareButton>
          </div>
          <IssuerInfo>Emitido por:</IssuerInfo>
          {userRole !== 3 ? (
            <IssuerName onClick={navigateToOrganization}>{badge_details.issuer}</IssuerName>
          ) : (
            <IssuerName>{badge_details.issuer}</IssuerName>
          )}
        </div>
      </BadgeImageWrapper>
      <BadgeResumenInfoWrapper>
        <BadgeResumenItemWrapper>
          <div>
            <icons.EducationIcon />
          </div>
          <BadgeResumenItem>
            <BadgeResumenItemTitle>Tipo</BadgeResumenItemTitle>
            {badge_details.details.type !== undefined &&
            badge_details.details.type !== null &&
            badge_details.details.type.length !== 0 ? (
              <BadgeResumenItemDesc>{badge_details.details.type}</BadgeResumenItemDesc>
            ) : (
              <BadgeResumenItemDesc>-</BadgeResumenItemDesc>
            )}
          </BadgeResumenItem>
        </BadgeResumenItemWrapper>
        <BadgeResumenItemWrapper>
          <div>
            <icons.DollarIcon />
          </div>
          <BadgeResumenItem>
            <BadgeResumenItemTitle>Costo</BadgeResumenItemTitle>
            {badge_details.details.cost !== undefined && badge_details.details.cost !== null ? (
              badge_details.details.cost !== 0 ? (
                <BadgeResumenItemDesc>Pago</BadgeResumenItemDesc>
              ) : (
                <BadgeResumenItemDesc>Gratis</BadgeResumenItemDesc>
              )
            ) : (
              <BadgeResumenItemDesc>-</BadgeResumenItemDesc>
            )}
          </BadgeResumenItem>
        </BadgeResumenItemWrapper>
        <BadgeResumenItemWrapper>
          <div>
            <icons.LevelBlueIcon />
          </div>
          <BadgeResumenItem>
            <BadgeResumenItemTitle>Nivel</BadgeResumenItemTitle>
            {badge_details.details.level !== undefined && badge_details.details.level !== null ? (
              <BadgeResumenItemDesc>{badge_details.details.level}</BadgeResumenItemDesc>
            ) : (
              <BadgeResumenItemDesc>-</BadgeResumenItemDesc>
            )}
          </BadgeResumenItem>
        </BadgeResumenItemWrapper>
        <BadgeResumenItemWrapper>
          <div>
            <icons.CalendarBlueIcon />
          </div>
          <BadgeResumenItem>
            {organization_badge ? (
              <div>
                <BadgeResumenItemTitle>Creación</BadgeResumenItemTitle>
                <br />
                <BadgeResumenItemDesc>
                  {badge_details.details.issue_date.substring(0, 10)}
                </BadgeResumenItemDesc>
              </div>
            ) : (
              <div>
                <BadgeResumenItemTitle>Emisión</BadgeResumenItemTitle>
                <br />
                <BadgeResumenItemDesc>
                  {badge_details.details.issue_date.substring(0, 10)}
                </BadgeResumenItemDesc>
              </div>
            )}
          </BadgeResumenItem>
        </BadgeResumenItemWrapper>
        <BadgeResumenItemWrapper>
          <div>
            <icons.CalendarBlueIcon />
          </div>
          <BadgeResumenItem>
            <BadgeResumenItemTitle>Duración</BadgeResumenItemTitle>
            {badge_details.details.duration !== undefined &&
            badge_details.details.duration !== null &&
            badge_details.details.duration.length !== 0 ? (
              <BadgeResumenItemDesc>
                {badge_details.details.duration + ' ' + badge_details.details.duration_type}
              </BadgeResumenItemDesc>
            ) : (
              <BadgeResumenItemDesc>-</BadgeResumenItemDesc>
            )}
          </BadgeResumenItem>
        </BadgeResumenItemWrapper>
      </BadgeResumenInfoWrapper>

      {badgeId && <DownloadCertificatePdf badgeId={badgeId} />}
    </BadgeDetailsResumenInfoContainer>
  );
};

export default BadgeDetailsResumenInfo;
