import icons from 'assets/Icon';
import AcredittaText from 'components/atoms/AcredittaText';
import AcredittaButton from 'components/atoms/AcredittaButton';
import { useContext } from 'react';
import { BadgesStackableContext } from '../context/BadgeStackableProvider';
import BadgeRow from './BadgeRow';
import 'react-circular-progressbar/dist/styles.css';
import BadgeMainImage from './BadgeMainImage';
import BadgeMoreInfo from './BadgeMoreInfo';
import { useState } from 'react';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { AdminRole } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';
import ShareBadgeModal from 'components/molecules/ShareBadgeModal/ShareBadgeModal';
import LinkPublicProfileProvider from 'components/atoms/shared/LinkPublicProfileProvider';

const BadgesStackableDetail = () => {
  const { mapInSlices, collections, mainBadge, ready } = useContext(BadgesStackableContext);
  const { name } = mainBadge;
  const [show, setShow] = useState(false);
  const [showShareScreen, setShowShareScreen] = useState(false);

  if (!ready) {
    return (
      <div style={{ marginTop: '50px' }}>
        <AcredittaLoader />
      </div>
    );
  }

  return (
    <>
      <ShareBadgeModal
        badgeId={mainBadge?.uuid}
        badge_details={mainBadge}
        showModal={showShareScreen}
        handleSocialModal={() => setShowShareScreen(false)}
        isTemplate={true}
      />
      <div className="container mt-4" style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: 18, zIndex: 999 }}>
          <Link to={AdminRole.badgesPreview}>
            <icons.GoBackIcon />
          </Link>
        </div>
        <div className="row">
          <div className="col-md-12 ml-4">
            <AcredittaText variant="h3" align="center">
              {name}
            </AcredittaText>
          </div>
        </div>
        <div className="row mb-3" onClick={() => setShow(true)}>
          <div className="col-sm-12">
            <BadgeMainImage />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <LinkPublicProfileProvider provider={mainBadge.provider} />
          </div>
        </div>
        <div style={{ display: show ? 'block' : 'none' }}>
          <BadgeMoreInfo badge={mainBadge} onClose={() => setShow(false)} />
        </div>

        {mapInSlices(collections, 5, (slice: any, i) => (
          <BadgeRow slice={slice} i={i} key={i} />
        ))}

        <div className="row text-center mt-3">
          <div className="col">
            <AcredittaButton
              title="Compartir"
              icon={() => <icons.ShareIcon />}
              variant="primary"
              type="button"
              onPress={() => setShowShareScreen(true)}
            />
          </div>
        </div>
      </div>
      <div style={{ height: '100px' }} />
    </>
  );
};

export default BadgesStackableDetail;
