import * as React from 'react';
import icons from 'assets/Icon';
import Switch from 'react-switch';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import {
  ExperienceInfoWrapper,
  ExperienceNameWrapper,
  ExperienceName,
  InfoWrapper,
  SwitchLabel,
  SwitchContainer,
  ExperiencesContainer,
} from './styles';

interface CourseExperiencesWrapperProps {
  experiences: IPathExperiences[];
}

const CourseExperiencesWrapper = ({ experiences }: CourseExperiencesWrapperProps): JSX.Element => {
  const [pathExperiences, setPathExperiences] = React.useState<IPathExperiences[]>(experiences);

  const mediumDevice = useMediaQuery({ query: device.mediumLaptop });

  const handleExperience = (experience: IPathExperiences, index: number) => {
    let helper: IPathExperiences[] = [];
    experience = { ...experience, is_required: !experience.is_required };
    pathExperiences.map((_, indexExperince) => {
      if (indexExperince === index) {
        return (helper = [...helper, experience]);
      } else {
        return (helper = [...helper, pathExperiences[indexExperince]]);
      }
    });
    setPathExperiences(helper);
  };

  React.useEffect(() => {
    setPathExperiences(experiences);
  }, [experiences]);

  return (
    <ExperiencesContainer>
      {pathExperiences &&
        pathExperiences.map((experience, index) => {
          return (
            <div key={index}>
              <ExperienceInfoWrapper key={index}>
                <ExperienceNameWrapper>
                  <icons.MoveIcon />
                  <ExperienceName>{experience.name}</ExperienceName>
                </ExperienceNameWrapper>
                <SwitchContainer>
                  {!mediumDevice && <SwitchLabel> No</SwitchLabel>}
                  <Switch
                    onChange={() => handleExperience(experience, index)}
                    checked={experience.is_required}
                    onColor={PRIMARY_COLOR}
                    uncheckedIcon={false}
                    height={20}
                    width={38}
                  />
                  {!mediumDevice && <SwitchLabel> Si</SwitchLabel>}
                </SwitchContainer>
                {!mediumDevice && (
                  <InfoWrapper>
                    <icons.PlaySmallButton />
                    <span>{experience.duration}</span>
                  </InfoWrapper>
                )}
              </ExperienceInfoWrapper>
            </div>
          );
        })}
    </ExperiencesContainer>
  );
};

export default CourseExperiencesWrapper;
