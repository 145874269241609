import * as React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import {
  TextInputLabelContainer,
  TextInputLabel,
  WrapperWithErrorHandling,
  ErrorLabel,
  Search
} from './styles';

interface TextPhoneInputProps extends PhoneInputProps {
  name: string;
  error: Record<string, any>;
  label?: string;
  icon?: React.ReactElement;
  withError?: boolean;
  searchIcon?: React.ReactElement;
}

const TextPhoneInput: React.FC<TextPhoneInputProps> = ({ value, onChange, name, error, label, icon, withError, searchIcon  }) => {
  return (
    <React.Fragment>
      {label && (
        <TextInputLabelContainer>
          {icon}
          <TextInputLabel>{label}</TextInputLabel>
        </TextInputLabelContainer>
      )}
      {searchIcon && <Search>{searchIcon}</Search>}
      <WrapperWithErrorHandling>
      <PhoneInput
        country={'co'}
        value={value}
        onChange={onChange}
        enableSearch={true}
        countryCodeEditable={false}
        inputStyle={{
          width: "100%",
          height: "56px",
          fontSize: "15px",
          paddingLeft: "60px",
          borderRadius: "5px"
        }}
        inputProps={{
          name: name,
          required: false,
          autoFocus: false
        }}
      />
      {withError && error[name]?.type && <ErrorLabel>{error[name]?.message}</ErrorLabel>}
      </WrapperWithErrorHandling>
    </React.Fragment>
  );
};

export default TextPhoneInput;
