import { useState, useEffect } from 'react';
import './styles.css';
import { CreateNewTag, DeleteTag, ListTags, UpdateTag } from 'actions/ApiClient/OnboardingActions';
import { manageErrors } from 'helpers/manage-error';
import { Modal } from 'reactstrap';
import { useForm } from 'react-hook-form';
import icons from 'assets/Icon';
import AcredittaButton from '../AcredittaButton';
import toast from 'react-hot-toast';
import AcredittaText from '../AcredittaText';

type InputSelectTagsProps = {
  onChangeInputValues: (values: any) => void;
  loadOptions?: any;
};
const colors = [
  '#00C6AB',
  '#026842',
  '#0303B5',
  '#4B1C71',
  '#5CABCD',
  '#84BE09',
  '#9A0000',
  '#9AB4FF',
  '#B15FE4',
  '#B87400',
  '#C04276',
  '#DC143C',
  '#FF9393',
  '#FF9800',
  '#FFBF00',
];

const InputSelectTags = ({ onChangeInputValues, loadOptions }: InputSelectTagsProps) => {
  const [value, setValue] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState('');
  const [errorInModal, setErrorInModal] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState<any>({ open: false, tag: '' });
  const [colorSelected, setColorSelected] = useState('');
  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    onChangeInputValues(value);
  }, [value, onChangeInputValues]);

  useEffect(() => {
    if (loadOptions && loadOptions?.length > 0) {
      setValue(loadOptions);
    }
  }, [loadOptions]);

  const getColorRandom = () => {
    const random = Math.floor(Math.random() * colors.length);
    return colors[random];
  };

  const handleCreate = async (inputValue: string) => {
    try {
      const obj = {
        name: inputValue,
        color: getColorRandom(),
      };
      const res: any = await CreateNewTag(obj);
      const newBage = {
        label: res.data.name,
        name: res.data.name,
        id: res.data.id,
        color: res.data.color,
      };
      setValue([...value, newBage]);
      setData(data.filter((item: any) => item.label !== 'crear'));
      setInputValue('');
    } catch (e) {
      manageErrors(e);
    }
  };

  const getListTags = async (input: string) => {
    setInputValue(input);
    if (input.trim() !== '') {
      const resp = await ListTags();
      const filter = resp.filter((tag: any) =>
        tag.name.toLowerCase().includes(input.toLowerCase().trim())
      );
      setData(filter);
      if (data.find((tag: any) => tag.name === input.trim()) === undefined && input !== '') {
        setData([
          ...filter,
          { value: input.trim(), name: `Crear etiqueta "${input.trim()}"`, label: 'crear' },
        ]);
      }
    }

    if (input.trim() === '') {
      setTimeout(() => {
        setData([]);
      }, 800);
    }
    setError('');
  };

  const handleSelectTag = (tag: any) => {
    if (value.find((item: any) => item.id === tag.id)) {
      return setError('Ya agregaste esta etiqueta');
    }
    setValue([...value, tag]);
    setError('');
    setInputValue('');
    setData([]);
  };

  const handleRemoveTag = (tag: any) => {
    const newData = value.filter((item: any) => item.id !== tag.id);
    setValue(newData);
    setError('');
  };

  const handleTagAction = (e: any, tag: any, action: string) => {
    e.stopPropagation();
    setColorSelected(tag.color);
    setDropdownOpen({ open: true, tag, action });
    if (action === 'editar') {
      reset({ name: tag.name });
    }
  };

  const onSubmitForm = async (newdata: any) => {
    const res = await UpdateTag(newdata, dropdownOpen.tag.id);
    if (res.okay) {
      getListTags(inputValue);
      setDropdownOpen({ ...dropdownOpen, open: false });
      toast.success('Etiqueta actualizada correctamente');
      if (value.find((item: any) => item.id === dropdownOpen.tag.id)) {
        const filter = value.filter((item: any) => item.id !== dropdownOpen.tag.id);
        setValue([...filter, res.data]);
      }
      setInputValue('');
      setTimeout(() => {
        setData([]);
      }, 800);
    }
  };

  const handleDeleteTag = async (tag: any) => {
    const res = await DeleteTag(tag);
    if (res.okay) {
      const filter = value.filter((item: any) => item.id !== dropdownOpen.tag.id);
      setValue(filter);
      toast.success('Etiqueta eliminada correctamente');
      setDropdownOpen({ ...dropdownOpen, open: false });
      setInputValue('');
      setTimeout(() => {
        setData([]);
      }, 800);
    }
  };

  return (
    <>
      <div className="input__container">
        <div className="input__value">
          {value.map((item: any) => (
            <span
              style={{ border: `2px solid ${item.color}` }}
              key={item.id}
              className="tag input__tag"
            >
              <span style={{ color: `${item.color}` }}>{item.name}</span>
              <button onClick={() => handleRemoveTag(item)}>
                <icons.DeleteIconTag style={{ stroke: `${item.color}` }} />
              </button>
            </span>
          ))}
          <input
            className="input__text"
            placeholder="Buscar etiquetas..."
            value={inputValue}
            type="text"
            onChange={(input: any) => getListTags(input.target.value)}
          />
        </div>
      </div>
      <p className="errors">{error !== '' && error}</p>
      <div className="tags__container">
        {data?.map((item: any) => (
          <>
            {item.label === 'crear' && inputValue !== '' && (
              <p className="tag crear__tag" onClick={() => handleCreate(item.value)}>
                {item.name}
              </p>
            )}

            {item.label !== 'crear' && (
              <span
                className="tag tags__tag"
                style={{ border: `2px solid ${item.color}` }}
                onClick={() => handleSelectTag(item)}
                key={item.id}
              >
                <span style={{ color: `${item.color}` }}>{item.name}</span>
                <div className="tags__tag-buttons">
                  <button
                    className="button__tag editar__button"
                    onClick={(event) => handleTagAction(event, item, 'editar')}
                  >
                    <icons.EditPath />
                  </button>
                  <span className="divisor__line"></span>
                  <button
                    className="button__tag delete__button"
                    onClick={(event) => handleTagAction(event, item, 'borrar')}
                  >
                    <icons.DeleteBlueIcon />
                  </button>
                </div>
              </span>
            )}
          </>
        ))}
      </div>
      <Modal
        centered={true}
        isOpen={dropdownOpen.open}
        toggle={() => setDropdownOpen({ ...dropdownOpen, open: false })}
      >
        {dropdownOpen.action === 'editar' ? (
          <form
            className="modal__container"
            onSubmit={handleSubmit((tForm) => onSubmitForm({ ...tForm, color: colorSelected }))}
          >
            <AcredittaText variant={'h5'}>Editar etiqueta</AcredittaText>
            <input onClick={() => setErrorInModal('')} type="text" {...register('name')} />
            {errorInModal !== '' && <p className="errors">{errorInModal}</p>}
            <div className="modal__colors-container">
              {colors.map((color: any, index) => (
                <span
                  className={`color ${colorSelected === color && 'selected'}`}
                  key={index}
                  onClick={() => setColorSelected(color)}
                  style={{ backgroundColor: color }}
                ></span>
              ))}
            </div>
            <AcredittaButton title="Guardar" variant="primary" />
          </form>
        ) : (
          <div className="modal__container">
            <AcredittaText variant={'h5'}>¿Estás seguro de eliminar esta etiqueta?</AcredittaText>
            <span className="tag" style={{ border: `2px solid ${dropdownOpen.tag.color}` }}>
              <span style={{ color: `${dropdownOpen.tag.color}` }}>
                {dropdownOpen.tag && dropdownOpen.tag.name}
              </span>
            </span>
            <div>
              <AcredittaButton onPress={() => handleDeleteTag(dropdownOpen.tag.id)} title="Si" />
              <AcredittaButton
                onPress={() => setDropdownOpen({ ...dropdownOpen, open: false })}
                variant="secondary"
                title="No"
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default InputSelectTags;
