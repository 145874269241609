import AdminLearningPathPreview from 'components/organisms/AdminLearningPathPreview';
import * as React from 'react';
import { useParams } from 'react-router-dom';

const LearningPathPreview: React.FC = () => {
  let { pathwayId } = useParams<{ pathwayId: string }>();

  return <AdminLearningPathPreview pathwayId={pathwayId} />;
};

export default LearningPathPreview;
