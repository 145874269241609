import { useParams } from 'react-router-dom';
import BadgeEditLayout from './BadgeEditLayout';
import BadgeContextProvider from './BadgeContext';

type ParamsT = {
  id: string;
};

const BadgeEdit = () => {
  const { id }: ParamsT = useParams();

  return (
    <BadgeContextProvider id={id}>
      <BadgeEditLayout />
    </BadgeContextProvider>
  );
};

export default BadgeEdit;
