import {
  LearningPathBasicInfoContainer,
  LearningPathPreviewDescWrapper,
  LearningPathOwner,
  LearningPathOwnerInfo,
  LearningPathDescriptionWrapper,
  LearningPathDescription,
  LearningPathDescriptionTitle,
  LearningPathDescriptionContainer,
  LearningPathOwnerInfoWrapper,
} from './styles';
import AdminLearningCourseDescriptionMenu from '../AdminLearningCourseDescriptionMenu';

interface AdminCoursePreviewDescriptionProps {
  provider: string;
  description: string;
  image: string;
  start: any;
  duration: number;
  users: number;
  level: string;
  content: number;
  language: string;
  methodology: string;
  isPrivate: boolean;
}

const AdminLearningCoursePreviewDescription = ({
  provider,
  description,
  image,
  start,
  duration,
  users,
  level,
  content,
  language,
  methodology,
}: AdminCoursePreviewDescriptionProps): JSX.Element => {
  return (
    <LearningPathPreviewDescWrapper>
      <LearningPathBasicInfoContainer>
        <LearningPathOwnerInfoWrapper>
          <div>
            <LearningPathOwnerInfo>Ofrecido por:</LearningPathOwnerInfo>
            <LearningPathOwner>{provider}</LearningPathOwner>
          </div>
        </LearningPathOwnerInfoWrapper>
        {/* <LearningPathScoreWrapper>
          <RatingView ratingValue={4.7} size={20} />
          <LearningPathScore>4.7</LearningPathScore>
        </LearningPathScoreWrapper> */}
      </LearningPathBasicInfoContainer>
      <LearningPathDescriptionContainer>
        <AdminLearningCourseDescriptionMenu
          start={start}
          duration={duration}
          users={users}
          level={level}
          content={content}
          language={language}
          methodology={methodology}
        />
        <LearningPathDescriptionWrapper>
          <img src={image} style={{ width: '300px' }} alt="imagen-de-la-ruta" />
          <div>
            <LearningPathDescriptionTitle>Descripción del curso</LearningPathDescriptionTitle>

            <LearningPathDescription>{description}</LearningPathDescription>
          </div>
        </LearningPathDescriptionWrapper>
      </LearningPathDescriptionContainer>
    </LearningPathPreviewDescWrapper>
  );
};

export default AdminLearningCoursePreviewDescription;
