import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const RecomendedCoursesLabel = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 27px;
  color: ${PRIMARY_COLOR};
  margin: 20px 0px;
`;

export const AssignHeaderLabel = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin: 0px;
  margin-left: 15px;
`;

export const RecomendedCoursesContainer = styled.div`
  margin: 20px 40px;
`;

export const AssignRouteContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 40px;
`;

export const AssignHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0px 0px 40px;
  svg {
    width: 40px;
    cursor: pointer;
  }
`;

export const AssignUsersContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.15);
  border-radius: 8px;
  margin-top: 40px;
`;

export const AssignUsersTypesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 60px 40px;
`;

export const AssignUser = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => (props.$selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
  span {
    font-family: NotoSansJPBold;
    font-size: 16px;
    line-height: 20px;
  }
  cursor: pointer;
`;

export const SelectionContainer = styled.div`
  display: grid;
  grid-template-columns: 10% 80%;
  align-items: center;
  justify-content: center;
  span {
    font-family: NotoSansJPBold;
    font-size: 16px;
    line-height: 19px;
    color: ${PRIMARY_COLOR};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const SelectedUsersContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 130px;
`;
