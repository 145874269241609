import * as React from 'react';
import {
  LoginContainer,
  LoginPictureContainer,
  LoginPictureWrapper,
  LoginPageContainer,
  ColLoginContainer,
  ColFormContainer,
  MainLogoIcon,
  LoginTitle,
  LoginLogoPictureMainWrapper,
  LoginPictureContainerMobile,
  WrapperImageMobile,
  LoginWrapperCol,
  TitleSingUp,
  ContainerSingUp,
  TextSingUp,
  ButtonWrapper,
} from './styles';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { objectValidation, S3_STATIC } from 'utils';
import toast from 'react-hot-toast';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useAppDispatch } from 'hooks/redux';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import { Container, Row } from 'reactstrap';
import Button from 'components/atoms/Button';
import SingUpForm from 'components/molecules/SingUpForm';
import { RegisterThunk } from 'actions/Thunks/AuthThunk';
import { emailSingUp } from 'states/slices/admin.slice';

const SingUp: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = React.useState<boolean>(false);

  const responsive = useMediaQuery({ query: device.bigMobile });
  const smallMobileResponsive = useMediaQuery({ query: device.smallMobile });

  const defaultValues: SingUpForm = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    password_copy: '',
    number_employee: {
      value: 1,
      label: 'Seleccione una opcion',
    },
    terms_conditions: true,
    organization: {
      name: '',
      sector_id: {
        value: 1,
        label: 'Seleccione una opcion',
      },
    },
    accredited: {
      job_title: '',
    },
  };

  const getDefaultFormValues = (): SingUpForm => {
    return defaultValues;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SingUpForm>({ ...objectValidation, defaultValues: getDefaultFormValues() });

  const submitLogin = async (singUpInfo: SingUpForm) => {
    setLoading(true);
    dispatch(emailSingUp(singUpInfo.email));
    if (singUpInfo) {
      const sendUserData: SendSingUpForm = {
        first_name: singUpInfo.first_name,
        last_name: singUpInfo.last_name,
        email: singUpInfo.email,
        phone_number: singUpInfo.phone_number,
        password: singUpInfo.password,
        password_copy: singUpInfo.password_copy,
        number_employee: singUpInfo.number_employee.value,
        terms_conditions: true,
        organization: {
          name: singUpInfo.organizationName || '',
          sector_id: singUpInfo.organization.sector_id.value,
        },
        accredited: {
          job_title: singUpInfo.accreditedJobTitle || '',
        },
      };

      const registerSingleUser = await dispatch(RegisterThunk(sendUserData));

      if (RegisterThunk.rejected.match(registerSingleUser)) {
        setLoading(false);
        toast.error(registerSingleUser.payload as string);
      } else {
        setLoading(false);
        toast.success('Verifica tu correo');
        history.push(AuthNavigations.codeSingUp);
      }
    }
  };

  const navigateLogin = () => {
    history.push(AuthNavigations.login);
  };

  return (
    <React.Fragment>
      <LoginPageContainer className="container-fluid ps-md-0">
        <React.Fragment>
          <LoginContainer>
            {responsive && !smallMobileResponsive && (
              <LoginPictureContainerMobile sm={6}>
                <WrapperImageMobile />
              </LoginPictureContainerMobile>
            )}
            <ColLoginContainer className="col-md-6 col-lg-6">
              <LoginWrapperCol className="d-flex align-items-center py-5">
                <Container>
                  <Row>
                    <div className="col-md-9 col-lg-8 mx-auto">
                      <LoginLogoPictureMainWrapper>
                        <LoginPictureWrapper xs="11">
                          <MainLogoIcon src={`${S3_STATIC}login-logo.svg`} alt="Main logo" />
                        </LoginPictureWrapper>
                      </LoginLogoPictureMainWrapper>
                      <LoginTitle>Crea tu cuenta</LoginTitle>
                      <ColFormContainer>
                        <SingUpForm
                          control={control}
                          submitLogin={submitLogin}
                          handleSubmit={handleSubmit}
                          errors={errors}
                          loading={loading}
                        />
                      </ColFormContainer>
                    </div>
                  </Row>
                </Container>
              </LoginWrapperCol>
            </ColLoginContainer>
            {!responsive && (
              <LoginPictureContainer className="d-none d-md-flex col-md-6 col-lg-6">
                <ContainerSingUp>
                  <TitleSingUp>Tu organización crece cuando lo hace tu gente</TitleSingUp>
                  <TextSingUp>
                    Aumenta la culminación de tus cursos en 200% con insignias verificables en
                    blockchain e impulsa el upskilling y reskilling para las nuevas necesidades de
                    tu mercado.
                  </TextSingUp>
                  <ButtonWrapper>
                    <Button label={'Iniciar Sesión'} onClick={navigateLogin} />
                  </ButtonWrapper>
                </ContainerSingUp>
              </LoginPictureContainer>
            )}
          </LoginContainer>
        </React.Fragment>
      </LoginPageContainer>
    </React.Fragment>
  );
};

export default SingUp;
