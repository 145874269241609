import { S3_APP, S3_STATIC, ACREDITTA_UPLOADS } from 'utils';

export const onboardingFile = `${S3_STATIC}onboarding/`;

export const S3_INTEREST = `${S3_STATIC}interests/`;
export const S3_ADMIN = `${S3_STATIC}admin/`;

export const S3_APP_MEDIA = `${S3_APP}media/`;
export const S3_APP_MEDIA_ACCOUNTS = `${S3_APP_MEDIA}accounts/`;

export const S3_APP_MEDIA_CSV = `${S3_APP_MEDIA_ACCOUNTS}acreditta_usuarios.csv`;

export const S3_APP_MEDIA_MASSIVE_CSV = `${S3_APP_MEDIA_ACCOUNTS}badges_template_massive.csv`;

export const S3_APP_GLOBAL = `${S3_APP_MEDIA}global/`;

export const RoleDefinition = {
  Admin: 1,
  Lead: 2,
  Accredited: 3,
};

export const UserRole: ISelectOptionValue[] = [
  { value: RoleDefinition.Admin, label: 'Administrador' },
  { value: RoleDefinition.Lead, label: 'Líder de Área' },
  { value: RoleDefinition.Accredited, label: 'Acreditado' },
];

export const Employee: ISelectOptionValue[] = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
];

export const DragItemTypes = {
  COURSE: 'course',
};

export const termsConditions = `${ACREDITTA_UPLOADS}2022/02/TOS-Terminos-de-Uso-Servicio-Acreditta-1.pdf`;
