import icons from 'assets/Icon';
import * as React from 'react';
import { CardBody } from 'reactstrap';
import {
  AdminDnDCardCourseWrapper,
  AdminDnDCardFooter,
  AdminDnDCardFooterLabel,
  AdminDndCardIconWrapper,
  AdminDnDCardLabel,
  AdminDndCardTimeWrapper,
  AdminDnDCardTitle,
} from './styles';
import { SECONDARY_COLOR } from 'assets/styles/colors';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { DragItemTypes } from '../../../constants';

interface AdminDnDCardCourseProps extends ISelectedCourseList {}

const AdminDnDCardCourse: React.FC<AdminDnDCardCourseProps> = React.memo(
  function AdminDnDCardCourse(props) {
    const { name, duration, provider_name } = props;

    const [{ opacity }, drag] = useDrag(
      () => ({
        type: DragItemTypes.COURSE,
        item: props,
        collect: (
          monitor: DragSourceMonitor<
            {
              name: string;
            },
            unknown
          >
        ) => ({
          opacity: monitor.isDragging() ? 0.4 : 1,
        }),
      }),
      [name]
    );

    return (
      <AdminDnDCardCourseWrapper ref={drag} className="card" $opacity={opacity}>
        <CardBody>
          <AdminDnDCardTitle>{name}</AdminDnDCardTitle>
          <AdminDndCardTimeWrapper>
            <AdminDndCardIconWrapper>
              <icons.TimeIcon color={SECONDARY_COLOR} />
            </AdminDndCardIconWrapper>
            {duration ? (
              <AdminDnDCardLabel>
                Duración: <b> {duration} </b>
              </AdminDnDCardLabel>
            ) : (
              <AdminDnDCardLabel>
                Duración: <b> A tu ritmo </b>
              </AdminDnDCardLabel>
            )}
          </AdminDndCardTimeWrapper>
        </CardBody>
        <AdminDnDCardFooter>
          <AdminDnDCardFooterLabel>{provider_name}</AdminDnDCardFooterLabel>
        </AdminDnDCardFooter>
      </AdminDnDCardCourseWrapper>
    );
  }
);
export default AdminDnDCardCourse;
