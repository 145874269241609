import AcredittaBox from '../../AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import MostViewedBadgeItem from '../components/MostViewedBadgeItem';
import { useEffect, useState, useRef } from 'react';
import { getBadgeList } from 'services/badges.service';
import { BadgeResult } from 'interfaces/badge-list-response';
import CardMostViewedBadgesInfo from '../card-most-viewed-badges-info/CardMostViewedBadgesInfo';
import AcredittaLoader from 'components/atoms/AcredittaLoader';

const CardMostViewedBadge = () => {
  const [badgesMostViewed, setBadgeMosViewed] = useState<BadgeResult[]>([]);
  const [loading, setLoading] = useState(true);
  const timeRef = useRef(null) as any;

  useEffect(() => {
    (async () => {
      try {
        const resp = await getBadgeList({ ordering: '-get_issue_count' });
        if (resp.data.results.length > 0) {
          setBadgeMosViewed(resp.data.results.slice(0, 3));
        }
      } catch (e) {
        console.error(e);
      }
      timeRef.current = setTimeout(() => {
        setLoading(false);
      }, 500);
    })();

    return () => {
      clearTimeout(timeRef.current);
    };
  }, []);

  if (loading) {
    return <AcredittaLoader />;
  }

  if (badgesMostViewed.length === 0) {
    return <CardMostViewedBadgesInfo />;
  }

  return (
    <AcredittaBox borderRadius="25px" boxStyle={{ minHeight: '100%', height: '100%' }}>
      <AcredittaText variant="h4" align="center" style={{ marginBottom: 10 }}>
        Insignias más vistas
      </AcredittaText>
      <div>
        {badgesMostViewed.map((item, idx) => (
          <MostViewedBadgeItem position={idx} item={item} key={idx} />
        ))}
      </div>
    </AcredittaBox>
  );
};

export default CardMostViewedBadge;
