import * as React from 'react';
import { InputProps } from 'reactstrap/es/Input';
import {
  TextInputLabelContainer,
  InputContainer,
  TextInputLabel,
  Search,
  CharacterCounter,
  WrapperWithErrorHandling,
  CharacterCounterWrapper,
  ErrorLabel,
} from './styles';

export interface TextInputProps extends InputProps {
  error: Record<string, any>;
  name: string;
  label?: string;
  icon?: React.ReactElement;
  withError?: boolean;
  searchIcon?: React.ReactElement;
  height?: number;
  characterCounter?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextInputMax: React.FC<TextInputProps> = ({
  value,
  type,
  placeholder,
  onChange,
  name,
  error,
  label,
  icon,
  withError,
  searchIcon,
  height,
  characterCounter,
  defaultValue,
  onKeyDown,
  onKeyPress,
}) => {
  return (
    <React.Fragment>
      {label && (
        <TextInputLabelContainer>
          {icon}
          <TextInputLabel>{label}</TextInputLabel>
        </TextInputLabelContainer>
      )}
      {searchIcon && <Search>{searchIcon}</Search>}
      <WrapperWithErrorHandling>
        <InputContainer
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          invalid={!!error[name]?.type}
          defaultValue={defaultValue}
          $height={height}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          maxLength="80"
        />
        {characterCounter && (
          <CharacterCounterWrapper>
            <CharacterCounter>
              {value ? value.toString().length : 0}/{80}
            </CharacterCounter>
          </CharacterCounterWrapper>
        )}
        {withError && error[name]?.type && <ErrorLabel>{error[name]?.message}</ErrorLabel>}
      </WrapperWithErrorHandling>
    </React.Fragment>
  );
};

export default TextInputMax;
