import * as React from 'react';
import icons from 'assets/Icon';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import {
  ProgressMenuContainer,
  ItemMenuWrapper,
  ItemMenuTitle,
  MenuWrapper,
  IconWrapper,
  RectangleWrapper,
} from './styles';

interface ProgressMenuProps {
  selected: number;
  uploadOwnCourses: boolean;
}

interface IMenuItems {
  step: number;
  item: string;
  icon: JSX.Element;
}

const ProgressMenu = ({ selected, uploadOwnCourses }: ProgressMenuProps): JSX.Element => {
  const responsiveIcons = useMediaQuery({ query: device.mediumLaptop });
  const responsiveItemsName = useMediaQuery({ query: device.mobile });

  const menuItemsOwnCourses: IMenuItems[] = [
    {
      step: 1,
      item: 'Datos de la Organización',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.BuildingIcon />,
    },
    {
      step: 2,
      item: 'Selección de Intereses',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.InterestsIcon />,
    },
    // {
    //   step: 3,
    //   item: 'Carga tus cursos',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.InterestsIcon />,
    // },
    // {
    //   step: 4,
    //   item: 'Creación de una ruta',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.CreateRouteIcon />,
    // },
    // {
    //   step: 5,
    //   item: 'Organiza los cursos',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.CreateRouteIcon />,
    // },
    // {
    //   step: 3,
    //   item: 'Finalización del proceso',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.CreateRouteIcon />,
    // },
  ];

  const menuItemsNoOwnCourses: IMenuItems[] = [
    {
      step: 1,
      item: 'Datos de la Organización',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.BuildingIcon />,
    },
    {
      step: 2,
      item: 'Selección de Intereses',
      icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.InterestsIcon />,
    },
    // {
    //   step: 3,
    //   item: 'Creación de una ruta',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.CreateRouteIcon />,
    // },
    // {
    //   step: 4,
    //   item: 'Organiza los cursos',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.CreateRouteIcon />,
    // },
    // {
    //   step: 3,
    //   item: 'Finalización del proceso',
    //   icon: responsiveIcons ? <icons.ProgressMenuResponsiveIcon /> : <icons.CreateRouteIcon />,
    // },
  ];

  const menuItems: IMenuItems[] = uploadOwnCourses ? menuItemsOwnCourses : menuItemsNoOwnCourses;

  return (
    <ProgressMenuContainer>
      {menuItems &&
        menuItems.map((item, itemIndex) => {
          return (
            <MenuWrapper key={itemIndex}>
              <ItemMenuWrapper>
                <IconWrapper $selected={selected === item.step}>{item.icon}</IconWrapper>
                {!responsiveItemsName && (
                  <ItemMenuTitle $selected={selected === item.step}>{item.item}</ItemMenuTitle>
                )}
              </ItemMenuWrapper>
              {itemIndex < menuItems.length - 1 && <RectangleWrapper />}
            </MenuWrapper>
          );
        })}
    </ProgressMenuContainer>
  );
};

export default ProgressMenu;
