import { 
  useCallback, 
  useEffect 
} from 'react';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useAppDispatch } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import { cleanState } from 'states/slices/clean.slice';
import { SignOut } from 'actions/ApiClient/AuthActions';

const Unauthorized = (): JSX.Element => {
  const {push} = useHistory();
  const dispatch = useAppDispatch();

  const logout = useCallback(async() => {
    await dispatch(cleanState());
    await SignOut();
    push(AuthNavigations.login);
  },[dispatch, push])


  useEffect(() => {
    const timer = setTimeout(() => {
      logout();
    }, 6000);
    return () => clearTimeout(timer);
  }, [logout]);

  return (
    <>
      <h1>Esta página está desautorizada</h1>
    </>
  );
};

export default Unauthorized;
