import { S3_APP_MEDIA_CSV, S3_APP_MEDIA_MASSIVE_CSV } from 'constants/index';
import * as React from 'react';
import {
  DownloadContentLabel,
  DownloadContentWrapper,
  DownloadLinkContainer,
  ImageIconDownload,
  ImageIconWrapper,
} from './styles';

interface DownloadTemplateMassiveUsersProps {
  massiveUsers?: boolean;
  massiveBadges?: boolean;
}

const DownloadTemplateMassiveUsers = ({
  massiveUsers,
  massiveBadges,
}: DownloadTemplateMassiveUsersProps): JSX.Element => {
  return (
    <DownloadLinkContainer
      href={
        massiveUsers
          ? S3_APP_MEDIA_CSV
          : massiveBadges
          ? S3_APP_MEDIA_MASSIVE_CSV
          : S3_APP_MEDIA_CSV
      }
      download
    >
      <ImageIconWrapper>
        <ImageIconDownload />
      </ImageIconWrapper>
      <DownloadContentWrapper>
        <DownloadContentLabel style={{ cursor: 'pointer' }}>
          Descargar archivo csv
        </DownloadContentLabel>
      </DownloadContentWrapper>
    </DownloadLinkContainer>
  );
};

export default DownloadTemplateMassiveUsers;
