import AdminCoursePreview from 'components/organisms/AdminCoursePreview';
import * as React from 'react';
import { useParams } from 'react-router-dom';

const LearningPathPreview: React.FC = () => {
  let { courseId } = useParams<{ courseId: string }>();
  return <AdminCoursePreview courseId={courseId} />;
};

export default LearningPathPreview;
