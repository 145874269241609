import * as React from 'react';
import AdminLearningPathPreviewDescription from 'components/molecules/AdminLearningPathPreviewDescription';
import AdminSkillsList from 'components/molecules/AdminSkillsList';
import AdminBadgesList from 'components/molecules/AdminBadgesList';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  CoursesWrappedSection,
  CoursesSectionTitle,
  CoursesCardWrapper,
  RecomendedCoursesWrappedSection,
  RecomendedCoursesCardWrapper,
  RecomendedCoursesSectionTitle,
  AdminCoverSection,
  RouteInfoWrapper,
  RouteSubTitle,
  RouteShareTitle,
  ButtonWrapper,
  RouteShareSectionWrapper,
  ModalContainer,
  ModalBodyStyled,
  ModalTitle,
  ModalText,
  UploadFileWrapper,
  ModalTextFile,
} from './styles';
import { recomendedCourses } from './mock';
import CoursesCards from 'components/molecules/CoursesCards';
import Pagination from 'components/molecules/Pagination';
import AdminRouteCoursesList from 'components/molecules/AdminRouteCoursesList';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  DetailPathwayContentCourseThunk,
  DetailPathwayDashboardThunk,
  DetailPathwaySkillsThunk,
  SelectedCourseListThunk,
  LearningPathExperiencesThunk,
  DetailPathwayBagdesThunk,
} from '../../../actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';
import CoursesCardsPathwayDescription from '../../molecules/CoursesCardPathwayDescription';
import { useHistory } from 'react-router-dom';
import { AdminRole, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import ButtonCom from 'components/atoms/ButtonCom';
import { Input } from 'reactstrap';
import UploadFile from 'components/atoms/UploadAnyFile';
import { UploadEvidenceThunk } from 'actions/Thunks/AcreditedThunk';
import { ENVIRONMENT_URL } from 'utils';

interface AdminLearningPathPreviewProps {
  pathwayId: string;
}

const AdminLearningPathPreview = ({ pathwayId }: AdminLearningPathPreviewProps): JSX.Element => {
  const visible = false;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [notVisible] = React.useState<boolean>(true);
  const [descriptionValue, setDescriptionValue] = React.useState('');
  const [file, setFile] = React.useState<File | null>();
  const [modal, setModal] = React.useState(false);
  const [info, setInfo] = React.useState<IUploadevidence>({
    file: null,
    path: null,
    description: null,
  });
  const admin = useAppSelector((state) => state.admin);
  const user = useAppSelector((state) => state.user.userInfo);
  const courseSelector = useAppSelector((state) => state.courses);

  const currentPageCourses = React.useMemo(() => {
    if (courseSelector.selectedCourseList) {
      const firstPageIndex = (currentPage - 1) * courseSelector.selectedCourseList.length;
      const lastPageIndex = firstPageIndex + courseSelector.selectedCourseList.length;

      return courseSelector.selectedCourseList.slice(firstPageIndex, lastPageIndex);
    }
  }, [courseSelector.selectedCourseList, currentPage]);

  const pathwaySkills = React.useCallback(async () => {
    const skills = await dispatch(DetailPathwaySkillsThunk(parseInt(pathwayId, 10)));

    if (DetailPathwaySkillsThunk.rejected.match(skills)) {
      toast.error(skills.payload as string);
    }
  }, [dispatch, pathwayId]);

  const pathwayDashboard = React.useCallback(async () => {
    const dashboard = await dispatch(DetailPathwayDashboardThunk(parseInt(pathwayId, 10)));

    if (DetailPathwayDashboardThunk.rejected.match(dashboard)) {
      toast.error(dashboard.payload as string);
    }
  }, [dispatch, pathwayId]);

  const pathwayBadges = React.useCallback(async () => {
    const badges = await dispatch(DetailPathwayBagdesThunk(pathwayId));

    if (DetailPathwayBagdesThunk.rejected.match(badges)) {
      toast.error(badges.payload as string);
    }
  }, [dispatch, pathwayId]);

  const selectedCourses = React.useCallback(async () => {
    if (pathwayId) {
      const courses = await dispatch(SelectedCourseListThunk(parseInt(pathwayId)));

      if (SelectedCourseListThunk.rejected.match(courses)) {
        toast.error(courses.payload as string);
      }
    }
  }, [pathwayId, dispatch]);

  const contentCourse = React.useCallback(async () => {
    const contentCoursePathway = await dispatch(
      DetailPathwayContentCourseThunk(parseInt(pathwayId, 10))
    );

    if (DetailPathwayContentCourseThunk.rejected.match(contentCoursePathway)) {
      toast.error(contentCoursePathway.payload as string);
    }
  }, [dispatch, pathwayId]);

  const getExperiencesPath = React.useCallback(async () => {
    if (pathwayId) {
      const experiences = await dispatch(LearningPathExperiencesThunk(parseInt(pathwayId)));
      if (LearningPathExperiencesThunk.rejected.match(experiences)) {
        toast.error(experiences.payload as string);
      }
    }
  }, [pathwayId, dispatch]);

  const postUploadPath = React.useCallback(async () => {
    if (info.description === null) {
      if (info.file === null) {
        toast('Adjunta alguna evidencia.');
      } else {
        const uploadFile = await dispatch(UploadEvidenceThunk(info));
        if (UploadEvidenceThunk.rejected.match(uploadFile)) {
          toast.error(uploadFile.payload as string);
        } else {
          setModal(!modal);
          toast.success('Se realizo tu solicitud.');
        }
      }
    } else {
      if (info.file === null) {
        const uploadFile = await dispatch(UploadEvidenceThunk(info));
        if (UploadEvidenceThunk.rejected.match(uploadFile)) {
          toast.error(uploadFile.payload as string);
        } else {
          setModal(!modal);
          toast.success('Se realizo tu solicitud.');
        }
      } else {
        const uploadFile = await dispatch(UploadEvidenceThunk(info));
        if (UploadEvidenceThunk.rejected.match(uploadFile)) {
          toast.error(uploadFile.payload as string);
        } else {
          setModal(!modal);
          toast.success('Se realizo tu solicitud.');
        }
      }
    }
  }, [modal, info, dispatch]);

  const assignRoute = () => {
    if (courseSelector.selectedCourseList?.length !== 0) {
      if (courseSelector.selectedCourseList) {
        history.push(`${AdminRole.coursePreview}/${courseSelector.selectedCourseList[0].course}`);
      }
    }
  };

  const onSelectCsvFile = async (csvFile: File) => {
    setFile(csvFile);
    if (descriptionValue !== '') {
      setInfo({
        file: csvFile,
        path: parseInt(pathwayId),
        description: descriptionValue,
      });
    } else {
      setInfo({
        file: csvFile,
        path: parseInt(pathwayId),
        description: null,
      });
    }
    toast.success('Tu documento se subió correctamente.');
  };

  const handleDescription = (event: any) => {
    setDescriptionValue(event.target.value);
    if (file !== undefined) {
      setInfo({
        file: file,
        path: parseInt(pathwayId),
        description: event.target.value,
      });
    } else {
      setInfo({
        file: null,
        path: parseInt(pathwayId),
        description: event.target.value,
      });
    }
  };

  const toggle = () => {
    setModal(!modal);
    setInfo({
      file: null,
      path: null,
      description: null,
    });
    setDescriptionValue('');
    setFile(null);
  };

  React.useEffect(() => {
    pathwayDashboard();
    pathwaySkills();
    selectedCourses();
    contentCourse();
    getExperiencesPath();
    pathwayBadges();
  }, [
    pathwayDashboard,
    pathwaySkills,
    selectedCourses,
    contentCourse,
    getExperiencesPath,
    pathwayBadges,
  ]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={user?.user_type.code === 'ADMIN' ? false : true}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {courseSelector.pathwayDetailGeneralContent && (
        <React.Fragment>
          <AdminCoverSection>
            <img
              src="https://acreditta-rutas.s3.amazonaws.com/static/route-cover-example.png"
              alt="imagen-de-portada"
            />
            <RouteInfoWrapper>
              <p
                style={{
                  maxWidth: '95%',
                  textAlign: 'center',
                  fontFamily: 'NotoSansJPBold',
                  fontSize: '40px',
                  lineHeight: '68px',
                  color: '#ffffff',
                }}
              >
                {courseSelector.pathwayDetailGeneralContent.name}
              </p>
              <ButtonWrapper>
                <Button label="Continuar Ruta" widthCustom={378} onClick={assignRoute} />
              </ButtonWrapper>
              <RouteSubTitle>¿Conoces a alguien que le gustaría esta ruta?</RouteSubTitle>
              <RouteShareSectionWrapper>
                <RouteShareTitle
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${ENVIRONMENT_URL}${NonAuthenticatedUrls.path}/${pathwayId}`
                    );
                    toast('Enlace copiado');
                  }}
                >
                  ¡Compártela!
                </RouteShareTitle>
                {visible && <icons.UploadFileIcon />}
              </RouteShareSectionWrapper>
            </RouteInfoWrapper>
          </AdminCoverSection>
          <AdminLearningPathPreviewDescription
            creator={courseSelector.pathwayDetailGeneralContent.organization}
            start={courseSelector.pathwayDetailGeneralContent.start_date}
            content={courseSelector.pathwayDetailGeneralContent.course_count}
            description={courseSelector.pathwayDetailGeneralContent.description}
            duration={courseSelector.pathwayDetailGeneralContent.duration}
            users={courseSelector.pathwayDetailGeneralContent.user_count}
            level={courseSelector.pathwayDetailGeneralContent.level}
            language={courseSelector.pathwayDetailGeneralContent.language}
            methodology={courseSelector.pathwayDetailGeneralContent.methodology}
            isPrivate={courseSelector.pathwayDetailGeneralContent.is_private}
            image={courseSelector.pathwayDetailGeneralContent.photo}
          />
        </React.Fragment>
      )}
      {courseSelector.pathwayDetailSkillsContent &&
        (courseSelector.pathwayDetailSkillsContent?.length !== 0 ? (
          <AdminSkillsList skills={courseSelector.pathwayDetailSkillsContent} />
        ) : (
          <div></div>
        ))}
      {courseSelector.pathwayDetailBadges &&
        courseSelector.pathwayDetailGeneralContent &&
        (courseSelector.pathwayDetailBadges?.length !== 0 ? (
          <AdminBadgesList
            badges={courseSelector.pathwayDetailBadges}
            dateStart={courseSelector.pathwayDetailGeneralContent.start_date}
            pathwayId={pathwayId}
            userType={3}
          />
        ) : (
          <div>
            <br />
          </div>
        ))}
      {courseSelector.selectedCourseList && (
        <CoursesWrappedSection>
          <CoursesSectionTitle>
            {courseSelector.selectedCourseList.length} Cursos en esta ruta
          </CoursesSectionTitle>
          <CoursesCardWrapper>
            <CoursesCardsPathwayDescription
              courses={courseSelector.selectedCourseList}
              allowSelection={false}
              section={true}
            />
          </CoursesCardWrapper>
        </CoursesWrappedSection>
      )}
      {courseSelector.pathwayDetailContentCourse && (
        <AdminRouteCoursesList coursesList={courseSelector.pathwayDetailContentCourse} />
      )}
      {admin.experiencesPathListView && (
        <AdminRouteCoursesList experiencesList={admin.experiencesPathListView} />
      )}
      {!notVisible && (
        <RecomendedCoursesWrappedSection>
          {currentPageCourses && (
            <RecomendedCoursesCardWrapper>
              <RecomendedCoursesSectionTitle>
                Otros usuarios también vieron estos contenidos
              </RecomendedCoursesSectionTitle>
              <CoursesCards courses={currentPageCourses} />
              <Pagination
                currentPage={currentPage}
                totalCount={recomendedCourses.length}
                pageSize={2}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </RecomendedCoursesCardWrapper>
          )}
        </RecomendedCoursesWrappedSection>
      )}
      {courseSelector &&
        courseSelector.pathwayDetailGeneralContent !== null &&
        (courseSelector.pathwayDetailGeneralContent.status_evidence === null ||
        courseSelector.pathwayDetailGeneralContent.status_evidence === 'DECLINED' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <ButtonWrapper>
              <Button label="Finalizar Ruta" widthCustom={378} onClick={() => toggle()} />
            </ButtonWrapper>
          </div>
        ) : (
          courseSelector.pathwayDetailGeneralContent.status_evidence === 'IN_PROGRESS' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <ButtonWrapper>
                <Button
                  disabled={true}
                  label="La evidencia se encuentra en revisión"
                  widthCustom={378}
                />
              </ButtonWrapper>
            </div>
          )
        ))}

      <ModalContainer
        isOpen={modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyled>
          {courseSelector.pathwayDetailGeneralContent && (
            <ModalTitle>
              ¿Has finalizado la ruta {courseSelector.pathwayDetailGeneralContent.name}?
            </ModalTitle>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '2%',
            }}
          >
            <ModalText>
              Sube un documento o haz un resumen que certifique que finalizaste la ruta exitosamente
              para que el administrador pueda aprobarlo
            </ModalText>

            <UploadFileWrapper>
              <UploadFile onSelectCsvFile={onSelectCsvFile} />
            </UploadFileWrapper>

            <ModalText>Puedes subir archivos en formato .pdf .jpg .png</ModalText>
            {info?.file !== null && <ModalTextFile>{info?.file.name}</ModalTextFile>}
            <div
              style={{
                width: '70%',
                padding: '4%',
              }}
            >
              <Input
                id="description"
                name="description"
                type="textarea"
                value={descriptionValue}
                onChange={handleDescription}
                placeholder="Haz un resumen del desarrollo de la experiencia asignada"
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '2%',
              }}
            >
              <div style={{ marginRight: '10px' }}>
                <ButtonCom
                  label="Cancelar"
                  widthCustom={150}
                  lightMode={true}
                  onClick={() => toggle()}
                />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <ButtonCom label="Subir" widthCustom={150} onClick={() => postUploadPath()} />
              </div>
            </div>
          </div>
        </ModalBodyStyled>
      </ModalContainer>
    </CommonPageLayout>
  );
};

export default AdminLearningPathPreview;
