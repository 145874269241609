import { useAppSelector } from 'hooks/redux';
import { BadgeDetail } from 'interfaces/badge-detail';
import { LinkedinIcon } from 'react-share';

interface Props {
  badge: BadgeDetail;
  uri: string;
}

const ShareOnLinkedinProfileVertical = ({ badge, uri }: Props) => {
  const { adminInfo } = useAppSelector((state) => state.user);

  const handleOptionAddToMyProfile = () => {
    const [, monthIssued, yearIssued] = String(badge.issued_at).split('-');

    let uriToShare = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${badge.badge_template.name}&organizationName=${badge.issuer_organization}&issueYear=${yearIssued}&issueMonth=${monthIssued}&certId=${badge.id}&certUrl=${uri}/${badge.id}`;

    if (typeof badge.expires_at === 'string' && badge.expires_at.trim().length > 0) {
      const [, monthExpired, yearExpired] = String(badge.expires_at).split('-');
      uriToShare += `&expirationYear=${yearExpired}&expirationMonth=${monthExpired}`;
    }

    if (typeof adminInfo?.linkedin_id === 'string' && adminInfo?.linkedin_id.trim().length > 0) {
      uriToShare += `&organizationId=${adminInfo?.linkedin_id}`;
    }

    window.open(uriToShare, '_blank');
  };

  return (
    <button onClick={handleOptionAddToMyProfile}>
      <div>
        <LinkedinIcon size={50} round={true} />
      </div>
      <span>
        Perfil en Linkedin
      </span>
    </button>
  );
};

export default ShareOnLinkedinProfileVertical;
