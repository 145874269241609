import * as React from 'react';
import { DividerLine, FooterAcredittaLogoWrapper, FooterContainer, FooterNavbar } from './styles';
import icons from 'assets/Icon';
import FooterBadges from 'components/molecules/FooterBadges';
import FooterAboutUs from 'components/molecules/FooterAboutUs';
import FooterContactUs from 'components/molecules/FooterContactUs';

const DashboardFooter: React.FC = () => {
  return (
    <FooterContainer>
      <FooterNavbar expand="md">
        <FooterAcredittaLogoWrapper href="/">
          <icons.AcredittaDark />
        </FooterAcredittaLogoWrapper>
        <DividerLine />
        <FooterBadges />
        <FooterAboutUs />
        <FooterContactUs />
      </FooterNavbar>
    </FooterContainer>
  );
};

export default DashboardFooter;
