import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateSingleUser,
  GetCountryList,
  CreateNewOrganization,
  GetDocumentType,
  UploadMassiveUsers,
  GetCitiesList,
  GetOrganizationInterest,
  CreateInterestForOrganization,
  GetDepartmentOrganizationList,
  GetUsersRelatedOrganization,
  SendNewUsersInvitations,
  CourseCatalogList,
  CourseCatalogFilterOptions,
  CreateNewLearningExperience,
  LearningExperienceCategory,
  LearningPathListView,
  UpdateLearningPathListView,
  CreatePathwayCatalogCourse,
  SelectedCourseList,
  DetailPathwayDescription,
  DetailPathwaySkills,
  DetailPathwayDashboard,
  DetailPathwayContentCourse,
  SaveNewLearningPath,
  CreateNewCourse,
  GetLanguagesList,
  GetModalityList,
  GetMethodologyList,
  LearningPathExperiences,
  UpdateImageNewCourse,
  UpdateOwnCourses,
  AssignLearningPath,
  LearningPathLevels,
  CreateNew,
  GetOrganizationBadges,
  GetBadgeDetails,
  CreateNewBadageTemplate,
  DetailPathwayContent,
  CreateNewIssue,
  GetOrganizationHab,
  AddMassiveUsers,
  DetailPathwayBadges,
  AddBadgeImage,
  NewIssue,
  GetUserDetail,
  GetOrganizationOverview,
  GetOrganizationBadgesInfo,
  ShareBadgeEmail,
  DownloadBadgeImage,
  IssueMasiveBadges,
  GetNewIssue,
  GetUserType,
  DownloadBadgeImageCert,
  ChangeImageProfile,
  EditProfileForm,
  GetDetailsProfileInfo,
  UnauthDetailPathwayContentCourse,
  UnauthDetailPathwayDashboard,
  UnauthSelectedCourseList,
} from 'actions/ApiClient/AdminActions';
import { GetPathList } from 'actions/ApiClient/OnboardingActions';
import { GetErrorMessage, SerializeSelectionOption } from 'utils';

export const AdminCreateMassiveUsersThunk = createAsyncThunk(
  'admin/massive',
  async (file: IAdminCreateCognitoUsersThunk, { rejectWithValue }) => {
    try {
      const massiveCreation = await UploadMassiveUsers(file);
      return massiveCreation;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewOrganizationThunk = createAsyncThunk(
  'admin/createNewOrganization',
  async (newAdditionalInfoForm: ICreateNewOrganization, { rejectWithValue }) => {
    try {
      const createNewAdditionalInfoForm = await CreateNewOrganization(newAdditionalInfoForm);
      return createNewAdditionalInfoForm;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewThunk = createAsyncThunk(
  'admin/createNewPerfil',
  async (newAdditionalInfoForm: ICreateNew, { rejectWithValue }) => {
    try {
      const createNewAdditionalInfoForm = await CreateNew(
        newAdditionalInfoForm.path_info,
        newAdditionalInfoForm.path_photo
      );
      return createNewAdditionalInfoForm;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AdminCreateSingleUserThunk = createAsyncThunk(
  'admin/createSingleUser',
  async (newUser: ICreateSingleUser, { rejectWithValue }) => {
    try {
      const createUser = await CreateSingleUser(newUser);
      return createUser;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CountryListThunk = createAsyncThunk(
  'admin/countries',
  async (_, { rejectWithValue }) => {
    try {
      const country = await GetCountryList();

      let constructNewCountry: ISelectOptionValue[] = [];

      country.map((countryMap) => {
        return constructNewCountry.push({
          value: countryMap.id,
          label: countryMap.name,
        });
      });

      return constructNewCountry;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListDocumentTypesThunk = createAsyncThunk(
  'admin/documentType',
  async (countryId: number, { rejectWithValue }) => {
    try {
      const documentType = await GetDocumentType(countryId);
      return documentType;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListUserTypeThunk = createAsyncThunk(
  'admin/userType',
  async (_, { rejectWithValue }) => {
    try {
      const userType = await GetUserType();
      let constructUserType: ISelectOptionValue[] = [];

      userType.map((userTypeMap) => {
        return constructUserType.push({
          value: userTypeMap.id,
          label: userTypeMap.name,
        });
      });
      return constructUserType;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetUserDetailThunk = createAsyncThunk(
  'admin/user-data-detail',
  async (user_id: any, { rejectWithValue }) => {
    try {
      const userDet = await GetUserDetail(user_id);
      return userDet;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListCitiesThunk = createAsyncThunk(
  'admin/cities',
  async (countryId: number, { rejectWithValue }) => {
    try {
      const cities = await GetCitiesList(countryId);

      let constructCities: ISelectOptionValue[] = [];

      cities.map((city) => {
        return constructCities.push({ value: city.id, label: city.name });
      });

      return constructCities;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListInterestOrganizationThunk = createAsyncThunk(
  'admin/interestList',
  async (_, { rejectWithValue }) => {
    try {
      const interest = await GetOrganizationInterest();
      let constructInterest: ISelectOptionValue[] = [];

      interest.results.map((interestMap) => {
        return constructInterest.push({
          value: interestMap.id,
          label: interestMap.name,
        });
      });

      return constructInterest;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ListInterestOrganizationHabThunk = createAsyncThunk(
  'admin/interestHabList',
  async (_, { rejectWithValue }) => {
    try {
      const interest = await GetOrganizationHab();
      let constructInterest: ISelectOptionValue[] = [];

      interest.results.map((interestMap) => {
        return constructInterest.push({
          value: interestMap.id,
          label: interestMap.name,
        });
      });

      return constructInterest;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateInterestOrganizationThunk = createAsyncThunk(
  'admin/createInterestOrganization',
  async (newInterest: INewInterestOrganization, { rejectWithValue }) => {
    try {
      const createInterest = await CreateInterestForOrganization(
        {
          new_organization_interests: newInterest.new_organization_interests,
          organization_interest: newInterest.organization_interest,
        },
        newInterest.organizationId
      );
      return createInterest;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetDepartmentOrganizationListThunk = createAsyncThunk(
  'admin/getDepartmentOrganization',
  async (organizationId: number, { rejectWithValue }) => {
    try {
      const departments = await GetDepartmentOrganizationList(organizationId);

      return departments;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetUsersRelatedOrganizationThunk = createAsyncThunk(
  'admin/getUsersRelatedOrganization',
  async (organizationId: number, { rejectWithValue }) => {
    try {
      const users = await GetUsersRelatedOrganization(organizationId);

      return users;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AdminSendNewUsersInvitationsThunk = createAsyncThunk(
  'admin/sendNewUsersInvitations',
  async (invitations: INewUsersInvitations, { rejectWithValue }) => {
    try {
      const sendInvitations = await SendNewUsersInvitations(invitations);
      return sendInvitations;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AdminCourseCatalogThunk = createAsyncThunk(
  'admin/courseCatalog',
  async (filter: CourseCatalogThunk, { rejectWithValue }) => {
    try {
      const courses = await CourseCatalogList(filter);
      return courses;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AdminCourseCatalogFilterOptionsThunk = createAsyncThunk(
  'admin/courseCatalogFilterOption',
  async (_, { rejectWithValue }) => {
    try {
      const courseCatalogFilterOption = await CourseCatalogFilterOptions();

      const serializeFilterOption: IFilterSerializedOption[] = [
        // {
        //   id: 1,
        //   name: 'Categoría',
        //   selected: false,
        //   subCategories: SerializeSelectionOption(courseCatalogFilterOption.interest),
        // },
        {
          id: 2,
          name: 'Proveedor',
          selected: false,
          subCategories: SerializeSelectionOption(courseCatalogFilterOption.provider),
        },
        {
          id: 3,
          name: 'Duración',
          selected: false,
          subCategories: [
            {
              id: 1,
              name: 'Meses',
              selected: false,
            },
            {
              id: 2,
              name: 'Días',
              selected: false,
            },
            {
              id: 3,
              name: 'Horas',
              selected: false,
            },
          ],
        },
        {
          id: 4,
          name: 'Costo',
          selected: false,
          subCategories: SerializeSelectionOption(courseCatalogFilterOption.type_cost),
        },
        {
          id: 5,
          name: 'Nivel',
          selected: false,
          subCategories: SerializeSelectionOption(courseCatalogFilterOption.level),
        },
        {
          id: 6,
          name: 'Idioma',
          selected: false,
          subCategories: SerializeSelectionOption(courseCatalogFilterOption.language),
        },
        {
          id: 7,
          name: 'Habilidades',
          selected: false,
          subCategories: SerializeSelectionOption(courseCatalogFilterOption.skill),
        },
      ];

      return serializeFilterOption;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewLearningExperienceThunk = createAsyncThunk(
  'admin/createNewLearningExperience',
  async (newLearningExperience: ICreateNewLearningExperience, { rejectWithValue }) => {
    try {
      const createLearningExperience = await CreateNewLearningExperience(newLearningExperience);
      return createLearningExperience;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const LearningExperienceCategoryThunk = createAsyncThunk(
  'admin/getLearningExperienceCategory',
  async (_, { rejectWithValue }) => {
    try {
      const categories = await LearningExperienceCategory();

      return categories;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const LearningPathListViewThunk = createAsyncThunk(
  'admin/getLearningPathListView',
  async (pathway_id: number, { rejectWithValue }) => {
    try {
      const learningPath = await LearningPathListView(pathway_id);

      return learningPath;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UpdateLearningPathListViewThunk = createAsyncThunk(
  'admin/updateLearningPathListView',
  async (new_path: IUpdateLearningPathListView[], { rejectWithValue }) => {
    try {
      const updated_path = await UpdateLearningPathListView({ subpath: new_path });

      return updated_path;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreatePathwayCatalogCourseThunk = createAsyncThunk(
  'admin/createPathwayCourseCatalog',
  async (courseSelected: { course: number[] }, { rejectWithValue }) => {
    try {
      const createCoursePathway = await CreatePathwayCatalogCourse(courseSelected);
      return createCoursePathway;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const SelectedCourseListThunk = createAsyncThunk(
  'admin/selectCourseList',
  async (id: number, { rejectWithValue }) => {
    try {
      const selectedCourse = await SelectedCourseList(id);
      return selectedCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UnauthSelectedCourseListThunk = createAsyncThunk(
  'admin/selectCourseListUnauth',
  async (id: number, { rejectWithValue }) => {
    try {
      const selectedCourse = await UnauthSelectedCourseList(id);
      return selectedCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailPathwayDescriptionThunk = createAsyncThunk(
  'admin/pathwayDescription',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwayDescription = await DetailPathwayDescription(id);
      return pathwayDescription;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailPathwaySkillsThunk = createAsyncThunk(
  'admin/pathwaySkills',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwaySkills = await DetailPathwaySkills(id);
      return pathwaySkills;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailPathwayDashboardThunk = createAsyncThunk(
  'admin/pathwayDashboard',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwayDashboard = await DetailPathwayDashboard(id);
      return pathwayDashboard;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UnauthDetailPathwayDashboardThunk = createAsyncThunk(
  'admin/pathwayDashboardUnauth',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwayDashboard = await UnauthDetailPathwayDashboard(id);
      return pathwayDashboard;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailPathwayBagdesThunk = createAsyncThunk(
  'admin/pathwayBadgesAssing',
  async (id: any, { rejectWithValue }) => {
    try {
      const pathwayBadges = await DetailPathwayBadges(id);
      return pathwayBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailPathwayContentCourseThunk = createAsyncThunk(
  'admin/pathwayContentCourse',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwayContentCourse = await DetailPathwayContentCourse(id);
      return pathwayContentCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UnauthDetailPathwayContentCourseThunk = createAsyncThunk(
  'admin/pathwayContentCourseUnauth',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwayContentCourse = await UnauthDetailPathwayContentCourse(id);
      return pathwayContentCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailPathwayContentThunk = createAsyncThunk(
  'admin/pathwayContent',
  async (id: number, { rejectWithValue }) => {
    try {
      const pathwayContentCourse = await DetailPathwayContent(id);
      return pathwayContentCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const SaveNewLearningPathThunk = createAsyncThunk(
  'admin/saveNewLearningPath',
  async (new_learning_path: ISaveUpdatePath, { rejectWithValue }) => {
    try {
      const create_new_path = await SaveNewLearningPath(
        new_learning_path.path_id,
        new_learning_path.path_info,
        new_learning_path.path_photo
      );
      return create_new_path;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewCourseThunk = createAsyncThunk(
  'admin/createNewCourse',
  async (newCourseInfo: ICreateNewCourseIndex, { rejectWithValue }) => {
    try {
      const createNewCourse = await CreateNewCourse(
        newCourseInfo.path_info,
        newCourseInfo.path_photo
      );
      return createNewCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewIssueThunk = createAsyncThunk(
  'admin/createNewIssue',
  async (newIssueInfo: any, { rejectWithValue }) => {
    try {
      const createNewCourse = await CreateNewIssue(newIssueInfo.path_info);
      return createNewCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const NewIssueThunk = createAsyncThunk(
  'admin/createNewIssue',
  async (newIssueInfo: any, { rejectWithValue }) => {
    try {
      const createNewCourse = await NewIssue(newIssueInfo.path_info);
      return createNewCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetNewIssueThunk = createAsyncThunk(
  'admin/validate-email-issued',
  async (email: string, { rejectWithValue }) => {
    try {
      const validate = await GetNewIssue(email);
      return validate;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetLanguagesListThunk = createAsyncThunk(
  'admin/languages',
  async (_, { rejectWithValue }) => {
    try {
      const languages = await GetLanguagesList();
      let constructLanguages: ISelectOptionValue[] = [];

      languages.map((languagesMap) => {
        return constructLanguages.push({
          value: languagesMap.id,
          label: languagesMap.name,
        });
      });

      return constructLanguages;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetModalityListThunk = createAsyncThunk(
  'admin/modality',
  async (_, { rejectWithValue }) => {
    try {
      const modalities = await GetModalityList();
      let constructModalities: ISelectOptionValue[] = [];

      modalities.map((madality) => {
        return constructModalities.push({
          value: madality.id,
          label: madality.name,
        });
      });

      return constructModalities;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetMethodologyListThunk = createAsyncThunk(
  'admin/methodology',
  async (_, { rejectWithValue }) => {
    try {
      const methodologies = await GetMethodologyList();
      let constructMmthodologies: ISelectOptionValue[] = [];

      methodologies.map((methodology) => {
        return constructMmthodologies.push({
          value: methodology.id,
          label: methodology.name,
        });
      });

      return constructMmthodologies;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const LearningPathExperiencesThunk = createAsyncThunk(
  'admin/getLearningPathExperiences',
  async (pathway_id: number, { rejectWithValue }) => {
    try {
      const experiences = await LearningPathExperiences(pathway_id);

      return experiences;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UpdateImageNewCourseThunk = createAsyncThunk(
  'admin/updateNewCourseImage',
  async (imageInfo: IUpdateNewCourseImage, { rejectWithValue }) => {
    try {
      const updateCourse = await UpdateImageNewCourse(imageInfo);
      return updateCourse;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UpdateOwnCoursesThunk = createAsyncThunk(
  'admin/updateOwnCourses',
  async (updateForm: IUpdateUserOwnCourses, { rejectWithValue }) => {
    try {
      const updateOwnCourses = await UpdateOwnCourses(updateForm.own_courses, updateForm.id);
      return updateOwnCourses;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AssignLearningPathThunk = createAsyncThunk(
  'admin/assign-courses',
  async (newUsersForm: IAssingUsersForm, { rejectWithValue }) => {
    try {
      const assignUsers = await AssignLearningPath(newUsersForm);
      return assignUsers;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const LearningPathLevelsThunk = createAsyncThunk(
  'admin/getPathwaysLevels',
  async (_, { rejectWithValue }) => {
    try {
      const levels = await LearningPathLevels();
      let formatLevels: ISelectOptionValue[] = [];

      levels.map((level) => {
        return formatLevels.push({
          value: level.id,
          label: level.name,
        });
      });

      return formatLevels;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetOrganizationBadgesThunk = createAsyncThunk(
  'admin/getOrganizationBadges',
  async (user_id: any, { rejectWithValue }) => {
    try {
      const badges = await GetOrganizationBadges(user_id);

      return badges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetBadgeDetailsThunk = createAsyncThunk(
  'admin/getBadgeDetails',
  async (badge_id: string, { rejectWithValue }) => {
    try {
      const badge = await GetBadgeDetails(badge_id);

      return badge;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewBadageTemplateThunk = createAsyncThunk(
  'admin/createNewBadgeTemplate',
  async (new_badge: ICreateNewBadgeTemplate, { rejectWithValue }) => {
    try {
      const create_new_badge = await CreateNewBadageTemplate(new_badge);
      return create_new_badge;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AdminAddMassiveUsersThunk = createAsyncThunk(
  'admin/massive-users',
  async (file: File, { rejectWithValue }) => {
    try {
      const masiveUsers = await AddMassiveUsers(file);
      return masiveUsers;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CreateNewBadageTemplateImageThunk = createAsyncThunk(
  'admin/createNewBadageTemplateImageThunk',
  async (info: any, { rejectWithValue }) => {
    try {
      const new_image = await AddBadgeImage(info.badge_image, info.id);
      return new_image;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetOrganizationOverviewThunk = createAsyncThunk(
  'admin/createNewBadageTemplateImageThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const organization = await GetOrganizationOverview(id);
      return organization;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ShareBadgeEmailThunk = createAsyncThunk(
  'badge/shareBageEmail',
  async (emailInfo: IShareBadgeEmailRequest, { rejectWithValue }) => {
    try {
      const email = await ShareBadgeEmail(emailInfo);
      return email;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetOrganizationBadgesInfoThunk = createAsyncThunk(
  'admin/createNewBadageTemplateImageThunk',
  async (id: string, { rejectWithValue }) => {
    try {
      const badges = await GetOrganizationBadgesInfo(id);
      return badges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DownloadBadgeImageThunk = createAsyncThunk(
  'badge/downloadBadgeImage',
  async (badgeId: string, { rejectWithValue }) => {
    try {
      const badgeImage = await DownloadBadgeImage(badgeId);

      return badgeImage;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AdminIssueMassiveBadgesThunk = createAsyncThunk(
  'admin/massive-badges',
  async (info: IMassiveBadges, { rejectWithValue }) => {
    try {
      const massiveBadges = await IssueMasiveBadges(info);
      return massiveBadges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DownloadBadgeImageCertThunk = createAsyncThunk(
  'badge/downloadBadgeImageCert',
  async (badgeId: string, { rejectWithValue }) => {
    try {
      const badgeImage = await DownloadBadgeImageCert(badgeId);

      return badgeImage;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ChangeImageProfileThunk = createAsyncThunk(
  'profile/changeImageProfile',
  async (data: IFormEditProfie, { rejectWithValue }) => {
    try {
      const profileImage = await ChangeImageProfile(data);

      return profileImage;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const EditProfileFormThunk = createAsyncThunk(
  'profile/editProfileForm',
  async (data: IFormEditProfie, { rejectWithValue }) => {
    try {
      const profileImage = await EditProfileForm(data);

      return profileImage;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetDetailsProfileInfoThunk = createAsyncThunk(
  'profile/getProfileForm',
  async (cognito_id: string, { rejectWithValue }) => {
    try {
      const profileImage = await GetDetailsProfileInfo(cognito_id);

      return profileImage;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetPathListThunk = createAsyncThunk(
  'admin/paths',
  async (_, { rejectWithValue }) => {
    try {
      const list = await GetPathList();
      let arraylist: ISelectOptionValue[] = [];

      list.map((item: any) => {
        return arraylist.push({
          value: item.id,
          label: item.name,
        });
      });
      return arraylist;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
