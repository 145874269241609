import { useEffect, useState } from 'react';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { getUserDataDetails } from '../actions/ApiClient/AdminActions';
import * as Sentry from '@sentry/react';
import { AccreditedProfileResponse } from 'interfaces/accredited-profile';

const useGetUser = (id: string) => {
  const [user, setUser] = useState({} as AccreditedProfileResponse);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response: any = await getUserDataDetails(id);
        setUser(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        Sentry.captureException(err);
        logException(GetErrorMessage(err), true);
        throw new Error(GetErrorMessage(err));
      }
    })();
  }, [id]);

  return { user, loading };
};

export default useGetUser;
