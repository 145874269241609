import { RoleDefinition } from 'constants/index';
import { AccreditedRole, AdminRole, NonAuthenticatedUrls } from '../../constants/NavigationUrls';

export function RoleGuard(user: IUserInfo): number {
  if (user.user_type.code === 'ADMIN') {
    return RoleDefinition.Admin;
  } else if (user.user_type.code !== 'ACCREDITED') {
    return RoleDefinition.Lead;
  } else {
    return RoleDefinition.Accredited;
  }
}

export function RouteRoleGuard(user: IUserInfo, admin: IStatusInfo | null): string {
  if (user.user_type.code === 'ADMIN') {
    if (user.onboarding.completed_profile) {
      return AdminRole.dashboard;
    } else {
      if (user.onboarding.path_created === true) {
        if (admin !== null) {
          return `${NonAuthenticatedUrls.end_path}/${admin.uuid}/${admin.status}`;
        } else {
          return AdminRole.dashboard;
        }
      } else {
        return AdminRole.dashboard;
      }
    }
  } else if (user.user_type.code !== 'ACCREDITED') {
    if (user.onboarding.completed_profile) {
      return AccreditedRole.profile;
    } else {
      return AccreditedRole.onBoarding;
    }
  } else {
    if (user.onboarding.completed_profile === true) {
      return AccreditedRole.profile;
    } else {
      return AccreditedRole.onBoarding;
    }
  }
}
