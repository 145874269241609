import { manageErrors } from 'helpers/manage-error';
import { Badge } from 'interfaces/badge';
import { createContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { getBadgeData, updateDataBadge } from '../../../actions/ApiClient/BadgesAction';
import { useHistory } from 'react-router';
import { AdminRole } from 'constants/NavigationUrls';

type Requirement = {
  id: number | null;
  name: string;
};

interface SkillI {
  value: number;
  label: string;
}

interface InitialValueI {
  nombre: string;
  descripcion: string;
  habilidades: SkillI[];
  requisitos: Requirement[];
  url: string;
  visibilidad: boolean;
  tipo: string | number | null;
  nivel: string | number | null;
  duracion: string | number | null;
  costo: string | number | null;
  durationTime: number;
  costAmount: number;
}

interface BadgeContextI {
  badge: any;
  skills: any[];
  isFetching: boolean;
  initialValues: Object;
  isSaving: boolean;
  setSkills: (values: any) => void;
  handleFormSubmit: (values: any) => void;
}

export const BadgeContext = createContext({} as BadgeContextI);

const BadgeContextProvider = ({ id, children }: any) => {
  const [badge, setBadge] = useState({} as any);
  const [isFetching, setIsFetching] = useState(false);
  const [skills, setSkills] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<InitialValueI>({
    nombre: '',
    descripcion: '',
    habilidades: [],
    requisitos: [{ id: null, name: '' }],
    url: '',
    visibilidad: false,
    tipo: null,
    nivel: null,
    duracion: null,
    costo: null,
    durationTime: 1,
    costAmount: 1,
  });
  const [isSaving, setIsSaving] = useState(false);
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const resp = await getBadgeData(id);
        const data = resp.data as Badge;
        setBadge(data);
        setInitialValues({
          nombre: data.name,
          descripcion: data.description,
          habilidades:
            data.skill_tag && data.skill_tag.length > 0
              ? data.skill_tag.map((item) => ({ label: item.name, value: item.id }))
              : [],
          requisitos: data.requirement,
          url: data.additional_info,
          visibilidad: data.is_public,
          tipo: String(data.badge_type?.id),
          nivel: String(data.level?.id),
          duracion: String(data.duration_type?.id),
          costo: String(data.type_cost?.id),
          durationTime: data.duration,
          costAmount: data.cost,
        });
        setIsFetching(false);
      } catch (e) {
        setIsFetching(false);
        manageErrors(e);
      }
    })();
  }, [id]);

  const handleFormSubmit = async (values: InitialValueI) => {
    try {
      setIsSaving(true);
      const payload = {
        name: values.nombre,
        description: values.descripcion,
        additional_info: values.url,
        is_public: values.visibilidad,
        badge_type: Number(values.tipo),
        level: Number(values.nivel),
        duration_type: Number(values.duracion),
        duration: values.durationTime,
        type_cost: Number(values.costo),
        cost: values.costAmount,
        skill_tag: skills.map((item) => item.value),
        requirements: values.requisitos,
      };
      await updateDataBadge(badge.uuid, payload);
      toast.success('Insignia actualizada correctamente');
      push(AdminRole.badgesPreview);
    } catch (e) {
      setIsSaving(false);
      manageErrors(e);
    }
  };

  return (
    <BadgeContext.Provider
      value={{
        skills,
        badge,
        isFetching,
        initialValues,
        isSaving,
        setSkills,
        handleFormSubmit,
      }}
    >
      {children}
    </BadgeContext.Provider>
  );
};

export default BadgeContextProvider;
