import { BadgeOverviewResponse } from 'interfaces/badge-overview';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TextCenterChart from './TextMidleChart';

type TotalDataT = 2 | 3;

interface PieChartProps {
  badgeOverview: BadgeOverviewResponse;
  width?: number;
  height?: number;
  totalData?: TotalDataT;
}

const PieChart = ({ badgeOverview, width = 313, height = 313, totalData = 2 }: PieChartProps) => {
  const { accepted, pending, rejected } = badgeOverview;

  const getTotal = (): number => accepted.value + pending.value + rejected.value;

  return (
    <div style={{ width, height }}>
      <CircularProgressbarWithChildren
        value={accepted.value}
        strokeWidth={4}
        styles={buildStyles({
          pathColor: '#02B148',
          trailColor: '#dadada',
        })}
      >
        <div style={{ width: '83%' }}>
          <CircularProgressbarWithChildren
            value={pending.value}
            strokeWidth={4}
            styles={buildStyles({
              pathColor: '#F9B233',
              trailColor: '#dadada',
            })}
          >
            {totalData === 2 ? (
              <TextCenterChart total={getTotal()} />
            ) : (
              <div style={{ width: '78%' }}>
                <CircularProgressbarWithChildren
                  value={rejected.value}
                  strokeWidth={4}
                  styles={buildStyles({
                    pathColor: '#DA291C',
                    trailColor: '#dadada',
                    textSize: 11,
                  })}
                >
                  <TextCenterChart total={getTotal()} />
                </CircularProgressbarWithChildren>
              </div>
            )}
          </CircularProgressbarWithChildren>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default PieChart;
