import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetBadgesListTemplatesArchivedThunk,
  GetBadgesListTemplatesThunk,
} from 'actions/Thunks/AdminPathsThunk';
import {
  AdminCourseCatalogFilterOptionsThunk,
  AdminCourseCatalogThunk,
  ListInterestOrganizationThunk,
  LearningExperienceCategoryThunk,
  LearningPathListViewThunk,
  LearningPathExperiencesThunk,
  LearningPathLevelsThunk,
  GetOrganizationBadgesThunk,
  ListInterestOrganizationHabThunk,
} from 'actions/Thunks/AdminThunk';

const ADMIN = 'ADMIN';

const initialState: IAdmin = {
  createUsers: null,
  interestOrganizationList: null,
  interestOrganizationListHab: null,
  courseCatalog: null,
  courseCatalogFilter: null,
  courseCatalogMenu: null,
  experienceCategories: null,
  learningPathListView: null,
  experiencesPathListView: null,
  levelPath: null,
  organizationBadges: null,
  statusEndPath: null,
  emailSingUp: null,
  badgeTemplates: null,
  badgeTemplatesArchived: null,
};

const adminSlice = createSlice({
  name: ADMIN,
  initialState,
  reducers: {
    addCsvFile(state, action: PayloadAction<{ csv: File }>) {
      return {
        ...state,
        createUsers: { ...state.createUsers, csvFileSelected: action.payload.csv },
      };
    },
    removeCsvFile(state) {
      return { ...state, createUsers: { ...state.createUsers, csvFileSelected: null } };
    },
    removeListSelectedCourse(state) {
      return {
        ...state,
        learningPathListView: null,
      };
    },
    dataEndPath(state, action: PayloadAction<IStatusInfo>) {
      return {
        ...state,
        statusEndPath: action.payload,
      };
    },

    cleanDataEndPath(state) {
      return {
        ...state,
        statusEndPath: null,
      };
    },

    emailSingUp(state, action: PayloadAction<string>) {
      return {
        ...state,
        emailSingUp: action.payload,
      };
    },

    cleanEmailSingUp(state) {
      return {
        ...state,
        emailSingUp: null,
      };
    },

    addProgressFile(state, action: PayloadAction<number>) {
      return {
        ...state,
        createUsers: { ...state.createUsers, progressFileSelected: action.payload },
      };
    },
    addCourseCatalogFilter(state, action: PayloadAction<ICourseCatalogFilter>) {
      return { ...state, courseCatalogFilter: action.payload };
    },
    addSingleCourseCatalogFiler(
      state,
      action: PayloadAction<{ index: number; idOption: number; idCategory: number }>
    ) {
      const idCategory = action.payload.idCategory;

      if (state.courseCatalogFilter) {
        switch (idCategory) {
          case 1:
            let interestFilter = state.courseCatalogFilter.interest.slice();
            interestFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                interest: interestFilter,
              },
            };
          case 2:
            let providerFilter = state.courseCatalogFilter.provider.slice();
            providerFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                provider: providerFilter,
              },
            };
          case 3:
            let durationFilter = state.courseCatalogFilter.duration.slice();
            durationFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                duration: durationFilter,
              },
            };
          case 4:
            let typeCostFilter = state.courseCatalogFilter.type_cost.slice();
            typeCostFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                type_cost: typeCostFilter,
              },
            };
          case 5:
            let levelFilter = state.courseCatalogFilter.level.slice();
            levelFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                level: levelFilter,
              },
            };
          case 6:
            let languageFilter = state.courseCatalogFilter.language.slice();
            languageFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                language: languageFilter,
              },
            };
          case 7:
            let skillsFilter = state.courseCatalogFilter.skill.slice();
            skillsFilter.splice(action.payload.index, 0, action.payload.idOption);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                skill: skillsFilter,
              },
            };
        }
      }
      return { ...state };
    },
    removeSingleCourseCatalogFiler(
      state,
      action: PayloadAction<{ index: number; idCategory: number; idItem: number }>
    ) {
      const idCategory = action.payload.idCategory;

      if (state.courseCatalogFilter) {
        const indexToRemove = state.courseCatalogFilter.interest.indexOf(action.payload.idItem);

        switch (idCategory) {
          case 1:
            let interestFilter = state.courseCatalogFilter.interest.slice();
            interestFilter.splice(indexToRemove, 1);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                interest: interestFilter,
              },
            };
          case 2:
            let providerFilter = state.courseCatalogFilter.provider.slice();
            providerFilter.splice(indexToRemove, 1);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                provider: providerFilter,
              },
            };
          case 3:
            let durationFilter = state.courseCatalogFilter.duration.slice();
            durationFilter.splice(indexToRemove, 1);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                duration: durationFilter,
              },
            };
          case 4:
            let typeCostFilter = state.courseCatalogFilter.type_cost.slice();
            typeCostFilter.splice(indexToRemove, 1);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                type_cost: typeCostFilter,
              },
            };
          case 5:
            let levelFilter = state.courseCatalogFilter.level.slice();
            levelFilter.splice(indexToRemove, 1);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                level: levelFilter,
              },
            };
          case 6:
            let languageFilter = state.courseCatalogFilter.language.slice();
            languageFilter.splice(indexToRemove, 1);

            return {
              ...state,
              courseCatalogFilter: {
                ...state.courseCatalogFilter,
                language: languageFilter,
              },
            };
        }
      }
      return { ...state };
    },
    updateSelectionCatalogMenu(state, action: PayloadAction<number>) {
      if (state.courseCatalogMenu) {
        const updateFilter = state.courseCatalogMenu.map((filter, filterIndex) => {
          let updateSelection: IFilterSerializedOption;

          if (action.payload === filterIndex) {
            updateSelection = { ...filter, selected: !filter.selected };
          } else {
            updateSelection = { ...filter, selected: false };
          }
          return updateSelection;
        });

        return { ...state, courseCatalogMenu: updateFilter };
      }
      return { ...state };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ListInterestOrganizationThunk.fulfilled, (state, { payload }) => {
      state.interestOrganizationList = payload;
    });
    builder.addCase(ListInterestOrganizationHabThunk.fulfilled, (state, { payload }) => {
      state.interestOrganizationListHab = payload;
    });
    builder.addCase(AdminCourseCatalogThunk.fulfilled, (state, { payload }) => {
      state.courseCatalog = payload;
    });
    builder.addCase(AdminCourseCatalogFilterOptionsThunk.fulfilled, (state, { payload }) => {
      state.courseCatalogMenu = payload;
    });
    builder.addCase(LearningExperienceCategoryThunk.fulfilled, (state, { payload }) => {
      state.experienceCategories = payload;
    });
    builder.addCase(LearningPathListViewThunk.fulfilled, (state, { payload }) => {
      state.learningPathListView = payload;
    });
    builder.addCase(LearningPathExperiencesThunk.fulfilled, (state, { payload }) => {
      state.experiencesPathListView = payload;
    });
    builder.addCase(LearningPathLevelsThunk.fulfilled, (state, { payload }) => {
      state.levelPath = payload;
    });
    builder.addCase(GetOrganizationBadgesThunk.fulfilled, (state, { payload }) => {
      state.organizationBadges = payload;
    });
    builder.addCase(GetBadgesListTemplatesThunk.fulfilled, (state, { payload }) => {
      state.badgeTemplates = payload;
    });
    builder.addCase(GetBadgesListTemplatesArchivedThunk.fulfilled, (state, { payload }) => {
      state.badgeTemplatesArchived = payload;
    });
  },
});

export const {
  addCsvFile,
  removeCsvFile,
  addProgressFile,
  addCourseCatalogFilter,
  addSingleCourseCatalogFiler,
  removeSingleCourseCatalogFiler,
  updateSelectionCatalogMenu,
  removeListSelectedCourse,
  dataEndPath,
  cleanDataEndPath,
  emailSingUp,
} = adminSlice.actions;
export default adminSlice.reducer;
