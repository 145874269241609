import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const MainNotFoundTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 50px;
  color: ${PRIMARY_COLOR};
  text-align: center;
  line-height: normal;

  @media ${device.smallMobile} {
    font-size: 40px;
  }
`;

export const TextLabelNotFound = styled(Label)`
  text-align: center;
  margin-top: 29px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 29px;
  margin-bottom: 10px;
`;

export const ImageMainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ImageNotFoundWrapper = styled.div`
  max-width: 777px;
`;

export const ImageNotFound = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`;
