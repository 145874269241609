import * as React from 'react';
import {
  BadgesListSectionTitle,
  BadgesDateTitle,
  BadgesDate,
  AdminBadgeListContainer,
  BadgeName,
  BadgeOwner,
  BadgesContainer,
  BadgesInfoContainer,
  BadgeDateWrapper,
  ColStyled,
  MyRoutesRows,
} from '../AdminBadgesList/styles';
import { formatDate } from 'utils';

interface AdminBadgesListProps {
  badges: any;
  dateStart: any;
  pathwayId: string;
  userType: any;
}

const BadgeListPublic = ({
  badges,
  dateStart,
  pathwayId,
  userType,
}: AdminBadgesListProps): JSX.Element => {

  return (
    <AdminBadgeListContainer>
      <BadgesContainer>
        <MyRoutesRows>
          {badges &&
            badges.map((badge: any, index: any) => {
              return (
                <ColStyled key={index}>
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <BadgeName styles>{badge.badge_template.name.substring(0, 20)}</BadgeName>
                    <img src={badge.badge_template.image.url} alt="" width={'120px'} />
                    <BadgeOwner>{badge.badge_template.owner.name.substring(0, 10)}</BadgeOwner>
                  </div>
                </ColStyled>
              );
            })}
        </MyRoutesRows>
      </BadgesContainer>
      <BadgesInfoContainer>
        <BadgesListSectionTitle>
          Obtén {badges.length} {badges.length > 1 ? 'insignias' : 'insignia'} al finalizar la ruta
        </BadgesListSectionTitle>
        <BadgeDateWrapper>
          <BadgesDateTitle>Fecha de inicio</BadgesDateTitle>
          {dateStart ? (
            <BadgesDate>{formatDate(dateStart)}</BadgesDate>
          ) : (
            <BadgesDate>Fecha flexible</BadgesDate>
          )}
        </BadgeDateWrapper>
      </BadgesInfoContainer>
    </AdminBadgeListContainer>
  );
};

export default BadgeListPublic;
