import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const CoursesWrappedSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

export const CoursesCardWrapper = styled.div`
  margin: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CoursesSectionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
`;

export const RecomendedCoursesWrappedSection = styled.div`
  background: #f7f7f7;
`;

export const RecomendedCoursesCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RecomendedCoursesSectionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin: 40px;
`;

export const AdminCoverSection = styled.div`
  height: 500px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const RouteInfoWrapper = styled.div`
  position: relative;
  top: -400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RouteTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 40px;
  line-height: 68px;
  color: #ffffff;
`;

export const RouteSubTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
`;

export const RouteShareTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  margin: 10px;
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  margin: 40px 0px;
`;

export const RouteShareSectionWrapper = styled.div`
  color: white;
  svg {
    cursor: pointer;
  }
`;
