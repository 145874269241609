const additionalBadgeInfo = {
  cost: [
    { label: 'Pago', value: 1 },
    { label: 'Grátis', value: 2 },
  ],
  type: [
    { label: 'Aprendizaje', value: 1 },
    { label: 'Experiencia', value: 2 },
    { label: 'Validación', value: 3 },
    { label: 'Certificación', value: 4 },
  ],
  duration: [
    { label: 'Años', value: 1 },
    { label: 'Días', value: 2 },
    { label: 'Meses', value: 3 },
    { label: 'Horas', value: 4 },
    { label: 'Semanas', value: 5 },
  ],
};

export default additionalBadgeInfo;
