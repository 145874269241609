import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import FooterSocials from '../FooterSocials';
import { FooterContactUsContainer, FooterContactUsNavLink, FooterContactUsTitle } from './styles';

const FooterContactUs: React.FC = () => {
  return (
    <FooterContactUsContainer>
      <FooterContactUsTitle>Contáctenos</FooterContactUsTitle>
      <Nav vertical>
        <NavItem>
          <FooterContactUsNavLink href="#">Formulario</FooterContactUsNavLink>
        </NavItem>
        <NavItem>
          <FooterContactUsNavLink href="#">Chat</FooterContactUsNavLink>
        </NavItem>
        <NavItem>
          <FooterContactUsNavLink href="#">Colombia, Bogota, D.C.</FooterContactUsNavLink>
        </NavItem>
        <NavItem>
          <FooterContactUsNavLink href="#">+ 57 (319) 310 - 0496</FooterContactUsNavLink>
        </NavItem>
        <NavItem>
          <FooterContactUsNavLink href="#">+ 57 (031) 745 - 2237</FooterContactUsNavLink>
        </NavItem>
      </Nav>
      <FooterSocials />
    </FooterContactUsContainer>
  );
};

export default FooterContactUs;
