import styled from 'styled-components';
import AcredittaText from 'components/atoms/AcredittaText';
import IssueBadgeButton from './IssueBadgeButton';
import { useState } from 'react';
import { BadgeResult } from 'interfaces/badge-list-response';

const StyledDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 20px 15px;
  margin-right: 12px;
  min-height: 239px;
  height: 239px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledImage = styled.img`
  margin: 10px auto;
  display: block;
`;

const StyleItemDiv = styled.div`
  flex: 1;
  min-height: 50px;
  max-height: 50px;
`;
interface Props {
  item: BadgeResult;
}

const CardCarouselItem = ({ item }: Props) => {
  const [show, setShow] = useState(false);

  return (
    <StyledDiv onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      {show && <IssueBadgeButton id={item.uuid} />}
      <StyleItemDiv>
        <AcredittaText variant="body3" lineHeight="1em" align="center" fontWeight="bold">
          {item.name.substring(0, 25)} {item.name.length > 25 && '...'}
        </AcredittaText>
      </StyleItemDiv>
      <div style={{ position: 'relative' }}>
        <StyledImage src={item.image_url} alt="" width="110" height="110" style={{ zIndex: -1 }} />
      </div>
      <div>
        <AcredittaText variant="body3" color="secondary" lineHeight="1em" align="center">
          {item.created_at.substring(0, 10)}
        </AcredittaText>
      </div>
    </StyledDiv>
  );
};

export default CardCarouselItem;
