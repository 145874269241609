import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const IssuerInfo = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const IssuerName = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;

export const BadgeDetailsResumenInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;

export const BadgeImageWrapper = styled.div<{
  $selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  img {
    width: 250px;
    opacity: ${(props) => (props.$selected ? '1' : '1')};
  }
`;

export const BadgeIssuerWrapper = styled.div`
  margin: 20px 0px;
  top: 20px;
`;

export const BadgeResumenInfoWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: grid;
  grid-template-columns: 50% 50%;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgb(138 149 158 / 30%);
  border-radius: 8px;
`;

export const BadgeResumenItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const BadgeResumenItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  svg {
    width: 40px;
    margin-right: 10px;
    color: ${PRIMARY_COLOR};
  }
`;

export const BadgeResumenItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const BadgeResumenItemDesc = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const ImageContainerOnHover = styled.div`
  width: 380px;
  height: 300px;
  position: absolute;
  top: 60px;
  mix-blend-mode: luminosity;
  opacity: 0.9;
  background: #ffffff;
`;

export const SocialMediaShareButtonsContainer = styled.div`
  width: 100%;
  height: 0px;
  position: relative;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const BadgeResumenItemTitleDownload = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${SECONDARY_COLOR};
  margin-bottom: 0px;
  cursor: pointer;
`;
