import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import { useParams } from 'react-router-dom';
import BadgesStackableDetail from './components/BadgesStackableDetail';
import BadgeImageProvider from './context/BadgeImageProvider';
import BadgesStackableProvider from './context/BadgeStackableProvider';

// TODO: link de ver mas información a detalle de la pagina

const BagdesStackableMain = () => {
  const { id } = useParams() as any;

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <BadgesStackableProvider id={id}>
        <BadgeImageProvider>
          <BadgesStackableDetail></BadgesStackableDetail>
        </BadgeImageProvider>
      </BadgesStackableProvider>
    </CommonPageLayout>
  );
};

export default BagdesStackableMain;
