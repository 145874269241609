import arrowLeft from '../../../../assets/images/arrow-left.png';
import arrowRight from '../../../../assets/images/arrow-right.png';

const CarouselButtons = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  return (
    <div className="carousel-button-group">
      <div
        style={{ position: 'absolute', top: '50%', left: -10, cursor: 'pointer' }}
        className={currentSlide === 0 ? 'disable' : ''}
        onClick={() => previous()}
      >
        <img src={arrowLeft} alt="" />
      </div>
      <div
        style={{ position: 'absolute', top: '50%', right: 0, cursor: 'pointer' }}
        className={currentSlide === 0 ? 'disable' : ''}
        onClick={() => next()}
      >
        <img src={arrowRight} alt="" />
      </div>
    </div>
  );
};

export default CarouselButtons;
