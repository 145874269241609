import * as React from 'react';
import { NavigationWrapper, IconAddUser, IconText, IconWrapper, IconAddPeople } from './styles';

interface BadgeIssueNavigationProps {
  navigateIndividual?: () => void;
  navigateMassive?: () => void;
  selection: string;
}

const BadgeIssueNavigation: React.FC<BadgeIssueNavigationProps> = ({
  selection,
  navigateIndividual,
  navigateMassive,
}) => {
  return (
    <NavigationWrapper>
      <IconWrapper onClick={navigateIndividual}>
        <IconAddUser selected={selection === 'individual'} />
        <IconText selected={selection === 'individual'}>Emisión Individual</IconText>
      </IconWrapper>
      <IconWrapper onClick={navigateMassive}>
        <IconAddPeople selected={selection === 'masive'} />
        <IconText selected={selection === 'masive'}>Emisión Masiva</IconText>
      </IconWrapper>
    </NavigationWrapper>
  );
};

export default BadgeIssueNavigation;
