import { createSlice } from '@reduxjs/toolkit';
import { GetStatusEvidenceThunk, ListRoutesThunk } from 'actions/Thunks/AcreditedThunk';

const ROUTE = 'ROUTE';

const initialState: any = {
  listMyRoutes: null,
  dataPathVerify: null,
};

const acreditedSlice = createSlice({
  name: ROUTE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ListRoutesThunk.fulfilled, (state, { payload }) => {
      state.listMyRoutes = payload;
    });
    builder.addCase(GetStatusEvidenceThunk.fulfilled, (state, { payload }) => {
      state.dataPathVerify = payload;
    });
  },
});

export default acreditedSlice.reducer;
