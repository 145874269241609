import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media (max-width: 1116px) {
    flex-wrap: wrap;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  width: 150px;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MenuItemHeader = styled.div`
  display: flex;
  align-items: center;
  color: ${SECONDARY_COLOR};
  svg {
    margin-right: 4px;
  }
`;

export const MenuItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const MenuItemInfo = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
  margin-bottom: 0px;
`;
export const Divisor = styled.div`
  width: 2px;
  height: 48px;
  background-color: #EBEBEB;
  @media (max-width: 1116px) {
    display: none;
  }
`;                

