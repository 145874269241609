import styled from 'styled-components';
import { Row } from 'reactstrap';

export const DnDBoardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.25);
  border-radius: 8px;
  padding: 38px 28px;
  margin-top: 50px;
`;

export const DnDBoardContentMainWrapper = styled.div``;

export const DnDBoardContentDataWrapper = styled(Row)`
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const DnDBoardWrapperPanPitch = styled.div`
  border: 1px solid #54575b;
  border-radius: 8px;
  height: 640px;
  padding: 17px;
`;
