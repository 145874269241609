import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import icons from 'assets/Icon';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { seeMoreInformationAcreditado, seeMoreInformationAdmin } from 'constants/NavigationUrls';

interface Props {
  badge: any;
  onClose: () => void;
}

const BadgeMoreInfo = ({ badge, onClose }: Props) => {
  const { name, description, skills, duration, duration_type, uuid } = badge;
  const user = useAppSelector((state) => state.user);

  return (
    <AcredittaBox>
      <div className="text-right cursor-pointer" onClick={onClose}>
        <icons.CleanSearchIcon />
      </div>
      <AcredittaText variant="h3" align="center">
        {name}
      </AcredittaText>
      <div>
        <AcredittaText variant="h5">Descripción</AcredittaText>
        <AcredittaText variant="body1" color="secondary">
          {description}
        </AcredittaText>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-8">
          <AcredittaText variant="h5">Habilidades</AcredittaText>
          <div className="d-flex" style={{ flexWrap: 'wrap' }}>
            {skills &&
              skills.map((skill: string, id: number) => (
                <div className="skill-box" key={id}>
                  {skill}
                </div>
              ))}
          </div>
        </div>
        <div className="col-xs-12 col-sm-4" style={{ borderLeft: '2px solid rgba(0,0,0,.1)' }}>
          <AcredittaText variant="h5">Tiempo invertido</AcredittaText>
          <div>
            <AcredittaText variant="body1" color="secondary">
              <icons.CheckIcon width={18} height={18} /> {duration}{' '}
              {duration_type && duration_type.name}
            </AcredittaText>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <Link
            to={
              user?.userInfo?.user_type.id === 2
                ? seeMoreInformationAdmin(uuid)
                : seeMoreInformationAcreditado(uuid)
            }
            style={{ textDecoration: 'underline' }}
          >
            <AcredittaText variant="body1" align="center" fontWeight="600">
              Ver más información
            </AcredittaText>
          </Link>
        </div>
      </div>
    </AcredittaBox>
  );
};

export default BadgeMoreInfo;
