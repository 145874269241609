import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import { device } from 'constants/responsiveDevice';
import { useMediaQuery } from 'react-responsive';
import ArrowStep from './ArrowStep';
import { useContext } from 'react';
import { BadgesStackableContext } from '../context/BadgeStackableProvider';
import icons from '../../../../assets/Icon/index';
import { useAppSelector } from 'hooks/redux';

interface BadgeCardProps {
  item: any;
  idx: number;
  elements: number;
  onPress: () => void;
}

const BadgeCard = ({ item, idx, elements, onPress }: BadgeCardProps) => {
  const responsive = useMediaQuery({ query: device.ipad });
  const { collections, type } = useContext(BadgesStackableContext);
  const { name, image_url, is_complete, step } = item;
  const user = useAppSelector((state) => state.user);
  const isAdmin = user.userInfo && user.userInfo.user_type.code === 'ADMIN';

  return (
    <>
      <div
        className={`${type === 'step' ? 'custom-box-col1' : 'custom-box-col2'}`}
        key={item.uuid}
        onClick={onPress}
      >
        {type === 'step' && (
          <AcredittaText variant="h5" align="center">
            Paso # {step}
          </AcredittaText>
        )}

        <AcredittaBox boxStyle={{ position: 'relative', minHeight: '290px' }}>
          {!isAdmin && (
            <div style={{ position: 'absolute', top: 15, right: 10 }}>
              <icons.CheckIcon color={is_complete ? 'green' : 'grey'} width="18" height="18" />
            </div>
          )}
          <img
            src={image_url}
            width="100%"
            height="auto"
            style={{ minHeight: '142px' }}
            className={`center-image ${!isAdmin ? (is_complete ? 'green' : 'grey') : ''}`}
            alt=""
          />
          <AcredittaText
            variant="h5"
            align="center"
            style={{ lineHeight: '1.2em', marginTop: '10px' }}
          >
            {name.substring(0, 25)} ...
          </AcredittaText>
        </AcredittaBox>
      </div>

      {type === 'step' && (
        <>
          {responsive && item < collections.length && <ArrowStep />}
          {!responsive && idx + 1 < elements && <ArrowStep />}
        </>
      )}
    </>
  );
};
export default BadgeCard;
