import styled, { css } from 'styled-components';

const primary = '#002057';
const secondary = '#53565A';
const accent = '#202120';
const red = '#DA291C';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body1' | 'body2' | 'body3';
type Color = 'primary' | 'secondary' | 'accent' | 'white' | 'red';
type Align = 'left' | 'center' | 'right' | 'justify';
type FontWeight =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | 'bold'
  | 'normal';

const handleFontSize = (variant: Variant) => {
  switch (variant) {
    case 'h1':
      return css`
        font-family: NotoSansJPBold;
        font-size: 50px;
        font-weight: 700;
      `;

    case 'h2':
      return css`
        font-family: NotoSansJPBold;
        font-size: 35px;
        font-weight: 700;
      `;

    case 'h3':
      return css`
        font-family: NotoSansJPBold;
        font-size: 30px;
        font-weight: 700;
      `;

    case 'h4':
      return css`
        font-family: NotoSansJPBold;
        font-size: 20px;
        font-weight: 700;
      `;

    case 'h5':
      return css`
        font-family: NotoSansJPBold;
        font-size: 16px;
        font-weight: 700;
      `;

    case 'body1':
      return css`
        font-family: NotoSansJP;
        font-style: normal;
        font-size: 16px;
      `;

    case 'body2':
      return css`
        font-family: NotoSansJP;
        font-style: normal;
        font-size: 20px;
      `;

    case 'body3':
      return css`
        font-family: NotoSansJP;
        font-style: normal;
        font-size: 14px;
      `;
  }
};

const handleFontColor = (color: Color) => {
  switch (color) {
    case 'primary':
      return primary;
    case 'secondary':
      return secondary;
    case 'accent':
      return accent;
    case 'white':
      return '#ffffff';
    case 'red':
      return red;
  }
};

type Props = {
  variant: Variant;
  color?: Color;
  align?: Align;
  fontWeight?: FontWeight;
  lineHeight?: string;
};

const AcredittaText = styled.p<Props>`
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  color: ${({ color }) => (color ? handleFontColor(color) : primary)};
  ${({ variant }) => handleFontSize(variant)};
  margin: 10px 0;
  padding: 0;
  text-align: ${({ align }) => (align ? align : 'left')};
  line-height: ${({ lineHeight }) => lineHeight && lineHeight};
`;

export default AcredittaText;
