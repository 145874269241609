import * as React from 'react';
import LearningPathDetails from 'components/molecules/AdminCreateNewLearningPath/LearningPathDetails';
import LearningPathInfo from 'components/molecules/AdminCreateNewLearningPath/LearningPathInfo';
import { OrganizationProfileContainer, OrganizationProfileSectionsContainer } from './styles';
import { StepWizardProps } from 'react-step-wizard';
import { useForm } from 'react-hook-form';
import { objectValidation } from 'utils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SaveNewLearningPathThunk, SelectedCourseListThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';

interface AdminEditLearningPathProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
  pathwayId: string;
}

const AdminEditLearningPath = ({ pathwayId }: AdminEditLearningPathProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const courseSelector = useAppSelector((state) => state.courses);
  const coursesSelector = useAppSelector((state) => state.courses);
  const user = useAppSelector((state) => state.user);

  const [is_private, onSelectVisibility] = React.useState<boolean>(false);
  const [type_path, onSelectTypePath] = React.useState<number | null>(null);
  const [level, onSelectLevel] = React.useState<number | null>(null);

  const defaultValuesAdditionalInfo: IUpdatePath = {
    name: '',
    description: '',
    is_private: false,
    is_draft: false,
    is_group: false,
    is_completed: false,
    level: null,
    photo: null,
    insignia: null,
    methodology: null,
  };

  const getDefaultAdditionalInfoFormValues = (): IUpdatePath => {
    return defaultValuesAdditionalInfo;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IUpdatePath>({
    ...objectValidation,
    defaultValues: getDefaultAdditionalInfoFormValues(),
  });

  const finishOnBoarding = () => {
    if (coursesSelector.pathwayCreated) {
      if (user.userRole === 3) {
        history.push(`${AccreditedRole.learningPathPreview}/${coursesSelector.pathwayCreated.id}`);
      } else {
        history.push(`${AdminRole.learningPathPreview}/${coursesSelector.pathwayCreated.id}`);
      }
    }
  };

  const submitNewPathForm = async (pathInfo: IUpdatePath) => {
    if (courseSelector.pathwayCreated && user.userInfo) {
      const new_learning_path: ISaveUpdatePath = {
        path_id: parseInt(pathwayId),
        path_info: {
          name: pathInfo.name,
          description: pathInfo.description,
          is_private: is_private === true ? false : true,
          is_draft: false,
          is_group: pathInfo.is_group,
          is_completed: pathInfo.is_completed,
          methodology: type_path,
          level: level,
        },
        path_photo: {
          photo: pathInfo.photo,
        },
      };
      const create_new_path = await dispatch(SaveNewLearningPathThunk(new_learning_path));
      if (SaveNewLearningPathThunk.fulfilled.match(create_new_path)) {
        if (create_new_path.payload) {
          toast.success(create_new_path.payload);
          finishOnBoarding();
        }
      } else {
        toast.error(create_new_path.payload as string);
      }
    }
  };

  const persistPath = React.useCallback(async () => {
    const experiencesSel = await dispatch(SelectedCourseListThunk(parseInt(pathwayId)));
    if (SelectedCourseListThunk.rejected.match(experiencesSel)) {
      toast.error(experiencesSel.payload as string);
    }
  }, [pathwayId, dispatch]);

  React.useEffect(() => {
    persistPath();
  }, [persistPath]);

  const saveDraftNewPathForm = async (pathInfo: IUpdatePath) => {
    if (courseSelector.pathwayCreated && user.userInfo) {
      const new_learning_path: ISaveUpdatePath = {
        path_id: parseInt(pathwayId),
        path_info: {
          name: pathInfo.name,
          description: pathInfo.description,
          is_private: is_private,
          is_draft: true,
          is_group: pathInfo.is_group,
          is_completed: pathInfo.is_completed,
          methodology: type_path,
          level: level,
        },
        path_photo: {
          photo: pathInfo.photo,
        },
      };
      const create_new_path = await dispatch(SaveNewLearningPathThunk(new_learning_path));
      if (SaveNewLearningPathThunk.fulfilled.match(create_new_path)) {
        if (create_new_path.payload) {
          toast.success(create_new_path.payload);
          finishOnBoarding();
        }
      } else {
        toast.error(create_new_path.payload as string);
      }
    }
  };

  return (
    <OrganizationProfileContainer>
      <OrganizationProfileSectionsContainer>
        <LearningPathDetails
          controlInfo={control}
          errorsInfo={errors}
          watch={watch}
          onSelectTypePath={onSelectTypePath}
          onSelectLevel={onSelectLevel}
        />
        <LearningPathInfo
          controlInfo={control}
          handleSubmitForm={handleSubmit}
          errorsInfo={errors}
          submitNewPathForm={submitNewPathForm}
          onSelectVisibility={onSelectVisibility}
          submitDraft={saveDraftNewPathForm}
        />
      </OrganizationProfileSectionsContainer>
    </OrganizationProfileContainer>
  );
};

export default AdminEditLearningPath;
