import { AcredittaNavbar } from 'components/atoms/Navbar';
import PublicHeader from 'components/atoms/PublicHeader';
import { useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { PublicLayoutContainer, LayoutContainer } from './styles';

interface PublicLayoutProps {
  children: React.ReactNode;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
  const { userInfo } = useAppSelector((state) => state.user);

  return (
    <LayoutContainer>
      {userInfo ? <AcredittaNavbar /> : <PublicHeader />}
      <main>
        <PublicLayoutContainer>{children}</PublicLayoutContainer>
      </main>
    </LayoutContainer>
  );
};

export default PublicLayout;
