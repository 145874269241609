import { useContext } from 'react';
import { Navbar, Nav } from 'reactstrap';
import { MenuContext } from './context/MenuContextProvider';
import { MenuItem, MenuItemWithList } from './components/desktop';
import { AcredittaThemeContext } from '../../../context/AcredittaThemeContext';
import './styles.css';

const AcredittaNavbarMenu = ({ args }: any) => {
  const { backgroundHeader } = useContext(AcredittaThemeContext);
  const { menu } = useContext(MenuContext);

  if (!menu) {
    return <></>;
  }

  return (
    <div>
      <Navbar {...args} style={{ background: backgroundHeader }}>
        <Nav navbar>
          {menu.map((item) =>
            item.subItems && item.subItems.length > 0 ? (
              <MenuItemWithList mainText={item.text} items={item.subItems} key={item.id} />
            ) : (
              <MenuItem key={item.id} element={item} />
            )
          )}
        </Nav>
      </Navbar>
    </div>
  );
};

export default AcredittaNavbarMenu;
