import * as React from 'react';
import {
  BadgeDetailsContainer,
  ModalBodyStyled,
  ModalContainer,
  ModalText,
  ModalTitle,
} from './styles';
import { useForm } from 'react-hook-form';
import BadgeIssueResumenInfo from 'components/molecules/BadgeIssueResumenInfo';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { objectValidation } from 'utils';
import AdminUploadNewBadgeForm from 'components/molecules/AdminUploadNewBadgeForm';
import { DetailBadgesThunk } from 'actions/Thunks/AdminPathsThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { GetNewIssueThunk, NewIssueThunk } from 'actions/Thunks/AdminThunk';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import BadgeIssueNavigation from 'components/molecules/BadgeIssueNavigation';
import { AdminRole } from 'constants/NavigationUrls';
import ButtonCom from 'components/atoms/ButtonCom';
import { ChangeNumberThunk } from 'actions/Thunks/UserThunk';
import PhoneInput from 'react-phone-input-2';
import { withHttps } from 'helpers/with-https';

interface AdminBadgeDetail {
  badge_id: string;
}

const BadgeDetails = ({ badge_id }: AdminBadgeDetail): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const detail = useAppSelector((state) => state.detailBadges);
  const user = useAppSelector((state) => state.user);

  const [modalTwo, setModalTwo] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<any>();
  const [country, setCountry] = React.useState<any>();
  const [email, setEmail] = React.useState<string>('');
  const [provider, setProvider] = React.useState<string>();

  const newPhoneForm = async () => {
    if (phoneNumber !== '') {
      const numberRequest: any = {
        uuid: user.userInfo?.cognito_id,
        path: {
          path: '/issue-badge/',
        },
        new_number: {
          user: {
            phone_number: phoneNumber,
          },
          accredited: {
            country: country.dialCode,
          },
        },
      };
      const newPhone = await dispatch(ChangeNumberThunk(numberRequest));

      if (ChangeNumberThunk.fulfilled.match(newPhone)) {
        setModalTwo(!modalTwo);
      } else {
        toast.error(newPhone.payload as string);
      }
    }
  };

  const submitNewIssueForm = async (issueInfo: any) => {
    if (issueInfo.name && user.userInfo && detail.detailBadges) {
      const valdiateEmail = await dispatch(GetNewIssueThunk(issueInfo.email));
      if (NewIssueThunk.rejected.match(valdiateEmail)) {
        setEmail(issueInfo.email);
        setModalTwo(!modalTwo);
      } else {
        const newIssueInfo: any = {
          path_info: {
            first_name: issueInfo.name,
            last_name: issueInfo.lastName,
            dni: issueInfo.document,
            email: issueInfo.email,
            city: 2,
            country: 1,
            dni_type: issueInfo.typeDocument ? parseInt(issueInfo.typeDocument, 10) : undefined,
            evidence_type: null,
            user: null,
            badge_notification_email: false,
            expires_at: issueInfo.end_date ? moment(issueInfo.end_date).format('YYYY-MM-DD') : null,
            issued_at: moment(issueInfo.start_date).format('YYYY-MM-DD'),
            language: [2],
            badge: {
              badge_template: badge_id,
              is_public: detail.detailBadges.data.public,
            },
            accredited_type_tag: issueInfo.tags,
            evidence: {
              name: issueInfo.nombreEvidencia || '',
              description: issueInfo.descripcionEvidencia || '',
              url: issueInfo.enlaceComprobacion
                ? withHttps(String(issueInfo.enlaceComprobacion))
                : '',
            },
          },
        };
        const newCourse = await dispatch(NewIssueThunk(newIssueInfo));
        if (NewIssueThunk.rejected.match(newCourse)) {
          toast.error(
            (newCourse.payload as string) ||
              'Ha ocurrido un error, por favor inténtelo de nuevo más tarde'
          );
        } else {
          toast.success('Se están emitiendo tus insignias, por favor espera un momento');
          history.goBack();
        }
      }
    }
  };

  const defaultValuesNewCourseUpload: any = {
    first_name: null,
    last_name: null,
    dni: null,
    email: null,
    city: null,
    country: null,
    dni_type: null,
    evidence_type: null,
    user: user?.userRole,
    badge_notification_email: false,
    expires_at: null,
    issued_at: null,
    language: [2],
    badge: {
      badge_template: badge_id,
      is_public: detail.detailBadges?.data.public,
    },
    accredited_type_tag: null,
  };

  const getDefaultFormValues = (): IUploadNewIssue => {
    return defaultValuesNewCourseUpload;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<INewIssueControl>({
    ...objectValidation,
    defaultValues: getDefaultFormValues(),
  });

  const getDetailBadges = React.useCallback(async () => {
    const badgesDetail = await dispatch(DetailBadgesThunk(badge_id));
    setProvider(badgesDetail.payload.data.provider);
    if (DetailBadgesThunk.rejected.match(badgesDetail)) {
      toast.error(badgesDetail.payload as string);
    }
  }, [badge_id, dispatch]);

  const navigateMassive = () => {
    history.push(`${AdminRole.issueMasive}/${badge_id}`);
  };

  React.useEffect(() => {
    getDetailBadges();
  }, [getDetailBadges]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <BadgeIssueNavigation selection="individual" navigateMassive={navigateMassive} />
      <BadgeDetailsContainer>
        {detail.detailBadges &&
          (detail.detailBadges.data.image_url !== null ? (
            <BadgeIssueResumenInfo
              image={detail.detailBadges.data.image_url}
              name={detail.detailBadges.data.name}
              uuid={detail.detailBadges.data.id}
            />
          ) : (
            <BadgeIssueResumenInfo
              image={detail.detailBadges.data.image.url}
              name={detail.detailBadges.data.name}
              uuid={detail.detailBadges.data.id}
            />
          ))}
        <AdminUploadNewBadgeForm
          controlInfo={control}
          handleSubmitForm={handleSubmit}
          errorsInfo={errors}
          onCreate={submitNewIssueForm}
          submitNewIssueForm={submitNewIssueForm}
          watch={watch}
          provider={provider}
        />
      </BadgeDetailsContainer>
      <ModalContainer
        isOpen={modalTwo}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyled>
          <ModalTitle>¡Mejora tu cuenta!</ModalTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {detail.detailBadges !== null && (
              <ModalText>
                <strong>No</strong> pudimos emitir la insignia{' '}
                <strong>{detail.detailBadges.data.name}</strong> los siguientes usuarios
              </ModalText>
            )}
            {email !== '' && (
              <ModalText>
                <strong>{email}</strong>
              </ModalText>
            )}
            {user.userInfo !== null && (
              <ModalText>
                Actualmente cuentas con <strong>25 usuarios activos</strong> en tu plan{' '}
                <strong>{user.userInfo?.organization.plan.name}</strong>, para obtener más usuarios
                déjanos tus datos y el área de ventas se contactará contigo lo más pronto posible.
              </ModalText>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <PhoneInput
                regions={['north-america', 'south-america', 'central-america']}
                country="co"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                value={phoneNumber}
                onChange={(phoneNumber, country) => {
                  setPhoneNumber(phoneNumber);
                  setCountry(country);
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <ButtonCom
                label="Cancelar"
                widthCustom={150}
                lightMode={true}
                onClick={() => setModalTwo(!modalTwo)}
                style={{
                  padding: '5%',
                }}
              />
              <div
                style={{
                  padding: '5%',
                }}
              >
                <ButtonCom
                  label={'Solicitar Mejora'}
                  widthCustom={200}
                  onClick={() => newPhoneForm()}
                />
              </div>
            </div>
          </div>
        </ModalBodyStyled>
      </ModalContainer>
    </CommonPageLayout>
  );
};

export default BadgeDetails;
