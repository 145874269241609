export const LoginException = (code: string): string => {
  switch (code) {
    case 'UserNotConfirmedException':
      return 'Tu usuario no se encuentra verificado, contáctate con el administrador';
    case 'PasswordResetRequiredException':
      return 'Debes cambiar tu contraseña para poder continuar';
    case 'NotAuthorizedException':
      return 'Inicio de sesión inválido';
    case 'UserNotFoundException':
      return 'Lo sentimos, tu usuario no existe, contáctate con el administrador';
    case 'InvalidParameterException':
      return 'El email o contraseña ingresados son incorrectos';
    case 'LimitExceededException':
      return 'Límite de intentos superado, por favor, inténtelo después de un tiempo.';
    case 'CodeMismatchException':
      return 'Código ingresado es incorrecto.';
    case 'ExpiredCodeException':
      return 'El código ingresado ha expirado';
    default:
      return 'Ha ocurrido un error al iniciar sesión';
  }
};
