import * as React from 'react';
import {
  ExperiencesListContainer,
  ExperienceWrapper,
  ExperienceName,
  ExperienceIconWrapper,
  ExperiencesContainer,
} from './styles';
import icons from 'assets/Icon';
import { LearningExperienceCategoryThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';

interface LearningExperiencesListProps {
  onSelectLearningExperience: (category: number) => void;
  canceledSelection: boolean;
}

const LearningExperiencesList = ({
  onSelectLearningExperience,
  canceledSelection,
}: LearningExperiencesListProps): JSX.Element => {
  const [itemSelected, setItemSelected] = React.useState<string | null>(null);

  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.admin);

  const onSelect = (name: string, categoryID: number) => {
    setItemSelected(name);
    onSelectLearningExperience(categoryID);
  };

  const getLearningExperiences = React.useCallback(async () => {
    const experiences = await dispatch(LearningExperienceCategoryThunk());
    if (LearningExperienceCategoryThunk.rejected.match(experiences)) {
      toast.error(experiences.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    getLearningExperiences();
  }, [getLearningExperiences]);

  return (
    <ExperiencesListContainer>
      {admin.experienceCategories &&
        admin.experienceCategories.map(
          (experience: ILearningExperienceCategory, experienceIndex: number) => {
            return (
              <ExperiencesContainer key={experienceIndex}>
                <ExperienceWrapper
                  $selected={experience.name === itemSelected && !canceledSelection}
                  onClick={() => onSelect(experience.name, experience.id)}
                >
                  <ExperienceIconWrapper>
                    <img src={experience.icon} alt={`${experience.name}-icon`} />
                  </ExperienceIconWrapper>
                  <ExperienceName>{experience.name}</ExperienceName>
                </ExperienceWrapper>
                {admin.experienceCategories && (
                  <React.Fragment>
                    {experienceIndex + 1 < admin.experienceCategories.length && (
                      <icons.DividerIcon />
                    )}
                  </React.Fragment>
                )}
              </ExperiencesContainer>
            );
          }
        )}
    </ExperiencesListContainer>
  );
};

export default LearningExperiencesList;
