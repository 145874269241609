import Amplify from 'aws-amplify';
import {
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_WEB_CLIENT_ID,
} from 'constants/Environments';

export const AWSInitialSetup = () => {
  Amplify.configure({
    Auth: {
      region: REACT_APP_REGION,
      userPoolId: REACT_APP_USER_POOL_ID,
      userPoolWebClientId: REACT_APP_WEB_CLIENT_ID,
    },
  });
};
