import icons from 'assets/Icon';
import { Col, Container, Row } from 'reactstrap';
import { useState, useCallback } from 'react';
import { themeConfig } from '../../../theme/config';
import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaFormLabel from 'components/atoms/AcredittaFormLabel';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import DinamicBadgeSelect from './components/DinamicBadgeSelect';
import RadioInput from './components/RadioInput';
import useGetBadgesTemplates from './hooks/useGetBadgesTemplates';
import uuid from 'react-uuid';
import { manageErrors } from '../../../helpers/manage-error';
import { badgesSaveCollection } from '../../../services/badges.service';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import { AdminRole } from '../../../constants/NavigationUrls';
import AsyncSelect from 'react-select/async';
import { customStyles, styledTheme } from 'theme/async-select';
import ThumbImage from './components/ThumbImage';
import styles from './styles.module.css';

const RADIO_INPUTS = [
  { id: 1, label: 'En pasos' },
  { id: 2, label: 'Aleatorio' },
];

const BadgesStackableForm = () => {
  const [finalBadge, setFinalBadge] = useState('');
  const [finalBadgeImage, setFinalBadgeImage] = useState('');
  const [badgeStackableType, setBadgeStackableType] = useState('1');
  const [badges, setBadges] = useState<any[]>([]);
  const { data, fetching, filterData } = useGetBadgesTemplates();
  const { push } = useHistory();
  const [childBadgesCount, setChildBadgesCount] = useState(0);
  const [error, setError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(1);

  function containsDuplicates(array: string[]) {
    if (array.length !== new Set(array).size) {
      return true;
    }

    return false;
  }

  const handleChangeBadgeSelects = useCallback(
    (values) => {
      setError(false);
      setError(
        containsDuplicates(
          values
            .filter((i: any) => typeof i.currentSelected !== 'undefined')
            .map((i: any) => i.currentSelected)
        )
      );

      let filterValues = values.filter((item: any) => typeof item.currentSelected !== 'undefined');
      setChildBadgesCount(filterValues.length);
      setBadges(values);
      if (filterValues.length > 0) {
        let newArray = values.map((item: any) => item.currentSelected);
        filterData(newArray);
      }
    },
    [filterData]
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let mainBadge = {
      is_required: true,
      is_main: true,
      badge_template: finalBadge,
      next_badge: null,
    };

    let badgesCollection = [];

    for (let i = 0; i < badges.length; i++) {
      let nextBadge: string | null = null;

      if (badgeStackableType === '1') {
        nextBadge = i + 1 < badges.length ? badges[i + 1].currentSelected : finalBadge;
      } else {
        nextBadge = null;
      }

      let currentBadge = {
        is_required: true,
        is_main: false,
        badge_template: badges[i].currentSelected,
        next_badge: nextBadge,
      };

      badgesCollection.push(currentBadge);
    }

    try {
      const payload = [mainBadge, ...badgesCollection];
      await badgesSaveCollection(payload);
      toast.success('La colección se ha creado correctamente');
      push(AdminRole.badgesPreview);
    } catch (e: any) {
      let errMessage = 'Ocurrió un error al guardar la información';
      if (e.response.data.status_code === 406) {
        errMessage = 'La insignia final ya se encuentra asignada a otra colección';
      }
      manageErrors(e, errMessage, { showToast: true });
    }
  };

  const filterBadges = (inputValue: string) => {
    return data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(filterBadges(inputValue));
      }, 1000);
    });

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {fetching && (
        <div className="text-center mt-4">
          <AcredittaLoader />
        </div>
      )}

      {!fetching && (
        <>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-md-3 d-flex align-items-center justify-content-center">
                <Link to={AdminRole.badgesPreview}>
                  <icons.GoBackIcon />
                </Link>
              </div>
              <div className="col-md-9 ">
                <AcredittaText variant="h3">Insignias apilables</AcredittaText>
              </div>
            </div>
          </div>
          <Container>
            <Row>
              <Col>
                <AcredittaText variant="body2" color="secondary" style={{ lineHeight: '1.5em' }}>
                  Las insignias apilables son reconocimientos individuales que al ser combinados
                  representan un logro mayor a quienes los reciben. Estas insignias son una forma de
                  acumular logros y conocimiento con el cual los acreditados van construyendo su
                  perfil profesional.{' '}
                  <a
                    href={`${process.env.REACT_APP_ACREDITTA_WEB}insignias-apilables-que-son-por-que-son-importantes/`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ fontWeight: 'bold', color: themeConfig.colors.primary.blue }}
                  >
                    Leer más
                  </a>
                </AcredittaText>
              </Col>
            </Row>
            <Row>
              <Col>
                <AcredittaBox>
                  <AcredittaText variant="h4" color="primary">
                    Selecciona la forma en la que se obtendrán las insignias
                  </AcredittaText>
                  <form>
                    <div className="row">
                      <div className="col-xs-12 col-md-2">&nbsp;</div>
                      <div className="col-xs-12 col-md-10">
                        {RADIO_INPUTS.map(({ id, label }) => (
                          <div className={styles.tooltip} onMouseEnter={() => setShowTooltip(id)}>
                            <RadioInput
                              key={uuid()}
                              id={id}
                              label={label}
                              badgeStackableType={badgeStackableType}
                              handleChange={setBadgeStackableType}
                            />
                            <span className={styles.tooltiptext}>
                              {showTooltip === 1 ? (
                                <AcredittaText variant="body3" color="secondary" fontWeight="400">
                                  Apilar tus insignias{' '}
                                  <span style={{ fontWeight: 'bold', color: '#002057' }}>
                                    "En orden"
                                  </span>{' '}
                                  te permite establecer un paso a paso que los acreditados deberán
                                  seguir para obtener la insignia final
                                </AcredittaText>
                              ) : (
                                <AcredittaText variant="body3" color="secondary" fontWeight="400">
                                  Si seleccionas la opción de{' '}
                                  <span style={{ fontWeight: 'bold', color: '#002057' }}>
                                    "aleatorio"
                                  </span>{' '}
                                  los acreditados podrán obtener las insignias sin un orden
                                  establecido, deberán conseguir todas las insignias para ganar la
                                  final
                                </AcredittaText>
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <DinamicBadgeSelect
                      badgeStackableType={badgeStackableType}
                      onFinish={handleChangeBadgeSelects}
                    />

                    <div className="row mb-3">
                      <div className="col-xs-12 col-md-2">
                        <AcredittaFormLabel text="Insignia final" />
                      </div>
                      <div className="col-xs-12 col-md-9">
                        <div className="d-flex">
                          {finalBadgeImage && (
                            <div>
                              <ThumbImage source={finalBadgeImage} />
                            </div>
                          )}
                          <div style={{ flex: 1 }}>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions={data}
                              loadOptions={promiseOptions}
                              styles={customStyles}
                              theme={styledTheme}
                              onChange={({ value, img }) => {
                                setFinalBadgeImage(img);
                                setFinalBadge(value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {error && (
                      <div className="row">
                        <div className="col-6 mx-auto">
                          <div
                            className="mt-2 alert"
                            style={{
                              color: '#721c24',
                              backgroundColor: '#f8d7da',
                              borderColor: '#f5c6cb',
                              textAlign: 'center',
                            }}
                          >
                            Verifica que las insignias no estén repetidas
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row d-flex justify-content-center">
                      <AcredittaButton
                        onPress={() => push(AdminRole.badgesPreview)}
                        title="Cancelar"
                        variant="secondary"
                        type="button"
                      />
                      <AcredittaButton
                        type="submit"
                        onPress={handleSubmit}
                        title="Crear colección"
                        variant="primary"
                        disabled={!finalBadge || childBadgesCount < 2 || error}
                      />
                    </div>
                  </form>
                </AcredittaBox>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </CommonPageLayout>
  );
};

export default BadgesStackableForm;
