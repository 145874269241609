import React from 'react';
import NewOrganizationForm from 'components/molecules/AdminCreateOrganizationProfile/NewOrganizationForm';
import NewOrganizationDetailsForm from 'components/molecules/AdminCreateOrganizationProfile/NewOrganizationDetailsForm';
import { useForm } from 'react-hook-form';
import { objectValidation } from 'utils';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  CreateNewOrganizationThunk,
  AdminSendNewUsersInvitationsThunk,
} from 'actions/Thunks/AdminThunk';
import {
  ColStyled,
  OrganizationProfileContainer,
  OrganizationProfileSectionsContainer,
} from './styles';
import { StepWizardProps } from 'react-step-wizard';
import AdminInviteUsersModal from 'components/molecules/AdminInviteUsersModal';
import { useHistory } from 'react-router-dom';

interface AdminOrganizationProfileProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const AdminOrganizationProfile = ({ onNextStep }: AdminOrganizationProfileProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showInviteUsersModal, setInviteUsersModal] = React.useState(false);

  const admin = useAppSelector((state) => state.user);

  const defaultValuesAdditionalInfo: INewOrganizationInfoForm = {
    name: '',
    domain: '',
    email: '',
    photo: null,
    own_courses: false,
  };

  const getDefaultAdditionalInfoFormValues = (): INewOrganizationInfoForm => {
    return defaultValuesAdditionalInfo;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<INewOrganizationInfoForm>({
    ...objectValidation,
    defaultValues: getDefaultAdditionalInfoFormValues(),
  });

  const submitNewOrganizationForm = async (formInfo: INewOrganizationInfoForm) => {
    if (formInfo.photo) {
      const formInfoRequest: ICreateNewOrganization = {
        ...formInfo,
        photo: formInfo.photo,
      };
      const newOrganization = await dispatch(CreateNewOrganizationThunk(formInfoRequest));

      if (CreateNewOrganizationThunk.fulfilled.match(newOrganization)) {
        toast.success('Se ha creado la organización con éxito');
        setInviteUsersModal(true);
      } else {
        toast.error(newOrganization.payload as string);
      }
    }
  };

  const sendNewUsersInvitations = async (usersEmails: string[]) => {
    if (admin.adminInfo) {
      let invitations = {
        emails: usersEmails,
        organization_id: admin.adminInfo.id,
      };
      const sendInvitations = await dispatch(AdminSendNewUsersInvitationsThunk(invitations));
      if (AdminSendNewUsersInvitationsThunk.rejected.match(sendInvitations)) {
        toast.error(sendInvitations.payload as string);
      } else {
        if (sendInvitations.payload) {
          toast.success(sendInvitations.payload);
          onNextStep();
          history.go(0);
        }
      }
    } else {
      toast.error('Lo sentimos a ocurrido un error intente nuevamente.');
    }
  };

  const handleCloseModal = () => {
    setInviteUsersModal(false);
    onNextStep();
    history.go(0);
  };

  return (
    <OrganizationProfileContainer>
      <OrganizationProfileSectionsContainer>
        <ColStyled xl="7" md="6" xs="12">
          <NewOrganizationForm
            controlInfo={control}
            handleSubmitInfoForm={handleSubmit}
            errorsInfo={errors}
            submitNewOrganizationForm={submitNewOrganizationForm}
            watch={watch}
            loading={false}
            setValue={setValue}
          />
        </ColStyled>
        <ColStyled xl="5" md="6" xs="12">
          <NewOrganizationDetailsForm
            controlBasicInfo={control}
            errorsBasicInfo={errors}
            handleSubmitBasicInfoForm={handleSubmit}
            watch={watch}
          />
        </ColStyled>
      </OrganizationProfileSectionsContainer>
      <AdminInviteUsersModal
        show={showInviteUsersModal}
        handleCloseModal={handleCloseModal}
        handleSendInvitations={sendNewUsersInvitations}
      />
    </OrganizationProfileContainer>
  );
};

export default AdminOrganizationProfile;
