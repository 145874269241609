import { AdminRole } from 'constants/NavigationUrls';
import { manageErrors } from 'helpers/manage-error';
import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPreviewBadgeCollection } from 'services/badges.service';

export interface BadgeStackableContextI {
  mapInSlices: (
    array: any[],
    sliceSize: number,
    sliceFunc: (data: any[], i: number) => void
  ) => void[];
  mainBadge: any;
  collections: any[];
  type: 'step' | 'random';
  ready: boolean;
}

export const BadgesStackableContext = createContext({} as BadgeStackableContextI);

const BadgesStackableProvider = ({ id, children }: any) => {
  const [collections, setCollections] = useState<any[]>([]);
  const [type, setType] = useState<'step' | 'random'>('step');
  const [mainBadge, setMainBadge] = useState({} as any);
  const { push } = useHistory();
  const [ready, setIsReady] = useState(false);

  const mapInSlices = (
    array: any[],
    sliceSize: number,
    sliceFunc: (data: any[], i: number) => void
  ) => {
    const out = [];
    for (var i = 0; i < array.length; i += sliceSize) {
      const slice = array.slice(i, i + sliceSize);
      out.push(sliceFunc(slice, i));
    }
    return out;
  };

  useEffect(() => {
    (async () => {
      try {
        const resp = await getPreviewBadgeCollection(id);
        setMainBadge(resp.data);
        setType(resp.data.collection_type);
        setCollections(resp.data.collections);
        setIsReady(true);
      } catch (e: any) {
        push(AdminRole.badgesPreview);
        manageErrors(e);
      }
    })();
  }, [id, push]);

  return (
    <BadgesStackableContext.Provider
      value={{
        mainBadge,
        collections,
        type,
        ready,
        mapInSlices,
      }}
    >
      {children}
    </BadgesStackableContext.Provider>
  );
};

export default BadgesStackableProvider;
