import icons from 'assets/Icon';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  margin: 39px 0;
  padding: 5px 7px;
  margin: 40px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const MainTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  margin-bottom: 0;

  @media ${device.bigMobile} {
    font-size: 20px;
  }

  @media ${device.mobile} {
    font-size: 16px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`;

export const IconText = styled(Label)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
  font-size: 16px;
  line-height: 20px;
  font-family: NotoSansJPBold;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const IconAddUser = styled(icons.IssueIndividual)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
`;

export const IconAddPeople = styled(icons.IssueMasive)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? SECONDARY_COLOR : PRIMARY_COLOR)};
`;
