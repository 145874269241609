import * as React from 'react';
import AdminHomeCourseCatalog from 'components/organisms/AdminHomeCatalogCourse';

const CatalogCourses: React.FC = () => {
  return (
    <div>
      <AdminHomeCourseCatalog />
    </div>
  );
};

export default CatalogCourses;
