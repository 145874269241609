import React from 'react';
import { CourseCoverIconContainer, NewIndividualCourseContainer } from './styles';
import { StepWizardProps } from 'react-step-wizard';
import AdminUploadNewCourseForm from 'components/molecules/AdminUploadNewCourseForm';
import { objectValidation } from 'utils';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { CreateNewCourseThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch } from 'hooks/redux';
import { ValidURL } from 'utils';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
import AdminUploadImageInfoCourse from 'components/molecules/AdminUploadImageInfoCourse';

interface AdminUploadNewIndividualCourseProps extends Partial<StepWizardProps> {
  handleCourseSubmit: () => void;
  hideNewCourseSection: () => void;
}

const AdminUploadNewIndividualCourseCatalog = ({
  handleCourseSubmit,
  hideNewCourseSection,
}: AdminUploadNewIndividualCourseProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [inputList, setInputList] = React.useState<ICourseContent[]>([{ name: '', duration: 0 }]);
  const [modality, setModality] = React.useState<number | null>(null);
  const [level, setLevel] = React.useState<number | null>(null);

  const submitNewCourseForm = async (courseInfo: IUploadNewCourse) => {
    let skills_selected: number[] = [];
    let url_validated = ValidURL(courseInfo.url);
    courseInfo.skill?.map((skills: any) => {
      return skills_selected.push(skills.value);
    });
    if (url_validated) {
      const newCourseInfo: ICreateNewCourseIndex = {
        path_info: {
          name: courseInfo.name,
          description: courseInfo.description,
          url: url_validated,
          skill_tag: skills_selected,
          language: courseInfo.language ? [courseInfo.language.value] : [],
          is_private: courseInfo.is_private,
          level: level,
          modality: modality,
          cost: parseInt(courseInfo.cost),
          new_content: inputList,
        },
        path_photo: {
          image: courseInfo.image,
        },
      };
      const newCourse = await dispatch(CreateNewCourseThunk(newCourseInfo));

      if (CreateNewCourseThunk.fulfilled.match(newCourse)) {
        toast.success('Se ha creado el curso con éxito');
        history.push(AdminRole.catalogCourse);
      } else {
        toast.error(newCourse.payload as string);
      }
    }
  };

  const defaultValuesNewCourseUpload: IUploadNewCourse = {
    name: '',
    description: '',
    image: null,
    score: null,
    duration: 0,
    is_completed: null,
    cost: null,
    session: null,
    teacher: null,
    start_date: null,
    end_date: null,
    modality: null,
    materiality: null,
    organization: null,
    difficulty: null,
    type_cost: null,
    level: null,
    provider: null,
    skill: [],
    tag: null,
    language: null,
    content: null,
    url: '',
    is_private: false,
    new_content: null,
  };

  const getDefaultFormValues = (): IUploadNewCourse => {
    return defaultValuesNewCourseUpload;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IUploadNewCourse>({
    ...objectValidation,
    defaultValues: getDefaultFormValues(),
  });

  const hideSection = () => {
    history.push(AdminRole.catalogCourse);
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
    >
      <NewIndividualCourseContainer>
        <CourseCoverIconContainer>
          <AdminUploadImageInfoCourse
            watch={watch}
            control={control}
            errors={errors}
            onSelectModality={setModality}
            onSelectLevel={setLevel}
          />
        </CourseCoverIconContainer>
        <AdminUploadNewCourseForm
          controlInfo={control}
          handleSubmitForm={handleSubmit}
          errorsInfo={errors}
          submitNewCourseForm={submitNewCourseForm}
          hideSection={hideSection}
          watch={watch}
          onChangeContent={(value: ICourseContent[]) => setInputList(value)}
        />
      </NewIndividualCourseContainer>
    </CommonPageLayout>
  );
};

export default AdminUploadNewIndividualCourseCatalog;
