import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { UserRoles } from './interfaces/user-roles';
import { MenuContext } from './context/MenuContextProvider';
import { AdminRole } from '../../../constants/NavigationUrls';
import { AcredittaThemeContext } from '../../../context/AcredittaThemeContext';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { CommonMenuItem, PublicProfileMenuItem } from './components/desktop';
import { MAILTO_SUPPORT } from './data';
import './styles.css';

import defaultProfile from '../../../assets/images/default-profile.png';

// TODO: Colocar el avatar del usuario

const AcredittaNavbar = () => {
  const { backgroundHeader } = useContext(AcredittaThemeContext);
  const { userInfo } = useAppSelector((state) => state.user);
  const { role, configMenu, logout } = useContext(MenuContext);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light main-nav"
      style={{ background: backgroundHeader }}
    >
      {/* user logo */}
      <Link className="navbar-brand" to="/">
        <img src={userInfo?.branding.image} className="img-brand" alt="" />
      </Link>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {role === String(UserRoles.ADMIN) && (
            // create badge
            <CommonMenuItem
              element={{ text: 'Crear insignia', path: AdminRole.createBadge, icon: true }}
            />
          )}

          {/* support link */}
          <CommonMenuItem
            element={{
              text: 'Soporte',
              path: MAILTO_SUPPORT,
              external: true,
            }}
          />

          <UncontrolledDropdown nav inNavbar={false}>
            {/* Info user */}
            <div className="profile-dropdown" style={{ background: backgroundHeader }}>
              <img
                src={userInfo?.avatar || defaultProfile}
                alt=""
                className="profile-desktop-image"
              />
              <DropdownToggle nav caret style={{ padding: 0 }}>
                <span style={{ paddingLeft: '35px' }}>{userInfo?.organization.name}</span>
              </DropdownToggle>
            </div>

            <DropdownMenu right>
              {/* user profile link for acreddited */}
              <PublicProfileMenuItem />

              {/* configuration menu user based in role */}
              {configMenu &&
                configMenu.map(({ id, text, path }) => (
                  <DropdownItem key={id}>
                    <Link to={path}>{text}</Link>
                  </DropdownItem>
                ))}

              {/* close session */}
              <DropdownItem onClick={logout} style={{ cursor: 'pointer' }}>
                Cerrar sesión
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
      </div>
    </nav>
  );
};

export default AcredittaNavbar;
