import { AdminRole } from 'constants/NavigationUrls';
import { MenuI } from '../interfaces/menu';

export const ADMIN_MENU: MenuI[] = [
  { id: 1, text: 'Inicio', path: '/' },
  {
    id: 2,
    text: 'Insignias',
    path: '/',
    subItems: [
      {
        id: 1,
        text: 'Insignias creadas',
        path: AdminRole.badgesPreview,
      },
      // TODO: activar cuando este listo el modulo de insignias programadas
      // {
      //   id: 2,
      //   text: 'Insignias otorgadas',
      //   path: '/',
      // },
      {
        id: 3,
        text: 'Crear colección',
        path: AdminRole.badgesStackable,
      },
    ],
  },
  { id: 3, text: 'Reportes', path: AdminRole.analyticsReports },
  {
    id: 4,
    text: 'Rutas',
    path: '/',
    subItems: [
      {
        id: 1,
        text: 'Crear ruta',
        path: AdminRole.newPathWizz,
      },
      {
        id: 2,
        text: 'Rutas creadas',
        path: AdminRole.pathPreview,
      },
      {
        id: 3,
        text: 'Cátalogo de cursos',
        path: AdminRole.catalogCourse,
      },
    ],
  },
  {
    id: 5,
    text: 'Mi cuenta',
    path: '/',
    subItems: [
      {
        id: 1,
        text: 'Insignias obtenidas',
        path: AdminRole.profile,
      },
      {
        id: 2,
        text: 'Información personal',
        path: AdminRole.editPerfil,
      },
    ],
  },
];
