import AdminOnboardingWizard from 'components/organisms/AdminOnboardingWizard';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckSession } from '../../../utils/Routing/AuthUserGuard';
import { AdminDataThunk } from '../../../actions/Thunks/UserThunk';
import { cleanState } from 'states/slices/clean.slice';
import { SignOut } from 'actions/ApiClient/AuthActions';

const OnboardingWizardSteps = (): JSX.Element => {
  const history = useHistory();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const withCompletedProfile = React.useCallback(() => {
    dispatch(cleanState());
    SignOut();
    history.push(AuthNavigations.login);
    // if (user.userInfo && user.userInfo.onboarding.completed_profile) {
    //   history.push(AdminRole.home);
    // }
  }, [history, dispatch]);

  const adminData = React.useCallback(() => {
    if (user.userInfo && user.userInfo.user_type.code === 'ADMIN') {
      CheckSession().then((result) => {
        if (result) {
          dispatch(AdminDataThunk()).then((admin) => {
            if (AdminDataThunk.rejected.match(admin)) {
              console.warn(admin.payload as string);
            }
          });
        }
      });
    }
  }, [dispatch, user.userInfo]);

  React.useEffect(() => {
    withCompletedProfile();
    adminData();
  }, [withCompletedProfile, adminData]);

  return <AdminOnboardingWizard />;
};

export default OnboardingWizardSteps;
