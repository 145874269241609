import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const MasiveUploadCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const InstructionsWrapper = styled.div``;

export const InstructionsTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
`;

export const InstructionsText = styled(Label)`
  font-size: 16px;
  line-height: 23px;
  color: #53565a;
  margin: 10px 0px;
`;

export const CSVLabel = styled(Label)`
  background: #ebebeb;
  border-radius: 15px;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  padding: 6px 10px;
  cursor: pointer;
  margin-bottom: 0px;
`;

export const CSVInstructionsLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  margin-bottom: 0px;
`;

export const CSVIconWrapper = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin-right: 20px;
`;
