import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import {
  NewBadgeFormContainer,
  NewBadgeInstructionsTitle,
  FormGroupStyled,
  FormLabelSimpleWrapper,
  FormInputWrapper,
  FormLabelSimple,
  ButtonsContainer,
  InfoText,
  SwitchContainer,
  SwitchLabel,
  ButtonWrapper,
  RequirementsContainer,
  FormLabelRequirement,
  RequirementContainer,
  FormLabelRequirementWrapper,
  RequirementAddButton,
  RequirementAddButtonTitle,
  RequirementAddButtonWrapper,
  DeleteRequirementButtonContainer,
  RequirementsDescContainer,
  RequirementsWrapper,
  RequirementAddButtonWrapperTitle,
  ErrorLabel,
} from './styles';
import Switch from 'react-switch';
import TextInput from 'components/atoms/TextInput';
import { Control, UseFormHandleSubmit, Controller, UseFormWatch } from 'react-hook-form';
import { urlRegex } from 'utils';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { useAppDispatch } from 'hooks/redux';
import icons from 'assets/Icon';
import toast from 'react-hot-toast';
import { SkillListEleThunk, SkillsTagListThunk } from 'actions/Thunks/OnboardingThunk';
import AcredittaSkillsSelector from 'components/atoms/AcredittaSkillsSelector';
import InputSelectTags from 'components/atoms/InputTags/InputSelectTags';
import ContactMethods from 'components/atoms/ContactMethods/ContactMethods';
import InputSelect from 'components/atoms/InputSelect';
import { GetPathListThunk } from 'actions/Thunks/AdminThunk';
import EstandarInputs from 'components/atoms/EstandarInputs/EstandarInputs';
import { ListTags } from 'actions/ApiClient/OnboardingActions';
import { withHttps } from 'helpers';
import validator from 'validator';
import AcredittaText from 'components/atoms/AcredittaText';

interface AdminUploadNewBadgeFormProps {
  controlInfo: Control<IUploadNewBadgeTemplate>;
  handleSubmitForm: UseFormHandleSubmit<IUploadNewBadgeTemplate>;
  submitNewBadgeForm: (formInfo: IUploadNewBadgeTemplate) => void;
  errorsInfo: Record<string, any>;
  watch: UseFormWatch<IUploadNewBadgeTemplate>;
  onChangeContent: (value: IBadgeRequirement[]) => void;
  onChangeStandardList: (value: any) => void;
  onChangueContactMethod: (value: any) => void;
  badgeCloned: any;
}

const AdminUploadNewBadgeTemplateForm = ({
  controlInfo,
  handleSubmitForm,
  submitNewBadgeForm,
  errorsInfo,
  onChangeContent,
  onChangeStandardList,
  onChangueContactMethod,
  badgeCloned,
}: AdminUploadNewBadgeFormProps): JSX.Element => {
  const [skillsSelected, setSkillsSelected] = useState([]);
  const [tagsSelected, setTagsSelected] = useState([]);

  const [skillsTag, setSkillsTag] = React.useState<any>();
  const [inputList, setInputList] = useState<IBadgeRequirement[]>([{ name: '', description: '' }]);
  const [standardList, setStandardList] = useState<any>([{ name: '', url: '' }]);
  const [optionsValues, setOptionsValues] = useState<any>({ skills: [], tags: [] });
  const dispatch = useAppDispatch();
  const [errRequirements, setErrRequirements] = useState(true);

  useEffect(() => {
    if (badgeCloned !== undefined) {
      setInputList(
        badgeCloned?.badge_template_activities.map((e: any) => ({ name: '', description: e.title }))
      );
      if (badgeCloned.standard.length !== 0) {
        setStandardList(badgeCloned.standard.map((e: any) => ({ name: e.name, url: e.url })));
      }
      if (badgeCloned.skill_tag) {
        setOptionsValues({
          skills: badgeCloned.skill_tag.map((e: any) => ({ label: e.name, value: e.id })),
        });
      }
      if (badgeCloned.badge_template_tag) {
        (async () => {
          const resp = await ListTags();
          const Array = badgeCloned.badge_template_tag.map((e: any) => {
            return resp.find((item: any) => item.id === e);
          });
          const tagsFiltered = Array.map(
            (value: any) =>
              value !== undefined && {
                name: value.name,
                label: value.name,
                color: value.color,
                id: value.id,
              }
          );
          setOptionsValues((content: any) => ({
            ...content,
            tags: tagsFiltered.filter((item: any) => item !== false),
          }));
        })();
      }
    }
    // eslint-disable-next-line
  }, [badgeCloned]);

  const handleInputChange = (e: any, index: number) => {
    const list = [...inputList];
    list[index].description = e.target.value;

    if (validator.isEmpty(e.target.value)) {
      list[index].name = 'El campo es requerido';
    } else {
      list[index].name = '';
    }

    setErrRequirements(list.some((i) => validator.isEmpty(i.description)));

    setInputList(list);
    onChangeContent(list);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    onChangeContent(list);
  };

  const handleRemoveStandard = (index: number) => {
    const list = [...standardList];
    list.splice(index, 1);
    setStandardList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { name: 'El campo es requerido', description: '' }]);
    onChangeContent([...inputList, { name: 'El campo es requerido', description: '' }]);
  };

  const handleAddStandard = () => {
    setStandardList([...standardList, { name: '', url: '' }]);
  };

  const initListSkillTag = React.useCallback(
    async (newValue: string) => {
      const skillsListTag = await dispatch(SkillsTagListThunk(newValue));
      if (SkillListEleThunk.rejected.match(skillsListTag)) {
        toast.error(skillsListTag.payload as string);
      } else {
        setSkillsTag(skillsListTag.payload);
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (!skillsTag) {
      initListSkillTag('');
    }
  }, [initListSkillTag, skillsTag]);

  useEffect(() => {
    onChangeContent(inputList);
    // eslint-disable-next-line
  }, [inputList]);

  const getSkillsInputValues = (values: any) => {
    setSkillsSelected(values);
  };

  const getTagsInputValues = (values: any) => {
    setTagsSelected(values);
  };

  const loadPathList = () => {
    return new Promise((resolve) => {
      dispatch(GetPathListThunk()).then((pathList) => {
        if (GetPathListThunk.fulfilled.match(pathList)) {
          resolve(pathList.payload);
        } else {
          toast.error(pathList.payload as string);
        }
      });
    });
  };

  return (
    <NewBadgeFormContainer>
      <NewBadgeInstructionsTitle>
        Completa la información para cargar una nueva insignia
      </NewBadgeInstructionsTitle>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Nombre*</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="name"
                placeholder="Nombre de la insignia"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="description">Descripción*</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="description"
                placeholder="Describe tu insignia"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                type={'textarea'}
                height={100}
                characterCounter={500}
                withError
              />
            )}
            name="description"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Habilidades</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <AcredittaSkillsSelector
            loadOptionsValues={optionsValues.skills}
            onChangeInputValues={getSkillsInputValues}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <RequirementsDescContainer>
        <RequirementAddButtonWrapper>
          <RequirementAddButtonWrapperTitle>
            <RequirementAddButtonTitle>Requisitos</RequirementAddButtonTitle>
            <FormInputWrapper>
              <InfoText>
                Define los requisitos que se deben cumplir para obtener la insignia
              </InfoText>
            </FormInputWrapper>
          </RequirementAddButtonWrapperTitle>
          {inputList && (
            <RequirementAddButton onClick={handleAddClick}>Añadir +</RequirementAddButton>
          )}
        </RequirementAddButtonWrapper>
      </RequirementsDescContainer>
      <RequirementsWrapper>
        {inputList.map((x, i) => (
          <>
            <RequirementsContainer>
              <RequirementContainer>
                <FormLabelRequirementWrapper>
                  <FormLabelRequirement for="labels">{`Requisito #${i + 1}`}</FormLabelRequirement>
                </FormLabelRequirementWrapper>
                <div style={{ width: '100%', display: 'flex' }}>
                  <input
                    type="text"
                    name="requisito"
                    placeholder={`Requisito ${i + 1}`}
                    value={x.description}
                    onChange={(e) => handleInputChange(e, i)}
                    onBlur={(e) => handleInputChange(e, i)}
                    maxLength={400}
                    className={`form-control ${inputList[i].name && 'is-invalid'}`}
                    style={{ height: '40px' }}
                  />
                  {inputList.length !== 1 && (
                    <DeleteRequirementButtonContainer onClick={() => handleRemoveClick(i)}>
                      <icons.DeleteBlueIcon />
                    </DeleteRequirementButtonContainer>
                  )}
                </div>
              </RequirementContainer>
            </RequirementsContainer>
            <div style={{ marginLeft: '220px' }}>
              <AcredittaText
                variant="body1"
                color="red"
                style={{ fontSize: '12px', lineHeight: '1em' }}
              >
                {inputList[i].name && inputList[i].name}
              </AcredittaText>
            </div>
          </>
        ))}
      </RequirementsWrapper>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">URL Información adicional*</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="url"
                placeholder="URL"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="additional_info"
            rules={{
              required: true,
              pattern: { value: urlRegex, message: 'Debes ingresar una página web valida' },
            }}
          />
          {errorsInfo.additional_info && (
            <ErrorLabel>Debes ingresar una página web valida</ErrorLabel>
          )}
        </FormInputWrapper>
      </FormGroupStyled>
      <RequirementsDescContainer>
        <RequirementAddButtonWrapper>
          <RequirementAddButtonWrapperTitle>
            <RequirementAddButtonTitle>Estándar</RequirementAddButtonTitle>
            <FormInputWrapper>
              <InfoText>Indica que norma o estándar cumple esta insignia</InfoText>
            </FormInputWrapper>
          </RequirementAddButtonWrapperTitle>
          {inputList && (
            <RequirementAddButton onClick={handleAddStandard}>Añadir +</RequirementAddButton>
          )}
        </RequirementAddButtonWrapper>
      </RequirementsDescContainer>
      <RequirementsWrapper>
        {standardList.map((x: any, i: number) => {
          return (
            <RequirementsContainer>
              <RequirementContainer>
                <FormLabelRequirementWrapper>
                  <FormLabelRequirement for="labels">{`Estándar #${i + 1}`}</FormLabelRequirement>
                </FormLabelRequirementWrapper>
                <div style={{ width: '100%', display: 'flex' }}>
                  <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1em' }}
                  >
                    <EstandarInputs
                      onChangeStandardList={onChangeStandardList}
                      standardList={standardList}
                      setStandardList={setStandardList}
                      i={i}
                    />
                  </div>

                  {standardList.length !== 1 && (
                    <DeleteRequirementButtonContainer onClick={() => handleRemoveStandard(i)}>
                      <icons.DeleteBlueIcon />
                    </DeleteRequirementButtonContainer>
                  )}
                </div>
              </RequirementContainer>
            </RequirementsContainer>
          );
        })}
      </RequirementsWrapper>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Etiquetas</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InputSelectTags
            loadOptions={optionsValues.tags}
            onChangeInputValues={getTagsInputValues}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Visibilidad</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InfoText>
            Las insignias marcadas como públicas se mostrarán en el catálogo de insignias de la
            plataforma.
          </InfoText>
          <SwitchContainer>
            <SwitchLabel> Privada </SwitchLabel>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <Switch
                  onChange={onChange}
                  checked={value ? true : false}
                  onColor={PRIMARY_COLOR}
                  uncheckedIcon={false}
                  height={20}
                  width={38}
                />
              )}
              name="is_public"
            />
            <SwitchLabel> Pública </SwitchLabel>
          </SwitchContainer>
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">¿Cómo obtener?</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InfoText>
            ¿Cómo quieres que se contacten los usuarios para obtener esta insignia?.
          </InfoText>
          <ContactMethods
            defectValue={badgeCloned !== undefined && badgeCloned.contact_method}
            errorsInfo={errorsInfo}
            onChangueContactMethod={onChangueContactMethod}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Vincular ruta</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <InputSelect
                name="path_select"
                placeholder="Vincula una ruta"
                onChange={(value) => onChange(value)}
                loadOptions={loadPathList}
                asyncSelect={true}
                error={errorsInfo}
                height={38}
                value={value}
                defaultOptions
              />
            )}
            name="path_select"
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            label={'Guardar borrador'}
            lightMode={true}
            widthCustom={230}
            onClick={handleSubmitForm((template_form) => {
              submitNewBadgeForm({
                ...template_form,
                skill: skillsSelected.map((item: any) => item.value),
                tags: tagsSelected.map((tag: any) => tag.id),
                status: 'draft',
              });
            })}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            disabled={errRequirements || Object.keys(errorsInfo).length > 0}
            label={'Publicar insignia'}
            widthCustom={230}
            onClick={handleSubmitForm((template_form) => {
              submitNewBadgeForm({
                ...template_form,
                additional_info: withHttps(
                  template_form.additional_info ? template_form.additional_info : ''
                ),
                skill: skillsSelected.map((item: any) => item.value),
                tags: tagsSelected.map((tag: any) => tag.id),
                status: 'active',
              });
            })}
          />
        </ButtonWrapper>
      </ButtonsContainer>
    </NewBadgeFormContainer>
  );
};

export default AdminUploadNewBadgeTemplateForm;
