import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AuthenticatedThunk } from 'actions/Thunks/AuthThunk';
import toast from 'react-hot-toast';

interface CustomRouteProps extends RouteProps {
  requiredRoles: number[];
  userRole: number;
}

/**
 * Private route checks authentication and roles validity
 * @param Component react component
 * @param requiredRoles roles allowed for this route
 * @param userRole current user role
 */
export const PrivateRoute = ({
  component: Component,
  requiredRoles,
  userRole,
  ...rest
}: CustomRouteProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.auth);
  const userHasRequiredRole = requiredRoles.includes(userRole);

  const checkAuth = React.useCallback(async () => {
    const auth = await dispatch(AuthenticatedThunk());

    if (AuthenticatedThunk.rejected.match(auth)) {
      toast.error('Error');
    }
  }, [dispatch]);

  React.useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (!Component) {
    return null;
  }

  if (authentication.isAuthenticated && userHasRequiredRole) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Redirect to={userHasRequiredRole ? AuthNavigations.login : AuthNavigations.unauthorized} />
    );
  }
};

// Public route redirect for non authenticated pages
export const PublicRoute = ({ component: Component, ...rest }: RouteProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.auth);

  const checkAuth = React.useCallback(async () => {
    const auth = await dispatch(AuthenticatedThunk());

    if (AuthenticatedThunk.rejected.match(auth)) {
      toast.error('Error');
    }
  }, [dispatch]);

  React.useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (!Component) {
    return null;
  }

  if (authentication.isAuthenticated) {
    return <Redirect to={'/'} />;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};
