import icons from 'assets/Icon';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import AcredittaText from '../AcredittaText';

const StyledDiv = styled.div`
  cursor: pointer;
  display: flex;
  padding: 1%;
  justify-content: center;
`;

const ShowProfileLink = () => {
  const { adminInfo } = useSelector((state: any) => state.user);
  const nickname = adminInfo ? adminInfo.nickname : null;
  const publicUrl = `${ENVIRONMENT_URL_BADGE}/public/organizacion/${nickname}`;

  if (!nickname) {
    return <></>;
  }

  return (
    <StyledDiv>
      <a href={publicUrl} target="_blank" rel="noreferrer">
        <icons.Icon_eye_open_blue
          style={{ marginLeft: '15px', width: '17px', marginBottom: '5px' }}
        />
        <AcredittaText variant="body1" style={{ lineHeight: 0 }} fontWeight="600">
          Ver Perfil
        </AcredittaText>
      </a>
    </StyledDiv>
  );
};

export default ShowProfileLink;
