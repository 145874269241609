import * as React from 'react';
import { Label } from 'reactstrap';
import {
  ModalContainer,
  ModalHeaderStyled,
  ModalBodyStyled,
  ModalFooterStyled,
  ButtonWrapper,
  ErrosContainer,
  ErrorLabel,
} from './styles';
import Button from 'components/atoms/Button';
import TextInput from 'components/atoms/TextInput';
import { emailRegex } from 'utils';
import icons from 'assets/Icon';
import { useMediaQuery } from 'react-responsive';
import { device } from 'constants/responsiveDevice';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { objectValidation } from 'utils';

interface AdminInviteUsersModalProps {
  show: boolean;
  handleCloseModal: () => void;
  handleSendInvitations: (val: string[]) => void;
}

const AdminInviteUsersModal = ({
  show,
  handleCloseModal,
  handleSendInvitations,
}: AdminInviteUsersModalProps): JSX.Element => {
  const [invalidEmails, setInvalidEmails] = React.useState<string[]>([]);

  const smallMobileResponsive = useMediaQuery({ query: device.mobile });

  const defaultValues: INewUsersInvitationsForm = {
    newEmail: '',
  };

  const getFormDefaultValues = (): INewUsersInvitationsForm => {
    return defaultValues;
  };

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<INewUsersInvitationsForm>({
    ...objectValidation,
    defaultValues: getFormDefaultValues(),
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addEmail();
    }
  };

  const addEmail = () => {
    let helper: string[] = [];
    let errorsHelper: string[] = [];
    let newEmails = watch('newEmail').split(',');
    newEmails.map((email) => {
      if (email.trim().match(emailRegex)) {
        return (helper = [...helper, email.trim()]);
      } else {
        return (errorsHelper = [...errorsHelper, `${email} no es un email válido`]);
      }
    });
    setInvalidEmails(errorsHelper);

    if (errorsHelper.length === 0) {
      handleSendInvitations(newEmails);
    }
  };

  return (
    <div>
      <ModalContainer
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={handleCloseModal}
        centered={true}
      >
        <ModalHeaderStyled toggle={handleCloseModal}>¡Invita a un usuario!</ModalHeaderStyled>
        <ModalBodyStyled>
          <Label>
            Ingresa el correo electronico de los usuarios que deseas invitar para que conozcan
            nuestra plataforma.
          </Label>
          <Controller
            control={control}
            name="newEmail"
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errors}
                name="newEmail"
                label="Correo"
                height={41}
                onKeyDown={handleKeyDown}
                searchIcon={<icons.SendRedIcon onClick={addEmail} />}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{ required: 'Campo requerido' }}
          />
          <ErrosContainer>
            {invalidEmails &&
              invalidEmails.map((errorEmail, errorEmailIndex) => {
                return <ErrorLabel key={errorEmailIndex}>{errorEmail}</ErrorLabel>;
              })}
          </ErrosContainer>
        </ModalBodyStyled>
        <ModalFooterStyled>
          <ButtonWrapper>
            <Button
              label="Cancelar"
              lightMode={true}
              onClick={handleCloseModal}
              widthCustom={smallMobileResponsive ? 100 : 150}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              label="Invitar"
              color="secondary"
              onClick={addEmail}
              widthCustom={smallMobileResponsive ? 100 : 150}
            />
          </ButtonWrapper>
        </ModalFooterStyled>
      </ModalContainer>
    </div>
  );
};

export default AdminInviteUsersModal;
