import icons from 'assets/Icon';
import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaFormLabel from 'components/atoms/AcredittaFormLabel';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AcredittaText from 'components/atoms/AcredittaText';
import { ErrorMessage, Field, Formik } from 'formik';
import { manageErrors } from 'helpers/manage-error';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useState } from 'react';
import {
  getOrganizationData,
  updateOrganizationData,
} from 'actions/ApiClient/organization-actions';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useAppDispatch } from '../../../../hooks/redux';
import { updateAdminProfileIsCompleted } from 'states/slices/user.slice';
import { InitialValues } from 'interfaces/organization-profile';
import { validationSchemaProfileEdit } from 'validations/organzation-profile';
import UploadOrganizationPhoto from './UploadOrganizationPhoto';
import { excludePhoneCodeEuropeContries } from 'constants/exclude-eu-countries';
import { isUuid } from 'helpers/is-uuid';
import { withHttps } from 'helpers/with-https';
import ShowProfileLink from 'components/atoms/show-profile-link/ShowProfileLink';

const ProfileFormOrganism = () => {
  const { userInfo } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const dispatch = useAppDispatch();
  const [imageError, setImageError] = useState('');

  const [initialValues, setInitialValues] = useState({
    url_linkedin: '',
    url_twitter: '',
    url_facebook: '',
    url_instagram: '',
    name: '',
    description: '',
    country: '',
    city: '',
    address: '',
    code: '',
    phone: '',
    contactEmail: '',
    website: 'http://',
    acredittaProfile: '',
    nickname: '',
    linkedinId: '',
  });
  const { push } = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const resp: any = await getOrganizationData(userInfo?.organization.id);
        let phoneCode = resp.data.phone_code || '';
        let phoneNumber = resp.data.phone_number || '';
        setPhoto(resp.data.photo);
        setName(resp.data.name || '');
        setInitialValues({
          name: resp.data.name,
          url_linkedin: resp.data.url_linkedin || '',
          url_twitter: resp.data.url_twitter || '',
          url_facebook: resp.data.url_facebook || '',
          url_instagram: resp.data.url_instagram || '',
          description: resp.data.description || '',
          country: '',
          city: '',
          address: resp.data.address || '',
          code: phoneCode || null,
          phone: phoneNumber,
          contactEmail: resp.data.contact_email || '',
          website: resp.data.domain !== 'https://example.org' ? resp.data.domain : '',
          acredittaProfile: resp.data.url_acreditta || '',
          nickname: isUuid(resp.data.nickname) ? '' : resp.data.nickname,
          linkedinId: resp.data.linkedin_id,
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
        manageErrors(e);
      }
    })();
  }, [userInfo]);

  const handleUpdateOrganizationData = async (values: InitialValues) => {
    try {
      setImageError('');
      setIsSaving(true);
      const payload = {
        url_linkedin: values.url_linkedin,
        url_twitter: values.url_twitter,
        url_facebook: values.url_facebook,
        url_instagram: values.url_instagram,
        description: values.description,
        domain: withHttps(values.website),
        name: values.name,
        phone_code: values.code,
        phone_number: values.phone,
        address: values.address,
        contact_email: values.contactEmail,
        nickname: values.nickname,
        linkedin_id: values.linkedinId,
      };
      const resp = await updateOrganizationData(userInfo?.organization.id, payload);
      setIsSaving(false);
      dispatch(
        updateAdminProfileIsCompleted({ isCompleted: resp.data.is_completed, name: values.name })
      );
      toast.success('Perfil de organización actualizado correctamente');
      push(AdminRole.organizationBadges);
    } catch (e: any) {
      setIsSaving(false);
      setImageError(e.response?.data?.message);
      manageErrors(e, e.response?.data?.message || '');
    }
  };

  if (loading) {
    return (
      <div className="mt-5">
        <AcredittaLoader />
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        handleUpdateOrganizationData(values);
      }}
      validationSchema={validationSchemaProfileEdit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <div className="row mt-5">
            <div className="col-4">
              <AcredittaText variant="h2" align="center">
                {name}
              </AcredittaText>

              <UploadOrganizationPhoto
                name={initialValues.name}
                photo={photo}
                errorText={imageError}
                onFinish={() => setImageError('')}
              />

              <div className="mt-4">
                <AcredittaText variant="h4">Redes sociales</AcredittaText>
                <div className="row mb-3">
                  <div className="col-2 mt-2 text-right">
                    <icons.IconoLinkedin2 />
                  </div>
                  <div className="col-10">
                    <Field
                      className="form-control"
                      name="url_linkedin"
                      value={values.url_linkedin}
                      onChange={(e: any) => {
                        setFieldValue('url_linkedin', e.target.value.replace(/\s/g, ''));
                      }}
                      onBlur={handleBlur}
                      placeholder="URL Linkedin"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 mt-2 text-right">
                    <icons.IconoTwitter2 />
                  </div>
                  <div className="col-10">
                    <Field
                      className="form-control"
                      name="url_twitter"
                      value={values.url_twitter}
                      onChange={(e: any) => {
                        setFieldValue('url_twitter', e.target.value.replace(/\s/g, ''));
                      }}
                      onBlur={handleBlur}
                      placeholder="URL Twitter"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 mt-2 text-right">
                    <icons.IconoFacebook2 />
                  </div>
                  <div className="col-10">
                    <Field
                      className="form-control"
                      name="url_facebook"
                      value={values.url_facebook}
                      onChange={(e: any) => {
                        setFieldValue('url_facebook', e.target.value.replace(/\s/g, ''));
                      }}
                      onBlur={handleBlur}
                      placeholder="URL Facebook"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2 mt-2 text-right">
                    <icons.IconoInstagram2 />
                  </div>
                  <div className="col-10">
                    <Field
                      className="form-control"
                      name="url_instagram"
                      value={values.url_instagram}
                      onChange={(e: any) => {
                        setFieldValue('url_instagram', e.target.value.replace(/\s/g, ''));
                      }}
                      onBlur={handleBlur}
                      placeholder="URL Instagram"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8">
              <AcredittaBox>
                <div className="row mb-3">
                  <div className="col-xs-12 col-md-6">
                    <AcredittaText variant="h4">Datos de la organización</AcredittaText>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <ShowProfileLink />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Organización *" />
                  </div>
                  <div className="col-9">
                    <Field
                      name="name"
                      placeholder="Nombre"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage component="span" name="name" className="custom-text-error" />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Descripción *" />
                  </div>
                  <div className="col-9">
                    <Field
                      name="description"
                      as="textarea"
                      placeholder="Descripción"
                      style={{ height: '80px', resize: 'none' }}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`form-control ${
                        errors.description && touched.description ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="span"
                      name="description"
                      className="custom-text-error"
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Dirección" />
                  </div>
                  <div className="col-9">
                    <Field
                      className="form-control"
                      name="address"
                      maxLength={80}
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Dirección"
                    />
                    <AcredittaText variant="body3" color="secondary" align="right">
                      <span style={{ color: 80 - values.address.length <= 10 ? 'red' : '' }}>
                        {80 - values.address.length}
                      </span>{' '}
                      / 80
                    </AcredittaText>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Teléfono" />
                  </div>
                  <div className="col-9">
                    <PhoneInput
                      regions={['north-america', 'south-america', 'central-america', 'eu-union']}
                      excludeCountries={excludePhoneCodeEuropeContries}
                      country="co"
                      inputProps={{
                        name: 'phone',
                        required: false,
                        autoFocus: false,
                      }}
                      inputClass="form-control"
                      inputStyle={{ width: '100%' }}
                      value={values.phone}
                      onChange={(phoneNumber: string, country: CountryData) => {
                        setFieldValue('phone', phoneNumber);
                        setFieldValue('code', country.dialCode);
                      }}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Correo" />
                  </div>
                  <div className="col-9">
                    <Field
                      className={`form-control ${
                        errors.contactEmail && touched.contactEmail ? 'is-invalid' : ''
                      }`}
                      name="contactEmail"
                      value={values.contactEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Correo"
                    />
                    <ErrorMessage
                      component="span"
                      name="contactEmail"
                      className="custom-text-error"
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Página web*" />
                  </div>
                  <div className="col-9">
                    <Field
                      className={`form-control ${
                        errors.website && touched.website ? 'is-invalid' : ''
                      }`}
                      name="website"
                      placeholder="Página web"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage component="span" name="website" className="custom-text-error" />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Nombre de usuario*" />
                  </div>
                  <div className="col-9">
                    {/* <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          id="basic-addon3"
                          style={{ border: '1px solid #53565a' }}
                        >
                          www.acreditta.com/org/
                        </span>
                      </div> */}
                    <input
                      type="text"
                      className={`form-control ${
                        errors.nickname && touched.nickname ? 'is-invalid' : ''
                      }`}
                      name="nickname"
                      placeholder="Nombre de usuario"
                      value={values.nickname}
                      onChange={(e: any) => setFieldValue('nickname', e.target.value.trim())}
                      onBlur={handleBlur}
                    />
                    {/* </div> */}
                    <ErrorMessage component="span" name="nickname" className="custom-text-error" />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-3">
                    <AcredittaFormLabel text="Linkedin Id" />
                  </div>
                  <div className="col-9">
                    <Field
                      className="form-control"
                      name="linkedinId"
                      placeholder="Linkedin id"
                      value={values.linkedinId}
                      onChange={(e: any) => setFieldValue('linkedinId', e.target.value.trim())}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <AcredittaText
                    variant="body1"
                    color="secondary"
                    align="center"
                    style={{ fontSize: '11px' }}
                  >
                    Los campos marcados con * son obligatorios
                  </AcredittaText>
                </div>

                <div className="mb-3 text-center">
                  <AcredittaButton
                    variant="secondary"
                    title="Cancelar"
                    type="button"
                    onPress={() => push(AdminRole.organizationBadges)}
                  />
                  <AcredittaButton
                    variant="primary"
                    title="Guardar"
                    type="submit"
                    isLoading={isSaving}
                    disabled={isSaving}
                  />
                </div>
              </AcredittaBox>
            </div>
            <div style={{ height: '200px' }}>&nbsp;</div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default ProfileFormOrganism;
