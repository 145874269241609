import * as React from 'react';
import { RouteSectionContainer } from './styles';
import CourseItemsListWrapper from 'components/atoms/CourseItemsListWrapper';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { LearningPathListViewThunk, LearningPathExperiencesThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import CourseExperiencesWrapper from 'components/atoms/CourseExperiencesWrapper';

interface AdminLearningPathProps {
  handleNewPathOrder: (path: ILearningPathListView[]) => void;
}

const AdminLearningPath = ({ handleNewPathOrder }: AdminLearningPathProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const admin = useAppSelector((state) => state.admin);
  const courseSelector = useAppSelector((state) => state.courses);

  const getLearningPath = React.useCallback(async () => {
    if (courseSelector.pathwayCreated) {
      const path = await dispatch(LearningPathListViewThunk(courseSelector.pathwayCreated.id));
      if (LearningPathListViewThunk.rejected.match(path)) {
        toast.error(path.payload as string);
      }
    }
  }, [courseSelector.pathwayCreated, dispatch]);

  const getExperiencesPath = React.useCallback(async () => {
    if (courseSelector.pathwayCreated) {
      const experiences = await dispatch(
        LearningPathExperiencesThunk(courseSelector.pathwayCreated.id)
      );
      if (LearningPathExperiencesThunk.rejected.match(experiences)) {
        toast.error(experiences.payload as string);
      }
    }
  }, [courseSelector.pathwayCreated, dispatch]);

  const handleNewPath = (path: ILearningPathCourseElement[], path_id: number) => {
    let helper: ILearningPathListView[] = [];
    if (admin.learningPathListView) {
      admin.learningPathListView.map((item) => {
        if (item.id === path_id) {
          item = { ...item, path_element: path };
          return (helper = [...helper, item]);
        } else {
          return (helper = [...helper, item]);
        }
      });
      handleNewPathOrder(helper);
    }
  };

  React.useEffect(() => {
    getLearningPath();
    getExperiencesPath();
  }, [getLearningPath, getExperiencesPath]);

  return (
    <div>
      <div>
        {admin.learningPathListView &&
          admin.learningPathListView.map((path: ILearningPathListView, indexCourse: number) => {
            return (
              <div>
                {path.path_element.length > 0 && (
                  <RouteSectionContainer key={indexCourse}>
                    <CourseItemsListWrapper pathSections={path} handleNewOrder={handleNewPath} />
                  </RouteSectionContainer>
                )}
              </div>
            );
          })}
      </div>
      <div>
        {admin.experiencesPathListView && (
          <CourseExperiencesWrapper experiences={admin.experiencesPathListView} />
        )}
      </div>
    </div>
  );
};

export default AdminLearningPath;
