import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const BadgesInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 40px;
  margin: 40px 0px;
`;

export const ColStyled = styled(Col)`
  margin-bottom: 20px;
`;

export const RowStyled = styled(Row)`
  margin: 40px 2px 0px;
`;

export const SearchIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 18px;
  svg {
    width: 15px;
  }
`;

export const ContainerNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BarSearchContainer = styled(Col)`
  display: grid;
  grid-template-columns: 2% 98%;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  background-color: ${PRIMARY_COLOR};
  margin-bottom: 20px;

  @media ${device.laptops} {
    grid-template-columns: 4% 96%;
  }
  @media ${device.bigMobile} {
    grid-template-columns: 6% 94%;
  }
  @media ${device.smallMobile} {
    grid-template-columns: 8% 92%;
  }
`;

export const IconFilterWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
`;
