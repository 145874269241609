import * as React from 'react';
import {
  RouteContainer,
  RouteHeader,
  RouteTitle,
  RouteProgressInfoContainer,
  RouteDate,
  ProfileInfoImage,
  RouteInfoContainer,
  RouteInfo,
  TitleContainer,
  RouteInfoTItle,
} from './styles';
import icons from 'assets/Icon';

interface RouteProps {
  name: string;
  image: string;
  date: string;
  courses: number;
  skills: number;
  start_date: string;
  badge_count: number;
  organization_name: string;
  duration: string;
}

const Route: React.FC<RouteProps> = ({
  name,
  image,
  date,
  badge_count,
  courses,
  skills,
  start_date,
  organization_name,
  duration,
}) => {
  return (
    <RouteContainer>
      <RouteHeader>
        {start_date !== null ? (
          <RouteDate>
            Fecha Inicio: <strong>{start_date}</strong>
          </RouteDate>
        ) : (
          <RouteDate>
            Fecha Inicio: <strong>No Disponible</strong>
          </RouteDate>
        )}
      </RouteHeader>
      <RouteProgressInfoContainer>
        {image && <ProfileInfoImage src={image} alt="profile" />}
      </RouteProgressInfoContainer>
      <RouteTitle>{name}</RouteTitle>
      {/* Espacio para calficacion */}
      <br />
      <RouteInfoContainer>
        <div>
          <RouteInfo>
            <icons.CoursesIcon />
            {courses ? <span>{courses} Cursos</span> : <span>No Disponible</span>}
          </RouteInfo>
          <RouteInfo>
            <icons.Icono_Badge />
            {badge_count ? <span>{badge_count} Insignias</span> : <span>No Disponible</span>}
          </RouteInfo>
          <RouteInfo>
            <icons.DurationLogo />
            {duration ? <span>Duración: {duration}</span> : <span>No Disponible</span>}
          </RouteInfo>
          <RouteInfo>
            <icons.SkillsIcon />
            {skills ? <span>{skills} Habilidades</span> : <span>No Disponible</span>}
          </RouteInfo>
        </div>
      </RouteInfoContainer>
      <TitleContainer>
        <RouteInfoTItle>
          {organization_name ? <span>{organization_name}</span> : <span>No Disponible</span>}
        </RouteInfoTItle>
      </TitleContainer>
    </RouteContainer>
  );
};

export default Route;
