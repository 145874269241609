import { BLUE_LIGHT, PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Card, CardImg, Label, Row } from 'reactstrap';
import styled from 'styled-components';

export const CreateUserCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.25);
  border-radius: 8px;
`;

export const CreateUserWrapper = styled.div`
  width: 35%;
  display: flex;
  align-self: center;
  margin: 19px;

  @media ${device.tablet} {
    width: 40%;
  }

  @media ${device.mobile} {
    width: 60%;
  }

  @media ${device.smallMobile} {
    width: 70%;
  } ;
`;

export const CreateUserCardImage = styled(CardImg)`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const LabelText = styled(Label)`
  color: ${BLUE_LIGHT};
  line-height: 16px;
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: flex-end;
  margin-right: 11px;
  width: 20%;

  @media ${device.ipad} {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const InputsLabel = styled(Label)`
  font-family: NotoSansJPBold;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin: 20px 0;

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const InputWrapperMessageProfile = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin: 20px 0;

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const CheckboxWrapper = styled(Row)`
  margin: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.smallMobile} {
    flex-direction: column;
  }
`;

export const ErrorMessageLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;

export const TitleWarningMessage = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold;
`;

export const AlternativeWarningMessage = styled(Label)`
  font-size: 12px;
`;

export const ButtonWrapperChild = styled.div`
  margin-left: 10px;

  @media ${device.smallMobile} {
    margin-bottom: 10px;
  }
`;

export const MessageInfoProfileWrapperChild = styled.div`
  width: 83%;

  @media ${device.ipad} {
    width: 100%;
  }
`;
