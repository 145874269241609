import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  WelcomePageTitle,
  WelcomePageDesc,
  WelcomePageButtonWrapper,
  WelcomePageContainer,
} from './styles';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';
import { useHistory } from 'react-router-dom';
import { AuthNavigations } from 'constants/NavigationUrls';

const Welcome = (): JSX.Element => {
  const history = useHistory();

  const goToLogin = () => {
    history.push(AuthNavigations.login);
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={false}
    >
      <WelcomePageContainer>
        <WelcomePageTitle>Revisa tu correo</WelcomePageTitle>
        <WelcomePageDesc>
          Te hemos enviado un correo con una contraseña temporal para que puedas iniciar sesión
        </WelcomePageDesc>
        <WelcomePageButtonWrapper>
          <Button label="Iniciar Sesión" widthCustom={477} onClick={goToLogin} />
        </WelcomePageButtonWrapper>
        <icons.WelcomePageIlustration />
      </WelcomePageContainer>
    </CommonPageLayout>
  );
};

export default Welcome;
