export const themeConfig = {
  colors: {
    primary: {
      white: "#FFFFFF",
      red: "#DA291C",
      blue: "#002057",
      grey: "#53565A"
    },
    secondary: {
      green: "#02B148",
      blue: "#003DA6",
      red: "#FF3021",
      yellow: "#F9B233"
    },
    neutrals:{
      lightgrey: "#EBEBEB",
      greybg: "#F5F5F5",
      darkgrey: "#DADADA"
    }
  },
};
