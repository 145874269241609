import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AgreeTermsAndConditions,
  CodeVerify,
  GetNumberEmployee,
  GetSector,
  InviteByUser,
  RegisterAction,
} from 'actions/ApiClient/AuthActions';
import { GetErrorMessage } from 'utils';
import { CheckSession } from '../../utils/Routing/AuthUserGuard';

export const AgreeTermsConditionsThunk = createAsyncThunk(
  'auth/terms',
  async (_, { rejectWithValue }) => {
    try {
      const terms = await AgreeTermsAndConditions({ terms_conditions: true });
      return terms;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const AuthenticatedThunk = createAsyncThunk('auth/user', async (_, { rejectWithValue }) => {
  try {
    const session = await CheckSession();
    return session;
  } catch (error) {
    return rejectWithValue(GetErrorMessage(error));
  }
});

export const InviteByUserThunk = createAsyncThunk(
  'public/inviteByUser',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const invite = await InviteByUser(uuid);
      return invite;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const RegisterThunk = createAsyncThunk(
  'account/createNewUser',
  async (user: SendSingUpForm, { rejectWithValue }) => {
    try {
      const registerUser = await RegisterAction(user);
      return registerUser;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const CodeVerifyThunk = createAsyncThunk(
  'account/validate-code-singUp',
  async (user: SingUpValidate, { rejectWithValue }) => {
    try {
      const registerUser = await CodeVerify(user);
      return registerUser;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetNumberEmployeeThunk = createAsyncThunk(
  'list/employee',
  async (_, { rejectWithValue }) => {
    try {
      const List = await GetNumberEmployee();
      let constructLanguages: ISelectOptionValue[] = [];

      List.map((languagesMap: any) => {
        return constructLanguages.push({
          value: languagesMap.id,
          label: languagesMap.name,
        });
      });

      return constructLanguages;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetSectorThunk = createAsyncThunk(
  'organization/sectors',
  async (_, { rejectWithValue }) => {
    try {
      const List = await GetSector(); //TODO: this function works in a generic way and should be generic named as GetListElements or something
      let constructSectors: ISelectOptionValue[] = [];

      List.map((sectorsMap: any) => {
        return constructSectors.push({
          value: sectorsMap.id,
          label: sectorsMap.name,
        });
      });

      return constructSectors;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
