import { SignOut } from 'actions/ApiClient/AuthActions';
import StyledButton from 'components/atoms/Button';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useAppDispatch } from 'hooks/redux';
import * as React from 'react';
import { useHistory } from 'react-router';
import { cleanState } from 'states/slices/clean.slice';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';

const Home: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const logout = async () => {
    await SignOut();
    await dispatch(cleanState());
    history.push(AuthNavigations.login);
  };

  return (
    <CommonPageLayout showHeader={true} showMenuHeader={true}>
      <div>
        <h1>Home Administrador</h1>
        <StyledButton label="Cerrar Sesión" onClick={logout} />
      </div>
    </CommonPageLayout>
  );
};

export default Home;
