import { createSlice } from '@reduxjs/toolkit';
import {
  DeletePathThunk,
  DetailBadgesThunk,
  ListBadgesOrganizationThunk,
  ListBadgesTemplateThunk,
  ListBadgesThunk,
  ListPathThunk,
} from 'actions/Thunks/AdminPathsThunk';
import { InterestListProfileThunk } from 'actions/Thunks/OnboardingThunk';

const PATHS = 'PATHS';

const initialState: IPathsLists = {
  listPaths: null,
  deletePath: null,
  listBadges: null,
  listBadgesOrg: null,
  listBadgesTemp: null,
  detailBadges: null,
  interestsProfile: null,
};

const listPathsSlice = createSlice({
  name: PATHS,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ListPathThunk.fulfilled, (state, { payload }) => {
      state.listPaths = payload;
    });
    builder.addCase(DeletePathThunk.fulfilled, (state, { payload }) => {
      state.deletePath = payload;
    });
    builder.addCase(ListBadgesThunk.fulfilled, (state, { payload }) => {
      state.listBadges = payload;
    });
    builder.addCase(ListBadgesOrganizationThunk.fulfilled, (state, { payload }) => {
      state.listBadgesOrg = payload;
    });
    builder.addCase(DetailBadgesThunk.fulfilled, (state, { payload }) => {
      state.detailBadges = payload;
    });
    builder.addCase(InterestListProfileThunk.fulfilled, (state, { payload }) => {
      state.interestsProfile = payload;
    });
    builder.addCase(ListBadgesTemplateThunk.fulfilled, (state, { payload }) => {
      state.listBadgesTemp = payload;
    });
  },
});

export default listPathsSlice.reducer;
