import DownloadTemplateMassiveUsers from 'components/atoms/DownloadTemplateMassiveUsers';
import UploadFile from 'components/atoms/UploadFile';
import { S3_ADMIN } from 'constants/index';
import * as React from 'react';
import { CardBody } from 'reactstrap';
import {
  LabelText,
  LabelTitle,
  LabelTitleMessage,
  MassiveUserCard,
  MassiveUserCardImage,
  MassiveUserWrapper,
  UploadFileWrapper,
} from './styles';

interface AdminCreateMassiveCardProps {
  onSelectCsvFile: (file: File) => void;
  onGoBack: () => void;
  onNavigationInterests: () => void;
}

const AdminCreateMassiveCard: React.FC<AdminCreateMassiveCardProps> = ({
  onSelectCsvFile,
  onGoBack,
  onNavigationInterests,
}) => {
  return (
    <MassiveUserCard>
      <MassiveUserWrapper>
        <MassiveUserCardImage top src={`${S3_ADMIN}AddMassiveUsers.svg`} alt="Card image cap" />
      </MassiveUserWrapper>
      <CardBody>
        <LabelTitleMessage>
          Carga tu archivo .csv para subir más de un empleado a tu organización
        </LabelTitleMessage>
        <LabelText>
          Un archivo <b>.csv</b> te permite realizar la carga de tus empleado de forma masiva.
          Recuerda que este archivo de texto debe estar delimitado por comas y debe contene hasta
          5.000 filas. Asegurate de que el archivo <b>.csv</b> esté en el formato requerido y que se
          completen todos los campos obligatorios para subir los datos correctamente.
        </LabelText>
        <UploadFileWrapper>
          <UploadFile onSelectCsvFile={onSelectCsvFile} />
        </UploadFileWrapper>
        <LabelTitle>¿No tienes ningún documento?</LabelTitle>
        <LabelText>
          No te preocupes, descarga nuestra plantilla y completa los espacio para obtener tu archivo
          <b>.csv</b> y poder subir a plataforma todos tus empleados.
        </LabelText>
        <UploadFileWrapper>
          <DownloadTemplateMassiveUsers massiveUsers={true} />
        </UploadFileWrapper>
      </CardBody>
    </MassiveUserCard>
  );
};

export default AdminCreateMassiveCard;
