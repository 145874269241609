import { ConfirmPasswordChangue, SendEmailToResetPassword } from 'actions/ApiClient/AuthActions';
import PasswordRecovery from 'components/molecules/PasswordRecovery';
import RequestEmailRecovery from 'components/molecules/RequestEmailRecovery';
import { AuthNavigations } from 'constants/NavigationUrls';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { objectValidation } from 'utils';
import { logEvent } from 'utils/GA/analytics';
import AuthPageLayout from '../Layout/AuthPageLayout';

const RecoveryPassword: React.FC = () => {
  const [addPassword, setAddPassword] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userEmail, setUserEmail] = React.useState<any>(undefined);

  const history = useHistory();

  const defaultValues: IRecoveryPassword = {
    email: '',
    code: '',
    confirmPassword: '',
    password: '',
  };

  const getDefaultFormValues = (): IRecoveryPassword => {
    return defaultValues;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IRecoveryPassword>({ ...objectValidation, defaultValues: getDefaultFormValues() });

  const submitEmail = async (requestEmail: IRecoveryPassword) => {
    logEvent('User', 'Request recovery password email');
    setUserEmail(requestEmail.email);
    setLoading(true);
    try {
      const requestRestore = await SendEmailToResetPassword(requestEmail.email);
      console.log(requestRestore);
      toast.success('Hemos enviado un código de verificación a tu correo');
      setAddPassword(true);
    } catch (error: any) {
      toast.error(error.response.data.message, { duration: 4000 });
    } finally {
      setLoading(false);
    }
  };

  const onSubmitConfirmPassword = async (user: IRecoveryPassword) => {
    logEvent('User', 'Change password');
    const userData = {
      email: userEmail,
      code: user.code,
      password: user.password,
      password_copy: user.confirmPassword,
    };
    setLoading(true);
    try {
      const confirm = await ConfirmPasswordChangue(userData);
      if (confirm.status === 200) {
        toast.success('La contraseña se actualizó correctamente.', { duration: 4000 });
        history.push(AuthNavigations.login);
      }
    } catch (error: any) {
      toast.error(error.response.data.message, { duration: 4000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthPageLayout showFooter={false} showHeader={true}>
      {!addPassword ? (
        <RequestEmailRecovery
          loading={loading}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          submitEmail={submitEmail}
        />
      ) : (
        <PasswordRecovery
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          onSubmitConfirmPassword={onSubmitConfirmPassword}
          loading={loading}
          watch={watch}
          email={userEmail}
        />
      )}
    </AuthPageLayout>
  );
};

export default RecoveryPassword;
