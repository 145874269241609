import { CardContainer, RouteInfo, RouteInfoTItle, TitleContainer } from './styles';
import icons from 'assets/Icon';
import { formatDate } from 'utils';
import { CourseTitle, CourseTextItem, CardInfoWrapper, ImageWrapper, CardImage } from './styles';
import * as React from 'react';

interface CourseCardProps {
  name: string;
  image: string;
  company: string;
  start: Date | null;
  score: number | null;
  duration: string;
  skills: number;
  price: number | null;
  companyColor: string;
}

const CourseCard = ({
  name,
  image,
  company,
  start,
  score,
  skills,
  duration,
  price,
  companyColor,
}: CourseCardProps): JSX.Element => {
  return (
    <CardContainer>
      <ImageWrapper>
        <CardImage src={image} alt={`${name}-img`} />
      </ImageWrapper>
      <CourseTitle>{name}</CourseTitle>
      {/* Espacio para calficacion */}
      <br />
      {/* {score && (
        <RatingWrapper>
          <RatingView ratingValue={score} size={20} />
          <CourseTextItem>{score}</CourseTextItem>
        </RatingWrapper>
      )} */}
      <CardInfoWrapper>
        <div>
          <RouteInfo>
            <icons.CalendarIcon />
            {start ? (
              <CourseTextItem>Inicio: {formatDate(start)}</CourseTextItem>
            ) : (
              <CourseTextItem>Inicia a tu ritmo</CourseTextItem>
            )}
          </RouteInfo>
          <RouteInfo>
            <icons.Icono_Duracion />
            {duration ? (
              <CourseTextItem>Duración: {duration} </CourseTextItem>
            ) : (
              <CourseTextItem>Duración: No Disponible </CourseTextItem>
            )}
          </RouteInfo>
          <RouteInfo>
            <icons.SkillsIcon />
            {skills ? (
              <CourseTextItem>{skills} Habilidades </CourseTextItem>
            ) : (
              <CourseTextItem>No Disponible </CourseTextItem>
            )}
          </RouteInfo>
        </div>
      </CardInfoWrapper>
      <TitleContainer>
        {company ? (
          <RouteInfoTItle>
            <span>{company}</span>
          </RouteInfoTItle>
        ) : (
          <RouteInfoTItle>
            <span>No Disponible</span>
          </RouteInfoTItle>
        )}
      </TitleContainer>
    </CardContainer>
  );
};

export default CourseCard;
