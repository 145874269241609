import * as React from 'react';
import {
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  SubtitleFirst,
} from './styles';
import { Badge, Card, Table } from 'reactstrap';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';

interface CoursesCardsProps {
  dataTable: IBadgeListTemplateResults[];
}

const AdminPreviewBadgesArchived = ({ dataTable }: CoursesCardsProps): JSX.Element => {
  const history = useHistory();

  const handleBadgeDetailUsers = (id: string, name: string) => {
    history.push(`${AdminRole.badgesPreviewUsers}/${name}/${id}`);
  };

  return (
    <React.Fragment>
      <CourseCatalogContainer>
        <CourseCatalogHeaderTitles>
          <CourseCatalogSubTitles>
            <SubtitleFirst>Insignias Archivadas</SubtitleFirst>
          </CourseCatalogSubTitles>
        </CourseCatalogHeaderTitles>
        <Card>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Insignias</th>
                <th>Creación</th>
                <th>Versión</th>
                <th>Estatus</th>
                <th>Inscritos</th>
              </tr>
            </thead>
            <tbody>
              {dataTable.map((path: IBadgeListTemplateResults, i: number) => {
                return (
                  <tr key={Date.now() + i}>
                    <th>{path?.name.substring(0, 50)}</th>
                    <td>{path?.updated_at.substring(0, 10)}</td>
                    <td>{path?.get_issue_count}</td>
                    <td>
                      {path?.status === 'active' ? (
                        <Badge color="success">Activa</Badge>
                      ) : path?.status === 'draft' ? (
                        <Badge color="warning">Borrador</Badge>
                      ) : (
                        <Badge color="danger">Inactiva</Badge>
                      )}
                    </td>
                    <td>
                      {path.users && path.users.length !== 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleBadgeDetailUsers(path.uuid, path.name)}
                        >
                          <img
                            src={path.users[0].avatar}
                            style={{ width: '30px', height: '30px', borderRadius: '30px' }}
                            alt="img"
                          />
                          {path.users.length > 1 && (
                            <img
                              src={path.users[1].avatar}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginLeft: '-15px',
                              }}
                              alt="img"
                            />
                          )}
                          {path.users.length > 2 && (
                            <img
                              src={path.users[2].avatar}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginLeft: '-15px',
                              }}
                              alt="img"
                            />
                          )}
                          {path.users.length > 3 && (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginLeft: '-15px',
                                backgroundColor: '#002057',
                              }}
                            >
                              <h6
                                style={{
                                  color: 'white',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                  marginTop: '8px',
                                  fontSize: '10px',
                                }}
                              >
                                +{path.users.length}
                              </h6>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <h6
                            style={{
                              color: 'black',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '15px',
                            }}
                          >
                            _
                          </h6>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </CourseCatalogContainer>
    </React.Fragment>
  );
};

export default AdminPreviewBadgesArchived;
