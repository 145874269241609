import * as Sentry from '@sentry/react';
import { AdminUrls, API_URL } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetDataConfigSubscribe(id: number): Promise<IDataConfigSubscribe> {
  try {
    const data = await new HttpRequest().Get<IDataConfigSubscribe>(
      `${API_URL}${AdminUrls.configSubscription}${id}/`
    );

    if (data.okay && data.data) {
      return data.data;
    } else {
      throw Error(data.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetDataConfigPermission(id: number): Promise<IDataConfigPermission> {
  try {
    const data = await new HttpRequest().Get<IDataConfigPermission>(
      `${API_URL}${AdminUrls.configPermission}${id}/`
    );

    if (data.okay && data.data) {
      return data.data;
    } else {
      throw Error(data.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function PatchDataConfigPermission(
  id: number,
  dataProps: IDataConfigPermissionPatch
): Promise<IDataConfigPermissionPatch> {
  try {
    const data = await new HttpRequest().Patch<IDataConfigPermissionPatch>(
      `${API_URL}${AdminUrls.configPermission}${id}/`,
      dataProps
    );

    if (data.okay && data.data) {
      return data.data;
    } else {
      throw Error(data.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DeleteMyUser(dataProps: IDeleteMyUser): Promise<IDeleteMyUser> {
  try {
    const data = await new HttpRequest().Post<IDeleteMyUser>(
      `${API_URL}${AdminUrls.deleteMyUser}`,
      dataProps
    );

    if (data.okay && data.data) {
      return data.data;
    } else {
      throw Error(data.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
