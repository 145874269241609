import AcredittaText from 'components/atoms/AcredittaText';
import BadgeInfoComponent from 'components/molecules/BadgeInfoComponent/BadgeInfoComponent';
import icons from 'assets/Icon';
import CommonPageLayout from '../Layout/CommonPageLayout';
import './styles.css';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AcredittaButton from 'components/atoms/AcredittaButton';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { useEffect, useState } from 'react';
import ShareBadgeModal from 'components/molecules/ShareBadgeModal/ShareBadgeModal';
import { DetailBadgesUserTempThunk } from 'actions/Thunks/UnAuthUsersThunks';
import toast from 'react-hot-toast';
import { getBadgeCollection } from 'services/badges.service';
import CardBadgeCollection from 'components/molecules/CardBadgeCollection/CardBadgeCollection';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { ENVIRONMENT_URL_BADGE } from 'utils';

const templateUrl = `${ENVIRONMENT_URL_BADGE}/badges/template`;

const BadgeTemplateInfo = () => {
  const [userInfo, setUserInfo] = useState<any>();
  const [showShareScreen, setShowShareScreen] = useState(false);
  const [badgeTemplate, setBadgeTemplate] = useState<any>();
  const [badgeCollection, setBadgeCollection] = useState<any>(null);

  const urlTemplate = `${templateUrl}/${badgeTemplate?.id}`;

  let { badge_id } = useParams<{ badge_id: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useAppSelector((state) => state.user);

  const getBadgeDetailFunction = async (id: string) => {
    const badge = await dispatch(DetailBadgesUserTempThunk(id));
    if (DetailBadgesUserTempThunk.rejected.match(badge)) {
      toast.error(badge.payload as string);
    } else {
      setBadgeTemplate(badge.payload.data);
    }
  };

  const getBadgeCollectionFunction = async (id: string) => {
    try {
      const collection = await getBadgeCollection(id);
      setBadgeCollection(collection.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleMethodContact = () => {
    if (badgeTemplate?.contact_method !== null) {
      const { email, phone_code, url } = badgeTemplate.contact_method;
      if (email !== null) {
        let body = `¡Hola!\r\rquiero mas información para obtener esta insignia.\r\r${urlTemplate}`;
        var a = document.createElement('a');
        a.target = '_blank';
        a.href = `mailto:${email}?subject=${''}&body=${encodeURIComponent(body)}`;
        a.click();
      }
      if (phone_code !== null) {
        var tel = document.createElement('a');
        tel.target = '_blank';
        tel.href = `tel:${phone_code}`;
        tel.click();
      }
      if (url !== null) {
        window.open(url);
      }
    } else {
      toast.error('No existe metodo de contacto en este template', { duration: 4000 });
    }
  };

  useEffect(() => {
    setUserInfo(user.userInfo);
  }, [user]);

  useEffect(() => {
    getBadgeDetailFunction(badge_id);
    getBadgeCollectionFunction(badge_id);
    // eslint-disable-next-line
  }, [badge_id]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={userInfo?.user_type.id === 3 ? false : true}
      showMenuHeaderAcredited={userInfo?.user_type.id === 3 ? true : false}
      showCommon={true}
    >
      <ShareBadgeModal
        badgeId={badgeTemplate?.id}
        badge_details={badgeTemplate}
        showModal={showShareScreen}
        handleSocialModal={() => setShowShareScreen(false)}
        isTemplate={true}
      />
      <div className="template-badge__container">
        {badgeTemplate ? (
          <>
            <div onClick={history.goBack} className="template-badge__icon">
              <icons.GoBackIcon />
            </div>
            <div className="template-badge__title">
              <AcredittaText variant="h3">{badgeTemplate?.name}</AcredittaText>
            </div>
            <div className="template-badge__imagen">
              <img src={badgeTemplate?.image.url} alt="imagen de insignia" />
            </div>
            {userInfo?.user_type.code === 'ADMIN' ? (
              <div className="badge-share__container">
                <LinkedinShareButton title="Mira este template!" url={urlTemplate}>
                  <LinkedinIcon bgStyle={{ fill: '#e2231a' }} size={42} round={true} />
                </LinkedinShareButton>
                <TwitterShareButton title="Mira este template!" url={urlTemplate}>
                  <TwitterIcon bgStyle={{ fill: '#e2231a' }} size={42} round={true} />
                </TwitterShareButton>
                <FacebookShareButton quote="Mira este template!" url={urlTemplate}>
                  <FacebookIcon bgStyle={{ fill: '#e2231a' }} size={42} round={true} />
                </FacebookShareButton>
              </div>
            ) : null}
            <div className="template-badge__provider">
              <AcredittaText variant="body1">
                Emitida por: <span>{badgeTemplate?.provider}</span>
              </AcredittaText>
            </div>
            <div className="template-badge__button">
              {userInfo?.user_type.code === 'ADMIN' ? (
                <AcredittaButton
                  onPress={() => setShowShareScreen(true)}
                  fullWidth={true}
                  variant="secondary"
                  title="Compartir"
                />
              ) : (
                <AcredittaButton
                  onPress={handleMethodContact}
                  fullWidth={true}
                  variant="secondary"
                  title="Qué hacer para ganar esta insignia"
                />
              )}
            </div>
            <BadgeInfoComponent
              userRol={userInfo?.user_type.code}
              badgeInfo={badgeTemplate}
              showSharemModal={() => setShowShareScreen(true)}
              handleMethodContact={handleMethodContact}
            />
            {badgeCollection?.length !== 0 && (
              <div className="template-badge__collections">
                <div>
                  <AcredittaText variant="h3">
                    Colección de insignias que debes obtener
                  </AcredittaText>
                </div>
                <div className="collection-container">
                  {badgeCollection?.map((item: any) => (
                    <CardBadgeCollection key={item.id} item={item} />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <AcredittaLoader />
        )}
      </div>
    </CommonPageLayout>
  );
};

export default BadgeTemplateInfo;
