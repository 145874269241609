import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label, Col } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const MyRoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
`;

export const ColStyled = styled(Col)`
  @media ${device.mediumLaptop} {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media ${device.mediumMobile} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
export const MyRoutesTitle = styled(Label)`
  width: 70%;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const ContainerNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MyRoutesRows = styled.div`
  width: 70%;
  display: flex;
  overflow-x: auto;
  padding: 25px 0px;
  @media ${device.bigMobile} {
    width: 80%;
  }
`;
