import { useEffect, useState } from 'react';
import { manageErrors } from 'helpers/manage-error';
import { LearningPathLevels } from 'actions/ApiClient/AdminActions';

const useGetLevels = () => {
  const [levels, setLevels] = useState<IPathLevel[]>([]);
  const [isLevelFetching, setIsLevelFetching] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLevelFetching(true);
        const resp = await LearningPathLevels();
        setLevels(resp);
        setIsLevelFetching(false);
      } catch (e) {
        setIsLevelFetching(false);
        manageErrors(e);
      }
    })();
  }, []);

  return { levels, isLevelFetching };
};

export default useGetLevels;
