import { useState, useRef, useEffect } from 'react';
import { UploadFileWrapper } from './styles';
import BadgeIssueNavigation from 'components/molecules/BadgeIssueNavigation';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import { useHistory } from 'react-router';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';
import { AdminIssueMassiveBadgesThunk } from 'actions/Thunks/AdminThunk';
import { removeCsvFile } from 'states/slices/admin.slice';
import { AdminRole } from 'constants/NavigationUrls';
import DownloadTemplateMassiveUsers from 'components/atoms/DownloadTemplateMassiveUsers';
import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import AcredittaButton from 'components/atoms/AcredittaButton';
import icons from 'assets/Icon';
import CsvLink from './components/CsvLink';
import { manageErrors } from 'helpers/manage-error';
import { getBadgeTemplateDetail } from 'services/badges.service';
import AcredittaLoader from 'components/atoms/AcredittaLoader';

interface BadgeIssueMasiveProps {
  badge_id: string;
}

const BadgeIssueMasive = ({ badge_id }: BadgeIssueMasiveProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [label1, setLabel1] = useState(false);
  const [label2, setLabel2] = useState(false);
  const inputRef = useRef(null) as any;
  const [inputFile, setInputFile] = useState(null) as any;
  const [submitting, setSubmitting] = useState(false);
  const [fetching, setIsFetching] = useState(false);
  const [badge, setBadge] = useState({}) as any;

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        const resp = await getBadgeTemplateDetail(badge_id);
        setIsFetching(false);
        setBadge(resp.data.data);
      } catch (e) {
        setIsFetching(false);
        manageErrors(e);
      }
    })();
  }, [badge_id]);

  const navigateIndividual = () => {
    history.push(`${AdminRole.badgeIssue}/${badge_id}`);
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    const info = {
      file: inputFile,
      badge: badge_id,
    };

    const uploadFile = await dispatch(AdminIssueMassiveBadgesThunk(info));

    if (AdminIssueMassiveBadgesThunk.fulfilled.match(uploadFile)) {
      if (uploadFile.payload) {
        toast.success(uploadFile.payload);
      }
    } else {
      toast.error(uploadFile.payload as string);
      dispatch(removeCsvFile());
    }
    setSubmitting(false);
    setInputFile(null);
  };

  const handleInputChange = (e: any) => {
    setInputFile(e.target.files[0]);
  };

  const handleOpenBadge = (type: 'list' | 'preview') => {
    var a = document.createElement('a');
    a.target = '_blank';
    if (type === 'list') {
      history.push(`${AdminRole.badgesPreview}`);
    } else {
      a.href = `${AdminRole.templateBadgeDetail}/${badge_id}`;
      a.click();
    }
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <BadgeIssueNavigation selection="masive" navigateIndividual={navigateIndividual} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-4 text-center p-4">
            {fetching ? (
              <AcredittaLoader />
            ) : (
              <>
                <AcredittaText variant="h3" align="center">
                  {badge.name}
                </AcredittaText>
                <div>
                  <img
                    src={badge?.image_url || badge.image?.url}
                    alt=""
                    width="auto"
                    height="300px"
                    style={{ display: 'block', margin: '10px auto' }}
                  />
                </div>
                <AcredittaButton title="Ver insignia" onPress={() => handleOpenBadge('preview')} />
              </>
            )}
          </div>
          <div className="col-xs-12 col-sm-8">
            <AcredittaBox>
              <AcredittaText variant="h4">
                Carga tu archivo csv para emitir las insignias
              </AcredittaText>
              <AcredittaText variant="body1" color="secondary">
                Para la emisión de manera masiva se require llenar una plantilla con los datos de
                los acreditados.
              </AcredittaText>
              <AcredittaText variant="body1" color="secondary">
                Adjunta tu plantilla en formato{' '}
                <CsvLink
                  label={label1}
                  onLeave={() => setLabel1(false)}
                  onEnter={() => setLabel1(true)}
                  position="left"
                />
                para emitir una insignia
              </AcredittaText>
              <div className="text-center">
                <input
                  type="file"
                  name="archivo"
                  ref={inputRef}
                  accept="text/csv"
                  onChange={handleInputChange}
                  style={{ display: 'none' }}
                />
                <AcredittaButton onPress={() => inputRef.current.click()} title="Subir archivo" />
                {inputFile && (
                  <AcredittaText
                    variant="body1"
                    style={{ color: '#02B148' }}
                    align="center"
                    fontWeight="700"
                  >
                    <icons.DocumentIcon style={{ marginRight: 10 }} />
                    {inputFile.name}
                  </AcredittaText>
                )}
              </div>

              <AcredittaText variant="h4">¿No cuentas con una plantilla csv ?</AcredittaText>

              <AcredittaText variant="body1" color="secondary">
                No te preocupes, descarga nuestra plantilla y completa los espacio para obtener tu
                archivo
                <CsvLink
                  label={label2}
                  onLeave={() => setLabel2(false)}
                  onEnter={() => setLabel2(true)}
                  position="right"
                />
                y poder emitir la insignia.
              </AcredittaText>

              <UploadFileWrapper>
                <DownloadTemplateMassiveUsers massiveBadges={true} />
              </UploadFileWrapper>

              <div className="text-center">
                <AcredittaButton
                  onPress={() => handleOpenBadge('list')}
                  title="Cancelar"
                  variant="secondary"
                />
                <AcredittaButton
                  onPress={handleSubmit}
                  title="Emitir"
                  variant="primary"
                  disabled={!inputFile || submitting}
                  isLoading={submitting}
                />
              </div>
            </AcredittaBox>
          </div>
        </div>
      </div>
      <div style={{ height: '100px' }}></div>
    </CommonPageLayout>
  );
};

export default BadgeIssueMasive;
