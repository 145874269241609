import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangeImageOrganization,
  ChangeThemeOrganization,
  GetThemes,
  GetThemesOrganizartion,
} from 'actions/ApiClient/BrandingActions';
import { GetErrorMessage } from 'utils';

export const GetThemesThunk = createAsyncThunk(
  'theme/list-theme',
  async (_, { rejectWithValue }) => {
    try {
      const ListThemes = await GetThemes();
      return ListThemes;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetThemesOrganizationThunk = createAsyncThunk(
  'theme/organization-theme',
  async (_, { rejectWithValue }) => {
    try {
      const ListThemesOrganization = await GetThemesOrganizartion();
      return ListThemesOrganization;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ChangeThemeOrganizationThunk = createAsyncThunk(
  'upload/theme-organization',
  async (body: any, { rejectWithValue }) => {
    try {
      const themeChange = await ChangeThemeOrganization(body);
      return themeChange;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ChangeImageOrganizationThunk = createAsyncThunk(
  'upload/image-organization',
  async (body: any, { rejectWithValue }) => {
    try {
      const themeImage = await ChangeImageOrganization(body);
      return themeImage;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
