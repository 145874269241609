import icons from 'assets/Icon';
import * as React from 'react';
import { ButtonWrapper, Container, SubtitleFirst, SubtitleSecondNoData } from './styles';
import Button from 'components/atoms/Button';
import { AdminRole } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';

const NoDataUsers: React.FC = () => {
  const history = useHistory();

  const navegationUser = () => {
    history.push(`${AdminRole.loadNewUsers}`);
  };

  return (
    <React.Fragment>
      <Container>
        <icons.Icono_Usuarios />
        <br />
        <br />
        <SubtitleFirst>No tienes usuarios registrados</SubtitleFirst>
        <SubtitleSecondNoData>
          ¡Carga a un usuario para que haga parte de tu organización!
        </SubtitleSecondNoData>
        <ButtonWrapper>
          <Button onClick={() => navegationUser()} label={'Cargar un Usuario'} widthCustom={300} />
        </ButtonWrapper>
      </Container>
    </React.Fragment>
  );
};

export default NoDataUsers;
