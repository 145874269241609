import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { REACT_APP_SENTRY } from 'constants/Environments';

export function SentryConfigure() {
  Sentry.init({
    dsn: REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: 'production',
    release: 'acreditta-frontend-badges@' + process.env.npm_package_version,
  });
}
