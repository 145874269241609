import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import Button from 'components/atoms/Button';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setToggleModal } from 'states/slices/auth.slice';

const TermsAndConditions: React.FC = () => {
  const authSeletor = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const toggle = () => {
    dispatch(setToggleModal());
  };

  return (
    <React.Fragment>
      <Modal isOpen={authSeletor.modal} toggle={toggle} size="lg" centered={true}>
        <ModalHeader>{authSeletor.title}</ModalHeader>
        <ModalBody>{authSeletor.textBody}</ModalBody>
        <ModalFooter>
          <Button label="Cancelar" lightMode={true} onClick={toggle} />
          <Button label="Aceptar" onClick={toggle} />
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default TermsAndConditions;
