import { MenuI } from '../../interfaces/menu';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

interface MenuItemWithListProp {
  mainText: string;
  items: MenuI[];
}

const MenuItemWithList = ({ mainText, items }: MenuItemWithListProp) => {
  return (
    <UncontrolledDropdown nav inNavbar={false}>
      <DropdownToggle nav caret>
        {mainText}
      </DropdownToggle>
      <DropdownMenu>
        {items.map((el) => (
          <DropdownItem key={el.id}>
            <Link to={el.path}>{el.text}</Link>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default MenuItemWithList;
