import { useContext } from 'react';
import { BadgeImageContext } from '../context/BadgeImageProvider';
import BadgeMasterImage from './BadgeMasterImage';
import BagdeMasterImageWithProgress from './BagdeMasterImageWithProgress';

const BadgeMainImage = () => {
  const { setShow, isAdmin } = useContext(BadgeImageContext);

  const setSize = isAdmin ? 220 : 300;

  return (
    <div
      style={{ width: setSize, height: setSize, margin: '10px auto' }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {isAdmin && <BadgeMasterImage />}
      {!isAdmin && <BagdeMasterImageWithProgress />}
    </div>
  );
};

export default BadgeMainImage;
