import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';

export const ColStyled = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

export const OrganizationProfileContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OrganizationProfileSectionsContainer = styled(Row)`
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`;
