import * as React from 'react';
import BadgeIssueMasive from 'components/molecules/BagdeIssueMasive';
import { useParams } from 'react-router-dom';

const MassiveIssue: React.FC = () => {
  let { badge_id } = useParams<{ badge_id: string }>();

  return <BadgeIssueMasive badge_id={badge_id} />;
};

export default MassiveIssue;
