import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { FormGroup, Label, Input, Modal } from 'reactstrap';
import { BLUE_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';

export const BadgeDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: start;
  justify-content: center;
  margin: 40px;
`;

export const BadgeInfoWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const BadgeName = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const ItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const ItemDesc = styled(Label)`
  font-size: 16px;
  line-height: 23px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const BadgeNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: center;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`;

export const SkillContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const RequirementsWrapper = styled.div`
  display: flex;
  align-items: start;
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const MoreInfoWrapper = styled.div`
  display: flex;
  align-items: start;
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const SkillsSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
`;

export const ShareBadgeContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 40px;
`;

export const NewCourseFormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const NewCourseInstructionsTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
`;

export const FormLabelSimple = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media ${device.mediumMobile} {
    flex-direction: column-reverse;
  }
`;

export const InfoText = styled(Label)`
  font-size: 14px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin-bottom: 20px;
  margin-top: 1px;
`;

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;
  display: flex;
  margin: 0;
  margin-bottom: 22px;

  @media ${device.laptops} {
    flex-direction: column;
  }
`;

export const FormLabelSimpleWrapper = styled.div`
  width: 20%;
  text-align: end;
  padding-right: 10px;
  margin: 0;

  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormDateLabelSimpleWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  margin: 0;
  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormInputWrapper = styled.div`
  width: 80%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const InputDate = styled(Input)`
  height: 20px;
`;

export const SwitchContainer = styled.div`
  width: '100%';
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 20px;
`;

export const InputSelectContainer = styled.div`
  width: 100%;
`;

export const SwitchLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin: 5px 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const SelectedSkillsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 130px;
`;

export const FormMoreInfoLabel = styled.div`
  width: 100%;
`;

export const MoreInfoLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: ${SECONDARY_COLOR};
  width: 100%;
  cursor: pointer;
`;

export const FormLabelSimpleDates = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
  text-align: end;
  padding-right: 5px;
`;

export const FormGroupDatesStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% 30% 25% 25%;
  margin: 30px 0px;
`;

export const SessionsContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
  margin: 10px 0px;
`;

export const SessionContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
`;

export const FormLabelSession = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: #53565a;
  margin-bottom: 0px;
  padding-bottom: 23px;
`;

export const FormLabelSessionWrapper = styled.div`
  width: 100%;
  text-align: end;
  padding-right: 10px;
  margin: 0;
`;

export const SessionsDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const SesionAddButtonWrapper = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DeleteSessionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    width: 15px;
  }
`;

export const SesionAddButton = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: #da291c;
  cursor: pointer;
`;

export const DeleteSessionButton = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  margin: 5px;
`;

export const SesionAddButtonTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  padding-left: 3%;
`;

export const InputWrapper = styled.div`
  padding-right: 10px;
  padding-bottom: 23px;
`;

export const ModalContainer = styled(Modal)`
  max-width: 900px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 50px;
  padding: 5%;
`;

export const ModalSubTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
`;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
  padding: 2% 0%;
`;
