import AcredittaText from 'components/atoms/AcredittaText';
import ImagesStackableBadges from 'components/atoms/ImagesStackableBadges/ImagesStackableBadges';
import { getBadgeStackableDetailAcreditado } from 'constants/NavigationUrls';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserCollection } from 'services/badges.service';
import './styles.css';

const MyCollections = () => {
  const [collections, setCollections] = useState<any>([]);

  const getCollections = async () => {
    try {
      const resp = await getUserCollection();
      setCollections(resp.data.results);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <div className="collections-container">
      <div className="collections-name">
        <AcredittaText variant="h3">Mis Colecciones en proceso</AcredittaText>
      </div>
      <div className="cards-collection__container">
        <div className="cards-collection">
          {collections.length > 0 ? (
            collections?.map((card: any) => (
              <Link
                to={getBadgeStackableDetailAcreditado(card.uuid)}
                className="card-collection-item"
              >
                <div className="card-name">
                  <AcredittaText align="center" fontWeight="bold" color="secondary" variant="body2">
                    {card.name}
                  </AcredittaText>
                </div>
                <div>
                  <ImagesStackableBadges
                    master={card.image_url}
                    badges={card.collections.map((item: any) => item.image_url)}
                    altura={70}
                  />
                </div>
                <div>
                  <AcredittaText align="center" fontWeight="bold" color="secondary" variant="body1">
                    {card.provider}
                  </AcredittaText>
                </div>
              </Link>
            ))
          ) : (
            <AcredittaText color="secondary" variant="body2">
              Obtén insignias para ver posibles colecciones.
            </AcredittaText>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCollections;
