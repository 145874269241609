import linkedin from '../../../../assets/images/linkedin-logo.png';
import twitter from '../../../../assets/images/twitter-logo.png';
import facebook from '../../../../assets/images/facebook-logo.png';
import mail from '../../../../assets/images/mail-logo.png';
import styled from 'styled-components';

const StyledImage = styled.img`
  max-height: 35px;
  width: auto;
`;

const LinkedinLogoPng = () => {
  return <StyledImage src={linkedin} alt="" />;
};

const TwitterLogoPng = () => {
  return <StyledImage src={twitter} alt="" />;
};

const FacebookLogoPng = () => {
  return <StyledImage src={facebook} alt="" />;
};

const MailLogoPng = () => {
  return <StyledImage src={mail} alt="" />;
};

export { LinkedinLogoPng, TwitterLogoPng, FacebookLogoPng, MailLogoPng };
