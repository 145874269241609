import * as React from 'react';
import TextInput from 'components/atoms/TextInput';
import {
  ShareBadgeLinkContainer,
  ShareBadgeLinkTitle,
  InputWrapper,
  InputLabelWrapper,
  InputsLabel,
  ButtonsWrapper,
} from './styles';
import { UncontrolledTooltip } from 'reactstrap';
import Button from 'components/atoms/Button';

interface ShareBadgeLinkProps {
  badge_url: string;
  closeShareSection: () => void;
}

const ShareBadgeLink = ({ badge_url, closeShareSection }: ShareBadgeLinkProps): JSX.Element => {
  return (
    <div>
      <ShareBadgeLinkContainer
        onClick={() => {
          navigator.clipboard.writeText(badge_url);
        }}
        id="copy-url"
      >
        <ShareBadgeLinkTitle>Copiar Enlace</ShareBadgeLinkTitle>
        <InputWrapper>
          <InputLabelWrapper>
            <InputsLabel $height={15}>URL</InputsLabel>
          </InputLabelWrapper>
          <UncontrolledTooltip placement="bottom" target="copy-url" trigger="click" autohide={true}>
            Texto copiado al portapapeles
          </UncontrolledTooltip>
          <TextInput
            error={{}}
            name="badgeLink"
            height={41}
            value={badge_url}
            disabled
            pointer={true}
          />
        </InputWrapper>
      </ShareBadgeLinkContainer>
      <ButtonsWrapper>
        <Button label="Cancelar" widthCustom={150} lightMode={true} onClick={closeShareSection} />
        <Button
          label="Copiar"
          widthCustom={150}
          onClick={() => {
            navigator.clipboard.writeText(badge_url);
          }}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default ShareBadgeLink;
