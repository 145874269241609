import { themeConfig } from 'theme/config';
import styled from 'styled-components';

interface SearchInputProps {
  text: string;
  onChange: (value: string) => void;
  onClear: () => void;
}

const StyledInput = styled.input`
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  height: 40px;
`;

const SearchInput = ({ text, onChange, onClear }: SearchInputProps) => {
  return (
    <div
      className="input-group mb-3"
      style={{ background: themeConfig.colors.primary.blue, padding: 10, margin: '0 auto' }}
    >
      <StyledInput
        type="text"
        className="form-control"
        placeholder="Buscar usuario por correo"
        value={text}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-outline-danger"
          style={{
            backgroundColor: themeConfig.colors.primary.red,
            color: 'white',
            height: '39px',
          }}
          onClick={onClear}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
