import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Col, Container, Label, Row } from 'reactstrap';
import styled from 'styled-components';

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const PageItemsContainer = styled(Row)`
  justify-content: center;
`;

export const SkillsContainer = styled(Row)`
  width: 70%;
  margin-top: 30px;

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const AddMoreSkillsContainer = styled(Row)`
  width: 70%;

  @media ${device.ipad} {
    width: 100%;
  }
`;

export const PageSearchContainer = styled(Row)`
  width: 70%;
`;

export const PageTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold;
  font-size: 50px;
  margin-bottom: 0;
  text-align: center;

  @media ${device.ipad} {
    font-size: 35px;
  }
`;

export const PageDescription = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  max-width: 70%;
  margin: 40px 0px;
  color: #53565a;

  @media ${device.ipad} {
    max-width: 100%;
  }
`;

export const AddMoreSkills = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${SECONDARY_COLOR};
  cursor: pointer;
  align-self: self-start;
  padding-left: 15px;
`;

export const SkillTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;

export const SkillsListContainer = styled(Col)``;

export const SkillContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d2d2d2;
  svg {
    width: 30px;
    cursor: pointer;
  }
`;
export const ButtonContainer = styled.div``;

export const SkillsSelectedConatiner = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 15px;
  margin: 10px 0px;
`;

export const TextInputWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  svg {
    width: 16px;
    top: 4px;
    right: 8px;
    cursor: pointer;
  }
`;
