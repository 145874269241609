interface SpanBadgeProps {
  text: string;
}

const SpanBadgeStatus = ({ text }: SpanBadgeProps) => {
  const getColor = () => {
    if (text === 'pending') {
      return 'warning';
    } else if (text === 'accepted') {
      return 'success';
    } else {
      return 'danger';
    }
  };

  const getText = () => {
    if (text === 'pending') {
      return 'Pendiente';
    } else if (text === 'accepted') {
      return 'Aceptado';
    } else {
      return 'Rechazado';
    }
  };

  return <span className={`badge badge-${getColor()}`}>{getText()}</span>;
};

export default SpanBadgeStatus;
