import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeleteMyUser,
  GetDataConfigPermission,
  GetDataConfigSubscribe,
  PatchDataConfigPermission,
} from 'actions/ApiClient/ConfigSubscribe';
import { GetErrorMessage } from 'utils';

export const GetDataConfigSubscribeThunk = createAsyncThunk(
  'config/subscribe-data',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await GetDataConfigSubscribe(id);
      return data;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetDataConfigPermissionThunk = createAsyncThunk(
  'config/subscribe-permission',
  async (id: number, { rejectWithValue }) => {
    try {
      const data = await GetDataConfigPermission(id);
      return data;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const PatchDataConfigPermissionThunk = createAsyncThunk(
  'config/subscribe-patch',
  async (dataProps: IDataConfigPermissionPatchFormat, { rejectWithValue }) => {
    try {
      const data = await PatchDataConfigPermission(dataProps.id, dataProps.data);
      return data;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DeleteMyUserThunk = createAsyncThunk(
  'config/subscribe-delete',
  async (dataProps: IDeleteMyUser, { rejectWithValue }) => {
    try {
      const data = await DeleteMyUser(dataProps);
      return data;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
