import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import numbers from '../../../../assets/images/numbers.png';
import styled from 'styled-components';

const StyleImg = styled.img`
  display: block;
  margin: 0 auto;
`;

const CardMostViewedBadgesInfo = () => {
  return (
    <AcredittaBox
      borderRadius="25px"
      boxStyle={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <StyleImg src={numbers} alt="" />
      </div>
      <div>
        <AcredittaText variant="body1" align="center" color="secondary">
          Conoce cuales son las insignias que más visitan tus usuarios.
        </AcredittaText>
      </div>
    </AcredittaBox>
  );
};

export default CardMostViewedBadgesInfo;
