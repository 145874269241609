import AcredittaText from 'components/atoms/AcredittaText';
import { Link } from 'react-router-dom';

interface Props {
  text: string;
  path: string;
}

const MobileConfigMenuItem = ({ path, text }: Props) => {
  return (
    <Link to={path}>
      <AcredittaText variant="body1" color="secondary" fontWeight="600">
        {text}
      </AcredittaText>
    </Link>
  );
};

export default MobileConfigMenuItem;
