import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const FilterContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 5px 20px rgba(138, 149, 158, 0.25);
  border-radius: 4px;
  margin: 10px;
  cursor: pointer;
`;

export const FilterUtils = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 14px 8px 14px;
`;

export const FilterName = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #53565a;
  margin-bottom: 0;
  padding: 10px;
  cursor: pointer;
`;

export const FiltersSubCategories = styled.div`
  width: 280px;
  position: absolute;
  top: 45px;
  border-radius: 4px;
  margin: 10px;
  background: #ffffff;
  box-shadow: 0 10px 30px 5px rgba(138, 149, 158, 0.3);
  z-index: 2;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 13px;
`;

export const ButtonActionOption = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
  line-height: normal;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  margin-bottom: 0;
`;
