import { device } from 'constants/responsiveDevice';
import { useMediaQuery } from 'react-responsive';
import icons from 'assets/Icon';

const ArrowStep = () => {
  const responsive = useMediaQuery({ query: device.ipad });

  return (
    <div className="custom-sm-box-col">
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <icons.GoBackIcon style={{ transform: `rotate(${responsive ? '-90deg' : '180deg'})` }} />
      </div>
    </div>
  );
};
export default ArrowStep;
