import * as React from 'react';
import TextInput from 'components/atoms/TextInput';
import TextInputMax from 'components/atoms/TextInputMax';
import InputSelect from 'components/atoms/InputSelect';
import Button from 'components/atoms/Button';
import {
  PathInfoContainer,
  WarningText,
  FormGroupStyled,
  FormLabelSimpleWrapper,
  FormInputWrapper,
  FormLabelSimple,
  ButtonsContainer,
  InfoText,
  SwitchContainer,
  SwitchLabel,
  InputSelectContainer,
  ButtonWrapper,
} from './styles';
import Switch from 'react-switch';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SelectedCourseListThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import AdminCoursesList from 'components/molecules/AdminCoursesList';
import { Control, UseFormHandleSubmit, Controller } from 'react-hook-form';
import { ListBadgesTemplateThunk } from 'actions/Thunks/AdminPathsThunk';
import SkillButton from 'components/atoms/SkillButton';
import { SelectedSkillsContainer } from 'components/molecules/AdminUploadNewCourseForm/styles';
import { arrayObjectIndexOf } from 'utils';

interface LearningPathDetailsProps {
  controlInfo: Control<IUpdatePath>;
  handleSubmitForm: UseFormHandleSubmit<IUpdatePath>;
  submitNewPathForm: (
    formInfo: IUpdatePath,
    skills: IListNewLearningExperienceSkillString[]
  ) => void;
  submitDraft: (formInfo: IUpdatePath) => void;
  errorsInfo: Record<string, any>;
  onSelectVisibility: (is_private: boolean) => void;
}

const LearningPathInfo = ({
  controlInfo,
  handleSubmitForm,
  submitNewPathForm,
  errorsInfo,
  onSelectVisibility,
  submitDraft,
}: LearningPathDetailsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [switchVisibility, handleVisibility] = React.useState<boolean>(false);
  const [skillsSelected, setSkills] = React.useState<IListNewLearningExperienceSkillString[]>([]);

  const coursesSelector = useAppSelector((state) => state.courses);
  const listBadgesTemplate = useAppSelector((state) => state.listBadgesTemp);
  const user = useAppSelector((state) => state.user);

  const initListCourseSelected = React.useCallback(async () => {
    if (coursesSelector.pathwayCreated) {
      const courseSelectedList = await dispatch(
        SelectedCourseListThunk(coursesSelector.pathwayCreated.id)
      );
      if (SelectedCourseListThunk.rejected.match(courseSelectedList)) {
        toast.error(courseSelectedList.payload as string);
      }
    }
  }, [coursesSelector.pathwayCreated, dispatch]);

  const initListSkill = React.useCallback(async () => {
    const badgesListTemplate = await dispatch(ListBadgesTemplateThunk());
    if (ListBadgesTemplateThunk.rejected.match(badgesListTemplate)) {
      toast.error(badgesListTemplate.payload as string);
    }
  }, [dispatch]);

  const setSkillsSelected = (skill: IListNewLearningExperienceSkillString) => {
    if (skillsSelected) {
      let skillHelper = skillsSelected;
      const verifiedSkill = skillHelper.find(
        (skillFiltered) => skillFiltered.label === skill.label
      );
      if (!verifiedSkill) {
        skillHelper = [...skillHelper, skill];
        setSkills(skillHelper);
      }
    }
  };

  const deleteSkillSelected = (skill: IListNewLearningExperienceSkillString) => {
    if (skillsSelected) {
      let skillHelper = skillsSelected;
      const index = arrayObjectIndexOf(skillHelper, skill.label, 'label');
      if (index > -1) {
        setSkills(skillHelper.filter((skillFilter) => skillFilter.label !== skill.label));
      }
    }
  };

  React.useEffect(() => {
    initListCourseSelected();
    initListSkill();
  }, [initListCourseSelected, initListSkill]);

  const setVisibility = (is_public: boolean) => {
    handleVisibility(is_public);
    onSelectVisibility(is_public);
  };

  return (
    <PathInfoContainer>
      <WarningText>
        Completa la siguiente información para crear una ruta de aprendizaje
      </WarningText>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Nombre</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="description">Descripción</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="description"
                placeholder="Describe tu ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                type={'textarea'}
                height={110}
                characterCounter={500}
                withError
              />
            )}
            name="description"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Visibilidad de la Ruta</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InfoText>
            Las rutas marcadas como públicas serán visibles en la plataforma para todas las
            organizaciones.
          </InfoText>
          <SwitchContainer>
            <SwitchLabel> Pública</SwitchLabel>
            <Switch
              onChange={() => setVisibility(!switchVisibility)}
              checked={switchVisibility}
              onColor={PRIMARY_COLOR}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={38}
            />
          </SwitchContainer>
        </FormInputWrapper>
      </FormGroupStyled>
      {user && user.userRole !== 3 ? (
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="labels">Asignar Insignia</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <InputSelectContainer>
              <Controller
                control={controlInfo}
                name="insignia"
                render={() => (
                  <div style={{ width: '100%' }}>
                    {listBadgesTemplate.listBadgesTemp && (
                      <InputSelect
                        onChange={setSkillsSelected}
                        value={skillsSelected}
                        options={listBadgesTemplate.listBadgesTemp}
                        placeholder="Buscar..."
                        error={errorsInfo}
                        name="insignia"
                      />
                    )}
                  </div>
                )}
              />
            </InputSelectContainer>
          </FormInputWrapper>
        </FormGroupStyled>
      ) : (
        <div></div>
      )}
      <SelectedSkillsContainer>
        {skillsSelected &&
          skillsSelected.map((newSkill, newSkillIndex) => {
            return (
              <SkillButton
                key={newSkillIndex}
                skill={newSkill.label}
                handleSkill={() => deleteSkillSelected(newSkill)}
              />
            );
          })}
      </SelectedSkillsContainer>
      <FormGroupStyled row>
        {coursesSelector && coursesSelector.selectedCourseList && (
          <AdminCoursesList courses={coursesSelector.selectedCourseList} />
        )}
      </FormGroupStyled>
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            label={'Borrador'}
            disabled={false}
            lightMode={true}
            widthCustom={150}
            onClick={handleSubmitForm(submitDraft)}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            label={'Publicar'}
            disabled={false}
            widthCustom={150}
            onClick={handleSubmitForm((formInfo) => submitNewPathForm(formInfo, skillsSelected))}
          />
        </ButtonWrapper>
      </ButtonsContainer>
    </PathInfoContainer>
  );
};

export default LearningPathInfo;
