import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Collapse, Label } from 'reactstrap';
import styled from 'styled-components';

export const CoursesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CoursesListWrapper = styled.div`
  width: 80%;
  border: 1px solid #53565a;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 30px 0px;
`;

export const CourseItemWrapper = styled.div<{
  $backgroundGray: boolean;
  $cursor: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: ${(props) => (props.$backgroundGray ? '#F9F9F9' : '#FFFFFF')};
  border-radius: 8px;
  cursor: ${(props) => (props.$cursor ? 'pointer' : 'auto')};
`;

export const CourseItemSessionsWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    margin-right: 10px;
  }
`;

export const CourseItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const CourseItemSessionsSubTitle = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const CourseSessionTime = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
`;

export const CourseTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  svg {
    margin-bottom: 20px;
  }
  span {
    font-family: NotoSansJP;
    font-size: 16px;
    line-height: 22px;
    color: #53565a;
  }
`;

export const CourseContainerSingle = styled(Collapse)`
  margin: 0 70px 10px 70px;
`;

export const CourseSessionWrapperSingle = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const CoursesSessionContentWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const CoursesSEssionLabelSigle = styled(Label)`
  margin-bottom: 0;
`;

export const CoursesSessionCountWrapper = styled.div`
  background-color: ${PRIMARY_COLOR};
  border-radius: 100px;
  margin-bottom: 0;
  margin-right: 18px;
  width: 22px;
  height: 22px;
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const CoursesSessionCount = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
`;
