import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const AdminSkillsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
  background: #f7f7f7;
  padding: 60px 0px;
`;

export const SkillsSectionTitle = styled(Label)`
  width: 80%;
  text-align: center;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
`;

export const SkillsWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
`;

export const SkillContainer = styled.div`
  background: #ffffff;
  border: 1px solid #53565a;
  box-sizing: border-box;
  border-radius: 15px;
  margin: 5px 10px;
`;

export const SkillName = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0px;
  padding: 5px 20px;
  color: #53565a;
`;
