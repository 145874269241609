import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import styles from './styles.module.css';

interface Props {
  socialNetwork: string;
  icon: any;
  total: number;
}

const CardShareBadgeResume = ({ socialNetwork, icon: Icon, total }: Props) => {
  return (
    <AcredittaBox borderRadius="25px">
      <div>
        <Icon />
      </div>
      <div>
        <AcredittaText variant="h2">
          <span className="mr-2">{total}</span>
          <AcredittaText variant="body1" className={styles.spanText} style={{ lineHeight: 0 }}>
            insignias
          </AcredittaText>
        </AcredittaText>
      </div>
      <div>
        <AcredittaText variant="body1" color="secondary" style={{ lineHeight: 0 }}>
          Compartidas en {socialNetwork}
        </AcredittaText>
      </div>
    </AcredittaBox>
  );
};

export default CardShareBadgeResume;
