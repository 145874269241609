import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateNewOrganizationThunk } from 'actions/Thunks/AdminThunk';
import { AdminDataThunk, UserDataThunk } from 'actions/Thunks/UserThunk';
import { RoleGuard } from 'utils/Routing/RoleGuard';

const USER = 'USER';

const initialState: IUser = {
  userInfo: null,
  adminInfo: null,
  userRole: null,
};

const userSlice = createSlice({
  name: USER,
  initialState,
  reducers: {
    updateAdminProfileIsCompleted(state:any, action: PayloadAction<any>){
      return {
        ...state, 
        userInfo: {
          ...state.userInfo, 
          organization: {
            ...state.userInfo.organization,
            name: action.payload.name
          }
        },
        adminInfo: {
          ...state.adminInfo, is_completed: action.payload.isCompleted
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(UserDataThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userInfo = payload;
        state.userRole = RoleGuard(payload);
      }
    });
    builder.addCase(CreateNewOrganizationThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.adminInfo = payload;
      }
    });
    builder.addCase(AdminDataThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.adminInfo = payload;
      }
    });
  },
});

export const {
  updateAdminProfileIsCompleted
} = userSlice.actions;

export default userSlice.reducer;
