import VideoCardCover from 'components/atoms/VideoCardCover';
import * as React from 'react';
import YouTube from 'react-youtube';
import { Modal, ModalHeader } from 'reactstrap';
import { logEvent } from 'utils/GA/analytics';

interface VideoCheckProps {
  src: string;
  title: string;
  changeStatus: () => void;
}

const VideoCheck: React.FC<VideoCheckProps> = ({ src, changeStatus, title }) => {
  const [modal, setModal] = React.useState<boolean>(false);

  const toggle = () => {
    logEvent('Onboarding', 'Play video', title);

    setModal(!modal);
  };
  const opts: any = {
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const closeModalFinishedVideo = () => {
    logEvent('Onboarding', 'Watch the full video', title);

    changeStatus();
    setModal(false);
  };

  return (
    <React.Fragment>
      <VideoCardCover openModal={toggle} />
      <Modal isOpen={modal} toggle={toggle} size="lg" centered={true}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <YouTube videoId={src} opts={opts} onEnd={closeModalFinishedVideo} />
      </Modal>
    </React.Fragment>
  );
};

export default VideoCheck;
