import ReactLoading from 'react-loading';
import styles from './styles.module.css';
import { themeConfig } from '../../../theme/config';
import clx from 'classnames';

type AcredittaButtonProps = {
  title: string;
  disabled?: boolean;
  isLoading?: boolean;
  onPress?: (e?: any) => void;
  type?: 'button' | 'submit';
  variant?: 'primary' | 'secondary' | 'small' | 'smallSecondary' | 'transparent';
  fullWidth?: boolean;
  icon?: any;
};

const AcredittaButton = ({
  type,
  disabled = false,
  isLoading = false,
  title = 'button',
  variant = 'primary',
  onPress,
  fullWidth = false,
  icon: Icon,
  ...rest
}: AcredittaButtonProps) => {
  const {
    colors: {
      primary: { white, blue },
    },
  } = themeConfig;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onPress}
      className={clx({
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.small]: variant === 'small',
        [styles.smallSecondary]: variant === 'smallSecondary',
        [styles.transparent]: variant === 'transparent',
      })}
      {...rest}
      style={
        disabled
          ? {
              background: 'rgba(0, 0, 0, 0.3)',
              border: 'none',
              color: 'white',
              width: fullWidth ? '100%' : 'none',
            }
          : { width: fullWidth ? '100%' : 'none' }
      }
    >
      <div className="d-flex justify-content-center">
        {isLoading && (
          <ReactLoading
            type="spin"
            width={20}
            height={20}
            color={variant === 'primary' ? white : blue}
            className={styles.loading}
          />
        )}
        {isLoading ? (
          <span>Procesando...</span>
        ) : (
          <>
            <span>{title}</span>

            {Icon && <Icon />}
          </>
        )}
      </div>
    </button>
  );
};

export default AcredittaButton;
