import { useState } from 'react';
import BadgeCard from './BadgeCard';
import BadgeMoreInfo from './BadgeMoreInfo';

interface BadgeRowProps {
  slice: any[];
  i: number;
}

const BadgeRow = ({ slice, i }: BadgeRowProps) => {
  const [show, setShow] = useState(false);
  const [currentItem, setCurrentItem] = useState({} as any);

  const handleCardClick = (item: any) => {
    setCurrentItem(item);
    setShow(true);
  };

  return (
    <>
      <div className="row" key={i}>
        {slice.map((item: any, idx: number) => (
          <BadgeCard
            elements={slice.length}
            item={item}
            idx={idx}
            key={idx}
            onPress={() => handleCardClick(item)}
          />
        ))}
      </div>
      <div style={{ display: show ? 'block' : 'none' }}>
        <BadgeMoreInfo onClose={() => setShow(false)} badge={currentItem} />
      </div>
    </>
  );
};

export default BadgeRow;
