import * as React from 'react';
import {
  MenuWrapper,
  MenuItemWrapper,
  MenuItemHeader,
  MenuItemTitle,
  MenuItemInfo,
} from './styles';
import icons from '../../../assets/Icon';
import { formatDate } from '../../../utils';

interface AdminLearningPathDescriptionMenuProps {
  start: Date;
  duration: number;
  users: number;
  level: string;
  content: number;
  language: string;
  methodology: string;
}

const AdminLearningCourseDescriptionMenu = ({
  start,
  duration,
  users,
  level,
  content,
  language,
  methodology,
}: AdminLearningPathDescriptionMenuProps): JSX.Element => {
  return (
    <MenuWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.CalendarIcon />
          <MenuItemTitle>Inicio</MenuItemTitle>
        </MenuItemHeader>
        <div>
          {start !== null ? (
            <MenuItemInfo>{formatDate(start)}</MenuItemInfo>
          ) : (
            <MenuItemInfo>Fecha flexible</MenuItemInfo>
          )}
        </div>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.TimeIcon />
          <MenuItemTitle>Duración</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{duration}</MenuItemInfo>
        </div>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.UserIcon />
          <MenuItemTitle>Usuarios</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{users}</MenuItemInfo>
        </div>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.LevelIcon />
          <MenuItemTitle>Nivel</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{level}</MenuItemInfo>
        </div>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.CoursesIcon />
          <MenuItemTitle>Costo</MenuItemTitle>
        </MenuItemHeader>
        <div>
          {content !== null ? (
            <MenuItemInfo>{content} USD</MenuItemInfo>
          ) : (
            <MenuItemInfo>{content}</MenuItemInfo>
          )}
        </div>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.LanguageIcon />
          <MenuItemTitle>Idioma</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{language}</MenuItemInfo>
        </div>
      </MenuItemWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.MarkBookIcon />
          <MenuItemTitle>Modalidad</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{methodology}</MenuItemInfo>
        </div>
      </MenuItemWrapper>
    </MenuWrapper>
  );
};

export default AdminLearningCourseDescriptionMenu;
