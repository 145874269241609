import UnauthLearningPathPreview from 'components/organisms/AdminLearningPathPreviewAcreUnauth';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';
import { useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const LearningPathPreviewUnauth: React.FC = () => {
  const history = useHistory();

  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  let { pathwayId } = useParams<{ pathwayId: string }>();
  if (auth.isAuthenticated === true) {
    if (user.userRole === 3) {
      history.push(`${AccreditedRole.learningPathPreview}/${pathwayId}`);
    } else {
      history.push(`${AdminRole.learningPathPreview}/${pathwayId}`);
    }
  }

  return (
    <React.Fragment>
      <UnauthLearningPathPreview pathwayId={pathwayId} />
    </React.Fragment>
  );
};

export default LearningPathPreviewUnauth;
