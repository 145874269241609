import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, Modal } from 'reactstrap';
import styled from 'styled-components';

export const CoursesWrappedSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

export const CoursesCardWrapper = styled.div`
  margin: 40px;
`;

export const CoursesSectionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
`;

export const RecomendedCoursesWrappedSection = styled.div`
  background: #f7f7f7;
`;

export const RecomendedCoursesCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RecomendedCoursesSectionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin: 40px;
`;

export const AdminCoverSection = styled.div`
  height: 500px;
  img {
    width: 100%;
    background-position: center;
    background-size: cover;
  }
`;

export const RouteInfoWrapper = styled.div`
  position: relative;
  top: -400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RouteTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
`;

export const RouteSubTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
`;

export const RouteShareTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  margin: 10px;
`;

export const ButtonWrapper = styled.div`
  margin: 40px 0px;
`;

export const RouteShareSectionWrapper = styled.div`
  color: white;
  svg {
    cursor: pointer;
  }
`;

export const ModalContainer = styled(Modal)`
  max-width: 900px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
  padding: 3% 20%;
  text-align: center;
`;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  padding: 0% 20%;
`;

export const ModalTextFile = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  padding: 0% 20%;
`;

export const ModalTextFooter = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  text-align: center;
`;

export const CSVIconWrapper = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

export const UploadFileWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 22px;
`;
export const CSVLabel = styled(Label)`
  background: #ebebeb;
  border-radius: 15px;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  padding: 6px 10px;
  cursor: pointer;
  margin-bottom: 0px;
`;
