import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const TermsAndConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TermsAndConditions = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin: 40px 0px;
    @media ${device.mobile} {
      width: 280px;
    }
  }
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const TermsTextContainer = styled.div`
  max-width: 70%;
  max-height: 286px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: ${PRIMARY_COLOR};
  overflow-y: auto;
  padding: 0px 20px;
  margin: 40px 0px;
`;

export const TermsPageTitle = styled(Label)`
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  color: ${PRIMARY_COLOR};
  margin: 20px 0px;
  text-align: center;
  @media ${device.mobile} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const TermsTitle = styled(Label)`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 20px;
`;

export const CheckBoxContainer = styled.div`
  margin: 20px 0px;
  text-align: center;
`;
