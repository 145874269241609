import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Container, Label, Col } from 'reactstrap';
import styled from 'styled-components';

export const CourseCatalogContainer = styled(Container)`
  flex: 1;
`;

export const ColStyled = styled(Col)`
  margin-top: 30px;
`;

export const CourseCatalogHeaderTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CourseCatalogTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
  margin-top: 50px;
`;

export const ContainerNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CourseCatalogSubTitles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 25px;
`;

export const SubtitleFirst = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;

export const SubtitleSecond = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
`;
