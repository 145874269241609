import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Form } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const Label = styled.div`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
  color: ${PRIMARY_COLOR};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
`;

export const PasswordRecovery = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  padding: 0px 10px;
  align-self: flex-end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 0px 16px;
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-top: 2em;

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const InputLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  @media ${device.ipad} {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const InputsLabel = styled(Label)`
  font-family: NotoSansJPBold;
  margin-left: 0px;
`;

export const Warning = styled.div`
  margin-bottom: 25px;
  margin-top: 25px;
  p {
    font-size: 14px;
    color: ${PRIMARY_COLOR};
    margin: 0px;
  }
`;
