import * as React from 'react';
import icons from 'assets/Icon';
import { S3_ADMIN } from 'constants/index';
import { UseFormWatch, Controller, Control } from 'react-hook-form';
import {
  DetailsContainer,
  LearningPathImageWrapper,
  PathCover,
  InputFileLabel,
  PathName,
  PathIconContainer,
  WarningContainer,
  WarningImageText,
  AdditionalInformationContainer,
  AdditionalInformationTitle,
  AdditionalInfoWrapper,
  AdditionalInfoLabel,
  RowStyled,
  ButtonWrapper,
  ErrorMessageLabel,
} from './styles';
import toast from 'react-hot-toast';
import { ImageValidation } from 'utils';
import { Input } from 'reactstrap';
import { useAppSelector } from 'hooks/redux';

interface LearningPathDetailsProps {
  watch: UseFormWatch<IUpdatePath>;
  controlInfo: Control<IUpdatePath>;
  errorsInfo: Record<string, any>;
}

const LearningPathDetails = ({
  controlInfo,
  errorsInfo,
  watch,
}: LearningPathDetailsProps): JSX.Element => {
  const user = useAppSelector((state) => state.user);

  const [showNewPhoto, setShowNewPhoto] = React.useState<any>(false);

  const file = watch().photo;

  const selectedPicture = React.useMemo(() => {
    if (file) {
      setShowNewPhoto(true);
      return URL.createObjectURL(file);
    } else {
      return `${S3_ADMIN}NoProfilePictureIcon.svg`;
    }
  }, [file]);

  const route_name = watch().name;

  const route = React.useMemo(() => {
    if (user.userInfo?.first_name !== null) {
      return user.userInfo?.first_name + ' ' + user.userInfo?.last_name;
    }
    if (route_name) {
      return route_name;
    } else {
      if (user.userInfo?.first_name !== null) {
        return user.userInfo?.first_name + ' ' + user.userInfo?.last_name;
      } else {
        return 'Nombre de la Ruta';
      }
    }
  }, [user.userInfo?.first_name, user.userInfo?.last_name, route_name]);

  return (
    <DetailsContainer>
      <PathName>{route}</PathName>
      <PathIconContainer>
        <LearningPathImageWrapper>
          {showNewPhoto ? (
            <PathCover src={selectedPicture} alt="imagen-portada-ruta" />
          ) : user.userInfo?.avatar !== null ? (
            <PathCover src={user.userInfo?.avatar} alt="imagen-portada-ruta" />
          ) : (
            <PathCover src={selectedPicture} alt="imagen-portada-ruta" />
          )}
        </LearningPathImageWrapper>
        <Controller
          control={controlInfo}
          render={({ field: { onChange } }) => (
            <ButtonWrapper>
              <InputFileLabel>
                <Input
                  name="photo"
                  onChange={async (value) => {
                    const files = value.target.files;
                    const fileList: File[] = Array.prototype.slice.call(files);

                    if (await ImageValidation(fileList)) {
                      return onChange(fileList[0]);
                    } else {
                      toast.error(
                        'La imagen debe cumplir con los criterios de formato y dimensión'
                      );
                    }
                  }}
                  type="file"
                  accept="image/*"
                />
                <span>Cargar Portada</span>
              </InputFileLabel>
              {errorsInfo['photo']?.type && (
                <ErrorMessageLabel>{errorsInfo['photo']?.message}</ErrorMessageLabel>
              )}
            </ButtonWrapper>
          )}
          name="photo"
        />
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>
            Imagen formato PNG/JPG con dimensión máxima de 1200x1200 pixeles
          </WarningImageText>
        </WarningContainer>
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>Esta imagen será la foto de portada de tu ruta</WarningImageText>
        </WarningContainer>
      </PathIconContainer>
      <AdditionalInformationContainer>
        <AdditionalInformationTitle>Información Adicional</AdditionalInformationTitle>
        <AdditionalInfoWrapper>
          <AdditionalInfoLabel>Organización</AdditionalInfoLabel>
          <RowStyled>
            <h1>-</h1>
          </RowStyled>
        </AdditionalInfoWrapper>
        <AdditionalInfoWrapper>
          <AdditionalInfoLabel>Cargo Actual</AdditionalInfoLabel>
          <RowStyled>
            <h1>-</h1>
          </RowStyled>
        </AdditionalInfoWrapper>
        <AdditionalInfoWrapper>
          <AdditionalInfoLabel>Correo</AdditionalInfoLabel>
          <RowStyled>
            <h1>-</h1>
          </RowStyled>
        </AdditionalInfoWrapper>
      </AdditionalInformationContainer>
    </DetailsContainer>
  );
};

export default LearningPathDetails;
