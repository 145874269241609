import * as React from 'react';
import {
  BadgeContainer,
  BadgeTitle,
  BadgeCompany,
  InfoIconWrapper,
  ImageWrapper,
  ShareContainer,
  AceptedBadge,
  AceptedBadgeLabel,
  ExpiredBadgeLabel,
  ExpiredBadgeCompany,
  BadgeContainerClose,
} from './styles';
import icons from 'assets/Icon';
import { AdminRole, AccreditedRole, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { GetAcceptBadgeThunk, PostEditBadgeThunk } from 'actions/Thunks/AcreditedThunk';

interface BadgeProps {
  id: any;
  name: any;
  company: any;
  image: any;
  badge_organization?: any;
  nonAuth?: any;
  typeBadge: boolean;
  isPublicBadge: boolean;
  date: string;
  dateExpired: string;
  url: string;
}

const Badge = ({
  name,
  image,
  company,
  id,
  badge_organization,
  nonAuth,
  isPublicBadge,
  typeBadge,
  date,
  dateExpired,
  url,
}: BadgeProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  const handleBadgeDetail = (id: string) => {
    if (nonAuth) {
      history.push(`${NonAuthenticatedUrls.badges_template}/${id}`);
    } else {
      if (badge_organization) {
        if (user.userInfo && user.userInfo.user_type.code === 'ACCREDITED') {
          history.push(`${AccreditedRole.organizationBadgeDetail}/${id}`);
        } else {
          history.push(`${AdminRole.organizationBadgeDetail}/${id}`);
        }
      } else {
        if (user.userInfo && user.userInfo.user_type.code === 'ACCREDITED') {
          history.push(`${AccreditedRole.badgeDetails}/${id}`);
        } else {
          history.push(`${AdminRole.badgeDetails}/${id}`);
        }
      }
    }
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(`${url}${id}`);
    toast.success('Url de insignia copiada correctamente');
  };

  const AcceptBadge = React.useCallback(
    async (id: string) => {
      const accept = await dispatch(GetAcceptBadgeThunk(id));
      if (GetAcceptBadgeThunk.rejected.match(accept)) {
        toast.error(accept.payload as string);
      } else {
        history.go(0);
        toast.success('Insignia aceptada correctamente');
      }
    },
    [dispatch, history]
  );

  const PublicBadge = React.useCallback(
    async (id: string, info: boolean) => {
      let content = {
        uuid: id,
        info: info,
      };
      const publicBadge = await dispatch(PostEditBadgeThunk(content));
      if (PostEditBadgeThunk.rejected.match(publicBadge)) {
        toast.error(publicBadge.payload as string);
      } else {
        history.go(0);
      }
    },
    [dispatch, history]
  );

  return (
    <div>
      {typeBadge === true ? (
        <BadgeContainer>
          <AceptedBadge onClick={() => AcceptBadge(id)}>
            <AceptedBadgeLabel>Aceptar Insignia</AceptedBadgeLabel>
          </AceptedBadge>
          <InfoIconWrapper onClick={() => handleBadgeDetail(id)}>
            <BadgeCompany>{name}</BadgeCompany>
          </InfoIconWrapper>
          <ImageWrapper onClick={() => handleBadgeDetail(id)}>
            <img src={image} alt={`${name}-badge`} />
          </ImageWrapper>
          <ShareContainer>
            {/* <icons.Icono_badge_acepted
              style={{
                cursor: 'pointer',
              }}
            /> */}
            <div></div>
            <BadgeTitle>{company}</BadgeTitle>
            <icons.UploadFileIcon
              onClick={() => copyUrl()}
              style={{
                cursor: 'pointer',
              }}
            />
          </ShareContainer>
        </BadgeContainer>
      ) : isPublicBadge === true ? (
        <BadgeContainer>
          <AceptedBadge>
            {dateExpired === null ? (
              <div>
                <div></div>
                <icons.Icon_eye_close
                  onClick={() => PublicBadge(id, false)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '120px',
                  }}
                />
              </div>
            ) : (
              <div>
                <ExpiredBadgeLabel>Expirada el {dateExpired}</ExpiredBadgeLabel>
                <icons.Icon_eye_close
                  onClick={() => PublicBadge(id, false)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '14px',
                  }}
                />
              </div>
            )}
          </AceptedBadge>
          <InfoIconWrapper onClick={() => handleBadgeDetail(id)}>
            <ExpiredBadgeCompany>{name}</ExpiredBadgeCompany>
          </InfoIconWrapper>
          <ImageWrapper onClick={() => handleBadgeDetail(id)}>
            <img src={image} alt={`${name}-badge`} />
          </ImageWrapper>
          <ShareContainer>
            {/* <icons.Star
              style={{
                cursor: 'pointer',
              }}
            /> */}
            <div></div>
            <BadgeTitle>{company}</BadgeTitle>
            <icons.UploadFileIcon
              onClick={() => copyUrl()}
              style={{
                cursor: 'pointer',
              }}
            />
          </ShareContainer>
        </BadgeContainer>
      ) : (
        <BadgeContainerClose>
          <AceptedBadge>
            <icons.Icon_eye_open
              onClick={() => PublicBadge(id, true)}
              style={{
                cursor: 'pointer',
                marginLeft: '130px',
              }}
            />
          </AceptedBadge>
          <InfoIconWrapper onClick={() => handleBadgeDetail(id)}>
            <BadgeCompany>{name}</BadgeCompany>
          </InfoIconWrapper>
          <ImageWrapper onClick={() => handleBadgeDetail(id)}>
            <img src={image} alt={`${name}-badge`} />
          </ImageWrapper>
          <ShareContainer>
            <div
              style={{
                marginLeft: '30px',
              }}
            ></div>
            <BadgeTitle>{company}</BadgeTitle>
            <icons.UploadFileIcon
              onClick={() => copyUrl()}
              style={{
                cursor: 'pointer',
              }}
            />
          </ShareContainer>
        </BadgeContainerClose>
      )}
    </div>
  );
};

export default Badge;
