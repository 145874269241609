import * as React from 'react';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import {
  ShareBadgeCodeContainer,
  ShareBadgeCodeTitle,
  InputWrapper,
  InputLabelWrapper,
  InputsLabel,
  ButtonsWrapper,
} from './styles';
import { UncontrolledTooltip } from 'reactstrap';
import { GetBadgeJsonThunk } from 'actions/Thunks/ShareRRSSThunk';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';

interface ShareBadgeCodeProps {
  badge_id: string;
  closeShareSection: () => void;
}

const ShareBadgeCode = ({ badge_id, closeShareSection }: ShareBadgeCodeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [jsonUnAuthUser, setJsonUnAuthUser] = React.useState<any>('');

  const getJsonUnAuthUser = React.useCallback(async () => {
    const shareBadgeData = await dispatch(GetBadgeJsonThunk(badge_id));
    if (GetBadgeJsonThunk.rejected.match(shareBadgeData)) {
      toast.error(shareBadgeData.payload as string);
    } else {
      setJsonUnAuthUser(JSON.stringify(shareBadgeData.payload));
    }
  }, [badge_id, dispatch]);

  React.useEffect(() => {
    getJsonUnAuthUser();
  }, [getJsonUnAuthUser]);

  return (
    <div>
      <ShareBadgeCodeContainer
        onClick={() => {
          navigator.clipboard.writeText(`${jsonUnAuthUser}`);
        }}
        id="copy-code"
      >
        <ShareBadgeCodeTitle>Copiar JSON</ShareBadgeCodeTitle>
        <InputWrapper>
          <InputLabelWrapper>
            <InputsLabel $height={15}>JSON</InputsLabel>
          </InputLabelWrapper>
          <UncontrolledTooltip
            placement="bottom"
            target="copy-code"
            trigger="click"
            autohide={true}
          >
            Texto copiado al portapapeles
          </UncontrolledTooltip>
          <TextInput
            error={{}}
            name="badgeLink"
            height={90}
            value={`${jsonUnAuthUser}`}
            disabled
            type="textarea"
            pointer={true}
          />
        </InputWrapper>
      </ShareBadgeCodeContainer>
      <ButtonsWrapper>
        <Button label="Cancelar" widthCustom={150} lightMode={true} onClick={closeShareSection} />
        <Button
          label="Copiar"
          widthCustom={150}
          onClick={() => {
            navigator.clipboard.writeText(`${jsonUnAuthUser}`);
          }}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default ShareBadgeCode;
