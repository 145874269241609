import styled from 'styled-components';
import { BLUE_LIGHT, GREEN } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const ProgressMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
`;

export const ItemMenuWrapper = styled.div`
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.mediumLaptop} {
    width: 50px;
  }
  @media ${device.bigMobile} {
    width: 30px;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div<{
  $selected: boolean;
}>`
  color: ${(props) => (props.$selected ? GREEN : BLUE_LIGHT)};
  display: flex;
  align-items: center;
`;

export const RectangleWrapper = styled.div`
  margin-bottom: 14%;
  width: 207px;
  height: 3px;
  left: 234px;
  top: 127px;
  background: #53565a;
  border-radius: 4px;
  @media ${device.desktop} {
    width: 177px;
    margin-bottom: 18%;
  }
  @media ${device.laptops} {
    width: 130px;
  }
  @media ${device.mediumLaptop} {
    width: 100px;
    margin-bottom: 24%;
  }
  @media ${device.ipad} {
    width: 80px;
    margin-bottom: 28%;
  }
  @media ${device.tablet} {
    width: 75px;
    margin-bottom: 28%;
  }
  @media ${device.bigMobile} {
    width: 70px;
    margin-bottom: 36%;
  }
  @media ${device.mobile} {
    width: 65px;
    margin-bottom: 4%;
  }
  @media ${device.mediumMobile} {
    width: 60px;
  }
  @media only screen and (max-width: 400px) {
    width: 50px;
  }
  @media only screen and (max-width: 350px) {
    width: 40px;
  }
  @media ${device.smallMobile} {
    width: 30px;
  }
`;

export const ItemMenuTitle = styled.span<{
  $selected: boolean;
}>`
  width: 90px;
  font-family: NotoSansJPBold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin-top: 4px;
  color: ${(props) => (props.$selected ? GREEN : BLUE_LIGHT)};
`;
