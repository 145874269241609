import {
  Field,
  ErrorMessage,
  FieldArray,
  useFormikContext,
  FormikContextType,
  FormikValues,
} from 'formik';
import icons from 'assets/Icon';
// import Switch from 'react-switch';
import BadgeEditImage from './BadgeEditImage';
import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaFormLabel from 'components/atoms/AcredittaFormLabel';
import AcredittaSkillsSelector from 'components/atoms/AcredittaSkillsSelector';
import AcredittaButton from 'components/atoms/AcredittaButton';
import additionalInfo from 'constants/additional-info';
import useGetLevels from '../../../hooks/useGetLevels';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AcredittaText from 'components/atoms/AcredittaText';
import { useContext, useEffect, useRef } from 'react';
import { BadgeContext } from './BadgeContext';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';

const BadgeEditForm = () => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
  }: FormikContextType<FormikValues> = useFormikContext();
  const { setSkills, isSaving } = useContext(BadgeContext);
  const { cost, type, duration } = additionalInfo;
  const { levels, isLevelFetching } = useGetLevels();
  const mounted = useRef(true);
  const { push } = useHistory();

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const handleUpdateSkills = (values: any) => {
    if (mounted.current) {
      setSkills(values);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-4" style={{ padding: '40px' }}>
          <BadgeEditImage />

          <AcredittaText
            variant="h4"
            color="primary"
            align="center"
            style={{ marginBottom: '30px' }}
          >
            Información adicional
          </AcredittaText>

          <div className="row mb-4">
            <div className="col-3">
              <AcredittaText variant="h5" align="right">
                Tipo
              </AcredittaText>
            </div>
            <div className="col-9">
              <div className="row">
                {type.map((item, i) => (
                  <div className="col-6">
                    <label>
                      <Field type="radio" name="tipo" value={String(item.value)} />
                      <span className="small pl-2">{item.label}</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-3">
              <AcredittaText variant="h5" align="right">
                Nivel
              </AcredittaText>
            </div>
            <div className="col-9">
              {isLevelFetching && <AcredittaLoader height={22} width={22} isTextDisabled={true} />}

              {!isLevelFetching && (
                <div className="row">
                  {levels.map((item, i) => (
                    <div className="col-6">
                      <label>
                        <Field type="radio" name="nivel" value={String(item.id)} />
                        <span className="small pl-2">{item.name}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-3">
              <AcredittaText variant="h5" align="right">
                Duración
              </AcredittaText>
            </div>
            <div className="col-9">
              <div className="row">
                {duration.map((item, i) => (
                  <div className="col-6">
                    <label>
                      <Field type="radio" name="duracion" value={String(item.value)} />
                      <span className="small pl-2">{item.label}</span>
                    </label>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col">
                  <Field
                    name="durationTime"
                    placeholder="Indica el número"
                    className="form-control"
                    values={values.durationTime}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-3">
              <AcredittaText variant="h5" align="right">
                Costo
              </AcredittaText>
            </div>
            <div className="col-9">
              <div className="row">
                {cost.map((item, i) => (
                  <div className="col-6">
                    <label>
                      <Field type="radio" name="costo" value={String(item.value)} />
                      <span className="small pl-2">{item.label}</span>
                    </label>
                  </div>
                ))}
              </div>
              {values.costo === String(2) && (
                <div className="row">
                  <div className="col">
                    <Field
                      name="costAmount"
                      value={values.costAmount}
                      placeholder="Indica el costo"
                      className="form-control"
                    />
                    <ErrorMessage
                      component="span"
                      name="costAmount"
                      className="custom-text-error"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-8" style={{ padding: '80px' }}>
          <AcredittaBox>
            <AcredittaText variant="h4" style={{ marginBottom: '10px' }}>
              Información de la insignia
            </AcredittaText>
            <div className="row mb-3">
              <div className="col-3 text-right">
                <AcredittaFormLabel text="Nombre*" />
              </div>
              <div className="col-9">
                <Field
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  value={values.nombre}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.nombre && touched.nombre ? 'is-invalid' : ''}`}
                />
                <ErrorMessage component="span" name="nombre" className="custom-text-error" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-3 text-right">
                <AcredittaFormLabel text="Descripción*" />
              </div>
              <div className="col-9">
                <Field
                  as="textarea"
                  id="descripcion"
                  name="descripcion"
                  placeholder="Descripción*"
                  value={values.descripcion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ resize: 'none', height: "100px" }}
                  className={`form-control ${
                    errors.descripcion && touched.descripcion ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage component="span" name="descripcion" className="custom-text-error" />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-3 text-right">
                <AcredittaFormLabel text="Habilidades" />
              </div>
              <div className="col-9">
                <AcredittaSkillsSelector
                  onChangeInputValues={handleUpdateSkills}
                  loadOptionsValues={values.habilidades}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <FieldArray
                  name="requisitos"
                  render={(arrayHelpers) => (
                    <>
                      <div className="row mb-3">
                        <div className="col-3">
                          <AcredittaFormLabel text="Requisitos*" />
                        </div>
                        <div className="col-6 pt-2">
                          <span className="help-block">
                            Define los requisitos que se deben cumplir para obtener la insignia
                          </span>
                          {values.requisitos.length === 0 && (
                            <ErrorMessage
                              component="span"
                              name="requisitos"
                              className="custom-text-error"
                            />
                          )}
                        </div>
                        <div className="col-3 pt-2 text-right">
                          <button
                            type="button"
                            onClick={() => arrayHelpers.push({ id: null, name: '' })}
                            style={{
                              background: 'none',
                              border: 'none',
                              color: '#DA291C',
                              fontWeight: 700,
                              fontSize: '14px',
                              cursor: 'pointer',
                            }}
                          >
                            Añadir +
                          </button>
                        </div>
                      </div>
                      {values.requisitos &&
                        values.requisitos.length > 0 &&
                        values.requisitos.map((requisito: any, index: number) => (
                          <div key={index} className="row mb-3">
                            <div className="col-3">
                              <AcredittaFormLabel text={`Requisito ${index + 1}`} />
                            </div>
                            <div className="col-8">
                              <Field
                                name={`requisitos.[${index}].name`}
                                className={`form-control`}
                                placeholder="Ingrese el valor"
                                value={requisito.name}
                              />

                              <ErrorMessage
                                component="span"
                                name={`requisitos.[${index}].name`}
                                className="custom-text-error"
                              />
                            </div>
                            <div className="col-1 mt-2">
                              <span
                                onClick={() => arrayHelpers.remove(index)}
                                style={{ cursor: 'pointer' }}
                              >
                                <icons.DeleteBlueIcon />
                              </span>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-3 text-right">
                <AcredittaFormLabel text="URL Información adicional*" />
              </div>
              <div className="col-9">
                <Field
                  id="url"
                  name="url"
                  placeholder="URL información adicional"
                  value={values.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`form-control ${errors.url && touched.url ? 'is-invalid' : ''}`}
                />
                <ErrorMessage component="span" name="url" className="custom-text-error" />
              </div>
            </div>

            {/* REMOVE IN THIS FEATURE
            <div className="row mb-3">
              <div className="col-3 text-right">
                <AcredittaFormLabel text="Visibilidad" />
              </div>
              <div className="col-9">
                <div>
                  <p className="small">
                    Las insignias marcadas como públicas se mostrarán en el catálogo de insignias de
                    la plataforma.
                  </p>
                </div>
                <span className="small mr-4">Privada</span>
                <Field
                  as={Switch}
                  checked={values.visibilidad ? values.visibilidad : false}
                  id="visibilidad"
                  name="visibilidad"
                  value={values.visibilidad}
                  onChange={(value: any) => setFieldValue('visibilidad', value)}
                  onBlur={handleBlur}
                />
                <span className="small ml-4">Pública</span>
              </div>
            </div> */}
            <div className="mb-3 text-center">
              <AcredittaButton
                title="Cancelar"
                type="button"
                variant="secondary"
                onPress={() => push(AdminRole.badgesPreview)}
              />
              <AcredittaButton
                title="Guardar"
                type="submit"
                variant="primary"
                isLoading={isSaving}
              />
            </div>
          </AcredittaBox>
        </div>
      </div>
    </form>
  );
};

export default BadgeEditForm;
