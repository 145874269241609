import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  SubtitleFirst,
  SubtitleSecond,
} from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  GetBadgesListTemplatesArchivedThunk,
  GetBadgesListTemplatesThunk,
} from 'actions/Thunks/AdminPathsThunk';
import Pagination from 'components/molecules/Pagination';
import toast from 'react-hot-toast';
import { AdminDataThunk, GetValidatePlanPathsThunk } from 'actions/Thunks/UserThunk';
import 'react-phone-input-2/lib/style.css';
import AdminPreviewBadgesArchived from '../AdminPreviewBadgesArchived';
import AdminPreviewBadgesTable from 'components/molecules/AdminPreviewBadgesTable';
import { objectValidation } from 'utils/index';
import { useForm } from 'react-hook-form';
import AdminIssueBadge from 'components/molecules/AdminIssueBadge/AdminIssueBadge';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import ReactPaginate from 'react-paginate';
import { getPageCount } from 'helpers';
import { getBadgeList } from 'services/badges.service';
import { BadgeResult } from '../../../interfaces/badge-list-response';
interface SearchBarForm {
  search: string;
}

const AdminPreviewBadges: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams() as any;
  const [currentPageArchived, setCurrentPageArchived] = React.useState(1);

  // si no tengo el id cargo el listado sino la emision
  const [modalIssueBadge, setModalIssueBadge] = React.useState({
    open: typeof id === 'undefined' ? false : true,
    id,
  });

  const BadgesTemplatesArchived = useAppSelector((state) => state.admin.badgeTemplatesArchived);
  const defaultValuesSearch: SearchBarForm = {
    search: '',
  };

  const getDefaultFormSearch = (): SearchBarForm => {
    return defaultValuesSearch;
  };

  const { reset } = useForm<SearchBarForm>({
    ...objectValidation,
    defaultValues: getDefaultFormSearch(),
  });

  const getListBadges = React.useCallback(async () => {
    const badgesList = await dispatch(GetBadgesListTemplatesThunk({}));
    if (GetBadgesListTemplatesThunk.rejected.match(badgesList)) {
      toast.error(badgesList.payload as string);
    }

    const badgesListArchived = await dispatch(GetBadgesListTemplatesArchivedThunk({}));
    if (GetBadgesListTemplatesArchivedThunk.rejected.match(badgesListArchived)) {
      toast.error(badgesListArchived.payload as string);
    }
  }, [dispatch]);

  const getAdminInfo = React.useCallback(async () => {
    const adminInfoData = await dispatch(AdminDataThunk());
    if (AdminDataThunk.rejected.match(adminInfoData)) {
      toast.error(adminInfoData.payload as string);
    }
  }, [dispatch]);

  const newBadge = React.useCallback(async () => {
    const plan = await dispatch(GetValidatePlanPathsThunk('/create-badge/'));
    if (GetValidatePlanPathsThunk.rejected.match(plan)) {
      history.push(AdminRole.createBadge);
    } else {
      history.push(AdminRole.createBadge);
    }
  }, [dispatch, history]);

  const onFocus = React.useCallback(async () => {
    getListBadges();
  }, [getListBadges]);

  const onBlur = React.useCallback(async () => {
    getListBadges();
  }, [getListBadges]);

  const handleSearchPageArchived = async (page: number) => {
    setCurrentPageArchived(page);
    const courses = await dispatch(
      GetBadgesListTemplatesArchivedThunk({
        page: page,
      })
    );
    if (GetBadgesListTemplatesThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
    reset();
  };

  const navigationIssueId = (id: string) => {
    setModalIssueBadge({
      open: true,
      id: id,
    });
  };

  const [data, setData] = React.useState<BadgeResult[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [activePage, setActivePage] = React.useState(1);
  const [pageOffset, setPageOffset] = React.useState(0);
  const [fetching, setFetching] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        window.scrollTo(0, 0);
        setFetching(true);
        const resp = await getBadgeList({}, activePage);
        setPageOffset(activePage - 1);
        setPageCount(getPageCount(resp.data.count));
        console.log(getPageCount(resp.data.count));
        setData(resp.data.results);
      } catch (e) {
        console.error(e);
      } finally {
        setFetching(false);
      }
    })();
  }, [activePage]);

  React.useEffect(() => {
    getListBadges();
    getAdminInfo();
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [getListBadges, getAdminInfo, onFocus, onBlur]);

  const handlePageChange = (selected: number) => {
    setActivePage(selected + 1);
  };

  return (
    <React.Fragment>
      <CommonPageLayout
        showHeader={true}
        showFooter={false}
        childrenMargin={false}
        showMenuHeader={true}
        showMenuHeaderAcredited={false}
        showCommonMenu={false}
        showCommon={true}
        showOnboarding={false}
      >
        <div>
          {!modalIssueBadge.open ? (
            <>
              <CourseCatalogContainer>
                <CourseCatalogHeaderTitles>
                  <CourseCatalogSubTitles>
                    <SubtitleFirst>Insignias de la organización</SubtitleFirst>
                    <SubtitleSecond
                      onClick={() => {
                        newBadge();
                      }}
                    >
                      Crear una Insignia +
                    </SubtitleSecond>
                  </CourseCatalogSubTitles>
                </CourseCatalogHeaderTitles>
                {data && (
                  <>
                    <AdminPreviewBadgesTable
                      navigationIssueId={navigationIssueId}
                      dataTable={data}
                      onFinishDelete={() => setActivePage(0)}
                      loading={fetching}
                    />
                    <nav style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }}>
                      <ReactPaginate
                        activeClassName="active"
                        breakClassName="page-item"
                        breakLinkClassName="custom-page-link"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        containerClassName="pagination"
                        previousClassName="custom-nav-link"
                        nextClassName="custom-nav-link"
                        nextLabel=">"
                        previousLabel="<"
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        onPageChange={({ selected }) => handlePageChange(selected)}
                        forcePage={pageOffset}
                        renderOnZeroPageCount={() => null}
                      />
                    </nav>
                  </>
                )}
              </CourseCatalogContainer>
              {BadgesTemplatesArchived ? (
                <div
                  style={{
                    marginBottom: '110px',
                  }}
                >
                  <AdminPreviewBadgesArchived dataTable={BadgesTemplatesArchived.results} />
                  <Pagination
                    currentPage={currentPageArchived}
                    totalCount={BadgesTemplatesArchived.count}
                    pageSize={20}
                    onPageChange={(page: number) => handleSearchPageArchived(page)}
                  />
                </div>
              ) : (
                <AcredittaLoader text="Cargando..." />
              )}
            </>
          ) : (
            <AdminIssueBadge
              selectIssuedId={modalIssueBadge.id}
              setModalIssueBadge={setModalIssueBadge}
            />
          )}
        </div>
      </CommonPageLayout>
    </React.Fragment>
  );
};

export default AdminPreviewBadges;
