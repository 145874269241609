import AcredittaRequest from 'actions/requests/api';

interface PayloadI {
  resource: string;
  resourceType: string;
  utmSource: string;
}

export const analyticsLinkShare = async (payload: PayloadI) => {
  const { resource, resourceType, utmSource } = payload;
  return await AcredittaRequest.post(`/analytics/link-share/`, {
    resource: resource,
    resource_type: resourceType,
    utm_source: utmSource,
  });
};

/**
 * Get data share badges
 * @returns Promise
 */
export const getShareData = async () => {
  return await AcredittaRequest.get(`organization/shares-statistics`);
};
