import icons from 'assets/Icon';
import * as React from 'react';
import { Nav } from 'reactstrap';
import TopPageMenu from '../TopPageMenu';
import { LateralMenuContainer, NavItemStyled, NavLinkStyled, ProfileImage, MenuContainer } from './styles';
import MockIcon from 'assets/images/MockIcon.png';

interface LateralMenuProps {
  showTopMenu?: boolean;
}

const LateralMenu: React.FC<LateralMenuProps> = ({ showTopMenu }) => {
  return (
    <MenuContainer>
      <LateralMenuContainer>
        <ProfileImage src={MockIcon} alt="imagen-de-perfil" />
        <Nav vertical>
          <NavItemStyled>
            <icons.AnalyticsIcon />
            <NavLinkStyled to="#">ANALYTICS</NavLinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <icons.BadgeIcon />
            <NavLinkStyled to="#">INSIGNIAS</NavLinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <icons.RutasIcon />
            <NavLinkStyled to="#">RUTAS</NavLinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <icons.UsersIcon />
            <NavLinkStyled to="#">USUARIOS</NavLinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <icons.SettingsIcon />
            <NavLinkStyled to="#">PREFERENCIAS</NavLinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <icons.UserSettingsIcon />
            <NavLinkStyled to="#">CUENTA</NavLinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <icons.UserIcon />
            <NavLinkStyled to="#">VER PERFIL</NavLinkStyled>
          </NavItemStyled>
        </Nav>
      </LateralMenuContainer>
      {showTopMenu && <TopPageMenu />}
    </MenuContainer>
  );
};

export default LateralMenu;
