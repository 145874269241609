import icons from 'assets/Icon';
import styles from './styles.module.css';
import { useAppSelector } from 'hooks/redux';
import { BadgeDetail } from 'interfaces/badge-detail';
import AcredittaText from 'components/atoms/AcredittaText';

interface Props {
  badge: BadgeDetail;
  uri: string;
}

const ShareOnLinkedinProfileButton = ({ badge, uri }: Props) => {
  const { adminInfo } = useAppSelector((state) => state.user);

  const handleOptionAddToMyProfile = () => {
    const [, monthIssued, yearIssued] = String(badge.issued_at).split('-');

    let uriToShare = `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${badge.badge_template.name}&organizationName=${badge.issuer_organization}&issueYear=${yearIssued}&issueMonth=${monthIssued}&certId=${badge.id}&certUrl=${uri}/${badge.id}`;

    if (typeof badge.expires_at === 'string' && badge.expires_at.trim().length > 0) {
      const [, monthExpired, yearExpired] = String(badge.expires_at).split('-');
      uriToShare += `&expirationYear=${yearExpired}&expirationMonth=${monthExpired}`;
    }

    if (typeof adminInfo?.linkedin_id === 'string' && adminInfo?.linkedin_id.trim().length > 0) {
      uriToShare += `&organizationId=${adminInfo?.linkedin_id}`;
    }

    window.open(uriToShare, '_blank');
  };

  return (
    <div className={styles.btnBlue} onClick={handleOptionAddToMyProfile}>
      <AcredittaText variant="body1" color="white" align="center" fontWeight="bold">
        <icons.IconLinkedinWhite />
        <span className="ml-3">Agregar a mi perfil</span>
      </AcredittaText>
    </div>
  );
};

export default ShareOnLinkedinProfileButton;
