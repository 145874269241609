import { SECONDARY_COLOR } from 'assets/styles/colors';
import styled from 'styled-components';

export const CheckboxCont = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  align-items: center;
  justify-content: center;
`;

export const FormLabelText = styled.span`
  color: ${SECONDARY_COLOR};
  font-size: 12px;
`;
