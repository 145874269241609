import icons from 'assets/Icon';
import { SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Input, Label } from 'reactstrap';
import styled from 'styled-components';

export const UploadMainContainer = styled.div`
  position: relative;
  bottom: 6px;
  display: flex;
  width: 100%;
  justify-content: center;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const UploadFileContainer = styled.div`
  display: flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${device.mobile} {
    margin-left: 0;
  }
`;

export const UploadFileIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  height: 50px;
`;

export const UploadFileIcon = styled(icons.DocumentIcon)``;

export const UploadFileWrapperInput = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
`;

export const UploadFileInputLabel = styled.label`
  border-radius: 0 0 8px 8px;
  background: #002057;
  padding: 4px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: NotoSansJPBold;
  font-size: 14px;
  color: #ebebeb;
  margin-bottom: 0;
  height: 30px;
  width: 100%;
  input[type='file'] {
    display: none;
  }
`;

export const UploadFileInput = styled(Input)``;

export const LabelFileSelected = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold;
`;

export const InvalidLabel = styled(Label)`
  color: ${SECONDARY_COLOR};
`;

export const UploadProgressWrapper = styled.div`
  width: 30%;
  padding-top: 8px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const FileSelectedWrapper = styled.div`
  display: flex;
  align-self: center;
`;
