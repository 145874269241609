import * as React from 'react';
import { Controller } from 'react-hook-form';
import TextInput from 'components/atoms/TextInput';
import { useForm } from 'react-hook-form';
import { objectValidation } from 'utils';
import { emailRegex } from 'utils';
import Button from 'components/atoms/Button';
import { ShareBadgeEmailThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';
import {
  InputWrapper,
  InputLabelWrapper,
  InputsLabel,
  ShareBadgeEmailContainer,
  ShareBadgeEmailTitle,
  ButtonsWrapper,
  ErrorLabel,
} from './styles';

interface ShareBadgeEmailProps {
  closeShareSection: () => void;
  badgeId: string;
}

const ShareBadgeEmail = ({ closeShareSection, badgeId }: ShareBadgeEmailProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const defaultValues: IShareBadgeEmail = {
    email: '',
    subject: '¡Mira la insignia que obtuve!',
    description: '',
  };

  const getFormDefaultValues = (): IShareBadgeEmail => {
    return defaultValues;
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<IShareBadgeEmail>({
    ...objectValidation,
    defaultValues: getFormDefaultValues(),
  });

  const submitInfo = async (form: IShareBadgeEmail) => {
    const emailInfo: IShareBadgeEmailRequest = {
      email: form.email,
      subject: form.subject,
      description: form.description,
      uuid: badgeId,
    };
    const new_badge_image = await dispatch(ShareBadgeEmailThunk(emailInfo));

    if (ShareBadgeEmailThunk.fulfilled.match(new_badge_image)) {
      toast.success('Se ha enviado el correo con éxito');
      closeShareSection();
    } else {
      toast.error('No se ha podido enviar el correo');
    }
  };

  return (
    <ShareBadgeEmailContainer>
      <ShareBadgeEmailTitle>Correo Electrónico</ShareBadgeEmailTitle>
      <div>
        <InputWrapper>
          <InputLabelWrapper>
            <InputsLabel $height={15}>Correo *</InputsLabel>
          </InputLabelWrapper>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errors}
                name="email"
                height={41}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: true,
              pattern: { value: emailRegex, message: 'Email invalido' },
            }}
          />
        </InputWrapper>
        {errors.email && <ErrorLabel>Campo requerido. Debe ser un email válido</ErrorLabel>}
        <InputWrapper>
          <InputLabelWrapper>
            <InputsLabel $height={15}>Asunto *</InputsLabel>
          </InputLabelWrapper>
          <Controller
            control={control}
            name="subject"
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errors}
                name="subject"
                height={41}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              required: 'Campo requerido',
            }}
          />
        </InputWrapper>
        {errors.subject && <ErrorLabel>Campo requerido</ErrorLabel>}
        <InputWrapper>
          <InputLabelWrapper>
            <InputsLabel $height={100}>Descripción</InputsLabel>
          </InputLabelWrapper>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value } }) => (
              <TextInput
                error={errors}
                name="description"
                height={120}
                value={value}
                onChange={onChange}
                type="textarea"
                characterCounter={500}
              />
            )}
          />
        </InputWrapper>
        <ButtonsWrapper>
          <Button label="Cancelar" widthCustom={150} lightMode={true} onClick={closeShareSection} />
          <Button label="Enviar" widthCustom={150} onClick={handleSubmit(submitInfo)} />
        </ButtonsWrapper>
      </div>
    </ShareBadgeEmailContainer>
  );
};

export default ShareBadgeEmail;
