import { DropdownItem } from 'reactstrap';
import { UserRoles } from '../../interfaces/user-roles';
import { useContext } from 'react';
import { MenuContext } from '../../context/MenuContextProvider';

const PublicProfileMenuItem = () => {
  const { goToPublicProfile, role } = useContext(MenuContext);

  if (role !== String(UserRoles.ACREDITTED)) {
    return <></>;
  }

  return (
    <DropdownItem onClick={goToPublicProfile} style={{ cursor: 'pointer' }}>
      Perfil público
    </DropdownItem>
  );
};

export default PublicProfileMenuItem;
