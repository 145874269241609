import Step1 from 'components/molecules/OnBoardingSteps/Step1';
import Step2 from 'components/molecules/OnBoardingSteps/Step2';
import ProgressMenu from 'components/molecules/ProgressMenuAcredited';
import CommonPageLayaout from 'components/organisms/Layout/CommonPageLayout';
import AdminEndStepProcess from '../AdminEndStepProcessAcredited';
import { useAppSelector } from 'hooks/redux';
import * as React from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import AcreditedProfile from '../AcreditedProfile';
import { ColProgressMenuWrapper } from './styles';

const OnBoardingWizard: React.FC = () => {
  const [wizardInstance, setWizardInstance] = React.useState<Partial<StepWizardChildProps>>();
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const user = useAppSelector((state) => state.user);

  const onNextStep = () => {
    if (wizardInstance && wizardInstance.nextStep) {
      wizardInstance.nextStep();
      if (wizardInstance.currentStep) setCurrentStep(wizardInstance.currentStep);
    }
  };

  return (
    <React.Fragment>
      <CommonPageLayaout
        showHeader={true}
        showFooter={false}
        childrenMargin={false}
        showMenuHeader={false}
        showMenuHeaderAcredited={false}
        showCommonMenu={false}
        showCommon={true}
        showOnboarding={true}
      >
        {currentStep && (
          <ColProgressMenuWrapper sm="12">
            <ProgressMenu
              selected={currentStep}
              uploadOwnCourses={user.adminInfo ? user.adminInfo.own_courses : false}
            />
          </ColProgressMenuWrapper>
        )}
        <StepWizard instance={setWizardInstance} isHashEnabled={true} isLazyMount={true}>
          <AcreditedProfile onNextStep={onNextStep} />
          <Step1 onNextStep={onNextStep} />
          <Step2 onNextStep={onNextStep} />
          <AdminEndStepProcess />
        </StepWizard>
      </CommonPageLayaout>
    </React.Fragment>
  );
};

export default OnBoardingWizard;
