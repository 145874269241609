import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label, Col, Row } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const VideoTitle = styled(Label)`
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
`;

export const StepVideoContainer = styled(Col)`
  @media ${device.ipad} {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
  }
`;

export const VideoContainer = styled.div``;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.bigMobile} {
    flex-direction: row-reverse;
  }
`;

export const ButtonsContainer = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${device.bigMobile} {
    display: flex;
  }
  button {
    @media ${device.bigMobile} {
      padding: 2px;
    }
  }
  button span {
    @media ${device.bigMobile} {
      display: flex;
    }
  }
`;

export const IconLineContainer = styled(Col)`
  width: 380px;
  svg {
    width: 380px;
    @media ${device.desktop} {
      width: 300px;
    }
    @media ${device.laptops} {
      width: 220px;
    }
    @media ${device.mediumLaptop} {
      width: 180px;
    }
    @media ${device.tablet} {
      width: 130px;
    }
    @media ${device.bigMobile} {
      transform: rotate(90deg);
      position: absolute;
      width: 280px;
      right: -108px;
      height: 300px;
      top: 12px;
    }
    @media ${device.mobile} {
      width: 220px;
      right: -78px;
      height: 250px;
      top: 10px;
    }
  }
  @media ${device.desktop} {
    width: 300px;
  }
  @media ${device.laptops} {
    width: 220px;
  }
  @media ${device.mediumLaptop} {
    width: 180px;
  }
  @media ${device.tablet} {
    width: 130px;
  }
  @media ${device.bigMobile} {
    width: 50px;
    height: 300px;
  }
  @media ${device.mobile} {
    height: 250px;
  }
`;

export const VideoNumber = styled.span`
  background: #ffffff;
  border-radius: 0.8em;
  border: 1px solid #002057;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: ${PRIMARY_COLOR};
  display: inline-block;
  margin-right: 15px;
  text-align: center;
  width: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`;

export const VideoNumberContainer = styled(Col)`
  display: flex;
  justify-content: center;
  svg {
    width: 40px;
    @media ${device.bigMobile} {
      width: 35px;
    }
  }
`;

export const VideoRows = styled(Row)`
  width: 90%;
`;

export const VideoStepRows = styled(Row)`
  width: 90%;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    &:first-child {
      justify-content: flex-end;
    }
  }
  > * {
    &:last-child {
      justify-content: flex-start;
    }
  }
  @media ${device.bigMobile} {
    width: 20%;
    flex-direction: column;
    justify-content: space-around;
  }
  @media ${device.mobile} {
    width: 30%;
  }
`;
