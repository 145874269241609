import * as React from 'react';
import { FormGroup } from 'reactstrap';
import { Control, Controller, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';
import {
  ColButtonContainer,
  FormContainer,
  FormMainWrapper,
  LinkText,
  RequestNewPasswordContent,
  RequestNewPasswordTitle,
  WarningText,
} from './styles';
import TextPasswordInput from 'components/atoms/TextPasswordInput';
import { passwordRegex } from 'utils';
import CheckBox from 'components/atoms/CheckBox';
import TermsAndConditions from 'components/molecules/TermsAndConditions';
import { termsConditions } from 'constants/index';

interface RequestNewPasswordProps {
  controlNewPassword: Control<NewPasswordForm>;
  submitRequiredNewPassword: (newPassword: NewPasswordForm) => void;
  errorsNewPassword: Record<string, any>;
  handleSubmitNewPassword: UseFormHandleSubmit<NewPasswordForm>;
  loading: boolean;
  watch: UseFormWatch<NewPasswordForm>;
}

const RequestNewPassword: React.FC<RequestNewPasswordProps> = ({
  controlNewPassword,
  handleSubmitNewPassword,
  submitRequiredNewPassword,
  errorsNewPassword,
  loading,
  watch,
}) => {
  // const toggle = (displayedTitle: string, displayedBody: string) => {
  //   logEvent('Terms and Conditions / Policies', 'Read article', displayedTitle);

  //   dispatch(setToggleModal());
  //   dispatch(setTermsContent({ title: displayedTitle, textBody: displayedBody }));
  // };

  return (
    <FormContainer onSubmit={handleSubmitNewPassword(submitRequiredNewPassword)}>
      <FormMainWrapper>
        <FormGroup>
          <div>
            <RequestNewPasswordTitle>Crea tu contraseña</RequestNewPasswordTitle>
            <RequestNewPasswordContent>
              Digita la nueva
              contraseña
            </RequestNewPasswordContent>
            <Controller
              control={controlNewPassword}
              render={({ field: { onChange, value } }) => (
                <React.Fragment>
                  <TextPasswordInput
                    error={errorsNewPassword}
                    name="newPassword"
                    label="Contraseña"
                    icon={<icons.PasswordIcon />}
                    value={value}
                    onChange={(value) => onChange(value)}
                    placeholder="Ingresar contraseña"
                  />
                </React.Fragment>
              )}
              name="newPassword"
              rules={{
                required: true,
                pattern: {
                  value: passwordRegex,
                  message:
                    'La contraseña debe contener mínimo 8 caracteres, una mayúscula, un caracter especial y un número.',
                },
              }}
            />
          </div>
          <div>
            <Controller
              control={controlNewPassword}
              render={({ field: { onChange, value } }) => (
                <TextPasswordInput
                  label="Confirmar contraseña"
                  icon={<icons.PasswordIcon />}
                  name="confirmNewPassword"
                  placeholder="Confirmar contraseña"
                  value={value}
                  onChange={(value) => onChange(value)}
                  error={errorsNewPassword}
                />
              )}
              name="confirmNewPassword"
              rules={{
                required: true,
                validate: (value) =>
                  value === watch('newPassword') || 'Las contraseñas no coinciden',
              }}
            />
          </div>
        </FormGroup>
        <FormGroup check>
          <div style={{ textAlign: 'center' }}>
            <Controller
              control={controlNewPassword}
              render={({ field: { onChange, value } }) => (
                <CheckBox
                  name="termsConditions"
                  error={errorsNewPassword}
                  complement={
                    <React.Fragment>
                      <WarningText>He leído y acepto los </WarningText>
                      <LinkText href={termsConditions} target="_blank">
                        Términos y Condiciones
                      </LinkText>
                      <WarningText> de uso y </WarningText>
                      <LinkText href={termsConditions} target="_blank">
                        Políticas de Privacidad
                      </LinkText>
                    </React.Fragment>
                  }
                  onChange={(value) => onChange(value)}
                  value={value}
                />
              )}
              name="termsConditions"
              rules={{
                required: 'Para continuar debes aceptar los términos y condiciones',
              }}
            />
          </div>
          <ColButtonContainer>
            <Button label="Enviar" loading={loading} disabled={loading} widthCustom={150} />
          </ColButtonContainer>
        </FormGroup>
        <TermsAndConditions />
      </FormMainWrapper>
    </FormContainer>
  );
};

export default RequestNewPassword;
