import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Form } from 'reactstrap';

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const Label = styled.div`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 24px;
  margin-left: 10px;
  color: ${PRIMARY_COLOR};
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
`;

export const PasswordRecovery = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  padding: 0px 10px;
  align-self: flex-end;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 12px;
  margin-bottom: 25px;
`;
