import { PRIMARY_COLOR } from 'assets/styles/colors';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const PaginationContainer = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  padding: 0px;
`;

export const PaginationIconWrapper = styled.li`
  cursor: pointer;
  svg {
    width: 30px;
  }
`;

export const PaginationNumberWrapper = styled.li<{
  $current: boolean;
}>`
  border-radius: 100px;
  padding: 0px 8px;
  font-size: 16px;
  line-height: 22px;
  margin: 0px 10px;
  color: ${(props) => (props.$current ? 'white' : PRIMARY_COLOR)};
  background-color: ${(props) => (props.$current ? PRIMARY_COLOR : 'transparent')};
  cursor: pointer;

  @media ${device.mediumMobile} {
    margin: 0px 5px;
  }
  @media ${device.smallMobile} {
    margin: 0px;
  }
`;
