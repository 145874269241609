import { GetCountryList } from 'actions/ApiClient/AdminActions';
import { useEffect, useState } from 'react';

type SelectValues = {
  label: string;
  value: number;
};

const useCountries = () => {
  const [countries, setCountries] = useState<SelectValues[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await GetCountryList();
        const newData = response.map((item) => ({ value: Number(item.id), label: item.name }));
        setCountries(newData);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setCountries([]);
        setLoading(false);
      }
    })();
  }, []);

  const filterCountries = (inputValue: string) => {
    return countries.filter((i: any) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue: string) => {
    return new Promise<SelectValues[]>((resolve) => {
      setTimeout(() => {
        resolve(filterCountries(inputValue));
      }, 1000);
    });
  };

  return {
    loading,
    loadOptions,
    countries
  };
};

export default useCountries;
