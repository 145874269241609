import styled from 'styled-components';
import { BLUE_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';

export const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 20px;
  margin: 40px;
`;

export const RouteHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RouteDate = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin-bottom: 0px;
  span {
    color: ${SECONDARY_COLOR};
  }
`;
export const RouteTitle = styled(Label)`
  max-width: 80%;
  align-self: flex-start;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  margin-left: 10px;
  color: ${PRIMARY_COLOR};
`;

export const RouteInfoImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
  img {
    width: 100px;
    border-radius: 100px;
    margin: 20px 0px;
  }
`;

export const RouteInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RouteInfo = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${PRIMARY_COLOR};
  }
  svg {
    margin-right: 4px;
    color: ${SECONDARY_COLOR};
    width: 14px;
  }
`;
