import icons from 'assets/Icon';
import { usePagination } from 'hooks/usePagination';
import { PaginationNumberWrapper, PaginationContainer, PaginationIconWrapper } from './styles';

interface PaginationProps {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

let siblingCount = 1;
const DOTS = '...';

const Pagination = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}: PaginationProps): JSX.Element => {
  const paginationRange = usePagination(totalCount, pageSize, siblingCount, currentPage);

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null!;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <PaginationContainer>
      {paginationRange && (
        <div>
          {currentPage > 1 && (
            <PaginationIconWrapper onClick={onPrevious}>
              <icons.PreviousArrowIcon />
            </PaginationIconWrapper>
          )}
        </div>
      )}
      {paginationRange &&
        paginationRange.map((pageNumber, pageNumberIndex) => {
          if (pageNumber === DOTS) {
            return (
              <PaginationNumberWrapper key={pageNumberIndex} $current={false}>
                &#8230;
              </PaginationNumberWrapper>
            );
          } else {
            return (
              <PaginationNumberWrapper
                key={pageNumberIndex}
                $current={pageNumber === currentPage}
                onClick={() =>
                  onPageChange(typeof pageNumber === 'number' ? pageNumber : parseInt(pageNumber))
                }
              >
                {pageNumber}
              </PaginationNumberWrapper>
            );
          }
        })}
      {paginationRange && (
        <div>
          {currentPage < paginationRange.length && (
            <PaginationIconWrapper onClick={onNext}>
              <icons.NextArrowIcon />
            </PaginationIconWrapper>
          )}
        </div>
      )}
    </PaginationContainer>
  );
};

export default Pagination;
