import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetBadgeDetailUnAuthUser,
  GetBadgeTemplateDetailUnAuthUser,
  GetAllBadgesTemplatesDetailUnAuthOrg,
  GetOrganizationInfo,
  GetBadgeDetailAuthUser,
  VerifyBadge,
  GetBadgeDetailAuthUserTemp,
  GetSearchBadgesTemplatesDetailUnAuthOrg,
} from 'actions/ApiClient/UnAuthUsersPaths';
import { GetErrorMessage } from 'utils';

export const DetailBadgesUnAuthUserThunk = createAsyncThunk(
  'paths/detail-badges-unauth-user',
  async (badge_id: any, { rejectWithValue }) => {
    try {
      const badge_detail = await GetBadgeDetailUnAuthUser(badge_id);
      return badge_detail;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailBadgesUserThunk = createAsyncThunk(
  'paths/detail-badges-auth-user',
  async (badge_id: any, { rejectWithValue }) => {
    try {
      const badge_detail = await GetBadgeDetailAuthUser(badge_id);
      return badge_detail;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const DetailBadgesUserTempThunk = createAsyncThunk(
  'paths/detail-badges-auth-user-Temp',
  async (badge_id: any, { rejectWithValue }) => {
    try {
      const badge_detail = await GetBadgeDetailAuthUserTemp(badge_id);
      return badge_detail;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetBadgeTemplateDetailUnAuthUserThunk = createAsyncThunk(
  'paths/detail-badges-template-unauth-user',
  async (badge_id: any, { rejectWithValue }) => {
    try {
      const badge_template_detail = await GetBadgeTemplateDetailUnAuthUser(badge_id);
      return badge_template_detail;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetAllBadgesTemplatesDetailUnAuthOrgThunk = createAsyncThunk(
  'paths/all-badges-template-unauth-org',
  async (organization_id: any, { rejectWithValue }) => {
    try {
      const badges = await GetAllBadgesTemplatesDetailUnAuthOrg(organization_id);
      return badges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetOrganizationInfoThunk = createAsyncThunk(
  'paths/get-organization-info',
  async (_, { rejectWithValue }) => {
    try {
      const organization = await GetOrganizationInfo();
      return organization;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const VerifyBadgeThunk = createAsyncThunk(
  'paths/verify-badge',
  async (badge_id: string, { rejectWithValue }) => {
    try {
      const badge_info = await VerifyBadge(badge_id);
      return badge_info;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetSearchBadgesTemplatesDetailUnAuthOrgThunk = createAsyncThunk(
  'paths/search-badges-template-unauth-org',
  async (info: IBadgesSearch, { rejectWithValue }) => {
    try {
      const badges = await GetSearchBadgesTemplatesDetailUnAuthOrg(info);
      return badges;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
