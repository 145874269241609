import styled, { css } from 'styled-components';
import { LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import {
  Navbar,
  Nav,
  NavbarToggler,
  NavItem,
  NavLink,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  Row,
  Col,
  Modal,
} from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const Header = styled.header`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${PRIMARY_COLOR};
  align-items: center;
  height: 71px;
  z-index: 2;
`;

export const NavBrandMobile = styled.div`
  display: none;
  @media only screen and (max-width: 810px) {
    display: inline;
  }
`;

export const NavBarHeader = styled(Navbar)<{ $background?: string }>`
  ${(props) =>
    props.$background &&
    css`
      background-image: ${props.$background} !important;
    `}
  z-index: 200;
  height: 70px !important;
  width: 100%;
  display: flex;
`;

export const NavBarHeaderTwo = styled(Navbar)<{ $background?: string }>`
  ${(props) =>
    props.$background &&
    css`
      background-image: ${props.$background} !important;
    `}
  z-index: 10;
  height: 90px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    height: 20px !important;
  }
`;

export const NavLinkStyled = styled(NavLink)`
  @media ${device.mediumLaptop} {
    font-size: 15px;
  }
`;

export const NavMobile = styled(Nav)``;

export const NavbarTogglerMobile = styled(NavbarToggler)``;

export const IconContainer = styled.div`
  margin: 10px;
`;

export const MenuContainer = styled.header`
  margin: 10px;
`;

export const MenuMobileContainer = styled.header`
  margin: 10px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const NavItemStyled = styled(NavItem)`
  &:hover {
    opacity: 1;
  }

  &:active {
    color: ${SECONDARY_COLOR};
  }

  @media ${device.ipad} {
    padding-left: 20px;
  }
`;

export const ProfileImageContainer = styled(NavItem)``;

export const ProfileImage = styled.img`
  max-height: 100%;
  max-width: 200px;
`;

export const HeaderLabel = styled(Label)`
  color: ${LIGHT};
  font-family: NotoSansJPBold, sans-serif;
  margin-bottom: 0;
  @media ${device.ipad} {
    color: ${PRIMARY_COLOR};
  }
`;

export const HeaderLabelTitle = styled(Label)`
  color: ${LIGHT};
  font-family: NotoSansJPBold, sans-serif;
  margin-bottom: 0;

  @media ${device.ipad} {
    color: ${PRIMARY_COLOR};
  }
`;

export const UncontrolledDropdownStyled = styled(UncontrolledDropdown)`
  display: flex;
  &:hover {
    opacity: 1;
  }

  @media ${device.ipad} {
    padding-left: 20px;
  }
`;

export const HeaderOptionWrapper = styled(NavLink)`
  display: flex;
  align-self: center;
`;

export const HeaderOptionIconWrapper = styled.div<{ $padding?: boolean }>`
  ${(props) =>
    props.$padding &&
    css`
      padding-top: 4px;
    `}

  display: flex;
  align-self: center;
  margin-right: 3px;

  svg {
    &:active {
      color: ${SECONDARY_COLOR};
    }
  }
`;

export const HeaderDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-self: center;
  padding: 0px;
`;

export const RowStyled = styled(Row)`
  margin: 0px 2px;
`;

export const SearchIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 18px;
  svg {
    width: 15px;
  }
`;

export const IconFilterWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
`;

export const SubtitleFirst = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
  cursor: pointer;
`;

export const BarSearchContainer = styled(Col)`
  display: grid;
  grid-template-columns: 2% 98%;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  background-image: ${PRIMARY_COLOR};
  margin-bottom: 20px;

  @media ${device.laptops} {
    grid-template-columns: 4% 96%;
  }
  @media ${device.bigMobile} {
    grid-template-columns: 6% 94%;
  }
  @media ${device.smallMobile} {
    grid-template-columns: 8% 92%;
  }
`;

export const ColStyled = styled(Col)`
  margin-top: 30px;
`;
export const ModalContainer = styled(Modal)`
  max-width: 900px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 50px;
  padding: 5%;
`;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
`;
