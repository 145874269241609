import { Theme } from 'react-select';
import { PRIMARY_COLOR } from 'assets/styles/colors';

const customStyles = {
  input: () => ({ height: 26 }),
};

const styledTheme = (theme: Theme) => {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      neutral20: PRIMARY_COLOR,
      neutral30: PRIMARY_COLOR,
    },
  };
};

export { customStyles, styledTheme };
