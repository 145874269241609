import { exportOrganizationBadgeReport } from 'actions/ApiClient/analytics';
import AcredittaButton from 'components/atoms/AcredittaButton';
import { format } from 'date-fns';
import { manageErrors } from 'helpers/manage-error';
import { useState } from 'react';

interface ExportButtonProps {
  fromDate: Date;
  toDate: Date;
}

const ExportButton = ({ fromDate, toDate }: ExportButtonProps) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      const resp = await exportOrganizationBadgeReport(
        format(fromDate, 'yyyy-MM-dd'),
        format(toDate, 'yyyy-MM-dd')
      );
      const blob = new Blob([resp.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = new Date().getTime().toString();
      link.click();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      manageErrors(e);
    }
  };

  return (
    <AcredittaButton
      onPress={handleClick}
      title="Exportar"
      type="button"
      variant="primary"
      disabled={loading}
      isLoading={loading}
    />
  );
};

export default ExportButton;
