import styled from 'styled-components';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';

export const InputContainer = styled.div`
  padding: 20px 0px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkillSelection = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const SelectedStatusLabel = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-size: 12px;
  line-height: 16px;
  margin-left: 15px;
  margin-top: 11px;
`;

export const SelectSkillWrapper = styled.div`
  padding: 10px 15px;
  border-top: 1px solid #e8e8e8;
`;
