import AcredittaLoader from 'components/atoms/AcredittaLoader';

interface Props {
  colSpan?: number;
}

const TableRowLoading = ({ colSpan = 4 }: Props) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <AcredittaLoader width={32} height={32} isTextDisabled={true} />
      </td>
    </tr>
  );
};

export default TableRowLoading;
