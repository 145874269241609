import * as React from 'react';
import Filter from 'components/atoms/Filter';
import { RowStyled } from './styles';
import { Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { updateSelectionCatalogMenu } from 'states/slices/admin.slice';

interface CourseFiltersProps {
  showFileterResults?: () => void;
}

const CourseFilters = ({ showFileterResults }: CourseFiltersProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.admin);

  const toggle = (index: number) => {
    dispatch(updateSelectionCatalogMenu(index));
  };

  const setShowFilterResults = () => {
    if (showFileterResults) {
      showFileterResults();
    }
  };

  return (
    <RowStyled>
      {admin.courseCatalogMenu &&
        admin.courseCatalogMenu.map((filter, filterIndex) => {
          return (
            <Col key={filterIndex}>
              <Filter
                categoryId={filter.id}
                name={filter.name}
                subCategories={filter.subCategories}
                selected={filter.selected}
                onToggle={() => toggle(filterIndex)}
                handleFilter={() => toggle(filterIndex)}
                showFileterResults={setShowFilterResults}
              />
            </Col>
          );
        })}
    </RowStyled>
  );
};

export default CourseFilters;
