import icons from 'assets/Icon';
import { AuthenticatedUrls } from 'constants/NavigationUrls';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AcredittaButton from '../AcredittaButton';
import { NavbarBrandStyled, NavBrand, NavBrandMobile } from './styles';

const StyleNavbarHeader = styled.div`
  background-image: linear-gradient(
    90.8deg,
    #283c85 0.12%,
    #51386f 33.93%,
    #843451 67.23%,
    #aa303b
  );
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const PublicHeader: React.FC = () => {
  const { push } = useHistory();

  const handleNavigate = () => {
    push('/');
  };

  return (
    <StyleNavbarHeader>
      <Link to={AuthenticatedUrls.root}>
        <NavbarBrandStyled className="mr-auto">
          <NavBrand>
            <icons.AcredittaLogoBlanco />
          </NavBrand>
          <NavBrandMobile>
            <icons.AcredittaLogoMobileIcon />
          </NavBrandMobile>
        </NavbarBrandStyled>
      </Link>
      <div className="navbar-nav ml-auto">
        <AcredittaButton
          onPress={handleNavigate}
          type="button"
          title="Iniciar sesión"
          variant="transparent"
        />
      </div>
    </StyleNavbarHeader>
  );
};

export default PublicHeader;
