import React, { useContext } from 'react';
import { useAppSelector } from 'hooks/redux';
import { DrawerMenu } from './components/mobile';
import iconMenu from '../../../assets/images/icon-menu.png';
import { MenuContext } from './context/MenuContextProvider';
import { AcredittaThemeContext } from 'context/AcredittaThemeContext';
import './styles.css';

// TODO: agregar soporte Link

const AcredittaNavbarMobile = () => {
  const { backgroundHeader } = useContext(AcredittaThemeContext);
  const { userInfo } = useAppSelector((state) => state.user);
  const { menu, toogleMenu } = useContext(MenuContext);

  if (!menu) {
    return <></>;
  }

  return (
    <>
      <DrawerMenu />

      <div
        className="mobile__main__menu"
        style={{
          background: backgroundHeader,
        }}
      >
        <div onClick={toogleMenu}>
          <img src={iconMenu} alt="" />
        </div>
        <div>
          <img src={userInfo?.branding.image} alt="" width="200" height="auto" className="ml-3" />
        </div>
      </div>
    </>
  );
};

export default AcredittaNavbarMobile;
