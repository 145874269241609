import React from 'react';
import Button from 'components/atoms/Button';
import {
  NewBadgeFormContainer,
  NewBadgeInstructionsTitle,
  FormGroupStyled,
  FormLabelSimpleWrapper,
  FormInputWrapper,
  FormLabelSimple,
  ButtonsContainer,
  InputSelectContainer,
  ButtonWrapper,
  MenuItemWrapperContainer,
  MenuItemTitle,
  MenuItemWrapper,
  MenuItemText,
  SeeProfile,
} from './styles';
import InputSelect from 'components/atoms/InputSelect';
import TextInput from 'components/atoms/TextInput';
import { Control, UseFormHandleSubmit, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { CountryListThunk, GetDetailsProfileInfoThunk } from 'actions/Thunks/AdminThunk';
import icons from 'assets/Icon';
import { Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ENVIRONMENT_URL_BADGE } from 'utils';

interface AdminEditPerfilInfoForm {
  controlInfo: Control<IFormEditProfie>;
  handleSubmitForm: UseFormHandleSubmit<IFormEditProfie>;
  submitNewBadgeForm: (formInfo: IFormEditProfie) => void;
  errorsInfo: Record<string, any>;
}

const AdminEditPerfilInfoFormData = ({
  controlInfo,
  handleSubmitForm,
  submitNewBadgeForm,
  errorsInfo,
}: AdminEditPerfilInfoForm): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const user = useAppSelector((state) => state.user);

  const [infoUser, setInfoUser] = React.useState<IUserProfileData>();
  const [infoUserNickname, setInfoUserNickname] = React.useState('');

  const loadCountries = () => {
    return new Promise((resolve) => {
      dispatch(CountryListThunk()).then((countries) => {
        if (CountryListThunk.fulfilled.match(countries)) {
          resolve(countries.payload);
        } else {
          toast.error(countries.payload as string);
        }
      });
    });
  };

  const getProfileInfo = React.useCallback(async () => {
    if (user.userInfo?.cognito_id !== undefined) {
      const data = await dispatch(GetDetailsProfileInfoThunk(user.userInfo?.cognito_id));
      if (GetDetailsProfileInfoThunk.fulfilled.match(data)) {
        setInfoUser(data.payload);
        setInfoUserNickname(`${ENVIRONMENT_URL_BADGE}/public/acreditado/${data.payload.nickname}`);
      } else {
        toast.error(data.payload as string);
      }
    }
  }, [user, dispatch]);

  const handleCancel = () => {
    history.goBack();
  };

  React.useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  return infoUser !== undefined ? (
    <div>
      <a href={infoUserNickname} target="_blank" rel="noreferrer">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            float: 'right',
            padding: '1%',
          }}
        >
          <icons.Icon_eye_open_blue
            style={{
              marginLeft: '15px',
              width: '17px',
            }}
          />
          <SeeProfile>Ver Perfil</SeeProfile>
        </div>
      </a>

      <NewBadgeFormContainer>
        <NewBadgeInstructionsTitle>Tus Datos Personales</NewBadgeInstructionsTitle>
        <Row>
          <MenuItemWrapperContainer>
            <MenuItemWrapper>
              <MenuItemTitle>Organización:</MenuItemTitle>
              {user.userInfo?.organization.name !== undefined && (
                <MenuItemText>{user.userInfo?.organization.name}</MenuItemText>
              )}
            </MenuItemWrapper>
          </MenuItemWrapperContainer>
          <MenuItemWrapperContainer>
            <React.Fragment>
              <icons.DividerIcon />
            </React.Fragment>
            <MenuItemWrapper>
              <MenuItemTitle>Cargo Actual:</MenuItemTitle>
              {infoUser.accredited?.job_title ? (
                <MenuItemText>{infoUser.accredited.job_title}</MenuItemText>
              ) : (
                <MenuItemText>No disponible</MenuItemText>
              )}
            </MenuItemWrapper>
          </MenuItemWrapperContainer>

          <MenuItemWrapperContainer>
            <React.Fragment>
              <icons.DividerIcon />
            </React.Fragment>
            <MenuItemWrapper>
              <MenuItemTitle>Correo:</MenuItemTitle>
              <MenuItemText>{infoUser.email}</MenuItemText>
            </MenuItemWrapper>
          </MenuItemWrapperContainer>
        </Row>
        <br />
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="first_name">Nombres</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="first_name"
                  onChange={(value) => onChange(value)}
                  defaultValue={infoUser.first_name}
                  error={errorsInfo}
                  height={38}
                  withError
                />
              )}
              name="form.first_name"
            />
          </FormInputWrapper>
        </FormGroupStyled>
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="last_name">Apellidos</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="last_name"
                  onChange={(value) => onChange(value)}
                  defaultValue={infoUser.last_name}
                  error={errorsInfo}
                  height={38}
                  withError
                />
              )}
              name="form.last_name"
            />
          </FormInputWrapper>
        </FormGroupStyled>
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="biography">Biografía</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="biography"
                  onChange={(value) => onChange(value)}
                  defaultValue={infoUser.accredited.biography}
                  error={errorsInfo}
                  height={100}
                  withError
                  type={'textarea'}
                />
              )}
              name="form.accredited.biography"
            />
          </FormInputWrapper>
        </FormGroupStyled>
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="country">País</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <InputSelectContainer>
              <Controller
                control={controlInfo}
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: '100%' }}>
                    <InputSelect
                      onChange={(value) => onChange(value)}
                      value={value}
                      loadOptions={loadCountries}
                      placeholder="Buscar..."
                      asyncSelect={true}
                      error={errorsInfo}
                      name="country"
                      defaultOptions
                    />
                  </div>
                )}
                name="form.accredited.country"
              />
            </InputSelectContainer>
          </FormInputWrapper>
        </FormGroupStyled>
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="contact_email">Correo Adicional</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="contact_email"
                  onChange={(value) => onChange(value)}
                  defaultValue={infoUser.email}
                  error={errorsInfo}
                  height={38}
                  withError
                />
              )}
              name="form.contact_email"
            />
          </FormInputWrapper>
        </FormGroupStyled>
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="domain">Pagina Web</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="domain"
                  onChange={(value) => onChange(value)}
                  defaultValue={infoUser.accredited.domain}
                  error={errorsInfo}
                  height={38}
                  withError
                />
              )}
              name="form.accredited.domain"
            />
          </FormInputWrapper>
        </FormGroupStyled>
        {/* <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="nickname">Perfil Acreditta</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="nickname"
                defaultValue={'www.acreditta.com/' + infoUser.nickname}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="form.nickname"
          />
        </FormInputWrapper>
      </FormGroupStyled> */}
        <ButtonsContainer>
          <ButtonWrapper>
            <Button
              label={'Cancelar'}
              lightMode={true}
              widthCustom={150}
              onClick={() => handleCancel()}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              label={'Guardar'}
              widthCustom={150}
              onClick={handleSubmitForm((template_form) => submitNewBadgeForm(template_form))}
            />
          </ButtonWrapper>
        </ButtonsContainer>
      </NewBadgeFormContainer>
    </div>
  ) : (
    <h1>cargando</h1>
  );
};

export default AdminEditPerfilInfoFormData;
