export const skills = [
  'Lorem Ipsum loremipsu',
  'Lorem Ipsum',
  'Lorem Ipsum',
  'Lorem Ipsum',
  'Lorem Ipsum',
  'Lorem Ipsum loremipsu',
  'Lorem Ipsum',
  'Lorem Ipsum',
  'Lorem Ipsum',
  'Lorem Ipsum',
  'Lorem Ipsum loremipsu',
];

export const badges: IBadgeBasicInfo[] = [
  {
    img: 'https://acreditta-rutas.s3.amazonaws.com/static/badge-example-1.jpg',
    name: 'Entusiasta de la Investigación',
    owner: 'Universidad del Rosario',
  },
  {
    img: 'https://acreditta-rutas.s3.amazonaws.com/static/badge-example-2.png',
    name: 'Promotor de Sueños',
    owner: 'Universidad del Rosario',
  },
  {
    img: 'https://acreditta-rutas.s3.amazonaws.com/static/badge-example-3.png',
    name: 'Integridad Científicas',
    owner: 'Universidad del Rosario',
  },
];

export const courses: ISingleCourse[] = [
  {
    id: 1,
    name: 'Business Intelligence',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
  {
    id: 2,
    name: 'Marketing Digital',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
  {
    id: 3,
    name: 'Business Intelligence',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
  {
    id: 4,
    name: 'Marketing Digital',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
];

export const recomendedCourses: ISingleCourse[] = [
  {
    id: 1,
    name: 'Business Intelligence',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
  {
    id: 2,
    name: 'Marketing Digital',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
  {
    id: 3,
    name: 'Business Intelligence',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
  {
    id: 4,
    name: 'Marketing Digital',
    image: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
    provider_name: 'Bedu',
    start_date: new Date(),
    duration: '2',
    cost: 30,
    skill_count: 6,
    score: 4,
    course: 14,
    image_url: 'https://acreditta-rutas.s3.amazonaws.com/static/course-image-example.jpg',
  },
];

export const learningPath = [
  {
    id: 1,
    children: [
      {
        id: 1,
        order: 1,
        name: 'Digital',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 2,
        order: 2,
        name: 'Marqueting Digital',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 3,
        order: 3,
        name: 'Producción',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 4,
        order: 4,
        name: 'Producción Audiovisual',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 5,
        order: 5,
        name: 'Ventas',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 6,
        order: 6,
        name: 'Maquetación Web',
        sessions: 8,
        mandatory: true,
      },
    ],
  },
  {
    id: 2,
    children: [
      {
        id: 4,
        order: 1,
        name: 'Negocios Internacionales',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 5,
        order: 2,
        name: 'Bussiness Intelligence',
        sessions: 8,
        mandatory: true,
      },
      {
        id: 6,
        order: 3,
        name: 'Desarrollo',
        sessions: 8,
        mandatory: true,
      },
    ],
  },
];
