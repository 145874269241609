import { createContext, useEffect, useState } from 'react';

export const ConfigureOrganizationContext = createContext({} as any);

const ConfigureOrganizationProvider = ({ children }: any) => {
  const [currentOption, setCurrentOption] = useState('profile');

  useEffect(() => {
    setCurrentOption(localStorage.getItem('@currentOptionConfigure') || 'profile');
  }, []);

  useEffect(() => {
    localStorage.setItem('@currentOptionConfigure', currentOption);
  }, [currentOption]);

  return (
    <ConfigureOrganizationContext.Provider value={{ currentOption, setCurrentOption }}>
      {children}
    </ConfigureOrganizationContext.Provider>
  );
};

export default ConfigureOrganizationProvider;
