import { Auth } from '@aws-amplify/auth';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CheckSession } from 'utils/Routing/AuthUserGuard';

const defaultHeader: AxiosRequestConfig = {
  headers: {
    Authorization: null,
    'Content-Type': 'application/json',
  },
};

const ERROR_SESSION = 'Ha ocurrido un error, por favor intentar más tarde.';

export class HttpRequest {
  public async Get<T>(request: string): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          headers: { ...defaultHeader.headers, Authorization: token },
        };
        const response = await axios.get(request, headers);

        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async Delete<T>(request: string): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          headers: { ...defaultHeader.headers, Authorization: token },
        };
        const response = await axios.delete(request, headers);

        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async PublicGet<T>(request: string): Promise<IHttpResponse<T>> {
    try {
      let headers = {
        ...defaultHeader,
        headers: { ...defaultHeader.headers },
      };
      const response = await axios.get(request, headers);

      return this.GetAxiosSuccessResponse(response);
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async Post<T>(request: string, object: any): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          headers: { ...defaultHeader.headers, Authorization: token },
        };
        const response = await axios.post(request, object, headers);
        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async PostForm<T>(
    request: string,
    form: FormData,
    chunk?: TUploadProgress
  ): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          onUploadProgress: chunk,
          headers: { ...defaultHeader.headers, Authorization: token },
        };

        const response = await axios.post<T>(request, form, headers);

        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async PatchForm<T>(
    request: string,
    form: FormData,
    chunk?: TUploadProgress
  ): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          onUploadProgress: chunk,
          headers: { ...defaultHeader.headers, Authorization: token },
        };

        const response = await axios.patch<T>(request, form, headers);

        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async Put<T>(request: string, object: any): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          headers: { ...defaultHeader.headers, Authorization: token },
        };
        const response = await axios.put(request, object, headers);
        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async Patch<T>(request: string, object: any): Promise<IHttpResponse<T>> {
    try {
      const session = await CheckSession();
      if (session) {
        let token = await this.GetToken();
        let headers = {
          ...defaultHeader,
          headers: { ...defaultHeader.headers, Authorization: token },
        };
        const response = await axios.patch(request, object, headers);
        return this.GetAxiosSuccessResponse(response);
      } else {
        return this.GetAxiosErrorResponse(ERROR_SESSION);
      }
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  private async GetToken() {
    let token = undefined;
    try {
      const currentSession = await Auth.currentSession();
      token = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
    } finally {
      return token;
    }
  }

  private GetAxiosSuccessResponse<T>(response: AxiosResponse): IHttpResponse<T> {
    return {
      okay: true,
      message: '',
      data: response.data as T,
    };
  }

  private GetAxiosErrorResponse<T>(error: unknown): IHttpResponse<T> {
    return {
      okay: false,
      message: this.GetAxiosError(error),
      data: null,
    };
  }

  private GetAxiosError(error: unknown): string {
    if (axios.isAxiosError(error)) {
      return error.response?.data.message;
    } else {
      return JSON.stringify(error);
    }
  }

  public async UnAuthGet<T>(request: string): Promise<IHttpResponse<T>> {
    try {
      const response = await axios.get(request);
      return this.GetAxiosSuccessResponse(response);
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }

  public async UnAuthPost<T>(request: string, object: any): Promise<IHttpResponse<T>> {
    try {
      const response = await axios.post(request, object);
      return this.GetAxiosSuccessResponse(response);
    } catch (error) {
      return this.GetAxiosErrorResponse(error);
    }
  }
}
