import { API_URL, IntegrationsUrls } from 'constants/ApiUrls';
import { HttpRequest } from './HttpRequest';
import { GetErrorMessage } from 'utils';

export async function GetDataRRSS(uuid: string): Promise<IRRSSInfo | null> {
  try {
    const rrss = await new HttpRequest().Get<IRRSSInfo | null>(
      `${API_URL}${IntegrationsUrls.shareBadgesData}${uuid}`
    );
    if (rrss.okay && rrss.data) {
      return rrss.data;
    } else {
      throw Error(rrss.message);
    }
  } catch (error) {
    throw new Error(GetErrorMessage(error));
  }
}

export async function GetDataRRSSUnauth(uuid: string): Promise<IRRSSInfo | null> {
  try {
    const rrss = await new HttpRequest().UnAuthGet<IRRSSInfo | null>(
      `${API_URL}${IntegrationsUrls.shareBadgesData}${uuid}`
    );
    if (rrss.okay && rrss.data) {
      return rrss.data;
    } else {
      throw Error(rrss.message);
    }
  } catch (error) {
    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeJson(uuid: string): Promise<any> {
  try {
    const json = await new HttpRequest().UnAuthGet<any>(
      `${API_URL}${IntegrationsUrls.shareBadgesJson}${uuid}`
    );
    if (json.okay && json.data) {
      return json.data;
    } else {
      throw Error(json.message);
    }
  } catch (error) {
    throw new Error(GetErrorMessage(error));
  }
}
