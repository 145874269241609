import * as React from 'react';
import {
  FilterContainer,
  FilterName,
  FiltersSubCategories,
  FilterWrapper,
  FilterUtils,
  ButtonActionOption,
} from './styles';
import SimpleCheckBox from 'components/atoms/SimpleCheckBox';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  addCourseCatalogFilter,
  addSingleCourseCatalogFiler,
  removeSingleCourseCatalogFiler,
} from 'states/slices/admin.slice';
import { AdminCourseCatalogThunk } from '../../../actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';

interface FilterProps {
  categoryId: number;
  name: string;
  subCategories: IFilterOptionsCatalogItem[];
  onToggle: () => void;
  selected: boolean;
  handleFilter: () => void;
  showFileterResults?: () => void;
}

const Filter = ({
  categoryId,
  name,
  subCategories,
  onToggle,
  selected,
  handleFilter,
  showFileterResults,
}: FilterProps): JSX.Element => {
  const [checkAll, setCheckAll] = React.useState<boolean | undefined>(undefined);
  const [disableFilter] = React.useState<boolean>(true);

  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.admin);

  const selectAll = () => {
    setCheckAll(true);
  };

  const deselectAll = () => {
    setCheckAll(undefined);
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, id: number, index: number) => {
    if (e.target.checked) {
      dispatch(addSingleCourseCatalogFiler({ index: index, idOption: id, idCategory: categoryId }));
    } else {
      dispatch(
        removeSingleCourseCatalogFiler({ index: index, idCategory: categoryId, idItem: id })
      );
    }
  };

  const acceptFilter = async () => {
    if (admin.courseCatalogFilter) {
      const setFilter = await dispatch(
        AdminCourseCatalogThunk({
          filter: admin.courseCatalogFilter,
        })
      );

      if (AdminCourseCatalogThunk.rejected.match(setFilter)) {
        toast.error(setFilter.payload as string);
      } else {
        handleFilter();
        const defaultFilterOptions = {
          interest: [],
          provider: [],
          duration: [],
          type_cost: [],
          level: [],
          language: [],
          skill: [],
        };
        dispatch(addCourseCatalogFilter(defaultFilterOptions));
      }
    }
    if (showFileterResults) {
      showFileterResults();
    }
  };

  React.useEffect(() => {
    const defaultFilterOptions = {
      interest: [],
      provider: [],
      duration: [],
      type_cost: [],
      level: [],
      language: [],
      skill: [],
    };
    dispatch(addCourseCatalogFilter(defaultFilterOptions));
  }, [dispatch]);

  return (
    <div>
      <FilterContainer onClick={onToggle}>
        <FilterName>{name}</FilterName>
      </FilterContainer>
      {selected && (
        <FiltersSubCategories>
          {subCategories &&
            subCategories.map((subCategory, subCategoryIndex) => {
              return (
                <FilterWrapper key={subCategoryIndex}>
                  <SimpleCheckBox
                    isChecked={checkAll}
                    handleCheckElement={(e) => handleCheck(e, subCategory.id, subCategoryIndex)}
                    value={subCategory.name}
                    type={'checkbox'}
                  />
                </FilterWrapper>
              );
            })}
          <FilterUtils>
            <ButtonActionOption onClick={acceptFilter}>Aplicar</ButtonActionOption>
          </FilterUtils>
          {!disableFilter && (
            <FilterUtils>
              <ButtonActionOption onClick={deselectAll}>Borrar</ButtonActionOption>
              <ButtonActionOption onClick={selectAll}>Ver Todo</ButtonActionOption>
            </FilterUtils>
          )}
        </FiltersSubCategories>
      )}
    </div>
  );
};

export default Filter;
