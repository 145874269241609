import * as React from 'react';
import {
  DnDBoardContainer,
  DnDBoardContentDataWrapper,
  DnDBoardContentMainWrapper,
  DnDBoardWrapperPanPitch,
} from './styles';
import { Col } from 'reactstrap';
import AdminDnDContentStatus from 'components/atoms/AdminDnDContentStatus';
import icons from 'assets/Icon';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import AdminDnDBoardPathway from 'components/molecules/AdminDnDBoardPathway';
import { useAppSelector } from '../../../hooks/redux';
import { formatPrice } from '../../../utils';

const AdminDnDBoard = (): JSX.Element => {
  const courseSelector = useAppSelector((state) => state.courses);
  const courseCounterPrice = React.useMemo(() => {
    if (courseSelector.pathwayCourseCounter) {
      return formatPrice(courseSelector.pathwayCourseCounter.cost) + ' USD';
    }
  }, [courseSelector.pathwayCourseCounter]);

  return (
    <DnDBoardContainer>
      <DnDBoardContentMainWrapper>
        {courseSelector ? (
          courseSelector.pathwayCourseCounter &&
          courseCounterPrice &&
          courseSelector.pathwayDetailContent ? (
            <DnDBoardContentDataWrapper>
              {courseSelector.pathwayDetailContent.duration !== null ? (
                <Col sm={3}>
                  <AdminDnDContentStatus
                    icon={<icons.TimeIcon color={PRIMARY_COLOR} />}
                    status={`${courseSelector.pathwayDetailContent.duration}`}
                    label="de contenido"
                  />
                </Col>
              ) : (
                <Col sm={3}>
                  <AdminDnDContentStatus
                    icon={<icons.TimeIcon color={PRIMARY_COLOR} />}
                    status={'Flexible'}
                    label="A tu ritmo"
                  />
                </Col>
              )}
              {courseSelector.pathwayCourseCounter && courseSelector.pathwayCourseCounter.course ? (
                <Col sm={3}>
                  <AdminDnDContentStatus
                    icon={<icons.BlockIcon />}
                    status={`${courseSelector.pathwayCourseCounter.course} cursos`}
                    label="en esta ruta"
                  />
                </Col>
              ) : (
                <Col sm={3}>
                  <AdminDnDContentStatus
                    icon={<icons.BlockIcon />}
                    status={`0 cursos`}
                    label="en esta ruta"
                  />
                </Col>
              )}
              {courseCounterPrice && (
                <Col sm={3}>
                  <AdminDnDContentStatus
                    icon={<icons.MoneyIcon />}
                    status={courseCounterPrice}
                    label="costo total"
                  />
                </Col>
              )}
            </DnDBoardContentDataWrapper>
          ) : (
            <DnDBoardContentDataWrapper>
              <Col sm={3}>
                <AdminDnDContentStatus
                  icon={<icons.TimeIcon color={PRIMARY_COLOR} />}
                  status="0 h"
                  label="de contenido"
                />
              </Col>
              <Col sm={3}>
                <AdminDnDContentStatus
                  icon={<icons.BlockIcon />}
                  status="0 cursos"
                  label="en esta ruta"
                />
              </Col>
              <Col sm={3}>
                <AdminDnDContentStatus
                  icon={<icons.MoneyIcon />}
                  status="0 usd "
                  label="costo total"
                />
              </Col>
            </DnDBoardContentDataWrapper>
          )
        ) : (
          <h1>Loading...</h1>
        )}

        <DnDBoardWrapperPanPitch>
          <AdminDnDBoardPathway />
        </DnDBoardWrapperPanPitch>
      </DnDBoardContentMainWrapper>
    </DnDBoardContainer>
  );
};
export default AdminDnDBoard;
