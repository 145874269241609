import React from 'react';
import { useLocation } from 'react-router-dom';

import { initGA, logPageView } from '../utils/GA/analytics';

export default function useGoogleAnalytics() {
  const location = useLocation();

  React.useEffect(() => {
    initGA();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    logPageView(currentPath);
  }, [location]);
}
