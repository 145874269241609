import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { FooterAboutUsContainer, FooterAboutUsNavLink, FooterAboutUsTitle } from './styles';

const FooterAboutUs: React.FC = () => {
  return (
    <FooterAboutUsContainer>
      <FooterAboutUsTitle>Nosotros</FooterAboutUsTitle>
      <Nav vertical>
        <NavItem>
          <FooterAboutUsNavLink to="#">Quienes Somos</FooterAboutUsNavLink>
        </NavItem>
        <NavItem>
          <FooterAboutUsNavLink to="#">Nuestro Equipo</FooterAboutUsNavLink>
        </NavItem>
        <NavItem>
          <FooterAboutUsNavLink to="#">Aliados</FooterAboutUsNavLink>
        </NavItem>
      </Nav>
    </FooterAboutUsContainer>
  );
};

export default FooterAboutUs;
