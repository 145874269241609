import ReactLoading from 'react-loading';
import styles from './index.module.css';

type AcredittaLoaderProps = {
  text?: string;
  color?: string;
  width?: number;
  height?: number;
  isTextDisabled?: boolean;
};

const DEFAULT_TEXT = 'Cargando...';

const AcredittaLoader = ({
  text = DEFAULT_TEXT,
  color = '#002057',
  width = 100,
  height = 100,
  isTextDisabled = false,
}: AcredittaLoaderProps) => {
  return (
    <div className={styles.root}>
      <div>
        <ReactLoading
          type="spin"
          color={color}
          height={height}
          width={width}
          className="text-center"
        />
        {!isTextDisabled && <h4 style={{color: `${color}`}} className="text-center mt-3">{text}</h4>}
      </div>
    </div>
  );
};

export default AcredittaLoader;
