import { Label, Row } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { LIGHT, SECONDARY_COLOR, PRIMARY_COLOR } from 'assets/styles/colors';

export const UploadSelectedType = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 35px;
  line-height: 48px;
  color: ${PRIMARY_COLOR};
  width: 70%;
  text-align: center;
  margin-bottom: 30px;
`;

export const BadgesUploadTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BadgeCoverIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BadgeImageWrapper = styled.div`
  margin-bottom: 10px;
`;

export const BadgeImage = styled.img`
  width: 350px;
  height: auto;
  object-fit: cover;
  @media ${device.desktop} {
    width: 250px;
  }
`;

export const WarningImageText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  margin-left: 5px;
  @media ${device.mediumMobile} {
    font-size: 11px;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 400px;
  svg {
    width: 20px;
  }
  @media ${device.mediumMobile} {
    width: 300px;
  }
  @media ${device.smallMobile} {
    width: 230px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const InputFileLabel = styled.label`
  width: 320px;
  height: 44px;
  border-radius: 8px;
  background: ${SECONDARY_COLOR};
  padding: 4px 21px;
  cursor: pointer;
  display: inline-block;
  font-family: NotoSansJPBold;
  color: ${LIGHT};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='file'] {
    display: none;
  }
  @media ${device.mediumMobile} {
    width: 300px;
  }
  @media ${device.smallMobile} {
    width: 230px;
  }
`;

export const ErrorMessageLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;

export const AdditionalInfoWrapper = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px;
  @media ${device.desktop} {
    margin-bottom: 40px;
  }
  @media ${device.laptops} {
    grid-template-columns: 35% 65%;
  }
  @media ${device.mediumLaptop} {
    grid-template-columns: 25% 75%;
  }
`;

export const AdditionalInfoLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold;
  margin-top: 2px;
`;

export const RowStyled = styled(Row)`
  margin: 0px;
  justify-content: space-between;

  @media ${device.laptops} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.mediumLaptop} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ColStyled = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

export const RowLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const InputSelectContainer = styled.div`
  width: 100%;
`;

export const FormInputWrapper = styled.div`
  width: 80%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const FormLabelSimpleWrapper = styled.div`
  width: 90%;
  text-align: start;
  padding-right: 10px;
  margin: 20px 0px;

  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormLabelSimple = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;
