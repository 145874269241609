import { createTheme } from 'react-data-table-component';

export const acredittaTheme = {
  text: {
    primary: '#525f7f',
    secondary: '#525f7f',
  },
  striped: {
    default: 'rgba(0,0,0,.05)',
    text: '#525f7f',
  },
};

createTheme('acreditta', acredittaTheme, 'light');
