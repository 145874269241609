import * as React from 'react';
import { OrganizationSocialMediaContainer } from './styles';
import icons from 'assets/Icon';
import AcredittaText from 'components/atoms/AcredittaText';
import customStyles from './custom.module.css';

interface OrganizationBadgesInfoProps {
  description: string;
  organization_name: string;
  organization_icon: string;
  organization_email: string;
  organization_website: string;
  organization_facebook: string;
  organization_twitter: string;
  organization_linkedin: string;
  organization_instagram?: string;
}

const OrganizationBadgesInfo = ({
  description,
  organization_name,
  organization_icon,
  organization_facebook,
  organization_linkedin,
  organization_twitter,
  organization_instagram,
}: OrganizationBadgesInfoProps): JSX.Element => {
  return (
    <>
      <div className="row mb-4">
        <div className="col-xs-12 col-md-4">
          <img
            src={organization_icon}
            alt={`organization-icon`}
            width="200px"
            style={{ margin: '10px auto', display: 'block' }}
          />
        </div>
        <div className="col-xs-12 col-md-8">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <AcredittaText variant="h2" className={customStyles.centerxs}>
                {organization_name}
              </AcredittaText>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className={customStyles.socialDiv}>
                <OrganizationSocialMediaContainer className={customStyles.centerFlexXtraSmall}>
                  {organization_linkedin && (
                    <a href={organization_linkedin} target="_blank" rel="noreferrer">
                      <icons.LinkedinIconBlue />
                    </a>
                  )}
                  {organization_twitter && (
                    <a href={organization_twitter} target="_blank" rel="noreferrer">
                      <icons.TwitterIconBlue />
                    </a>
                  )}
                  {organization_facebook && (
                    <a href={organization_facebook} target="_blank" rel="noreferrer">
                      <icons.FacebookIconBlue />
                    </a>
                  )}
                  {organization_instagram && (
                    <a href={organization_instagram} target="_blank" rel="noreferrer">
                      <icons.IconoInstagramBlue />
                    </a>
                  )}
                </OrganizationSocialMediaContainer>
              </div>
            </div>
          </div>
          <div className={customStyles.separatorDiv}></div>
          <div className="row">
            <div className="col-xs-12 p-3">
              <AcredittaText variant="body1" color="secondary" className={customStyles.centerxs}>
                {description}
              </AcredittaText>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationBadgesInfo;
