import Icons from 'assets/Icon/index';
import AcredittaText from 'components/atoms/AcredittaText';
import './styles.css';

type variant = 'emitidas' | 'aceptadas' | 'compartidas' | 'pendientes';

interface DashboardIssueCardInterface {
  data: number | string;
  variant: variant;
}

const CardDashboardIssue = ({ data, variant }: DashboardIssueCardInterface) => {
  const renderIcon = () => {
    switch (variant) {
      case 'emitidas':
        return <Icons.InsigniasEmitidasIcon />;
      case 'aceptadas':
        return <Icons.InsigniasAceptadasIcon />;
      case 'compartidas':
        return <Icons.InsigniasCompartidasIcon />;
      case 'pendientes':
        return <Icons.InsigniasPendientesIcon />;
    }
  };
  return (
    <div className="issue-card">
      <div className="issue-card__data">
        <div>{renderIcon()}</div>
        <AcredittaText variant="h1">{data}</AcredittaText>
      </div>
      <div className="issue-card__data">
        <AcredittaText fontWeight="400" variant="body2" color="secondary">
          Insignias {variant}
        </AcredittaText>
      </div>
    </div>
  );
};

export default CardDashboardIssue;
