import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Col, Label, Modal } from 'reactstrap';
import styled from 'styled-components';

export const OrganizationBadgesInfoContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  justify-content: center;
`;

export const OrganizationIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
    margin-right: 20px;
  }
`;

export const OrganizationInfoDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrganizationInfoDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const OrganizationName = styled.a`
  font-family: NotoSansJPBold;
  font-size: 25px;
  line-height: 34px;
  color: ${PRIMARY_COLOR};
  margin: 10px 0px;
`;

export const BadgesInfo = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 35px;
  line-height: 48px;
  text-shadow: 0px 4px 4px rgba(138, 149, 158, 0.15);
  color: ${PRIMARY_COLOR};
  margin-right: 10px;
`;

export const BadgesLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 18px;
  color: ${PRIMARY_COLOR};
`;

export const OrganizationDescription = styled(Label)`
  font-size: 16px;
  line-height: 30px;
  color: #53565a;
`;

export const OrganizationTheme = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 15px;
            text-align: 'right',
  color: ${PRIMARY_COLOR};
`;

export const OrganizationDescriptionContinaer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrganizationSocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${PRIMARY_COLOR};
  svg {
    margin: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  svg:hover {
    opacity: 0.6;
  }
`;

export const ModalContainer = styled(Modal)`
  max-width: 250px;
  max-height: 250px;

  @media ${device.tablet} {
    max-width: 250px;
    max-height: 250px;
  }
  @media ${device.bigMobile} {
    max-width: 250px;
    margin: 1.75rem auto;
    top: 10%;
    max-height: 250px;
  }
  @media ${device.mediumMobile} {
    max-width: 250px;
    max-height: 250px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
    max-height: 250px;
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 15px;
  padding: 3% 5%;
`;

export const ColStyled = styled(Col)``;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  padding: 0% 20%;
`;

export const ModalTextFile = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 15px;
  padding: 0% 5%;
  text-align: center;
`;

export const ModalTextFooter = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  text-align: center;
`;

export const UploadFileWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 22px;
`;
