import icons from 'assets/Icon';
import * as React from 'react';
import TextInput, { TextInputProps } from '../TextInput';
import {
  ShowHideIconWrapper,
  ShowHideInputWrapper,
  ShowHideWrapper,
} from './styles';

interface TextPasswordInputProps extends TextInputProps {}

const TextPasswordInput: React.FC<TextPasswordInputProps> = ({ error, name, ...rest }) => {
  const [passwordShown, setPasswordShown] = React.useState<boolean>(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <React.Fragment>
      <ShowHideWrapper>
        <ShowHideInputWrapper>
          <TextInput
            {...rest}
            type={passwordShown ? 'text' : 'password'}
            name={name}
            error={error}
          />
        </ShowHideInputWrapper>
        <ShowHideIconWrapper onClick={togglePasswordVisibility}>
          {passwordShown ? <icons.EyeBlockedIcon /> : <icons.EyeIcon />}
        </ShowHideIconWrapper>
      </ShowHideWrapper>
    </React.Fragment>
  );
};

export default TextPasswordInput;
