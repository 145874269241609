import icons from 'assets/Icon';
import { SocialMedia } from 'constants/SocialMedia';
import * as React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

const FooterSocials: React.FC = () => {
  return (
    <Nav>
      <NavItem>
        <NavLink href={SocialMedia.facebook} target="_blank">
          <icons.FacebookWhite />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={SocialMedia.facebook} target="_blank">
          <icons.InstagramWhite />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={SocialMedia.facebook} target="_blank">
          <icons.LinkedinWhite />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={SocialMedia.facebook} target="_blank">
          <icons.TwitterWhite />
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default FooterSocials;
