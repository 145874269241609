import * as React from 'react';
import { SkillCardContainer, SkilName } from './styles';
import icons from 'assets/Icon';

interface SkillButtonProps {
  skill: string;
  handleSkill: () => void;
}

const SkillButton: React.FC<SkillButtonProps> = ({ skill, handleSkill }) => {
  return (
    <SkillCardContainer>
      <SkilName> {skill} </SkilName>
      <icons.CloseIcon onClick={handleSkill} />
    </SkillCardContainer>
  );
};

export default SkillButton;
