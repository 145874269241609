import * as React from 'react';
import { Row } from 'reactstrap';
import CourseCard from 'components/atoms/CourseCard';
import icons from 'assets/Icon';
import { CheckWrapper, ColStyled } from './styles';
import { arrayObjectIndexOf } from 'utils';
import { useAppDispatch } from 'hooks/redux';
import { addCourseSelection, removeCourseSelection } from 'states/slices/course.slice';

interface CoursesCardsProps {
  courses: ISingleCourse[];
}

const CoursesCards = ({ courses }: CoursesCardsProps): JSX.Element => {
  const [selected, setSelected] = React.useState<ISingleCourse[] | null>(null);
  const dispatch = useAppDispatch();

  const isSelected = React.useCallback(
    (course: ISingleCourse) => {
      return !!(
        selected &&
        selected.filter((courseSelected) => courseSelected.name === course.name).length > 0
      );
    },
    [selected]
  );

  const selectCourse = (course: ISingleCourse) => {
    let helper = selected ? selected : [];
    const verifiedCourse = helper.find((courseFiltered) => courseFiltered.name === course.name);

    if (!verifiedCourse) {
      helper = [...helper, course];

      dispatch(addCourseSelection(course.id));

      setSelected(helper);
    } else {
      const index = arrayObjectIndexOf(helper, course.name, 'name');

      if (index > -1) {
        const removedCourseItem = helper.filter((skillFilter) => skillFilter.name !== course.name);

        dispatch(removeCourseSelection(course.id));

        setSelected(removedCourseItem);
      }
    }
  };

  return (
    <Row>
      {courses.map((course, indexCourse) => {
        return (
          <ColStyled md="3" sm="4" xs="12" key={indexCourse} onClick={() => selectCourse(course)}>
            {course.image === null ? (
              <CourseCard
                name={course.name}
                image={course.image_url}
                company={course.provider_name}
                start={course.start_date}
                score={course.score}
                skills={course.skill_count}
                duration={course.duration}
                price={course.cost}
                companyColor={'003DA6'}
              />
            ) : (
              <CourseCard
                name={course.name}
                image={course.image}
                company={course.provider_name}
                start={course.start_date}
                score={course.score}
                skills={course.skill_count}
                duration={course.duration}
                price={course.cost}
                companyColor={'003DA6'}
              />
            )}
            {isSelected(course) && (
              <CheckWrapper>
                <icons.CheckIcon />
              </CheckWrapper>
            )}
          </ColStyled>
        );
      })}
    </Row>
  );
};

export default CoursesCards;
