import icons from 'assets/Icon';
import * as React from 'react';
import {
  ShareMenuContainer,
  MenuItemWrapper,
  MenuItemTitle,
  MenuItemWrapperContainer,
} from './styles';
import ShareBadgeEmail from 'components/atoms/ShareBadgeEmail';
import ShareBadgeLink from 'components/atoms/ShareBadgeLink';
import ShareBadgeImage from 'components/atoms/ShareBadgeImage';
import { NonAuthenticatedUrls } from 'constants/NavigationUrls';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import ShareBadgeCode from 'components/atoms/ShareBadgeCode';

interface ShareBadgeProps {
  badge_details: any;
  closeShareSection: () => void;
}

const ShareBadgeTemp = ({ badge_details, closeShareSection }: ShareBadgeProps): JSX.Element => {
  const [menuItemSelected, setMenuItemSelected] = React.useState<string>('Enlace');

  const menuItems = [
    {
      name: 'Correo',
      icon: <icons.EmailBlueIcon />,
      component: (
        <ShareBadgeEmail closeShareSection={closeShareSection} badgeId={badge_details.data.id} />
      ),
    },
    {
      name: 'Enlace',
      icon: <icons.LinkBlueIcon />,
      component: (
        <ShareBadgeLink
          closeShareSection={closeShareSection}
          badge_url={`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.badgesBack}/${badge_details.data.id}`}
        />
      ),
    },
    {
      name: 'Imagen',
      icon: <icons.ImageBlueIcon />,
      component: (
        <ShareBadgeImage
          image_url={badge_details.data.image_url}
          closeShareSection={closeShareSection}
          badgeId={badge_details.data.id}
        />
      ),
    },
    {
      name: 'JSON',
      icon: <icons.DocumentIcon />,
      component: (
        <ShareBadgeCode badge_id={badge_details.id} closeShareSection={closeShareSection} />
      ),
    },
  ];

  return (
    <div>
      <ShareMenuContainer>
        {menuItems &&
          menuItems.map((menuItem, index) => {
            return (
              <MenuItemWrapperContainer>
                <MenuItemWrapper
                  key={index}
                  $selected={menuItemSelected === menuItem.name}
                  onClick={() => setMenuItemSelected(menuItem.name)}
                >
                  {menuItem.icon}
                  <MenuItemTitle
                    $selected={menuItemSelected === menuItem.name}
                    onClick={() => setMenuItemSelected(menuItem.name)}
                  >
                    {menuItem.name}
                  </MenuItemTitle>
                </MenuItemWrapper>
                <React.Fragment>
                  {index + 1 < menuItems.length && <icons.DividerIcon />}
                </React.Fragment>
              </MenuItemWrapperContainer>
            );
          })}
      </ShareMenuContainer>
      {menuItems &&
        menuItemSelected &&
        menuItems.map((item, index) => {
          return (
            <div key={index}>{item.name === menuItemSelected && <div>{item.component}</div>}</div>
          );
        })}
    </div>
  );
};

export default ShareBadgeTemp;
