import styled from 'styled-components';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';

export const AdminDnDCourseCardPathwayWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  width: 240px;
  flex: 0 0 auto;
  cursor: pointer;
`;

export const AdminDnDCourseCardPathwayTitle = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  color: ${PRIMARY_COLOR};
`;

export const AdminDnDCourseCardPathwayFooter = styled.div`
  border-radius: 0 0 8px 8px;
  background-color: #003da6;
  border-color: #003da6;
  height: 19px;
  padding-left: 10px;
`;

export const AdminDnDCourseCardPathwayFooterLabel = styled(Label)`
  font-size: 12px;
  font-family: NotoSansJPBold, sans-serif;
  color: white;
  display: flex;
  align-self: center;
`;

export const AdminDnDCourseCardPathwayLabel = styled(Label)`
  font-size: 14px;
  margin-bottom: 0;
`;

export const AdminDnDCourseCardPathwayIconWrapper = styled.div`
  margin-right: 4px;
  width: 20px;
  display: flex;
  align-self: center;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const AdminDnDCourseCardPathwayTimeWrapper = styled.div`
  display: flex;
`;

export const AdminDnDCourseCardPathwayImageUnionWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-right: 10px;
  margin-left: 10px;
`;
