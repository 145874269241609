import { updatePhotoSignature, uploadSignaturePhoto } from 'actions/ApiClient/certificates-pdf';
import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaText from 'components/atoms/AcredittaText';
import { manageErrors } from 'helpers/manage-error';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import picture from '../../../../assets/images/picture-img.png';
import styles from './styles.module.css';

interface Props {
  position: number;
  signature: any;
  onFinish: (value: number, position: number) => void;
  isInvalid: (value: boolean) => void;
}

const UploadSignature = ({ position, signature, onFinish, isInvalid }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [signatureName, setSignatureName] = useState(signature.name || '');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (signature.id) {
      setEditMode(true);
    }
  }, [signature]);

  const handleUploadImage = async (e: any) => {
    try {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      formData.append('name', signatureName);
      let response;

      if (editMode) {
        response = await updatePhotoSignature(signature.id, formData);
      } else {
        response = await uploadSignaturePhoto(formData);
      }

      toast.success('Se ha actualizado la imágen de la firma correctamente');
      onFinish(response.data.id, position);
    } catch (e: any) {
      setUploading(false);
      manageErrors(e, e.response?.data?.message);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setSignatureName(e.target.value);
  };

  const handleBlur = () => {
    setError('');
    setTouched(true);
    isInvalid(false);

    if (signatureName.trim().length === 0) {
      isInvalid(true);
      setError(`La firma ${position} es requerida`);
      return;
    }

    setTimeout(async () => {
      if (editMode) {
        try {
          const formData = new FormData();
          formData.append('name', signatureName);
          await updatePhotoSignature(signature.id, formData);
          toast.success('Nombre de la firma actualizado correctamente');
        } catch (e: any) {
          manageErrors(e, e.response?.message);
        }
      }
    }, 800);
  };

  return (
    <>
      <div className="mb-3">
        <input
          value={signatureName}
          type="text"
          name="signatureName"
          className={`form-control ${error && touched && 'is-invalid'}`}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={`Escribe el nombre de la firma ${position}`}
        />
        {error && touched && <span className="invalid-feedback">{error}</span>}
      </div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={handleUploadImage}
        accept="image/png"
      />
      <div>
        <AcredittaText variant="body1" color='secondary' style={{ fontSize: "14px"}}>Imagen recomendable de 1200 x 1200 px</AcredittaText>
      </div>
      <div className="d-flex align-items-center mt-3">
        <div>
          <img src={picture} alt="" width={20} height={20} className={styles.miniImage} />
        </div>
        <div>
          <AcredittaButton
            title="Buscar firma"
            onPress={() => inputRef.current?.click()}
            variant="small"
            type="button"
            isLoading={uploading}
            disabled={uploading || !signatureName}
          />
        </div>
      </div>
    </>
  );
};

export default UploadSignature;
