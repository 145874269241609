import { REACT_APP_API } from './Environments';

export const API_URL = REACT_APP_API;

export const AccountsUrls = {
  terms: 'accounts/update-user/',
  userData: 'accounts/user/',
  userDataModify: 'accounts/user/',
  userDataDetail: 'accounts/user-profile/',
  userPhoneNumber: 'accounts/phone-number/',
  addMasiveUsers: 'accounts/employee/upload/',
  regsiterUser: 'accounts/sign-up/',
  verifyUser: 'accounts/confirm-sign-up/',
};

export const OnboardingUrls = {
  interestList: 'organization/interest/',
  skillsList: 'config/skill/',
  createInterestSkills: 'accounts/onboarding/',
  skillsTagList: 'skills/',
};

export const AdminUrls = {
  analyticDataContent: 'reports/monthly-content-overview/f7b7d6ce-9e7b-4028-86f3-88ea478fb9df',
  massiveCreation: 'accounts/cognito/upload/',
  createSingleUser: 'accounts/cognito/',
  loadUsers: 'accounts/organization-users-management-list',
  countries: 'config/country/',
  documentType: 'config/dni_type/',
  userType: 'accounts/user-type/',
  cities: 'config/city/',
  interest: 'organization/interest/',
  interestHab: 'config/interest/',
  createInterest: 'organization/profile/',
  organizationList: 'organization/profile/',
  departmentsForOrganization: 'accounts/organization-department/',
  usersForOrganization: 'accounts/organization-user/',
  usersInvitations: 'config/invitation/',
  courseCatalog: 'pathways/course-filter/',
  courseCatalogFilterOptions: 'pathways/course-menu-filter/',
  courseCatalogLearningExperience: 'pathways/experience/',
  learningExperienceCategory: 'pathways/experience-category/',
  learningPathListView: 'pathways/path-element-listview/',
  updateLearningPathListView: 'pathways/path-element-update/',
  createPathwayCourseCatalog: 'pathways/custom-path/',
  courseSelectedCourseList: 'pathways/selected-course-list/',
  courseSelectedCourseListUnauth: 'pathways/courses/public/selected-courses/dashboard',
  pathwayDescription: 'pathways/path/',
  pathwaySkills: 'pathways/path-skill/',
  coursePreview: 'pathways/course-dashboard/',
  pathwayDashboard: 'pathways/path-dashboard/',
  pathwayDashboardUnauth: 'pathways/paths/public/dashboard/',
  pathwayBadges: 'pathways/badge-template-list/',
  pathwayDnD: 'pathways/path-review-short/',
  valdiateEmail: 'accounts/validate-badge-users/',
  pathwayContentCourse: 'pathways/content-course/',
  pathwayContentCourseUnauth: 'pathways/courses/public/contents',
  newLearningPath: 'pathways/path/',
  newCourse: 'pathways/course/',
  addBadge: 'pathways/path-badge-template/',
  languages: 'config/language/',
  interestCourse: 'pathways/interest-course/',
  recommendedCourse: 'pathways/recommended-course/',
  organizationCourse: 'pathways/organization-course/',
  popularCourse: 'pathways/popular-course/',
  modalitys: 'config/modality/',
  methodologies: 'config/methodology/',
  routeExperiences: 'pathways/experience-path/',
  updateOwnCourses: 'organization/own-courses/',
  listPaths: 'pathways/list-path/',
  OrganizationlistPaths: 'pathways/published-list-path/',
  listBadges: 'badges/badge-template-detail/',
  listBadgesTemplate: 'badges/badge-templates/',
  listBadgesT: 'badges/badge-template-detail/',
  detailBadges: 'integrations/credly-badge-template-detail/2/',
  deletePaths: 'pathways/disable-path/',
  deleteUsers: 'accounts/disable-user/',
  assignPaths: 'pathways/assign-path/',
  routeLevels: 'pathways/level/ ',
  createNewBadgeTemplate: 'badges/badge-template/',
  organizarionOverview: 'organization/overview/',
  organizarionBadgesInfo: 'organization/badge-template-card/',
  shareBadge: 'badges/share-badge/',
  downloadBadgeImage: 'badges/badge-image-download/',
  downloadBadgeImageCert: 'cert-acreditta/v2/download-image-badge/',
  issueMassive: 'badges/badge/upload/',
  validatePlan: 'payments/validate-plan/',
  themes: 'config/themes/',
  themesOrganization: 'config/organization-themes/',
  resendInvitation: 'config/resend-invitation/',
  editProfileInfo: 'accounts/profile/',
  editProfileImage: 'accounts/profile-avatar/',
  recommendedPaths: 'pathways/paths/recommended/list-card/',
  organizationContent: 'pathways/paths/organizations/list-card/',
  configSubscription: 'organization/statistics/',
  configPermission: 'config/organizations/permissions/',
  deleteMyUser: 'accounts/delete/',
  acceptBadge: 'badges/badge/accepted/',
  isPublicBadge: 'badges/badge/visible/',
  listBadgesTemplates: 'badges/templates/list/',
  listBadgesTemplatesArchived: 'badges/archived-templates/list',
  listBadgesUsers: 'badges/templates/users/',
  getReminderIndividual: 'badges/reminder/individual/',
  postReminderMasive: 'badges/reminder/massive/',
  getRenewBadge: 'badges/renew/individual/',
  archivedBadge: 'badges/badge/archived/',
  userContactMethods: 'badges/user-contact-method/'
};

export const NewOrganizationUrls = {
  createNewOrganization: 'organization/profile/',
};

export const PublicUrls = {
  inviteByUser: 'config/invitation-user/',
  listMyRoutes: 'accounts/path-card/',
  interestListProfile: 'accounts/user-interest/',
  detailBadges: 'badges/badge-detail/',
  detailBadgeOrg: 'badges/badge-template-detail/',
  organizationBadges: 'badges/badge-template-detail/',
  organizationInfo: 'integrations/credly-organization-detail/',
  verifyBadge: 'badges/badge-assertion/',
  uploadEvidence: 'pathways/path-user-evidence/',
  statusEvidence: 'pathways/evidence-status/',
  employeeList: 'accounts/number-employee/',
  verifyEmail: 'accounts/reset-password/',
  sectorList: 'organization/sectors'
};

export const IntegrationsUrls = {
  getOrganizationBadges: 'badges/badge-detail/',
  getBadgeDetail: 'badges/badge-detail/',
  listBadges: 'badges/badge-template-detail/',
  shareBadgesData: 'badges/share-badge-rrss/',
  shareBadgesJson: 'cert-acreditta/v2/badge-assertions/',
};

export const TagsAcredittaUrls = {
  listTags: 'badges/templates/tags/',
  TagsAcreditado: 'accounts/accredited-type-tags/'
}