import styled, { css } from 'styled-components';

export const DropZoneContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const DropZoneRow = styled.div<{ $isActive?: boolean }>`
  display: flex;
  padding: 20px;
  min-height: 162px;
  ${(props) =>
    props.$isActive &&
    css`
      background-color: #f9f9f9;
    `}
`;
