import { Formik } from 'formik';
import BadgeEditForm from './BadgeEditForm';
import { useContext } from 'react';
import { BadgeContext } from './BadgeContext';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { editBadgeValidationSchema } from 'validations/badges';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import { Link } from 'react-router-dom';
import icons from 'assets/Icon';
import { AdminRole } from 'constants/NavigationUrls';

const BadgeEditLayout = () => {
  const { isFetching, handleFormSubmit, initialValues } = useContext(BadgeContext);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {isFetching ? (
        <div className="mt-3">
          <AcredittaLoader />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-sm-12 col-md-2 pt-4 text-sm-center">
              <Link to={AdminRole.badgesPreview}>
                <icons.GoBackIcon />
              </Link>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={editBadgeValidationSchema}
            enableReinitialize={true}
            onSubmit={(values: any) => {
              handleFormSubmit(values);
            }}
          >
            <div className="container-fluid">
              <BadgeEditForm />
            </div>
          </Formik>
        </>
      )}
    </CommonPageLayout>
  );
};

export default BadgeEditLayout;
