import * as React from 'react';
import StyledButton from 'components/atoms/Button';
import { StepWizardProps } from 'react-step-wizard';
import {
  CoursesCatalogContainer,
  CoursesSectionCatalogContainer,
  TitleWrapper,
  Title,
  BarSearchContainer,
  RowStyled,
  SearchIconContainer,
  ColStyled,
  IconFilterWrapper,
  SubtitleFirst,
  ContainerNoData,
  ContainNoData,
  SubtitleSecondNoData,
  ButtonWrapper,
  SubtitleFirstNoData,
} from './styles';
import CoursesCards from 'components/molecules/CoursesCards';
import icons from 'assets/Icon';
import TextInput from 'components/atoms/TextInput';
import CourseFilters from 'components/molecules/CourseFilters';
import Pagination from 'components/molecules/Pagination';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  AdminCourseCatalogFilterOptionsThunk,
  AdminCourseCatalogThunk,
  CreatePathwayCatalogCourseThunk,
} from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { objectValidation } from 'utils/index';
import { defaultCourseSelection } from '../../../states/slices/course.slice';
import { Col } from 'reactstrap';
import AdminUploadNewIndividualCourse from 'components/organisms/AdminUploadNewIndividualCourse';
import ReactLoading from 'react-loading';
import Button from 'components/atoms/Button';

interface AdminCourseCatalogProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

interface SearchBarForm {
  search: string;
}

const AdminCatalogNewPath = ({ onNextStep }: AdminCourseCatalogProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [toggleFilter, setToggleFilter] = React.useState<boolean>(false);
  const [uploadNewCourse, setUploadNewCourse] = React.useState<boolean>(false);
  const [wordSearch, setWordSearch] = React.useState<string>('');

  const admin = useAppSelector((state) => state.admin);
  const courseSelector = useAppSelector((state) => state.courses);
  const user = useAppSelector((state) => state.user);

  const defaultValuesSearch: SearchBarForm = {
    search: '',
  };

  const getDefaultFormSearch = (): SearchBarForm => {
    return defaultValuesSearch;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<SearchBarForm>({
    ...objectValidation,
    defaultValues: getDefaultFormSearch(),
  });

  const keywords = watch().search;

  const listCourseCatalog = React.useCallback(async () => {
    const courses = await dispatch(AdminCourseCatalogThunk({}));
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  }, [dispatch]);

  const toggleFilterHandler = () => {
    setToggleFilter(!toggleFilter);
  };

  const menuFilterOptionItems = React.useCallback(async () => {
    const menuFilter = await dispatch(AdminCourseCatalogFilterOptionsThunk());

    if (AdminCourseCatalogFilterOptionsThunk.rejected.match(menuFilter)) {
      toast.error(menuFilter.payload as string);
    }
  }, [dispatch]);

  const handleSearch = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: keywords,
      })
    );
    setWordSearch(keywords);
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleSearchClear = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: '',
      })
    );

    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleUserKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(handleSearch)();
      reset();
    }
  };

  const handleSearchPage = async (page: number) => {
    setCurrentPage(page);
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        page: page,
      })
    );
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
    reset();
  };

  const addSelectCoursePathway = async () => {
    if (courseSelector.selectedCourse.length > 0) {
      if (courseSelector.selectedCourse.length <= 8) {
        const createCourseSelectionPathway = await dispatch(
          CreatePathwayCatalogCourseThunk({ course: courseSelector.selectedCourse })
        );
        if (CreatePathwayCatalogCourseThunk.rejected.match(createCourseSelectionPathway)) {
          toast.error(createCourseSelectionPathway.payload as string);
        }
        onNextStep();
      } else {
        toast.error('La ruta tiene permitido agregar una cantidad máxima de 8 cursos.');
      }
    } else {
      toast.error(
        'Para continuar debes seleccionar los cursos que deseas agregar en tu ruta de aprendizaje.'
      );
    }
  };

  const handleNewCourseSubmit = () => {
    setUploadNewCourse(false);
  };

  React.useEffect(() => {
    listCourseCatalog();
    menuFilterOptionItems();
    dispatch(defaultCourseSelection());
  }, [dispatch, listCourseCatalog, menuFilterOptionItems]);

  return (
    <div>
      {!uploadNewCourse ? (
        <CoursesCatalogContainer>
          <CoursesSectionCatalogContainer>
            <TitleWrapper sm="12">
              <Title>
                Selecciona los cursos que quieres que hagan parte de una ruta de aprendizaje
              </Title>
            </TitleWrapper>
            <Col>
              {user.userRole !== 3 ? (
                <SubtitleFirst onClick={() => setUploadNewCourse(true)}>
                  Cargar curso +
                </SubtitleFirst>
              ) : (
                <div></div>
              )}
            </Col>
          </CoursesSectionCatalogContainer>
          <RowStyled>
            <BarSearchContainer sm="12">
              <IconFilterWrapper onClick={toggleFilterHandler}>
                <icons.FilterIcon />
              </IconFilterWrapper>
              <div>
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      name="search"
                      error={errors}
                      height={35}
                      placeholder="Buscar contenido..."
                      value={value}
                      onChange={onChange}
                      onKeyPress={handleUserKeyPressSearch}
                    />
                  )}
                  name="search"
                />
                <SearchIconContainer onClick={handleSubmit(handleSearchClear)}>
                  <icons.CleanSearchIcon />
                </SearchIconContainer>
              </div>
            </BarSearchContainer>
          </RowStyled>
          {toggleFilter && <CourseFilters />}
          {admin.courseCatalog && admin.courseCatalog.results ? (
            admin.courseCatalog.results.length !== 0 ? (
              <div>
                <React.Fragment>
                  <CoursesCards courses={admin.courseCatalog.results} />
                  <Pagination
                    currentPage={currentPage}
                    totalCount={admin.courseCatalog.count}
                    pageSize={20}
                    onPageChange={(page: number) => handleSearchPage(page)}
                  />
                </React.Fragment>
                <ColStyled sm={{ size: 4, offset: 4 }}>
                  <StyledButton label="Agregar" onClick={addSelectCoursePathway} />
                </ColStyled>
              </div>
            ) : (
              <ContainerNoData
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5%',
                }}
              >
                <ContainNoData>
                  <icons.Icono_Busqueda />
                  <SubtitleFirstNoData>No encontramos resultados</SubtitleFirstNoData>
                  <SubtitleSecondNoData>
                    Buscamos "{wordSearch}" y no hemos encontrado elementos relacionados
                  </SubtitleSecondNoData>
                  <ButtonWrapper>
                    <Button
                      label={'Borrar búsqueda'}
                      widthCustom={300}
                      onClick={handleSubmit(handleSearchClear)}
                    />
                  </ButtonWrapper>
                </ContainNoData>
              </ContainerNoData>
            )
          ) : (
            <ContainerNoData
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10%',
              }}
            >
              <ReactLoading type="spin" color={'black'} height={120} width={120} />
            </ContainerNoData>
          )}
        </CoursesCatalogContainer>
      ) : (
        <AdminUploadNewIndividualCourse
          handleCourseSubmit={handleNewCourseSubmit}
          hideNewCourseSection={() => setUploadNewCourse(false)}
        />
      )}
    </div>
  );
};

export default AdminCatalogNewPath;
