import styled from 'styled-components';
import { Form, FormGroup, Label } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const PasswordForm = styled(Form)`
  padding: 50px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PasswordFormGroup = styled(FormGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PasswordTitle = styled(Label)`
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: normal;
  color: #002057;

  @media ${device.mobile} {
    font-size: 35px;
  }
`;

export const PasswordSubTitle = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #53565a;
  padding-bottom: 40px;
`;

export const PasswordContainer = styled.div`
  text-align: center;
`;
