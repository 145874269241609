import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import styled from 'styled-components';

export const TitleAnalyticsBars = styled.h1`
  width: 100%;
  font-size: 30px;
  line-height: 40.86px;
  text-align: center;
  color: ${PRIMARY_COLOR};
  margin-top: 20px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const BarsChartContainer = styled.div`
  justify-content: center;
  display: flex;
`;
