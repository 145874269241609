import icons from 'assets/Icon';
import toast from 'react-hot-toast';
import 'react-phone-input-2/lib/style.css';
import { useAppDispatch } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { columns } from './badge-table-settings';
import DataTable from 'react-data-table-component';
import AcredittaBox from 'components/atoms/AcredittaBox';
import { getUsersByBadge } from 'services/badges.service';
import CommonPageLayout from '../Layout/CommonPageLayout';
import AcredittaText from 'components/atoms/AcredittaText';
import { manageErrors } from '../../../helpers/manage-error';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AssignHeader, CourseCatalogContainer } from './styles';
import { PostReminderMasiveThunk } from 'actions/Thunks/AcreditedThunk';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import SearchInput from './SearchInput';

interface AdminBadgeUsers {
  badge_id: string;
  name: string;
}

const AdminPreviewBadgesUsers = ({ badge_id, name }: AdminBadgeUsers): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const fetchData = useCallback(
    async (page, search?: string) => {
      try {
        setLoading(true);
        const resp = await getUsersByBadge(badge_id, page, search);
        setTotalRows(resp.data.count);
        setData(resp.data.results);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        manageErrors(e);
      }
    },
    [badge_id]
  );

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  const handlePageChange = (page: any) => {
    fetchData(page, filterText);
  };

  const reminderMasive = React.useCallback(async () => {
    const adminInfoData = await dispatch(PostReminderMasiveThunk(badge_id));
    if (PostReminderMasiveThunk.rejected.match(adminInfoData)) {
      toast.error(adminInfoData.payload as string);
    }
    toast.success('Recordatorio masivo enviado exitosamente a todos los usuarios');
  }, [dispatch, badge_id]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText('');
        setResetPaginationToggle(!resetPaginationToggle);
      }
    };

    fetchData(1, filterText);

    return <SearchInput onClear={handleClear} onChange={setFilterText} text={filterText} />;
  }, [filterText, resetPaginationToggle, fetchData]);

  return (
    <React.Fragment>
      <CommonPageLayout
        showHeader={true}
        showFooter={false}
        childrenMargin={false}
        showMenuHeader={true}
        showMenuHeaderAcredited={false}
        showCommonMenu={false}
        showCommon={true}
        showOnboarding={false}
      >
        <CourseCatalogContainer>
          <AssignHeader>
            <icons.GoBackIcon onClick={history.goBack} />
          </AssignHeader>

          {/* data table */}
          <AcredittaBox boxStyle={{ marginTop: 10 }}>
            <Row>
              <Col xs={12} sm={6}>
                <AcredittaText variant="h4">
                  Usuarios con insignia {decodeURIComponent(name)}
                </AcredittaText>
              </Col>
              <Col xs={12} sm={6} className="text-right">
                <Button onClick={() => reminderMasive()}>
                  <icons.Icono_Reenviar
                    style={{
                      width: '15px',
                      height: '15px',
                      marginRight: '10px',
                    }}
                  />
                  Recordatorio masivo
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              noDataComponent="No hay datos"
              theme="acreditta"
              data={data}
              dense
              pagination
              paginationServer
              striped
              paginationComponentOptions={{
                rowsPerPageText: 'Filas por página',
                noRowsPerPage: true,
              }}
              paginationResetDefaultPage={resetPaginationToggle}
              progressPending={loading}
              progressComponent={<AcredittaLoader width={45} height={45} />}
              paginationTotalRows={totalRows}
              paginationPerPage={20}
              onChangePage={handlePageChange}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
            />
          </AcredittaBox>
          {/* end table */}
        </CourseCatalogContainer>
      </CommonPageLayout>
    </React.Fragment>
  );
};

export default AdminPreviewBadgesUsers;
