import React from 'react';
import icons from 'assets/Icon';
import { Button, Card, Collapse } from 'reactstrap';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import {
  ChangeImageOrganizationThunk,
  ChangeThemeOrganizationThunk,
  GetThemesThunk,
} from 'actions/Thunks/BrandingThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useHistory } from 'react-router-dom';
import UploadFile from 'components/atoms/UploadImageFile';
import ButtonCom from 'components/atoms/ButtonCom';
import './styles.css';

const CustomizeYourTheme = () => {
  const [collapse, setCollapse] = useState(false);
  const [theme, setTheme] = React.useState<any>();
  const [currentImage, setCurrentImage] = useState<string>('');
  const [info, setInfo] = useState<any>({
    id: null,
    file: null,
    theme: null,
  });

  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  const postUploadPath = React.useCallback(async () => {
    if (info.file === null) {
      toast.error('Selecciona una imágen válida');
    } else {
      const fileUpload = await dispatch(ChangeImageOrganizationThunk(info));
      if (ChangeImageOrganizationThunk.rejected.match(fileUpload)) {
        toast.error(fileUpload.payload as string);
      } else {
        setCollapse(!collapse);
        toast('Logo cambiado correctamente.');
        history.go(0);
      }
    }
  }, [dispatch, history, info, collapse]);

  const postUploadTheme = React.useCallback(
    async (themeNumber: number) => {
      const themeUpload = await dispatch(
        ChangeThemeOrganizationThunk({
          id: user.userInfo?.branding.id,
          theme: themeNumber,
        })
      );
      if (ChangeThemeOrganizationThunk.rejected.match(themeUpload)) {
        toast.error(themeUpload.payload as string);
      } else {
        setCollapse(!collapse);
        toast('Tema cambiado correctamente.');
        history.go(0);
      }
    },
    [dispatch, history, collapse, user.userInfo?.branding.id]
  );

  const GetThemes = React.useCallback(async () => {
    const theme = await dispatch(GetThemesThunk());
    if (GetThemesThunk.rejected.match(theme)) {
      toast.error(theme.payload as string);
    } else {
      setTheme(theme.payload.results);
    }
  }, [dispatch]);

  const onSelectCsvFile = async (csvFile: File) => {
    setInfo({
      id: user.userInfo?.branding.id,
      file: csvFile,
      theme: null,
    });
    toast.success('Tu documento se subió correctamente.');
  };

  const toggleCollapse = () => {
    setCollapse(!collapse);
    setInfo({
      id: null,
      file: null,
      theme: null,
    });
  };

  useEffect(() => {
    GetThemes();
  }, [GetThemes]);

  useEffect(() => {
    if (info.file !== null) {
      setCurrentImage(URL.createObjectURL(info.file));
    }
  }, [info]);

  return (
    <div className="edit-theme">
      <div className="edit-theme__container" onClick={() => toggleCollapse()}>
        <icons.BrushIcon className="edit-theme__icon"  />
        <span className="edit-theme__button">
          Editar tema
        </span>
        <Collapse
          className="collapse"
          style={{
            zIndex: 20,
            position: 'absolute',
            top: '120%',
            right: '0',
            width: '321px',
            height: '379px',
            cursor: 'default',
          }}
          isOpen={collapse}
          onClick={(e) => e.stopPropagation()}
        >
          <Card className="card">
            <span className="card-title">Selecciona un tema</span>
            <div className="card-container__themes">
              {theme && (
                <>
                  {theme.map((them: any, themeIndex: any) => {
                    return (
                      <Button
                        key={themeIndex}
                        className="card-container__themes__button"
                        style={{
                          backgroundImage: `${them.value}`,
                          margin: '0px',
                        }}
                        onClick={() => postUploadTheme(them.id)}
                      />
                    );
                  })}
                </>
              )}
            </div>
            <span className="card-title">Logo del menú</span>
            <div className="card-image">
              <img src={currentImage || user.userInfo?.branding.image} alt="info" />
            </div>
            <UploadFile onSelectCsvFile={onSelectCsvFile} />
            {/* {info?.file !== null && <span>{info?.file.name}</span>} */}
            <span className="card-text">Recomendable imagen de 200x40 pixeles</span>
            <div>
              <ButtonCom
                label="Cambiar imagen"
                widthCustom={130}
                lightMode={true}
                onClick={() => postUploadPath()}
              />
            </div>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};

export default CustomizeYourTheme;
