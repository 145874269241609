import styled from 'styled-components';
import { NavbarBrand } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const NavBrand = styled.div`
  @media only screen and (max-width: 810px) {
    display: none;
  }
`;

export const NavbarBrandStyled = styled(NavbarBrand)`
  @media ${device.laptops} {
    svg {
      width: 140px;
    }
  }
  @media ${device.mediumLaptop} {
    svg {
      width: 130px;
    }
  }
  @media only screen and (max-width: 810px) {
    svg {
      width: auto;
    }
  }
`;

export const NavBrandMobile = styled.div`
  display: none;
  @media only screen and (max-width: 810px) {
    display: inline;
  }
`;

export const ButtonLogIn = styled.button`
  display: flex;
  text-aling: center;
  border: 2px solid #ffffff;
  border-radius: 8px;
  background-color: transparent;
  color: white;
  font-family: NotoSansJP;
  font-weight: 700;
  cursor: pointer;
`;
