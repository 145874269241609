import * as React from 'react';
import {
  CancelUploadingIcon,
  CancelUploadingWrapper,
  ProgressStyled,
  ProgressWrapper,
  UploadUserProgressWrapper,
} from './styles';

interface IUploadUserProgressProps {
  progress: number;
  onCancel?: () => void;
}

const UploadUserProgress: React.FC<IUploadUserProgressProps> = ({ progress, onCancel }) => {
  const progressText = React.useMemo(() => {
    if (progress < 100) {
      return `Subiendo... ${progress}%`;
    } else {
      return `Completado... ${progress}%`;
    }
  }, [progress]);

  return (
    <UploadUserProgressWrapper>
      <ProgressWrapper>
        <ProgressStyled value={progress}>{progressText}</ProgressStyled>
      </ProgressWrapper>
      {progress !== 100 && (
        <CancelUploadingWrapper onClick={onCancel}>
          <CancelUploadingIcon />
        </CancelUploadingWrapper>
      )}
    </UploadUserProgressWrapper>
  );
};

export default UploadUserProgress;
