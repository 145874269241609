import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
import CreateMassiveUser from 'pages/Admin/CreateMassiveUser';
import OnboardingWizardSteps from 'pages/Admin/OnboardingWizardSteps';
import OnboardingWizardStepsEdit from 'pages/Admin/OnboardingWizardStepsEdit';
import CreateUser from 'pages/Admin/CreateUser';
import Home from 'pages/Admin/Home';
import { default as LearningPathPreviewAdmin } from 'pages/Admin/LearningPathPreview';
import { default as LearningPathPreviewAcreditado } from 'pages/LearningPathPreview';
import CoursePreview from 'pages/Admin/CoursePreview';
import CatalogCourses from 'pages/Admin/CatalogCourses';
import AssignRoutes from 'pages/Admin/AssignRoutes';
import AssignBagdesPaths from 'pages/Admin/AssignBadgesPaths';
import CreateNewPath from 'pages/Admin/CreateNewPath';
import PreviewPaths from 'pages/Admin/PathPreview';
import PreviewBadges from 'pages/Admin/BadgesPreview';
import PreviewBadgesUsers from 'pages/Admin/BadgesPreviewUsers';
import AdminUploadNewIndividualCourseCatalog from 'components/organisms/AdminUploadNewIndividualCourseCatalog';
import AdminCreateNewBadgeTemplate from 'components/organisms/AdminCreateNewBadgeTemplate';
import BadgesDashboard from 'pages/Badges/Dashboard';
import OrganizationBadges from 'pages/Badges/OrganizationDashboard';
import BadgeDetails from 'pages/Badges/Details';
import DetailOrganizationBadge from 'pages/Badges/DetailOrganizationBadge';
import BadgeIssue from 'pages/Badges/Issue';
import DetailTemplateBadge from 'pages/Badges/DetailTemplateBadge';
import EndPathMsg from 'components/organisms/EndPath';
import EndPathAcept from 'components/organisms/EndPathAcept';
import UsersPreview from 'pages/Admin/UsersPreview';
import MassiveIssue from 'pages/Badges/MassiveIssue';
import Profile from 'pages/Profile';
import AdminEditPerfilInfo from 'components/organisms/AdminEditPerfilInfo';
import { AdminAnalyticsPage, BadgeDashboardPage } from 'pages/Admin/Analytics';
import AdminUserDetailPage from 'pages/Admin/AdminUserDetail';
import BadgeEdit from '../../pages/Badges/BadgeEdit/index';
import ConfigurationOrganizationPage from 'pages/Admin/ConfigureOrganizationProfile';
import BadgeTemplateInfo from 'components/organisms/BadgeTemplateInfo/BadgeTemplateInfo';
import BagdesStackableMain from 'pages/Badges/badges-stackable/BagdesStackableMain';
import BadgesStackableForm from '../../pages/Badges/badges-stackable/BadgesStackableForm';
import HomeDashboardPage from '../../pages/home-dashboard/HomeDashboardPage';

const AdminNavigation = (): JSX.Element => {
  return (
    <Switch>
      <Route path={AdminRole.onboardingRoot} component={OnboardingWizardSteps} />
      <Route path={`${AdminRole.editPath}/:pathwayId`} component={OnboardingWizardStepsEdit} />
      <Route path={AdminRole.newPathWizz} component={CreateNewPath} />
      <Route path={AdminRole.loadNewUsers} component={CreateUser} />
      <Route path={AdminRole.loadUsers} component={UsersPreview} exact />
      <Route path={`${AdminRole.loadUsers}/:id`} component={AdminUserDetailPage} exact />
      <Route path={`${AdminRole.templateInfo}/:badge_id`} component={BadgeTemplateInfo} exact />
      <Route path={AdminRole.loadMassiveUsers} component={CreateMassiveUser} />
      <Route path={AdminRole.home} component={Home} />
      <Route
        path={`${AdminRole.learningPathPreview}/:pathwayId`}
        component={LearningPathPreviewAdmin}
      />
      <Route
        path={`${AdminRole.learningPathPreviewAcreditado}/:pathwayId`}
        component={LearningPathPreviewAcreditado}
      />
      <Route path={`${AdminRole.coursePreview}/:courseId`} component={CoursePreview} />
      <Route path={AdminRole.catalogCourse} component={CatalogCourses} />
      <Route path={AdminRole.assingRoute} component={AssignRoutes} />
      <Route path={AdminRole.assingBadgesPaths} component={AssignBagdesPaths} />
      <Route path={AdminRole.pathPreview} component={PreviewPaths} />
      <Route path={AdminRole.badgesPreview} component={PreviewBadges} />
      <Route
        path={`${AdminRole.badgesPreviewUsers}/:name/:badgeId`}
        component={PreviewBadgesUsers}
      />
      <Route path={`${AdminRole.badgeEmit}/:id`} component={PreviewBadges} />
      <Route path={AdminRole.createCourse} component={AdminUploadNewIndividualCourseCatalog} />
      <Route path={AdminRole.badgesDashboard} component={BadgesDashboard} />
      <Route path={AdminRole.badgeDetails} component={BadgeDetails} />
      <Route path={`${AdminRole.badgeIssue}/:badge_id`} component={BadgeIssue} />
      <Route path={AdminRole.createBadge} component={AdminCreateNewBadgeTemplate} />
      <Route path={`${AdminRole.editBadge}/:id`} component={BadgeEdit} exact />
      <Route
        path={`${AdminRole.duplicateBadgeUrl}/:id`}
        component={AdminCreateNewBadgeTemplate}
        exact
      />
      <Route path={AdminRole.editPerfil} component={AdminEditPerfilInfo} />
      <Route path={AdminRole.organizationBadges} component={OrganizationBadges} />
      <Route path={AdminRole.successEnd} component={EndPathMsg} />
      <Route path={AdminRole.successEndPath} component={EndPathAcept} />
      <Route path={AdminRole.organizationBadgeDetail} component={DetailOrganizationBadge} />
      <Route path={`${AdminRole.templateBadgeDetail}/:badge_id`} component={DetailTemplateBadge} />
      <Route path={`${AdminRole.issueMasive}/:badge_id`} component={MassiveIssue} />
      <Route path={AdminRole.profile} component={Profile} />
      <Route path={AdminRole.analytics} component={AdminAnalyticsPage} exact />
      <Route path={AdminRole.analyticsReports} component={BadgeDashboardPage} exact />
      <Route
        path={AdminRole.configureOrganization}
        component={ConfigurationOrganizationPage}
        exact
      />
      <Route path={AdminRole.badgesStackable} component={BadgesStackableForm} exact />
      <Route path={`${AdminRole.badgesStackable}/:id`} component={BagdesStackableMain} exact />
      <Route path={`${AdminRole.dashboard}`} component={HomeDashboardPage} exact />
    </Switch>
  );
};

export default AdminNavigation;
