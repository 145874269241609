import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { AccreditedRole, AdminRole, AuthNavigations } from 'constants/NavigationUrls';
import toast from 'react-hot-toast';
import { ContainerNoData } from 'components/organisms/AdminHomeCatalogCourse/styles';
import ReactLoading from 'react-loading';
import { ChangeStatusPathThunk, GetStatusEvidenceThunk } from 'actions/Thunks/AcreditedThunk';
import { dataEndPath, cleanDataEndPath } from 'states/slices/admin.slice';

const EndPath = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  let { uuid } = useParams<{ uuid: string }>();
  let { status } = useParams<{ status: string }>();

  const changeStatusInfoPath = React.useCallback(
    async (statusInfo: IStatusInfo) => {
      const statusEvidence = await dispatch(GetStatusEvidenceThunk(uuid));
      if (GetStatusEvidenceThunk.fulfilled.match(statusEvidence)) {
        if (statusEvidence.payload.status === 'VERIFIED') {
          dispatch(cleanDataEndPath());
          toast('Esta ruta ya fue verificada');
          history.push(`${AdminRole.successEndPath}`);
        } else {
          const change = await dispatch(ChangeStatusPathThunk(statusInfo));

          if (ChangeStatusPathThunk.fulfilled.match(change)) {
            dispatch(cleanDataEndPath());
            if (status === 'VERIFIED') {
              history.push(`${AdminRole.successEnd}`);
            } else {
              toast.error('Verificacion Rechazada');
              history.push(AdminRole.catalogCourse);
            }
          }
        }
      }
    },
    [history, status, uuid, dispatch]
  );

  React.useEffect(() => {
    if (auth.isAuthenticated === true) {
      if (user.userRole === 3) {
        dispatch(cleanDataEndPath());
        history.push(`${AccreditedRole.profile}`);
      } else {
        const statusInfo = {
          uuid: uuid,
          status: status,
        };
        changeStatusInfoPath(statusInfo);
      }
    } else {
      const statusInfo = {
        uuid: uuid,
        status: status,
      };
      dispatch(dataEndPath(statusInfo));
      history.push(`${AuthNavigations.login}`);
    }
  }, [auth.isAuthenticated, user.userRole, changeStatusInfoPath, dispatch, history, uuid, status]);

  return (
    <React.Fragment>
      <ContainerNoData
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          padding: '10%',
        }}
      >
        <ReactLoading type="spin" color={'#002057'} height={150} width={150} />
      </ContainerNoData>
    </React.Fragment>
  );
};

export default EndPath;
