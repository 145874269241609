import { Theme } from 'react-select';
import { useState, useEffect } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SkillsTagListAction } from 'actions/ApiClient/OnboardingActions';
import { createNewSkill } from '../../../actions/ApiClient/OnboardingActions';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import './styles.css';
import { manageErrors } from '../../../helpers/manage-error';

const styledTheme = (theme: Theme) => {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      neutral20: PRIMARY_COLOR,
      neutral30: PRIMARY_COLOR,
    },
  };
};

const customStyles = {
  input: () => ({ height: 26 }),
};

interface SkillI {
  value: number;
  label: string;
}

type AcredittaSkillsSelectorProps = {
  loadOptionsValues?: SkillI[],
  onChangeInputValues: (values: any) => void;
};

const AcredittaSkillsSelector = ({ loadOptionsValues, onChangeInputValues }: AcredittaSkillsSelectorProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<SkillI[]>([]);

  useEffect(() => {
    onChangeInputValues(value);
  }, [value, onChangeInputValues]);

  useEffect(() => {
    if(loadOptionsValues && loadOptionsValues?.length > 0){
      setValue(loadOptionsValues)
    }
  },[loadOptionsValues])

  const handleCreate = async (inputValue: string) => {
    try {
      setLoading(true);
      const resp: any = await createNewSkill(inputValue);
      let newOption = { label: inputValue, value: resp.data.id };
      setValue([...value, newOption]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      manageErrors(e);
    }
  };

  const handleChange = (_: any, actionMeta: any) => {
    if (actionMeta.action === 'remove-value') {
      const newData = value.filter((item: any) => item.value !== actionMeta.removedValue.value);
      setValue(newData);
    }

    if (actionMeta.action === 'select-option') {
      setValue([...value, actionMeta.option]);
    }

    if (actionMeta.action === 'clear') {
      setValue([]);
    }
  };

  const filterSkills = async (inputValue: string) => {
    let data: any[] = [];

    if (inputValue !== '') {
      const resp = await SkillsTagListAction(inputValue);
      data = resp.map((item) => ({ value: item.id, label: item.name }));
    }

    return data;
  };

  const promiseSkills = (inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSkills(inputValue));
      }, 800);
    });
  }

  return (
    <AsyncCreatableSelect
      placeholder="Buscar habilidades..."
      formatCreateLabel={() => 'Agregar nueva habilidad +'}
      noOptionsMessage={() => 'No hay opciones'}
      loadingMessage={() => 'Buscando...'}
      isClearable
      isDisabled={loading}
      isLoading={loading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      defaultOptions
      loadOptions={promiseSkills}
      value={value}
      isMulti
      theme={styledTheme}
      styles={customStyles}
      openMenuOnClick={false}
    />
  );
};

export default AcredittaSkillsSelector;
