import * as React from 'react';
import CourseCard from 'components/atoms/CourseCard';
import icons from 'assets/Icon';
import { CheckWrapper, ColStyled } from './styles';
import { arrayObjectIndexOf } from 'utils';
import { useAppDispatch } from 'hooks/redux';
import { addCourseSelection, removeCourseSelection } from 'states/slices/course.slice';
import { AdminRole } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';
import './styles.css'

interface CoursesCardsPathwayDescriptionProps {
  courses: ISingleCourse[];
  allowSelection: boolean;
  section: boolean;
}

const CoursesCardsPathwayDescription = ({
  courses,
  allowSelection,
  section,
}: CoursesCardsPathwayDescriptionProps): JSX.Element => {
  const [selected, setSelected] = React.useState<ISingleCourse[] | null>(null);
  const dispatch = useAppDispatch();

  const isSelected = React.useCallback(
    (course: ISingleCourse) => {
      return !!(
        selected &&
        selected.filter((courseSelected) => courseSelected.name === course.name).length > 0
      );
    },
    [selected]
  );

  const selectCourse = (course: ISingleCourse) => {
    if (allowSelection) {
      let helper = selected ? selected : [];
      const verifiedCourse = helper.find((courseFiltered) => courseFiltered.name === course.name);

      if (!verifiedCourse) {
        helper = [...helper, course];

        dispatch(addCourseSelection(course.id));

        setSelected(helper);
      } else {
        const index = arrayObjectIndexOf(helper, course.name, 'name');

        if (index > -1) {
          const removedCourseItem = helper.filter(
            (skillFilter) => skillFilter.name !== course.name
          );

          dispatch(removeCourseSelection(course.id));

          setSelected(removedCourseItem);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {section === true ? (
        <div className='courses-container'>
          {courses.map((course, indexCourse) => {
            return (
              <ColStyled key={indexCourse} onClick={() => selectCourse(course)}>
                <Link to={`${AdminRole.coursePreview}/${course.course}`}>
                  {course.image === null ? (
                    <CourseCard
                      name={course.name}
                      image={course.image_url}
                      company={course.provider_name}
                      start={course.start_date}
                      score={course.score}
                      skills={course.skill_count}
                      duration={course.duration}
                      price={course.cost}
                      companyColor={'003DA6'}
                    />
                  ) : (
                    <CourseCard
                      name={course.name}
                      image={course.image}
                      company={course.provider_name}
                      start={course.start_date}
                      score={course.score}
                      skills={course.skill_count}
                      duration={course.duration}
                      price={course.cost}
                      companyColor={'003DA6'}
                    />
                  )}
                </Link>
                {isSelected(course) && (
                  <CheckWrapper>
                    <icons.CheckIcon />
                  </CheckWrapper>
                )}
              </ColStyled>
            );
          })}
        </div>
      ) : (
        <div className='courses-container'>
          {courses.map((course, indexCourse) => {
            return (
              <ColStyled key={indexCourse} onClick={() => selectCourse(course)}>
                <Link to={`${AdminRole.coursePreview}/${course.id}`}>
                  {course.image === null ? (
                    <CourseCard
                      name={course.name}
                      image={course.image_url}
                      company={course.provider_name}
                      start={course.start_date}
                      score={course.score}
                      skills={course.skill_count}
                      duration={course.duration}
                      price={course.cost}
                      companyColor={'003DA6'}
                    />
                  ) : (
                    <CourseCard
                      name={course.name}
                      image={course.image}
                      company={course.provider_name}
                      start={course.start_date}
                      score={course.score}
                      skills={course.skill_count}
                      duration={course.duration}
                      price={course.cost}
                      companyColor={'003DA6'}
                    />
                  )}
                </Link>
                {isSelected(course) && (
                  <CheckWrapper>
                    <icons.CheckIcon />
                  </CheckWrapper>
                )}
              </ColStyled>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default CoursesCardsPathwayDescription;
