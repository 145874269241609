import { uploadOrganizationImage } from 'actions/ApiClient/organization-actions';
import icons from 'assets/Icon';
import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaText from 'components/atoms/AcredittaText';
import { manageErrors } from 'helpers/manage-error';
import { useAppSelector } from 'hooks/redux';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';

type Props = {
  photo: string;
  name: string;
  onFinish: () => void;
  errorText?: string;
};

const UploadOrganizationPhoto = ({ photo, name, errorText, onFinish }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentImage, setCurrentImage] = useState<string>('');
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const { userInfo } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (selectedFile) {
      setCurrentImage(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const handleUploadImage = async (e: any) => {
    try {
      onFinish();
      setUploading(true);
      const image = e.target.files[0];
      const formData = new FormData();
      formData.append('photo', image!);
      await uploadOrganizationImage(userInfo?.organization.id, formData);
      toast.success('Imágen actualizada correctamente');
      setSelectedFile(image);
      setUploading(false);
    } catch (e: any) {
      setUploading(false);
      onFinish();
      manageErrors(e, e.response?.data?.message);
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={handleUploadImage}
        accept="image/*"
      />
      <img src={currentImage || photo} className="photo-profile" alt={name} />
      <div className="text-center">
        <AcredittaButton
          variant="primary"
          title="Cargar foto"
          onPress={() => inputRef.current?.click()}
          disabled={uploading}
          isLoading={uploading}
          type="button"
        />
        {errorText && (
          <div className="row">
            <div className="col">
              <AcredittaText variant="body1" color="red" align="center">
                {errorText}
              </AcredittaText>
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-2 text-right mt-2">
          <icons.CheckIcon height={16} />
        </div>
        <div className="col-10">
          <AcredittaText variant="body1" color="secondary" align="justify">
            Imagen formato PNG con dimensión máxima de 600x600 pixeles y 10MB.
          </AcredittaText>
        </div>
      </div>

      <div className="row">
        <div className="col-2 text-right mt-2">
          <icons.CheckIcon height={16} />
        </div>
        <div className="col-10">
          <AcredittaText variant="body1" color="secondary" align="justify">
            Ten en cuenta que esta imagen será la foto de portada de tu perfil.
          </AcredittaText>
        </div>
      </div>
    </div>
  );
};

export default UploadOrganizationPhoto;
