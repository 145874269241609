import * as React from 'react';
import { CardBody } from 'reactstrap';
import {
  AdminDnDCourseCardPathwayWrapper,
  AdminDnDCourseCardPathwayFooter,
  AdminDnDCourseCardPathwayFooterLabel,
  AdminDnDCourseCardPathwayIconWrapper,
  AdminDnDCourseCardPathwayLabel,
  AdminDnDCourseCardPathwayTimeWrapper,
  AdminDnDCourseCardPathwayTitle,
  AdminDnDCourseCardPathwayImageUnionWrapper,
} from './styles';
import icons from 'assets/Icon';
import { SECONDARY_COLOR } from 'assets/styles/colors';
import { useDrag } from 'react-dnd';

interface AdminDnDCourseCardPathwayProps {
  title: string;
  duration: string;
  provider: string;
  removeCardDragged: () => void;
}

const AdminDnDCourseCardPathway = ({
  title,
  duration,
  provider,
  removeCardDragged,
}: AdminDnDCourseCardPathwayProps): JSX.Element => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'box',
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <React.Fragment>
      <AdminDnDCourseCardPathwayWrapper ref={drag} className="card" onClick={removeCardDragged}>
        <CardBody>
          <AdminDnDCourseCardPathwayTitle>{title}</AdminDnDCourseCardPathwayTitle>
          <AdminDnDCourseCardPathwayTimeWrapper>
            <AdminDnDCourseCardPathwayIconWrapper>
              <icons.TimeIcon color={SECONDARY_COLOR} />
            </AdminDnDCourseCardPathwayIconWrapper>
            {duration ? (
              <AdminDnDCourseCardPathwayLabel>
                Duración: <b> {duration} </b>
              </AdminDnDCourseCardPathwayLabel>
            ) : (
              <AdminDnDCourseCardPathwayLabel>
                Duración: <b> A tu ritmo </b>
              </AdminDnDCourseCardPathwayLabel>
            )}
          </AdminDnDCourseCardPathwayTimeWrapper>
        </CardBody>
        <AdminDnDCourseCardPathwayFooter>
          <AdminDnDCourseCardPathwayFooterLabel>{provider}</AdminDnDCourseCardPathwayFooterLabel>
        </AdminDnDCourseCardPathwayFooter>
      </AdminDnDCourseCardPathwayWrapper>
      <AdminDnDCourseCardPathwayImageUnionWrapper>
        <icons.DragAndDropUnionIcon />
      </AdminDnDCourseCardPathwayImageUnionWrapper>
    </React.Fragment>
  );
};

export default AdminDnDCourseCardPathway;
