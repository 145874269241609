import icons from 'assets/Icon';
import {
  ProfileInfoContainer,
  ProfileInfoImage,
  InfoContainer,
  PersonalInfoContainer,
  SubTitleLabel,
  GeneralProgressInfoContainer,
  GeneralProgressInfoImg,
  GeneralProgressInfoData,
  GeneralProgressInfoContainerInfo,
  BoxRRSS,
} from 'components/molecules/ProfileInfo/styles';
import * as React from 'react';
interface IDataAcredited {
  name: string | null;
  avatar: string | null;
  countPath: any;
  countBadges: any;
  biography: string;
  linkedin: string;
  instagram: string;
  facebook: string;
  twitter: string;
}

const ProfileInfo: React.FC<IDataAcredited> = ({
  name,
  avatar,
  countPath,
  countBadges,
  biography,
  linkedin,
  instagram,
  facebook,
  twitter,
}) => {
  const capitalizeUserName = (name: string) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <ProfileInfoContainer>
      <InfoContainer>
        <PersonalInfoContainer>
          <GeneralProgressInfoImg>
            {avatar !== null ? (
              <ProfileInfoImage src={avatar} alt="profile" />
            ) : (
              <ProfileInfoImage
                src="https://images.unsplash.com/photo-1628336684306-69558cef3b20?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                alt="profile"
              />
            )}
          </GeneralProgressInfoImg>
          <GeneralProgressInfoContainer>
            <GeneralProgressInfoData>
              <div>{name && <SubTitleLabel> {capitalizeUserName(name)} </SubTitleLabel>}</div>
              <BoxRRSS>
                <a
                  href={linkedin}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <icons.LinkedinIconBlue
                    style={{
                      width: '20px',
                      margin: '5px',
                    }}
                  />
                </a>
                <a
                  href={twitter}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <icons.TwitterIconBlue
                    style={{
                      width: '20px',
                      margin: '5px',
                    }}
                  />
                </a>
                <a
                  href={facebook}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <icons.FacebookIconBlue
                    style={{
                      width: '20px',
                      margin: '5px',
                    }}
                  />
                </a>
                <a
                  href={instagram}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <icons.IconoInstagramBlue
                    style={{
                      width: '20px',
                      margin: '5px',
                    }}
                  />
                </a>
              </BoxRRSS>
            </GeneralProgressInfoData>
            <GeneralProgressInfoContainerInfo>
              <p>{biography}</p>
            </GeneralProgressInfoContainerInfo>
          </GeneralProgressInfoContainer>
        </PersonalInfoContainer>
      </InfoContainer>
    </ProfileInfoContainer>
  );
};

export default ProfileInfo;
