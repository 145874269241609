import * as React from 'react';
import BadgeIssue from 'components/organisms/BadgeIssue';
import { useParams } from 'react-router-dom';

const BadgeIssuesPage: React.FC = () => {
  let { badge_id } = useParams<{ badge_id: string }>();
  return <BadgeIssue badge_id={badge_id} />;
};

export default BadgeIssuesPage;
