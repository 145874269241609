import * as React from 'react';
import { CardImg } from 'reactstrap';
import VideoCoverImage from 'assets/images/video_cover.png';
import { ButtonImage, ButtonWrapper, CardContainer } from './styles';

interface VideoCardCoverProps {
  openModal: () => void;
}

const VideoCardCover: React.FC<VideoCardCoverProps> = ({ openModal }) => {
  return (
    <CardContainer onClick={openModal}>
      <CardImg top width="100%" src={VideoCoverImage} alt="Card image cap" />
      <ButtonWrapper>
        <ButtonImage />
      </ButtonWrapper>
    </CardContainer>
  );
};

export default VideoCardCover;
