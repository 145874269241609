import { AdminAddMassiveUsersThunk } from 'actions/Thunks/AdminThunk';
import AdminCreateMassiveCard from 'components/molecules/AdminCreateMassiveCard';
import AdminCreateUserHeader from 'components/molecules/AdminCreateUserHeader';
import { AdminRole } from 'constants/NavigationUrls';
import { useAppDispatch } from 'hooks/redux';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import { removeCsvFile } from 'states/slices/admin.slice';
import CommonPageLayout from '../Layout/CommonPageLayout';

const AdminCreateMassiveUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onSelectCsvFile = async (csvFile: File) => {
    const uploadFile = await dispatch(AdminAddMassiveUsersThunk(csvFile));
    if (AdminAddMassiveUsersThunk.fulfilled.match(uploadFile)) {
      if (uploadFile.payload) {
        toast.success(uploadFile.payload);
      }
    } else {
      toast.error((uploadFile.payload as string) || 'Ocurrió un error al subir el archivo');
      dispatch(removeCsvFile());
    }
  };

  const onGoBack = () => {
    history.push(AdminRole.catalogCourse);
  };

  const navigateIndividual = () => {
    history.push(AdminRole.loadNewUsers);
  };

  const onNavigationInterests = () => {
    history.push(AdminRole.pathPreview);
  };

  React.useEffect(() => {
    dispatch(removeCsvFile());
  }, [dispatch]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showCommon={true}
      showOnboarding={false}
    >
      <Container>
        <AdminCreateUserHeader title="Agrega Usuarios" navigateIndividual={navigateIndividual} />
        <AdminCreateMassiveCard
          onSelectCsvFile={onSelectCsvFile}
          onGoBack={onGoBack}
          onNavigationInterests={onNavigationInterests}
        />
      </Container>
    </CommonPageLayout>
  );
};

export default AdminCreateMassiveUser;
