import AcredittaText from 'components/atoms/AcredittaText';
import { useContext } from 'react';
import { MenuContext } from '../../context/MenuContextProvider';

const MobileLogoutButton = () => {
  const { logout } = useContext(MenuContext);
  return (
    <div onClick={logout}>
      <AcredittaText variant="h5" color="red">
        Cerrar sesión
      </AcredittaText>
    </div>
  );
};

export default MobileLogoutButton;
