import styled, { css } from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Container, Col, ModalBody, ModalFooter } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const InputContainer = styled.div`
  padding: 20px 0px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  padding: 40px 0px;
`;

export const StepSection = styled.span`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  margin: 5px;
  color: ${PRIMARY_COLOR};
`;

export const Step = styled(Container)`
  width: 70%;
  @media ${device.ipad} {
    width: 90%;
  }
`;

export const InterestContainer = styled.div<{ gap: string }>`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: ${({ gap }) => '-' + gap};
`;

export const StepCol = styled(Col)`
  padding-bottom: 40px;
`;

export const ModalBodySelect = styled(ModalBody)`
  background-color: white !important;
  margin: 32px 27px;

  @media ${device.mobile} {
    margin: 12px 10px;
  }
`;

export const ModalFooterSkills = styled(ModalFooter)`
  display: flex;
  justify-content: center;

  @media ${device.smallMobile} {
    flex-direction: column-reverse;
  }
`;

export const ModalButtonWrapper = styled.div<{ $withMargin?: boolean }>`
  ${(props) =>
    props.$withMargin &&
    css`
      margin-right: 10px;

      @media ${device.smallMobile} {
        margin-right: 0;
        margin-left: 0;
      }
    `}

  @media ${device.smallMobile} {
    width: 100%;
    margin-bottom: 10px;

    :not(:last-child) {
      margin-right: 0;
    }
    :not(:first-child) {
      margin-left: 0;
    }
  }
`;
