import styled from 'styled-components';
import { SECONDARY_COLOR, PRIMARY_COLOR, LIGHT } from 'assets/styles/colors';
import { Button } from 'reactstrap';

export const CustomButton = styled(Button)<{
  $lightMode: boolean;
  $widthCustom?: number;
}>`
  height: 44px;
  width: ${(props) => (props.$widthCustom ? `${props.$widthCustom}px` : '100%')} !important;

  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-family: NotoSansJPBold;
  background-color: ${(props) => (props.$lightMode ? LIGHT : SECONDARY_COLOR)};
  color: ${(props) => (props.$lightMode ? PRIMARY_COLOR : 'white')};
  border: 1px solid ${(props) => (props.$lightMode ? PRIMARY_COLOR : SECONDARY_COLOR)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 0 !important;
`;

export const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

export const LabelContent = styled.span`
  font-size: 16px;
  font-family: NotoSansJPBold;
`;
