import styled from 'styled-components';

interface Props {
  data: string[];
}

const StyledTh = styled.th`
  text-align: center;
`;

const AcredittaTableHead = ({ data }: Props) => {
  return (
    <thead>
      <tr>
        {data.map((item: string, index: number) => (
          <StyledTh key={index}>{item}</StyledTh>
        ))}
      </tr>
    </thead>
  );
};

export default AcredittaTableHead;
