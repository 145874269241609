import AcredittaText from 'components/atoms/AcredittaText';
import { Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';

interface ShortMenuI {
  text: string;
  path: string;
  icon?: boolean;
  external?: boolean;
}

interface Props {
  element: ShortMenuI;
}

const CommonMenuItem = ({ element }: Props) => {
  const { icon, text, path, external } = element;

  return (
    <NavItem>
      {external ? (
        <a href={path} target="_blank" rel="noreferrer" style={{ color: 'white', fontWeight: 600 }}>
          {text}
        </a>
      ) : (
        <Link to={path}>
          <AcredittaText
            as="span"
            variant="body1"
            style={{ lineHeight: 0 }}
            color="white"
            fontWeight="600"
          >
            {icon && <span style={{ color: 'white' }}>+</span>} {text}
          </AcredittaText>
        </Link>
      )}
    </NavItem>
  );
};

export default CommonMenuItem;
