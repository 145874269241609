import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Col, Label, Row } from 'reactstrap';
import styled from 'styled-components';

export const AdminEndStepProcessTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 45px;
  line-height: normal;
  color: ${PRIMARY_COLOR};

  @media ${device.smallMobile} {
    font-size: 35px;
  }
`;

export const AdminEndStepProcessContent = styled(Label)`
  font-size: 22px;
`;

export const AdminEndStepProcessContentContainer = styled(Col)`
  display: flex;
  align-self: center;
`;

export const AdminEndStepProcessImageContainer = styled(Col)`
  max-width: 652px;
  display: flex;
  align-self: center;
`;

export const AdminEndStepProcessImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const AdminEndStepProcessContentWrapper = styled.div`
  width: 100%;
`;

export const AdminEndStepProcessRow = styled(Row)`
  min-height: calc(100vh - 100px);
`;
