import { PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY } from 'assets/styles/colors';
import { Theme } from 'react-select';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export function styledTheme(theme: Theme) {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      primary: SECONDARY_COLOR,
      neutral10: 'green',
      primary25: TERTIARY,
      neutral20: PRIMARY_COLOR,
      neutral30: PRIMARY_COLOR,
      // neutral50: 'red',
    },
  };
}

export const customStyles = {
  input: () => ({ height: 26 }),
};

export const ErrorMessage = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;
