import icons from 'assets/Icon';
import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { ModalBodyStyled, ModalTitle, ModalText } from './styles';

const EndPathMsg = (): JSX.Element => {
  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <ModalBodyStyled>
        <ModalTitle>¡Ruta aceptada correctamente!</ModalTitle>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '2%',
          }}
        >
          <br />
          <ModalText>Has aceptado la ruta finalizada por el acreditado</ModalText>
          <br />
          <br />
          <icons.Image_Ruta_Finalizada
            style={{
              width: '100%',
              marginRight: '20px',
            }}
          />
        </div>
      </ModalBodyStyled>
    </CommonPageLayout>
  );
};

export default EndPathMsg;
