import * as React from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import AdminEndStepProcess from '../AdminEndStepProcess';
import AdminOrganizeLearningPath from '../AdminOrganizeLearningPath';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import {
  DetailPathwayContentThunk,
  LearningPathExperiencesThunk,
  LearningPathListViewThunk,
  SelectedCourseListThunk,
} from 'actions/Thunks/AdminThunk';
import AdminEditLearningPath from '../AdminEditLearningPath';
interface AdminLearningPathEdit {
  pathwayId: string;
}

const AdminOnboardingWizard = ({ pathwayId }: AdminLearningPathEdit): JSX.Element => {
  const dispatch = useAppDispatch();

  const [wizardInstance, setWizardInstance] = React.useState<Partial<StepWizardChildProps>>();

  const user = useAppSelector((state) => state.user);
  const onNextStep = () => {
    if (wizardInstance && wizardInstance.nextStep) {
      wizardInstance.nextStep();
      // if (wizardInstance.currentStep) setCurrentStep(wizardInstance.currentStep);
    }
  };

  const persistStep = React.useCallback(() => {
    if (user.userInfo) {
      if (wizardInstance && wizardInstance.goToStep) {
        wizardInstance.goToStep(2);
        // setCurrentStep(2);
      }
    }
  }, [user.userInfo, wizardInstance]);

  const persistPath = React.useCallback(async () => {
    const experiencesPath = await dispatch(DetailPathwayContentThunk(parseInt(pathwayId)));
    if (DetailPathwayContentThunk.rejected.match(experiencesPath)) {
      toast.error(experiencesPath.payload as string);
    }
    const experiencesCour = await dispatch(LearningPathListViewThunk(parseInt(pathwayId)));
    if (LearningPathListViewThunk.rejected.match(experiencesCour)) {
      toast.error(experiencesCour.payload as string);
    }
    const experiencesExp = await dispatch(LearningPathExperiencesThunk(parseInt(pathwayId)));
    if (LearningPathExperiencesThunk.rejected.match(experiencesExp)) {
      toast.error(experiencesExp.payload as string);
    }
    const experiencesSel = await dispatch(SelectedCourseListThunk(parseInt(pathwayId)));
    if (SelectedCourseListThunk.rejected.match(experiencesSel)) {
      toast.error(experiencesSel.payload as string);
    }
  }, [pathwayId, dispatch]);

  React.useEffect(() => {
    persistPath();
    persistStep();
  }, [persistPath, persistStep]);

  return user.userRole === 3 ? (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={false}
      showMenuHeaderAcredited={true}
    >
      <StepWizard instance={setWizardInstance} isLazyMount={true}>
        <AdminOrganizeLearningPath onNextStep={onNextStep} />
        <AdminEditLearningPath onNextStep={onNextStep} pathwayId={pathwayId} />
        <AdminEndStepProcess />
      </StepWizard>
    </CommonPageLayout>
  ) : (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
    >
      <StepWizard instance={setWizardInstance} isLazyMount={true}>
        <AdminOrganizeLearningPath onNextStep={onNextStep} />
        <AdminEditLearningPath onNextStep={onNextStep} pathwayId={pathwayId} />
        <AdminEndStepProcess />
      </StepWizard>
    </CommonPageLayout>
  );
};

export default AdminOnboardingWizard;
