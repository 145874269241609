import AcredittaText from 'components/atoms/AcredittaText';
import { MenuI } from '../../interfaces/menu';
import { NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  element: MenuI;
}

const MenuItem = ({ element }: MenuItemProps) => {
  const { id, path, text } = element;

  return (
    <NavItem key={id} style={{ paddingTop: '5px' }}>
      <Link to={path}>
        <AcredittaText
          as="span"
          variant="body1"
          style={{ lineHeight: 0 }}
          color="white"
          fontWeight="600"
        >
          {text}
        </AcredittaText>
      </Link>
    </NavItem>
  );
};

export default MenuItem;
