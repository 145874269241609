import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

export const ModalItemContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;
export const ModalContainer = styled(Modal)`
  max-width: 900px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalBodyStyledCredly = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 50px;
  padding: 5%;
`;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
`;
export const ModalTextFooter = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  text-align: center;
`;

export const ModalItemLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const ModalItemSubLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
`;

export const ModalLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalIconContainer = styled.div<{
  verified: boolean;
}>`
  color: ${(props) => (props.verified ? '#02b148' : 'gray')};
  svg {
    width: 25px;
  }
`;

export const ModalFooterStyled = styled(ModalFooter)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 22px;
  color: #02b148;
  padding-top: 0px;
  margin-bottom: 10px;
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  background-color: white;
  margin: 30px 0px 0px;
  padding: 0px;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 22px;
  h5 {
    color: ${PRIMARY_COLOR};
  }
  button {
    padding-right: 80px !important;
  }
  span {
    color: ${PRIMARY_COLOR} !important;
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  margin: 10px 40px 30px 40px;
`;
