import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const OrganizationProfileContainer = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0px;
  grid-column-start: 2;
  @media ${device.mediumLaptop} {
    width: 100%;
  }
`;

export const OrganizationProfileSectionsContainer = styled.div`
  width: 90%;
  margin: 20px 0px;
  display: grid;
  grid-template-columns: 30% 70%;
  @media ${device.mediumLaptop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media ${device.mobile} {
    width: 90%;
  }
`;

export const TitleSection = styled.div`
  width: 90%;
  background-color: ${PRIMARY_COLOR};
  border-radius: 8px;
  height: 38px;
`;
