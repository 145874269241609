import AcredittaText from 'components/atoms/AcredittaText';
import { MAILTO_SUPPORT } from '../../data';

const MobileSupportLink = () => {
  return (
    <a href={MAILTO_SUPPORT} target="_blank" rel="noreferrer">
      <AcredittaText variant="h5" fontWeight="600">
        Soporte
      </AcredittaText>
    </a>
  );
};

export default MobileSupportLink;
