import * as React from 'react';
import { Row } from 'reactstrap';
import CourseCard from 'components/atoms/CourseCard';
import { ColStyled } from './styles';
import { Link } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';

interface CoursesCardsProps {
  courses: ISingleCourse[];
}

const CoursesCards = ({ courses }: CoursesCardsProps): JSX.Element => {
  return (
    <Row>
      {courses.map((course, indexCourse) => {
        return (
          <ColStyled md="3" sm="4" xs="12" key={indexCourse}>
            <Link to={`${AdminRole.coursePreview}/${course.id}`}>
              {course.image === null ? (
                <CourseCard
                  name={course.name}
                  image={course.image_url}
                  company={course.provider_name}
                  start={course.start_date}
                  score={course.score}
                  skills={course.skill_count}
                  duration={course.duration}
                  price={course.cost}
                  companyColor={'003DA6'}
                />
              ) : (
                <CourseCard
                  name={course.name}
                  image={course.image}
                  company={course.provider_name}
                  start={course.start_date}
                  score={course.score}
                  skills={course.skill_count}
                  duration={course.duration}
                  price={course.cost}
                  companyColor={'003DA6'}
                />
              )}
            </Link>
          </ColStyled>
        );
      })}
    </Row>
  );
};

export default CoursesCards;
