import * as React from 'react';
import LearningPathDetails from 'components/molecules/EditProfile/LearningPathDetails';
import LearningPathInfo from 'components/molecules/EditProfile/LearningPathInfo';
import { OrganizationProfileContainer, OrganizationProfileSectionsContainer } from './styles';
import { useForm } from 'react-hook-form';
import { objectValidation } from 'utils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SaveNewLearningPathThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';
import { AddBagdePathsThunk } from 'actions/Thunks/AdminPathsThunk';
import CommonPageLayout from '../Layout/CommonPageLayout';

const EditProfile: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const courseSelector = useAppSelector((state) => state.courses);
  const coursesSelector = useAppSelector((state) => state.courses);
  const user = useAppSelector((state) => state.user);

  const [is_private, onSelectVisibility] = React.useState<boolean>(false);

  const defaultValuesAdditionalInfo: IUpdatePath = {
    name: '',
    description: '',
    is_private: false,
    is_draft: false,
    is_group: false,
    is_completed: false,
    level: null,
    photo: null,
    insignia: null,
    methodology: null,
  };

  const getDefaultAdditionalInfoFormValues = (): IUpdatePath => {
    return defaultValuesAdditionalInfo;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IUpdatePath>({
    ...objectValidation,
    defaultValues: getDefaultAdditionalInfoFormValues(),
  });

  const finishOnBoarding = () => {
    if (coursesSelector.pathwayCreated) {
      if (user.userRole === 3) {
        history.push(`${AccreditedRole.learningPathPreview}/${coursesSelector.pathwayCreated.id}`);
      } else {
        history.push(`${AdminRole.learningPathPreview}/${coursesSelector.pathwayCreated.id}`);
      }
    }
  };

  const submitNewPathForm = async (
    pathInfo: IUpdatePath,
    skills: IListNewLearningExperienceSkillString[]
  ) => {
    if (courseSelector.pathwayCreated && user.userInfo) {
      const new_learning_path: ISaveUpdatePath = {
        path_id: courseSelector.pathwayCreated.id,
        path_info: {
          name: pathInfo.name,
          description: pathInfo.description,
          is_private: is_private === true ? false : true,
          is_draft: false,
          is_group: pathInfo.is_group,
          is_completed: pathInfo.is_completed,
          methodology: 1,
          level: 1,
        },
        path_photo: {
          photo: pathInfo.photo,
        },
      };
      skills.map((skills: IListNewLearningExperienceSkillString) => {
        if (courseSelector.pathwayCreated) {
          const addBadges: IAddBadgePathCreate = {
            addBadge: {
              badge_template: skills.value,
              path: courseSelector.pathwayCreated?.id,
            },
          };
          dispatch(AddBagdePathsThunk(addBadges.addBadge));
        }
        return 0;
      });

      const create_new_path = await dispatch(SaveNewLearningPathThunk(new_learning_path));
      if (SaveNewLearningPathThunk.fulfilled.match(create_new_path)) {
        if (create_new_path.payload) {
          toast.success(create_new_path.payload);
          finishOnBoarding();
        }
      } else {
        toast.error(create_new_path.payload as string);
      }
    }
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {user && (
        <OrganizationProfileContainer>
          <OrganizationProfileSectionsContainer>
            <LearningPathDetails controlInfo={control} errorsInfo={errors} watch={watch} />
            <LearningPathInfo
              handleSubmitForm={handleSubmit}
              controlInfo={control}
              errorsInfo={errors}
              submitNewPathForm={submitNewPathForm}
              onSelectVisibility={onSelectVisibility}
            />
          </OrganizationProfileSectionsContainer>
        </OrganizationProfileContainer>
      )}
    </CommonPageLayout>
  );
};

export default EditProfile;
