import icons from 'assets/Icon';

const MoreInfoMobile = ({ user }: any) => {
  return (
    <div
      className="row p-3 mx-1"
      style={{
        background: user.userInfo?.branding.theme.value,
        borderRadius: '8px',
        boxShadow: '0px 10px 30px 5px rgba(153, 162, 169, 0.1)',
      }}
    >
      <div className="col text-center">
        <a href={`mailto:${user.adminInfo?.contact_email}`} title={user.adminInfo?.contact_email}>
          <icons.EmailBlueIcon color="white" />
        </a>
      </div>
      <div className="col text-center">
        {user.adminInfo?.domain !== 'https://example.org' && (
          <a
            href={user.adminInfo?.domain}
            rel="noreferrer"
            target="_blank"
            style={{ color: 'white' }}
            title={user.adminInfo?.domain}
          >
            <icons.LinkBlueIcon color="white" />
          </a>
        )}
      </div>
    </div>
  );
};

export default MoreInfoMobile;
