import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreatePathwayCatalogCourseThunk,
  DetailPathwayBagdesThunk,
  DetailPathwayContentCourseThunk,
  DetailPathwayContentThunk,
  DetailPathwayDashboardThunk,
  DetailPathwaySkillsThunk,
  SelectedCourseListThunk,
  UnauthDetailPathwayContentCourseThunk,
  UnauthDetailPathwayDashboardThunk,
  UnauthSelectedCourseListThunk,
} from '../../actions/Thunks/AdminThunk';
import { arrayObjectIndexOf } from '../../utils';

const COURSE = 'COURSE';

const initialState: ICourse = {
  selectedCourse: [],
  pathwayCreated: null,
  selectedCourseList: null,
  pathwayDetailGeneralContent: null,
  pathwayDetailBadges: null,
  pathwayDetailSkillsContent: null,
  pathwayDetailContentCourse: null,
  pathwayDetailContent: null,
  pathwayCourseCounter: null,
  pathwayDragAndDropSelection: null,
};

const courseSlice = createSlice({
  name: COURSE,
  initialState,
  reducers: {
    addCourseSelection(state, action: PayloadAction<number>) {
      const indexToAdd = state.selectedCourse.indexOf(action.payload);
      let newCourses = state.selectedCourse.slice();

      newCourses.splice(indexToAdd, 0, action.payload);

      return { ...state, selectedCourse: newCourses };
    },
    removeCourseSelection(state, action: PayloadAction<number>) {
      const indexToRemove = state.selectedCourse.indexOf(action.payload);
      let newCourses = state.selectedCourse.slice();

      newCourses.splice(indexToRemove, 1);

      return { ...state, selectedCourse: newCourses };
    },
    defaultCourseSelection(state) {
      return { ...state, selectedCourse: [] };
    },
    updateFromDragAndDrop(state, action: PayloadAction<string>) {
      if (state.selectedCourseList) {
        let newArray = state.selectedCourseList.filter((item) => {
          return item.name !== action.payload;
        });

        return { ...state, selectedCourseList: newArray };
      } else {
        return { ...state };
      }
    },
    insertFromDragAndDrop(state, action: PayloadAction<ISelectedCourseList>) {
      if (state.selectedCourseList) {
        let newArray = state.selectedCourseList.slice();

        const indexSelected = arrayObjectIndexOf(
          state.selectedCourseList,
          action.payload.name,
          'title'
        );

        newArray.splice(indexSelected, 0, action.payload);

        return { ...state, selectedCourseList: newArray };
      } else {
        return { ...state };
      }
    },
    addDefaultDragAndDropSelection(state, action: PayloadAction<IPathwayDragAndDrop[]>) {
      return { ...state, pathwayDragAndDropSelection: action.payload };
    },
    addDragAndDropPathwayElement(
      state,
      action: PayloadAction<{
        index: number;
        pathElement: ISelectedCourseList[];
      }>
    ) {
      if (state.pathwayDragAndDropSelection) {
        const updateSelection = state.pathwayDragAndDropSelection.map((item, index) => {
          if (index !== action.payload.index) {
            return item;
          }

          const addElementParent = { ...item, path_element: action.payload.pathElement };

          return {
            ...item,
            ...addElementParent,
          };
        });

        return { ...state, pathwayDragAndDropSelection: updateSelection };
      }
      return { ...state };
    },
    removeDragAndDropPathwayElement(
      state,
      action: PayloadAction<{ index: number; courseTitle: string }>
    ) {
      if (state.pathwayDragAndDropSelection) {
        const updatePathwaySelection = state.pathwayDragAndDropSelection.map((pathwaySelection) => {
          if (pathwaySelection.order !== action.payload.index + 1) {
            return { ...pathwaySelection };
          } else {
            const updatePathway = pathwaySelection.path_element.filter(
              (pathElement) => pathElement.name !== action.payload.courseTitle
            );

            return {
              ...pathwaySelection,
              path_element: updatePathway,
            };
          }
        });

        return { ...state, pathwayDragAndDropSelection: updatePathwaySelection };
      } else {
        return { ...state };
      }
    },
    pathwayResultCounter(state) {
      if (state.pathwayDragAndDropSelection) {
        const counter = state.pathwayDragAndDropSelection.map((course) => {
          const duration = course.path_element.reduce((previousValue, currentValue) => 0 + 0, 0);

          const cost = course.path_element.reduce(
            (previousValue, currentValue) => previousValue + currentValue['cost'],
            0
          );

          return {
            duration,
            cost,
          };
        });

        const resultDuration = counter.reduce((previousValue, currentValue) => 0 + 0, 0);

        const resultCost = counter.reduce(
          (previousValue, currentValue) => previousValue + currentValue['cost'],
          0
        );

        const resultCourse = state.pathwayDragAndDropSelection.reduce(
          (previousValue, currentValue) => previousValue + currentValue['path_element'].length,
          0
        );

        return {
          ...state,
          pathwayCourseCounter: {
            duration: resultDuration,
            cost: resultCost,
            course: resultCourse,
          },
        };
      }

      return { ...state };
    },
    restorePathwayResultCounter(state) {
      return {
        ...state,
        pathwayCourseCounter: {
          course: 0,
          cost: 0,
          duration: 0,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(CreatePathwayCatalogCourseThunk.fulfilled, (state, { payload }) => {
      state.pathwayCreated = payload;
    });
    builder.addCase(SelectedCourseListThunk.fulfilled, (state, { payload }) => {
      state.selectedCourseList = payload;
    });
    builder.addCase(UnauthSelectedCourseListThunk.fulfilled, (state, { payload }) => {
      state.selectedCourseList = payload;
    });
    builder.addCase(DetailPathwayDashboardThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailGeneralContent = payload;
    });
    builder.addCase(UnauthDetailPathwayDashboardThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailGeneralContent = payload;
    });
    builder.addCase(DetailPathwayBagdesThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailBadges = payload;
    });
    builder.addCase(DetailPathwaySkillsThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailSkillsContent = payload;
    });
    builder.addCase(DetailPathwayContentCourseThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailContentCourse = payload;
    });
    builder.addCase(UnauthDetailPathwayContentCourseThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailContentCourse = payload;
    });
    builder.addCase(DetailPathwayContentThunk.fulfilled, (state, { payload }) => {
      state.pathwayDetailContent = payload;
    });
  },
});

export const {
  addCourseSelection,
  removeCourseSelection,
  defaultCourseSelection,
  updateFromDragAndDrop,
  insertFromDragAndDrop,
  addDefaultDragAndDropSelection,
  addDragAndDropPathwayElement,
  pathwayResultCounter,
  restorePathwayResultCounter,
  removeDragAndDropPathwayElement,
} = courseSlice.actions;

export default courseSlice.reducer;
