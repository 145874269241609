import { AdminCreateSingleUserThunk } from 'actions/Thunks/AdminThunk';
import AdminCreateUserCard from 'components/molecules/AdminCreateUserCard';
import AdminCreateUserHeader from 'components/molecules/AdminCreateUserHeader';
import { AdminRole } from 'constants/NavigationUrls';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import { objectValidation } from 'utils';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { CheckSession } from '../../../utils/Routing/AuthUserGuard';
import { AdminDataThunk } from '../../../actions/Thunks/UserThunk';

interface AdminCreateUserProps {
  showHeader: boolean;
  onSuccess: () => void;
}

const AdminCreateUser = ({ showHeader, onSuccess }: AdminCreateUserProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const history = useHistory();

  const adminData = React.useCallback(() => {
    if (user.userInfo && user.userInfo.user_type.code === 'ADMIN') {
      CheckSession().then((result) => {
        if (result) {
          dispatch(AdminDataThunk()).then((admin) => {
            if (AdminDataThunk.rejected.match(admin)) {
              toast.error(admin.payload as string);
            }
          });
        }
      });
    }
  }, [dispatch, user.userInfo]);

  React.useEffect(() => {
    adminData();
  }, [adminData]);

  const defaultValues: ICreateUserForm = {
    firstName: '',
    lastName: '',
    email: '',
    country: null,
    document: '',
    typeDocument: '',
    jobTitle: '',
    department: '',
    userRole: null,
  };

  const getDefaultValues = (): ICreateUserForm => {
    return defaultValues;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ICreateUserForm>({
    ...objectValidation,
    defaultValues: getDefaultValues(),
  });

  const createUser = async (formData: ICreateUserForm) => {
    if (formData.userRole && user.adminInfo) {
      const sendUserData: ICreateSingleUser = {
        username: formData.email,
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
        accredited: {
          dni_type_id: formData.typeDocument ? parseInt(formData.typeDocument, 10) : undefined,
          dni: formData.document,
          job_title: formData.jobTitle ? formData.jobTitle : undefined,
          organization_id: user.adminInfo.id,
          user_type_id: formData.userRole.value,
          country_id: formData.country ? formData.country.value : undefined,
          text_department: formData.department ? formData.department : undefined,
          text_user_area: formData.userRoleLeaderArea,
        },
      };

      const createSingleUser = await dispatch(AdminCreateSingleUserThunk(sendUserData));

      if (AdminCreateSingleUserThunk.rejected.match(createSingleUser)) {
        toast.error(createSingleUser.payload as string);
      } else {
        if (createSingleUser.payload) {
          toast.success(createSingleUser.payload);
          onSuccess();
        }
      }
    }
  };

  // TODO: Change the submit depends on the component that is calling this
  const onGoBack = () => {
    history.push(AdminRole.catalogCourse);
  };

  const navigateMassive = () => {
    history.push(AdminRole.loadMassiveUsers);
  };

  return (
    <CommonPageLayout
      showHeader={showHeader}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <Container>
        <AdminCreateUserHeader title="Agrega Usuarios" navigateMassive={navigateMassive} />
        <AdminCreateUserCard
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          onCreate={createUser}
          watch={watch}
          onGoBack={onGoBack}
        />
      </Container>
    </CommonPageLayout>
  );
};

export default AdminCreateUser;
