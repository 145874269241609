import AcredittaText from 'components/atoms/AcredittaText';
import { useContext } from 'react';
import { MenuContext } from '../../context/MenuContextProvider';

const MobilePublicProfileLink = () => {
  const { goToPublicProfile } = useContext(MenuContext);
  return (
    <AcredittaText variant="body1" color="secondary" fontWeight="600" onClick={goToPublicProfile}>
      Perfil público
    </AcredittaText>
  );
};

export default MobilePublicProfileLink;
