import * as React from 'react';
import {
  BadgeDetailsContainer,
  BadgeInfoWrapper,
  BadgeName,
  BadgeNameWrapper,
  ItemWrapper,
  ItemTitle,
  ItemDesc,
  SkillContainer,
  RequirementsWrapper,
  MoreInfoWrapper,
  SkillsSectionWrapper,
  ShareBadgeContainer,
  ButtonContainer,
  UserOwnerContainer,
  UserOwnerText,
} from './styles';
import icons from 'assets/Icon';
import Button from 'components/atoms/Button';
import BadgeDetailsResumenInfo from 'components/molecules/BadgeDetailsResumenInfo';
import ShareBadgeTemp from 'components/molecules/ShareBadgeTemp';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { GetBadgeDetailsThunk } from 'actions/Thunks/AdminThunk';
import { DetailBadgesThunk } from 'actions/Thunks/AdminPathsThunk';
import {
  DetailBadgesUnAuthUserThunk,
  DetailBadgesUserThunk,
  GetBadgeTemplateDetailUnAuthUserThunk,
} from 'actions/Thunks/UnAuthUsersThunks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { NonAuthenticatedUrls } from 'constants/NavigationUrls';
import VerifyBadgeModal from 'components/molecules/VerifyBadgeModal';
import { VerifyBadgeThunk } from 'actions/Thunks/UnAuthUsersThunks';
import { ENVIRONMENT_URL, ENVIRONMENT_URL_BADGE } from 'utils/index';
import { GetDataRRSSThunk, GetDataRRSSUnauthThunk } from 'actions/Thunks/ShareRRSSThunk';
import './styles.css';
import ShareBadgeModal from 'components/molecules/ShareBadgeModal/ShareBadgeModal';
interface BadgeDetailsProps {
  badge_id: string;
  organization_badge?: boolean;
  unauthorized?: boolean;
  showHeaderMenu?: boolean;
}
const BadgeDetails = ({
  badge_id,
  organization_badge,
  unauthorized,
  showHeaderMenu,
}: BadgeDetailsProps): JSX.Element => {
  const [showShareScreen, setShowShareScreen] = React.useState<boolean>(false);
  const [userBadge, setUserBadgeInfo] = React.useState<any>(null);
  const [badgeTemplate, setBadgeTemplaeteInfo] = React.useState<any>(null);
  const [userBadgeTemplate, setUserBadgeTemplateInfo] = React.useState<any>(null);
  const [showVerifyScreen, setVerifyScreen] = React.useState<boolean>(false);
  const [userInfo, setUserInfo] = React.useState<any>(null);

  const user = useAppSelector((state) => state.user.userInfo);
  const auth = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const getBadgesDetails = React.useCallback(async () => {
    const badge = await dispatch(GetBadgeDetailsThunk(badge_id));
    if (GetBadgeDetailsThunk.rejected.match(badge)) {
      toast.error(badge.payload as string);
    } else {
      setUserBadgeInfo(badge.payload);
    }
    const shareBadgeData = await dispatch(GetDataRRSSThunk(badge_id));
    if (GetDataRRSSThunk.rejected.match(shareBadgeData)) {
      console.log(shareBadgeData.payload as string);
    }
  }, [badge_id, dispatch]);

  const getBadgesOrganizationDetails = React.useCallback(async () => {
    const badge = await dispatch(DetailBadgesThunk(badge_id));
    if (DetailBadgesThunk.rejected.match(badge)) {
      toast.error(badge.payload as string);
    } else {
      setBadgeTemplaeteInfo(badge.payload);
    }
  }, [badge_id, dispatch]);

  const getBadgeUnAuthUser = React.useCallback(async () => {
    if (auth.isAuthenticated) {
      const badge = await dispatch(DetailBadgesUserThunk(badge_id));
      if (DetailBadgesUserThunk.rejected.match(badge)) {
        toast.error(badge.payload as string);
      } else {
        setUserBadgeTemplateInfo(badge.payload);
      }
    } else {
      const badge = await dispatch(DetailBadgesUnAuthUserThunk(badge_id));
      if (DetailBadgesUnAuthUserThunk.rejected.match(badge)) {
        console.log(badge.payload as string);
      } else {
        setUserBadgeTemplateInfo(badge.payload);
      }
      const shareBadgeData = await dispatch(GetDataRRSSUnauthThunk(badge_id));
      if (GetDataRRSSUnauthThunk.rejected.match(shareBadgeData)) {
        console.log(shareBadgeData.payload as string);
      }
    }
  }, [auth.isAuthenticated, badge_id, dispatch]);

  const getBadgeTemplateUnAuthUser = React.useCallback(async () => {
    if (auth.isAuthenticated) {
      const badge = await dispatch(GetBadgeTemplateDetailUnAuthUserThunk(badge_id));
      if (GetBadgeTemplateDetailUnAuthUserThunk.rejected.match(badge)) {
        toast.error(badge.payload as string);
      } else {
        setBadgeTemplaeteInfo(badge.payload);
      }
    } else {
      const badge = await dispatch(DetailBadgesUnAuthUserThunk(badge_id));
      if (DetailBadgesUnAuthUserThunk.rejected.match(badge)) {
        toast.error(badge.payload as string);
      } else {
        setUserBadgeTemplateInfo(badge.payload);
      }
    }
  }, [auth.isAuthenticated, badge_id, dispatch]);

  const getUserBadgeInfo = React.useCallback(async () => {
    const badge_info = await dispatch(VerifyBadgeThunk(badge_id));
    if (VerifyBadgeThunk.rejected.match(badge_info)) {
    } else {
      setUserInfo(badge_info.payload);
    }
  }, [badge_id, dispatch]);

  React.useEffect(() => {
    getUserBadgeInfo();
    if (organization_badge && !unauthorized) {
      getBadgesOrganizationDetails();
    } else if (organization_badge && unauthorized) {
      getBadgeTemplateUnAuthUser();
    } else if (!organization_badge && unauthorized) {
      getBadgeUnAuthUser();
    } else {
      getBadgesDetails();
    }
  }, [
    organization_badge,
    unauthorized,
    getUserBadgeInfo,
    getBadgesOrganizationDetails,
    getBadgeTemplateUnAuthUser,
    getBadgeUnAuthUser,
    getBadgesDetails,
  ]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={showHeaderMenu}
      showMenuHeaderAcredited={user?.user_type.code === 'ADMIN' ? false : true}
      showCommon={auth.isAuthenticated === true ? true : false}
    >
      {userBadge !== null ? (
        <BadgeDetailsContainer>
          <BadgeDetailsResumenInfo
            badge_details={{
              name: userBadge.data.badge_template.name,
              image: userBadge.data.badge_template.image.url,
              issuer: userBadge.data.issuer_organization,
              details: {
                type: userBadge.data.badge_template.type_category,
                cost: userBadge.data.badge_template.cost,
                level: userBadge.data.badge_template.level,
                issue_date: userBadge.data.issued_at,
                duration: userBadge.data.badge_template.duration,
                duration_type: userBadge.data.badge_template.duration_type,
              },
            }}
            badgeId={badge_id}
            badge_url={`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.url_badges}/${userBadge.data.id}`}
          />
          <BadgeInfoWrapper>
            <BadgeNameWrapper>
              <BadgeName>{userBadge.data.badge_template.name}</BadgeName>
              {!unauthorized && (
                <div>
                  <button onClick={() => setShowShareScreen(true)} className="share-button">
                    Compartir
                    <icons.ShareIcon />
                  </button>
                  <ButtonContainer>
                    <Button
                      label="Verificar"
                      widthCustom={150}
                      lightMode={true}
                      onClick={() => setVerifyScreen(true)}
                    />
                  </ButtonContainer>
                </div>
              )}
            </BadgeNameWrapper>
            <ItemWrapper>
              <ItemTitle>Descripción</ItemTitle>
              <ItemDesc>{userBadge.data.badge_template.description}</ItemDesc>
            </ItemWrapper>
            <ItemWrapper>
              <ItemTitle>Habilidades</ItemTitle>
              <SkillsSectionWrapper>
                {userBadge.data.badge_template.skills &&
                  userBadge.data.badge_template.skills.map((skill: string, index: number) => {
                    return (
                      <SkillContainer key={index}>
                        <icons.EmptyHexagonIcon />
                        <ItemDesc>{skill}</ItemDesc>
                      </SkillContainer>
                    );
                  })}
              </SkillsSectionWrapper>
            </ItemWrapper>
            <ItemWrapper>
              <ItemTitle>Requisitos</ItemTitle>
              {userBadge.data.badge_template.badge_template_activities &&
                userBadge.data.badge_template.badge_template_activities.map(
                  (requirement: { title: string }, index: number) => {
                    return (
                      <RequirementsWrapper key={index}>
                        <icons.CheckIcon />
                        <ItemDesc>{requirement.title}</ItemDesc>
                      </RequirementsWrapper>
                    );
                  }
                )}
            </ItemWrapper>
            <ItemWrapper>
              <ItemTitle>Información Adicional</ItemTitle>
              {userBadge.data.badge_template.additional_info !== undefined ? (
                <MoreInfoWrapper>
                  <a
                    href={`${
                      userBadge.data.badge_template.additional_info?.includes('https:/')
                        ? userBadge.data.badge_template.additional_info
                        : 'https://' + userBadge.data.badge_template.additional_info
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <icons.URLIcon />
                    <ItemDesc>{userBadge.data.badge_template.additional_info}</ItemDesc>
                  </a>
                </MoreInfoWrapper>
              ) : (
                <MoreInfoWrapper>
                  <a
                    href={`${
                      userBadge.data.badge_template.url.includes('https:/')
                        ? userBadge.data.badge_template.url
                        : 'https://' + userBadge.data.badge_template.url
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <icons.URLIcon />
                    <ItemDesc>{userBadge.data.badge_template.url}</ItemDesc>
                  </a>
                </MoreInfoWrapper>
              )}
            </ItemWrapper>
          </BadgeInfoWrapper>
          <ShareBadgeModal
            badgeId={badge_id}
            badge_details={userBadge.data}
            showModal={showShareScreen}
            handleSocialModal={() => setShowShareScreen(false)}
          />
          <VerifyBadgeModal
            badge_id={badge_id}
            showModal={showVerifyScreen}
            handleModal={() => setVerifyScreen(false)}
          />
        </BadgeDetailsContainer>
      ) : userBadgeTemplate !== null ? (
        <BadgeDetailsContainer>
          <BadgeDetailsResumenInfo
            badge_details={{
              name: userBadgeTemplate.data?.badge_template.name,
              image: userBadgeTemplate.data?.badge_template.image.url,
              issuer: userBadgeTemplate.data?.badge_template.owner.name,
              details: {
                type: userBadgeTemplate.data?.badge_template.type_category,
                cost: userBadgeTemplate.data?.badge_template.cost,
                level: userBadgeTemplate.data?.badge_template.level,
                issue_date: userBadgeTemplate.data?.issued_at,
                duration: userBadgeTemplate.data?.badge_template.duration,
                duration_type: userBadgeTemplate.data?.badge_template.duration_type,
              },
            }}
            badge_url={`${ENVIRONMENT_URL}${NonAuthenticatedUrls.badges}/${userBadgeTemplate.data?.badge_template.id}`}
            organization_badge={true}
          />
          {!showShareScreen ? (
            <div>
              {userInfo && (
                <UserOwnerContainer>
                  <icons.BadgeIlustration />
                  <UserOwnerText>
                    Esta insignia fue emitida a <span>{userInfo.issued_to}</span> el{' '}
                    <span>{userInfo.issued_at}</span>
                  </UserOwnerText>
                </UserOwnerContainer>
              )}
              <BadgeInfoWrapper>
                <BadgeNameWrapper>
                  <BadgeName>{userBadgeTemplate.data?.badge_template.name}</BadgeName>
                  {!unauthorized && (
                    <Button
                      label="Compartir"
                      widthCustom={150}
                      onClick={() => setShowShareScreen(true)}
                    />
                  )}
                  <ButtonContainer>
                    <Button
                      label="Verificar"
                      widthCustom={150}
                      lightMode={true}
                      onClick={() => setVerifyScreen(true)}
                    />
                  </ButtonContainer>
                </BadgeNameWrapper>
                <ItemWrapper>
                  <ItemTitle>Descripción</ItemTitle>
                  <ItemDesc>{userBadgeTemplate.data?.badge_template.description}</ItemDesc>
                </ItemWrapper>
                <ItemWrapper>
                  <ItemTitle>Habilidades</ItemTitle>
                  <SkillsSectionWrapper>
                    {userBadgeTemplate.data?.badge_template.skills &&
                      userBadgeTemplate.data?.badge_template.skills.map(
                        (skill: string, index: number) => {
                          return (
                            <SkillContainer key={index}>
                              <icons.EmptyHexagonIcon />
                              <ItemDesc>{skill}</ItemDesc>
                            </SkillContainer>
                          );
                        }
                      )}
                  </SkillsSectionWrapper>
                </ItemWrapper>
                <ItemWrapper>
                  <ItemTitle>Requisitos</ItemTitle>
                  {userBadgeTemplate.data?.badge_template.badge_template_activities &&
                    userBadgeTemplate.data?.badge_template.badge_template_activities.map(
                      (requirement: { title: string }, index: number) => {
                        return (
                          <RequirementsWrapper key={index}>
                            <icons.CheckIcon />
                            <ItemDesc>{requirement.title}</ItemDesc>
                          </RequirementsWrapper>
                        );
                      }
                    )}
                </ItemWrapper>
                <ItemWrapper>
                  <ItemTitle>Información Adicional</ItemTitle>
                  {userBadgeTemplate.data.badge_template.additional_info !== undefined ? (
                    <MoreInfoWrapper>
                      <a
                        href={`${
                          userBadgeTemplate.data.badge_template.additional_info.includes('https:/')
                            ? userBadgeTemplate.data.badge_template.additional_info
                            : 'https://' + userBadgeTemplate.data.badge_template.additional_info
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <icons.URLIcon />
                        <ItemDesc>{userBadgeTemplate.data.badge_template.additional_info}</ItemDesc>
                      </a>
                    </MoreInfoWrapper>
                  ) : (
                    <MoreInfoWrapper>
                      <a
                        href={`${
                          userBadgeTemplate.data.badge_template.url.includes('https:/')
                            ? userBadgeTemplate.data.badge_template.url
                            : 'https://' + userBadgeTemplate.data.badge_template.url
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <icons.URLIcon />
                        <ItemDesc>{userBadgeTemplate.data.badge_template.url}</ItemDesc>
                      </a>
                    </MoreInfoWrapper>
                  )}
                </ItemWrapper>
                <VerifyBadgeModal
                  badge_id={userBadgeTemplate?.data.id}
                  showModal={showVerifyScreen}
                  handleModal={() => setVerifyScreen(false)}
                />
              </BadgeInfoWrapper>
            </div>
          ) : (
            <ShareBadgeContainer>
              <ShareBadgeTemp
                badge_details={badgeTemplate}
                closeShareSection={() => setShowShareScreen(false)}
              />
            </ShareBadgeContainer>
          )}
        </BadgeDetailsContainer>
      ) : (
        <h1>Loading...</h1>
      )}
    </CommonPageLayout>
  );
};

export default BadgeDetails;
