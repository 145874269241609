import { UserDataThunk } from 'actions/Thunks/UserThunk';
import TermsPage from 'components/organisms/Terms';
import { AccreditedRole } from 'constants/NavigationUrls';
import { useAppDispatch } from 'hooks/redux';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

const Terms: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userData = React.useCallback(() => {
    dispatch(UserDataThunk()).then((user) => {
      if (UserDataThunk.fulfilled.match(user)) {
        if (user.payload && user.payload.terms_conditions) {
          history.push(AccreditedRole.profile);
        }
      } else {
        toast.error(user.payload as string);
      }
    });
  }, [dispatch, history]);

  React.useEffect(() => {
    userData();
  }, [userData]);

  return <TermsPage />;
};

export default Terms;
