import styled from 'styled-components';

export const NewIndividualCourseContainer = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: self-start;
  margin: 80px 20px;
`;

export const CourseCoverIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
