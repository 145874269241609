import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangeStatusPath,
  GetAcceptBadge,
  GetListRoutes,
  GetReminderIndividual,
  GetRenewBadge,
  GetStatusEvidence,
  PostEditBadge,
  PostReminderMasive,
  UploadEvidence,
} from 'actions/ApiClient/AcreditedActions';
import { GetErrorMessage } from 'utils';

export const ListRoutesThunk = createAsyncThunk(
  'catalog/list-course',
  async (_, { rejectWithValue }) => {
    try {
      const ListMyPaths = await GetListRoutes();
      return ListMyPaths;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const UploadEvidenceThunk = createAsyncThunk(
  'upload/evidence-path',
  async (info: IUploadevidence, { rejectWithValue }) => {
    try {
      const upload = await UploadEvidence(info);
      return upload;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const ChangeStatusPathThunk = createAsyncThunk(
  'upload/evidence-path-status',
  async (statusInfo: IStatusInfo, { rejectWithValue }) => {
    try {
      const createNewAdditionalInfoForm = await ChangeStatusPath(
        statusInfo.uuid,
        statusInfo.status
      );
      return createNewAdditionalInfoForm;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetStatusEvidenceThunk = createAsyncThunk(
  'path/status-evidence',
  async (id: string, { rejectWithValue }) => {
    try {
      const StatusEvidence = await GetStatusEvidence(id);
      return StatusEvidence;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetAcceptBadgeThunk = createAsyncThunk(
  'badges/accept',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const accept = await GetAcceptBadge(uuid);
      return accept;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const PostEditBadgeThunk = createAsyncThunk(
  'badges/public',
  async (content: IContentInfo, { rejectWithValue }) => {
    try {
      const publicBadge = await PostEditBadge(content);
      return publicBadge;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetReminderIndividualThunk = createAsyncThunk(
  'badges/reminder-individual',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const individual = await GetReminderIndividual(uuid);
      return individual;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const PostReminderMasiveThunk = createAsyncThunk(
  'badges/reminder-massive',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const masive = await PostReminderMasive(uuid);
      return masive;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetRenewBadgeThunk = createAsyncThunk(
  'badges/renew-badge',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const renewBadge = await GetRenewBadge(uuid);
      return renewBadge;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
