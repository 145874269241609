import { BLUE_LIGHT, PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { FormGroup, Label, Input, Form } from 'reactstrap';
import styled from 'styled-components';

export const PathInfoContainer = styled(Form)`
  margin-left: 20px;
  padding: 43px 23px 43px 22px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  width: 100%;

  @media ${device.mediumLaptop} {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

export const FormLabelSimple = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 12px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  @media ${device.mediumMobile} {
    flex-direction: column-reverse;
  }
`;

export const WarningText = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 30px;
`;

export const InfoText = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin-bottom: 20px;
  margin-top: 1px;
`;

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;
  display: flex;
  margin: 0;
  margin-bottom: 22px;

  @media ${device.laptops} {
    flex-direction: column;
  }
`;

export const FormLabelSimpleWrapper = styled.div`
  width: 20%;
  text-align: end;
  padding-right: 10px;
  margin: 0;

  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormDateLabelSimpleWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  margin: 0;
  @media ${device.desktop} {
    width: 100%;
    text-align: left;
  }
`;

export const FormInputWrapper = styled.div`
  width: 80%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const InputDate = styled(Input)`
  height: 20px;
`;

export const SwitchContainer = styled.div`
  width: '100%';
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: 20px;
`;

export const InputSelectContainer = styled.div`
  width: '100%';
  padding-top: 10px;
`;

export const SwitchLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin: 5px 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;
