import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  ExpiredCodePageTitle,
  ExpiredCodePageDesc,
  ExpiredCodePageButtonWrapper,
  ExpiredCodePageContainer,
} from './styles';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';
import { useHistory } from 'react-router-dom';
import { AuthNavigations } from 'constants/NavigationUrls';

const ExpiredCode = (): JSX.Element => {
  const history = useHistory();

  const goToLogin = () => {
    history.push(AuthNavigations.login);
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={false}
    >
      <ExpiredCodePageContainer>
        <ExpiredCodePageTitle>¡Ohh no! tu invitación expiró</ExpiredCodePageTitle>
        <ExpiredCodePageDesc>
          Comunícate con el administrador de tu organización para que te podamos enviar nuevamente
          la invitación a la plataforma.
        </ExpiredCodePageDesc>
        <ExpiredCodePageButtonWrapper>
          <Button label="Volver al Inicio" widthCustom={477} onClick={goToLogin} />
        </ExpiredCodePageButtonWrapper>
        <icons.ExpiredCodePageIlustration />
      </ExpiredCodePageContainer>
    </CommonPageLayout>
  );
};

export default ExpiredCode;
