import AdminPreviewPaths from 'components/organisms/AdminPreviewPaths';
import * as React from 'react';

const PreviewPaths: React.FC = () => {
  return (
    <React.Fragment>
      <AdminPreviewPaths />
    </React.Fragment>
  );
};

export default PreviewPaths;
