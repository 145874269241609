import { ReactNode } from 'react';
import styles from './styles.module.css';

interface Props {
  mainNode: ReactNode;
  children: ReactNode;
  [rest: string]: any;
}

const AcredittaTooltip = ({ mainNode, children, ...rest }: Props) => {
  return (
    <div className={`${styles.tooltip}`} {...rest}>
      {mainNode}
      <span className={`${styles.tooltiptext}`}>{children}</span>
    </div>
  );
};

export default AcredittaTooltip;
