import icons from 'assets/Icon';
import AcredittaText from 'components/atoms/AcredittaText';
import TextInput from 'components/atoms/TextInput';
import { FC, useEffect, useState, useRef } from 'react';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import { Col } from 'reactstrap';
import Button from '../../atoms/Button';
import { PasswordForm, PasswordFormGroup } from './styles';
import { manageErrors } from '../../../helpers/manage-error';
import { useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { resendConfirmationCode } from 'services/user.service';
import { setTimeout } from 'timers';
import { obscureEmail } from 'helpers';

/**
 * Form for validate email
 * @returns React.Node
 */

const TIMESET = 60;

interface PasswordRecoveryProps {
  handleSubmit: UseFormHandleSubmit<SingUpValidate>;
  onSubmitConfirmCode: (user: SingUpValidate) => Promise<void>;
  control: Control<SingUpValidate>;
  errors: Record<string, any>;
  loading: boolean;
}

const CodeValidate: FC<PasswordRecoveryProps> = ({
  handleSubmit,
  onSubmitConfirmCode,
  control,
  errors,
  loading,
}) => {
  const { emailSingUp } = useAppSelector((state) => state.admin);
  const { replace } = useHistory();
  const [counter, setCounter] = useState(TIMESET);
  const timer1 = useRef(null as any);

  useEffect(() => {
    if (counter > 0) {
      timer1.current = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }

    return () => clearTimeout(timer1.current);
  }, [counter]);

  useEffect(() => {
    if (!emailSingUp) {
      replace('/');
    }
  }, [emailSingUp, replace]);

  const handleResendCode = async () => {
    try {
      await resendConfirmationCode(emailSingUp!);
      toast.success('Se ha renviado el código de verificación al correo');
      setCounter(TIMESET);
    } catch (e: any) {
      manageErrors(e, 'Ha ocurrido un error, por favor intente nuevamente', { showToast: true });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <PasswordForm onSubmit={handleSubmit(onSubmitConfirmCode)}>
            <AcredittaText variant="h3" align="center">
              Verificación de correo electrónico
            </AcredittaText>
            <div style={{ padding: '0 300px' }}>
              <AcredittaText variant="body1" align="center" color="secondary">
                Ingresa el código de verificación enviado al correo{' '}
                <span className="font-weight-bold">{obscureEmail(emailSingUp as any)}</span>. Este
                código estará activo por 5 minutos únicamente
              </AcredittaText>
            </div>

            <PasswordFormGroup row>
              <Col sm={6}>
                <Controller
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      label="Código de Verificación"
                      icon={<icons.PasswordIcon />}
                      type="number"
                      name="code"
                      placeholder="Ingresar código"
                      value={value}
                      onChange={(value) => onChange(value)}
                      error={errors}
                    />
                  )}
                  name="code"
                  rules={{
                    required: true,
                  }}
                />
              </Col>
            </PasswordFormGroup>

            <div className="row">
              <div className="col">
                {counter > 0 ? (
                  <>
                    <AcredittaText variant="body1" color="secondary">
                      Podrás solicitar un código nuevo en
                    </AcredittaText>
                    <AcredittaText variant="h3" align="center">
                      {counter}
                      <span style={{ marginLeft: 5, fontSize: 12, color: 'grey' }}>segundos</span>
                    </AcredittaText>
                  </>
                ) : (
                  <AcredittaText variant="body1">
                    ¿No recibiste el código?{' '}
                    <span
                      onClick={handleResendCode}
                      style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer ' }}
                    >
                      Reenviar código de verificación
                    </span>
                  </AcredittaText>
                )}
              </div>
            </div>

            <div>
              <Button label={'Enviar'} disabled={loading} loading={loading} widthCustom={150} />
            </div>
          </PasswordForm>
        </div>
      </div>
    </div>
  );
};

export default CodeValidate;
