import AcredittaText from 'components/atoms/AcredittaText';
import { AdminRole } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';

const MobileCreateBadgeLink = () => {
  return (
    <Link to={AdminRole.createBadge}>
      <AcredittaText variant="h5" fontWeight="600">
        <span> + </span>Crear insignia
      </AcredittaText>
    </Link>
  );
};

export default MobileCreateBadgeLink;
