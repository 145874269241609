import * as React from 'react';
import { StepWizardProps } from 'react-step-wizard';
import {
  AdminDndTitle,
  AdminDndContainer,
  AdminDndHeaderTitles,
  AdminDndSubTitles,
  SubtitleFirst,
  SubtitleSecond,
  HorizontalCourseListWrapper,
  NextStepWrapperButton,
  ModalContainer,
  ModalBodyStyled,
  ModalTitle,
  ModalText,
} from './syles';
import AdminDnDBoard from 'components/molecules/AdminDnDBoard';
import AdminDnDCardCourse from 'components/atoms/AdminDnDCardCourse';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  DetailPathwayContentThunk,
  SelectedCourseListThunk,
  UpdateLearningPathListViewThunk,
} from 'actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';
import StyledButton from 'components/atoms/Button';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ButtonCom from 'components/atoms/ButtonCom';
import giftTutorial from '../../../assets/images/Organizar_Ruta_00.m.gif';

interface AdminCreateDnDProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const AdminCreateDnD = ({ onNextStep }: AdminCreateDnDProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const coursesSelector = useAppSelector((state) => state.courses);

  const [modal, setModal] = React.useState(
    localStorage.getItem('tour-dnd') === 'true' ? false : true
  );

  const initListCourseSelected = React.useCallback(async () => {
    if (coursesSelector.pathwayCreated) {
      const courseSelectedList = await dispatch(
        SelectedCourseListThunk(coursesSelector.pathwayCreated.id)
      );
      if (SelectedCourseListThunk.rejected.match(courseSelectedList)) {
        toast.error(courseSelectedList.payload as string);
      }

      const cont = await dispatch(DetailPathwayContentThunk(coursesSelector.pathwayCreated.id));
      if (DetailPathwayContentThunk.rejected.match(cont)) {
        toast.error(cont.payload as string);
      }
    }
  }, [coursesSelector.pathwayCreated, dispatch]);

  const sendPathwayCreate = async () => {
    if (coursesSelector.pathwayDragAndDropSelection) {
      const changeSelection = coursesSelector.pathwayDragAndDropSelection.map((pathway) => {
        const updatePathElement: IPathElementCourseList[] = pathway.path_element.map(
          (element, index) => ({
            id: element.id,
            is_required: false,
            element_parent: index === 0 ? null : pathway.path_element[index - 1].id,
          })
        );

        return { ...pathway, path_element: updatePathElement };
      });

      const sendPath = await dispatch(UpdateLearningPathListViewThunk(changeSelection));

      if (UpdateLearningPathListViewThunk.rejected.match(sendPath)) {
        toast.error(sendPath.payload as string);
      } else {
        onNextStep();
      }
    }
  };

  const openTour = () => {
    setModal(!modal);
    localStorage.setItem('tour-dnd', 'false');
  };

  const closeTour = () => {
    setModal(!modal);
    localStorage.setItem('tour-dnd', 'true');
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    initListCourseSelected();
  }, [initListCourseSelected]);

  const visible = false;

  return (
    <DndProvider backend={HTML5Backend}>
      <AdminDndContainer>
        <AdminDndHeaderTitles>
          <AdminDndTitle>
            Organiza los cursos que serán parte de la nueva ruta de aprendizaje
          </AdminDndTitle>
          <AdminDndSubTitles>
            {/* TODO: add functionality to add the path name and save it to the db */}
            {visible && <SubtitleFirst>Escribe el nombre de la ruta</SubtitleFirst>}
            {/* TODO: add functionality to add more courses to the dnd */}
            {visible && <SubtitleSecond>Agregar más cursos +</SubtitleSecond>}
          </AdminDndSubTitles>
        </AdminDndHeaderTitles>
        <HorizontalCourseListWrapper>
          {coursesSelector.selectedCourseList && coursesSelector.pathwayDetailContent && (
            <React.Fragment>
              {coursesSelector.selectedCourseList.map((course, index) => (
                <React.Fragment key={index}>
                  <AdminDnDCardCourse
                    {...course}
                    name={course.name}
                    duration={course.duration}
                    provider_name={course.provider_name}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </HorizontalCourseListWrapper>
        <AdminDnDBoard />
        <NextStepWrapperButton>
          <StyledButton label="Siguiente" onClick={sendPathwayCreate} widthCustom={230} />
        </NextStepWrapperButton>
      </AdminDndContainer>
      <ModalContainer
        isOpen={modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyled>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5%',
            }}
          >
            <ModalTitle>Organiza tu ruta</ModalTitle>
            <ModalText>
              Arrastra los cursos que seleccionaste al tablero y dales el orden que quieras.
            </ModalText>
            <img src={giftTutorial} style={{ width: '80%' }} alt="gif-tutorial" />
            <ModalText>
              La ruta va de izquierda a derecha y puedes colocar cursos en paralelo.
            </ModalText>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '4%',
              }}
            >
              <ButtonCom
                label="No volver a mostrar"
                widthCustom={170}
                lightMode={true}
                onClick={() => closeTour()}
                style={{
                  padding: '3%',
                }}
              />
              <div
                style={{
                  padding: '3%',
                }}
              >
                <ButtonCom label={'Entendido'} widthCustom={170} onClick={() => openTour()} />
              </div>
            </div>
          </div>
        </ModalBodyStyled>
      </ModalContainer>
    </DndProvider>
  );
};

export default AdminCreateDnD;
