import styled from 'styled-components';

export const CheckWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 10px;
  color: #02b148;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 30px;
  border-radius: 100px;
  svg {
    width: 30px;
  }
`;

export const ColStyled = styled.article`
  width: 100%;
  max-width: 260px;
  position: relative;
`;
