import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const LearningPathPreviewDescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LearningPathBasicInfoContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LearningPathOwnerInfo = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const LearningPathOwner = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${SECONDARY_COLOR};
  margin-left: 5px;
`;

export const LearningPathScore = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  margin-left: 5px;
  margin-bottom: 3px;
`;

export const LearningPathScoreWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LearningPathDescriptionWrapper = styled.div`
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  margin-top: 30px;
`;

export const LearningPathDescriptionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  padding: 4px 30px 10px 30px;
`;

export const LearningPathDescription = styled.p`
  font-size: 20px;
  line-height: 30px;
  color: #53565a;
  padding: 10px 30px;
  margin-bottom: 0px;
`;

export const LearningPathDescriptionContainer = styled.div`
  width: 80%;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const LearningPathOwnerInfoWrapper = styled.div`
  margin: 40px 20px;
`;
