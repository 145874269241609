import * as React from 'react';
import icons from 'assets/Icon';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  ContainerNoData,
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  SubtitleFirst,
  SubtitleSecond,
} from './styles';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
import { Badge, Button, Card, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  DeleteUsersThunk,
  GetListUsersOrgThunk,
  ReSendInviteThunk,
} from 'actions/Thunks/AdminPathsThunk';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import { AdminDataThunk } from 'actions/Thunks/UserThunk';
import NoDataUsers from 'components/molecules/NoDataUsers';

const AdminUsersPreview: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [usersData, setUsersInfo] = React.useState<any>(null);
  const [modal, setModal] = React.useState(false);
  const [idUser, setIdUser] = React.useState('');
  const [nameUser, setNameUser] = React.useState('');

  const user = useAppSelector((state) => state.user);

  const getAdminInfo = React.useCallback(async () => {
    const adminInfoData = await dispatch(AdminDataThunk());
    if (AdminDataThunk.rejected.match(adminInfoData)) {
      toast.error(adminInfoData.payload as string);
    }
  }, [dispatch]);

  const getListUsers = React.useCallback(async () => {
    if (user.userInfo) {
      const usersList = await dispatch(GetListUsersOrgThunk(user.userInfo.organization.id));
      if (GetListUsersOrgThunk.rejected.match(usersList)) {
        toast.error(usersList.payload as string);
      } else {
        setUsersInfo(usersList.payload);
      }
    }
  }, [user.userInfo, dispatch]);

  const deleteUsers = React.useCallback(async () => {
    if (idUser) {
      const deleteUser = await dispatch(DeleteUsersThunk(idUser));
      if (DeleteUsersThunk.rejected.match(deleteUser)) {
        toast.error(deleteUser.payload as string);
      } else {
        history.go(0);
        toast.success('Usuario Eliminado Correctamente');
      }
    }
  }, [history, idUser, dispatch]);

  const resendInvite = React.useCallback(
    async (correo: string) => {
      const resend = await dispatch(ReSendInviteThunk(correo));
      if (ReSendInviteThunk.rejected.match(resend)) {
        toast.error(resend.payload as string);
      } else {
        toast.success('Usuario Invitado Nuevamente');
      }
    },
    [dispatch]
  );

  const navigationLoadNewUser = () => {
    history.push(`${AdminRole.loadNewUsers}`);
  };

  const toggle = (id: any, first_name: any, last_name: any) => {
    setModal(!modal);
    setIdUser(id);
    let name = `${first_name}${' '}${last_name}`;
    setNameUser(name);
  };

  const deleteUser = () => {
    if (idUser) {
      setModal(!modal);
      deleteUsers();
    }
  };

  const resendInviteMeth = (correo: string) => {
    resendInvite(correo);
  };

  React.useEffect(() => {
    getAdminInfo();
    getListUsers();
  }, [getAdminInfo, getListUsers]);

  return (
    <React.Fragment>
      <CommonPageLayout
        showHeader={true}
        showFooter={false}
        childrenMargin={false}
        showMenuHeader={true}
        showMenuHeaderAcredited={false}
        showCommonMenu={false}
        showCommon={true}
        showOnboarding={false}
      >
        <CourseCatalogContainer>
          <CourseCatalogHeaderTitles>
            <CourseCatalogSubTitles>
              <SubtitleFirst>Usuarios</SubtitleFirst>
              <SubtitleSecond onClick={() => navigationLoadNewUser()}>
                Cargar Usuarios +
              </SubtitleSecond>
            </CourseCatalogSubTitles>
          </CourseCatalogHeaderTitles>
          <Card>
            <Table hover responsive striped>
              <thead>
                <tr>
                  <th>Usuario</th>
                  <th>Cargo Actual</th>
                  <th>Usuario</th>
                  <th>Invitación</th>
                  <th>Estatus</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              {usersData !== null ? (
                usersData.results.length !== 0 ? (
                  <tbody>
                    {usersData.results.map((user: any) => {
                      return (
                        <tr key={user.id}>
                          {/* <th onClick={() => handleBadgeDetail(path.id)}> */}
                          <th
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'row',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '50px',
                                marginRight: '10px',
                              }}
                            >
                              <img src={user?.avatar} style={{ width: '100%' }} alt="img" />
                            </div>
                            {user?.first_name + ' ' + user?.last_name}
                          </th>
                          <th>
                            {user?.job_title !== undefined &&
                              (user?.job_title === null ? (
                                <p>No disponible</p>
                              ) : (
                                <p>{user?.job_title}</p>
                              ))}
                          </th>
                          <th>{user?.user_type}</th>
                          <th>{user?.invitation_date}</th>
                          <th>
                            {user?.check_cognito_user !== undefined &&
                              (user?.check_cognito_user === true ? (
                                <Badge color="success">Aceptada</Badge>
                              ) : (
                                <Badge color="warning">Pendiente</Badge>
                              ))}
                          </th>
                          <td>
                            <div className="row text-center">
                              <div className="col-4">
                                {user?.check_cognito_user && (
                                  <div
                                    className="p-3 cursor-pointer"
                                    onClick={() =>
                                      history.push(`${AdminRole.loadUsers}/${user.cognito_id}`)
                                    }
                                  >
                                    <icons.EditPath />
                                    <span className="font-weight-bold d-block mt-2 small">
                                      Editar
                                    </span>
                                  </div>
                                )}
                                {user?.check_cognito_user === false && (
                                  <div
                                    className="p-3 tcursor-pointer"
                                    onClick={() => resendInviteMeth(user.email)}
                                  >
                                    <icons.Icono_Reenviar />
                                    <span className="font-weight-bold d-block mt-2 small">
                                      Reenviar
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-4">
                                <div
                                  className="p-3 text-center cursor-pointer"
                                  onClick={() =>
                                    toggle(user.cognito_id, user.first_name, user.last_name)
                                  }
                                >
                                  <icons.DeletePath color="white" />
                                  <span className="font-weight-bold d-block mt-2 small text-danger">
                                    Eliminar
                                  </span>
                                  <Modal isOpen={modal} backdrop={false} centered>
                                    <ModalBody>
                                      <h2 color="#002057">
                                        <strong>¿Deseas eliminar al usuario?</strong>
                                      </h2>
                                      ¿Estás seguro que quieres eliminar al usuario {nameUser}?
                                      <br />
                                      Al hacerlo perderás toda la información
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button onClick={() => setModal(!modal)}>Cancelar</Button>{' '}
                                      <Button color="danger" onClick={() => deleteUser()}>
                                        Eliminar
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <ContainerNoData
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5%',
                      width: '205%',
                    }}
                  >
                    <NoDataUsers />
                  </ContainerNoData>
                )
              ) : (
                <ContainerNoData
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '580%',
                    padding: '10%',
                  }}
                >
                  <ReactLoading type="spin" color={'#002057'} height={100} width={100} />
                </ContainerNoData>
              )}
            </Table>
          </Card>
          <div style={{ height: '100px' }}></div>
        </CourseCatalogContainer>
      </CommonPageLayout>
    </React.Fragment>
  );
};

export default AdminUsersPreview;
