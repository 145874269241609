import * as React from 'react';
import BadgeDetail from 'components/organisms/BadgeDetail';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';

const DetailOrganizationBadge = (): JSX.Element => {
  const history = useHistory();

  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  let url = useLocation().pathname;
  let { uuid } = useParams<{ uuid: string }>();
  if (auth.isAuthenticated === true) {
    if (user.userRole === 3) {
      history.push(`${AccreditedRole.badgeDetails}/${uuid}`);
    } else {
      history.push(`${AdminRole.badgeDetails}/${uuid}`);
    }
  }

  return (
    <React.Fragment>
      <BadgeDetail
        badge_id={url.substring(url.lastIndexOf('/') + 1)}
        unauthorized={true}
        showHeaderMenu={false}
      />
    </React.Fragment>
  );
};

export default DetailOrganizationBadge;
