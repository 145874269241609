import AcredittaRequest from 'actions/requests/api';

/**
 * Get data from organization to fill update form
 * @param id
 * @returns Promise
 * @author Jose Quintero
 */
export const getOrganizationData = async (id: number | undefined) => {
  return await AcredittaRequest.get(`organization/profile/${id}`);
};

/**
 * Update data organization
 * @param id
 * @param payload
 * @returns Promise
 * @author Jose Quintero
 */
export const updateOrganizationData = async (id: number | undefined, payload: Object) => {
  return await AcredittaRequest.patch(`organization/profile/${id}/`, payload);
};

/**
 * Upload organization photo
 * @param id
 * @param formData
 * @returns Promise
 */
export const uploadOrganizationImage = async (id: number | undefined, formData: FormData) => {
  return await AcredittaRequest.patch(`organization/profile/images/${id}/`, formData);
};
