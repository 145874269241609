import { ReactComponent as Acreditta } from 'assets/Icon/icons/Acreditta.svg';
import { ReactComponent as HomeIcon } from 'assets/Icon/icons/HomeIcon.svg';
import { ReactComponent as MessageIcon } from 'assets/Icon/icons/MessageIcon.svg';
import { ReactComponent as RutasIcon } from 'assets/Icon/icons/RutasIcon.svg';
import { ReactComponent as SupportIcon } from 'assets/Icon/icons/SupportIcon.svg';
import { ReactComponent as BlueCircles } from 'assets/Icon/icons/BlueCircles.svg';
import { ReactComponent as RedCircles } from 'assets/Icon/icons/RedCircles.svg';
import { ReactComponent as UserIcon } from 'assets/Icon/icons/UserIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/Icon/icons/EyeIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/Icon/icons/PasswordIcon.svg';
import { ReactComponent as MenuIcon } from 'assets/Icon/icons/Menu.svg';
import { ReactComponent as PlayButton } from 'assets/Icon/icons/PlayButton.svg';
import { ReactComponent as PlusButton } from 'assets/Icon/icons/Icono_mas.svg';
import { ReactComponent as AcredittaDark } from 'assets/Icon/icons/AcredittaDark.svg';
import { ReactComponent as AcredittaWhite } from 'assets/Icon/icons/AcredittaWhite.svg';
import { ReactComponent as TwitterWhite } from 'assets/Icon/icons/TwitterWhite.svg';
import { ReactComponent as FacebookWhite } from 'assets/Icon/icons/FacebookWhite.svg';
import { ReactComponent as InstagramWhite } from 'assets/Icon/icons/InstagramWhite.svg';
import { ReactComponent as LinkedinWhite } from 'assets/Icon/icons/LinkedinWhite.svg';
import { ReactComponent as PasswordRecoveryIcon } from 'assets/Icon/icons/PasswordRecoveryIcon.svg';
import { ReactComponent as HeaderIcon } from 'assets/Icon/icons/HeaderIcon.svg';
import { ReactComponent as EyeBlockedIcon } from 'assets/Icon/icons/EyeBlockedIcon.svg';
import { ReactComponent as CheckIcon } from 'assets/Icon/icons/CheckIcon.svg';
import { ReactComponent as LineIcon } from 'assets/Icon/icons/LineIcon.svg';
import { ReactComponent as EndOnboardingIcon } from 'assets/Icon/icons/EndOnboardingIcon.svg';
import { ReactComponent as SearchIcon } from 'assets/Icon/icons/SearchIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/Icon/icons/ProfileIcon.svg';
import { ReactComponent as BadgeIcon } from 'assets/Icon/icons/BadgeIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/Icon/icons/SettingsIcon.svg';
import { ReactComponent as UploadFileIcon } from 'assets/Icon/icons/UploadFileIcon.svg';
import { ReactComponent as NotificationIcon } from 'assets/Icon/icons/NotificationIcon.svg';
import { ReactComponent as RouteImageExample } from 'assets/Icon/icons/RouteImageExample.svg';
import { ReactComponent as CalendarIcon } from 'assets/Icon/icons/CalendarIcon.svg';
import { ReactComponent as BadgeIconExample } from 'assets/Icon/icons/BadgeIconExample.svg';
import { ReactComponent as ArrowIcon } from 'assets/Icon/icons/ArrowIcon.svg';
import { ReactComponent as SkillsIcon } from 'assets/Icon/icons/SkillsIcon.svg';
import { ReactComponent as CoursesIcon } from 'assets/Icon/icons/CoursesIcon.svg';
import { ReactComponent as AcreditedProfileComplete } from 'assets/Icon/icons/ImagenInsigniaAcreditado.svg';
import { ReactComponent as EmptyHexagonIcon } from 'assets/Icon/icons/EmptyHexagonIcon.svg';
import { ReactComponent as FilledHexagonIcon } from 'assets/Icon/icons/FilledHexagonIcon.svg';
import { ReactComponent as FilledSecondaryHexagonIcon } from 'assets/Icon/icons/FilledSecondaryHexagonIcon.svg';
import { ReactComponent as HexagonGroupTop } from 'assets/Icon/icons/HexagonGroupTop.svg';
import { ReactComponent as HexagonGroupBottom } from 'assets/Icon/icons/HexagonGroupBottom.svg';
import { ReactComponent as TooltipInfoIcon } from 'assets/Icon/icons/TooltipInfoIcon.svg';
import { ReactComponent as TermsAndConditionsIcon } from 'assets/Icon/icons/TermsAndConditionsIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/Icon/icons/LogoutIcon.svg';
import { ReactComponent as AcredittaLogoMobileIcon } from 'assets/Icon/icons/AcredittaLogoMobileIcon.svg';
import { ReactComponent as OnboardingUno } from 'assets/Icon/icons/Group4415.svg';
import { ReactComponent as OnboardingDos } from 'assets/Icon/icons/Group4416.svg';
import { ReactComponent as OnboardingTres } from 'assets/Icon/icons/Group4485.svg';
import { ReactComponent as MobileMenuIcon } from 'assets/Icon/icons/MobileMenuIcon.svg';
import { ReactComponent as AddUserIcon } from 'assets/Icon/icons/AddUser.svg';
import { ReactComponent as AddPeopleIcon } from 'assets/Icon/icons/AddPeople.svg';
import { ReactComponent as DocumentIcon } from 'assets/Icon/icons/DocumentIcon.svg';
import { ReactComponent as DownloadTemplateIcon } from 'assets/Icon/icons/DownloadTemplateIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/Icon/icons/CloseIcon.svg';
import { ReactComponent as AddIcon } from 'assets/Icon/icons/AddIcon.svg';
import { ReactComponent as LinkedinRawIcon } from 'assets/Icon/icons/LinkedinRawIcon.svg';
import { ReactComponent as TwitterRawIcon } from 'assets/Icon/icons/TwitterRawIcon.svg';
import { ReactComponent as InstagramRawIcon } from 'assets/Icon/icons/InstagramRawIcon.svg';
import { ReactComponent as FacebookRawIcon } from 'assets/Icon/icons/FacebookRawIcon.svg';
import { ReactComponent as AddCoursesIcon } from 'assets/Icon/icons/AddCoursesIcon.svg';
import { ReactComponent as AnalyticsIcon } from 'assets/Icon/icons/AnalyticsIcon.svg';
import { ReactComponent as UsersIcon } from 'assets/Icon/icons/UsersIcon.svg';
import { ReactComponent as AsingPath } from 'assets/Icon/icons/Icono_Asignar.svg';
import { ReactComponent as EditPath } from 'assets/Icon/icons/Icono_Editar.svg';
import { ReactComponent as DeletePath } from 'assets/Icon/icons/Icono_Eliminar.svg';
import { ReactComponent as UserSettingsIcon } from 'assets/Icon/icons/UserSettingsIcon.svg';
import { ReactComponent as LabelIcon } from 'assets/Icon/icons/LabelIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/Icon/icons/DeleteIcon.svg';
import { ReactComponent as CreateRouteIcon } from 'assets/Icon/icons/CreateRouteIcon.svg';
import { ReactComponent as InterestsIcon } from 'assets/Icon/icons/InterestsIcon.svg';
import { ReactComponent as BuildingIcon } from 'assets/Icon/icons/BuildingIcon.svg';
import { ReactComponent as ProgressMenuRectangle } from 'assets/Icon/icons/ProgressMenuRectangle.svg';
import { ReactComponent as ProgressMenuResponsiveIcon } from 'assets/Icon/icons/ProgressMenuResponsiveIcon.svg';
import { ReactComponent as CatalogIcon } from 'assets/Icon/icons/CatalogIcon.svg';
import { ReactComponent as StarIcon } from 'assets/Icon/icons/StarIcon.svg';
import { ReactComponent as LikeIcon } from 'assets/Icon/icons/LikeIcon.svg';
import { ReactComponent as FilterIcon } from 'assets/Icon/icons/FilterIcon.svg';
import { ReactComponent as PreviousArrowIcon } from 'assets/Icon/icons/PreviousArrowIcon.svg';
import { ReactComponent as NextArrowIcon } from 'assets/Icon/icons/NextArrowIcon.svg';
import { ReactComponent as SendRedIcon } from 'assets/Icon/icons/SendRedIcon.svg';
import { ReactComponent as MeetIcon } from 'assets/Icon/icons/MeetIcon.svg';
import { ReactComponent as AssesmentIcon } from 'assets/Icon/icons/AssesmentIcon.svg';
import { ReactComponent as BookIcon } from 'assets/Icon/icons/BookIcon.svg';
import { ReactComponent as VideoIcon } from 'assets/Icon/icons/VideoIcon.svg';
import { ReactComponent as PodcastIcon } from 'assets/Icon/icons/PodcastIcon.svg';
import { ReactComponent as ArticleIcon } from 'assets/Icon/icons/ArticleIcon.svg';
import { ReactComponent as DividerIcon } from 'assets/Icon/icons/DividerIcon.svg';
import { ReactComponent as PlaySmallButton } from 'assets/Icon/icons/PlaySmallButton.svg';
import { ReactComponent as DeleteBlueIcon } from 'assets/Icon/icons/DeleteBlueIcon.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/Icon/icons/ArrowUpIcon.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/Icon/icons/ArrowDownIcon.svg';
import { ReactComponent as MoveIcon } from 'assets/Icon/icons/MoveIcon.svg';
import { ReactComponent as TimeIcon } from 'assets/Icon/icons/TimeIcon.svg';
import { ReactComponent as LanguageIcon } from 'assets/Icon/icons/LanguageIcon.svg';
import { ReactComponent as LevelIcon } from 'assets/Icon/icons/LevelIcon.svg';
import { ReactComponent as MarkBookIcon } from 'assets/Icon/icons/MarkBookIcon.svg';
import { ReactComponent as PadlockIcon } from 'assets/Icon/icons/PadlockIcon.svg';
import { ReactComponent as BlockIcon } from 'assets/Icon/icons/BlockIcon.svg';
import { ReactComponent as MoneyIcon } from 'assets/Icon/icons/MoneyIcon.svg';
import { ReactComponent as FullScreenIcon } from 'assets/Icon/icons/FullScreenIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/Icon/icons/PlusIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/Icon/icons/MinusIcon.svg';
import { ReactComponent as CoursesUploadIlustration } from 'assets/Icon/icons/CoursesUploadIlustration.svg';
import { ReactComponent as MasiveCoursesUploadIcon } from 'assets/Icon/icons/MasiveCoursesUploadIcon.svg';
import { ReactComponent as IndividualCoursesUploadIcon } from 'assets/Icon/icons/IndividualCoursesUploadIcon.svg';
import { ReactComponent as FileIcon } from 'assets/Icon/icons/FileIcon.svg';
import { ReactComponent as DownloadFileIcon } from 'assets/Icon/icons/DownloadFileIcon.svg';
import { ReactComponent as DragAndDropUnionIcon } from 'assets/Icon/icons/DragAndDropUnion.svg';
import { ReactComponent as PublicIcon } from 'assets/Icon/icons/PublicIcon.svg';
import { ReactComponent as GoBackIcon } from 'assets/Icon/icons/GoBackIcon.svg';
import { ReactComponent as BadgeIlustration } from 'assets/Icon/icons/BadgeIlustration.svg';
import { ReactComponent as RouteIlustration } from 'assets/Icon/icons/RouteIlustration.svg';
import { ReactComponent as InfoIcon } from 'assets/Icon/icons/InfoIcon.svg';
import { ReactComponent as URLIcon } from 'assets/Icon/icons/URLIcon.svg';
import { ReactComponent as DollarIcon } from 'assets/Icon/icons/DollarIcon.svg';
import { ReactComponent as EducationIcon } from 'assets/Icon/icons/EducationIcon.svg';
import { ReactComponent as LevelBlueIcon } from 'assets/Icon/icons/LevelBlueIcon.svg';
import { ReactComponent as DownloadBlueIcon } from 'assets/Icon/icons/DownloadBlueIcon.svg';
import { ReactComponent as RelojBlueIcon } from 'assets/Icon/icons/RelojBlueIcon.svg';
import { ReactComponent as CalendarBlueIcon } from 'assets/Icon/icons/CalendarBlueIcon.svg';
import { ReactComponent as LinkedinIconBlue } from 'assets/Icon/icons/LinkedinIconBlue.svg';
import { ReactComponent as FacebookIconBlue } from 'assets/Icon/icons/FacebookIconBlue.svg';
import { ReactComponent as TwitterIconBlue } from 'assets/Icon/icons/TwitterIconBlue.svg';
import { ReactComponent as BlockchainBlueIcon } from 'assets/Icon/icons/BlockchainBlueIcon.svg';
import { ReactComponent as CodeBlueIcon } from 'assets/Icon/icons/CodeBlueIcon.svg';
import { ReactComponent as ImageBlueIcon } from 'assets/Icon/icons/ImageBlueIcon.svg';
import { ReactComponent as LinkBlueIcon } from 'assets/Icon/icons/LinkBlueIcon.svg';
import { ReactComponent as EmailBlueIcon } from 'assets/Icon/icons/EmailBlueIcon.svg';
import { ReactComponent as WelcomePageIlustration } from 'assets/Icon/icons/WelcomePageIlustration.svg';
import { ReactComponent as IssueIcon } from 'assets/Icon/icons/Icono_Emitir_Insignia.svg';
import { ReactComponent as SeeMoreIcon } from 'assets/Icon/icons/Icono_Ver_Mas.svg';
import { ReactComponent as LinkToIcon } from 'assets/Icon/icons/Icono_Vincular.svg';
import { ReactComponent as CleanSearchIcon } from 'assets/Icon/icons/Icono_X01.svg';
import { ReactComponent as ExpiredCodePageIlustration } from 'assets/Icon/icons/ExpiredCodePageIlustration.svg';
import { ReactComponent as Imagen_Bienvenida } from 'assets/Icon/icons/Imagen_Bienvenida.svg';
import { ReactComponent as Vector_insignia } from 'assets/Icon/icons/Vector_insignia.svg';
import { ReactComponent as Icono_rutas } from 'assets/Icon/icons/Icono_rutas.svg';
import { ReactComponent as Icono_Cursos } from 'assets/Icon/icons/Icono_Cursos.svg';
import { ReactComponent as Icono_Duracion } from 'assets/Icon/icons/Icono_Duracion.svg';
import { ReactComponent as Icono_Busqueda } from 'assets/Icon/icons/Icono_Busqueda.svg';
import { ReactComponent as IssueIndividual } from 'assets/Icon/icons/IssueIndividual.svg';
import { ReactComponent as IssueMasive } from 'assets/Icon/icons/IssueMasive.svg';
import { ReactComponent as Icono_Alerta } from 'assets/Icon/icons/Icono_Alerta.svg';
import { ReactComponent as Icono_Acreditta } from 'assets/Icon/icons/Icono_Acreditta.svg';
import { ReactComponent as Icono_Documento } from 'assets/Icon/icons/Icono_Documento.svg';
import { ReactComponent as Image_Ruta_Finalizada } from 'assets/Icon/icons/Image_Ruta_Finalizada.svg';
import { ReactComponent as Imagen_ruta_ya_finalizada } from 'assets/Icon/icons/Imagen_ruta_ya_finalizada.svg';
import { ReactComponent as Imagen_Upgrade } from 'assets/Icon/icons/Imagen_Upgrade.svg';
import { ReactComponent as Vector } from 'assets/Icon/icons/Vector.svg';
import { ReactComponent as Icono_Usuarios } from 'assets/Icon/icons/Icono_Usuarios.svg';
import { ReactComponent as Icono_Reenviar } from 'assets/Icon/icons/Icono_Reenviar.svg';
import { ReactComponent as Icono_Badge } from 'assets/Icon/icons/badgesLogo.svg';
import { ReactComponent as DurationLogo } from 'assets/Icon/icons/DurationLogo.svg';
import { ReactComponent as Subscription } from 'assets/Icon/icons/Subscription.svg';
import { ReactComponent as BuildingNonCircle } from 'assets/Icon/icons/BuildingNonCircle.svg';
import { ReactComponent as IconoCertificado } from 'assets/Icon/icons/Icono_Certificado.svg';
import { ReactComponent as IconoTwitter2 } from 'assets/Icon/icons/Icono_Twitter2.svg';
import { ReactComponent as IconoLinkedin2 } from 'assets/Icon/icons/Icono_Linkedin2.svg';
import { ReactComponent as IconoFacebook2 } from 'assets/Icon/icons/Icono_Facebook2.svg';
import { ReactComponent as IconoInstagram2 } from 'assets/Icon/icons/Icono_Instagram2.svg';
import { ReactComponent as WarningIcon } from 'assets/Icon/icons/WarningIcon.svg';
import { ReactComponent as IconoInstagramBlue } from 'assets/Icon/icons/Icono_Instagram_00.svg';
import { ReactComponent as Icono_badge_acepted } from 'assets/Icon/icons/Icono_badge_acepted.svg';
import { ReactComponent as Icon_eye_open } from 'assets/Icon/icons/Icon_eye_open.svg';
import { ReactComponent as Icon_eye_close } from 'assets/Icon/icons/Icon_eye_close.svg';
import { ReactComponent as Star } from 'assets/Icon/icons/Star.svg';
import { ReactComponent as ArrowDown } from 'assets/Icon/icons/arrow-down.svg';
import { ReactComponent as IconLinkedinBlue } from 'assets/Icon/icons/Icono_Linkedin_B.svg';
import { ReactComponent as IconLinkedinWhite } from 'assets/Icon/icons/Icono_Linkedin_W.svg';
import { ReactComponent as Icon_eye_open_blue } from 'assets/Icon/icons/Icon_eye_open_blue.svg';
import { ReactComponent as PhoneIcon } from 'assets/Icon/icons/PhoneIcon.svg';
import { ReactComponent as RoleIcon } from 'assets/Icon/icons/RoleIcon.svg';
import { ReactComponent as OrganizationIcon } from 'assets/Icon/icons/OrganizationIcon.svg';
import { ReactComponent as ShareIcon } from 'assets/Icon/icons/ShareIcon.svg';
import { ReactComponent as BrushIcon } from 'assets/Icon/icons/BrushIcon.svg';
import { ReactComponent as DeleteIconTag } from 'assets/Icon/icons/DeleteIconTag.svg';
import { ReactComponent as duplicateIcon } from 'assets/Icon/icons/duplicateIcon.svg';
import { ReactComponent as InsigniasEmitidasIcon } from 'assets/Icon/icons/InsigniasEmitidasIcon.svg';
import { ReactComponent as InsigniasAceptadasIcon } from 'assets/Icon/icons/InsigniasAceptadasIcon.svg';
import { ReactComponent as InsigniasCompartidasIcon } from 'assets/Icon/icons/InsigniasCompartidasIcon.svg';
import { ReactComponent as InsigniasPendientesIcon } from 'assets/Icon/icons/InsigniasPendientesIcon.svg';
import { ReactComponent as AcredittaLogoBlanco } from 'assets/Icon/icons/AcredittaLogoBlanco.svg';

const icons = {
  Acreditta,
  Vector,
  Star,
  Imagen_Bienvenida,
  Imagen_ruta_ya_finalizada,
  HomeIcon,
  Icon_eye_open_blue,
  Icon_eye_close,
  Icon_eye_open,
  Icono_Badge,
  DurationLogo,
  Icono_Usuarios,
  Icono_Reenviar,
  Icono_Duracion,
  Icono_Documento,
  Icono_Alerta,
  Image_Ruta_Finalizada,
  Icono_Cursos,
  Icono_Acreditta,
  Icono_Busqueda,
  Icono_badge_acepted,
  Imagen_Upgrade,
  IssueIcon,
  Vector_insignia,
  SeeMoreIcon,
  MessageIcon,
  LinkToIcon,
  Icono_rutas,
  RutasIcon,
  OnboardingUno,
  OnboardingDos,
  OnboardingTres,
  SupportIcon,
  BlueCircles,
  RedCircles,
  UserIcon,
  EyeIcon,
  PasswordIcon,
  MenuIcon,
  PlayButton,
  AcredittaDark,
  AcredittaWhite,
  TwitterWhite,
  FacebookWhite,
  InstagramWhite,
  LinkedinWhite,
  PlusButton,
  AcreditedProfileComplete,
  PasswordRecoveryIcon,
  HeaderIcon,
  EyeBlockedIcon,
  CheckIcon,
  LineIcon,
  EndOnboardingIcon,
  SearchIcon,
  ProfileIcon,
  BadgeIcon,
  SettingsIcon,
  UploadFileIcon,
  NotificationIcon,
  RouteImageExample,
  CalendarIcon,
  BadgeIconExample,
  ArrowIcon,
  SkillsIcon,
  CoursesIcon,
  EmptyHexagonIcon,
  FilledHexagonIcon,
  FilledSecondaryHexagonIcon,
  HexagonGroupTop,
  HexagonGroupBottom,
  TooltipInfoIcon,
  TermsAndConditionsIcon,
  LogoutIcon,
  AcredittaLogoMobileIcon,
  MobileMenuIcon,
  AddPeopleIcon,
  AddUserIcon,
  DocumentIcon,
  DownloadTemplateIcon,
  CloseIcon,
  AddIcon,
  LinkedinRawIcon,
  TwitterRawIcon,
  FacebookRawIcon,
  InstagramRawIcon,
  AddCoursesIcon,
  AnalyticsIcon,
  UsersIcon,
  AsingPath,
  EditPath,
  DeletePath,
  UserSettingsIcon,
  LabelIcon,
  DeleteIcon,
  CreateRouteIcon,
  InterestsIcon,
  BuildingIcon,
  ProgressMenuRectangle,
  ProgressMenuResponsiveIcon,
  CatalogIcon,
  StarIcon,
  LikeIcon,
  FilterIcon,
  PreviousArrowIcon,
  NextArrowIcon,
  SendRedIcon,
  MeetIcon,
  AssesmentIcon,
  BookIcon,
  VideoIcon,
  PodcastIcon,
  ArticleIcon,
  DividerIcon,
  PlaySmallButton,
  DeleteBlueIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  MoveIcon,
  TimeIcon,
  LanguageIcon,
  LevelIcon,
  MarkBookIcon,
  PadlockIcon,
  BlockIcon,
  MoneyIcon,
  FullScreenIcon,
  PlusIcon,
  MinusIcon,
  CoursesUploadIlustration,
  MasiveCoursesUploadIcon,
  IndividualCoursesUploadIcon,
  FileIcon,
  DownloadFileIcon,
  DragAndDropUnionIcon,
  PublicIcon,
  GoBackIcon,
  BadgeIlustration,
  RouteIlustration,
  InfoIcon,
  URLIcon,
  DollarIcon,
  EducationIcon,
  LevelBlueIcon,
  DownloadBlueIcon,
  RelojBlueIcon,
  CalendarBlueIcon,
  LinkedinIconBlue,
  FacebookIconBlue,
  TwitterIconBlue,
  BlockchainBlueIcon,
  CodeBlueIcon,
  ImageBlueIcon,
  LinkBlueIcon,
  EmailBlueIcon,
  WelcomePageIlustration,
  CleanSearchIcon,
  ExpiredCodePageIlustration,
  IssueIndividual,
  IssueMasive,
  Subscription,
  BuildingNonCircle,
  IconoCertificado,
  IconoTwitter2,
  IconoLinkedin2,
  IconoFacebook2,
  IconoInstagram2,
  WarningIcon,
  IconoInstagramBlue,
  ArrowDown,
  IconLinkedinBlue,
  IconLinkedinWhite,
  PhoneIcon,
  RoleIcon,
  OrganizationIcon,
  ShareIcon,
  BrushIcon,
  DeleteIconTag,
  duplicateIcon,
  InsigniasEmitidasIcon,
  InsigniasAceptadasIcon,
  InsigniasCompartidasIcon,
  InsigniasPendientesIcon,
  AcredittaLogoBlanco,
};

export default icons;
