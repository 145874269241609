import { createContext, useState } from 'react';
import { useAppSelector } from '../../../../hooks/redux';

export const BadgeImageContext = createContext({} as any);

const BadgeImageProvider = ({ children }: any) => {
  const [show, setShow] = useState(false);
  const user = useAppSelector((state) => state.user);
  const isAdmin = user.userInfo && user.userInfo.user_type.code === 'ADMIN';

  return (
    <BadgeImageContext.Provider value={{ show, isAdmin, setShow }}>
      {children}
    </BadgeImageContext.Provider>
  );
};

export default BadgeImageProvider;
