import * as React from 'react';
import AdminLearningPathDescriptionMenu from 'components/molecules/AdminLearningPathDescriptionMenu';
import {
  LearningPathBasicInfoContainer,
  LearningPathPreviewDescWrapper,
  LearningPathOwner,
  LearningPathOwnerInfo,
  LearningPathDescriptionWrapper,
  LearningPathDescription,
  LearningPathDescriptionTitle,
  LearningPathModality,
  LearningPathDescriptionContainer,
  LearningPathModalityWrapper,
  LearningPathOwnerInfoWrapper,
} from './styles';
import icons from 'assets/Icon';

interface AdminLearningPathPreviewDescriptionProps {
  creator: string;
  start: Date;
  duration: number;
  users: number;
  level: string;
  content: number;
  language: string;
  methodology: string;
  description: string;
  isPrivate: boolean;
  image: string;
}

const AdminLearningPathPreviewDescription = ({
  creator,
  start,
  duration,
  users,
  level,
  content,
  language,
  methodology,
  description,
  isPrivate,
  image,
}: AdminLearningPathPreviewDescriptionProps): JSX.Element => {
  const isPrivateRoute = React.useMemo(() => {
    return isPrivate ? 'Ruta Privada' : 'Ruta Pública';
  }, [isPrivate]);

  return (
    <LearningPathPreviewDescWrapper>
      <LearningPathBasicInfoContainer>
        <LearningPathOwnerInfoWrapper>
          <div>
            <LearningPathOwnerInfo>Creada por:</LearningPathOwnerInfo>
            <LearningPathOwner>{creator}</LearningPathOwner>
          </div>
        </LearningPathOwnerInfoWrapper>
      </LearningPathBasicInfoContainer>
      <LearningPathDescriptionContainer>
        <AdminLearningPathDescriptionMenu
          start={start}
          duration={duration}
          users={users}
          level={level}
          content={content}
          language={language}
          methodology={methodology}
        />
        <LearningPathDescriptionWrapper>
          <img src={image} alt="imagen-de-la-ruta" />
          <div>
            <LearningPathDescriptionTitle>Descripción de la ruta</LearningPathDescriptionTitle>
            <LearningPathDescription>{description}</LearningPathDescription>
            <LearningPathModalityWrapper>
              {isPrivate ? <icons.PadlockIcon /> : <icons.PublicIcon />}
              <LearningPathModality>{isPrivateRoute}</LearningPathModality>
            </LearningPathModalityWrapper>
          </div>
        </LearningPathDescriptionWrapper>
      </LearningPathDescriptionContainer>
    </LearningPathPreviewDescWrapper>
  );
};

export default AdminLearningPathPreviewDescription;
