import icons from 'assets/Icon';
import candado from 'assets/Icon/icons/candado.svg'
import AcredittaText from 'components/atoms/AcredittaText';
import TextInput from 'components/atoms/TextInput';
import * as React from 'react';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import { emailRegex } from 'utils';
import {
  PasswordRecoveryForm,
  PasswordRecoveryFormGroup,
  PasswordRecoverySubTitle,
  PasswordContainer,
  PasswordRecoveryIconContainer,
} from './styles';
import './styles.css';
interface RequestEmailRecoveryProps {
  handleSubmit: UseFormHandleSubmit<IRecoveryPassword>;
  submitEmail: (user: IRecoveryPassword) => Promise<void>;
  control: Control<IRecoveryPassword>;
  errors: Record<string, any>;
  loading?: boolean;
}

const RequestEmailRecovery: React.FC<RequestEmailRecoveryProps> = ({
  handleSubmit,
  submitEmail,
  control,
  errors,
  loading,
}) => {
  return (
    <PasswordContainer>
      <PasswordRecoveryForm onSubmit={handleSubmit(submitEmail)}>
        <PasswordRecoveryFormGroup row>
          <AcredittaText align='center' variant="h1">Restablecer Contraseña</AcredittaText>
          <PasswordRecoverySubTitle for="exampleEmail" sm={4}>
            Ingresa tu dirección de correo electrónico para que se te envíe un mensaje de
            restablecimiento de contraseña
          </PasswordRecoverySubTitle>
          <div className="input-container">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <div>
                  <TextInput
                    label={'Correo'}
                    icon={<icons.UserIcon />}
                    type="email"
                    name="email"
                    placeholder="Ingresar email"
                    value={value}
                    onChange={(value) => onChange(value)}
                    error={errors}
                  />
                </div>
              )}
              name="email"
              rules={{
                required: true,
                pattern: { value: emailRegex, message: 'Email invalido' },
              }}
            />
          </div>
        </PasswordRecoveryFormGroup>
        <div className="button-container">
          <button>Enviar</button>
        </div>
      </PasswordRecoveryForm>
      <PasswordRecoveryIconContainer>
        <img src={candado} alt="" />
      </PasswordRecoveryIconContainer>
    </PasswordContainer>
  );
};

export default RequestEmailRecovery;
