export const policies = `Algunos de nuestros servicios te permiten enviar contenido. Si lo haces, seguirás siendo
el titular de los derechos de propiedad intelectual que tengas sobre ese contenido. En
pocas palabras, lo que te pertenece, tuyo es. Al subir contenido o al enviarlo por otros
medios a nuestros Servicios, concedes a Google (y a sus colaboradores) una licencia
mundial para usar, alojar, almacenar, reproducir, modificar, crear obras derivadas (por
ejemplo, las que resulten de la traducción, la adaptación u otros cambios que realicemos
para que tu contenido se adapte mejor a nuestros Servicios), comunicar, publicar, ejecutar
o mostrar públicamente y distribuir dicho contenido. Google usará los derechos que le
confiere esta licencia únicamente con el fin de proporcionar, promocionar y mejorar los
Servicios y de desarrollar servicios nuevos. Esta licencia seguirá vigente incluso cuando
dejes de usar nuestros Servicios (por ejemplo, en el caso de una ficha de empresa que
hayas añadido a Google Maps). Algunos Servicios te permiten acceder al contenido que hayas
proporcionado y eliminarlo. Algunos de nuestros servicios te permiten enviar contenido. Si
lo haces, seguirás siendo el titular de los derechos de propiedad intelectual que tengas
sobre ese contenido. Algunos de nuestros servicios te permiten enviar contenido. Si lo
haces, seguirás siendo el titular de los derechos de propiedad intelectual que tengas
sobre ese contenido. En pocas palabras, lo que te pertenece, tuyo es. Al subir contenido o
al enviarlo por otros medios a nuestros Servicios, concedes a Google (y a sus
colaboradores) una licencia mundial para usar, alojar, almacenar, reproducir, modificar,
crear obras derivadas (por ejemplo, las que resulten de la traducción, la adaptación u
otros cambios que realicemos para que tu contenido se adapte mejor a nuestros Servicios),
comunicar, publicar, ejecutar o mostrar públicamente y distribuir dicho contenido. Google
usará los derechos que le confiere esta licencia únicamente con el fin de proporcionar,
promocionar y mejorar los Servicios y de desarrollar servicios nuevos. Esta licencia
seguirá vigente incluso cuando dejes de usar nuestros Servicios (por ejemplo, en el caso
de una ficha de empresa que hayas añadido a Google Maps). Algunos Servicios te permiten
acceder al contenido que hayas proporcionado y eliminarlo. Algunos de nuestros servicios
te permiten enviar contenido. Si lo haces, seguirás siendo el titular de los derechos de
propiedad intelectual que tengas sobre ese contenido. Algunos de nuestros servicios te
permiten enviar contenido. Si lo haces, seguirás siendo el titular de los derechos de
propiedad intelectual que tengas sobre ese contenido. En pocas palabras, lo que te
pertenece, tuyo es. Al subir contenido o al enviarlo por otros medios a nuestros
Servicios, concedes a Google (y a sus colaboradores) una licencia mundial para usar,
alojar, almacenar, reproducir, modificar, crear obras derivadas (por ejemplo, las que
resulten de la traducción, la adaptación u otros cambios que realicemos para que tu
contenido se adapte mejor a nuestros Servicios), comunicar, publicar, ejecutar o mostrar
públicamente y distribuir dicho contenido. Google usará los derechos que le confiere esta
licencia únicamente con el fin de proporcionar, promocionar y mejorar los Servicios y de
desarrollar servicios nuevos. Esta licencia seguirá vigente incluso cuando dejes de usar
nuestros Servicios (por ejemplo, en el caso de una ficha de empresa que hayas añadido a
Google Maps). Algunos Servicios te permiten acceder al contenido que hayas proporcionado y
eliminarlo. Algunos de nuestros servicios te permiten enviar contenido. Si lo haces,
seguirás siendo el titular de los derechos de propiedad intelectual que tengas sobre ese
contenido.`;

export const terms = `Terminos y condiciones texto, Expetenda tincidunt in sed, ex partem placerat sea, porro commodo ex eam. His putant aeterno interesset at. Usu ea mundi tincidunt, omnium virtute aliquando ius ex. Ea aperiri sententiae duo. Usu nullam dolorum quaestio ei, sit vidit facilisis ea. Per ne impedit iracundia neglegentur. Consetetur neglegentur eum ut, vis animal legimus inimicus id.
Meis vocent signiferumque pri et. Facilis corpora recusabo ne quo, eum ne eruditi blandit suscipiantur. Mazim sapientem sed id, sea debet commune iracundia in. Eius falli propriae te usu. In usu nonummy volumus expetenda, sint quando facilisis ei per, delectus constituto sea te.
Eam ex integre quaeque bonorum, ea assum solet scriptorem pri, et usu nonummy accusata interpretaris. Debitis necessitatibus est no. Eu probo graeco eum, at eius choro sit, possit recusabo corrumpit vim ne. Noster diceret delicata vel id.
At cum soleat disputationi, quo veri admodum vituperata ad. Ea vix ceteros complectitur, vel cu nihil nullam. Nam placerat oporteat molestiae ei, an putant albucius qui. Oblique menandri ei his, mei te mazim oportere comprehensam.
Has maiorum habemus detraxit at. Timeam fabulas splendide et his. Facilisi aliquando sea ad, vel ne consetetur adversarium. Integre admodum et his, nominavi urbanitas et per, alii reprehendunt et qui. His ei meis legere nostro, eu kasd fabellas definiebas mei, in sea augue iriure.
Senserit mediocrem vis ex, et dicunt deleniti gubergren mei. Mel id clita mollis repudiare. Sed ad nostro delicatissimi, postea pertinax est an. Adhuc sensibus percipitur sed te, eirmod tritani debitis nec ea. Cu vis quis gubergren.`;
