import React from 'react';
import Button from 'components/atoms/Button';
import {
  NewCourseFormContainer,
  NewCourseInstructionsTitle,
  FormGroupStyled,
  FormLabelSimpleWrapper,
  FormInputWrapper,
  FormLabelSimple,
  ButtonsContainer,
  InfoText,
  SwitchContainer,
  SwitchLabel,
  InputSelectContainer,
  ButtonWrapper,
  FormGroupDatesStyled,
  FormLabelSimpleDates,
  SessionsContainer,
  FormLabelSession,
  SessionContainer,
  FormLabelSessionWrapper,
  SesionAddButton,
  SesionAddButtonTitle,
  SesionAddButtonWrapper,
  DeleteSessionButton,
  DeleteSessionButtonContainer,
  SessionsDescContainer,
  InputWrapper,
} from './styles';
import Switch from 'react-switch';
import TextInput from 'components/atoms/TextInput';
import InputSelect from 'components/atoms/InputSelect';
import { Control, UseFormHandleSubmit, Controller, UseFormWatch } from 'react-hook-form';
import validator from 'validator';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { useAppDispatch } from 'hooks/redux';
import { GetLanguagesListThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import DatePicker from 'react-date-picker';
import icons from 'assets/Icon';
import { FormText } from 'reactstrap';
import TextInputMax from 'components/atoms/TextInputMax';
import { SkillListEleThunk, SkillsTagListThunk } from 'actions/Thunks/OnboardingThunk';
import AcredittaSkillsSelector from 'components/atoms/AcredittaSkillsSelector';

interface AdminUploadNewCourseFormProps {
  controlInfo: Control<IUploadNewCourse>;
  handleSubmitForm: UseFormHandleSubmit<IUploadNewCourse>;
  submitNewCourseForm: (formInfo: IUploadNewCourse) => void;
  errorsInfo: Record<string, any>;
  hideSection: () => void;
  watch: UseFormWatch<IUploadNewCourse>;
  onChangeContent: (value: ICourseContent[]) => void;
  errorModule?: string;
}

const AdminUploadNewCourseForm = ({
  controlInfo,
  handleSubmitForm,
  submitNewCourseForm,
  errorsInfo,
  hideSection,
  watch,
  onChangeContent,
  errorModule=""
}: AdminUploadNewCourseFormProps): JSX.Element => {
  const [skillsSelected, setSkillsSelected] = React.useState([])
  const badges_visible = false;
  const dispatch = useAppDispatch();
  const date_i = watch().start_date;

  const [skillsTag, setSkillsTag] = React.useState<any>();
  const [inputList, setInputList] = React.useState<ICourseContent[]>([{ name: '', duration: 0 }]);

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list: ICourseContent[] = [...inputList];
    if (name === 'name') {
      list[index].name = value;
    } else {
      list[index].duration = value;
    }
    setInputList(list);
    onChangeContent(list);
  };

  const handleRemoveClick = (index: number) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    onChangeContent(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { name: '', duration: 0 }]);
    onChangeContent([...inputList, { name: '', duration: 0 }]);
  };

  const loadLanguages = () => {
    return new Promise((resolve) => {
      dispatch(GetLanguagesListThunk()).then((languages) => {
        if (GetLanguagesListThunk.fulfilled.match(languages)) {
          resolve(languages.payload);
        } else {
          toast.error(languages.payload as string);
        }
      });
    });
  };

  const initListSkillTag = React.useCallback(
    async (newValue: string) => {
      const skillsListTag = await dispatch(SkillsTagListThunk(newValue));
      if (SkillListEleThunk.rejected.match(skillsListTag)) {
        toast.error(skillsListTag.payload as string);
      } else {
        setSkillsTag(skillsListTag.payload);
      }
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (!skillsTag) {
      initListSkillTag('');
    }
  }, [initListSkillTag, skillsTag]);

  const getSkillsInputValues = (values:any) => {
    setSkillsSelected(values);
  }

  return (
    <NewCourseFormContainer>
      <NewCourseInstructionsTitle>
        Completa la información para cargar un curso
      </NewCourseInstructionsTitle>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Nombre</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre del curso"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Enlace *</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="url"
                placeholder="URL"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="url"
            rules={{
              required: 'Campo requerido',
              validate: {
                checkUrl: (v) => validator.isURL(v) || 'Debes ingresar una página web valida',
              },
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="description">Descripción *</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInput
                name="description"
                placeholder="Describe tu curso"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                type={'textarea'}
                height={100}
                characterCounter={500}
                withError
              />
            )}
            name="description"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Visibilidad</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InfoText>
            Los cursos marcados como públicos se mostrarán en el catálogo de cursos de la
            plataforma.
          </InfoText>
          <SwitchContainer>
            <SwitchLabel> Pública</SwitchLabel>
            <Controller
              control={controlInfo}
              render={({ field: { onChange, value } }) => (
                <Switch
                  onChange={onChange}
                  checked={value ? value : false}
                  onColor={PRIMARY_COLOR}
                  uncheckedIcon={false}
                  height={20}
                  width={38}
                />
              )}
              name="is_private"
            />
            <SwitchLabel> Privada</SwitchLabel>
          </SwitchContainer>
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Habilidades</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <AcredittaSkillsSelector 
          onChangeInputValues={getSkillsInputValues}/>
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Idioma</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InputSelectContainer>
            <Controller
              control={controlInfo}
              name="language"
              render={({ field: { onChange, value } }) => (
                <div style={{ width: '100%' }}>
                  <InputSelect
                    onChange={onChange}
                    value={value}
                    loadOptions={loadLanguages}
                    asyncSelect={true}
                    placeholder="Buscar..."
                    error={errorsInfo}
                    name="language"
                    defaultOptions
                  />
                </div>
              )}
            />
          </InputSelectContainer>
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupDatesStyled>
        <FormLabelSimpleDates>Fecha de Inicio</FormLabelSimpleDates>
        <Controller
          control={controlInfo}
          name="start_date"
          render={({ field: { onChange, value } }) => (
            <DatePicker onChange={onChange} value={value} locale="es-419" minDate={new Date()} />
          )}
        />
        <FormLabelSimpleDates>Fecha Final</FormLabelSimpleDates>
        <Controller
          control={controlInfo}
          name="end_date"
          render={({ field: { onChange, value } }) => (
            <DatePicker
              onChange={onChange}
              value={value}
              locale="es-419"
              minDate={date_i ? date_i : new Date()}
            />
          )}
        />
      </FormGroupDatesStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="labels">Contenido</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <InfoText>Define los módulos que tiene el curso</InfoText>
        </FormInputWrapper>
      </FormGroupStyled>
      <SessionsDescContainer>
        <SesionAddButtonWrapper>
          <SesionAddButtonTitle>Módulos</SesionAddButtonTitle>
          {inputList && <SesionAddButton onClick={handleAddClick}>Añadir +</SesionAddButton>}
        </SesionAddButtonWrapper>
      </SessionsDescContainer>

      {errorModule && (
        <p className="text-danger text-center">{errorModule}</p>
      )}
      
      {inputList.map((x, i) => {
        return (
          <SessionsContainer key={i}>
            <SessionContainer>
              <FormLabelSessionWrapper>
                <FormLabelSession for="labels">{`Módulo #${i + 1}`}</FormLabelSession>
              </FormLabelSessionWrapper>
              <InputWrapper>
                <TextInput
                  name="name"
                  placeholder={`Módulo #${i + 1}`}
                  value={x.name}
                  onChange={(e) => handleInputChange(e, i)}
                  error={errorsInfo}
                />
              </InputWrapper>
              <div>
                <TextInput
                  name="duration"
                  placeholder="Duración"
                  value={x.duration}
                  onChange={(e) => handleInputChange(e, i)}
                  error={errorsInfo}
                  type="number"
                />
                <FormText>Minutos</FormText>
              </div>
            </SessionContainer>
            <div>
              {inputList.length !== 1 && (
                <DeleteSessionButtonContainer>
                  <DeleteSessionButton onClick={() => handleRemoveClick(i)}>
                    Eliminar
                  </DeleteSessionButton>
                  <icons.DeleteBlueIcon onClick={() => handleRemoveClick(i)} />
                </DeleteSessionButtonContainer>
              )}
            </div>
          </SessionsContainer>
        );
      })}
      {badges_visible && (
        <FormGroupStyled row>
          <FormLabelSimpleWrapper>
            <FormLabelSimple for="labels">Asignar Insignia</FormLabelSimple>
          </FormLabelSimpleWrapper>
          <FormInputWrapper>
            <InputSelectContainer>
              <InputSelect
                placeholder=""
                asyncSelect={true}
                error={errorsInfo}
                name="labels"
                defaultOptions
              />
            </InputSelectContainer>
          </FormInputWrapper>
        </FormGroupStyled>
      )}
      <ButtonsContainer>
        <ButtonWrapper>
          <Button
            label={'Cancelar'}
            lightMode={true}
            widthCustom={150}
            onClick={() => hideSection()}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            label={'Cargar'}
            widthCustom={150}
            onClick={handleSubmitForm((courseForm) => {
              const skills = skillsSelected.map((item:any) => (item.value));
              const data = {...courseForm, skill: skills};
              submitNewCourseForm(data)
            })}
          />
        </ButtonWrapper>
      </ButtonsContainer>
    </NewCourseFormContainer>
  );
};

export default AdminUploadNewCourseForm;
