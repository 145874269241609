import styled from 'styled-components';
import AcredittaText from 'components/atoms/AcredittaText';
import icons from '../../../../assets/Icon/index';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';

const StyledDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 15px 10px;
  margin-right: 12px;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const CardBadgeAddMore = () => {
  const { push } = useHistory();

  return (
    <StyledDiv onClick={() => push(AdminRole.badgesPreview)}>
      <icons.PlusIcon style={{ width: 70, height: 70 }} />
      <AcredittaText variant="h5" align="center" lineHeight="1.1rem">
        Ver más insignias
      </AcredittaText>
    </StyledDiv>
  );
};

export default CardBadgeAddMore;
