import * as React from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  SubtitleFirst,
} from './styles';
import { Card, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ListBadgesThunk } from 'actions/Thunks/AdminPathsThunk';
import toast from 'react-hot-toast';

const AdminPreviewBadges: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const issues = useAppSelector((state) => state.listBadges);

  const getListBadges = React.useCallback(async () => {
    const badgesList = await dispatch(ListBadgesThunk());
    if (ListBadgesThunk.rejected.match(badgesList)) {
      toast.error(badgesList.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    getListBadges();
  }, [getListBadges]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={false}
      showMenuHeaderAcredited={true}
    >
      <CourseCatalogContainer>
        <CourseCatalogHeaderTitles>
          <CourseCatalogSubTitles>
            <SubtitleFirst>Insignias de la organización</SubtitleFirst>
          </CourseCatalogSubTitles>
        </CourseCatalogHeaderTitles>
        <Card>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Insignias</th>
                <th>Título</th>
                {/* <th>Estatus</th>
                <th>Actualización</th>
                <th>Emisiones</th> */}
                {/* <th>Acciones</th> */}
              </tr>
            </thead>
            <tbody>
              {issues.listBadges !== null ? (
                issues.listBadges.data.map((path: any) => {
                  return (
                    <tr>
                      {/* <th>
                        <div>
                          <img src={path.image.url} style={{ width: '40%' }} alt="img" />
                        </div>
                      </th>
                      <th>{path.name}</th>
                      <td>
                        {path.state === 'active' ? (
                          <Badge color="success">Publicada</Badge>
                        ) : (
                          <Badge color="warning">Borrador</Badge>
                        )}
                      </td> */}
                      <td>asd</td>
                      <td>asd</td>
                      {/* <td>
                        <ButtonToolbar>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              fontWeight: 'bold',
                            }}
                          >
                            <Button>
                              <icons.AsingPath />
                            </Button>
                            <FormText
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Emitir
                            </FormText>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <Button>
                              <icons.EditPath />
                            </Button>
                            <FormText
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Editar
                            </FormText>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                            }}
                          >
                            <Button>
                              <icons.DeletePath color="white" />
                            </Button>
                            <FormText
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Eliminar
                            </FormText>
                          </div>
                        </ButtonToolbar>
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <div>Loading...</div>
              )}
            </tbody>
          </Table>
        </Card>
      </CourseCatalogContainer>
    </CommonPageLayout>
  );
};

export default AdminPreviewBadges;
