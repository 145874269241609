import AdminLearningPathPreviewAcre from 'components/organisms/AdminLearningPathPreviewAcre';
import * as React from 'react';
import { useParams } from 'react-router-dom';

const LearningPathPreviewAcre: React.FC = () => {
  let { pathwayId } = useParams<{ pathwayId: string }>();

  return <AdminLearningPathPreviewAcre pathwayId={pathwayId} />;
};

export default LearningPathPreviewAcre;
