import * as React from 'react';
import icons from 'assets/Icon';
import { S3_ADMIN } from 'constants/index';
import SimpleCheckBox from 'components/atoms/SimpleCheckBox';
import { UseFormWatch, Controller, Control } from 'react-hook-form';
import {
  DetailsContainer,
  LearningPathImageWrapper,
  PathCover,
  InputFileLabel,
  PathName,
  PathIconContainer,
  WarningContainer,
  WarningImageText,
  AdditionalInformationContainer,
  AdditionalInformationTitle,
  AdditionalInfoWrapper,
  AdditionalInfoLabel,
  RowStyled,
  RowLabel,
  ColStyled,
  ButtonWrapper,
  ErrorMessageLabel,
} from './styles';
import toast from 'react-hot-toast';
import { ImageValidation } from 'utils';
import { Input } from 'reactstrap';
import { GetMethodologyListThunk, LearningPathLevelsThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

interface LearningPathDetailsProps {
  watch: UseFormWatch<IUpdatePath>;
  controlInfo: Control<IUpdatePath>;
  errorsInfo: Record<string, any>;
  onSelectTypePath: (type: number) => void;
  onSelectLevel: (is_private: number) => void;
}

const LearningPathDetails = ({
  controlInfo,
  errorsInfo,
  watch,
  onSelectLevel,
  onSelectTypePath,
}: LearningPathDetailsProps): JSX.Element => {
  const admin = useAppSelector((state) => state.admin);

  const [type_path, setTypePath] = React.useState<number | null>(null);
  const [levelType, setLevel] = React.useState<number | null>(null);
  const [routeType, setRouteType] = React.useState<ISelectOptionValue[] | null>(null);

  const dispatch = useAppDispatch();

  const file = watch().photo;

  const selectedPicture = React.useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    } else {
      return `${S3_ADMIN}NoProfilePictureIcon.svg`;
    }
  }, [file]);

  const route_name = watch().name;

  const route = React.useMemo(() => {
    if (route_name) {
      return route_name;
    } else {
      return 'Nombre de la Ruta';
    }
  }, [route_name]);

  const getModalities = React.useCallback(async () => {
    const methodologies = await dispatch(GetMethodologyListThunk());
    if (GetMethodologyListThunk.rejected.match(methodologies)) {
      toast.error(methodologies.payload as string);
    } else {
      setRouteType(methodologies.payload);
    }
  }, [dispatch]);

  const getLevels = React.useCallback(async () => {
    const levels = await dispatch(LearningPathLevelsThunk());
    if (LearningPathLevelsThunk.rejected.match(levels)) {
      toast.error(levels.payload as string);
    }
  }, [dispatch]);

  const setTypeLevel = (level: number) => {
    setLevel(level);
    onSelectLevel(level);
  };

  const setPath = (path_type: number) => {
    setTypePath(path_type);
    onSelectTypePath(path_type);
  };

  React.useEffect(() => {
    getModalities();
    getLevels();
  }, [getModalities, getLevels]);

  return (
    <DetailsContainer>
      <PathName>{route}</PathName>
      <PathIconContainer>
        <LearningPathImageWrapper>
          <PathCover src={selectedPicture} alt="imagen-portada-ruta" />
        </LearningPathImageWrapper>
        <Controller
          control={controlInfo}
          render={({ field: { onChange } }) => (
            <ButtonWrapper>
              <InputFileLabel>
                <Input
                  name="photo"
                  onChange={async (value) => {
                    const files = value.target.files;
                    const fileList: File[] = Array.prototype.slice.call(files);

                    if (await ImageValidation(fileList)) {
                      return onChange(fileList[0]);
                    } else {
                      toast.error(
                        'La imagen debe cumplir con los criterios de formato y dimensión'
                      );
                    }
                  }}
                  type="file"
                  accept="image/*"
                />
                <span>Cargar Portada</span>
              </InputFileLabel>
              {errorsInfo['photo']?.type && (
                <ErrorMessageLabel>{errorsInfo['photo']?.message}</ErrorMessageLabel>
              )}
            </ButtonWrapper>
          )}
          name="photo"
        />
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>
            Imagen formato PNG/JPG con dimensión máxima de 1200x1200 pixeles
          </WarningImageText>
        </WarningContainer>
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>Esta imagen será la foto de portada de tu ruta</WarningImageText>
        </WarningContainer>
      </PathIconContainer>
      <AdditionalInformationContainer>
        <AdditionalInformationTitle>Información Adicional</AdditionalInformationTitle>
        <AdditionalInfoWrapper>
          <AdditionalInfoLabel>Metodología</AdditionalInfoLabel>
          <RowStyled>
            {routeType &&
              routeType.map((type, index) => (
                <ColStyled key={index}>
                  <Controller
                    control={controlInfo}
                    render={() => (
                      <SimpleCheckBox
                        widthCustom={15}
                        handleCheckElement={() => setPath(type.value)}
                        isChecked={type_path === type.value}
                        onChange={() => setPath(type.value)}
                        type={'radio'}
                      />
                    )}
                    name="methodology"
                  />
                  <RowLabel>{type.label}</RowLabel>
                </ColStyled>
              ))}
          </RowStyled>
        </AdditionalInfoWrapper>
        <AdditionalInfoWrapper>
          <AdditionalInfoLabel>Nivel</AdditionalInfoLabel>
          <RowStyled>
            {admin.levelPath &&
              admin.levelPath.map((level, index) => (
                <ColStyled key={index}>
                  <Controller
                    control={controlInfo}
                    render={() => (
                      <SimpleCheckBox
                        widthCustom={15}
                        handleCheckElement={() => setTypeLevel(level.value)}
                        isChecked={levelType === level.value}
                        onChange={() => setTypeLevel(level.value)}
                        type={'radio'}
                      />
                    )}
                    name="level"
                  />
                  <RowLabel>{level.label}</RowLabel>
                </ColStyled>
              ))}
          </RowStyled>
        </AdditionalInfoWrapper>
      </AdditionalInformationContainer>
    </DetailsContainer>
  );
};

export default LearningPathDetails;
