import AdminCreateUser from 'components/organisms/AdminCreateUser';
import * as React from 'react';
import { useHistory } from 'react-router';
import { AdminRole } from 'constants/NavigationUrls';

const CreateUser: React.FC = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <AdminCreateUser showHeader={true} onSuccess={() => history.push(AdminRole.catalogCourse)} />
    </React.Fragment>
  );
};

export default CreateUser;
