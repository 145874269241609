import * as React from 'react';
import MainLogin from 'components/organisms/Login';

const Login = (): JSX.Element => {
  return (
    <React.Fragment>
      <MainLogin />
    </React.Fragment>
  );
};

export default Login;
