import AcredittaRequest from 'actions/requests/api';
import { queryParams } from 'helpers/create-query-params';
import { BadgeListResponse } from 'interfaces/badge-list-response';

/**
 * Get badge list
 * @param payload
 * @param page
 * @returns Promise
 */

interface GetBadgeListPayload {
  ordering?: string;
}

export const getBadgeList = (payload: GetBadgeListPayload, page = 1) => {
  let uri = `badges/templates/list/?page=${page}`;

  let query = queryParams(payload);

  if (query) {
    uri += `&${query}`;
  }

  return AcredittaRequest.get<BadgeListResponse>(uri);
};

export const getUsersByBadge = (uuid: string, page = 1, search?: string) => {
  let uri = `badges/templates/users/?uuid=${uuid}&page=${page}`;

  if (search) {
    uri += `&search=${search}`;
  }

  return AcredittaRequest.get(uri);
};

/**
 * Get badge detail template
 * @param id
 * @returns
 */
export const getBadgeTemplateDetail = async (id: string) => {
  return await AcredittaRequest.get(`badges/badge-template-detail/${id}`);
};

export const deleteBadgetemplate = async (uuid: string) => {
  return await AcredittaRequest.patch(`badges/templates/inactive/${uuid}/`);
};

export const getBadgeCollection = async (uuid: string) => {
  return await AcredittaRequest.get(`badges/collection-card/${uuid}`);
};

export const getUserCollection = async () => {
  return await AcredittaRequest.get('badges/user-badge-collections/card');
};
export const getBadgesTemplate = async () => {
  return await AcredittaRequest.get(`badges/badge-templates/`);
};

export const badgesSaveCollection = async (payload: any[]) => {
  return await AcredittaRequest.post(`badges/save-collection/`, payload);
};

export const getPreviewBadgeCollection = async (id: string) => {
  return await AcredittaRequest.get(`badges/preview-collection/${id}`);
};

export const setAsPublishBagdeTemplate = async (id: string) => {
  return await AcredittaRequest.patch(`badges/templates/update/${id}/`, { status: 'active' });
};
