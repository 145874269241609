interface Props {
  source: string;
}

const ThumbImage = ({ source }: Props) => {
  return (
    <div
      className="justify-content-center align-items-center"
      style={{ height: '100%', paddingTop: 5 }}
    >
      <img
        src={source}
        alt=""
        className="mr-2"
        style={{ display: 'inline-block', width: 36, height: 36 }}
      />
    </div>
  );
};

export default ThumbImage;
