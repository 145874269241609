import * as React from 'react';
import ExpiredCode from 'components/organisms/ExpiredCode';

const ExpiredCodePage = (): JSX.Element => {
  return (
    <React.Fragment>
      <ExpiredCode />
    </React.Fragment>
  );
};

export default ExpiredCodePage;
