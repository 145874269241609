import AcredittaRequest from 'actions/requests/api';
import { API_URL } from 'constants/ApiUrls';
import { BadgeDetailResponse } from 'interfaces/badge-detail';
import { HttpRequest } from './HttpRequest';

/**
 * Check is badged was emitted
 * @params {string} id
 * @returns Promise
 * @Author Jose Quintero
 */

export const checkIfBadgeWasEmitted = (id: string) => {
  return new HttpRequest().Get(`${API_URL}badges/templates/issued-badges/count/${id}`);
};

/**
 * Get badge data to edit
 * @params {string} id
 * @returns Promise
 * @Author Jose Quintero
 */

export const getBadgeData = (id: string) => {
  return new HttpRequest().Get(`${API_URL}badges/templates/update-version/${id}`);
};

/**
 * Update image for badge
 * @params {string} id
 * @params {binary} formData
 * @returns Promise
 * @Author Jose Quintero
 */
export const badgeUpdateImage = (id: string, formData: FormData) => {
  return new HttpRequest().Patch(`${API_URL}badges/templates/images/${id}/`, formData);
};

/**
 * Update data for badge
 * @params {string} id
 * @params {Object} payload
 * @returns Promise
 * @Author Jose Quintero
 */
export const updateDataBadge = (id: string, payload: Object) => {
  return new HttpRequest().Patch(`${API_URL}badges/templates/update/${id}/`, payload);
};

/**
 * Get badge details
 * @params {string} id
 * @returns Promise
 * @Author Jose Quintero
 */
export const getBadgeDetailsData = (id: string) => {
  return AcredittaRequest.get<BadgeDetailResponse>(`badges/badge-detail/${id}/`);
}

export const getBadgeTemplateDetailsData = (id: string) => {
  return AcredittaRequest.get(`badges/badge-template-detail/${id}/`);
}
