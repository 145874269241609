import * as React from 'react';
import {
  ShareBadgeImageContainer,
  ShareBadgeImageTitle,
  ImageWrapper,
  ButtonWrapper,
} from './styles';
import Button from 'components/atoms/Button';
import { DownloadBadgeImageCertThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';

interface ShareBadgeImageProps {
  image_url: string;
  closeShareSection: () => void;
  badgeId: string;
}

const ShareBadgeImage = ({
  image_url,
  closeShareSection,
  badgeId,
}: ShareBadgeImageProps): JSX.Element => {
  const dispatch = useAppDispatch();

  // const [imageSize, setImageSize] = React.useState<number>(200);

  const getImage = async () => {
    const badgeImage = await dispatch(DownloadBadgeImageCertThunk(badgeId));
    if (DownloadBadgeImageCertThunk.fulfilled.match(badgeImage)) {
      toast.success('Se ha descargado la imagen con éxito');
      var a = document.createElement('a');
      a.href = 'data:image/png;base64,' + badgeImage.payload.data.image;
      a.download = badgeImage.payload.data.filename.toString();
      a.click();
    } else {
      toast.error('No se ha podido descargar la imagen');
    }
  };

  return (
    <ShareBadgeImageContainer>
      <ShareBadgeImageTitle>Descargar Imagen</ShareBadgeImageTitle>
      <ImageWrapper>
        {/* <FormGroupStyled>
          <InputStyled
            id="image_size"
            name="image_size"
            type="range"
            onChange={(e: { target: { value: React.SetStateAction<number> } }) =>
              setImageSize(e.target.value)
            }
            min="50"
            max="600"
          />
        </FormGroupStyled> */}
        <img src={image_url} width={'300px'} alt="badge-img" />
      </ImageWrapper>
      <ButtonWrapper>
        <Button label="Cancelar" widthCustom={150} lightMode={true} onClick={closeShareSection} />
        <Button label="Descargar" widthCustom={150} onClick={getImage} />
      </ButtonWrapper>
    </ShareBadgeImageContainer>
  );
};

export default ShareBadgeImage;
