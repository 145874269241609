import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { Navbar, NavbarBrand } from 'reactstrap';
import { FONT_COLOR } from 'assets/styles/colors';

export const FooterContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
`;

export const FooterNavbar = styled(Navbar)`
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  padding: 19px;
`;

export const FooterAcredittaLogoWrapper = styled(NavbarBrand)`
  height: 100%;
  display: flex;
  align-self: flex-start;
  margin-bottom: 1.3125em;
`;

export const DividerLine = styled.div`
  width: 30%;
  border-top: 4px solid ${FONT_COLOR};
  display: flex;
  align-self: flex-start;
  margin-top: 20px;

  @media ${device.mediumLaptop} {
    display: none;
  }
`;
