export const device = {
  smallMobile: `only screen and (max-width: 320px)`,
  mediumMobile: `only screen and (max-width: 440px)`,
  mobile: `only screen and (max-width: 480px)`,
  bigMobile: `only screen and (max-width: 575px)`,
  tablet: `only screen and (max-width: 720px)`,
  ipad: `only screen and (max-width: 768px)`,
  mediumLaptop: `only screen and (max-width: 830px)`,
  laptopsHeight: `only screen and (max-height: 883px)`,
  laptops: `only screen and (max-width: 1024px)`,
  desktop: `only screen and (max-width: 1200px)`,
};
