import * as React from 'react';
import { BadgeDetailsResumenInfoContainer, BadgeName } from './styles';
import Button from 'components/atoms/Button';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';

interface DetailPropsBadgesIssue {
  image: string;
  name: string;
  uuid: string;
}

const BadgeIssueResumenInfo = ({ image, name, uuid }: DetailPropsBadgesIssue): JSX.Element => {
  const history = useHistory();

  const handleBadgeDetail = () => {
    history.push(`${AdminRole.templateBadgeDetail}/${uuid}`);
  };
  return (
    <BadgeDetailsResumenInfoContainer>
      <div style={{ padding: '10%' }}>
        <BadgeName style={{ textAlign: 'center' }}>{name}</BadgeName>
      </div>
      <div
        style={{
          padding: '10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={image} style={{ width: '80%' }} alt="imagen-de-la-insignia" />
      </div>
      <Button label="Ver Insignia" widthCustom={150} onClick={() => handleBadgeDetail()} />
    </BadgeDetailsResumenInfoContainer>
  );
};

export default BadgeIssueResumenInfo;
