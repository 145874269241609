import { GREEN, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Col, Container, Label } from 'reactstrap';
import styled from 'styled-components';

export const SubscriptionContainer = styled(Container)`
  flex: 1;
  margin-top: 30px;
  margin-bottom: 100px;
`;

export const SubscriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`;

export const SubscriptionTitles = styled(Label)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SubscriptionSubTitles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TitlesFirst = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
`;

export const TitlesSecond = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
`;

export const TitlesFirstGrid = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;

export const TitlesSecondGrid = styled(Label)`
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;

export const TitlesThirdGrid = styled(Label)`
  color: ${GREEN};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-top: -10px;
  cursor: pointer;
`;

export const IconGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

export const GridData = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5% 0%;
  @media ${device.mediumLaptop} {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media ${device.mediumMobile} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 115px;
  background: white
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  margin-bottom: 15px;
`;
