import icons from 'assets/Icon';
import { useState } from 'react';

const AcredittaPlatformMessage = ({ children }: any) => {
  const [show, setShow] = useState(true);

  return (
    <div
      style={{
        position: 'fixed',
        display: show ? 'block' : 'none',
        bottom: 45,
        left: 10,
        boxShadow: '0px 10px 30px 5px rgba(153, 162, 169, 0.5)',
        background: 'white',
        borderRadius: '8px',
        zIndex: 9999,
        padding: '0 0 5px 0',
        maxWidth: '470px',
      }}
    >
      <div
        onClick={() => setShow(false)}
        className="cursor-pointer"
        style={{ position: 'absolute', right: 10, top: 10 }}
      >
        <icons.CloseIcon fontSize={'13px'} style={{ margin: 0, padding: 0 }} />
      </div>
      {children}
    </div>
  );
};

export default AcredittaPlatformMessage;
