import { getBadgeOverview, getBadgeTemplateOverview } from 'actions/ApiClient/analytics';
import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AcredittaSearchInput from 'components/atoms/AcredittaSearchInput';
import AcredittaTable from 'components/atoms/AcredittaTable';
import AcredittaTableHead from 'components/atoms/AcredittaTableHead.tsx';
import AcredittaText from 'components/atoms/AcredittaText';
import BadgeResume from 'components/organisms/AdminAnalytics/components/BadgeResume';
import PieChart from 'components/organisms/AdminAnalytics/components/PieChart';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import { manageErrors } from 'helpers/manage-error';
import { BadgeOverviewResponse } from 'interfaces/badge-overview';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { TableRowData, TableRowLoading, TableRowNoData, BadgeResumeChart } from './components';
import ReactPaginate from 'react-paginate';
import { getPageCount } from 'helpers/page-count';
import { getDataCards } from './helpers/data-cards';
import '../../../theme/analytics.css';
import styled from 'styled-components';

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 540px;
  align-items: center;
  justify-content: center;
`;

const BadgeDashboardPage = () => {
  const [search, setSearch] = useState('');
  const [badgeOverview, setBadgeOverview] = useState({} as BadgeOverviewResponse);
  const [loadingBadgeOverview, setLoadingOverview] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [badgeTemplateOverview, setBadgeTemplateOverview] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [dataCards, setDataCards] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingOverview(true);
        const resp = await getBadgeOverview();
        setBadgeOverview(resp.data);
        setDataCards(getDataCards(resp.data));
        setLoadingOverview(false);
      } catch (e) {
        setLoadingOverview(false);
        manageErrors(e);
      }
    })();
  }, []);

  const fetchBadgeTemplateOverview = useCallback(
    async (search?: string) => {
      try {
        setLoadingTable(true);
        setBadgeTemplateOverview([]);
        const resp = await getBadgeTemplateOverview(search, activePage);
        const { results } = resp.data;
        setPageOffset(activePage - 1);
        setPageCount(getPageCount(resp.data.count));
        setBadgeTemplateOverview(results);
        setLoadingTable(false);
      } catch (e) {
        setLoadingTable(false);
        manageErrors(e);
      }
    },
    [activePage]
  );

  useEffect(() => {
    fetchBadgeTemplateOverview(search);
  }, [search, fetchBadgeTemplateOverview]);

  const handlePageChange = (selected: number) => {
    setActivePage(selected + 1);
  };

  const handleInputChange = (value: string) => {
    setActivePage(1);
    setSearch(value);
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-12">
            <AcredittaText variant="h3" style={{ marginLeft: '15px' }}>
              Análisis de insignias
            </AcredittaText>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-4">
            <AcredittaBox>
              {loadingBadgeOverview ? (
                <AcredittaLoader />
              ) : (
                <StyledFlexDiv>
                  <div>
                    <PieChart
                      totalData={3}
                      width={200}
                      height={200}
                      badgeOverview={badgeOverview}
                    />
                  </div>
                  <div className="mt-5">
                    <BadgeResume
                      text={`${badgeOverview.accepted.value} Aceptadas`}
                      color="#02B148"
                    />
                    <BadgeResume
                      text={`${badgeOverview.pending.value} Pendientes`}
                      color="#F9B233"
                    />
                    <BadgeResume
                      text={`${badgeOverview.rejected.value} Rechazadas`}
                      color="#DA291C"
                    />
                  </div>
                </StyledFlexDiv>
              )}
            </AcredittaBox>
          </div>
          <div className="col-sm-12 col-md-8">
            <div className="row p-2">
              <BadgeResumeChart />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          {!loadingBadgeOverview &&
            dataCards.map(({ value, label }, idx: number) => (
              <div className="col-sm-12 col-md-6" key={idx}>
                <AcredittaBox>
                  <AcredittaText variant="h3" style={{ lineHeight: '0.5em' }} align="center">
                    {value}
                  </AcredittaText>
                  <AcredittaText variant="body1" align="center">
                    {label}
                  </AcredittaText>
                </AcredittaBox>
              </div>
            ))}
        </div>

        <div className="row mt-5">
          <div className="col-sm-12">
            <AcredittaSearchInput onChangeInput={(value: string) => handleInputChange(value)} />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-12">
            <AcredittaText variant="h4">Insignias de la organización</AcredittaText>
          </div>
        </div>

        <Row>
          <Col sm={12}>
            <AcredittaTable>
              <AcredittaTableHead
                data={['Insignias de la organización', 'Emisiones', 'Aceptación', 'Rechazadas']}
              />
              <tbody>
                {loadingTable && <TableRowLoading />}
                {!loadingTable && badgeTemplateOverview.length === 0 && <TableRowNoData />}
                {!loadingTable && badgeTemplateOverview.length > 0 && (
                  <TableRowData data={badgeTemplateOverview} />
                )}
              </tbody>
            </AcredittaTable>
            {!loadingTable && badgeTemplateOverview.length > 0 && (
              <nav style={{ margin: '10px auto', display: 'flex', justifyContent: 'center' }}>
                <ReactPaginate
                  activeClassName="active"
                  breakClassName="page-item"
                  breakLinkClassName="custom-page-link"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  containerClassName="pagination"
                  previousClassName="custom-nav-link"
                  nextClassName="custom-nav-link"
                  nextLabel=">"
                  previousLabel="<"
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  onPageChange={({ selected }) => handlePageChange(selected)}
                  forcePage={pageOffset}
                />
              </nav>
            )}
          </Col>
        </Row>

        <div style={{ height: '500px' }} />
      </div>
    </CommonPageLayout>
  );
};

export default BadgeDashboardPage;
