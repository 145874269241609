import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from 'states';
import { Toaster } from 'react-hot-toast';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import { TourProvider } from '@reactour/tour';
import 'assets/styles/index.css';
import 'assets/styles/argon-dashboard-react.css';
import 'assets/styles/video-react.css';
import 'react-phone-input-2/lib/bootstrap.css';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
  dataLayerName: 'AcredittaFrontedPage',
};

TagManager.initialize(tagManagerArgs);

const history = createBrowserHistory();

const steps = [
  {
    selector: '.one-step',
    content: () => (
      <div style={{ textAlign: 'center' }}>
        <h3 color="#5A5A5A">Puedes personalizar el logo para tu organización</h3>
      </div>
    ),
  },
  {
    selector: '.two-step',
    content: () => (
      <div style={{ textAlign: 'center' }}>
        <h3 color="#5A5A5A">Acá podrás ver y crear insignias de tu organización</h3>
      </div>
    ),
  },
  {
    selector: '.tree-step',
    content: () => (
      <div style={{ textAlign: 'center' }}>
        <h3 color="#5A5A5A">Crea y visualiza tus rutas de aprendizaje</h3>
      </div>
    ),
  },
  {
    selector: '.four-step',
    content: () => (
      <div style={{ textAlign: 'center' }}>
        <h3 color="#5A5A5A">Descubre y sube contenido para crear rutas</h3>
      </div>
    ),
  },
  {
    selector: '.five-step',
    content: () => (
      <div style={{ textAlign: 'center' }}>
        <h3 color="#5A5A5A">
          Configura tu cuenta personal, visualiza tus insignias y mira tu perfil desde esta sección
        </h3>
      </div>
    ),
  },
  {
    selector: '.six-step',
    content: () => (
      <div style={{ textAlign: 'center' }}>
        <h3 color="#5A5A5A">
          Configura tu cuenta, carga usuarios y revisa el perfil de tu organización.
        </h3>
      </div>
    ),
  },
];

const styles = {
  badge: (base: any) => ({ ...base, color: 'blue' }),
};

const Badge = () => {
  return <div></div>;
};

const Close = () => {
  return <div></div>;
};

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Fragment>
          <TourProvider steps={steps} styles={styles} components={{ Badge, Close }}>
            <App />
          </TourProvider>
          <Toaster />
        </React.Fragment>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
