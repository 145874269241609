import toast from 'react-hot-toast';
import { manageErrors } from 'helpers/manage-error';
import CardBadge from 'components/molecules/CardBadge';
import { useState, useCallback, useEffect } from 'react';
import CommonPageLayout from '../Layout/CommonPageLayout';
import AcredittaText from 'components/atoms/AcredittaText';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { GetBadgeAutomation } from 'actions/ApiClient/AdminPaths';
import { GetOrganizationOverviewThunk } from 'actions/Thunks/AdminThunk';
import OrganizationBadgesInfo from 'components/molecules/BadgesOrganizationInfo';
import { GetAllBadgesTemplatesDetailUnAuthOrgThunk } from 'actions/Thunks/UnAuthUsersThunks';
import { BadgesInfoContainer } from './styles';
import './styles.css';
import MoreInfoDesktop from './MoreInfoDesktop';
import MoreInfoMobile from './MoreInfoMobile';
import CustomizeYourTheme from 'components/molecules/CustomizeYourTheme/CustomizeYourTheme';

const ProfileOrganization = () => {
  const [organizationInfo, setOrganizationInfo] = useState<any>(null);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [badges, setBadges] = useState<any[]>([]);
  const [isFetch, setIsFetch] = useState(false);

  const getOrganizationOverview = useCallback(async () => {
    if (user.userInfo?.organization.id) {
      const organization = await dispatch(
        GetOrganizationOverviewThunk(user.userInfo?.organization.id.toString())
      );
      if (GetAllBadgesTemplatesDetailUnAuthOrgThunk.rejected.match(organization)) {
        toast(organization.payload as string);
      } else {
        setOrganizationInfo(organization.payload);
      }
    }
  }, [user.userInfo?.organization.id, dispatch]);

  useEffect(() => {
    getOrganizationOverview();
  }, [user.userInfo?.organization.id, getOrganizationOverview]);

  useEffect(() => {
    (async () => {
      try {
        setIsFetch(true);
        const resp = await GetBadgeAutomation(user.userInfo?.organization.id);
        setBadges(resp);
        setIsFetch(false);
      } catch (e) {
        setIsFetch(false);
        manageErrors(e);
      }
    })();
  }, [user.userInfo?.organization.id]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <CustomizeYourTheme />
      <div className="container">
        <BadgesInfoContainer>
          {organizationInfo && (
            <OrganizationBadgesInfo
              description={organizationInfo.description}
              organization_name={organizationInfo.name}
              organization_email={organizationInfo.email}
              organization_website={organizationInfo.domain}
              organization_icon={organizationInfo.photo}
              organization_facebook={organizationInfo.url_facebook}
              organization_twitter={organizationInfo.url_twitter}
              organization_linkedin={organizationInfo.url_linkedin}
              organization_instagram={organizationInfo.url_instagram}
            />
          )}
        </BadgesInfoContainer>

        {window.innerWidth > 768 ? <MoreInfoDesktop user={user} /> : <MoreInfoMobile user={user} />}

        <div className="row mt-5">
          <div className="col">
            <AcredittaText variant="h3">
              {!isFetch && (
                <>
                  {badges.length} <span style={{ fontSize: '20px' }}>Insignias creadas</span>
                </>
              )}
            </AcredittaText>
          </div>
        </div>
        <div className="row">
          {isFetch && (
            <div className="mx-auto">
              <AcredittaLoader />
            </div>
          )}
          {!isFetch && badges.map((item) => <CardBadge item={item} />)}
        </div>
        <div style={{ height: '200px' }}>&nbsp;</div>
      </div>
    </CommonPageLayout>
  );
};

export default ProfileOrganization;
