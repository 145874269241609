import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import badge from '../../../../assets/images/badge.png';
import styles from './styles.module.css';
import AcredittaButton from 'components/atoms/AcredittaButton';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';

const CardNewBadge = () => {
  const { push } = useHistory();
  return (
    <AcredittaBox boxStyle={{ padding: '20px 0' }} borderRadius="25px">
      <div className="row">
        <div className="col">
          <div>
            <AcredittaText variant="h3" align="center">
              ¡Crea una insignia!
            </AcredittaText>
          </div>
          <div>
            <img src={badge} alt="" className={styles.image} />
          </div>
          <div>
            <AcredittaText variant="body1" color="secondary" align="center">
              ¡No esperes más! Crea tu primera insignia y comienza a reconocer el talento y el
              aprendizaje de tus usuarios.
            </AcredittaText>
          </div>
          <div className="text-center mt-4">
            <AcredittaButton
              type="button"
              variant="primary"
              title="Crear una insignia"
              onPress={() => push(AdminRole.createBadge)}
            />
          </div>
        </div>
      </div>
    </AcredittaBox>
  );
};

export default CardNewBadge;
