import icons from 'assets/Icon';
import * as React from 'react';
import {
  MenuWrapper,
  MenuItemWrapper,
  MenuItemHeader,
  MenuItemTitle,
  MenuItemInfo,
  Divisor,
} from './styles';
import { formatDate } from 'utils';

interface AdminLearningPathDescriptionMenuProps {
  start: Date;
  duration: number;
  users: number;
  level: string;
  content: number;
  language: string;
  methodology: string;
}

const AdminLearningPathDescriptionMenu = ({
  start,
  duration,
  users,
  level,
  content,
  language,
  methodology,
}: AdminLearningPathDescriptionMenuProps): JSX.Element => {
  return (
    <MenuWrapper>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.CalendarIcon />
          <MenuItemTitle>Inicio</MenuItemTitle>
        </MenuItemHeader>
        <div>
          {start ? (
            <MenuItemInfo>{formatDate(start)}</MenuItemInfo>
          ) : (
            <MenuItemInfo>Fecha flexible</MenuItemInfo>
          )}
        </div>
      </MenuItemWrapper>
      <Divisor/>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.TimeIcon />
          <MenuItemTitle>Duración</MenuItemTitle>
        </MenuItemHeader>
        <div>
          {duration ? (
            <MenuItemInfo>{duration}</MenuItemInfo>
          ) : (
            <MenuItemInfo>A tu ritmo</MenuItemInfo>
          )}
        </div>
      </MenuItemWrapper>
      <Divisor/>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.UserIcon />
          <MenuItemTitle>Usuarios</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{users}</MenuItemInfo>
        </div>
      </MenuItemWrapper>
      <Divisor/>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.LevelIcon />
          <MenuItemTitle>Nivel</MenuItemTitle>
        </MenuItemHeader>
        <div>
          {level ? <MenuItemInfo>{level}</MenuItemInfo> : <MenuItemInfo>Principiante</MenuItemInfo>}
        </div>
      </MenuItemWrapper>
      <Divisor/>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.CoursesIcon />
          <MenuItemTitle>Contenido</MenuItemTitle>
        </MenuItemHeader>
        <div>
          <MenuItemInfo>{content} Cursos</MenuItemInfo>
        </div>
      </MenuItemWrapper>
      <Divisor/>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.LanguageIcon />
          <MenuItemTitle>Idioma</MenuItemTitle>
        </MenuItemHeader>
        {/* TODO: display the languages  */}
        <MenuItemInfo>Español</MenuItemInfo>
      </MenuItemWrapper>
      <Divisor/>
      <MenuItemWrapper>
        <MenuItemHeader>
          <icons.MarkBookIcon />
          <MenuItemTitle>Metodología</MenuItemTitle>
        </MenuItemHeader>
        <div>
          {methodology ? (
            <MenuItemInfo>{methodology}</MenuItemInfo>
          ) : (
            <MenuItemInfo>E-LEARNING</MenuItemInfo>
          )}
        </div>
      </MenuItemWrapper>
    </MenuWrapper>
  );
};

export default AdminLearningPathDescriptionMenu;