import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Col, Label } from 'reactstrap';
import styled from 'styled-components';

export const AdminBadgeListContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 2em auto;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const BadgesListSectionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
`;

export const ColStyled = styled(Col)`
  width: 100%;
  max-width: 150px;
`;

export const MyRoutesRows = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 25px 0px;
  gap: 10px;
`;

export const BadgesDateTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 27px;
  color: ${PRIMARY_COLOR};
  margin-right: 5px;
`;

export const BadgesDate = styled(Label)`
  font-size: 20px;
  line-height: 27px;
  color: ${PRIMARY_COLOR};
`;

export const BadgesContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BadgesInfoContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
`;

export const BadgeName = styled(Label)`
  
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  text-align: center;
`;

export const BadgeOwner = styled(Label)`
  
  font-size: 20px;
  line-height: 19px;
  color: #53565a;
  text-align: center;
  margin-top: 5px;
`;

export const BadgeDateWrapper = styled.div`
  margin: 10px 0px;
`;
