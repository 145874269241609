import React from 'react';
import {
  UploadCoursesOptions,
  UploadCoursesOptionsContainer,
  UploadCoursesOptionWrapper,
  UploadSelectedType,
  CoursesUploadTypeWrapper,
  CoursesUploadContainer,
  CoursesUploadTypeFormWrapper,
  CourseCoverIconContainer,
} from './styles';
import { StepWizardProps } from 'react-step-wizard';
import icons from 'assets/Icon';
import AdminMasiveCoursesUploadCard from 'components/molecules/AdminMasiveCoursesUploadCard';
import AdminUploadNewCourseForm from 'components/molecules/AdminUploadNewCourseForm';
import { objectValidation } from 'utils';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  CreateNewCourseThunk,
  UpdateImageNewCourseThunk,
  UpdateOwnCoursesThunk,
} from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ValidURL } from 'utils';
import AdminUploadImageInfoCourse from 'components/molecules/AdminUploadImageInfoCourse';

interface AdminCoursesUploadSectionProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const AdminCoursesUploadSection = ({ onNextStep }: AdminCoursesUploadSectionProps): JSX.Element => {
  const [masiveUpload, setMasiveUpload] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [inputList, setInputList] = React.useState<ICourseContent[]>([{ name: '', duration: 0 }]);
  const [modality, setModality] = React.useState<number | null>(null);
  const [level, setLevel] = React.useState<number | null>(null);

  const submitNewCourseForm = async (courseInfo: IUploadNewCourse) => {
    let skills_selected: number[] = [];
    let url_validated = ValidURL(courseInfo.url);
    courseInfo.skill?.map((skills: any) => {
      return skills_selected.push(skills.value);
    });
    if (url_validated) {
      const newCourseInfo: ICreateNewCourseIndex = {
        path_info: {
          name: courseInfo.name,
          description: courseInfo.description,
          url: url_validated,
          skill_tag: skills_selected,
          language: courseInfo.language ? [courseInfo.language.value] : [],
          is_private: courseInfo.is_private,
          cost: parseInt(courseInfo.cost),
          new_content: inputList,
          modality: modality,
          level: level,
        },
        path_photo: {
          image: courseInfo.image,
        },
      };
      const newCourse = await dispatch(CreateNewCourseThunk(newCourseInfo));

      if (CreateNewCourseThunk.fulfilled.match(newCourse)) {
        if (watch().image) {
          const formUpdateImage: IUpdateNewCourseImage = {
            id: newCourse.payload.id,
            image: watch().image,
          };
          const updateImage = await dispatch(UpdateImageNewCourseThunk(formUpdateImage));
          if (UpdateImageNewCourseThunk.fulfilled.match(updateImage)) {
            toast.success('Se ha creado el curso con éxito');
            onNextStep();
          } else {
            toast.error('Error');
          }
        } else {
          toast.success('Se ha creado el curso con éxito');
          onNextStep();
        }
      } else {
        toast.error(newCourse.payload as string);
      }
    }
  };

  const defaultValuesNewCourseUpload: IUploadNewCourse = {
    name: '',
    description: '',
    image: null,
    score: null,
    duration: 0,
    is_completed: null,
    cost: null,
    session: null,
    teacher: null,
    start_date: null,
    end_date: null,
    modality: null,
    materiality: null,
    organization: null,
    difficulty: null,
    type_cost: null,
    level: null,
    provider: null,
    skill: [],
    tag: null,
    language: null,
    content: null,
    url: '',
    is_private: false,
    new_content: null,
  };

  const getDefaultFormValues = (): IUploadNewCourse => {
    return defaultValuesNewCourseUpload;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IUploadNewCourse>({
    ...objectValidation,
    defaultValues: getDefaultFormValues(),
  });

  // const file = watch().image;

  // const selectedPicture = React.useMemo(() => {
  //   if (file) {
  //     return URL.createObjectURL(file);
  //   } else {
  //     return `${S3_ADMIN}NoProfilePictureIcon.svg`;
  //   }
  // }, [file]);

  const onCancelUpload = async () => {
    if (user.adminInfo) {
      let formInfo: IUpdateUserOwnCourses = {
        own_courses: {
          own_courses: false,
        },
        id: user.adminInfo.id,
      };

      const update_info = await dispatch(UpdateOwnCoursesThunk(formInfo));

      if (UpdateOwnCoursesThunk.fulfilled.match(update_info)) {
        onNextStep();
      } else {
        toast.error(update_info.payload as string);
      }
    }
  };

  return (
    <div>
      <UploadCoursesOptionsContainer>
        <UploadCoursesOptionWrapper onClick={() => setMasiveUpload(true)} $selected={masiveUpload}>
          <UploadCoursesOptions $selected={masiveUpload}>Carga Masiva</UploadCoursesOptions>
          <icons.MasiveCoursesUploadIcon />
        </UploadCoursesOptionWrapper>
        <UploadCoursesOptionWrapper
          onClick={() => setMasiveUpload(false)}
          $selected={!masiveUpload}
        >
          <UploadCoursesOptions $selected={!masiveUpload}>Carga Individual</UploadCoursesOptions>
          <icons.IndividualCoursesUploadIcon />
        </UploadCoursesOptionWrapper>
      </UploadCoursesOptionsContainer>
      <CoursesUploadContainer>
        {masiveUpload ? (
          <CoursesUploadTypeWrapper>
            <UploadSelectedType>Carga Masiva de Cursos</UploadSelectedType>
            <icons.CoursesUploadIlustration />
          </CoursesUploadTypeWrapper>
        ) : (
          <CourseCoverIconContainer>
            <AdminUploadImageInfoCourse
              watch={watch}
              control={control}
              errors={errors}
              onSelectModality={setModality}
              onSelectLevel={setLevel}
            />
          </CourseCoverIconContainer>
        )}
        <CoursesUploadTypeFormWrapper>
          {masiveUpload ? (
            <AdminMasiveCoursesUploadCard cancelUpload={() => onCancelUpload()} />
          ) : (
            <AdminUploadNewCourseForm
              controlInfo={control}
              handleSubmitForm={handleSubmit}
              errorsInfo={errors}
              submitNewCourseForm={submitNewCourseForm}
              hideSection={() => setMasiveUpload(true)}
              watch={watch}
              onChangeContent={(value: ICourseContent[]) => setInputList(value)}
            />
          )}
        </CoursesUploadTypeFormWrapper>
      </CoursesUploadContainer>
    </div>
  );
};

export default AdminCoursesUploadSection;
