import { badgeEmitPath } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ModalBeforeBadgeEmit from 'components/molecules/ModalBeforeBadgeEmit';
import icon from '../../../../assets/images/icono-emitir.png';
import { useAppSelector } from 'hooks/redux';
import { useState } from 'react';

const StyledMainContainer = styled.div`
  background: rgba(83, 86, 90, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 93%;
  height: 100%;
  border-radius: 8px;
  margin-right: 12px;
  z-index: 1;
`;

const StyledDiv = styled.div`
  width: 50px;
  height: 50px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
  margin: auto;
  cursor: pointer;
`;

interface Props {
  id: string;
}

const IssueBadgeButton = ({ id }: Props) => {
  const { push } = useHistory();
  const [show, setShow] = useState(false);
  const { adminInfo } = useAppSelector((state) => state.user);

  const handleEmit = () => {
    if (!adminInfo?.is_completed) {
      setShow(true);
      return;
    }
    push(badgeEmitPath(id));
  };

  return (
    <>
      <ModalBeforeBadgeEmit show={show} toogle={() => setShow(!show)} />

      <StyledMainContainer>
        <StyledDiv onClick={handleEmit}>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100%' }}
          >
            <img src={icon} alt="" width="25" height="25" />
          </div>
        </StyledDiv>
      </StyledMainContainer>
    </>
  );
};

export default IssueBadgeButton;
