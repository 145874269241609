import styled from 'styled-components';

const StyledTable = styled.table`
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.25);
  border-radius: 8px;
  width: 100%;
`;

const AcredittaTable = ({ children }: any) => {
  return <StyledTable className="table table-striped">{children}</StyledTable>;
};

export default AcredittaTable;
