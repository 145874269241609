import { Col, Container, Row } from 'reactstrap';
import { manageErrors } from 'helpers/manage-error';
import AcredittaText from 'components/atoms/AcredittaText';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getAcceptedBadgeCount, getIssuedBadgeCount } from 'actions/ApiClient/analytics';
import {
  Chart as ChartJS,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { Data, DataSet } from '../interfaces/chart';
import icons from '../../../../assets/Icon';
import DatePicker from 'react-date-picker';
import AcredittaButton from 'components/atoms/AcredittaButton';
import { format, subDays } from 'date-fns';
import { es } from 'date-fns/locale';
import AcredittaBox from 'components/atoms/AcredittaBox';
import styled from 'styled-components';

import emisionesIcon from '../../../../assets/images/emisiones_icon.png';
import acceptedIcon from '../../../../assets/images/accepted_icon.png';
import ExportButton from './ExportButton';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Análisis de insignias',
    },
  },
  minBarLength: 50,
  scales: {
    xAxis: {
      type: 'time' as const,
      time: {
        unit: 'month' as const,
      },
      stacked: true,
    },
  },
};

const StyledRelativeDiv = styled.div`
  position: relative;
`;

export const BadgeResumeChart = () => {
  const [loading, setLoading] = useState(true);
  const timerRef = useRef(null) as any;
  const initialFromDate = subDays(new Date(), 30);
  const initialToDate = new Date();
  const [data, setData] = useState({} as Data);
  const [dataset1, setDataset1] = useState<DataSet>({
    label: 'Aceptadas',
    data: [],
    borderColor: '#DA291C',
    backgroundColor: '#DA291C',
  });

  const [dataset2, setDataset2] = useState<DataSet>({
    label: 'Emisiones',
    data: [],
    borderColor: 'rgb(0, 32, 87)',
    backgroundColor: 'rgba(0, 32, 87,0.5)',
  });

  const [date1, setDate1] = useState(initialFromDate);
  const [date2, setDate2] = useState(initialToDate);
  const [showCalendar, setShowCalendar] = useState(false);
  const [accepted, setAccepted] = useState(0);
  const [issued, setIssued] = useState(0);

  const fetchAcceptedBadgeCount = useCallback(async (fromDate?, toDate?) => {
    try {
      const resp =
        fromDate && toDate
          ? await getAcceptedBadgeCount(
              format(fromDate, 'yyyy-MM-dd'),
              format(toDate, 'yyyy-MM-dd')
            )
          : await getAcceptedBadgeCount();

      const { data: acceptedBadgeData } = resp.data;
      setDataset1((prevState) => ({
        ...prevState,
        data: acceptedBadgeData.map((item: any) => ({ x: item.day, y: item.count })),
      }));
      setAccepted(acceptedBadgeData.reduce((acc: number, obj: any) => acc + obj.count, 0));
    } catch (e) {
      manageErrors(e);
    }
  }, []);

  const fetchIssuedBadgeCount = useCallback(async (fromDate?, toDate?) => {
    try {
      const resp =
        fromDate && toDate
          ? await getIssuedBadgeCount(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'))
          : await getIssuedBadgeCount();
      const { data: issuedBadgeData } = resp.data;
      setDataset2((prevState) => ({
        ...prevState,
        data: issuedBadgeData.map((item: any) => ({ x: item.day, y: item.count })),
      }));
      setIssued(issuedBadgeData.reduce((acc: number, obj: any) => acc + obj.count, 0));
    } catch (e) {
      manageErrors(e);
    }
  }, []);

  useEffect(() => {
    setData({
      datasets: [dataset1, dataset2],
    });

    timerRef.current = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [dataset1, dataset2]);

  useEffect(() => {
    fetchAcceptedBadgeCount();
  }, [fetchAcceptedBadgeCount]);

  useEffect(() => {
    fetchIssuedBadgeCount();
  }, [fetchIssuedBadgeCount]);

  const handleDateChange = (name: string, value: Date) => {
    if (name === 'date1') {
      setDate1(value);
    }

    if (name === 'date2') {
      setDate2(value);
    }
  };

  const handleFilterByDate = () => {
    fetchAcceptedBadgeCount(date1, date2);
    fetchIssuedBadgeCount(date1, date2);
    setShowCalendar(false);
  };

  return (
    <Container>
      <Row className="mt-1 mb-3">
        <Col>
          <StyledRelativeDiv>
            <div className="text-right">
              <ExportButton fromDate={date1} toDate={date2} />
            </div>
            <AcredittaText
              variant="h4"
              align="right"
              onClick={() => setShowCalendar(!showCalendar)}
              style={{ cursor: 'pointer' }}
            >
              <span>
                {format(date1, 'dd MMM yyyy', {
                  locale: es,
                })}
              </span>
              <span> - </span>
              <span>
                {format(date2, 'dd MMM yyyy', {
                  locale: es,
                })}
              </span>
              <icons.ArrowDown
                style={{
                  transform: showCalendar ? 'rotate(180deg)' : 'none',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
              />
            </AcredittaText>
            <AcredittaBox
              boxStyle={{
                position: 'absolute',
                right: 0,
                zIndex: 999,
                marginTop: 0,
                display: showCalendar ? 'block' : 'none',
              }}
            >
              <div>
                <span>
                  <DatePicker
                    onChange={(value: any) => handleDateChange('date1', value)}
                    value={date1}
                    locale="es"
                    clearIcon={null}
                  />
                </span>
                <span> - </span>
                <span>
                  <DatePicker
                    locale="es"
                    onChange={(value: any) => handleDateChange('date2', value)}
                    value={date2}
                    clearIcon={null}
                  />
                </span>
              </div>
              <div className="text-center mt-2">
                <AcredittaButton
                  title="Aplicar"
                  onPress={handleFilterByDate}
                  fullWidth
                  variant="small"
                />
              </div>
            </AcredittaBox>
          </StyledRelativeDiv>
        </Col>
      </Row>

      <Row>
        <Col xs="12" sm="6" className="text-center" style={{ borderRight: '2px solid #EBEBEB' }}>
          <div>
            <img src={emisionesIcon} alt="" style={{ width: 22, height: 22 }} />
            <AcredittaText variant="body2" align="center" color="red" fontWeight="700">
              {issued}
            </AcredittaText>
            <AcredittaText
              variant="body1"
              align="center"
              style={{ lineHeight: 0 }}
              color="secondary"
            >
              Emisiones
            </AcredittaText>
          </div>
        </Col>
        <Col xs="12" sm="6" className="text-center">
          <div>
            <img src={acceptedIcon} alt="" style={{ width: 22, height: 22 }} />
            <AcredittaText variant="body2" align="center" color="red" fontWeight="700">
              {accepted}
            </AcredittaText>
            <AcredittaText
              variant="body1"
              align="center"
              style={{ lineHeight: 0 }}
              color="secondary"
            >
              Aceptadas
            </AcredittaText>
          </div>
        </Col>
      </Row>

      {!loading && <Line options={options} data={data} />}

      <div style={{ marginTop: 20 }}>{loading && <AcredittaLoader width={50} height={50} />}</div>
    </Container>
  );
};

export default BadgeResumeChart;
