import { Label } from 'reactstrap';
import styled, { css } from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import icons from 'assets/Icon';

export const CardContainer = styled.div<{ selected?: boolean }>`
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: white;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 8px;
  margin: 10px 0px;
  padding: 10px;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 5px 10px 5px rgba(138, 149, 158, 0.3);
    `}
`;

export const CardImage = styled.img`
  width: 70px;
  height: 80px;
  object-fit: none;
`;

export const CardTitleContainer = styled.div`
  margin-top: 10px;
`;

export const CardTitleStyled = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  @media ${device.tablet} {
    line-height: 20px;
    font-size: 14px;
    text-align: center;
  }
`;

export const CheckIconWrapper = styled.div`
  position: absolute;
  right: 14%;
  top: 10%;
`;

export const CheckIcon = styled(icons.CheckIcon)`
  height: 16px;
  width: 16px;
`;
