import { PRIMARY_COLOR, SECONDARY_COLOR, TERTIARY } from 'assets/styles/colors';
import { Form, FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import { Theme } from 'react-select';
import { device } from 'constants/responsiveDevice';

export const NewLearningExperienceContainer = styled.div``;

export const FormContainer = styled(Form)``;

export const FieldTitle = styled(Label)`
  width: 130px;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  text-align: end;
  margin-right: 5px;
`;

export const FileMoreInfoTitle = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
  text-align: start;
  margin-bottom: 0px;
`;

export function styledTheme(theme: Theme) {
  return {
    ...theme,
    borderRadius: 8,
    colors: {
      ...theme.colors,
      primary: SECONDARY_COLOR,
      primary25: TERTIARY,
      neutral20: PRIMARY_COLOR,
      neutral30: PRIMARY_COLOR,
      neutral50: 'red',
    },
  };
}

export const FormInputWrapper = styled.div`
  width: 89%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const InputSelectContainer = styled.div`
  width: 100%;
`;

export const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;
`;

export const FormGroupDescriptionStyled = styled(FormGroup)`
  display: flex;
  align-items: self-start;
  label {
    margin-top: 5px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin: 10px 20px;
`;

export const MoreInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectedSkillsContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 130px;
`;
