import AcredittaText from 'components/atoms/AcredittaText';
import { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { BadgeImageContext } from '../context/BadgeImageProvider';
import { BadgesStackableContext } from '../context/BadgeStackableProvider';

const fillbagde = (value: number) => keyframes`
from {
  height: 0%
}
to {
  height: ${value}%
}
`;

interface Props {
  value: number;
}

const ColorDiv = styled.div<Props>`
  height: 100%;
  animation: ${(props: any) => fillbagde(props.value)} 2s;
  animation-fill-mode: forwards;
  overflow: hidden;
`;

const BadgeMasterImage = () => {
  const {
    mainBadge: { image_url, progress },
  } = useContext(BadgesStackableContext);
  const { show, isAdmin } = useContext(BadgeImageContext);

  return (
    <div className="mainContainer cursor-pointer">
      <div className="imageBox grey">
        <img
          src={image_url}
          alt=""
          width="190"
          height="190"
          style={{ display: 'block', margin: '10px auto' }}
        />
      </div>
      <ColorDiv className="imageBox" value={Math.round(isAdmin ? 100 : progress)}>
        <img
          src={image_url}
          alt=""
          width="190"
          height="190"
          style={{ display: 'block', margin: '10px auto' }}
        />
      </ColorDiv>
      {!isAdmin && (
        <div
          style={{
            display: show ? 'block' : 'none',
            position: 'absolute',
            top: '5%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <AcredittaText variant="h2">{Math.round(progress)} %</AcredittaText>
        </div>
      )}
    </div>
  );
};

export default BadgeMasterImage;
