import * as React from 'react';
import {
  BadgesListSectionTitle,
  BadgesDateTitle,
  BadgesDate,
  AdminBadgeListContainer,
  BadgeName,
  BadgeOwner,
  BadgesContainer,
  BadgesInfoContainer,
  BadgeDateWrapper,
  ColStyled,
  MyRoutesRows,
} from './styles';
import { formatDate } from 'utils';
import Button from 'components/atoms/Button';
import { Link, useHistory } from 'react-router-dom';
import { AccreditedRole, AdminRole } from 'constants/NavigationUrls';
import { useAppSelector } from 'hooks/redux';

interface AdminBadgesListProps {
  badges: any;
  dateStart: any;
  pathwayId: string;
  userType: any;
}

const AdminBadgesList = ({
  badges,
  dateStart,
  pathwayId,
  userType,
}: AdminBadgesListProps): JSX.Element => {
  const history = useHistory();

  const user = useAppSelector((state) => state.user);

  const assignRoute = () => {
    if (pathwayId) {
      history.push(`${AdminRole.assingRoute}/${pathwayId}`);
    }
  };

  return (
    <AdminBadgeListContainer>
      <BadgesContainer>
        <MyRoutesRows>
          {badges &&
            badges.map((badge: any, index: any) => {
              return (
                <ColStyled key={index}>
                  {user.userRole !== 3 ? (
                    <Link to={`${AdminRole.templateBadgeDetail}/${badge.badge_template.id}`}>
                      <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                      >
                        <BadgeName styles>{badge.badge_template.name.substring(0, 20)}</BadgeName>
                        <img src={badge.badge_template.image.url} alt="" width={'120px'} />
                        <BadgeOwner>{badge.badge_template.owner.name.substring(0, 10)}</BadgeOwner>
                      </div>
                    </Link>
                  ) : (
                    <Link to={`${AccreditedRole.templateBadgeDetail}/${badge.badge_template.id}`}>
                      <div
                        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                      >
                        <BadgeName styles>{badge.badge_template.name.substring(0, 20)}</BadgeName>
                        <img src={badge.badge_template.image.url} alt="" width={'120px'} />
                        <BadgeOwner>{badge.badge_template.owner.name.substring(0, 10)}</BadgeOwner>
                      </div>
                    </Link>
                  )}
                </ColStyled>
              );
            })}
        </MyRoutesRows>
      </BadgesContainer>
      <BadgesInfoContainer>
        <BadgesListSectionTitle>
          Obtén {badges.length} {badges.length > 1 ? 'insignias' : 'insignia'} al finalizar la ruta
        </BadgesListSectionTitle>
        <BadgeDateWrapper>
          <BadgesDateTitle>Fecha de inicio</BadgesDateTitle>
          {dateStart ? (
            <BadgesDate>{formatDate(dateStart)}</BadgesDate>
          ) : (
            <BadgesDate>Fecha flexible</BadgesDate>
          )}
        </BadgeDateWrapper>
        {userType !== 3 ? (
          <Button label="Asignar esta Ruta" onClick={assignRoute} />
        ) : (
          <div>{/* <Button label="Regístrate Ahora" onClick={assignRoute} /> */}</div>
        )}
      </BadgesInfoContainer>
    </AdminBadgeListContainer>
  );
};

export default AdminBadgesList;
