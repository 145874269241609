require('dotenv').config();

export const {
  REACT_APP_WEB_CLIENT_ID,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_S3_URI,
  REACT_APP_API,
  REACT_APP_SENTRY,
  REACT_APP_TRACKING_ID_GOOGLE_ANALYTICS,
  REACT_APP_HUBSPOT_REGION_ID,
  REACT_APP_HUBSPOT_PORTAL_ID,
  REACT_APP_HUBSPOT_FORM_ID,
  REACT_APP_HUBSPOT_SRC,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ACREDITTA_WEB,
  REACT_APP_ENVIRONMENT_BADGE,
} = process.env;
