import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatedThunk } from 'actions/Thunks/AuthThunk';

const AUTH = 'AUTH';

const initialState: IAuthSlice = {
  title: null,
  textBody: null,
  modal: false,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    setToggleModal(state) {
      return { ...state, modal: !state.modal };
    },
    setTermsContent(state, action: PayloadAction<{ title: string; textBody: string }>) {
      return { ...state, title: action.payload.title, textBody: action.payload.textBody };
    },
    setAuthValid(state) {
      return { ...state, isAuthenticated: true };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AuthenticatedThunk.fulfilled, (state, { payload }) => {
      state.isAuthenticated = payload;
    });
  },
});

export const { setTermsContent, setToggleModal, setAuthValid } = authSlice.actions;
export default authSlice.reducer;
