import * as React from 'react';
import Button from 'components/atoms/Button';
import { StepWizardProps } from 'react-step-wizard';
import { SectionTitle, AdminOrganizeLearningPathContainer, SectionSubTitle } from './styles';
import AdminNewLearningExperience from 'components/molecules/AdminNewLearningExperience';
import LearningExperiencesList from 'components/atoms/LearningExperiencesList';
import AdminLearningPath from 'components/molecules/AdminLearningPath';
import { useForm } from 'react-hook-form';
import { objectValidation, ValidURL } from 'utils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  CreateNewLearningExperienceThunk,
  SelectedCourseListThunk,
  UpdateLearningPathListViewThunk,
} from 'actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';
import { formatDateYMD } from 'utils';
import { removeListSelectedCourse } from 'states/slices/admin.slice';

interface AdminOrganizeLearningPathProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const AdminOrganizeLearningPath = ({ onNextStep }: AdminOrganizeLearningPathProps): JSX.Element => {
  const courseSelector = useAppSelector((state) => state.courses);

  const [showNewLearningExperienceForm, setNewLearningExperienceForm] =
    React.useState<boolean>(false);
  const [learningExperienceSelected, setLearningExperienceSelected] = React.useState<number | null>(
    null
  );
  const [canceledSelection, setCanceledSelection] = React.useState(false);

  const dispatch = useAppDispatch();

  const defaultValues: IInitialCreateNewLearningExperience = {
    name: '',
    url: '',
    description: '',
    skills: null,
    date_initial: null,
    date_finish: null,
    duration: 0,
    category_name: 0,
    organization: 0,
  };

  const getDefaultValues = (): IInitialCreateNewLearningExperience => {
    return defaultValues;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<IInitialCreateNewLearningExperience>({
    ...objectValidation,
    defaultValues: getDefaultValues(),
  });
  const [newCoursesPathOrder, setNewOrder] = React.useState<IUpdateLearningPathListView[]>([]);

  const select = (categoryID: number) => {
    reset();
    setCanceledSelection(false);
    setLearningExperienceSelected(categoryID);
    setNewLearningExperienceForm(true);
  };

  const hideNewLearningExperienceForm = () => {
    setCanceledSelection(true);
    setNewLearningExperienceForm(false);
  };

  const initListCourseSelected = React.useCallback(async () => {
    if (courseSelector.pathwayCreated) {
      const courseSelectedList = await dispatch(
        SelectedCourseListThunk(courseSelector.pathwayCreated.id)
      );
      if (SelectedCourseListThunk.rejected.match(courseSelectedList)) {
        toast.error(courseSelectedList.payload as string);
      }
    }
  }, [courseSelector.pathwayCreated, dispatch]);

  const handleSubmitNewLearningExperience = async (
    formInfo: IInitialCreateNewLearningExperience
  ) => {
    if (learningExperienceSelected && courseSelector.pathwayCreated) {
      let skills_selected: number[] = [];
      formInfo.skills?.map((skill: any) => {
        return skills_selected.push(skill.value);
      });
      const valid_url = ValidURL(formInfo.url);
      const newLearningExperience: ICreateNewLearningExperience = {
        category: learningExperienceSelected,
        date_initial: formInfo.date_initial ? formatDateYMD(formInfo.date_initial) : null,
        date_finish: formInfo.date_finish ? formatDateYMD(formInfo.date_finish) : null,
        description: formInfo.description,
        duration: formInfo.duration,
        name: formInfo.name,
        skill_tag: skills_selected,
        is_public: false,
        path: courseSelector.pathwayCreated.id,
        url: valid_url ? valid_url : '',
      };
      const createOrganization = await dispatch(
        CreateNewLearningExperienceThunk(newLearningExperience)
      );
      if (CreateNewLearningExperienceThunk.fulfilled.match(createOrganization)) {
        if (createOrganization.payload) {
          toast.success(createOrganization.payload);
          hideNewLearningExperienceForm();
        }
      } else {
        toast.error(createOrganization.payload as string);
      }
    }
  };

  const handleNewPathOrder = (path: ILearningPathListView[]) => {
    if (courseSelector.pathwayCreated && courseSelector.pathwayCreated.id) {
      let helperPath: IUpdateLearningPathListView[] = [];

      path.map((item) => {
        let helperItem: IUpdateLearningPathCourseElement[] = [];
        item.path_element.map((element, index) => {
          let newElementStructure = {
            id: element.id,
            is_required: element.is_required,
            element_parent: index === 0 ? null : item.path_element[index - 1].id,
          };
          return (helperItem = [...helperItem, newElementStructure]);
        });
        let pathItem = {
          pathway_id: courseSelector.pathwayCreated ? courseSelector.pathwayCreated.id : 0,
          order: item.order,
          path_element: helperItem,
        };
        return (helperPath = [...helperPath, pathItem]);
      });
      setNewOrder(helperPath);
    }
  };

  const handleUpdateLearningPath = async () => {
    if (newCoursesPathOrder.length > 0) {
      const update_path = await dispatch(UpdateLearningPathListViewThunk(newCoursesPathOrder));
      if (UpdateLearningPathListViewThunk.fulfilled.match(update_path)) {
        if (update_path.payload) {
          toast.success(update_path.payload);
          dispatch(removeListSelectedCourse());
          onNextStep();
        }
      } else {
        toast.error(update_path.payload as string);
      }
    } else {
      onNextStep();
    }
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    initListCourseSelected();
  }, [initListCourseSelected]);

  return (
    <div>
      <SectionTitle>
        Organiza los cursos y añade experiencias de aprendizaje a la ruta{' '}
      </SectionTitle>
      <AdminOrganizeLearningPathContainer>
        <div>
          <SectionSubTitle>Añade una experiencia de aprendizaje</SectionSubTitle>
          <LearningExperiencesList
            onSelectLearningExperience={select}
            canceledSelection={canceledSelection}
          />
          {showNewLearningExperienceForm ? (
            <AdminNewLearningExperience
              handleSubmitInfoForm={handleSubmit}
              handleSubmitNewLearningExperience={handleSubmitNewLearningExperience}
              controlInfo={control}
              errors={errors}
              onCancel={hideNewLearningExperienceForm}
              watch={watch}
            />
          ) : (
            <AdminLearningPath handleNewPathOrder={handleNewPathOrder} />
          )}
          {!showNewLearningExperienceForm && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button label="Siguiente" onClick={handleUpdateLearningPath} widthCustom={230} />
            </div>
          )}
        </div>
      </AdminOrganizeLearningPathContainer>
    </div>
  );
};

export default AdminOrganizeLearningPath;
