import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Col, Label } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const TermsAndConditionsContentContainer = styled(Col)`
  height: 500px;
  overflow-y: auto;
`;

export const TermsAndConditionsPageTitle = styled(Label)`
  width: 100%;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  text-align: center;
  padding: 40px 0px;
  color: ${PRIMARY_COLOR};
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 28px;
  }
`;

export const TermsAndConditionsContent = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: justify;
  color: ${PRIMARY_COLOR};
  margin: 10px;
`;
