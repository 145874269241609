import { BLUE_LIGHT, PRIMARY_COLOR } from 'assets/styles/colors';
import { Label, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubtitleSecondNoData = styled(Label)`
  color: ${BLUE_LIGHT};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
`;

export const LateralMenuContainer = styled.div`
  background-color: #f7f7f7;
  height: 70vw;
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
`;

export const ProfileImage = styled.img`
  margin: 20px 10px;
`;

export const NavItemStyled = styled(NavItem)`
  display: flex;
  align-items: center;
  margin: 10px 20px;
`;

export const NavLinkStyled = styled(NavLink)`
  font-size: 16px;
  line-height: 19px;
  font-family: NotoSansJPBold;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;

export const SubtitleFirst = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 20px;
`;
