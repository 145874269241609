import * as Sentry from '@sentry/react';
import { AdminUrls, API_URL } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetThemes(): Promise<any> {
  try {
    const themes = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.themes}`);

    if (themes.okay && themes.data) {
      return themes.data;
    } else {
      throw Error(themes.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetThemesOrganizartion(): Promise<any> {
  try {
    const themes = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.themesOrganization}`);

    if (themes.okay && themes.data) {
      return themes.data;
    } else {
      throw Error(themes.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function ChangeThemeOrganization(body: any): Promise<any> {
  try {
    const formData = new FormData();
    if (body.theme) formData.append('theme', body.theme);
    const themeChange = await new HttpRequest().Patch(
      `${API_URL}${AdminUrls.themesOrganization}${body.id}/`,
      formData
    );

    if (themeChange.okay && themeChange.data) {
      return 'Se ha modificado el tema ';
    } else {
      throw Error(themeChange.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function ChangeImageOrganization(body: any): Promise<any> {
  try {
    const formData = new FormData();
    if (body.file) formData.append('image', body.file);
    const themeChange = await new HttpRequest().PatchForm(
      `${API_URL}${AdminUrls.themesOrganization}${body.id}/`,
      formData
    );

    if (themeChange.okay && themeChange.data) {
      return 'Se ha modificado el tema ';
    } else {
      throw Error(themeChange.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
