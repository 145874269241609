import icons from 'assets/Icon';
import { Card } from 'reactstrap';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
`;

export const ButtonImage = styled(icons.PlayButton)``;

export const CardContainer = styled(Card)`
  cursor: pointer;
`;
