import { GetUserContactMethods } from 'actions/ApiClient/OnboardingActions';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import SimpleCheckBox from '../SimpleCheckBox';
import TextInput from '../TextInput';
import validator from 'validator';

interface ContactMethodsInterface {
  getMethodsInformation?: (value: any) => void;
  errorsInfo: any;
  onChangueContactMethod: (value: any) => void;
  defectValue: any;
}

interface ObjI {
  method: number;
  email?: string;
  phone_code?: any;
  url?: string;
}

const ContactMethods = ({
  getMethodsInformation,
  errorsInfo,
  onChangueContactMethod,
}: ContactMethodsInterface) => {
  const [methods, setMethods] = useState<any>([]);
  const [selectedMethod, setSelectedMethod] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  const getMethods = async () => {
    const res = await GetUserContactMethods();
    if (res) {
      setMethods(res);
    }
  };

  const getInputValue = (e: any) => {
    let obj = {} as ObjI;

    if (!e.target) {
      obj = {
        method: 3,
        phone_code: e,
      };
    } else {
      const { name, value } = e.target;

      if (name === 'correo') {
        if (!validator.isEmail(value)) {
          setErrors((prev: any) => ({
            ...prev,
            correo: {
              type: 'invalid',
              message: 'Debes ingresar un correo valido',
            },
          }));
          onChangueContactMethod(null);
          return;
        }
        obj = {
          method: 1,
          email: value,
        };
        setErrors({});
      } else {
        if (!validator.isURL(value)) {
          setErrors((prev: any) => ({
            ...prev,
            enlace: {
              type: 'invalid',
              message: 'Debes ingresar una página web valida',
            },
          }));
          onChangueContactMethod(null);
          return;
        }

        obj = {
          method: 2,
          url: value.includes('https://') ? value : 'https://' + value,
        };
        setErrors({});
      }
    }

    onChangueContactMethod(obj);
  };
  const onChangueFunction = (method: string) => {
    if (selectedMethod === method) {
      setSelectedMethod('');
    } else {
      setSelectedMethod(method);
    }
  };

  useEffect(() => {
    onChangueContactMethod(null);
    setErrors({});
    // eslint-disable-next-line
  }, [selectedMethod]);

  useEffect(() => {
    getMethods();
  }, []);
  return (
    <>
      <div style={{ display: 'flex', gap: '2em', marginBottom: '1em' }}>
        {methods.map((e: any) => (
          <SimpleCheckBox
            handleCheckElement={() => onChangueFunction(e.name)}
            isChecked={selectedMethod === e.name ? true : false}
            value={e.name}
            name="metodos"
            type="radio"
            onClick={() => onChangueFunction(e.name)}
          />
        ))}
      </div>
      <div>
        {selectedMethod === 'Correo' && (
          <TextInput
            placeholder="Email"
            height={40}
            type="email"
            name="correo"
            error={errors}
            onChange={(e: any) => getInputValue(e)}
          />
        )}
        {selectedMethod === 'Enlace' && (
          <TextInput
            placeholder="www."
            height={40}
            type="text"
            name="enlace"
            error={errors}
            className={errors.url ? 'error' : ''}
            onChange={(e: any) => getInputValue(e)}
          />
        )}

        {selectedMethod === 'Teléfono' && (
          <PhoneInput
            country={'co'}
            onChange={(e: any) => getInputValue(e)}
            enableSearch={true}
            countryCodeEditable={false}
            inputStyle={{
              width: '100%',
              height: '40px',
              fontSize: '15px',
              paddingLeft: '60px',
              borderRadius: '5px',
            }}
            inputProps={{
              name: 'telefono',
              required: false,
              autoFocus: false,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ContactMethods;
