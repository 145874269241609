import * as React from 'react';
import CheckEmailByInvite from 'components/organisms/CheckEmailByInvite';
import { useParams } from 'react-router-dom';

const CheckEmailByInvitePage = (): JSX.Element => {
  let { uuid } = useParams<{ uuid: string }>();

  return <CheckEmailByInvite uuid={uuid} />;
};

export default CheckEmailByInvitePage;
