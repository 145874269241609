import styled from 'styled-components';
import { BLUE_LIGHT, LIGHT, PRIMARY_COLOR } from 'assets/styles/colors';
import { Row, Col, Label } from 'reactstrap';
import { device } from 'constants/responsiveDevice';
import { S3_STATIC } from 'utils';

export const DESKTOP_IMAGE = `${S3_STATIC}login-image-desktop.jpeg`;
export const MOBILE_IMAGE = `${S3_STATIC}login-image-mobile.jpeg`;

export const LoginContainer = styled(Row)``;

export const RequestNewPasswordContainer = styled.div`
  height: 100%;
  padding: 48px 90px;

  @media ${device.mediumLaptop} {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  @media ${device.mobile} {
    padding: 48px 40px;
  }

  @media ${device.smallMobile} {
    padding: 48px 15px;
  } ;
`;

export const ColLoginContainer = styled(Col)``;

export const ColFormContainer = styled.div`
  max-width: 100%;
`;

export const LoginPageContainer = styled.div``;

export const LoginPictureContainer = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgb(0, 32, 86, 0.8) 11.2%,
      rgb(0, 32, 86, 0.8) 53.57%,
      rgb(0, 32, 86, 0.8) 79.47%,
      rgb(0, 32, 86, 0.8) 101.6%
    ),
    url(${DESKTOP_IMAGE});
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WrapperImageMobile = styled.div`
  height: 167px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 11.2%,
      rgba(255, 255, 255, 0.56) 53.57%,
      rgba(255, 255, 255, 0.97) 79.47%,
      #ffffff 101.6%
    ),
    url(${MOBILE_IMAGE});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
`;

export const LoginPictureContainerMobile = styled(Col)`
  height: 167px;
  padding: 0;
`;

export const LoginLogoPictureMainWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoginPictureWrapper = styled(Col)`
  max-width: 70%;
  height: auto;
  text-align: center;
  padding-bottom: 50px;

  @media ${device.bigMobile} {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  @media ${device.laptops} {
    max-width: 100%;
  }
`;

export const LoginDesc = styled.h1`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${BLUE_LIGHT};
  margin-bottom: 20px;
  @media ${device.mobile} {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin: 40px 0;
`;

export const MainLogoIcon = styled.img`
  width: 80%;
  object-fit: contain;
`;

export const LoginTitle = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  @media ${device.mediumLaptop} {
    font-size: 20px;
  }
`;

export const LoginWrapperCol = styled.div`
  min-height: 100vh;

  @media ${device.bigMobile} {
    min-height: auto;
  }
`;

export const ContainerSingUp = styled.div`
  text-align: center;
  margin: 40% 10%;
`;

export const TitleSingUp = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 24px;
  line-height: 41px;
  color: ${LIGHT};
  @media ${device.mediumLaptop} {
    font-size: 16px;
  }
  @media ${device.desktop} {
    font-size: 24px;
  }
`;

export const TextSingUp = styled(Label)`
  font-family: NotoSansJP, sans-serif;
  font-size: 20px;
  line-height: 41px;
  color: ${LIGHT};
  @media ${device.mediumLaptop} {
    font-size: 12px;
  }
  @media ${device.desktop} {
    font-size: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  padding: 0% 15%;
  margin-top: 65px;
  margin-bottom: 15px;
`;
