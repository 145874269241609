import * as React from 'react';
import {
  RouteContainer,
  RouteHeader,
  RouteTitle,
  RouteInfoImageContainer,
  RouteDate,
  RouteInfoContainer,
  RouteInfo,
} from './styles';
import icons from 'assets/Icon';

interface RouteCardPreviewProps {
  name: string;
  image: string;
  date: Date;
  courses: number;
  skills: number;
  duration: number;
  score: number;
}

const RouteCardPreview = ({
  name,
  image,
  date,
  courses,
  skills,
  duration,
  score,
}: RouteCardPreviewProps) => {
  return (
    <RouteContainer>
      <RouteHeader>
        <RouteDate>
          Fecha Inicio: <span>{date ? date : `Inicio Flexible`}</span>
        </RouteDate>
      </RouteHeader>
      <RouteInfoImageContainer>
        <img src={image} alt="" />
      </RouteInfoImageContainer>
      <RouteTitle>{name}</RouteTitle>
      <RouteInfoContainer>
        <RouteInfo>
          <icons.CoursesIcon />
          <span>{courses} Cursos</span>
        </RouteInfo>
        <RouteInfo>
          <icons.TimeIcon />
          <span>Duración: {duration}</span>
        </RouteInfo>
        <RouteInfo>
          <icons.SkillsIcon />
          <span>{skills} Habilidades</span>
        </RouteInfo>
      </RouteInfoContainer>
    </RouteContainer>
  );
};

export default RouteCardPreview;
