import icons from 'assets/Icon';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Input, Label } from 'reactstrap';
import styled from 'styled-components';

export const UploadMainContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const UploadFileContainer = styled.div`
  display: flex;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    margin-left: 0;
  }
`;

export const UploadFileIconWrapper = styled.div`
  margin-right: 12px;
  display: flex;
  align-self: center;
  height: 50px;
`;

export const UploadFileIcon = styled(icons.DocumentIcon)``;

export const UploadFileWrapperInput = styled.div`
  display: flex;
  align-self: center;
`;

export const UploadFileInputLabel = styled.label`
  border-radius: 15px;
  background: #ebebeb;
  padding: 4px 21px;
  cursor: pointer;
  display: inline-block;
  font-family: NotoSansJPBold;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0;
  height: 32px;

  input[type='file'] {
    display: none;
  }
`;

export const UploadFileInput = styled(Input)``;

export const LabelFileSelected = styled(Label)`
  margin-left: 10px;
  color: ${SECONDARY_COLOR};
  font-family: NotoSansJPBold;
`;

export const InvalidLabel = styled(Label)`
  color: ${SECONDARY_COLOR};
`;

export const UploadProgressWrapper = styled.div`
  width: 30%;
  padding-top: 8px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const FileSelectedWrapper = styled.div`
  display: flex;
  align-self: center;
`;
