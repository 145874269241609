import { Auth } from 'aws-amplify';
import AcredittaText from 'components/atoms/AcredittaText';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import styles from '../Certificates/styles.module.css';

const HeaderCertificates = () => {
  const handleOpen = async () => {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getIdToken().getJwtToken();
    const uri = `${ENVIRONMENT_URL_BADGE}/editorpdf/editor-certificados?token=${token}`;
    window.open(uri, '_blank');
  };

  return (
    <div className={styles.boxTitle}>
      <div className="row mt-3">
        <div className="col-8">
          <AcredittaText variant="h3" align="right">
            Edita la plantilla del certificado
          </AcredittaText>
        </div>
        <div className="col-4">
          <div
            className="d-flex"
            style={{
              height: '100%',
              marginRight: '20px',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <span onClick={handleOpen} style={{ cursor: 'pointer' }}>
              Crear certificado +
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCertificates;
