import { AdminRole } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface Props {
  data: any[];
}

const StyledThumbImage = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
`;

const TableRowData = ({ data }: Props) => {
  return (
    <>
      {data.map(
        (
          { uuid, image, name, issued_badge_count, accepted_badge_count, rejected_badge_count },
          index: number
        ) => (
          <tr key={index}>
            <td>
              <Link
                to={`${AdminRole.badgesPreviewUsers}/${encodeURIComponent(name)}/${uuid}`}
                style={{ textDecoration: 'none' }}
              >
                <StyledThumbImage src={image} /> {name}
              </Link>
            </td>
            <td className="text-center">{issued_badge_count}</td>
            <td className="text-center">{accepted_badge_count}</td>
            <td className="text-center">{rejected_badge_count}</td>
          </tr>
        )
      )}
    </>
  );
};

export default TableRowData;
