import { Col } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const CheckWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: 10px;
  color: #02b148;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 30px;
  border-radius: 100px;
  svg {
    width: 30px;
  }
`;

export const ColStyled = styled(Col)`
  @media ${device.mediumLaptop} {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  @media ${device.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media ${device.mediumMobile} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
