import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetContentOrganization,
  GetInterestCourse,
  GetOrganizationCourse,
  GetPopularCourse,
  GetRecommendedCourse,
  GetRecommendedPaths,
} from 'actions/ApiClient/AdminCatalogActions';
import { GetErrorMessage } from 'utils';

export const RecommendedCourseThunk = createAsyncThunk(
  'catalog/recommended-course',
  async (page: number, { rejectWithValue }) => {
    try {
      const RecommendedCourseT = await GetRecommendedCourse(page);
      return RecommendedCourseT;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const OrganizationCourseThunk = createAsyncThunk(
  'catalog/organization-course',
  async (page: number, { rejectWithValue }) => {
    try {
      const OrganizationCourseT = await GetOrganizationCourse(page);
      return OrganizationCourseT;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const PopularCourseThunk = createAsyncThunk(
  'catalog/popular-course',
  async (page: number, { rejectWithValue }) => {
    try {
      const PopularCourseT = await GetPopularCourse(page);
      return PopularCourseT;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const InterestCourseThunk = createAsyncThunk(
  'catalog/interest-course',
  async (page: number, { rejectWithValue }) => {
    try {
      const InterestCourseT = await GetInterestCourse(page);
      return InterestCourseT;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetRecommendedPathsThunk = createAsyncThunk(
  'catalog/recommended-paths',
  async (_, { rejectWithValue }) => {
    try {
      const RecommendedPaths = await GetRecommendedPaths();
      return RecommendedPaths;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);

export const GetContentOrganizationThunk = createAsyncThunk(
  'catalog/content-organization',
  async (_, { rejectWithValue }) => {
    try {
      const OrganizationContent = await GetContentOrganization();
      return OrganizationContent;
    } catch (error) {
      return rejectWithValue(GetErrorMessage(error));
    }
  }
);
