export const AuthNavigations = {
  login: '/iniciar-sesion',
  singup: '/registrarse',
  recovery: '/recuperar-cuenta',
  codeSingUp: '/codigo-cuenta',
  unauthorized: '/no-autorizado',
  welcome: '/bienvenida',
  expiredCode: '/codigo-vencido',
};

export const AuthenticatedUrls = {
  root: '/',
  terms: '/terminos-politicas',
};

export const NonAuthenticatedUrls = {
  termsAndConditions: '/terminos-condiciones',
  policies: '/politicas-privacidad',
  acceptInvite: '/aceptar-invitacion',
  badges: '/insignia-detalle-publica',
  path: '/ruta',
  badgesBack: '/insignias/public',
  url_badges: '/insignias/public',
  badges_template: '/insignia-template-detalle',
  organization_badges: '/organizacion-insignias',
  end_path: '/finalizacion/ruta',
};

export const AccreditedRole = {
  profile: '/acreditado/perfil',
  editProfile: '/acreditado/editar-perfil',
  onBoarding: '/acreditado/personalizar-experiencia',
  badgesDashboard: '/acreditado/insignias',
  badgeDetails: '/acreditado/insignia-detalle',
  badgesPreview: '/acreditado/badges',
  catalogCourse: '/acreditado/catalog',
  newPathWizz: '/acreditado/new-path',
  learningPathPreview: '/acreditado/ruta',
  coursePreview: '/pathways/course-dashboard',
  organizationBadgeDetail: '/acreditado/insignia-organizacion-detalle',
  organizationBadges: '/acreditado/insignias-organizacion',
  pathPreview: '/acreditado/paths',
  catalogPlus: '/acreditado/catalog-plus',
  editPath: '/acreditado/editar-ruta',
  editPerfil: '/acreditado/edit-profile',
  templateBadgeDetail: '/acreditado/detalle-insignia',
  templateInfo: '/acreditado/template-info',
  badgesStackableDetail: '/acreditado/insignias-apilables',
};

export const AdminRole = {
  dashboard: '/admin/dashboard',
  profile: '/admin/perfil',
  loadUsers: '/admin/usuarios',
  loadNewUsers: '/admin/agregar-usuario',
  loadMassiveUsers: '/admin/agregar-usuarios-masivo',
  uploadUsersOptions: '/admin/tipo-de-carga',
  organizationNewLearningPath: '/admin/crear-nueva-ruta',
  home: '/admin/home',
  onboardingRoot: '/admin/primeros-pasos',
  editPath: '/admin/editar-ruta',
  newPathWizz: '/admin/new-path',
  pathPreview: '/admin/paths',
  badgesPreview: '/admin/badges',
  badgesPreviewUsers: '/admin/insignias/usuarios',
  learningPathPreviewAcreditado: '/acreditado/ruta',
  learningPathPreview: '/admin/ruta',
  coursePreview: '/pathways/course-dashboard',
  catalogCourse: '/admin/catalog',
  successEnd: '/admin/success',
  successEndPath: '/admin/success-path',
  assingRoute: '/admin/asignar-ruta',
  assingBadgesPaths: '/admin/asignar-insignia',
  createCourse: '/admin/create-course',
  badgesDashboard: '/admin/insignias',
  badgeDetails: '/admin/insignia-detalle',
  createBadge: '/admin/crear-insignia',
  editBadge: '/admin/editar-insignia',
  duplicateBadgeUrl: '/admin/duplicar-insignia',
  badgeIssue: '/admin/insignia-emitir',
  organizationBadges: '/admin/insignias-organizacion',
  organizationBadgeDetail: '/admin/insignia-organizacion-detalle',
  templateBadgeDetail: '/admin/detalle-insignia',
  issueMasive: '/admin/emitir-masivo',
  editPerfil: '/admin/edit-profile',
  configureOrganization: '/admin/configurar-organizacion',
  analytics: '/admin/estadisticas',
  analyticsReports: '/admin/estadisticas/analisis-insignias',
  templateInfo: '/admin/template-info',
  badgesStackable: '/admin/insignias-apilables',
  badgesStackableDetail: '/admin/insignias-apilables',
  badgeEmit: '/admin/emitir',
  testing: '/admin/testing',
  prueba: '/admin/deprueba',
};

export const getBadgeStackableDetailAdmin = (id: string) => {
  return `${AdminRole.badgesStackableDetail}/${id}`;
};

export const getBadgeStackableDetailAcreditado = (id: string) => {
  return `${AccreditedRole.badgesStackableDetail}/${id}`;
};

export const seeMoreInformationAdmin = (id: string) => {
  return `${AdminRole.templateInfo}/${id}`;
};

export const seeMoreInformationAcreditado = (id: string) => {
  return `${AccreditedRole.templateInfo}/${id}`;
};

export const badgeEmitPath = (id: string) => {
  return `${AdminRole.badgeEmit}/${id}`;
};
