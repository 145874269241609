import * as React from 'react';
import Route from 'components/atoms/Route';
import {
  MyRoutesRows,
  MyRoutesContainer,
  MyRoutesTitle,
  ColStyled,
  ContainerNoData,
  ButtonWrapper,
} from './styles';
import { toast } from 'react-hot-toast';
import { ListRoutesThunk } from 'actions/Thunks/AcreditedThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { Link } from 'react-router-dom';
import { AccreditedRole } from 'constants/NavigationUrls';
import ReactLoading from 'react-loading';
import icons from 'assets/Icon';
import Button from 'components/atoms/Button';

const MyRoutes: React.FC = () => {
  const dispatch = useAppDispatch();

  const routes = useAppSelector((state) => state.listMyRoutes);
  const getListPathsData = React.useCallback(async () => {
    const paths = await dispatch(ListRoutesThunk());
    if (ListRoutesThunk.rejected.match(paths)) {
      toast.error(paths.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    getListPathsData();
  }, [getListPathsData]);
  return (
    <MyRoutesContainer>
      <React.Fragment>
        <MyRoutesTitle>Rutas Activas</MyRoutesTitle>
        {routes.listMyRoutes !== null ? (
          routes.listMyRoutes.length !== 0 ? (
            <MyRoutesRows>
              {routes.listMyRoutes.map((route: any, indexRoute: any) => {
                return (
                  <ColStyled md="3" sm="4" xs="12" key={indexRoute}>
                    <Link to={`${AccreditedRole.learningPathPreview}/${route.id}`}>
                      <Route
                        name={route.name}
                        image={route.photo}
                        date={route.duration}
                        courses={route.courses}
                        skills={route.skill_count}
                      />
                    </Link>
                  </ColStyled>
                );
              })}
            </MyRoutesRows>
          ) : (
            <ContainerNoData
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <icons.Icono_rutas
                style={{
                  width: '20%',
                }}
              />
              <h3>
                No tienes rutas activas. ¡Crea una ruta de aprendizaje y comienza a desarrollarla!
              </h3>
              <ButtonWrapper>
                <Link to={AccreditedRole.newPathWizz}>
                  <Button label={'Crear una ruta'} widthCustom={300} />
                </Link>
              </ButtonWrapper>
            </ContainerNoData>
          )
        ) : (
          <ContainerNoData
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '2%',
            }}
          >
            <ReactLoading type="spin" color={'#002057'} height={80} width={80} />
          </ContainerNoData>
        )}
      </React.Fragment>
    </MyRoutesContainer>
  );
};

export default MyRoutes;
