import AcredittaRequest from 'actions/requests/api';

/**
 * Get images template available
 * @returns Promise
 */
export const getImageTemplates = async () => {
  return await AcredittaRequest.get(`pdf/templates/`);
};

/**
 * Upload signature photo
 * @param formData
 * @returns Promise
 */
export const uploadSignaturePhoto = async (formData: FormData) => {
  return await AcredittaRequest.post(`pdf/signatures/`, formData);
};

/**
 * Store organization templates certificates
 * @param payload
 * @returns Promise
 */
export const storeOrganizationTemplateCertificates = async (payload: any) => {
  return await AcredittaRequest.post(`pdf/certificates/templates/`, payload);
};

/**
 * Update organization templates certificates
 * @param payload
 * @returns Promise
 */
export const updateOrganizationTemplateCertificates = async (uuid: string, payload: any) => {
  return await AcredittaRequest.patch(`pdf/certificates/templates/${uuid}/`, payload);
};

/**
 * Get info certificates pdf template
 * @returns Promise
 */
export const getCertifatesPdfTemplateInfo = async () => {
  return await AcredittaRequest.get(`pdf/certificates/templates/`);
};

/**
 * Update photo and name signature
 * @params id, payload
 * @returns Promise
 */
export const updatePhotoSignature = async (id: number, payload: FormData) => {
  return await AcredittaRequest.patch(`pdf/signatures/${id}/`, payload);
};

/**
 * Get certificado PDF
 * @params uuid
 * @returns Promise
 */
export const dowloadCertificatePdf = async (uuid: string) => {
  return await AcredittaRequest.get(`pdf/badges/certificates/?badge_uuid=${uuid}`, {
    responseType: 'blob',
  });
};

/**
 *
 * @param uuid
 * @returns
 */
export const validateBadgeCertificate = async (uuid: string) => {
  return await AcredittaRequest.get(`pdf/validate-badge-certificate/?badge_uuid=${uuid}`);
};
