import * as React from 'react';
import WelcomePage from 'components/organisms/Welcome';

const Login = (): JSX.Element => {
  return (
    <React.Fragment>
      <WelcomePage />
    </React.Fragment>
  );
};

export default Login;
