import { NonAuthenticatedUrls } from 'constants/NavigationUrls';
import * as React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { FooterBadgesContainer, FooterBadgesNavLink, FooterBadgesTitle } from './styles';
import { termsConditions } from 'constants/index';

const FooterBadges: React.FC = () => {
  return (
    <FooterBadgesContainer>
      <FooterBadgesTitle>Insignias Digitales</FooterBadgesTitle>
      <Nav vertical>
        <NavItem>
          <FooterBadgesNavLink to="#">Educación</FooterBadgesNavLink>
        </NavItem>
        <NavItem>
          <FooterBadgesNavLink to="#">Corporativo</FooterBadgesNavLink>
        </NavItem>
        <NavItem>
          <FooterBadgesNavLink to="#">Trayectos de Aprendizaje</FooterBadgesNavLink>
        </NavItem>
        <NavItem>
          <FooterBadgesNavLink to="#">Preguntas Frecuentes</FooterBadgesNavLink>
        </NavItem>
        <NavItem>
          <FooterBadgesNavLink to={termsConditions}>Términos y Condiciones</FooterBadgesNavLink>
        </NavItem>
        <NavItem>
          <FooterBadgesNavLink to={NonAuthenticatedUrls.policies}>
            Políticas de Privacidad
          </FooterBadgesNavLink>
        </NavItem>
      </Nav>
    </FooterBadgesContainer>
  );
};

export default FooterBadges;
