import * as React from 'react';
import CoursesCardsPathwayDescription from 'components/molecules/CoursesCardPathwayDescription';
import icons from 'assets/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import CommonPageLayout from '../Layout/CommonPageLayout';
import RouteCardPreview from 'components/molecules/RouteCardPreview';
import {
  DetailPathwayDashboardThunk,
  AssignLearningPathThunk,
} from '../../../actions/Thunks/AdminThunk';
import { RecommendedCourseThunk } from 'actions/Thunks/AdminCatalogThunk';
import { toast } from 'react-hot-toast';
import {
  RecomendedCoursesLabel,
  RecomendedCoursesContainer,
  AssignRouteContainer,
  AssignHeader,
  AssignHeaderLabel,
  AssignUsersTypesContainer,
  AssignUsersContainer,
  SelectionContainer,
  ButtonWrapper,
  SelectedUsersContainer,
} from './styles';
import Button from 'components/atoms/Button';
import InputSelect from 'components/atoms/InputSelect';
import { GetUsersRelatedOrganizationThunk } from 'actions/Thunks/AdminThunk';
import { arrayObjectIndexOf } from 'utils';
import SkillButton from 'components/atoms/SkillButton';
import { useHistory } from 'react-router';
import AdminCreateUser from 'components/organisms/AdminCreateUser';

interface AssignRoutesAssignRoutesProps {
  routeID: string;
}

const AssignRoutesAssignRoutes = ({ routeID }: AssignRoutesAssignRoutesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const courseSelector = useAppSelector((state) => state.courses);

  const [selected] = React.useState<string>('Usuario');
  const [initialOptionsUsers, setOptionsUsers] = React.useState<any[] | null>(null);
  const [usersSelected, setUsers] = React.useState<ISelectOptionValue[]>([]);

  const user = useAppSelector((state) => state.user);
  const courses = useAppSelector((state) => state.catalogCourses);

  const pathwayDashboard = React.useCallback(async () => {
    const dashboard = await dispatch(DetailPathwayDashboardThunk(parseInt(routeID, 10)));
    if (DetailPathwayDashboardThunk.rejected.match(dashboard)) {
      toast.error(dashboard.payload as string);
    }
  }, [dispatch, routeID]);

  const getOrganizationUsers = React.useCallback(async () => {
    if (user.adminInfo) {
      const users = await dispatch(GetUsersRelatedOrganizationThunk(user.adminInfo.id));
      if (GetUsersRelatedOrganizationThunk.rejected.match(users)) {
        toast.error(users.payload as string);
      } else {
        let users_array: ISelectOptionValue[] = [];
        users.payload.map((user) => {
          let newFormat_user = {
            value: user.id,
            label: user.email,
          };
          return (users_array = [...users_array, newFormat_user]);
        });
        setOptionsUsers(users_array);
      }
    }
  }, [user.adminInfo, dispatch]);

  const getCoursesRecommendedData = React.useCallback(async () => {
    const recomended = await dispatch(RecommendedCourseThunk(0));
    if (RecommendedCourseThunk.rejected.match(recomended)) {
      toast.error(recomended.payload as string);
    }
  }, [dispatch]);

  const setUsersSelected = (user: ISelectOptionValue) => {
    if (usersSelected) {
      let userHelper = usersSelected;
      const verifiedUser = userHelper.find((userFiltered) => userFiltered.label === user.label);
      if (!verifiedUser) {
        userHelper = [...userHelper, user];
        setUsers(userHelper);
      }
    }
  };

  const deleteUserSelected = (user: ISelectOptionValue) => {
    if (usersSelected) {
      let userHelper = usersSelected;
      const index = arrayObjectIndexOf(userHelper, user.label, 'label');
      if (index > -1) {
        setUsers(userHelper.filter((userFilter) => userFilter.label !== user.label));
      }
    }
  };

  const onSubmitNewUsers = async () => {
    if (usersSelected) {
      let usersHelper: number[] = [];
      usersSelected.map((user) => {
        return usersHelper.push(user.value);
      });

      let formInfo: IAssingUsersForm = {
        users: {
          user: usersHelper,
        },
        route_id: parseInt(routeID, 10),
      };

      const add_users = await dispatch(AssignLearningPathThunk(formInfo));

      if (AssignLearningPathThunk.fulfilled.match(add_users)) {
        toast(add_users.payload as string);
      } else {
        toast.error(add_users.payload as string);
      }
    }
  };

  React.useEffect(() => {
    pathwayDashboard();
    getOrganizationUsers();
    getCoursesRecommendedData();
  }, [pathwayDashboard, getOrganizationUsers, getCoursesRecommendedData]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showCommon={true}
      showOnboarding={false}
    >
      {initialOptionsUsers && initialOptionsUsers.length > 1 ? (
        <div>
          <AssignHeader>
            <icons.GoBackIcon onClick={history.goBack} />
            <AssignHeaderLabel>Asigna esta ruta a un usuario</AssignHeaderLabel>
          </AssignHeader>
          {courseSelector && courseSelector.pathwayDetailGeneralContent && (
            <AssignRouteContainer>
              <RouteCardPreview
                name={courseSelector.pathwayDetailGeneralContent.name}
                image={courseSelector.pathwayDetailGeneralContent.photo}
                date={courseSelector.pathwayDetailGeneralContent.start_date}
                courses={courseSelector.pathwayDetailGeneralContent.course_count}
                skills={
                  courseSelector.pathwayDetailSkillsContent !== null
                    ? courseSelector.pathwayDetailSkillsContent?.length
                    : 0
                }
                duration={courseSelector.pathwayDetailGeneralContent.duration}
                score={5}
              />
              <AssignUsersContainer>
                <AssignUsersTypesContainer>
                  {/* <AssignUser
                    onClick={() => setUploadSelection('Usuario')}
                    $selected={selected === 'Usuario'}
                  >
                    <icons.UserIcon />
                    <span>Usuario</span>
                  </AssignUser>
                  <AssignUser
                    onClick={() => setUploadSelection('Equipo')}
                    $selected={selected === 'Equipo'}
                  >
                    <icons.UsersIcon />
                    <span>Equipo</span>
                  </AssignUser> */}
                </AssignUsersTypesContainer>
                <SelectionContainer>
                  <span>{selected}</span>
                  <InputSelect
                    onChange={(value) => setUsersSelected(value)}
                    value={{}}
                    options={selected === 'Usuario' ? initialOptionsUsers : initialOptionsUsers}
                    placeholder="Buscar..."
                    error={{}}
                    name="users"
                  />
                </SelectionContainer>
                <SelectedUsersContainer>
                  {usersSelected &&
                    usersSelected.map((user, userIndex) => {
                      return (
                        <SkillButton
                          key={userIndex}
                          skill={user.label}
                          handleSkill={() => deleteUserSelected(user)}
                        />
                      );
                    })}
                </SelectedUsersContainer>
                <ButtonWrapper>
                  <Button label="Asignar" widthCustom={150} onClick={onSubmitNewUsers} />
                </ButtonWrapper>
              </AssignUsersContainer>
            </AssignRouteContainer>
          )}
          {courses.recommendedCourseT && (
            <RecomendedCoursesContainer>
              <RecomendedCoursesLabel>
                Otros usuarios también vieron estos cursos
              </RecomendedCoursesLabel>
              {courses.recommendedCourseT !== null && courses.recommendedCourseT !== undefined ? (
                <CoursesCardsPathwayDescription
                  courses={courses.recommendedCourseT.results.slice(0, 5)}
                  allowSelection={false}
                  section={false}
                />
              ) : (
                <h1>Loading...</h1>
              )}
            </RecomendedCoursesContainer>
          )}
        </div>
      ) : (
        <AdminCreateUser showHeader={false} onSuccess={() => getOrganizationUsers()} />
      )}
    </CommonPageLayout>
  );
};

export default AssignRoutesAssignRoutes;
