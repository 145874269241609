import * as React from 'react';
import {
  ModalItemContainer,
  ModalItemLabel,
  ModalItemSubLabel,
  ModalLabelsContainer,
  ModalIconContainer,
  ModalFooterStyled,
  ModalHeaderStyled,
  ModalBodyStyled,
  ModalBodyStyledCredly,
  ModalContainer,
  ModalIcons,
  ModalText,
  ModalTextFooter,
  ModalTitle,
} from './styles';
import { Modal } from 'reactstrap';
import icons from 'assets/Icon';
import { VerifyBadgeThunk } from 'actions/Thunks/UnAuthUsersThunks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import ReactLoading from 'react-loading';
import LogoCredly from 'assets/images/Rectangle.png';
import ButtonCom from 'components/atoms/ButtonCom';
import Button from 'components/atoms/Button';

interface VerifyBadgeModalProps {
  badge_id: string;
  showModal: boolean;
  handleModal: () => void;
}

const VerifyBadgeModal = ({
  showModal,
  handleModal,
  badge_id,
}: VerifyBadgeModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [badgeInfo, setBadgeInfo] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [failed, setFailed] = React.useState<boolean>(false);
  const [modal, setModal] = React.useState(false);
  const [loadingFocus, setLoadingFocus] = React.useState<boolean>(false);

  const user = useAppSelector((state) => state.user);

  const verifyBadge = React.useCallback(async () => {
    const badge_info = await dispatch(VerifyBadgeThunk(badge_id));
    if (VerifyBadgeThunk.rejected.match(badge_info)) {
      setFailed(true);
    } else {
      setLoading(false);
      setBadgeInfo(badge_info.payload);
    }
  }, [badge_id, dispatch]);

  const toggleCredly = () => {
    setModal(!modal);
  };

  const hideScreen = () => {
    handleModal();
  };

  const onFocus = React.useCallback(async () => {
    verifyBadge();
    setLoadingFocus(false);
  }, [verifyBadge]);

  const onBlur = React.useCallback(async () => {
    setLoadingFocus(true);
    verifyBadge();
  }, [verifyBadge]);

  React.useEffect(() => {
    verifyBadge();
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [verifyBadge, onFocus, onBlur]);

  return (
    <div>
      <Modal isOpen={showModal} toggle={hideScreen} centered={true}>
        {badgeInfo ? (
          <div>
            {loading ? (
              <ModalHeaderStyled toggle={hideScreen}>Verificando la Insignia</ModalHeaderStyled>
            ) : (
              <ModalHeaderStyled toggle={hideScreen}>Insignia verificada</ModalHeaderStyled>
            )}
            <ModalBodyStyled>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Organización emisora:</ModalItemLabel>
                  {loading ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : badgeInfo.issuer_organization ? (
                    <ModalItemSubLabel>{badgeInfo.issuer_organization}</ModalItemSubLabel>
                  ) : (
                    <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                  )}
                </ModalLabelsContainer>
                <ModalIconContainer verified={badgeInfo.issuer_organization}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Fecha de emisión:</ModalItemLabel>
                  {loading ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : badgeInfo.issued_at ? (
                    <ModalItemSubLabel>{badgeInfo.issued_at}</ModalItemSubLabel>
                  ) : (
                    <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                  )}
                </ModalLabelsContainer>
                <ModalIconContainer verified={badgeInfo.issued_at}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Emitida a:</ModalItemLabel>
                  {loading ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : badgeInfo.issued_to ? (
                    <ModalItemSubLabel>{badgeInfo.issued_to}</ModalItemSubLabel>
                  ) : (
                    <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                  )}
                </ModalLabelsContainer>
                <ModalIconContainer verified={badgeInfo.issued_to}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Fecha de aceptación:</ModalItemLabel>
                  {loading ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : badgeInfo.accepted_at ? (
                    <ModalItemSubLabel>{badgeInfo.accepted_at.substring(10, 0)}</ModalItemSubLabel>
                  ) : (
                    <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                  )}
                </ModalLabelsContainer>
                <ModalIconContainer verified={badgeInfo.accepted_at}>
                  {loadingFocus === true ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : (
                    <icons.CheckIcon />
                  )}
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Emitida con:</ModalItemLabel>
                  {loading ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : badgeInfo.provider ? (
                    <ModalItemSubLabel>{badgeInfo.provider}</ModalItemSubLabel>
                  ) : (
                    <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                  )}
                </ModalLabelsContainer>
                <ModalIconContainer verified={badgeInfo.provider}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Ultima actualización:</ModalItemLabel>
                  {loading ? (
                    <ReactLoading type="spin" color={'#002057'} />
                  ) : badgeInfo.updated_at ? (
                    <ModalItemSubLabel>{badgeInfo.updated_at}</ModalItemSubLabel>
                  ) : (
                    <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                  )}
                </ModalLabelsContainer>
                <ModalIconContainer verified={badgeInfo.updated_at}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
            </ModalBodyStyled>
            <ModalFooterStyled>¡Verificada!</ModalFooterStyled>
            {loadingFocus === true ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <ReactLoading type="spin" color={'#002057'} height={50} width={50} />
              </div>
            ) : (
              badgeInfo.accepted_at === null && (
                <ModalItemSubLabel
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <icons.Icono_Alerta /> ¡No has aceptado la insignia! Ha
                  <div>
                    z <strong onClick={() => toggleCredly()}>click aquí</strong> y a
                  </div>
                  ceptala
                </ModalItemSubLabel>
              )
            )}
            <br />
          </div>
        ) : failed ? (
          <div>
            <ModalHeaderStyled toggle={hideScreen}>
              No se ha podido verificar la insignia
            </ModalHeaderStyled>
            <ModalBodyStyled>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Organización emisora:</ModalItemLabel>
                  <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                </ModalLabelsContainer>
                <ModalIconContainer verified={false}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Fecha de emisión:</ModalItemLabel>
                  <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                </ModalLabelsContainer>
                <ModalIconContainer verified={false}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Emitida a:</ModalItemLabel>
                  <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                </ModalLabelsContainer>
                <ModalIconContainer verified={false}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Fecha de aceptación:</ModalItemLabel>
                  <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                </ModalLabelsContainer>
                <ModalIconContainer verified={false}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Emitida con:</ModalItemLabel>
                  <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                </ModalLabelsContainer>
                <ModalIconContainer verified={false}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
              <ModalItemContainer>
                <ModalLabelsContainer>
                  <ModalItemLabel>Ultima actualización:</ModalItemLabel>
                  <ModalItemSubLabel>No hay datos</ModalItemSubLabel>
                </ModalLabelsContainer>
                <ModalIconContainer verified={false}>
                  <icons.CheckIcon />
                </ModalIconContainer>
              </ModalItemContainer>
            </ModalBodyStyled>
          </div>
        ) : (
          loading && <ReactLoading type="spin" color={'#002057'} />
        )}
      </Modal>
      <ModalContainer
        isOpen={modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyledCredly>
          <ModalTitle>¡Todo listo!</ModalTitle>
          <ModalIcons>
            <icons.Icono_Acreditta
              style={{
                width: '30%',
                marginRight: '20px',
              }}
            />
            <ReactLoading type="bars" color={'#002057'} height={100} width={100} />
            <img
              src={LogoCredly}
              alt="img"
              style={{
                width: '30%',
                marginLeft: '20px',
              }}
            />
          </ModalIcons>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5%',
            }}
          >
            <ModalText>
              Te enviaremos a la página principal de <strong>Credly</strong> para que puedas aceptar
              tu insignia digital.
            </ModalText>
            {user.userRole !== null ? (
              user.userRole === 3 ? (
                <ModalText>
                  Recuerda <strong>iniciar sesión</strong> con el correo{' '}
                  <strong>{user.userInfo?.email}</strong>.
                </ModalText>
              ) : (
                <ModalText>
                  Recuerda <strong>iniciar sesión</strong> con el correo donde te emitieron la
                  insignia.
                </ModalText>
              )
            ) : (
              <ModalText>
                Recuerda <strong>iniciar sesión</strong>
              </ModalText>
            )}
            <ModalTextFooter>
              Una vez hayas creado la insignia no olvides regresar a nuestra plataforma
            </ModalTextFooter>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <Button
                label="Cancelar"
                widthCustom={150}
                lightMode={true}
                onClick={() => toggleCredly()}
                style={{
                  padding: '5%',
                }}
              />
              <a
                href={`https://www.credly.com/earner/earned/badge/${badge_id}`}
                target="_blank"
                rel="noreferrer"
                onClick={() => toggleCredly()}
                style={{
                  padding: '5%',
                }}
              >
                <ButtonCom label={'Ir a Credly'} widthCustom={200} />
              </a>
            </div>
          </div>
        </ModalBodyStyledCredly>
      </ModalContainer>
    </div>
  );
};

export default VerifyBadgeModal;
