import React from 'react';
import {
  UploadSelectedType,
  BadgesUploadTypeWrapper,
  WarningImageText,
  BadgeImage,
  BadgeImageWrapper,
  BadgeCoverIconContainer,
  WarningContainer,
  ButtonWrapper,
  InputFileLabel,
  ErrorMessageLabel,
} from './styles';
import icons from 'assets/Icon';
import { Controller } from 'react-hook-form';
import { Input } from 'reactstrap';
import toast from 'react-hot-toast';
import { ImageValidation } from 'utils';
import { UseFormWatch, Control } from 'react-hook-form';
import { useAppSelector } from 'hooks/redux';

interface AdminEditPerfilInfoImage {
  watch: UseFormWatch<IFormEditProfie>;
  control: Control<IFormEditProfie>;
  errors: Record<string, any>;
}

const AdminEditPerfilInfoImageForm = ({
  watch,
  control,
  errors,
}: AdminEditPerfilInfoImage): JSX.Element => {
  const file = watch().avatar.image;

  const user = useAppSelector((state) => state.user);

  const selectedPicture = React.useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    } else if (user) {
      return `${user.userInfo?.avatar}`;
    }
  }, [file, user]);

  return (
    user && (
      <BadgesUploadTypeWrapper>
        <UploadSelectedType>
          {user.userInfo?.first_name + ' ' + user.userInfo?.last_name}
        </UploadSelectedType>
        <BadgeCoverIconContainer>
          <BadgeImageWrapper>
            <BadgeImage src={selectedPicture} alt="imagen-organizacion" />
          </BadgeImageWrapper>
          <Controller
            control={control}
            render={({ field: { onChange } }) => (
              <ButtonWrapper>
                <InputFileLabel>
                  <Input
                    name="photo"
                    onChange={async (value) => {
                      const files = value.target.files;
                      const fileList: File[] = Array.prototype.slice.call(files);

                      if (await ImageValidation(fileList)) {
                        return onChange(fileList[0]);
                      } else {
                        toast.error(
                          'La imagen debe cumplir con los criterios de formato y dimensión'
                        );
                      }
                    }}
                    type="file"
                    accept="image/*"
                  />
                  <span>Cargar Foto</span>
                </InputFileLabel>
                {errors['image']?.type && (
                  <ErrorMessageLabel>{errors['image']?.message}</ErrorMessageLabel>
                )}
              </ButtonWrapper>
            )}
            name="avatar.image"
          />
          <WarningContainer>
            <icons.CheckIcon />
            <WarningImageText>
              Imagen formato PNG/JPG con dimensión máxima de 1200x1200 pixeles
            </WarningImageText>
          </WarningContainer>
          <WarningContainer>
            <icons.CheckIcon />
            <WarningImageText>Esta imagen será la foto de portada de tu insignia</WarningImageText>
          </WarningContainer>
        </BadgeCoverIconContainer>
      </BadgesUploadTypeWrapper>
    )
  );
};

export default AdminEditPerfilInfoImageForm;
