import ProgressMenu from 'components/molecules/ProgressMenu';
import * as React from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
// import AdminCourseCatalog from '../AdminCourseCatalog';
// import AdminCreateDnD from '../AdminCreateDnD';
// import AdminCreateNewLearningPath from '../AdminCreateNewLearningPath';
// import AdminEndStepProcess from '../AdminEndStepProcess';
import AdminOrganizationProfile from '../AdminOrganizationProfile';
import AdminOrganizationSkills from '../AdminOrganizationSkills';
// import AdminOrganizeLearningPath from '../AdminOrganizeLearningPath';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { ColProgressMenuWrapper } from './styles';
import { useAppSelector } from 'hooks/redux';
// import AdminCoursesUploadSection from '../AdminCoursesUploadSection';
import { useHistory } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
// import { dispatch } from 'react-hot-toast/dist/core/store';

const AdminOnboardingWizard = (): JSX.Element => {
  const history = useHistory();

  const [wizardInstance, setWizardInstance] = React.useState<Partial<StepWizardChildProps>>();
  const [currentStep, setCurrentStep] = React.useState<number>(1);

  const user = useAppSelector((state) => state.user);

  const onNextStep = () => {
    if (wizardInstance && wizardInstance.nextStep) {
      wizardInstance.nextStep();
      if (wizardInstance.currentStep) setCurrentStep(wizardInstance.currentStep);
    }
  };

  const persistStep = React.useCallback(() => {
    if (user.userInfo) {
      if (wizardInstance && wizardInstance.goToStep) {
        const onboarding = user.userInfo.onboarding;

        if (onboarding.organization_created && onboarding.interest_created) {
          history.push(AdminRole.catalogCourse);
        } else {
          if (onboarding.organization_created) {
            wizardInstance.goToStep(2);
            setCurrentStep(2);
          } else {
            wizardInstance.goToStep(1);
            setCurrentStep(1);
          }
        }
      }
    }
  }, [user.userInfo, wizardInstance, history]);

  React.useEffect(() => {
    persistStep();
  }, [persistStep]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={false}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={true}
    >
      {currentStep && (
        <ColProgressMenuWrapper sm="12">
          <ProgressMenu
            selected={currentStep}
            uploadOwnCourses={user.adminInfo ? user.adminInfo.own_courses : false}
          />
        </ColProgressMenuWrapper>
      )}
      {user.adminInfo && user.adminInfo.own_courses ? (
        <StepWizard instance={setWizardInstance} isLazyMount={true}>
          <AdminOrganizationProfile onNextStep={onNextStep} />
          <AdminOrganizationSkills onNextStep={onNextStep} />
          {/* <AdminCoursesUploadSection onNextStep={onNextStep} /> */}
          {/* <AdminCourseCatalog onNextStep={onNextStep} /> */}
          {/* <AdminCreateDnD onNextStep={onNextStep} /> */}
          {/* <AdminOrganizeLearningPath onNextStep={onNextStep} /> */}
          {/* <AdminCreateNewLearningPath onNextStep={onNextStep} /> */}
          {/* <AdminEndStepProcess /> */}
        </StepWizard>
      ) : (
        <StepWizard instance={setWizardInstance} isLazyMount={true}>
          <AdminOrganizationProfile onNextStep={onNextStep} />
          <AdminOrganizationSkills onNextStep={onNextStep} />
          {/* <AdminCourseCatalog onNextStep={onNextStep} /> */}
          {/* <AdminCreateDnD onNextStep={onNextStep} /> */}
          {/* <AdminOrganizeLearningPath onNextStep={onNextStep} /> */}
          {/* <AdminCreateNewLearningPath onNextStep={onNextStep} /> */}
          {/* <AdminEndStepProcess /> */}
        </StepWizard>
      )}
    </CommonPageLayout>
  );
};

export default AdminOnboardingWizard;
