import * as React from 'react';
import {
  GetContentOrganizationThunk,
  GetRecommendedPathsThunk,
  InterestCourseThunk,
  OrganizationCourseThunk,
  PopularCourseThunk,
  RecommendedCourseThunk,
} from 'actions/Thunks/AdminCatalogThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  BarSearchContainer,
  ColStyled,
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  MyRoutesRows,
  RouteContainerFirst,
  RowStyled,
  SubtitleFirst,
  SubtitleSecond,
  TitleCard,
} from './styles';
import toast from 'react-hot-toast';
import AdminCourseCatalog from '../AdminCourseCatalogSeeMore';
import {
  AdminCourseCatalogFilterOptionsThunk,
  AdminCourseCatalogThunk,
} from 'actions/Thunks/AdminThunk';
import { Controller, useForm } from 'react-hook-form';
import { objectValidation } from 'utils';
import { defaultCourseSelection } from 'states/slices/course.slice';
import icons from 'assets/Icon';
import TextInput from 'components/atoms/TextInput';
import {
  ButtonWrapper,
  ContainerNoData,
  ContainNoData,
  IconFilterWrapper,
  SearchIconContainer,
  SubtitleFirstNoData,
  SubtitleSecondNoData,
} from '../AdminCourseCatalogSeeMore/styles';
import CoursesCardsUnSelect from 'components/molecules/CoursesCardsUnselect';
import CourseFilters from 'components/molecules/CourseFilters';
import ReactLoading from 'react-loading';
import ButtonCom from 'components/atoms/ButtonCom';
import Pagination from 'components/molecules/Pagination';
import { Link } from 'react-router-dom';
import { AccreditedRole } from 'constants/NavigationUrls';
import Route from 'components/atoms/Path';
import CourseCard from 'components/atoms/CourseCardCatalog';

const AdminHomeCourseCatalog: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const admin = useAppSelector((state) => state.admin);
  const user = useAppSelector((state) => state.user);

  const [toggleFilter, setToggleFilter] = React.useState<boolean>(false);
  const [seeMore, setSeeMore] = React.useState<boolean>(false);
  const [seeMoreData, setSeeMoreData] = React.useState<any>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [stateFilter, setStateFilter] = React.useState<boolean>(false);
  const [wordSearch, setWordSearch] = React.useState<string>('');
  const [dataPaths, setDataPaths] = React.useState<any>(null);
  const [dataContent, setDataContent] = React.useState<any>(null);
  const [courseRecomended, setCourseRecomended] = React.useState<any>(null);
  const [courseInterest, setCourseInterest] = React.useState<any>(null);

  const defaultValuesSearch: SearchBarForm = {
    search: '',
  };

  const getDefaultFormSearch = (): SearchBarForm => {
    return defaultValuesSearch;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<SearchBarForm>({
    ...objectValidation,
    defaultValues: getDefaultFormSearch(),
  });

  const keywords = watch().search;

  const getCoursesData = React.useCallback(async () => {
    const recomended = await dispatch(RecommendedCourseThunk(0));
    if (RecommendedCourseThunk.rejected.match(recomended)) {
      toast('');
    }
    const organization = await dispatch(OrganizationCourseThunk(0));
    if (OrganizationCourseThunk.rejected.match(organization)) {
      toast('');
    }
    const popular = await dispatch(PopularCourseThunk(0));
    if (PopularCourseThunk.rejected.match(popular)) {
      toast.error(popular.payload as string);
    } else {
      setCourseRecomended(popular.payload);
    }
    const interest = await dispatch(InterestCourseThunk(0));
    if (InterestCourseThunk.rejected.match(interest)) {
      toast.error(interest.payload as string);
    } else {
      setCourseInterest(interest.payload);
    }
  }, [dispatch]);

  const getContentOrganization = React.useCallback(async () => {
    const RecommendedPaths = await dispatch(GetRecommendedPathsThunk());
    if (GetRecommendedPathsThunk.rejected.match(RecommendedPaths)) {
      toast.error(RecommendedPaths.payload as string);
    } else {
      setDataPaths(RecommendedPaths.payload);
    }
    const OrganizationContent = await dispatch(GetContentOrganizationThunk());
    if (GetContentOrganizationThunk.rejected.match(OrganizationContent)) {
      toast.error(OrganizationContent.payload as string);
    } else {
      setDataContent(OrganizationContent.payload);
    }
  }, [dispatch]);

  const menuFilterOptionItems = React.useCallback(async () => {
    const menuFilter = await dispatch(AdminCourseCatalogFilterOptionsThunk());

    if (AdminCourseCatalogFilterOptionsThunk.rejected.match(menuFilter)) {
      toast.error(menuFilter.payload as string);
    }
  }, [dispatch]);

  const listCourseCatalog = React.useCallback(async () => {
    const courses = await dispatch(AdminCourseCatalogThunk({}));
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  }, [dispatch]);

  const handleSeeMore = (data: any) => {
    setSeeMoreData(data);
    setSeeMore(true);
  };

  const handleUserKeyPressSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(handleSearch)();
      reset();
    }
  };

  const handleSearch = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: keywords,
      })
    );
    setWordSearch(keywords);
    setStateFilter(true);
    setToggleFilter(false);

    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleSearchClear = async () => {
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        search: '',
      })
    );
    setStateFilter(false);

    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
  };

  const handleSearchPage = async (page: number) => {
    setCurrentPage(page);
    const courses = await dispatch(
      AdminCourseCatalogThunk({
        page: page,
      })
    );
    if (AdminCourseCatalogThunk.rejected.match(courses)) {
      toast.error(courses.payload as string);
    }
    reset();
  };

  const toggleFilterHandler = () => {
    setToggleFilter(!toggleFilter);
  };

  React.useEffect(() => {
    listCourseCatalog();
    menuFilterOptionItems();
    getCoursesData();
    dispatch(defaultCourseSelection());
    getContentOrganization();
  }, [dispatch, listCourseCatalog, menuFilterOptionItems, getCoursesData, getContentOrganization]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={false}
      showMenuHeaderAcredited={true}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      {!seeMore ? (
        <CourseCatalogContainer>
          <RowStyled>
            <BarSearchContainer sm="12">
              <IconFilterWrapper onClick={toggleFilterHandler}>
                <icons.FilterIcon />
              </IconFilterWrapper>
              <div>
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      name="search"
                      error={errors}
                      height={35}
                      placeholder="¿Qué cursos buscas hoy?"
                      value={value}
                      onChange={onChange}
                      onKeyPress={handleUserKeyPressSearch}
                    />
                  )}
                  name="search"
                />
                <SearchIconContainer onClick={handleSubmit(handleSearchClear)}>
                  <icons.CleanSearchIcon />
                </SearchIconContainer>
              </div>
            </BarSearchContainer>
          </RowStyled>
          {toggleFilter && <CourseFilters showFileterResults={() => setStateFilter(true)} />}
          {admin.courseCatalog && admin.courseCatalog.results ? (
            <React.Fragment>
              {stateFilter === true ? (
                admin.courseCatalog.results.length !== 0 ? (
                  <div>
                    <CoursesCardsUnSelect courses={admin.courseCatalog.results} />
                    <Pagination
                      currentPage={currentPage}
                      totalCount={admin.courseCatalog.count}
                      pageSize={20}
                      onPageChange={(page: number) => handleSearchPage(page)}
                    />
                  </div>
                ) : (
                  <ContainerNoData
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5%',
                    }}
                  >
                    <ContainNoData>
                      <icons.Icono_Busqueda />
                      <SubtitleFirstNoData>No encontramos resultados</SubtitleFirstNoData>
                      <SubtitleSecondNoData>
                        Buscamos "{wordSearch}" y no hemos encontrado elementos relacionados
                      </SubtitleSecondNoData>
                      <ButtonWrapper>
                        <ButtonCom
                          label={'Borrar búsqueda'}
                          widthCustom={300}
                          onClick={handleSubmit(handleSearchClear)}
                        />
                      </ButtonWrapper>
                    </ContainNoData>
                  </ContainerNoData>
                )
              ) : (
                <div>
                  {dataPaths !== null ? (
                    <div>
                      {dataPaths.results.length !== 0 && (
                        <div>
                          <CourseCatalogHeaderTitles>
                            <CourseCatalogSubTitles>
                              <SubtitleFirst>Rutas Recomendadas</SubtitleFirst>
                              <SubtitleSecond onClick={() => handleSeeMore('paths')}>
                                {/* Ver más + */}
                              </SubtitleSecond>
                            </CourseCatalogSubTitles>
                          </CourseCatalogHeaderTitles>
                          <MyRoutesRows>
                            {dataPaths.results.map((route: any, indexRoute: any) => {
                              return (
                                <ColStyled md="3" sm="4" xs="12" key={indexRoute}>
                                  <Link to={`${AccreditedRole.learningPathPreview}/${route.id}`}>
                                    <Route
                                      name={route.name}
                                      image={route.photo}
                                      date={route.duration}
                                      courses={route.course_count}
                                      skills={route.skill_count}
                                      start_date={route.start_date}
                                      badge_count={route.course_count}
                                      organization_name={route.organization_name}
                                      duration={route.duration}
                                    />
                                  </Link>
                                </ColStyled>
                              );
                            })}
                          </MyRoutesRows>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {dataContent !== null ? (
                    <div>
                      <CourseCatalogSubTitles>
                        <SubtitleFirst></SubtitleFirst>
                        <SubtitleSecond onClick={() => handleSeeMore('organizationCourseT')}>
                          {/* Ver más + */}
                        </SubtitleSecond>
                      </CourseCatalogSubTitles>
                      <MyRoutesRows>
                        <ColStyled md="3" sm="4" xs="12">
                          <RouteContainerFirst>
                            {user.userInfo !== null && (
                              <TitleCard>Contenido de {user.userInfo.organization.name}</TitleCard>
                            )}
                          </RouteContainerFirst>
                        </ColStyled>
                        {dataContent.results.map((route: any, indexRoute: any) => {
                          return (
                            <ColStyled md="3" sm="4" xs="12" key={indexRoute}>
                              <Link to={`${AccreditedRole.learningPathPreview}/${route.id}`}>
                                <Route
                                  name={route.name}
                                  image={route.photo}
                                  date={route.duration}
                                  courses={route.course_count}
                                  skills={route.skill_count}
                                  start_date={route.start_date}
                                  badge_count={route.course_count}
                                  organization_name={route.organization_name}
                                  duration={route.duration}
                                />
                              </Link>
                            </ColStyled>
                          );
                        })}
                      </MyRoutesRows>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {courseRecomended !== null ? (
                    <div>
                      {courseRecomended.results.length !== 0 && (
                        <div>
                          <CourseCatalogHeaderTitles>
                            <CourseCatalogSubTitles>
                              <SubtitleFirst>Cursos Populares</SubtitleFirst>
                              <SubtitleSecond onClick={() => handleSeeMore('popularCourseT')}>
                                Ver más +
                              </SubtitleSecond>
                            </CourseCatalogSubTitles>
                          </CourseCatalogHeaderTitles>
                          <MyRoutesRows>
                            {courseRecomended.results.map((course: any, indexRoute: any) => {
                              return (
                                <ColStyled md="3" sm="4" xs="12" key={indexRoute}>
                                  <Link to={`${AccreditedRole.coursePreview}/${course.id}`}>
                                    <CourseCard
                                      name={course.name}
                                      image={course.image_url}
                                      company={course.provider_name}
                                      start={course.start_date}
                                      score={course.score}
                                      skills={course.skill_count}
                                      duration={course.duration}
                                      price={course.cost}
                                      companyColor={'003DA6'}
                                    />
                                  </Link>
                                </ColStyled>
                              );
                            })}
                          </MyRoutesRows>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {courseInterest !== null ? (
                    <div
                      style={{
                        marginBottom: '100px',
                      }}
                    >
                      {courseInterest.results.length !== 0 && (
                        <div>
                          <CourseCatalogSubTitles>
                            <SubtitleFirst></SubtitleFirst>
                            <SubtitleSecond onClick={() => handleSeeMore('interestCourseT')}>
                              Ver más +
                            </SubtitleSecond>
                          </CourseCatalogSubTitles>
                          <MyRoutesRows>
                            <ColStyled md="3" sm="4" xs="12">
                              <RouteContainerFirst>
                                <TitleCard>Cursos según tus Intereses</TitleCard>
                              </RouteContainerFirst>
                            </ColStyled>
                            {courseInterest.results.map((course: any, indexRoute: any) => {
                              return (
                                <ColStyled md="3" sm="4" xs="12" key={indexRoute}>
                                  <Link to={`${AccreditedRole.coursePreview}/${course.id}`}>
                                    <CourseCard
                                      name={course.name}
                                      image={course.image_url}
                                      company={course.provider_name}
                                      start={course.start_date}
                                      score={course.score}
                                      skills={course.skill_count}
                                      duration={course.duration}
                                      price={course.cost}
                                      companyColor={'003DA6'}
                                    />
                                  </Link>
                                </ColStyled>
                              );
                            })}
                          </MyRoutesRows>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </React.Fragment>
          ) : (
            <ContainerNoData
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '330%',
                padding: '10%',
              }}
            >
              <ReactLoading type="spin" color={'black'} height={80} width={80} />
            </ContainerNoData>
          )}
        </CourseCatalogContainer>
      ) : (
        <AdminCourseCatalog courses={seeMoreData} hideNewCourseSection={() => setSeeMore(false)} />
      )}
    </CommonPageLayout>
  );
};

export default AdminHomeCourseCatalog;
