import { useEffect, useState } from 'react';
import { AcredittaThemeProvider } from '../../../context';
import { AcredittaNavbarBrand, AcredittaNavbarMenu } from './';
import AcredittaNavbarMobile from './AcredittaNavbarMobile';
import MenuContextProvider from './context/MenuContextProvider';

const AcredittaNavbar = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <AcredittaThemeProvider>
      <MenuContextProvider>
        {!isMobile ? (
          <>
            <AcredittaNavbarBrand />
            <AcredittaNavbarMenu />
          </>
        ) : (
          <AcredittaNavbarMobile />
        )}
      </MenuContextProvider>
    </AcredittaThemeProvider>
  );
};

export default AcredittaNavbar;
