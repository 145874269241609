import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const CourseInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  align-items: center;
  margin: 15px 0px;
  @media ${device.mediumLaptop} {
    grid-template-columns: 50% 10% 40%;
  }
`;

export const CourseNameWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  svg{
    width: 20px;
    height: 20px;
  }
`;

export const CourseName = styled(Label)`
  font-family: NotoSansJPBold;
  margin: 0px;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;

export const UtilsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const UtilItemsWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? `auto` : `pointer`)};
  color: ${(props) => (props.disabled ? `#EBEBEB` : PRIMARY_COLOR)};
  span {
    font-family: NotoSansJPBold;
    font-size: 10px;
    line-height: 14px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    line-height: 22px;
    color: #53565a;
    margin-left: 5px;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
  margin: 0px 10px 2px 10px;
`;

export const MandatoryLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  position: relative;
  top: -18px;
  background-color: white;
  left: 40%;
  padding: 5px;
  margin-bottom: 0px;
`;

export const DeleteLabel = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: #002057;
  position: relative;
  top: 15px;
  background-color: white;
  left: 84%;
  padding: 5px;
  margin-bottom: 0px;
  cursor: pointer;

  @media ${device.desktop} {
    left: 80%;
  }
  @media ${device.laptops} {
    left: 70%;
  }
`;
