import * as React from 'react';
import {
  RouteContainer,
  RouteHeader,
  RouteTitle,
  RouteProgressInfoContainer,
  RouteDate,
  ProfileInfoImage,
  RouteInfoContainer,
  RouteInfo,
} from './styles';
import icons from 'assets/Icon';

interface RouteProps {
  name: string;
  image: string;
  date: string;
  courses: number;
  skills: number;
}

const Route: React.FC<RouteProps> = ({ name, image, date, courses, skills }) => {
  return (
    <RouteContainer>
      <RouteHeader>
        <RouteDate>
          Duracion: <span>{date}</span>
        </RouteDate>
        <icons.ArrowIcon />
      </RouteHeader>
      <RouteProgressInfoContainer>
        {image && <ProfileInfoImage src={image} alt="profile" />}
      </RouteProgressInfoContainer>
      <RouteTitle>{name}</RouteTitle>
      <br />
      <RouteInfoContainer>
        <RouteInfo>
          <icons.CoursesIcon />
          <span>{courses} Cursos</span>
        </RouteInfo>
        <RouteInfo>
          <icons.SkillsIcon />
          <span>{skills} Habilidades</span>
        </RouteInfo>
      </RouteInfoContainer>
    </RouteContainer>
  );
};

export default Route;
