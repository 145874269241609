import StyledButton from 'components/atoms/Button';
import { S3_ADMIN } from 'constants/index';
import { AdminRole } from 'constants/NavigationUrls';
import { useAppDispatch } from 'hooks/redux';
import * as React from 'react';
import { useHistory } from 'react-router';
import { StepWizardProps } from 'react-step-wizard';
import { Container } from 'reactstrap';
import { removeListSelectedCourse } from 'states/slices/admin.slice';
import {
  AdminEndStepProcessContent,
  AdminEndStepProcessContentContainer,
  AdminEndStepProcessContentWrapper,
  AdminEndStepProcessImage,
  AdminEndStepProcessImageContainer,
  AdminEndStepProcessRow,
  AdminEndStepProcessTitle,
} from './styles';

interface AdminEndStepProcessProps extends Partial<StepWizardProps> {}

const AdminEndStepProcess = (_: AdminEndStepProcessProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const finishOnBoarding = () => {
    dispatch(removeListSelectedCourse());
    history.push(AdminRole.catalogCourse);
  };

  React.useEffect(() => {
    dispatch(removeListSelectedCourse());
  }, [dispatch]);

  return (
    <Container>
      <AdminEndStepProcessRow>
        <AdminEndStepProcessContentContainer md={6} lg={6}>
          <AdminEndStepProcessContentWrapper className="d-flex align-items-center flex-column mb-3">
            <AdminEndStepProcessTitle>
              Has finalizado el proceso de creación de rutas exitosamente
            </AdminEndStepProcessTitle>
            <AdminEndStepProcessContent>
              Sigue diseñando rutas para potenciar tus habilidades y las de tus usuarios.
            </AdminEndStepProcessContent>
            <StyledButton label="Finalizar" onClick={finishOnBoarding} />
          </AdminEndStepProcessContentWrapper>
        </AdminEndStepProcessContentContainer>
        <AdminEndStepProcessImageContainer md={6} lg={6}>
          <AdminEndStepProcessImage
            src={`${S3_ADMIN}AdminCompleteProcess.svg`}
            alt={`${S3_ADMIN}AdminCompleteProcess.svg`}
          />
        </AdminEndStepProcessImageContainer>
      </AdminEndStepProcessRow>
    </Container>
  );
};

export default AdminEndStepProcess;
