import * as React from 'react';
import Badge from 'components/atoms/Badge';
import {
  ButtonWrapper,
  ContainerNoData,
  MyBadgesContainer,
  MyBadgesTitle,
  MyRoutesRows,
} from './styles';
import { GetOrganizationBadgesThunk } from 'actions/Thunks/AdminThunk';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { ColStyled } from '../CoursesCards/styles';
import { toast } from 'react-hot-toast';
import ReactLoading from 'react-loading';
import icons from 'assets/Icon';
import Button from 'components/atoms/Button';
import { AccreditedRole, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';
import { ENVIRONMENT_URL_BADGE } from 'utils';

const MyBadges: React.FC = () => {
  const dispatch = useAppDispatch();

  const [urlBadge, setUrlBadge] = React.useState('');

  const admin = useAppSelector((state) => state.admin);
  const user = useAppSelector((state) => state.user.userInfo);

  const getBadgesList = React.useCallback(async () => {
    const badges = await dispatch(GetOrganizationBadgesThunk(user?.cognito_id));
    if (GetOrganizationBadgesThunk.rejected.match(badges)) {
      toast(badges.payload as string);
    } else {
      setUrlBadge(`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.url_badges}/`);
    }
  }, [user, dispatch]);

  React.useEffect(() => {
    getBadgesList();
  }, [getBadgesList]);

  return (
    <MyBadgesContainer>
      <MyBadgesTitle>Insignias Obtenidas</MyBadgesTitle>
      {admin.organizationBadges !== null ? (
        admin.organizationBadges.length !== 0 ? (
          <MyRoutesRows>
            {admin.organizationBadges.map((badge: any, index: any) => {
              return (
                <ColStyled md="3" sm="4" xs="12" key={index}>
                  {badge.badge_template && (
                    <Badge
                      typeBadge={badge.accepted_at === null ? true : false}
                      isPublicBadge={badge.public}
                      id={badge.id}
                      name={badge.badge_template.name}
                      image={badge.badge_template.image.url}
                      company={badge.badge_template.owner.name}
                      date={badge.accepted_at}
                      dateExpired={badge.expires_at}
                      url={urlBadge}
                    />
                  )}
                </ColStyled>
              );
            })}
          </MyRoutesRows>
        ) : (
          <ContainerNoData
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <icons.Vector_insignia
              style={{
                width: '20%',
              }}
            />
            <h3>
              Aún no tienes insignias. ¡Inscríbete a un curso o a una ruta para ganar una nueva!
            </h3>
            <ButtonWrapper>
              <Link to={AccreditedRole.catalogCourse}>
                <Button label={'Ir al catálogo'} widthCustom={300} />
              </Link>
            </ButtonWrapper>
          </ContainerNoData>
        )
      ) : (
        <ContainerNoData
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '2%',
          }}
        >
          <ReactLoading type="spin" color={'#002057'} height={80} width={80} />
        </ContainerNoData>
      )}
    </MyBadgesContainer>
  );
};

export default MyBadges;
