import styled from 'styled-components';
import { Input, Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const InputContainer = styled(Input)<{ $height?: number; $pointer: boolean }>`
  height: ${(props) => (props.$height ? props.$height : 52)}px !important;
  background: #ffffff;
  border: 1px solid #002057;
  box-shadow: 0px 4px 4px rgba(138, 149, 158, 0.3) !important;
  border-radius: 8px;
  resize: none;
  cursor: ${(props) => (props.$pointer ? 'pointer' : 'auto')};
  width: 100%
`;

export const TextInputLabelContainer = styled.div`
  width: fit-content;
  position: relative;
  z-index: 1;
  padding-right: 5px;
  top: 10px;
  left: 12px;
  text-align: initial;
  background-color: white;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  svg{
    width: 20px;
    height: 20px;
  }
  @media ${device.mediumLaptop} {
    font-size: 16px;
  }
  @media ${device.mediumMobile} {
    font-size: 14px;
  }
`;

export const TextInputLabel = styled(Label)`
  font-family: NotoSansJPBold;
  margin-bottom: 0px;
`;

export const Search = styled.div`
  width: 100%;
  position: relative;
  svg {
    position: absolute;
    bottom: 9px;
    right: 5px;
    top: 10px;
    width: 35px;
    height: 25px;
  }
`;

export const CharacterCounterWrapper = styled.div`
  position: relative;
  top: -25px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
`;

export const CharacterCounter = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #002057;
`;

export const WrapperWithErrorHandling = styled.div`
  width: 100%;
  position: relative;
`;

export const ErrorLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
  position: absolute;
`;