import { Auth } from 'aws-amplify';

export const CheckSession = async (): Promise<boolean> => {
  try {
    const result = await Auth.currentAuthenticatedUser();

    if (!result.username) {
      throw new Error('No user session');
    }
  } catch (e) {
    return false;
  }

  return true;
};
