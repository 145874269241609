import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { API_URL } from 'constants/ApiUrls';

async function getToken() {
  let token = undefined;
  try {
    const currentSession = await Auth.currentSession();
    token = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
  } finally {
    return token;
  }
}

const AcredittaRequest = axios.create({
  baseURL: API_URL || 'http://localhost:3000/api/v1',
});

AcredittaRequest.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    config.headers['Content-Type'] = 'application/json';
    config.headers.Authorization = token ? token : '';
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

export default AcredittaRequest;
