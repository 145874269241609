import toast from 'react-hot-toast';
import { GetErrorMessage } from 'utils';
import * as Sentry from '@sentry/react';
import { logException } from 'utils/GA/analytics';

const MESSAGE = 'Ocurrió un error';
/**
 * Manage catch with sentry + log + toast + generate exception
 * @param err
 * @param errMessage
 * @returns void
 */

interface Config {
  showToast: boolean;
}

export const manageErrors = (err: any, errMessage?: string, config?: Config) => {
  console.error({ err, errMessage });
  if (config?.showToast) {
    toast.error(errMessage || MESSAGE, { duration: 2000 });
  }
  Sentry.captureException(err);
  logException(GetErrorMessage(err), true);
};
