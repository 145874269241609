import { createContext, useEffect, useState } from 'react';
import { useAppSelector } from '../hooks/redux';

export const DEFAULT_BG =
  'linear-gradient(90.8deg, #283C85 0.12%, #51386F 33.93%, #843451 67.23%, #AA303B 100%);';

export interface AcredittaThemeContextI {
  backgroundHeader: string;
}

export const AcredittaThemeContext = createContext({} as AcredittaThemeContextI);

const AcredittaThemeProvider = ({ children }: any) => {
  const { userInfo } = useAppSelector((state) => state.user);
  const [backgroundHeader, setBackgroundHeader] = useState(
    userInfo?.branding.theme.value || DEFAULT_BG
  );

  useEffect(() => {
    if (userInfo?.branding.theme.value) {
      setBackgroundHeader(userInfo?.branding.theme.value);
    }
  }, [userInfo?.branding.theme.value]);

  return (
    <AcredittaThemeContext.Provider value={{ backgroundHeader }}>
      {children}
    </AcredittaThemeContext.Provider>
  );
};

export default AcredittaThemeProvider;
