import AcredittaFormLabel from 'components/atoms/AcredittaFormLabel';
import AcredittaText from 'components/atoms/AcredittaText';
import { useEffect, useState } from 'react';
import icons from 'assets/Icon';
import uuid from 'react-uuid';
import useGetBadgesTemplates from '../hooks/useGetBadgesTemplates';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import AsyncSelect from 'react-select/async';
import { customStyles, styledTheme } from 'theme/async-select';
import ThumbImage from './ThumbImage';

interface Props {
  badgeStackableType: string;
  onFinish: (values: any) => void;
}

interface SelectProps {
  label: string;
  value: number;
}

const DinamicBadgeSelect = ({ badgeStackableType, onFinish }: Props) => {
  const [badges, setBadges] = useState<any[]>([]);
  const { data, fetching } = useGetBadgesTemplates();
  const [items, setItems] = useState<number[]>([]);

  useEffect(() => {
    let d = badges
      .filter((i) => typeof i.currentSelected !== 'undefined')
      .map((i) => i.currentSelected);

    setItems(d);
  }, [badges]);

  useEffect(() => {
    let newElements = [
      {
        id: uuid(),
        values: data,
        currentSelected: undefined,
        img: undefined,
      },
      {
        id: uuid(),
        values: data,
        currentSelected: undefined,
        img: undefined,
      },
    ];

    setBadges([...newElements]);
  }, [data]);

  useEffect(() => {
    onFinish(badges);
  }, [badges, onFinish]);

  const handleDelete = (id: number) => {
    setBadges(badges.filter((item) => item.id !== id));
  };

  const handleAdd = () => {
    let newElement = {
      id: uuid(),
      values: data,
      currentSelected: undefined,
    };

    setBadges((prevState) => [...prevState, newElement]);
  };

  const handleArraySelectChange = (item: any, id: any) => {
    const { value, img } = item;
    let newData = badges.map((b) =>
      b.id === id ? { ...b, currentSelected: String(value), img } : b
    );
    setBadges(newData);
  };

  const filterBadges = (inputValue: string) => {
    return data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<SelectProps[]>((resolve) => {
      setTimeout(() => {
        resolve(filterBadges(inputValue));
      }, 1000);
    });

  if (fetching) {
    return <AcredittaLoader />;
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <AcredittaText
            variant="body1"
            color="red"
            fontWeight="600"
            align="right"
            onClick={handleAdd}
            style={{ cursor: 'pointer' }}
          >
            Añadir paso +
          </AcredittaText>
        </div>
      </div>

      {badges.map(({ id, currentSelected, img }, index) => (
        <div className="row mb-3" key={id}>
          <div className="col-xs-12 col-md-2">
            {badgeStackableType === String(1) && <AcredittaFormLabel text={`Paso #${index + 1}`} />}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="d-flex">
              {currentSelected && <ThumbImage source={img} />}
              <div style={{ flex: 1 }}>
                <AsyncSelect
                  defaultOptions
                  loadOptions={promiseOptions}
                  onChange={(values) => handleArraySelectChange(values, id)}
                  styles={{
                    ...customStyles,
                    control: (el) => ({
                      ...el,
                      border:
                        items.filter((i) => i === currentSelected).length > 1
                          ? '1px solid red'
                          : el.border,
                    }),
                  }}
                  theme={styledTheme}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-1">
            {badges.length > 2 && (
              <icons.DeletePath onClick={() => handleDelete(id)} style={{ cursor: 'pointer' }} />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default DinamicBadgeSelect;
