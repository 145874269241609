import { DropdownItem } from 'reactstrap';

interface OptionDropDownButtonProps {
  text: string;
  uuid: string;
  icon: any;
  handleClick: () => void;
  w?: number;
  h?: number;
}
const OptionDropDownButton = ({
  text,
  uuid,
  handleClick,
  icon: Icon,
  w = 15,
  h = 15,
}: OptionDropDownButtonProps) => {
  return (
    <DropdownItem onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Icon style={{ width: w, height: h }} />
      {text}
    </DropdownItem>
  );
};

export default OptionDropDownButton;
