import styled from 'styled-components';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';



export const TextInputLabelContainer = styled.div`
  width: fit-content;
  position: relative;
  z-index: 1;
  padding-right: 5px;
  top: 10px;
  left: 12px;
  text-align: initial;
  background-color: white;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  svg{
    transform: scale(1.3);
  }
  @media ${device.mediumLaptop} {
    font-size: 16px;
  }
  @media ${device.mediumMobile} {
    font-size: 14px;
  }
`;

export const Search = styled.div`
  width: 100%;
  position: relative;
  svg {
    position: absolute;
    bottom: 9px;
    right: 5px;
    top: 10px;
    width: 35px;
    height: 25px;
  }
`;

export const TextInputLabel = styled(Label)`
  font-family: NotoSansJPBold;
  margin-bottom: 0px;
`;


export const WrapperWithErrorHandling = styled.div`
  width: 100%;
`;
export const ErrorLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;
