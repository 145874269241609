import styled from 'styled-components';
import { Col, Label, Modal, Row } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const BadgesInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 98% 98%;
  margin-top: 20px;
`;

export const ColStyled = styled(Col)`
  margin: 20px 0px;
`;

export const RowStyled = styled(Row)`
  margin: 40px 2px 0px;
`;

export const SearchIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 18px;
  svg {
    width: 15px;
  }
`;

export const BarSearchContainer = styled(Col)`
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  background-color: ${PRIMARY_COLOR};
  margin-bottom: 20px;

  @media ${device.laptops} {
    grid-template-columns: 4% 96%;
  }
  @media ${device.bigMobile} {
    grid-template-columns: 6% 94%;
  }
  @media ${device.smallMobile} {
    grid-template-columns: 8% 92%;
  }
`;

export const IconFilterWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
`;

export const OrganizationMenuContainer = styled.div`
  background: ${PRIMARY_COLOR};
  border-radius: 8px;
  margin: 30px 0px;
  display: flex;
  padding: 10px;
`;

export const OrganizationMenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #ebebeb;
  &:last-child {
    border-right: none;
  }
`;

export const OrganizationMenuItem = styled(Label)`
  font-size: 35px;
  line-height: 48px;
  color: #ffffff;
  cursor: pointer;
`;

export const OrganizationMenuSubItem = styled.a`
  font-size: 20px;
  line-height: 38px;
  color: #ffffff;
  cursor: pointer;
`;

export const OrganizationMenuItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: white;
  cursor: pointer;
`;

export const BadgeTemplateCol = styled.div`
  margin: 20px 10px;
`;

export const RowCardsStyled = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
`;

export const ModalContainer = styled(Modal)`
  max-width: 900px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 30px;
  padding: 3% 20%;
  text-align: center;
`;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  padding: 0% 20%;
`;

export const ModalTextFile = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  padding: 0% 20%;
`;

export const ModalTextFooter = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  text-align: center;
`;

export const UploadFileWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 22px;
`;
