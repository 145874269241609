import { useState, useCallback } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import icons from 'assets/Icon';
import icon from '../../../assets/images/three-dots.png';
import { useAppDispatch } from 'hooks/redux';
import { GetReminderIndividualThunk, GetRenewBadgeThunk } from 'actions/Thunks/AcreditedThunk';
import toast from 'react-hot-toast';
import { dowloadCertificatePdf } from 'actions/ApiClient/certificates-pdf';
import { manageErrors } from 'helpers/manage-error';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import { NonAuthenticatedUrls } from 'constants/NavigationUrls';

interface ButtonActionsProps {
  data: any;
}

const ButtonActions = ({ data }: ButtonActionsProps) => {
  const { uuid, status } = data;

  const [open, setOpen] = useState(false);

  const toggle = () => setOpen((prevState: boolean) => !prevState);

  const dispatch = useAppDispatch();

  const reminderIndividual = useCallback(
    async (uuid: string) => {
      const adminInfoData = await dispatch(GetReminderIndividualThunk(uuid));
      if (GetReminderIndividualThunk.rejected.match(adminInfoData)) {
        toast.error(adminInfoData.payload as string);
      }

      toast.success('Recordatorio enviado correctamente');
    },
    [dispatch]
  );

  const renewBadge = useCallback(
    async (uuid: string) => {
      const renewBadge = await dispatch(GetRenewBadgeThunk(uuid));
      if (GetRenewBadgeThunk.rejected.match(renewBadge)) {
        toast.error(renewBadge.payload as string);
      }
    },
    [dispatch]
  );

  const handleOpenLink = () => {
    window.open(`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.badgesBack}/${uuid}`);
  };

  const getCertificatePdf = async (uuid: string) => {
    try {
      const resp: any = await dowloadCertificatePdf(uuid);
      const blob = new Blob([resp.data], { type: 'application/pdf' });
      window.open(URL.createObjectURL(blob), '_blank');
    } catch (e: any) {
      toast.error(
        e.response?.status === 406
          ? 'Esta insignia no cuenta con un certificado.'
          : 'Ha ocurrido un error, intente de nuevo más tarde'
      );
      manageErrors(e);
    }
  };

  return (
    <div>
      <Dropdown isOpen={open} toggle={toggle} title="Más acciones">
        <DropdownToggle size="sm">
          <img src={icon} alt="" width="20" height="20" />
        </DropdownToggle>
        <DropdownMenu>
          {status === 'accepted' && (
            <>
              <DropdownItem>
                <div style={{ cursor: 'pointer' }} onClick={handleOpenLink}>
                  <icons.LinkToIcon style={{ width: '15px', height: '15px' }} /> Ver enlace
                </div>
              </DropdownItem>
              <DropdownItem>
                <div style={{ cursor: 'pointer' }} onClick={() => getCertificatePdf(uuid)}>
                  <icons.FileIcon style={{ width: '15px', height: '15px' }} /> Ver certificado
                </div>
              </DropdownItem>
            </>
          )}
          {status === 'pending' && (
            <DropdownItem>
              <div style={{ cursor: 'pointer' }} onClick={() => reminderIndividual(uuid)}>
                <icons.Icono_Reenviar style={{ width: '15px', height: '15px' }} /> Reenviar insignia
              </div>
            </DropdownItem>
          )}

          {status === 'revoked' && status === 'expired' && (
            <DropdownItem>
              <div style={{ cursor: 'pointer' }} onClick={() => renewBadge(uuid)}>
                <icons.EyeIcon style={{ width: '15px', height: '15px' }} /> Renovar insignia
              </div>
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default ButtonActions;
