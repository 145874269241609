import { MenuI } from '../interfaces/menu';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import { useHistory } from 'react-router-dom';
import { UserRoles } from '../interfaces/user-roles';
import { ACREDITTED_MENU, ADMIN_MENU } from '../data';
import { cleanState } from 'states/slices/clean.slice';
import { SignOut } from 'actions/ApiClient/AuthActions';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useEffect, useState, createContext } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ORGANIZATION_CONFIG_MENU, ACREDITTED_CONFIG_MENU } from '../data/configuration';

interface MenuContextInterface {
  menu: MenuI[] | null;
  configMenu: MenuI[] | null;
  isOpen: boolean;
  role: 'ADMIN' | 'ACREDITTED';
  toogleMenu: () => void;
  openMenu: () => void;
  closeMenu: () => void;
  logout: () => void;
  goToPublicProfile: () => void;
}

export const MenuContext = createContext({} as MenuContextInterface);

const MenuContextProvider = ({ children }: any) => {
  const [menu, setMenu] = useState<MenuI[] | null>(null);
  const [configMenu, setConfigMenu] = useState<MenuI[] | null>(null);
  const { userInfo } = useAppSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const role = (userInfo?.user_type.code as any) || 'ACCREDITED';

  useEffect(() => {
    switch (role) {
      case String(UserRoles.ADMIN):
        setMenu(ADMIN_MENU);
        setConfigMenu(ORGANIZATION_CONFIG_MENU);
        break;

      case String(UserRoles.ACREDITTED):
        setMenu(ACREDITTED_MENU);
        setConfigMenu(ACREDITTED_CONFIG_MENU);
        break;
    }
  }, [role]);

  const goToPublicProfile = () => {
    let nickname = userInfo ? (userInfo?.nickname as any) : null;

    if (nickname) {
      const uri = `${ENVIRONMENT_URL_BADGE}/public/acreditado/${nickname}`;
      window.open(uri, '_blank');
    }
  };

  const logout = async () => {
    await dispatch(cleanState());
    await SignOut();
    push(AuthNavigations.login);
  };

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const openMenu = () => {
    setIsOpen(true);
  };

  return (
    <MenuContext.Provider
      value={{
        menu,
        isOpen,
        configMenu,
        role,
        toogleMenu,
        closeMenu,
        openMenu,
        goToPublicProfile,
        logout,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;
