import icons from 'assets/Icon';
import * as React from 'react';
import { ModalBodyStyled, ModalContainer, ModalIcons, ModalText, ModalTitle } from './styles';
import ButtonCom from 'components/atoms/ButtonCom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import PhoneInput from 'react-phone-input-2';
import { ChangeNumberThunk } from 'actions/Thunks/UserThunk';
import toast from 'react-hot-toast';

interface PropsDataConfig {
  modal: (type: boolean) => void;
}

const ModalUpgradeAccount = ({ modal }: PropsDataConfig): JSX.Element => {
  const dispatch = useAppDispatch();

  const [modalTwo, setModalTwo] = React.useState(true);
  const [phoneNumber, setPhoneNumber] = React.useState<any>(null);
  const [country, setCountry] = React.useState<any>();

  const user = useAppSelector((state) => state.user);

  const newPhoneForm = async () => {
    if (phoneNumber !== '') {
      const numberRequest: any = {
        uuid: user.userInfo?.cognito_id,
        path: {
          path: '',
        },
        new_number: {
          user: {
            phone_number: phoneNumber,
          },
          accredited: {
            country: country.dialCode,
          },
        },
      };
      const newPhone = await dispatch(ChangeNumberThunk(numberRequest));

      if (ChangeNumberThunk.fulfilled.match(newPhone)) {
        toast.success('Se ha modificado el perfil con éxito');
        setModalTwo(!modalTwo);
      } else {
        toast.error(newPhone.payload as string);
      }
    }
  };

  return (
    <React.Fragment>
      <ModalContainer
        isOpen={modalTwo}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyled>
          <ModalTitle>¡Mejora tu cuenta!</ModalTitle>
          <ModalIcons>
            <icons.Imagen_Upgrade />
          </ModalIcons>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5%',
            }}
          >
            {user.userInfo !== null && (
              <ModalText>
                Actualmente cuentas con un plan{' '}
                <strong>{user.userInfo?.organization.plan.name}</strong>, para obtener todas las
                funcionalidades del plan{' '}
                <strong>{user.userInfo?.organization.plan.next_plan.name}</strong> déjanos tus datos
                y el área de ventas se contactará contigo lo más pronto posible.
              </ModalText>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <PhoneInput
                regions={['north-america', 'south-america', 'central-america']}
                country="co"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                value={phoneNumber}
                onChange={(phoneNumber, country) => {
                  setPhoneNumber(phoneNumber);
                  setCountry(country);
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <ButtonCom
                label="Cancelar"
                widthCustom={150}
                lightMode={true}
                onClick={() => modal(false)}
                style={{
                  padding: '5%',
                }}
              />
              <div
                style={{
                  padding: '5%',
                }}
              >
                <ButtonCom
                  disabled={phoneNumber === null ? true : false}
                  label={'Solicitar Mejora'}
                  widthCustom={200}
                  onClick={() => newPhoneForm()}
                />
              </div>
            </div>
          </div>
        </ModalBodyStyled>
      </ModalContainer>
    </React.Fragment>
  );
};

export default ModalUpgradeAccount;
