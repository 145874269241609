import AcredittaText from 'components/atoms/AcredittaText';
import React from 'react';

const MoreInfoDesktop = ({ user }: any) => {
  return (
    <div
      className="row p-3"
      style={{
        background: user.userInfo?.branding.theme.value,
        borderRadius: '8px',
        boxShadow: '0px 10px 30px 5px rgba(153, 162, 169, 0.1)',
      }}
    >
      <div className="col-xs-12 col-sm-6">
        <AcredittaText variant="h4" color="white" align="center">
          {user.adminInfo?.contact_email}
        </AcredittaText>
      </div>
      <div className="col-xs-12 col-sm-6 border-white-left">
        <AcredittaText variant="h4" color="white" align="center">
          {user.adminInfo?.domain !== 'https://example.org' && (
            <a
              href={user.adminInfo?.domain}
              rel="noreferrer"
              target="_blank"
              style={{ color: 'white' }}
            >
              {user.adminInfo?.domain}
            </a>
          )}
        </AcredittaText>
      </div>
    </div>
  );
};

export default MoreInfoDesktop;
