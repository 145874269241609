import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, Container } from 'reactstrap';
import styled, { css } from 'styled-components';

export const MySkillsContainer = styled.div<{ gap: string }>`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: ${({ gap }) => '-' + gap};
`;

export const MySkillsContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const HexagonGroupContainer = styled.div<{ align: string }>`
  width: 70%;
  display: flex;
  align-items: center;

  ${(props) =>
    css`
      justify-content: flex-${props.align};
    `}
  svg {
    width: auto;
  }
  @media ${device.tablet} {
    svg {
      width: 150px;
    }
  }
  @media ${device.bigMobile} {
    svg {
      width: 130px;
    }
  }
  @media ${device.mobile} {
    svg {
      width: 120px;
    }
  }
  @media only screen and (max-width: 370px) {
    svg {
      width: 90px;
      height: 60px;
    }
  }
  @media ${device.smallMobile} {
    svg {
      width: 80px;
      height: 54px;
    }
  }
`;

export const MySkillsTitleContainer = styled.div`
  width: 70%;
  @media ${device.ipad} {
    width: 90%;
  }
`;

export const MySkillsTitle = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 4px;
  margin-right: 15px;
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const LineContainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    height: 60px;
  }
  @media ${device.bigMobile} {
    height: 54px;
  }
  @media ${device.mobile} {
    height: 45px;
  }
  @media only screen and (max-width: 420px) {
    height: 42px;
  }
  @media only screen and (max-width: 370px) {
    height: 36px;
  }
  @media ${device.smallMobile} {
    height: 32px;
  }
`;

export const SkillsContainer = styled(Container)`
  width: 70%;
  margin-left: 120px;
  margin-bottom: 70px;
  @media ${device.tablet} {
    margin-left: 80px;
  }
  @media ${device.bigMobile} {
    margin-left: 65px;
  }
  @media ${device.mobile} {
    margin-left: 55px;
  }
  @media only screen and (max-width: 420px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: 370px) {
    margin-left: 44px;
  }
  @media ${device.smallMobile} {
    margin-left: 40px;
  }
`;

export const Skills = styled.div`
  height: 80px;
  margin-right: 120px;
  @media ${device.mediumLaptop} {
    svg {
      width: 150px;
    }
  }
  @media ${device.tablet} {
    margin-right: 80px;
    svg {
      width: 130px;
    }
  }
  @media ${device.bigMobile} {
    margin-right: 65px;
    svg {
      width: 115px;
    }
  }
  @media ${device.mobile} {
    svg {
      width: 100px;
    }
  }
  @media ${device.mobile} {
    margin-right: 55px;
    svg {
      width: 100px;
    }
  }
  @media only screen and (max-width: 420px) {
    margin-right: 50px;
    svg {
      width: 90px;
    }
  }
  @media only screen and (max-width: 370px) {
    margin-right: 44px;
    svg {
      width: 80px;
    }
  }
  @media ${device.smallMobile} {
    margin-right: 40px;
    svg {
      width: 70px;
    }
  }
`;

export const HexagonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -85px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media ${device.tablet} {
    top: -70px;
  }
  @media ${device.tablet} {
    top: -70px;
  }
`;

export const SkillName = styled(Label)<{ fontColor: string }>`
  height: 142px;
  width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  font-family: NotoSansJPBold;
  font-size: 20px;
  position: relative;
  top: -60px;
  ${(props) =>
    css`
      color: #${props.fontColor};
    `}
  @media ${device.mediumLaptop} {
    width: 150px;
    font-size: 15px;
  }
  @media ${device.tablet} {
    height: 115px;
    width: 130px;
    font-size: 14px;
    line-height: 16px;
  }
  @media ${device.bigMobile} {
    height: 115px;
    width: 115px;
    font-size: 12px;
    line-height: 14px;
  }
  @media ${device.mobile} {
    top: -45px;
    height: 85px;
    width: 100px;
    font-size: 11px;
    line-height: 14px;
  }
  @media only screen and (max-width: 420px) {
    top: -40px;
    height: 75px;
    width: 90px;
  }
  @media only screen and (max-width: 370px) {
    font-size: 10px;
    line-height: 12px;
    top: -35px;
    height: 70px;
    width: 80px;
  }
  @media ${device.smallMobile} {
    font-size: 9px;
    line-height: 10px;
    top: -31px;
    height: 60px;
    width: 70px;
  }
`;
