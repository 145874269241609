import icons from 'assets/Icon';
import AcredittaText from 'components/atoms/AcredittaText';
import { AdminRole } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';
import { themeConfig } from 'theme/config';

const MessageProfileNotCompleted = () => {
  return (
    <div className="d-flex" style={{ padding: '15px 30px' }}>
      <div className="mr-2">
        <icons.WarningIcon />
      </div>
      <div className="ml-2">
        <AcredittaText variant="body1" color="secondary" style={{ margin: 0, lineHeight: '1.3em' }}>
          Recuerda completar la{' '}
          <Link
            to={AdminRole.configureOrganization}
            style={{
              color: themeConfig.colors.primary.blue,
              fontFamily: 'NotoSansJPBold',
              textDecoration: 'underline',
            }}
          >
            información del perfil de la organización{' '}
          </Link>
          para emitir insignias.
        </AcredittaText>
      </div>
    </div>
  );
};

export default MessageProfileNotCompleted;
