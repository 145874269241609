import styled from 'styled-components';
import { NavItem, NavLink, Nav } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';

export const CommonMenuContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const NavStyled = styled(Nav)`
  width: 90%;
  justify-content: space-around;
`;

export const NavItemStyled = styled(NavItem)`
  padding-top: 15px;
`;

export const NavLinkStyled = styled(NavLink)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;
