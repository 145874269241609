import styled from 'styled-components';
import { Form, FormGroup, Label } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const PasswordRecoveryForm = styled(Form)`
  padding: 50px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.laptopsHeight} {
    padding: 0;
  }
`;

export const PasswordRecoveryFormGroup = styled(FormGroup)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PasswordRecoveryTitle = styled(Label)`
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 68px;
  line-height: normal;
  color: #002057;

  @media ${device.mobile} {
    font-size: 35px;
  }
`;

export const PasswordRecoverySubTitle = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #53565a;
  @media ${device.mediumLaptop} {
    max-width: 100%;
  }
`;

export const PasswordContainer = styled.div`
  text-align: center;
`;

export const PasswordRecoveryIconContainer = styled.div`
  @media ${device.tablet} {
    img {
      width: 250px;
    }
  }
  @media ${device.mobile} {
    img {
      width: 250px;
    }
  }

  @media ${device.smallMobile} {
    img {
      width: 200px;
    }
  }

  @media ${device.laptopsHeight} {
    margin-top: 1em;
    img {
      width: 250px;
    }
  }
  
`;
