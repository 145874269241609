import {
  AdminUrls,
  API_URL,
  OnboardingUrls,
  PublicUrls,
  TagsAcredittaUrls,
} from 'constants/ApiUrls';
import { HttpRequest } from './HttpRequest';
import * as Sentry from '@sentry/react';
import { GetErrorMessage } from 'utils';

export async function InterestAction(): Promise<IGetOrganizationInterest> {
  try {
    const interest = await new HttpRequest().Get<IGetOrganizationInterest>(
      `${API_URL}${OnboardingUrls.interestList}`
    );

    if (interest.okay && interest.data) {
      return interest.data;
    } else {
      throw Error(interest.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function InterestActionProfile(): Promise<any> {
  try {
    const interest = await new HttpRequest().Get<any>(
      `${API_URL}${PublicUrls.interestListProfile}`
    );

    if (interest.okay && interest.data) {
      return interest.data;
    } else {
      throw Error(interest.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function SkillsListAction(): Promise<any | undefined> {
  try {
    const skills = await new HttpRequest().Get<any>(`${API_URL}${OnboardingUrls.skillsList}`);

    if (skills.okay && skills.data) {
      return skills.data;
    } else {
      throw Error(skills.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function SkillsTagListAction(tag: string): Promise<ISkillsTagList[]> {
  try {
    const skillsTag = await new HttpRequest().Get<ISkillsTagList[]>(
      `${API_URL}${OnboardingUrls.skillsTagList}?name=${tag}`
    );

    if (skillsTag.okay && skillsTag.data) {
      return skillsTag.data;
    } else {
      throw Error(skillsTag.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function SkillsAction(page: number, interestId: number): Promise<any | undefined> {
  try {
    const skills = await new HttpRequest().Get<any>(
      `${API_URL}${OnboardingUrls.skillsList}?interest_id=${interestId}`
    );

    if (skills.okay && skills.data) {
      return skills.data;
    } else {
      throw Error(skills.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateInterestSkills(
  newInterest: ICreateInterestSkills
): Promise<string | undefined> {
  try {
    const createInterestSkills = await new HttpRequest().Post(
      `${API_URL}${OnboardingUrls.createInterestSkills}`,
      newInterest
    );

    if (createInterestSkills.okay && createInterestSkills.data) {
      return 'Se han agregado estos intereses y habilidades a tu perfil exitosamente';
    } else {
      throw Error(createInterestSkills.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function ListTags(): Promise<any | undefined> {
  try {
    const tags = await new HttpRequest().Get<any>(`${API_URL}${TagsAcredittaUrls.listTags}`);
    if (tags.okay && tags.data) {
      return tags.data;
    } else {
      throw Error(tags.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}
export async function ListTagsAcreditado(): Promise<any | undefined> {
  try {
    const tags = await new HttpRequest().Get<any>(`${API_URL}${TagsAcredittaUrls.TagsAcreditado}`);
    if (tags.okay && tags.data) {
      return tags.data;
    } else {
      throw Error(tags.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export const CreateNewTag = (obj: any) => {
  return new HttpRequest().Post(`${API_URL}${TagsAcredittaUrls.listTags}`, obj);
};

export const UpdateTag = (obj: any, id: number) => {
  return new HttpRequest().Patch(`${API_URL}${TagsAcredittaUrls.listTags}${id}/`, obj);
};

export const DeleteTag = (id: number) => {
  return new HttpRequest().Delete(`${API_URL}${TagsAcredittaUrls.listTags}${id}/`);
};

export const CreateNewTagAcreditado = (obj: any) => {
  return new HttpRequest().Post(`${API_URL}${TagsAcredittaUrls.TagsAcreditado}`, obj);
};

export const UpdateTagAcreditado = (obj: any, id: number) => {
  return new HttpRequest().Patch(`${API_URL}${TagsAcredittaUrls.TagsAcreditado}${id}/`, obj);
};

export const DeleteTagAcreditado = (id: number) => {
  return new HttpRequest().Delete(`${API_URL}${TagsAcredittaUrls.TagsAcreditado}${id}/`);
};

export async function GetUserContactMethods(): Promise<any | undefined> {
  try {
    const res = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.userContactMethods}`);
    if (res.okay && res.data) {
      return res.data;
    } else {
      throw Error(res.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}

export async function GetPathList(): Promise<any | undefined> {
  try {
    const res = await new HttpRequest().Get<any>(`${API_URL}${AdminUrls.OrganizationlistPaths}`);
    if (res.okay && res.data) {
      return res.data;
    } else {
      throw Error(res.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(GetErrorMessage(error));
  }
}
/**
 * Create new skill
 * @params {string} name
 * @returns Promise
 * @author Jose Quintero
 */

export const createNewSkill = (name: string) => {
  return new HttpRequest().Post(`${API_URL}skills/`, { name });
};
