import styled from 'styled-components';
import { Label } from 'reactstrap';
import icons from 'assets/Icon';
import { device } from 'constants/responsiveDevice';

export const DownloadLinkContainer = styled.a`
  display: flex;
  cursor: pointer;
  margin-left: 30px;

  @media ${device.mobile} {
    margin-left: 0;
  }
`;

export const ImageIconWrapper = styled.div``;

export const ImageIconDownload = styled(icons.DownloadTemplateIcon)``;

export const DownloadContentWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-left: 8px;
`;

export const DownloadContentLabel = styled(Label)`
  margin-bottom: 0;
  font-family: NotoSansJPBold;
`;
