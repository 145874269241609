import * as React from 'react';
import { useState } from 'react';
import icons from 'assets/Icon';
import {
  ModalBodyStyled,
  ModalContainer,
  ModalIcons,
  ModalText,
  ModalTextFooter,
  ModalTitle,
} from './styles';
import { Link, useHistory } from 'react-router-dom';
import {
  AdminRole,
  getBadgeStackableDetailAcreditado,
  getBadgeStackableDetailAdmin,
} from 'constants/NavigationUrls';
import {
  Badge,
  ButtonToolbar,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import {
  ChangeNumberThunk,
  GetValidatePlanPathsThunk,
  PatchArchivedBadgeThunk,
} from 'actions/Thunks/UserThunk';
import 'react-phone-input-2/lib/style.css';
import AcredittaActionButton from 'components/atoms/AcredittaActionButton';
import { manageErrors } from '../../../helpers/manage-error';
import ModalBeforeEditBadge from '../../molecules/ModalBeforeEditBadge/index';
import { checkIfBadgeWasEmitted } from 'actions/ApiClient/BadgesAction';
import ModalBeforeBadgeEmit from 'components/molecules/ModalBeforeBadgeEmit';
import ButtonCom from 'components/atoms/ButtonCom';
import PhoneInput from 'react-phone-input-2';
import LogoCredly from 'assets/images/Rectangle.png';
import OptionDropDownButton from 'components/atoms/option-dropdown-button/OptionButton';
import Swal from 'sweetalert2';
import { deleteBadgetemplate, setAsPublishBagdeTemplate } from '../../../services/badges.service';
import ImagesStackableBadges from 'components/atoms/ImagesStackableBadges/ImagesStackableBadges';
import './styles.css';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { tr } from 'date-fns/locale';
import { BadgeResult } from 'interfaces/badge-list-response';

interface CoursesCardsProps {
  dataTable: IBadgeListTemplateResults[] | BadgeResult[];
  navigationIssueId: any;
  onFinishDelete?: () => void;
  loading?: boolean;
}

const AdminPreviewBadgesTable = ({
  dataTable,
  navigationIssueId,
  onFinishDelete,
  loading = false,
}: CoursesCardsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [pathContext, setPathContext] = React.useState<string>('');
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [dataToModal, setDataToModal] = useState({ id: '', name: '', count: 0 });
  const [showModalProfileCompleted, setShowModalProfileCompleted] = useState(false);
  const [modal, setModal] = React.useState(false);
  const [modalTwo, setModalTwo] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState<any>();
  const [country, setCountry] = React.useState<any>();

  const user = useAppSelector((state) => state.user);

  const assignRoute = React.useCallback(
    async (id: any) => {
      const plan = await dispatch(GetValidatePlanPathsThunk('/assign-route/'));
      if (GetValidatePlanPathsThunk.rejected.match(plan)) {
        setPathContext('/assign-route/');
        // setModalTwo(!modalTwo);
        history.push(`${AdminRole.assingBadgesPaths}/${id}`);
      } else {
        history.push(`${AdminRole.assingBadgesPaths}/${id}`);
      }
    },
    [dispatch, history]
  );

  const archivedBadge = React.useCallback(
    async (id: string) => {
      let data: IArchivedBadge = {
        uuid: id,
        status: {
          status: 'archived',
        },
      };
      const badge = await dispatch(PatchArchivedBadgeThunk(data));
      if (PatchArchivedBadgeThunk.rejected.match(badge)) {
        toast.error(badge.payload as string);
      } else {
        history.go(0);
      }
    },
    [dispatch, history]
  );

  const handleNavigation = (id: string) => {
    if (!user.adminInfo?.is_completed) {
      setShowModalProfileCompleted(true);
      return;
    }
    navigationIssueId(id);
  };

  const handleBadgeDetail = (id: string) => {
    history.push(`${AdminRole.templateBadgeDetail}/${id}`);
  };

  const handleBadgeDetailUsers = (id: string, name: string) => {
    let encodeName = encodeURIComponent(name);
    history.push(`${AdminRole.badgesPreviewUsers}/${encodeName}/${id}`);
  };

  const handleEditBadge = async (id: string) => {
    try {
      const resp: any = await checkIfBadgeWasEmitted(id);
      const { uuid, name, count } = resp.data;
      if (count > 0) {
        setDataToModal({ name, count, id: uuid });
        setShowModalEdit(true);
      } else {
        history.push(`${AdminRole.editBadge}/${uuid}`);
      }
    } catch (e) {
      manageErrors(e);
    }
  };

  const newPhoneForm = async () => {
    if (phoneNumber !== '') {
      const numberRequest: any = {
        uuid: user.userInfo?.cognito_id,
        path: {
          path: pathContext,
        },
        new_number: {
          user: {
            phone_number: phoneNumber,
          },
          accredited: {
            country: country.dialCode,
          },
        },
      };
      const newPhone = await dispatch(ChangeNumberThunk(numberRequest));

      if (ChangeNumberThunk.fulfilled.match(newPhone)) {
        toast.success('Se ha modificado el perfil con éxito');
        setModalTwo(!modalTwo);
      } else {
        toast.error(newPhone.payload as string);
      }
    }
  };

  const handleDeleteBadge = (uuid: string) => {
    Swal.fire({
      title: '¿Estas seguro que quieres eliminar esta insignia?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Eliminar',
      confirmButtonColor: '#ff0000',
      cancelButtonColor: '#ffffff',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteBadgetemplate(uuid);
          toast.success('La insignia ha sido eliminada');
          if (onFinishDelete) {
            onFinishDelete();
          }
        } catch (e) {
          manageErrors(e);
        }
      }
    });
  };

  const handlePublishBadge = async (id: string) => {
    toast.promise(
      setAsPublishBagdeTemplate(id),
      {
        loading: 'Procesando',
        success: 'Se ha guardado correctamente',
        error: 'Ha ocurrido un error',
      },
      { style: { minWidth: '250px' }, duration: 3000 }
    );
    setTimeout(() => {
      window.location.reload();
    }, 3500);
  };

  return (
    <React.Fragment>
      <ModalBeforeBadgeEmit
        show={showModalProfileCompleted}
        toogle={() => setShowModalProfileCompleted(!showModalProfileCompleted)}
      />
      <ModalBeforeEditBadge
        data={dataToModal}
        show={showModalEdit}
        toogle={() => setShowModalEdit(!showModalEdit)}
      />
      <Card>
        <Table hover responsive striped style={{ margin: '30px 0' }}>
          <thead>
            <tr>
              <th>Insignias</th>
              <th></th>
              <th>Estatus</th>
              <th>Creación</th>
              <th>Emisiones</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td className="text-center" colSpan={6}>
                  <AcredittaLoader width={50} height={50} />
                </td>
              </tr>
            )}
            {!loading &&
              dataTable.map((path: IBadgeListTemplateResults | BadgeResult, i: number) => {
                return (
                  <tr key={Date.now() + i}>
                    <th style={{ cursor: 'pointer' }}>
                      {path?.collections ? (
                        <Link
                          to={
                            user?.userInfo?.user_type.id === 2
                              ? getBadgeStackableDetailAdmin(path?.uuid)
                              : getBadgeStackableDetailAcreditado(path?.uuid)
                          }
                        >
                          <ImagesStackableBadges
                            altura={40}
                            master={path.image_url}
                            badges={path.collections.map((badge: any) => badge.image_url)}
                          />
                        </Link>
                      ) : (
                        <div
                          onClick={() => handleBadgeDetail(path.uuid)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '50px',
                          }}
                        >
                          <img src={path?.image_url} style={{ width: '100%' }} alt="img" />
                        </div>
                      )}
                    </th>
                    <th style={{ cursor: 'pointer' }}>{path?.name.substring(0, 50)}</th>
                    <td>
                      {path?.status === 'active' ? (
                        <Badge color="success">Activa</Badge>
                      ) : path?.status === 'draft' ? (
                        <Badge color="warning">Borrador</Badge>
                      ) : (
                        <Badge color="danger">Inactiva</Badge>
                      )}
                    </td>
                    <td>{path?.updated_at.substring(0, 10)}</td>
                    <td>
                      {path.users && path.users.length !== 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleBadgeDetailUsers(path.uuid, path.name)}
                        >
                          <img
                            src={path.users[0].avatar}
                            style={{
                              width: '30px',
                              height: '30px',
                              borderRadius: '30px',
                            }}
                            alt="img"
                          />
                          {path.users.length > 1 && (
                            <img
                              src={path.users[1].avatar}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginLeft: '-15px',
                              }}
                              alt="img"
                            />
                          )}
                          {path.users.length > 2 && (
                            <img
                              src={path.users[2].avatar}
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginLeft: '-15px',
                              }}
                              alt="img"
                            />
                          )}
                          {path.get_issue_count > 3 && (
                            <div
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                marginLeft: '-15px',
                                backgroundColor: '#002057',
                              }}
                            >
                              <h6
                                style={{
                                  color: 'white',
                                  textAlign: 'center',
                                  fontWeight: 'bold',
                                  marginTop: '8px',
                                  fontSize: '10px',
                                }}
                              >
                                +{path.get_issue_count - 3}
                              </h6>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <h6
                            style={{
                              color: 'black',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: '15px',
                            }}
                          >
                            _
                          </h6>
                        </div>
                      )}
                    </td>
                    <td>
                      <ButtonToolbar>
                        {path?.status === 'draft' && (
                          <Row>
                            <UncontrolledDropdown>
                              <DropdownToggle>
                                <icons.SeeMoreIcon />
                              </DropdownToggle>
                              <DropdownMenu>
                                {path?.provider === 'Acreditta' && (
                                  <OptionDropDownButton
                                    icon={icons.BlockIcon}
                                    text="Publicar"
                                    uuid={path.uuid}
                                    handleClick={() => handlePublishBadge(path.uuid)}
                                  />
                                )}
                                <DropdownItem onClick={() => handleEditBadge(path.uuid)}>
                                  <icons.EditPath
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                    }}
                                  />
                                  Editar
                                </DropdownItem>
                                {!path.users && (
                                  <OptionDropDownButton
                                    icon={icons.DeleteBlueIcon}
                                    uuid={path.uuid}
                                    text="Eliminar insignia"
                                    handleClick={() => handleDeleteBadge(path.uuid)}
                                  />
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Row>
                        )}

                        {path?.status === 'active' && (
                          <Row>
                            {!path?.collections && (
                              <AcredittaActionButton
                                text="Emitir"
                                icon={() => <icons.IssueIcon />}
                                onPress={() => handleNavigation(path.uuid)}
                              />
                            )}
                            <UncontrolledDropdown>
                              <DropdownToggle>
                                <icons.SeeMoreIcon />
                              </DropdownToggle>

                              <DropdownMenu>
                                {path.provider === 'Acreditta' && !path.collections && (
                                  <DropdownItem onClick={() => handleEditBadge(path.uuid)}>
                                    <icons.EditPath
                                      style={{
                                        width: '15px',
                                        height: '15px',
                                      }}
                                    />
                                    Editar
                                  </DropdownItem>
                                )}
                                {!path?.collections && (
                                  <>
                                    <DropdownItem onClick={() => assignRoute(path.uuid)}>
                                      <icons.FileIcon
                                        style={{
                                          width: '15px',
                                          height: '15px',
                                        }}
                                      />
                                      Vincular
                                    </DropdownItem>

                                    <DropdownItem onClick={() => archivedBadge(path.uuid)}>
                                      <icons.DragAndDropUnionIcon
                                        style={{
                                          width: '15px',
                                          height: '15px',
                                        }}
                                      />
                                      Archivar
                                    </DropdownItem>
                                    <OptionDropDownButton
                                      icon={icons.duplicateIcon}
                                      uuid={path.uuid}
                                      text="Duplicar Insignia"
                                      handleClick={() =>
                                        history.push(`${AdminRole.duplicateBadgeUrl}/${path.uuid}/`)
                                      }
                                    />
                                  </>
                                )}

                                {path.provider === 'Acreditta' &&
                                  !path.users &&
                                  !path?.collections && (
                                    <OptionDropDownButton
                                      icon={icons.DeleteBlueIcon}
                                      uuid={path.uuid}
                                      text="Eliminar insignia"
                                      handleClick={() => handleDeleteBadge(path.uuid)}
                                    />
                                  )}

                                {path?.collections && (
                                  <OptionDropDownButton
                                    icon={icons.DeleteBlueIcon}
                                    uuid={path.uuid}
                                    text="Eliminar insignia"
                                    handleClick={() => handleDeleteBadge(path.uuid)}
                                  />
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Row>
                        )}
                      </ButtonToolbar>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
      <ModalContainer
        isOpen={modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyled>
          <ModalTitle>¡Todo listo!</ModalTitle>
          <ModalIcons>
            <icons.Icono_Acreditta
              style={{
                width: '30%',
                marginRight: '20px',
              }}
            />
            <ReactLoading type="bars" color={'#002057'} height={100} width={100} />
            <img
              src={LogoCredly}
              alt="img"
              style={{
                width: '30%',
                marginLeft: '20px',
              }}
            />
          </ModalIcons>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5%',
            }}
          >
            <ModalText>
              Te enviaremos a la página principal de <strong>Credly</strong> para que puedas crear
              tu nueva insignia digital.
            </ModalText>
            <ModalText>
              Recuerda <strong>iniciar sesión</strong> con el correo de tu organización.
            </ModalText>
            <ModalTextFooter>
              Una vez hayas creado la insignia no olvides regresar a nuestra plataforma
            </ModalTextFooter>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <ButtonCom
                label="Cancelar"
                widthCustom={150}
                lightMode={true}
                onClick={() => setModal(!modal)}
                style={{
                  padding: '5%',
                }}
              />
              <a
                href={`https://www.credly.com/mgmt/organizations/${user.adminInfo?.uuid_credly}/badges/templates/new`}
                target="_blank"
                rel="noreferrer"
                onClick={() => setModal(!modal)}
                style={{
                  padding: '5%',
                }}
              >
                <ButtonCom label={'Ir a Credly'} widthCustom={200} />
              </a>
            </div>
          </div>
        </ModalBodyStyled>
      </ModalContainer>
      <ModalContainer
        isOpen={modalTwo}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        centered={true}
      >
        <ModalBodyStyled>
          <ModalTitle>¡Mejora tu cuenta!</ModalTitle>
          <ModalIcons>
            <icons.Imagen_Upgrade />
          </ModalIcons>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5%',
            }}
          >
            {user.userInfo !== null && (
              <ModalText>
                Actualmente cuentas con un plan{' '}
                <strong>{user.userInfo?.organization.plan.name}</strong>, para obtener todas las
                funcionalidades del plan{' '}
                <strong>{user.userInfo?.organization.plan.next_plan.name}</strong> déjanos tus datos
                y el área de ventas se contactará contigo lo más pronto posible.
              </ModalText>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <PhoneInput
                regions={['north-america', 'south-america', 'central-america']}
                country="co"
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                value={phoneNumber}
                onChange={(phoneNumber, country) => {
                  setPhoneNumber(phoneNumber);
                  setCountry(country);
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                padding: '5%',
              }}
            >
              <ButtonCom
                label="Cancelar"
                widthCustom={150}
                lightMode={true}
                onClick={() => setModalTwo(!modalTwo)}
                style={{
                  padding: '5%',
                }}
              />
              <div
                style={{
                  padding: '5%',
                }}
              >
                <ButtonCom
                  label={'Solicitar Mejora'}
                  widthCustom={200}
                  onClick={() => newPhoneForm()}
                />
              </div>
            </div>
          </div>
        </ModalBodyStyled>
      </ModalContainer>
    </React.Fragment>
  );
};

export default AdminPreviewBadgesTable;
