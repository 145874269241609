import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaText from 'components/atoms/AcredittaText';
import icons from 'assets/Icon';
import { useContext, useEffect, useRef, useState } from 'react';
import { BadgeContext } from './BadgeContext';
import { manageErrors } from '../../../helpers/manage-error';
import { badgeUpdateImage } from 'actions/ApiClient/BadgesAction';

const BadgeEditImage = () => {
  const { badge } = useContext(BadgeContext);
  const { image, image_url } = badge;
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [currentImage, setCurrentImage] = useState<string>('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      setCurrentImage(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const handleChange = async (e: any) => {
    try {
      setUploading(true);
      const image = e.target.files[0];
      const formData = new FormData();
      formData.append('image', image!);
      await badgeUpdateImage(badge.uuid, formData);
      setSelectedFile(image);
      setUploading(false);
    } catch (e) {
      setUploading(false);
      manageErrors(e);
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={handleChange}
        accept="image/*"
      />
      <img
        src={currentImage || image || image_url}
        width="350"
        height="auto"
        alt=""
        style={{ margin: '10px auto', display: 'block', objectFit: 'cover'}}
      />
      <div className="text-center mx-auto">
        <AcredittaButton
          type="button"
          onPress={() => inputRef.current?.click()}
          variant="primary"
          title="Cargar imágen"
          isLoading={uploading}
        />
      </div>

      <div className="row">
        <div className="col-2 text-right mt-2">
          <icons.CheckIcon height={16} />
        </div>
        <div className="col-10">
          <AcredittaText variant="body1" color="secondary" align="justify">
            Imagen formato PNG con dimensión máxima de 600x600 pixeles y 10MB
          </AcredittaText>
        </div>
      </div>

      <div className="row">
        <div className="col-2 text-right mt-2">
          <icons.CheckIcon height={16} />
        </div>
        <div className="col-10">
          <AcredittaText variant="body1" color="secondary" align="justify">
            Esta imagen será la foto de portada de tu insignia.
          </AcredittaText>
        </div>
      </div>
    </div>
  );
};

export default BadgeEditImage;
