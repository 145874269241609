import VideoCheck from 'components/molecules/VideoCheck';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { StepWizardProps } from 'react-step-wizard';
import { setSeenFirst, setSeenSecond, setSeenThird } from 'states/slices/onboarding.slice';

import {
  ButtonsContainer,
  VideoTitle,
  VideoNumber,
  VideoNumberContainer,
  IconLineContainer,
  VideoContainer,
  StepVideoContainer,
  VideoRows,
  VideoStepRows,
  Container,
} from './styles';
import {
  StepContainer,
  StepTitle,
  StepSubtitle,
} from 'components/molecules/OnBoardingSteps/CommonStyles/styles';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';
import { useHistory } from 'react-router-dom';
import { AccreditedRole } from 'constants/NavigationUrls';
import toast from 'react-hot-toast';
import { logEvent } from 'utils/GA/analytics';

interface Step2Props extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const Step2: React.FC<Step2Props> = ({ onNextStep }) => {
  const introStatus = useAppSelector((state) => state.onboarding.introSeen);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const seenVideo = (video: string) => {
    switch (video) {
      case 'first':
        return dispatch(setSeenFirst());
      case 'second':
        return dispatch(setSeenSecond());
      case 'third':
        return dispatch(setSeenThird());
    }
  };

  const checkNextStep = React.useMemo(() => {
    return introStatus.first && introStatus.second && introStatus.third;
  }, [introStatus.first, introStatus.second, introStatus.third]);

  const navigationSkip = () => {
    logEvent('Onboarding', 'Skip Onboarding video section');
    history.push(AccreditedRole.profile);
  };

  const nextStepHandler = () => {
    if (checkNextStep) {
      logEvent('Onboarding', 'Completed the video section');
      onNextStep();
    } else {
      toast.error('Para poder continuar es necesario finalizar los 3 videos');
    }
  };

  return (
    <StepContainer>
      <StepTitle>¿Estás listo? Gana tu primera insignia</StepTitle>
      <StepSubtitle>Ve los siguientes videos para obtener una insignia</StepSubtitle>
      <Container>
        <VideoRows>
          <StepVideoContainer lg="4" sm="4" xs="12">
            <VideoContainer>
              <VideoCheck
                title="¿Como conectamos habilidades?"
                src={`l1na7HRkb1Y`}
                changeStatus={() => seenVideo('first')}
              />
              <VideoTitle>¿Como conectamos habilidades?</VideoTitle>
            </VideoContainer>
          </StepVideoContainer>
          <StepVideoContainer lg="4" sm="4" xs="12">
            <VideoContainer>
              <VideoCheck
                title="¿Qué son las insignias digitales?"
                src={`rwmE3qEiKnM`}
                changeStatus={() => seenVideo('second')}
              />
              <VideoTitle>¿Qué son las insignias digitales?</VideoTitle>
            </VideoContainer>
          </StepVideoContainer>
          <StepVideoContainer lg="4" sm="4" xs="12">
            <VideoContainer>
              <VideoCheck
                title="Retos de los Profesionales del siglo XXI"
                src={`LpLu32ulQtc`}
                changeStatus={() => seenVideo('third')}
              />
              <VideoTitle>Retos de los Profesionales del siglo XXI</VideoTitle>
            </VideoContainer>
          </StepVideoContainer>
        </VideoRows>
        <VideoStepRows>
          <VideoNumberContainer xs="auto">
            {introStatus.first ? <icons.CheckIcon /> : <VideoNumber>1</VideoNumber>}
          </VideoNumberContainer>
          <IconLineContainer xs="auto">
            <icons.LineIcon />
          </IconLineContainer>
          <VideoNumberContainer xs="auto">
            {introStatus.second ? <icons.CheckIcon /> : <VideoNumber>2</VideoNumber>}
          </VideoNumberContainer>
          <IconLineContainer xs="auto">
            <icons.LineIcon />
          </IconLineContainer>
          <VideoNumberContainer xs="auto">
            {introStatus.third ? <icons.CheckIcon /> : <VideoNumber>3</VideoNumber>}
          </VideoNumberContainer>
        </VideoStepRows>
      </Container>
      <ButtonsContainer>
        <Button label="Omitir" onClick={navigationSkip} lightMode={true} widthCustom={150}></Button>
        <Button label="Siguiente" onClick={nextStepHandler} widthCustom={150}></Button>
      </ButtonsContainer>
    </StepContainer>
  );
};

export default Step2;
