import * as React from 'react';
import {
  AdminCreateUserHeaderWrapper,
  IconAddPeople,
  IconAddUser,
  IconText,
  IconWrapper,
  MainTitle,
  TitleWrapper,
} from './styles';

interface AdminCreateUserHeaderProps {
  title: string;
  navigateIndividual?: () => void;
  navigateMassive?: () => void;
}

const AdminCreateUserHeader: React.FC<AdminCreateUserHeaderProps> = ({
  title,
  navigateIndividual,
  navigateMassive,
}) => {
  return (
    <AdminCreateUserHeaderWrapper>
      <IconWrapper onClick={navigateIndividual}>
        <IconAddUser />
        <IconText>Individual</IconText>
      </IconWrapper>
      <TitleWrapper>
        <MainTitle>{title}</MainTitle>
      </TitleWrapper>
      <IconWrapper onClick={navigateMassive}>
        <IconAddPeople />
        <IconText>Masiva</IconText>
      </IconWrapper>
    </AdminCreateUserHeaderWrapper>
  );
};

export default AdminCreateUserHeader;
