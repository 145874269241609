import AcredittaBox from 'components/atoms/AcredittaBox';
import AcredittaText from 'components/atoms/AcredittaText';
import ImagesStackableBadges from 'components/atoms/ImagesStackableBadges/ImagesStackableBadges';
import { AdminRole, getBadgeStackableDetailAdmin } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';
import './style.css';

const CardBadge = ({ item }: any) => {
  const { push } = useHistory();

  const handleLinks = (id : string) => {
    if(item.collections !== null){
      push(getBadgeStackableDetailAdmin(id))
    }else{
      push(`${AdminRole.templateBadgeDetail}/${id}`)
    }
  }

  return (
    <div
      key={item}
      className="col-xs-12 col-sm-12 col-md-4 col-lg-3"
      style={{ cursor: 'pointer' }}
      onClick={() => handleLinks(item.id)}
    >
      <AcredittaBox boxStyle={{ padding: 0 }}>
        <div className="main">
          <div className="row1">
            <AcredittaText variant="h5" align="center">
              {item.name.substring(0, 55)} {item.name.length > 55 ? '...' : ''}
            </AcredittaText>
          </div>
          <div className="row2">
            {item.collections ? (
              <ImagesStackableBadges
                altura={100}
                master={item.image.url}
                badges={item.collections.map((badge: any) => badge.image_url)}
                center
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  alt={item.name}
                  src={item.image.url}
                  width={150}
                  height={150}
                  style={{
                    borderRadius: '50%',
                    margin: '0px auto',
                    display: 'block',
                  }}
                />
              </div>
            )}
          </div>
          <div className="row3">
            <AcredittaText variant="body1" color="secondary" align="center">
              {item.provider}
            </AcredittaText>
          </div>
        </div>
      </AcredittaBox>
    </div>
  );
};

export default CardBadge;
