import { createSlice } from '@reduxjs/toolkit';
import { DescriptionCourseThunk } from 'actions/Thunks/DescriptionCourseThunk';

const COURSEP = 'COURSEP';

const initialState: any = {
  coursePreview: null,
};

const CoursePreview = createSlice({
  name: COURSEP,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(DescriptionCourseThunk.fulfilled, (state, { payload }) => {
      state.coursePreview = payload;
    });
  },
});

export default CoursePreview.reducer;
