import * as Sentry from '@sentry/react';
import { AdminUrls, API_URL } from 'constants/ApiUrls';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetDescriptionCourse(id: number): Promise<any> {
  try {
    const descriptionCourse = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.coursePreview}${id}/`
    );

    if (descriptionCourse.okay && descriptionCourse.data) {
      return descriptionCourse.data;
    } else {
      throw Error(descriptionCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
