import { CodeVerifyThunk } from 'actions/Thunks/AuthThunk';
import CodeValidate from 'components/molecules/ValidateCode';
import { AuthNavigations } from 'constants/NavigationUrls';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { objectValidation } from 'utils';
import AuthPageLayout from '../Layout/AuthPageLayout';
import { setTimeout } from 'timers';

const CodeSingUp: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const admin = useAppSelector((state) => state.admin);

  const defaultValues: SingUpValidate = {
    email: admin.emailSingUp,
    code: '',
  };

  const getDefaultFormValues = (): SingUpValidate => {
    return defaultValues;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SingUpValidate>({ ...objectValidation, defaultValues: getDefaultFormValues() });

  const onSubmitConfirmCode = React.useCallback(
    async (user: SingUpValidate) => {
      setLoading(true);
      const confirm = await dispatch(CodeVerifyThunk(user));
      if (CodeVerifyThunk.rejected.match(confirm)) {
        setLoading(false);
        toast.error(confirm.payload as string);
      } else {
        setLoading(false);
        toast.success(
          'Su correo ha sido verificado con éxito, ahora puedes ingresar con él a la plataforma'
        );
        setTimeout(() => {
          history.replace(AuthNavigations.login);
        }, 800);
      }
    },
    [history, dispatch]
  );

  return (
    <AuthPageLayout showFooter={false} showHeader={true}>
      <CodeValidate
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        onSubmitConfirmCode={onSubmitConfirmCode}
        loading={loading}
      />
    </AuthPageLayout>
  );
};

export default CodeSingUp;
