import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthNavigations, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import Login from 'pages/Auth/Login';
import Recovery from 'pages/Auth/Recovery';
import TermsAnConditionsPage from 'pages/TermsAndConditions';
import PoliciesPage from 'pages/Policies';
import CheckEmailByInvitePage from 'pages/Auth/CheckEmailByInvite';
import BadgeTemplateDetails from 'pages/Badges/Unauthorized/BadgeTemplateDetail';
import OrgBadgesTemplates from 'pages/Badges/Unauthorized/OrganizationBadges';
import Welcome from 'pages/Auth/Welcome';
import ExpiredCode from 'pages/Auth/ExpiredCode';
import SingUp from 'pages/Auth/Singup';
import CodeSing from 'pages/Auth/CodeSingUP';

const AuthNavigation = (): JSX.Element => {
  return (
    <Switch>
      <Route path={AuthNavigations.login} component={Login} />
      <Route path={AuthNavigations.singup} component={SingUp} />
      <Route path={AuthNavigations.recovery} component={Recovery} />
      <Route path={AuthNavigations.codeSingUp} component={CodeSing} />
      <Route path={AuthNavigations.welcome} component={Welcome} />
      <Route path={AuthNavigations.expiredCode} component={ExpiredCode} />
      <Route path={NonAuthenticatedUrls.termsAndConditions} component={TermsAnConditionsPage} />
      <Route path={NonAuthenticatedUrls.policies} component={PoliciesPage} />
      <Route
        path={`${NonAuthenticatedUrls.acceptInvite}/:uuid`}
        component={CheckEmailByInvitePage}
      />
      <Route path={`${NonAuthenticatedUrls.badges_template}`} component={BadgeTemplateDetails} />
      <Route path={`${NonAuthenticatedUrls.organization_badges}`} component={OrgBadgesTemplates} />
    </Switch>
  );
};

export default AuthNavigation;
