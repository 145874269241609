import { Label } from 'reactstrap';
import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';

export const AdminOrganizeLearningPathContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.25);
  border-radius: 8px;
  padding: 60px;
  margin: 40px 80px;
`;

export const SectionTitle = styled(Label)`
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  text-align: center;
  margin: 20px 0px;
`;

export const SectionSubTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 27px;
  color: ${PRIMARY_COLOR};
`;
