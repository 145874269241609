import './styles.css'

interface StackableI {
  master : any,
  badges: any,
  altura: number,
  center?: boolean
}

const ImagesStackableBadges = ({master, badges, altura, center = false} : StackableI) => {

  const handleWidth = () =>{
    if(altura === 100 && badges.length <= 2){
      return altura - 10 + 40
    }
    if(altura === 100 && badges.length >= 3){
      return altura - 10 + 80
    }
    if (altura === 70 && badges.length <= 2) {
      return altura - 10 + 20
    }
    if (altura === 70 && badges.length >= 3) {
      return altura - 10 + 40
    }
    if (altura === 40 && badges.length <= 2) {
      return altura - 10 + 10
    }
    if (altura === 40 && badges.length >= 3) {
      return altura - 10 + 20
    }
  }

  return (
    <div style={{height: `${altura}px`, justifyContent: center ? 'center' : 'flex-start'}} className='stackable-badges__container'>
      <div style={{width: `${handleWidth()}px`}} className='stackable-badges'>
        {badges.slice(0,3).map((e: any, index: number) => (
        <div className='stackable-badges__badge' style={{left: `${altura === 100 ? (index * 4) : altura === 70 ? (index * 2): index}0px`, height: `${altura - 10}px`, width: `${altura - 10}px`}}>
          <img src={e} alt="imagen" />
        </div>
      ))}
      </div>
      
      <div style={{height: `${altura}px`, width: `${altura / 2}px`}} className="master-badge">
        <img style={{left: `-${altura / 2}px`}} src={master} alt="master badge" />
      </div>
    </div>
  )
}

export default ImagesStackableBadges