import { validateBadgeCertificate } from 'actions/ApiClient/certificates-pdf';
import { manageErrors } from 'helpers/manage-error';
import { useEffect, useState } from 'react';

interface UseGetCertificatedPfdI {
  showButton: boolean;
  isFetching: boolean;
}

const useGetCertificatePdf = (badgeId: string): UseGetCertificatedPfdI => {
  const [showButton, setShowButton] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsFetching(true);
        await validateBadgeCertificate(badgeId);
        setIsFetching(false);
        setShowButton(true);
      } catch (e) {
        setIsFetching(false);
        setShowButton(false);
        manageErrors(e);
      }
    })();
  }, [badgeId]);

  return { showButton, isFetching };
};

export default useGetCertificatePdf;
