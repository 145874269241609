import ReactGA from 'react-ga';
import { TRACKING_ID_GA } from 'utils';

export const initGA = () => {
  if (TRACKING_ID_GA) {
    ReactGA.initialize(TRACKING_ID_GA);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

export const logPageView = (path: string) => {
  ReactGA.set({ page: path });
  ReactGA.pageview(path);
};

export const logEvent = (category: string, action: string, label?: string) => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

export const logException = (description: string, fatal?: boolean) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
