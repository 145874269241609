import * as React from 'react';
import icons from 'assets/Icon';
import CommonPageLayout from '../Layout/CommonPageLayout';
import toast from 'react-hot-toast';
import { ListPathThunk } from 'actions/Thunks/AdminPathsThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  ContainerNoData,
  CourseCatalogContainer,
  CourseCatalogHeaderTitles,
  CourseCatalogSubTitles,
  SubtitleFirst,
  SubtitleSecond,
} from './styles';
import { Link, useHistory } from 'react-router-dom';
import { AccreditedRole } from 'constants/NavigationUrls';
import {
  Badge,
  Button,
  ButtonToolbar,
  Card,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
} from 'reactstrap';
import { DeletePaths } from 'actions/ApiClient/AdminPaths';
import NoDataPaths from 'components/molecules/NoDataPaths';
import ReactLoading from 'react-loading';

const AdminPreviewPaths: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [modal, setModal] = React.useState(false);
  const [idRoute, setIdRoute] = React.useState();
  const [nameRoute, setNameRoute] = React.useState();

  const paths = useAppSelector((state) => state.listPaths);

  const toggle = (id: any, name: any) => {
    setModal(!modal);
    setIdRoute(id);
    setNameRoute(name);
  };

  const getListPaths = React.useCallback(async () => {
    const pathsList = await dispatch(ListPathThunk());
    if (ListPathThunk.rejected.match(pathsList)) {
      toast.error(pathsList.payload as string);
    }
  }, [dispatch]);

  const editPath = (id: any) => {
    if (paths.listPaths) {
      history.push(`${AccreditedRole.editPath}/${id}`);
    }
  };

  const deletePths = () => {
    if (idRoute) {
      setModal(!modal);
      DeletePaths(idRoute);
      history.go(0);
      toast.success('Ruta Eliminada Correctamente');
    }
  };

  React.useEffect(() => {
    getListPaths();
  }, [getListPaths]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={false}
      showMenuHeaderAcredited={true}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={true}
    >
      <CourseCatalogContainer>
        <CourseCatalogHeaderTitles>
          <CourseCatalogSubTitles>
            <SubtitleFirst>Mis Rutas Creadas</SubtitleFirst>
            <Link to={AccreditedRole.newPathWizz}>
              <SubtitleSecond>Crear una ruta +</SubtitleSecond>
            </Link>
          </CourseCatalogSubTitles>
        </CourseCatalogHeaderTitles>
        <Card>
          <Table hover responsive striped>
            <thead>
              <tr>
                <th>Nombre de la Ruta</th>
                <th>Creación</th>
                <th>Estatus</th>
                <th>Cursos</th>
                {/* <th>Inscritos</th> */}
                <th
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Acciones
                </th>
              </tr>
            </thead>
            {paths.listPaths ? (
              paths.listPaths.length !== 0 ? (
                <tbody>
                  {paths.listPaths.map((path: any) => {
                    return (
                      <tr>
                        <th>
                          <Link to={`${AccreditedRole.learningPathPreview}/${path.id}`}>
                            {path.name}
                          </Link>
                        </th>
                        <td>{path.created_at}</td>
                        <td>
                          {path.is_draft === false ? (
                            <Badge color="success">Publicada</Badge>
                          ) : (
                            <Badge color="warning">Borrador</Badge>
                          )}
                        </td>
                        <td>{path.course_count}</td>
                        {/* <td>-</td> */}
                        <td>
                          <ButtonToolbar
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              {path.is_draft && (
                                <Button onClick={() => editPath(path.id)}>
                                  <icons.EditPath />
                                </Button>
                              )}
                              {path.is_draft && (
                                <FormText
                                  style={{
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Editar
                                </FormText>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <Button onClick={() => toggle(path.id, path.name)}>
                                <icons.DeletePath color="white" />
                              </Button>
                              <FormText
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                Eliminar
                              </FormText>
                            </div>
                            <Modal isOpen={modal} backdrop={false} centered>
                              <ModalBody>
                                <h2 color="#002057">
                                  <strong>¿Deseas eliminar la ruta?</strong>
                                </h2>
                                ¿Estás seguro que quieres eliminar la ruta {nameRoute}?
                                <br />
                                Al hacerlo perderás toda la información
                              </ModalBody>
                              <ModalFooter>
                                <Button onClick={() => setModal(!modal)}>Cancelar</Button>{' '}
                                <Button color="danger" onClick={() => deletePths()}>
                                  Eliminar
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <ContainerNoData
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5%',
                    width: '165%',
                  }}
                >
                  <NoDataPaths />
                </ContainerNoData>
              )
            ) : (
              <ContainerNoData
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '330%',
                  padding: '10%',
                }}
              >
                <ReactLoading type="spin" color={'#002057'} height={80} width={80} />
              </ContainerNoData>
            )}
          </Table>
        </Card>
      </CourseCatalogContainer>
    </CommonPageLayout>
  );
};

export default AdminPreviewPaths;
