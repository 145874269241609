import * as React from 'react';
import {
  CourseContainerSingle,
  CourseItemSessionsSubTitle,
  CourseItemSessionsWrapper,
  CourseItemTitle,
  CourseItemWrapper,
  CourseSessionTime,
  CourseSessionWrapperSingle,
  CoursesSessionContentWrapper,
  CoursesSessionCount,
  CoursesSessionCountWrapper,
  CoursesSEssionLabelSigle,
  CourseTimeWrapper,
} from './styles';
import icons from '../../../assets/Icon';

interface CourseSessionsProps {
  indexElement: number;
  course?: IPathwayDetailContentCourse;
  experience?: IPathExperiences;
}

const CourseSessions = ({ indexElement, course, experience }: CourseSessionsProps): JSX.Element => {
  const [toggle, setToggle] = React.useState<boolean>(false);
  return (
    <React.Fragment>
      <CourseItemWrapper
        $backgroundGray={indexElement % 2 !== 0}
        key={indexElement}
        onClick={course && course.content.length > 0 ? () => setToggle(!toggle) : () => {}}
        $cursor={course ? true : false}
      >
        {course ? (
          <div>
            <CourseItemTitle>
              Curso {indexElement + 1}: {course.name}
            </CourseItemTitle>
            {course.content.length > 0 && (
              <CourseItemSessionsWrapper>
                <icons.PlayButton />
                <CourseItemSessionsSubTitle>
                  {course.content.length} Módulos
                </CourseItemSessionsSubTitle>
              </CourseItemSessionsWrapper>
            )}
          </div>
        ) : (
          experience && (
            <div>
              <CourseItemTitle>
                <a href={experience.url} target="_blank" rel="noopener noreferrer">
                  Experiencia {indexElement + 1}: {experience.name}
                </a>
              </CourseItemTitle>
            </div>
          )
        )}
        {course && course.duration ? (
          <CourseTimeWrapper>
            <icons.AddIcon />
            <CourseSessionTime>
              Tiempo Total: <span>{course.duration}</span>
            </CourseSessionTime>
          </CourseTimeWrapper>
        ) : (
          experience?.duration &&
          experience && (
            <CourseTimeWrapper>
              <CourseSessionTime>
                Tiempo Total: <span>{experience.duration}</span>
              </CourseSessionTime>
            </CourseTimeWrapper>
          )
        )}
      </CourseItemWrapper>
      <CourseContainerSingle isOpen={toggle}>
        {course &&
          course.content.map((singleCourse, index) => (
            <CourseSessionWrapperSingle key={index}>
              <CoursesSessionCountWrapper>
                <CoursesSessionCount>{index + 1}</CoursesSessionCount>
              </CoursesSessionCountWrapper>
              <CoursesSessionContentWrapper>
                <CoursesSEssionLabelSigle>{singleCourse.name}</CoursesSEssionLabelSigle>
              </CoursesSessionContentWrapper>
            </CourseSessionWrapperSingle>
          ))}
      </CourseContainerSingle>
    </React.Fragment>
  );
};

export default CourseSessions;
