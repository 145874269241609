import React, { useContext } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { BadgeImageContext } from '../context/BadgeImageProvider';
import { BadgesStackableContext } from '../context/BadgeStackableProvider';
import BadgeMasterImage from './BadgeMasterImage';

const BagdeMasterImageWithProgress = () => {
  const {
    mainBadge: { progress },
  } = useContext(BadgesStackableContext);
  const { isAdmin } = useContext(BadgeImageContext);

  return (
    <CircularProgressbarWithChildren
      value={Math.round(isAdmin ? 100 : progress)}
      strokeWidth={3}
      styles={buildStyles({
        pathColor: '#02B148',
        trailColor: '#dadada',
      })}
    >
      <BadgeMasterImage />
    </CircularProgressbarWithChildren>
  );
};

export default BagdeMasterImageWithProgress;
