import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const BadgeDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: start;
  justify-content: center;
  margin: 40px;
`;

export const BadgeInfoWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 30px;
`;

export const BadgeName = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;

export const ItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const ItemDesc = styled(Label)`
  font-size: 16px;
  line-height: 23px;
  color: #53565a;
  margin-bottom: 0px;
`;

export const BadgeNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
`;

export const SkillContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const RequirementsWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 5px 0px;
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const MoreInfoWrapper = styled.div`
  display: flex;
  align-items: start;
  svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
`;

export const SkillsSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30%;
`;

export const ShareBadgeContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 40px;
`;

export const ButtonContainer = styled.div`
  margin: 10px 0px;
`;

export const UserOwnerContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
`;

export const UserOwnerText = styled(Label)`
  font-size: 18px;
  line-height: 23px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
  margin-left: 10px;
  span {
    font-family: NotoSansJPBold;
  }
`;
