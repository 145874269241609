import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, Form, FormGroup } from 'reactstrap';
import styled from 'styled-components';

export const FormContainer = styled(Form)`
  padding: 20px 40px;
  width: 100%;

  @media ${device.mediumLaptop} {
    margin-left: 0px;
    margin-top: 20px;
  }
  @media ${device.mediumMobile} {
    padding: 20px;
  }
  @media only screen and (max-width: 380px) {
    padding: 20px 10px;
  }
  @media ${device.smallMobile} {
    margin-top: 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  @media ${device.bigMobile} {
    height: 40px;
  }
`;

export const WarningText = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 25px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin: 15px 0px;
  @media ${device.mediumLaptop} {
    font-size: 20px;
  }
  @media ${device.mediumMobile} {
    font-size: 16px;
  }
`;

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;
  display: flex;
  margin: 0;
  margin-bottom: 10px;

  @media ${device.laptops} {
    flex-direction: column;
  }
`;

export const FormInputWrapper = styled.div`
  width: 100%;

  @media ${device.desktop} {
    width: 100%;
  }
`;

export const FormInputCheckBokWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const WarningHeaderWrapper = styled.div``;

export const WarningMemoLabel = styled(Label)`
  font-size: 16px;
  line-height: 27px;
  @media ${device.mediumLaptop} {
    font-size: 14px;
  }
  @media ${device.mediumMobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const WarningCheckBoxLabel = styled(Label)`
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 0px;
  color: ${PRIMARY_COLOR};
  margin-left: 5px;
  @media ${device.mediumLaptop} {
    font-size: 14px;
  }
  @media ${device.mediumMobile} {
    font-size: 14px;
    line-height: 20px;
  }
  span {
    font-family: NotoSansJPBold;
  }
`;
