import * as React from 'react';
import { CardContainer, CardImage, CardTitleContainer, CardTitleStyled } from './styles';

export interface InterestCardProps {
  title: string;
  image: string;
}

const InterestCard: React.FC<InterestCardProps> = ({ title, image }) => {
  return (
    <CardContainer>
      <CardImage src={image} alt="Card image cap" />
      <CardTitleContainer>
        <CardTitleStyled tag="h5">{title}</CardTitleStyled>
      </CardTitleContainer>
    </CardContainer>
  );
};

export default InterestCard;
