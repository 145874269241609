import * as React from 'react';
import { Row, Col, Container } from 'reactstrap';
import AuthPageLayout from '../Layout/AuthPageLayout';
import {
  TermsAndConditionsContentContainer,
  TermsAndConditionsPageTitle,
  TermsAndConditionsContent,
} from './styles';

interface TermsAndPoliciesProps {
  title: string;
  content: string;
}

const TermsAndPolicies: React.FC<TermsAndPoliciesProps> = ({ title, content }) => {
  return (
    <AuthPageLayout showHeader={true} showFooter={true} childrenMargin={true}>
      <Container>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <TermsAndConditionsPageTitle>{title}</TermsAndConditionsPageTitle>
          </Col>
          <TermsAndConditionsContentContainer sm="12" md={{ size: 6, offset: 3 }}>
            <TermsAndConditionsContent>{content}</TermsAndConditionsContent>
          </TermsAndConditionsContentContainer>
        </Row>
      </Container>
    </AuthPageLayout>
  );
};

export default TermsAndPolicies;
