import { AnyAction, CombinedState, combineReducers, Reducer } from '@reduxjs/toolkit';
import onboardingReducer from './slices/onboarding.slice';
import userReducer from './slices/user.slice';
import authReducer from './slices/auth.slice';
import adminSlice from './slices/admin.slice';
import { RootState } from 'states';
import storage from 'redux-persist/lib/storage';
import cleanReducer from './slices/clean.slice';
import courseReducer from './slices/course.slice';
import catalogCourseSlice from './slices/catalog.slice';
import pathsSlice from './slices/paths.slice';
import deletePath from './slices/paths.slice';
import badgesSlice from './slices/paths.slice';
import interestsProSlice from './slices/paths.slice';
import detailBadgesSlice from './slices/paths.slice';
import listBadgesTemp from './slices/paths.slice';
import acreditedSlice from './slices/acredited.slice';
import CoursePreview from './slices/courseDescription.slice';

export const appReducer = combineReducers({
  user: userReducer,
  onboarding: onboardingReducer,
  auth: authReducer,
  admin: adminSlice,
  clean: cleanReducer,
  courses: courseReducer,
  catalogCourses: catalogCourseSlice,
  listPaths: pathsSlice,
  listBadges: badgesSlice,
  listBadgesTemp: listBadgesTemp,
  detailBadges: detailBadgesSlice,
  deletePaths: deletePath,
  listMyRoutes: acreditedSlice,
  coursePreview: CoursePreview,
  interestsProfile: interestsProSlice,
});

export const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer: Reducer<CombinedState<any>, AnyAction> = (
  state: RootState,
  action: AnyAction
) => {
  if (action.type === 'CLEAN/cleanState') {
    storage.removeItem('persist:root');

    state = {} as RootState;
  }
  return appReducer(state, action);
};

export default rootReducer;
