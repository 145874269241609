import * as React from 'react';
import { Row } from 'reactstrap';
import icons from 'assets/Icon';
import {
  CourseCardContainer,
  CourseTitle,
  DurationWrapper,
  DurationTitleWrapper,
  DurationTitle,
  Duration,
  ProviderWrapper,
  CourseCardsContainer,
} from './styles';

interface AdminCoursesListProps {
  courses: ISelectedCourseList[];
}

const AdminCoursesList = ({ courses }: AdminCoursesListProps): JSX.Element => {
  return (
    <CourseCardsContainer>
      <Row>
        {courses &&
          courses.map((course, indexCourse) => {
            return (
              <CourseCardContainer key={indexCourse} xs={3}>
                <CourseTitle>{course.name}</CourseTitle>
                <DurationWrapper>
                  <DurationTitleWrapper>
                    <icons.Icono_Duracion />
                    <DurationTitle>Duración </DurationTitle>
                  </DurationTitleWrapper>
                  <Duration>{course.duration}</Duration>
                </DurationWrapper>
                <ProviderWrapper>{course.provider_name}</ProviderWrapper>
              </CourseCardContainer>
            );
          })}
      </Row>
    </CourseCardsContainer>
  );
};

export default AdminCoursesList;
