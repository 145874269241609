import useGetUser from 'hooks/useGetUser';
import { Link, useParams } from 'react-router-dom';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import CommonPageLayout from 'components/organisms/Layout/CommonPageLayout';
import AdminEditProfile from './AdminEditProfile';
import styles from './index.module.css';
import icons from 'assets/Icon';
import { AdminRole } from 'constants/NavigationUrls';

type ParamsT = {
  id: string;
};

const AdminUserDetailPage = () => {
  const { id } = useParams() as ParamsT;
  const { loading, user } = useGetUser(id);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <div className="container mt-5">
        {!user && <div className="alert alert-danger">Ha ocurrido un error</div>}

        {loading && (
          <div className="mx-auto">
            <AcredittaLoader />
          </div>
        )}

        {!loading && user && (
          <>
            <div className="row">
              <div className="col-sm-12 col-md-2 pt-1 text-sm-center mb-2">
                <Link to={AdminRole.loadUsers}>
                  <icons.GoBackIcon />
                </Link>
              </div>
              <div className="col-sm-12 col-md-8 pt-1">
                <div className={styles.nameContainer}>
                  <h1 className={styles.name}>
                    {user.first_name} {user.last_name}
                  </h1>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8 mx-auto">
                <div>
                  <img
                    src={user.avatar}
                    width="200"
                    height="200"
                    className={styles.imgAvatar}
                    alt=""
                  />
                </div>
                <AdminEditProfile user={user} />
                <div style={{ height: '200px' }}></div>
              </div>
            </div>
          </>
        )}
      </div>
    </CommonPageLayout>
  );
};

export default AdminUserDetailPage;
