import { LIGHT, SECONDARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, Col } from 'reactstrap';
import styled from 'styled-components';

export const NewOrganizationDetailsFormContainer = styled.div`
  padding: 10px;
  width: 100%;
`;

export const OrganizationIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 400px;
  svg {
    width: 20px;
  }
  @media ${device.mediumMobile} {
    width: 300px;
  }
  @media ${device.smallMobile} {
    width: 230px;
  }
`;

export const WarningImageText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #53565a;
  margin-left: 5px;
  @media ${device.mediumMobile} {
    font-size: 11px;
  }
`;

export const ColStyled = styled(Col)`
  @media ${device.desktop} {
    padding: 10px 0px 0px 20px;
    max-width: 100%;
  }
`;

export const ErrorMessageLabel = styled(Label)`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fb6340;
`;

export const OrganizationImageWrapper = styled.div`
  margin-bottom: 10px;
`;

export const OrganizationImage = styled.img`
  width: 350px;
  height: auto;
  object-fit: cover;
  @media ${device.desktop} {
    width: 250px;
  }
`;

export const InputFileLabel = styled.label`
  width: 320px;
  height: 44px;
  border-radius: 8px;
  background: ${SECONDARY_COLOR};
  padding: 4px 21px;
  cursor: pointer;
  display: inline-block;
  font-family: NotoSansJPBold;
  color: ${LIGHT};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='file'] {
    display: none;
  }
  @media ${device.mediumMobile} {
    width: 300px;
  }
  @media ${device.smallMobile} {
    width: 230px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;
