import styled from 'styled-components';

export const LayoutContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: 'white';
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
`;

export const PublicLayoutContainer = styled.div`
  background-color: white;
  width: 100%;
  height: auto;
`;
