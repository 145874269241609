import React from 'react';
import Button from 'components/atoms/Button';
import {
  InstructionsTitle,
  InstructionsText,
  CSVIconWrapper,
  ButtonsWrapper,
  InstructionsWrapper,
  MasiveUploadCardContainer,
  ButtonWrapper,
  CSVLabel,
  CSVInstructionsLabel,
} from './styles';
import icons from 'assets/Icon';

interface AdminMasiveCoursesUploadCardProps {
  cancelUpload: () => void;
}

const AdminMasiveCoursesUploadCard = ({
  cancelUpload,
}: AdminMasiveCoursesUploadCardProps): JSX.Element => {
  return (
    <MasiveUploadCardContainer>
      <InstructionsWrapper>
        <InstructionsTitle>Carga tu archivo .csv para subir más de un curso</InstructionsTitle>
        <InstructionsText>
          Un archivo .csv te permite realizar la carga de cursos de forma masiva. Recuerda que este
          archivo de texto debe estar delimitado por comas y debe contener hasta 5.000 filas.
          Asegurate de que el archivo .csv esté en el formato requerido y que se completen todos los
          campos obligatorios para subir los datos correctamente.
        </InstructionsText>
        <CSVIconWrapper>
          <icons.FileIcon />
          <CSVLabel>Buscar archivo</CSVLabel>
        </CSVIconWrapper>
      </InstructionsWrapper>
      <InstructionsWrapper>
        <InstructionsTitle>¿No tienes ningún archivo?</InstructionsTitle>
        <InstructionsText>
          No te preocupes, descarga nuestra plantilla y completa los espacio para obtener tu archivo
          .csv y poder subir a plataforma todos los cursos que necesites.
        </InstructionsText>
        <CSVIconWrapper>
          <icons.DownloadFileIcon />
          <CSVInstructionsLabel>Descargar archivo csv</CSVInstructionsLabel>
        </CSVIconWrapper>
      </InstructionsWrapper>
      <ButtonsWrapper>
        <ButtonWrapper>
          <Button label="Cancelar" lightMode={true} widthCustom={150} onClick={cancelUpload} />
        </ButtonWrapper>
        <Button label="Cargar" widthCustom={150} />
      </ButtonsWrapper>
    </MasiveUploadCardContainer>
  );
};

export default AdminMasiveCoursesUploadCard;
