import * as React from 'react';
import { CheckSession } from 'utils/Routing/AuthUserGuard';
import { UserDataThunk } from 'actions/Thunks/UserThunk';
import { AuthenticatedUrls } from 'constants/NavigationUrls';
import { useAppDispatch } from './redux';
import { useHistory } from 'react-router-dom';

export function useUserData(): { userData: () => void } {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userData = React.useCallback(() => {
    CheckSession().then((result) => {
      if (result) {
        dispatch(UserDataThunk()).then((user) => {
          if (UserDataThunk.fulfilled.match(user)) {
            if (user.payload && !user.payload.terms_conditions) {
              history.push(AuthenticatedUrls.terms);
            }
          } else {
            console.error(user.payload as string);
          }
        });
      }
    });
  }, [dispatch, history]);

  return { userData };
}
