import * as React from 'react';
import {
  CoursesCatalogContainer,
  ButtonsContainer,
  ButtonWrapper,
  ContainerNoData,
  ContainNoData,
  SubtitleSecondNoData,
  SubtitleFirstNoData,
} from './styles';
import Button from 'components/atoms/Button';
import CoursesCards from 'components/molecules/CoursesCardCatalog';
import icons from 'assets/Icon';
import Pagination from 'components/molecules/Pagination';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';
import ReactLoading from 'react-loading';
import {
  InterestCourseThunk,
  OrganizationCourseThunk,
  PopularCourseThunk,
  RecommendedCourseThunk,
} from 'actions/Thunks/AdminCatalogThunk';

interface CoursesCardsProps {
  courses: string;
  hideNewCourseSection: () => void;
}

const AdminCourseCatalog = ({ courses, hideNewCourseSection }: CoursesCardsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [dataCourses, setDataCourses] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const getCoursesData = React.useCallback(
    async (courses: string) => {
      switch (courses) {
        case 'recommendedCourseT':
          const recomended = await dispatch(RecommendedCourseThunk(0));
          if (RecommendedCourseThunk.rejected.match(recomended)) {
            toast.error(recomended.payload as string);
          } else {
            setDataCourses(recomended.payload);
            setLoading(false);
          }
          break;
        case 'organizationCourseT':
          const organization = await dispatch(OrganizationCourseThunk(0));
          if (OrganizationCourseThunk.rejected.match(organization)) {
            toast.error(organization.payload as string);
          } else {
            setDataCourses(organization.payload);
            setLoading(false);
          }
          break;
        case 'popularCourseT':
          const popular = await dispatch(PopularCourseThunk(0));
          if (PopularCourseThunk.rejected.match(popular)) {
            toast.error(popular.payload as string);
          } else {
            setDataCourses(popular.payload);
            setLoading(false);
          }
          break;
        case 'interestCourseT':
          const interest = await dispatch(InterestCourseThunk(0));
          if (InterestCourseThunk.rejected.match(interest)) {
          } else {
            setDataCourses(interest.payload);
            setLoading(false);
          }
          break;
        default:
          break;
      }
    },
    [dispatch]
  );

  const handleSearchPage = async (page: number, courses: string) => {
    setCurrentPage(page);
    switch (courses) {
      case 'recommendedCourseT':
        const recomended = await dispatch(RecommendedCourseThunk(page));
        if (RecommendedCourseThunk.rejected.match(recomended)) {
          toast.error(recomended.payload as string);
        } else {
          setDataCourses(recomended.payload);
          setLoading(false);
        }
        break;
      case 'organizationCourseT':
        const organization = await dispatch(OrganizationCourseThunk(page));
        if (OrganizationCourseThunk.rejected.match(organization)) {
          toast.error(organization.payload as string);
        } else {
          setDataCourses(organization.payload);
          setLoading(false);
        }
        break;
      case 'popularCourseT':
        const popular = await dispatch(PopularCourseThunk(page));
        if (PopularCourseThunk.rejected.match(popular)) {
          toast.error(popular.payload as string);
        } else {
          setDataCourses(popular.payload);
          setLoading(false);
        }
        break;
      case 'interestCourseT':
        const interest = await dispatch(InterestCourseThunk(page));
        if (InterestCourseThunk.rejected.match(interest)) {
        } else {
          setDataCourses(interest.payload);
          setLoading(false);
        }
        break;
      default:
        break;
    }
  };

  const hideSection = () => {
    hideNewCourseSection();
  };

  React.useEffect(() => {
    getCoursesData(courses);
  }, [getCoursesData, courses]);

  return (
    <div style={{ marginTop: '50px' }}>
      <CoursesCatalogContainer>
        {loading === false ? (
          dataCourses.results.length !== 0 ? (
            <React.Fragment>
              <CoursesCards courses={dataCourses.results} />
              <Pagination
                currentPage={currentPage}
                totalCount={dataCourses.count}
                pageSize={20}
                onPageChange={(page: number) => handleSearchPage(page, courses)}
              />
            </React.Fragment>
          ) : (
            <ContainerNoData
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5%',
              }}
            >
              <ContainNoData>
                <icons.Icono_Busqueda />
                <SubtitleFirstNoData>No encontramos resultados</SubtitleFirstNoData>
                <SubtitleSecondNoData>
                  Buscamos y no hemos encontrado elementos relacionados
                </SubtitleSecondNoData>
              </ContainNoData>
            </ContainerNoData>
          )
        ) : (
          <ContainerNoData
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10%',
            }}
          >
            <ReactLoading type="spin" color={'black'} height={120} width={120} />
          </ContainerNoData>
        )}
        <ButtonsContainer>
          <ButtonWrapper>
            <Button
              label={'Regresar'}
              lightMode={true}
              widthCustom={150}
              onClick={() => hideSection()}
            />
          </ButtonWrapper>
        </ButtonsContainer>
      </CoursesCatalogContainer>
    </div>
  );
};

export default AdminCourseCatalog;
