import styled from 'styled-components';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';

export const DnDContentStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ebebeb;
`;

export const DnDContentStatusIcon = styled.div`
  text-align: center;

  svg {
    color: ${PRIMARY_COLOR};
  }
`;

export const DnDContentStatusValues = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  color: ${SECONDARY_COLOR};
  text-align: center;
  margin-bottom: 0;
`;

export const DnDContentStatusLabel = styled(Label)`
  text-align: center;
`;
