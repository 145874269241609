import StyledButton from 'components/atoms/Button';
import { S3_APP_GLOBAL } from 'constants/index';
import { AuthNavigations } from 'constants/NavigationUrls';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import PublicLayout from '../Layout/PublicLayout';
import {
  ButtonWrapper,
  ImageMainWrapper,
  ImageCheckEmailByInvite,
  ImageCheckEmailByInviteWrapper,
  MainCheckEmailByInviteTitle,
  CheckEmailByInviteContainer,
  TextLabelCheckEmailByInvite,
} from './styles';
import { useAppDispatch } from '../../../hooks/redux';
import { InviteByUserThunk } from '../../../actions/Thunks/AuthThunk';
import { toast } from 'react-hot-toast';

interface CheckEmailByInviteProps {
  uuid: string;
}

const CheckEmailByInvite = ({ uuid }: CheckEmailByInviteProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [paramsValues] = React.useState<string[]>(uuid.split('&'));

  const navigationBackRoot = () => {
    history.push(AuthNavigations.login);
  };

  const acceptInvite = React.useCallback(async () => {
    const invite = await dispatch(InviteByUserThunk(paramsValues[0]));

    if (InviteByUserThunk.rejected.match(invite)) {
      toast.error(invite.payload as string);
    }
  }, [dispatch, paramsValues]);

  React.useEffect(() => {
    acceptInvite();
  }, [acceptInvite]);

  return (
    <PublicLayout>
      <Container>
        <CheckEmailByInviteContainer>
          <MainCheckEmailByInviteTitle>Revisa tu correo</MainCheckEmailByInviteTitle>
          <TextLabelCheckEmailByInvite>
            Hemos enviado un correo a <b>{paramsValues[1]}</b> con una contraseña temporal para que
            puedas iniciar sesión
          </TextLabelCheckEmailByInvite>
          <ButtonWrapper>
            <StyledButton label="Iniciar Sesión" widthCustom={470} onClick={navigationBackRoot} />
          </ButtonWrapper>
          <ImageMainWrapper>
            <ImageCheckEmailByInviteWrapper>
              <ImageCheckEmailByInvite
                src={`${S3_APP_GLOBAL}InviteByEmail.svg`}
                alt={`${S3_APP_GLOBAL}InviteByEmail.svg`}
              />
            </ImageCheckEmailByInviteWrapper>
          </ImageMainWrapper>
        </CheckEmailByInviteContainer>
      </Container>
    </PublicLayout>
  );
};

export default CheckEmailByInvite;
