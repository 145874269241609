import * as React from 'react';
import { FormGroup } from 'reactstrap';
import { PasswordRecovery, FormContainer, ButtonWrapper } from './styles';
import icons from 'assets/Icon';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import TextPasswordInput from 'components/atoms/TextPasswordInput';
import { useHistory } from 'react-router-dom';
import { AuthNavigations } from 'constants/NavigationUrls';

interface LoginFormProps {
  control: Control<LoginForm>;
  submitLogin: (loginInfo: LoginForm) => void;
  navigateRecovery: () => void;
  errors: Record<string, any>;
  handleSubmit: UseFormHandleSubmit<LoginForm>;
  loading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  control,
  submitLogin,
  navigateRecovery,
  errors,
  handleSubmit,
  loading,
}) => {
  const { push } = useHistory();

  return (
    <FormContainer onSubmit={handleSubmit(submitLogin)}>
      <FormGroup style={{ marginBottom: '2rem' }}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              label="Usuario"
              icon={<icons.UserIcon />}
              type="email"
              name="email"
              placeholder="Ingresa tu email de registro"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="email"
          rules={{ required: 'Email requerido' }}
        />
      </FormGroup>
      <FormGroup style={{ marginBottom: '2rem' }}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextPasswordInput
              label="Contraseña"
              icon={<icons.PasswordIcon />}
              type="password"
              name="password"
              placeholder="Ingresar contraseña"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="password"
          rules={{ required: 'La contraseña es requerida' }}
        />
        <div onClick={navigateRecovery}></div>
      </FormGroup>

      <FormGroup check row>
        <ButtonWrapper>
          <Button label={'Ingresar'} loading={loading} disabled={loading} />
          {'  '}
        </ButtonWrapper>
        <div className="text-center">
          {window.innerWidth > 767 && (
            <div>
              <PasswordRecovery onClick={() => push(AuthNavigations.singup)}>
                <span style={{ fontWeight: 'bold' }}>¿Aún no tienes cuenta?</span> Registrar mi
                organización
              </PasswordRecovery>
            </div>
          )}
          {window.innerWidth <= 767 && (
            <ButtonWrapper>
              <Button
                label={'Registrarse'}
                lightMode
                onClick={() => push(AuthNavigations.singup)}
                type="button"
              />
            </ButtonWrapper>
          )}
        </div>

        <div className="text-center">
          <PasswordRecovery onClick={navigateRecovery}>
            <span style={{ fontWeight: 'bold' }}>¿Olvidaste la contraseña?</span> Recuperar mi
            contraseña
          </PasswordRecovery>
        </div>
      </FormGroup>
    </FormContainer>
  );
};

export default LoginForm;
