import * as React from 'react';
// import OrganizationBadges from 'components/organisms/OrganizationBadgesDashboard';
import ProfileOrganization from 'components/organisms/OrganizationBadgesDashboard/ProfileOrganization';

const OrganizationBadgesDashboard = (): JSX.Element => {
  return (
    <React.Fragment>
      {/* <OrganizationBadges unauthorized={false} showMenuHeader={true} /> */}
      <ProfileOrganization></ProfileOrganization>
    </React.Fragment>
  );
};

export default OrganizationBadgesDashboard;
