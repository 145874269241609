import * as Sentry from '@sentry/react';
import {
  AccountsUrls,
  AdminUrls,
  API_URL,
  NewOrganizationUrls,
  IntegrationsUrls,
} from 'constants/ApiUrls';
import { GetErrorMessage, ValidURL } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';
import { Auth } from 'aws-amplify';

export async function UploadMassiveUsers(file: IAdminCreateCognitoUsersThunk): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('csv_file', file.csv_file);
    formData.append('organization_id', file.organization_id.toString());

    const addMassive = await new HttpRequest().PostForm<any>(
      `${API_URL}${AdminUrls.massiveCreation}`,
      formData,
      file.chunkProgress
    );

    if (addMassive.okay) {
      return 'Se han agregado los usuarios exitosamente.';
    } else {
      throw Error(addMassive.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateNewOrganization(form: ICreateNewOrganization): Promise<IAdminInfo> {
  try {
    const formData = new FormData();
    const domainUrl = ValidURL(form.domain);

    formData.append('name', form.name);
    formData.append('email', form.email);
    formData.append('photo', form.photo);
    formData.append('own_courses', form.own_courses.toString());
    if (domainUrl) formData.append('domain', domainUrl);

    const createNewOrganization = await new HttpRequest().PostForm<IAdminInfo>(
      `${API_URL}${NewOrganizationUrls.createNewOrganization}`,
      formData
    );

    if (createNewOrganization.okay && createNewOrganization.data) {
      return createNewOrganization.data;
    } else {
      throw Error(createNewOrganization.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
export async function CreateNew(
  user_info: INewInfoFormNoPhoto,
  new_photo: INewInfoFormPhoto
): Promise<string> {
  try {
    const awsAuthInfo = await Auth.currentUserInfo();
    const idCognito = awsAuthInfo.attributes.sub;

    const createInterest = await new HttpRequest().Patch(
      `${API_URL}${AccountsUrls.userDataModify}${idCognito}/`,
      user_info
    );

    if (createInterest.okay) {
      const formData = new FormData();

      if (new_photo.avatar) formData.append('avatar', new_photo.avatar);

      const create_image = await new HttpRequest().PatchForm<any>(
        `${API_URL}${AccountsUrls.userDataModify}${idCognito}/`,
        formData
      );

      if (create_image.okay && create_image.data) {
        return 'Se ha modificado el usuario ';
      } else {
        throw Error(create_image.message);
      }
    } else {
      throw Error(createInterest.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateSingleUser(newUser: ICreateSingleUser): Promise<string> {
  try {
    const create = await new HttpRequest().Post<any>(
      `${API_URL}${AdminUrls.createSingleUser}`,
      newUser
    );

    if (create.okay) {
      return 'Usuario creado con éxito';
    } else {
      throw Error(create.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetCountryList(): Promise<ICountryList[]> {
  try {
    const country = await new HttpRequest().Get<ICountryList[]>(`${API_URL}${AdminUrls.countries}`);

    if (country.okay && country.data) {
      return country.data;
    } else {
      throw Error(country.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetDocumentType(countryId: number): Promise<IDocumentType[]> {
  try {
    const documentType = await new HttpRequest().Get<IDocumentType[]>(
      `${API_URL}${AdminUrls.documentType}?country_id=${countryId}`
    );

    if (documentType.okay && documentType.data) {
      return documentType.data;
    } else {
      throw Error(documentType.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetUserType(): Promise<IUsertTypeList[]> {
  try {
    const userType = await new HttpRequest().Get<IUsertTypeList[]>(
      `${API_URL}${AdminUrls.userType}`
    );

    if (userType.okay && userType.data) {
      return userType.data;
    } else {
      throw Error(userType.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetCitiesList(countryId: number): Promise<ICitiesList[]> {
  try {
    const cities = await new HttpRequest().Get<ICitiesList[]>(
      `${API_URL}${AdminUrls.cities}?country_id=${countryId}`
    );

    if (cities.okay && cities.data) {
      return cities.data;
    } else {
      throw Error(cities.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationInterest(): Promise<IGetOrganizationInterest> {
  try {
    const interests = await new HttpRequest().Get<IGetOrganizationInterest>(
      `${API_URL}${AdminUrls.interest}`
    );

    if (interests.okay && interests.data) {
      return interests.data;
    } else {
      throw Error(interests.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetUserDetail(user_id: any): Promise<any> {
  try {
    const userDet = await new HttpRequest().Get<any>(
      `${API_URL}${AccountsUrls.userDataDetail}${user_id}/`
    );

    if (userDet.okay && userDet.data) {
      return userDet.data;
    } else {
      throw Error(userDet.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationHab(): Promise<IGetOrganizationInterest> {
  try {
    const interestHabi = await new HttpRequest().Get<IGetOrganizationInterest>(
      `${API_URL}${AdminUrls.interestHab}`
    );

    if (interestHabi.okay && interestHabi.data) {
      return interestHabi.data;
    } else {
      throw Error(interestHabi.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateInterestForOrganization(
  newInterest: ICreateOrganizationInterest,
  organizationId: number
): Promise<string> {
  try {
    const createInterest = await new HttpRequest().Patch(
      `${API_URL}${AdminUrls.createInterest}${organizationId}/`,
      newInterest
    );

    if (createInterest.okay) {
      return 'Se han creado los intereses correctamente';
    } else {
      throw Error(createInterest.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

// TODO: return type definition
export async function GetDepartmentOrganizationList(organizationId: number) {
  try {
    const departments = await new HttpRequest().Get(
      `${API_URL}${AdminUrls.departmentsForOrganization}?organization_id=${organizationId}`
    );

    if (departments.data && departments.okay) {
      return departments.data;
    } else {
      throw Error(departments.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetUsersRelatedOrganization(
  organizationId: number
): Promise<IOrganizationUser[]> {
  try {
    const users = await new HttpRequest().Get<IOrganizationUser[]>(
      `${API_URL}${AdminUrls.usersForOrganization}?organization_id=${organizationId}`
    );

    if (users.data && users.okay) {
      return users.data;
    } else {
      throw Error(users.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function SendNewUsersInvitations(invitations: INewUsersInvitations): Promise<string> {
  try {
    const send = await new HttpRequest().Post<IUsersInvitations>(
      `${API_URL}${AdminUrls.usersInvitations}`,
      invitations
    );

    if (send.okay) {
      return 'Usuario creado con éxito';
    } else {
      throw Error(send.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CourseCatalogList(
  filter: CourseCatalogThunk
): Promise<ICourseCatalogResponse> {
  try {
    let courses: IHttpResponse<ICourseCatalogResponse>;
    if (filter.page) {
      courses = await new HttpRequest().Post<ICourseCatalogResponse>(
        `${API_URL}${AdminUrls.courseCatalog}?page=${filter.page}`,
        filter.filter
      );
    } else if (filter.search) {
      courses = await new HttpRequest().Post<ICourseCatalogResponse>(
        `${API_URL}${AdminUrls.courseCatalog}?search=${filter.search}`,
        filter.filter
      );
    } else {
      courses = await new HttpRequest().Post<ICourseCatalogResponse>(
        `${API_URL}${AdminUrls.courseCatalog}`,
        filter.filter
      );
    }

    if (courses.okay && courses.data) {
      return courses.data;
    } else {
      throw Error(courses.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CourseCatalogFilterOptions(): Promise<ICourseCatalogFilterOption> {
  try {
    const catalogFilterOptions = await new HttpRequest().Get<ICourseCatalogFilterOption>(
      `${API_URL}${AdminUrls.courseCatalogFilterOptions}`
    );

    if (catalogFilterOptions.okay && catalogFilterOptions.data) {
      return catalogFilterOptions.data;
    } else {
      throw Error(catalogFilterOptions.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateNewLearningExperience(
  newLearningExperience: ICreateNewLearningExperience
): Promise<string> {
  try {
    const createLearningExperience = await new HttpRequest().Post(
      `${API_URL}${AdminUrls.courseCatalogLearningExperience}`,
      newLearningExperience
    );

    if (createLearningExperience.okay) {
      return 'Se ha agregado la experiencia con éxito';
    } else {
      throw Error(createLearningExperience.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function LearningExperienceCategory(): Promise<ILearningExperienceCategory[]> {
  try {
    const categories = await new HttpRequest().Get<ILearningExperienceCategories>(
      `${API_URL}${AdminUrls.learningExperienceCategory}`
    );

    if (categories.okay && categories.data) {
      return categories.data.results;
    } else {
      throw Error(categories.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function LearningPathListView(pathway_id: number): Promise<ILearningPathListView[]> {
  try {
    const learningPath = await new HttpRequest().Get<ILearningPathListView[]>(
      `${API_URL}${AdminUrls.learningPathListView}?pathway=${pathway_id}`
    );

    if (learningPath.okay && learningPath.data) {
      return learningPath.data;
    } else {
      throw Error(learningPath.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UpdateLearningPathListView(
  new_path: IUpdateLearningPathList
): Promise<string> {
  try {
    const update_path = await new HttpRequest().Put<IUpdateLearningPathList>(
      `${API_URL}${AdminUrls.updateLearningPathListView}`,
      new_path
    );

    if (update_path.okay && update_path.data) {
      return 'Se ha actualizado la ruta con éxito';
    } else {
      throw Error(update_path.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreatePathwayCatalogCourse(courseSelected: {
  course: number[];
}): Promise<ICreatePathwayCourseSelected> {
  try {
    const createCourseCatalogPathway = await new HttpRequest().Post<ICreatePathwayCourseSelected>(
      `${API_URL}${AdminUrls.createPathwayCourseCatalog}`,
      courseSelected
    );

    if (createCourseCatalogPathway.okay && createCourseCatalogPathway.data) {
      return createCourseCatalogPathway.data;
    } else {
      throw Error(createCourseCatalogPathway.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function SelectedCourseList(id: number): Promise<ISelectedCourseList[]> {
  try {
    const courseList = await new HttpRequest().Get<ISelectedCourseList[]>(
      `${API_URL}${AdminUrls.courseSelectedCourseList}?pathway=${id}`
    );

    if (courseList.okay && courseList.data) {
      return courseList.data;
    } else {
      throw Error(courseList.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UnauthSelectedCourseList(id: number): Promise<ISelectedCourseList[]> {
  try {
    const courseList = await new HttpRequest().UnAuthGet<ISelectedCourseList[]>(
      `${API_URL}${AdminUrls.courseSelectedCourseListUnauth}?id=${id}`
    );

    if (courseList.okay && courseList.data) {
      return courseList.data;
    } else {
      throw Error(courseList.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DetailPathwayDescription(
  pathway: number
): Promise<IDetailPathwayDescription> {
  try {
    const pathwayDescription = await new HttpRequest().Get<IDetailPathwayDescription>(
      `${API_URL}${AdminUrls.pathwayDescription}${pathway}/`
    );

    if (pathwayDescription.okay && pathwayDescription.data) {
      return pathwayDescription.data;
    } else {
      throw Error(pathwayDescription.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DetailPathwaySkills(id: number): Promise<IDetailPathwaySkills[]> {
  try {
    const skillsPathway = await new HttpRequest().Get<IDetailPathwaySkills[]>(
      `${API_URL}${AdminUrls.pathwaySkills}${id}/`
    );

    if (skillsPathway.okay && skillsPathway.data) {
      return skillsPathway.data;
    } else {
      throw Error(skillsPathway.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DetailPathwayDashboard(id: number): Promise<IDetailPathwayDashboard> {
  try {
    const dashboard = await new HttpRequest().Get<IDetailPathwayDashboard>(
      `${API_URL}${AdminUrls.pathwayDashboard}${id}/`
    );

    if (dashboard.okay && dashboard.data) {
      return dashboard.data;
    } else {
      throw Error(dashboard.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UnauthDetailPathwayDashboard(id: number): Promise<IDetailPathwayDashboard> {
  try {
    const dashboard = await new HttpRequest().UnAuthGet<IDetailPathwayDashboard>(
      `${API_URL}${AdminUrls.pathwayDashboardUnauth}${id}/`
    );

    if (dashboard.okay && dashboard.data) {
      return dashboard.data;
    } else {
      throw Error(dashboard.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DetailPathwayBadges(id: any): Promise<any> {
  try {
    const badgesPaths = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.pathwayBadges}?pathway_id=${id}`
    );

    if (badgesPaths.okay && badgesPaths.data) {
      return badgesPaths.data;
    } else {
      throw Error(badgesPaths.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DetailPathwayContentCourse(
  id: number
): Promise<IPathwayDetailContentCourse[]> {
  try {
    const contentCourse = await new HttpRequest().Get<IPathwayDetailContentCourse[]>(
      `${API_URL}${AdminUrls.pathwayContentCourse}?id=${id}`
    );

    if (contentCourse.okay && contentCourse.data) {
      return contentCourse.data;
    } else {
      throw Error(contentCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UnauthDetailPathwayContentCourse(
  id: number
): Promise<IPathwayDetailContentCourse[]> {
  try {
    const contentCourse = await new HttpRequest().UnAuthGet<IPathwayDetailContentCourse[]>(
      `${API_URL}${AdminUrls.pathwayContentCourseUnauth}?id=${id}`
    );

    if (contentCourse.okay && contentCourse.data) {
      return contentCourse.data;
    } else {
      throw Error(contentCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DetailPathwayContent(id: number): Promise<any> {
  try {
    const contentCourse = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.pathwayDnD}${id}/`
    );

    if (contentCourse.okay && contentCourse.data) {
      return contentCourse.data;
    } else {
      throw Error(contentCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function SaveNewLearningPath(
  path_id: number,
  new_path: IUpdatePathNoPhoto,
  new_photo: IPhotoNewLearningPath
): Promise<string> {
  try {
    const create_new_path = await new HttpRequest().Patch<any>(
      `${API_URL}${AdminUrls.newLearningPath}${path_id}/`,
      new_path
    );

    if (create_new_path.okay) {
      const formData = new FormData();

      if (new_photo.photo) formData.append('photo', new_photo.photo);

      const create_image = await new HttpRequest().PatchForm<any>(
        `${API_URL}${AdminUrls.newLearningPath}${path_id}/`,
        formData
      );

      if (create_image.okay && create_image.data) {
        return 'Se han creado la nueva ruta con éxito';
      } else {
        throw Error(create_image.message);
      }
    } else {
      throw Error(create_new_path.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateNewIssue(user_info: any): Promise<any> {
  try {
    const createNewCourse = await new HttpRequest().Post<any>(
      `${API_URL}integrations/credly-badge/`,
      user_info
    );

    if (createNewCourse.okay && createNewCourse.data) {
      return createNewCourse.data;
    } else {
      throw Error(createNewCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function NewIssue(user_info: any): Promise<any> {
  try {
    const createNewCourse = await new HttpRequest().Post<any>(
      `${API_URL}badges/badge-save/`,
      user_info
    );

    if (createNewCourse.okay && createNewCourse.data) {
      return createNewCourse.data;
    } else {
      throw Error(createNewCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetNewIssue(email: string): Promise<any> {
  try {
    const valdiation = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.valdiateEmail}?email=${email}/`
    );

    if (valdiation.okay && valdiation.data) {
      return valdiation.data;
    } else {
      throw Error(valdiation.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateNewCourse(
  user_info: ICreateNewCourse,
  new_photo: ICreateNewCourseImage
): Promise<any> {
  try {
    const createNewCourse = await new HttpRequest().Post<any>(
      `${API_URL}${AdminUrls.newCourse}`,
      user_info
    );

    if (createNewCourse.okay && createNewCourse.data) {
      const formData = new FormData();

      if (new_photo.image) formData.append('image', new_photo.image);

      const create_image = await new HttpRequest().PatchForm<any>(
        `${API_URL}${AdminUrls.newCourse}${createNewCourse.data.id}/`,
        formData
      );
      if (create_image.okay && create_image.data) {
        return createNewCourse.data;
      } else {
        throw Error(create_image.message);
      }
    } else {
      throw Error(createNewCourse.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetLanguagesList(): Promise<ILanguagesList[]> {
  try {
    const languages = await new HttpRequest().Get<ILanguagesListResponse>(
      `${API_URL}${AdminUrls.languages}`
    );

    if (languages.okay && languages.data) {
      return languages.data.results;
    } else {
      throw Error(languages.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetModalityList(): Promise<IModalityList[]> {
  try {
    const modalities = await new HttpRequest().Get<IModalityListResponse>(
      `${API_URL}${AdminUrls.modalitys}`
    );

    if (modalities.okay && modalities.data) {
      return modalities.data.results;
    } else {
      throw Error(modalities.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetMethodologyList(): Promise<IMethodologyist[]> {
  try {
    const methodologies = await new HttpRequest().Get<IMethodologyistResponse>(
      `${API_URL}${AdminUrls.methodologies}`
    );

    if (methodologies.okay && methodologies.data) {
      return methodologies.data.results;
    } else {
      throw Error(methodologies.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function LearningPathExperiences(pathway_id: number): Promise<IPathExperiences[]> {
  try {
    const experiences = await new HttpRequest().Get<IPathExperiencesResponse>(
      `${API_URL}${AdminUrls.routeExperiences}?path=${pathway_id}`
    );

    if (experiences.okay && experiences.data) {
      // TODO: remove .results
      return experiences.data.results;
    } else {
      throw Error(experiences.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UpdateImageNewCourse(form: IUpdateNewCourseImage): Promise<string> {
  try {
    const formData = new FormData();

    if (form.image) formData.append('image', form.image);

    const updateCourseImage = await new HttpRequest().PatchForm<string>(
      `${API_URL}${AdminUrls.newCourse}${form.id}/`,
      formData
    );

    if (updateCourseImage.okay && updateCourseImage.data) {
      return 'Se ha actualizado la imagen del cruso con éxito';
    } else {
      throw Error(updateCourseImage.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function UpdateOwnCourses(
  own_courses: IUpdateOwnCourses,
  organizationId: number
): Promise<string> {
  try {
    const update = await new HttpRequest().Patch(
      `${API_URL}${AdminUrls.updateOwnCourses}${organizationId}/`,
      own_courses
    );

    if (update.okay) {
      return 'Se ha actualizado la información correctamente';
    } else {
      throw Error(update.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function AssignLearningPath(formInfo: IAssingUsersForm): Promise<string> {
  try {
    const new_users = await new HttpRequest().Patch<number[]>(
      `${API_URL}${AdminUrls.assignPaths}${formInfo.route_id}/`,
      formInfo.users
    );

    if (new_users.okay && new_users.data) {
      return 'Se han agregado los usuarios a la ruta de forma exitosa';
    } else {
      throw Error(new_users.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function LearningPathLevels(): Promise<IPathLevel[]> {
  try {
    const levels = await new HttpRequest().Get<IPathLevelResponse>(
      `${API_URL}${AdminUrls.routeLevels}`
    );

    if (levels.okay && levels.data) {
      return levels.data.results;
    } else {
      throw Error(levels.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationBadges(user_id: any): Promise<any[]> {
  try {
    const badges = await new HttpRequest().Get<any>(
      `${API_URL}${IntegrationsUrls.getOrganizationBadges}?user_id=${user_id}`
    );

    if (badges.data && badges.okay) {
      return badges.data.data;
    } else {
      throw Error(badges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetBadgeDetails(badge_id: string): Promise<any[]> {
  try {
    const badge = await new HttpRequest().Get<any>(
      `${API_URL}${IntegrationsUrls.getBadgeDetail}${badge_id}`
    );

    if (badge.data && badge.okay) {
      return badge.data;
    } else {
      throw Error(badge.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function CreateNewBadageTemplate(new_badge: ICreateNewBadgeTemplate): Promise<any> {
  try {
    const create_new_badge = await new HttpRequest().Post(
      `${API_URL}${AdminUrls.createNewBadgeTemplate}`,
      new_badge
    );

    if (create_new_badge.okay) {
      return create_new_badge;
    } else {
      throw Error(create_new_badge.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function AddMassiveUsers(file: File): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const addMassiveUsers = await new HttpRequest().PostForm<any>(
      `${API_URL}${AccountsUrls.addMasiveUsers}`,
      formData
    );

    if (addMassiveUsers.okay) {
      return 'Se han agregado los usuarios exitosamente.';
    } else {
      throw Error(addMassiveUsers.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function AddBadgeImage(image: File, id: string): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('image', image);
    const add_image = await new HttpRequest().PatchForm<any>(
      `${API_URL}${AdminUrls.createNewBadgeTemplate}${id}/`,
      formData
    );

    if (add_image.okay) {
      return 'Se han agregado la imagen de la insignia exitosamente.';
    } else {
      throw Error(add_image.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function ChangeImageProfile(data: IFormEditProfie): Promise<string> {
  try {
    const formData = new FormData();
    if (data.avatar.image !== null) formData.append('avatar', data.avatar.image);

    const add_image = await new HttpRequest().PatchForm<any>(
      `${API_URL}${AdminUrls.editProfileImage}${data.id}/`,
      formData
    );

    if (add_image.okay) {
      return 'Se han cambiado la imagen de perfil.';
    } else {
      throw Error(add_image.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function EditProfileForm(data: IFormEditProfie): Promise<string> {
  try {
    const add_image = await new HttpRequest().Patch<IUploadEditFormProfile>(
      `${API_URL}${AdminUrls.editProfileInfo}${data.id}/`,
      data.form
    );

    if (add_image.okay) {
      return 'Se han modificado los datos exitosamente.';
    } else {
      throw Error(add_image.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetDetailsProfileInfo(cognito_id: string): Promise<IUserProfileData> {
  try {
    const profileInfo = await new HttpRequest().Get<IUserProfileData>(
      `${API_URL}${AdminUrls.editProfileInfo}${cognito_id}`
    );

    if (profileInfo.data && profileInfo.okay) {
      return profileInfo.data;
    } else {
      throw Error(profileInfo.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationOverview(orgnanizationId: string): Promise<any> {
  try {
    const organization = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.organizarionOverview}${orgnanizationId}`
    );

    if (organization.okay && organization.data) {
      return organization.data;
    } else {
      throw Error(organization.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function ShareBadgeEmail(emailInfo: IShareBadgeEmailRequest): Promise<string> {
  try {
    const email = await new HttpRequest().Post<any>(`${API_URL}${AdminUrls.shareBadge}`, emailInfo);

    if (email.okay) {
      return 'Se ha enviado el email con éxito';
    } else {
      throw Error(email.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DownloadBadgeImage(badgeID: string): Promise<any> {
  try {
    const badgeImage = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.downloadBadgeImageCert}${badgeID}`
    );

    if (badgeImage.okay) {
      return badgeImage;
    } else {
      throw Error(badgeImage.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetOrganizationBadgesInfo(orgnanizationId: string): Promise<any> {
  try {
    const organizationBadges = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.organizarionBadgesInfo}${orgnanizationId}`
    );

    if (organizationBadges.okay && organizationBadges.data) {
      return organizationBadges.data;
    } else {
      throw Error(organizationBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function IssueMasiveBadges(info: IMassiveBadges): Promise<string> {
  try {
    const formData = new FormData();
    formData.append('file', info.file);
    formData.append('badge', info.badge);
    const masiveBadges = await new HttpRequest().PostForm<string>(
      `${API_URL}${AdminUrls.issueMassive}`,
      formData
    );

    if (masiveBadges.okay) {
      return 'Tu archivo se ha subido correctamente te enviaremos un correo electrónico al finalizar el proceso de emisión de esta insignia.';
    } else {
      throw Error(masiveBadges.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function DownloadBadgeImageCert(badgeID: string): Promise<any> {
  try {
    const badgeImage = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.downloadBadgeImageCert}${badgeID}`
    );

    if (badgeImage.okay) {
      return badgeImage;
    } else {
      throw Error(badgeImage.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

/**
 * Get profile user data
 * @params {string} id
 * @returns Promise
 * @Author Jose Quintero
 */
export const getUserDataDetails = (id: string) => {
  return new HttpRequest().Get(`${API_URL}accounts/accredited-profile/${id}`);
};

/**
 * Update profile user data
 * @params {string} id
 * @params {object} payload
 * @returns Promise
 * @Author Jose Quintero
 */
export const updateUserData = (id: string, payload: any) => {
  return new HttpRequest().Patch(`${API_URL}accounts/accredited-profile/${id}/`, payload);
};

/**
 * upload profile photo
 * @params {string} id
 * @params {object} payload
 * @returns Promise
 * @Author Jose Quintero
 */
export const uploadProfilePhoto = (id: string, payload: any) => {
  return new HttpRequest().Patch(`${API_URL}accounts/profile-avatar/${id}/`, payload);
};

/**
 * Cancel acreditted invitation
 * @params {string} email
 * @returns Promise
 * @Author Jose Quintero
 */
export const cancelAcredittedInvitation = (email: string) => {
  return new HttpRequest().Post(`${API_URL}config/cancel-invitation/`, { email });
};
