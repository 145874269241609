import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Input, FormGroup } from 'reactstrap';

export const InputLabelWrapper = styled.div`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  @media ${device.ipad} {
    flex-direction: column;
  }
`;

export const ShareBadgeImageContainer = styled.div`
  margin: 40px 10px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  button {
    margin-right: 10px !important;
  }
`;

export const ShareBadgeImageTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;

export const InputStyled = styled(Input)`
  width: 70%;
`;

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
