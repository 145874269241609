import { getImageTemplates } from 'actions/ApiClient/certificates-pdf';
import icons from 'assets/Icon';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { manageErrors } from 'helpers/manage-error';
import { useEffect, useState } from 'react';
import NewCertificateButton from '../components/NewCertificateButton';
import styles from './styles.module.css';

interface TemplateI {
  css: string;
  html: string;
  id: number;
  image: string;
  name: string;
}

interface Props {
  initialSelected: number;
  selectAsDefault: (value: number) => void;
  handleClick: (id: number, template: any) => void;
}

const ShowTemplates = ({ initialSelected, selectAsDefault, handleClick }: Props) => {
  const [templates, setTemplates] = useState<TemplateI[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await getImageTemplates();
        setTemplates(response.data.results);
        setSelected(initialSelected === null ? response.data.results[0].id : initialSelected);
        selectAsDefault(initialSelected === null ? response.data.results[0].id : initialSelected);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        manageErrors(e);
      }
    })();
    // eslint-disable-next-line
  }, [handleClick, initialSelected]);

  const handleImageClick = (id: number) => {
    const template = templates?.find((i) => i.id === id);
    handleClick(id, template);
    setSelected(id);
  };

  return (
    <>
      {loading && <AcredittaLoader />}

      <div className="row mt-3">
        {templates?.map((item) => (
          <div
            className="col-xs-12 col-sm-4 col-md-4 mt-1 mb-1"
            key={item.id}
            onClick={() => handleImageClick(item.id)}
            style={{ cursor: 'pointer' }}
          >
            <div
              className={styles.imageBox}
              style={{
                minHeight: '300px',
              }}
            >
              <div style={{ position: 'absolute', top: 5, right: 10 }}>
                {item.id === selected ? (
                  <icons.CheckIcon className={styles.active} />
                ) : (
                  <icons.CheckIcon className={styles.inactive} />
                )}
              </div>
              <img
                src={item.image}
                alt=""
                height="auto"
                width="100%"
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        ))}
        {!loading && <NewCertificateButton />}
      </div>
    </>
  );
};

export default ShowTemplates;
