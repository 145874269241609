import AcredittaText from 'components/atoms/AcredittaText';

interface Props {
  colSpan?: number;
  text?: string;
}

const TableRowNoData = ({ colSpan = 4, text = 'Datos no encontrados' }: Props) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <AcredittaText variant="body1" color="secondary" align="center">
          {text}
        </AcredittaText>
      </td>
    </tr>
  );
};

export default TableRowNoData;
