import AcredittaButton from 'components/atoms/AcredittaButton';
import AcredittaText from 'components/atoms/AcredittaText';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useHistory } from 'react-router';
import { AdminRole } from '../../../constants/NavigationUrls';
import icons from '../../../assets/Icon/index';

type Props = {
  data: {
    id: string;
    name: string;
    count: number;
  };
  show: boolean;
  toogle: () => void;
};

const ModalBeforeEditBadge = ({ data, show, toogle }: Props) => {
  const { push } = useHistory();

  return (
    <Modal isOpen={show} centered size="lg">
      <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <span onClick={toogle} className="cursor-pointer">
          <icons.CloseIcon />
        </span>
      </div>
      <ModalHeader style={{ background: 'white' }}>
        <AcredittaText variant="h2" align="center">
          ¿Deseas editar la insignia?
        </AcredittaText>
      </ModalHeader>
      <ModalBody style={{ background: 'white', padding: 0, marginBottom: '25px' }}>
        <AcredittaText variant="body2" color="secondary" align="center">
          Actualmente la insignia <strong>{data.name}</strong> fue{' '}
          <strong>emitida a {data.count} usuarios.</strong>
        </AcredittaText>
        <AcredittaText variant="body2" color="secondary" align="center">
          Al editar esta insignia se creará una nueva versión que podrás emitir y la versión
          anterior se archivará pero seguirá siendo visible para los usuarios que la ganaron.
        </AcredittaText>
        <AcredittaText variant="body2" color="secondary" align="center">
          ¿Estás seguro que quieres editar la insignia?
        </AcredittaText>
        <div className="d-flex justify-content-center mt-3">
          <AcredittaButton onPress={toogle} title="Cancelar" variant="secondary" />
          <AcredittaButton
            onPress={() => push(`${AdminRole.editBadge}/${data.id}`)}
            title="Editar"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalBeforeEditBadge;
