import styled from 'styled-components';
import { BLUE_LIGHT, PRIMARY_COLOR, SECONDARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';

export const RouteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 225px;
  height: 264px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
  padding: 10px;
`;

export const RouteHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RouteDate = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${BLUE_LIGHT};
  margin-bottom: 0px;
  span {
    color: ${SECONDARY_COLOR};
  }
`;

export const ProfileInfoImage = styled.img`
  width: 4em;
  height: 4em;
  object-fit: cover;
  border-radius: 100%;
`;

export const RouteTitle = styled(Label)`
  max-width: 80%;
  height: 50px;
  align-self: flex-start;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 15px;
  line-height: 22px;
  margin-left: 10px;
  color: ${PRIMARY_COLOR};
`;

export const RouteProgressTitle = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 19px;
  color: ${PRIMARY_COLOR};
`;

export const RouteProgressInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
`;

export const RouteProgress = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 12px;
  color: ${PRIMARY_COLOR};
  > * {
    &:first-child {
      font-family: NotoSansJPBold;
      font-size: 20px;
      line-height: 22px;
    }
  }
`;

export const RouteInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const RouteInfo = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${PRIMARY_COLOR};
  }
  svg {
    margin-right: 4px;
  }
`;

export const RouteDesc = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
  color: #53565a;
`;
