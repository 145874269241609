import * as React from 'react';
import BadgeDetail from 'components/organisms/BadgeDetailTemp';
import { useParams } from 'react-router-dom';

const DetailTemplateBadge: React.FC = () => {
  let { badge_id } = useParams<{ badge_id: string }>();

  return (
    <React.Fragment>
      <BadgeDetail badge_id={badge_id} organization_badge={true} showHeaderMenu={true} />
    </React.Fragment>
  );
};

export default DetailTemplateBadge;
