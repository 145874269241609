import { Button, FormText } from 'reactstrap';

type AcredittaActionButtonProps = {
  text: string,
  icon: React.ElementType,
  onPress: () => void
}

const AcredittaActionButton = ({text, icon:Icon, onPress}:AcredittaActionButtonProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontWeight: 'bold',
      }}
    >
      <Button onClick={onPress}>
        <Icon />
      </Button>
      <FormText
        style={{
          fontWeight: 'bold',
        }}
      >
        {text}
      </FormText>
    </div>
  );
};

export default AcredittaActionButton;
