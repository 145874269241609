import * as React from 'react';
import MainSingUp from 'components/organisms/SingUp';

const SingUp = (): JSX.Element => {
  return (
    <React.Fragment>
      <MainSingUp />
    </React.Fragment>
  );
};

export default SingUp;
