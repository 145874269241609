import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { removeCsvFile } from 'states/slices/admin.slice';
import UploadUserProgress from '../UploadUserProgress';
import {
  FileSelectedWrapper,
  InvalidLabel,
  LabelFileSelected,
  UploadFileContainer,
  UploadFileInput,
  UploadFileInputLabel,
  UploadFileWrapperInput,
  UploadMainContainer,
  UploadProgressWrapper,
} from './styles';

interface UploadFileProps {
  onSelectCsvFile: (file: File) => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ onSelectCsvFile }) => {
  const [validFormat, setValidFormat] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.admin);

  const onSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      onSelectCsvFile(files[0]);
    } else {
      setValidFormat(false);
    }
  };

  const removeFileSelected = () => {
    dispatch(removeCsvFile());
  };

  const progress = React.useMemo(() => {
    if (admin.createUsers && admin.createUsers.progressFileSelected) {
      return admin.createUsers.progressFileSelected;
    }
    return 0;
  }, [admin.createUsers]);

  return (
    <React.Fragment>
      {validFormat && <InvalidLabel>Debes cargar un archivo de formato .png, .jpg</InvalidLabel>}
      <UploadMainContainer>
        <UploadFileContainer>
          <UploadFileWrapperInput>
            {admin.createUsers && admin.createUsers.csvFileSelected ? (
              <FileSelectedWrapper>
                <LabelFileSelected style={{ marginBottom: 0 }}>
                  {admin.createUsers.csvFileSelected.name}
                </LabelFileSelected>
              </FileSelectedWrapper>
            ) : (
              <UploadFileInputLabel>
                <UploadFileInput type="file" onChange={onSelectFile} accept=".png, .jpg" />
                <span>Buscar imagen</span>
              </UploadFileInputLabel>
            )}
          </UploadFileWrapperInput>
        </UploadFileContainer>
        {admin.createUsers && admin.createUsers.csvFileSelected && (
          <UploadProgressWrapper>
            <UploadUserProgress progress={progress} onCancel={removeFileSelected} />
          </UploadProgressWrapper>
        )}
      </UploadMainContainer>
    </React.Fragment>
  );
};

export default UploadFile;
