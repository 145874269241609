import { Link } from 'react-router-dom';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const FooterBadgesContainer = styled.div`
  height: 100%;
  align-self: flex-start;
  margin: 0 0.5625em;
`;

export const FooterBadgesTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
`;

export const FooterBadgesNavLink = styled(Link)`
  color: #525f7f;
  font-family: NotoSansJP;
  font-weight: 400;
  line-height: 1.5;
  font-size: 12px;
  padding: 0.5em 0;
`;
