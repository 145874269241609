import React from 'react';
import { AssignHeader, BadgeCoverIconContainer, NewIndividualBadgeContainer } from './styles';
import { objectValidation } from 'utils';
import { useForm } from 'react-hook-form';
import CommonPageLayout from '../Layout/CommonPageLayout';
import AdminUploadNewBadgeTemplateForm from 'components/molecules/AdminUploadNewBadgeTemplateForm';
import AdminUploadImageBadgeTemplate from 'components/molecules/AdminUploadImageBadgeTemplate';
import {
  CreateNewBadageTemplateThunk,
  CreateNewBadageTemplateImageThunk,
  GetPathListThunk,
} from 'actions/Thunks/AdminThunk';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { AdminRole } from 'constants/NavigationUrls';
import icons from 'assets/Icon';
import { DetailBadgesThunk } from 'actions/Thunks/AdminPathsThunk';
import uuid from 'react-uuid';

type ParamsT = {
  id: string;
};

const AdminCreateNewBadgeTemplate = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [inputList, setInputList] = React.useState<IBadgeRequirement[]>([
    { name: '', description: '' },
  ]);
  const [type, onSelectType] = React.useState<number | null>(null);
  const [level, setLevel] = React.useState<number | null>(null);
  const [duration, onSelectDuration] = React.useState<number | null>(null);
  const [cost, setCost] = React.useState<number | null>(null);
  const [standardList, setStandardList] = React.useState<any>([{ name: '', description: '' }]);
  const [contactMethod, setContactMethod] = React.useState<any>(null);
  const [badgeCloned, setBadgeCloned] = React.useState<any>();

  const { id }: ParamsT = useParams();

  const defaultValuesNewBadgeUpload: IUploadNewBadgeTemplate = {
    name: '',
    description: '',
    duration: 0,
    duration_type: 0,
    requirements: [],
    additional_info: '',
    cost: 0,
    is_public: false,
    blockchain_publication: false,
    enable_duplicate: false,
    enable_printing: false,
    status: '',
    type_cost: 0,
    level: 0,
    type_badge: 0,
    badge_collection: [],
    skill: null,
    provider: 0,
    image: null,
    tags: [],
    standard: null,
    contact_method: null,
    img_url: '',
  };

  const duplicateBadge = async (id: any) => {
    try {
      const res = await dispatch(DetailBadgesThunk(id));
      const { data } = res.payload;
      const paths: any = await dispatch(GetPathListThunk());
      const resetPath = paths.payload.filter((e: any) => e.value === data.path[0]);
      setBadgeCloned(data);
      reset({
        name: 'Copia de ' + data.name,
        description: data.description,
        duration: data.duration,
        requirements: data.badge_template_activities.map((e: any) => e.title),
        additional_info: data.additional_info,
        cost: data.cost,
        is_public: data.public,
        status: data.state,
        level: data.level,
        type_badge: data.type_category,
        skill: data.skills,
        provider: data.provider,
        path_select: resetPath[0],
        img_url: data.image.url,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getDefaultFormValues = () => {
    if (id) {
      return;
    }

    return defaultValuesNewBadgeUpload;
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<IUploadNewBadgeTemplate>({
    ...objectValidation,
    defaultValues: getDefaultFormValues(),
  });

  React.useEffect(() => {
    duplicateBadge(id);
    // eslint-disable-next-line
  }, [id]);

  const submitNewBadgeTemplate = async (badge_info: IUploadNewBadgeTemplate) => {
    let requ: string[] = [];

    inputList.map((req) => {
      requ.push(req.description);
      return 0;
    });

    if (badge_info.additional_info !== undefined) {
      let new_badge: ICreateNewBadgeTemplate = {
        name: badge_info.name,
        description: badge_info.description,
        duration: badge_info.duration,
        duration_type: duration !== 0 ? duration : null,
        additional_info: badge_info.additional_info.includes('https://')
          ? badge_info.additional_info
          : 'https://' + badge_info.additional_info,
        cost: badge_info.cost,
        is_public: badge_info.is_public,
        blockchain_publication: badge_info.blockchain_publication,
        enable_duplicate: badge_info.enable_duplicate,
        enable_printing: badge_info.enable_printing,
        status: badge_info.status,
        type_cost: cost,
        level: level,
        badge_type: type,
        badge_collection: badge_info.badge_collection,
        skill_tag: badge_info.skill,
        path: badge_info.path_select ? [badge_info.path_select.value] : [],
        requirements: requ,
        badge_template_tag: badge_info.tags,
        contact_method: contactMethod,
        standard: standardList.map((e: any) => e),
        img_url: badge_info.image ? null : badge_info.img_url,
      };
      if (new_badge) {
        const new_badge_template = await dispatch(CreateNewBadageTemplateThunk(new_badge));

        if (CreateNewBadageTemplateThunk.fulfilled.match(new_badge_template)) {
          if (badge_info.image) {
            const extension = badge_info.image.name.split('.').pop();
            const newImage = new File([badge_info.image], `${uuid()}.${extension}`);
            let badge_image = {
              badge_image: newImage,
              id: new_badge_template.payload.data.uuid,
            };
            const new_badge_image = await dispatch(CreateNewBadageTemplateImageThunk(badge_image));
            console.log(new_badge_image);
            if (CreateNewBadageTemplateImageThunk.fulfilled.match(new_badge_image)) {
              toast.success(
                badge_info.status === 'active'
                  ? 'Se ha creado la insignia con éxito'
                  : 'Se ha guardado como borrador',
                { duration: 4000 }
              );
              history.push(
                `${AdminRole.templateBadgeDetail}/${new_badge_template.payload.data.uuid}`
              );
            }
          } else {
            toast.success(
              badge_info.status === 'active'
                ? 'Se ha creado la insignia con éxito'
                : 'Se ha guardado como borrador',
              { duration: 4000 }
            );
            history.push(
              `${AdminRole.templateBadgeDetail}/${new_badge_template.payload.data.uuid}`
            );
          }
        } else {
          toast.error(new_badge_template.payload as string);
        }
      }
    }
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showCommon={true}
    >
      <div>
        <AssignHeader>
          <icons.GoBackIcon onClick={history.goBack} />
        </AssignHeader>
        <NewIndividualBadgeContainer>
          <BadgeCoverIconContainer>
            <AdminUploadImageBadgeTemplate
              watch={watch}
              control={control}
              errors={errors}
              onSelectType={onSelectType}
              onSelectCost={setCost}
              onSelectDuration={onSelectDuration}
              onSelectLevel={setLevel}
              badgeCloned={badgeCloned}
            />
          </BadgeCoverIconContainer>
          <AdminUploadNewBadgeTemplateForm
            controlInfo={control}
            handleSubmitForm={handleSubmit}
            errorsInfo={errors}
            submitNewBadgeForm={submitNewBadgeTemplate}
            watch={watch}
            onChangeContent={(value: IBadgeRequirement[]) => setInputList(value)}
            onChangeStandardList={(value: any) => setStandardList(value)}
            onChangueContactMethod={(value: any) => setContactMethod(value)}
            badgeCloned={badgeCloned}
          />
        </NewIndividualBadgeContainer>
      </div>
    </CommonPageLayout>
  );
};

export default AdminCreateNewBadgeTemplate;
