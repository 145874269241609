import AcredittaText from 'components/atoms/AcredittaText';
import { useContext, useEffect, useRef, useState } from 'react';
import { MenuContext } from '../../context/MenuContextProvider';
import ItemMenuMobile from './ItemMenuMobile';
import icons from 'assets/Icon';
import styles from './style.module.css';
import { useAppSelector } from 'hooks/redux';
import { UserRoles } from '../../interfaces/user-roles';
import { MenuI } from '../../interfaces/menu';
import {
  MobileCreateBadgeLink,
  MobilePublicProfileLink,
  MobileSupportLink,
  MobileConfigMenuItem,
  MobileLogoutButton,
} from './';
import defaultProfile from '../../../../../assets/images/default-profile.png';

const DrawerMenu = () => {
  const divRef = useRef(null) as any;
  const { menu, isOpen, role, configMenu, closeMenu } = useContext(MenuContext);
  const [openMenuOrg, setOpenMenuOrg] = useState(false);
  const { userInfo } = useAppSelector((state) => state.user);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = (e: any) => {
    if (!divRef.current.contains(e.target)) {
      closeMenu();
    }
  };

  if (!menu) {
    return <></>;
  }

  return (
    <div
      className={`animate__animated animate__fadeIn animate__faster ${styles.main}`}
      ref={divRef}
      style={{
        display: isOpen ? 'block' : 'none',
      }}
    >
      <div className="text-right">
        <span onClick={closeMenu} style={{ fontSize: '20px' }}>
          <icons.CleanSearchIcon />
        </span>
      </div>
      <div className="row-item" onClick={() => setOpenMenuOrg(!openMenuOrg)}>
        <div>
          <img src={userInfo?.avatar || defaultProfile} className="profile-image" alt="" />
        </div>
        <div>
          <AcredittaText variant="h5">
            {userInfo?.organization.name} {!openMenuOrg ? '\u2304' : '\u2303'}
          </AcredittaText>
        </div>
      </div>

      {configMenu && (
        <div
          className="animate__animated animate__fadeIn animate_faster"
          style={{ display: openMenuOrg ? 'block' : 'none', paddingLeft: '20px' }}
        >
          {role === String(UserRoles.ACREDITTED) && <MobilePublicProfileLink />}

          {configMenu.map(({ id, text, path }: MenuI) => (
            <MobileConfigMenuItem key={id} text={text} path={path} />
          ))}
        </div>
      )}

      {role === String(UserRoles.ADMIN) && <MobileCreateBadgeLink />}

      <MobileSupportLink />

      <div className="divider"></div>

      {menu.map((item) => (
        <ItemMenuMobile key={item.id} element={item} />
      ))}

      <div className="divider"></div>

      <MobileLogoutButton />
    </div>
  );
};

export default DrawerMenu;
