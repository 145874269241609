import icons from 'assets/Icon';
import * as React from 'react';
import { ButtonWrapper, Container, SubtitleFirst, SubtitleSecondNoData } from './styles';
import Button from 'components/atoms/Button';
import { AccreditedRole } from 'constants/NavigationUrls';
import { Link } from 'react-router-dom';

const NoData: React.FC = () => {
  return (
    <React.Fragment>
      <Container>
        <icons.Vector_insignia />
        <SubtitleFirst>Aún no tienes insignias</SubtitleFirst>
        <SubtitleSecondNoData>
          ¡Crea tu primera insignia y vincúlala a una ruta de aprendizaje!
        </SubtitleSecondNoData>
        <ButtonWrapper>
          <Link to={AccreditedRole.catalogCourse}>
            <Button label={'Ir al catálogo'} widthCustom={300} />
          </Link>
        </ButtonWrapper>
      </Container>
    </React.Fragment>
  );
};

export default NoData;
