import * as React from 'react';
import AdminLearningPathPreviewDescription from 'components/molecules/AdminLearningPathPreviewDescription';
import AdminSkillsList from 'components/molecules/AdminSkillsList';
import AdminBadgesList from 'components/molecules/AdminBadgesList';
import CommonPageLayout from '../Layout/CommonPageLayout';
import {
  CoursesWrappedSection,
  CoursesSectionTitle,
  CoursesCardWrapper,
  RecomendedCoursesWrappedSection,
  RecomendedCoursesCardWrapper,
  RecomendedCoursesSectionTitle,
  AdminCoverSection,
  RouteInfoWrapper,
  RouteSubTitle,
  RouteShareTitle,
  ButtonWrapper,
  RouteShareSectionWrapper,
} from './styles';
import { recomendedCourses } from './mock';
import CoursesCards from 'components/molecules/CoursesCards';
import Pagination from 'components/molecules/Pagination';
import AdminRouteCoursesList from 'components/molecules/AdminRouteCoursesList';
import Button from 'components/atoms/Button';
import icons from 'assets/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
  DetailPathwayContentCourseThunk,
  DetailPathwayDashboardThunk,
  DetailPathwaySkillsThunk,
  SelectedCourseListThunk,
  LearningPathExperiencesThunk,
  DetailPathwayBagdesThunk,
} from '../../../actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';
import CoursesCardsPathwayDescription from '../../molecules/CoursesCardPathwayDescription';
import { useHistory } from 'react-router';
import { AdminRole, NonAuthenticatedUrls } from 'constants/NavigationUrls';
import { ENVIRONMENT_URL } from 'utils';

interface AdminLearningPathPreviewProps {
  pathwayId: string;
}

const AdminLearningPathPreview = ({ pathwayId }: AdminLearningPathPreviewProps): JSX.Element => {
  const visible = false;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const courseSelector = useAppSelector((state) => state.courses);
  const admin = useAppSelector((state) => state.admin);

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [notVisible] = React.useState<boolean>(true);

  const currentPageCourses = React.useMemo(() => {
    if (courseSelector.selectedCourseList) {
      const firstPageIndex = (currentPage - 1) * courseSelector.selectedCourseList.length;
      const lastPageIndex = firstPageIndex + courseSelector.selectedCourseList.length;

      return courseSelector.selectedCourseList.slice(firstPageIndex, lastPageIndex);
    }
  }, [courseSelector.selectedCourseList, currentPage]);

  const pathwaySkills = React.useCallback(async () => {
    const skills = await dispatch(DetailPathwaySkillsThunk(parseInt(pathwayId, 10)));

    if (DetailPathwaySkillsThunk.rejected.match(skills)) {
      toast.error(skills.payload as string);
    }
  }, [dispatch, pathwayId]);

  const pathwayDashboard = React.useCallback(async () => {
    const dashboard = await dispatch(DetailPathwayDashboardThunk(parseInt(pathwayId, 10)));

    if (DetailPathwayDashboardThunk.rejected.match(dashboard)) {
      toast.error(dashboard.payload as string);
    }
  }, [dispatch, pathwayId]);

  const pathwayBadges = React.useCallback(async () => {
    const badges = await dispatch(DetailPathwayBagdesThunk(pathwayId));

    if (DetailPathwayBagdesThunk.rejected.match(badges)) {
      toast.error(badges.payload as string);
    }
  }, [dispatch, pathwayId]);

  const selectedCourses = React.useCallback(async () => {
    if (pathwayId) {
      const courses = await dispatch(SelectedCourseListThunk(parseInt(pathwayId)));

      if (SelectedCourseListThunk.rejected.match(courses)) {
        toast.error(courses.payload as string);
      }
    }
  }, [dispatch, pathwayId]);

  const contentCourse = React.useCallback(async () => {
    const contentCoursePathway = await dispatch(
      DetailPathwayContentCourseThunk(parseInt(pathwayId, 10))
    );

    if (DetailPathwayContentCourseThunk.rejected.match(contentCoursePathway)) {
      toast.error(contentCoursePathway.payload as string);
    }
  }, [dispatch, pathwayId]);

  const getExperiencesPath = React.useCallback(async () => {
    if (pathwayId) {
      const experiences = await dispatch(LearningPathExperiencesThunk(parseInt(pathwayId)));
      if (LearningPathExperiencesThunk.rejected.match(experiences)) {
        toast.error(experiences.payload as string);
      }
    }
  }, [pathwayId, dispatch]);

  const assignRoute = () => {
    if (pathwayId) {
      history.push(`${AdminRole.assingRoute}/${pathwayId}`);
    }
  };

  React.useEffect(() => {
    pathwayDashboard();
    pathwaySkills();
    selectedCourses();
    contentCourse();
    getExperiencesPath();
    pathwayBadges();
  }, [
    pathwayDashboard,
    pathwaySkills,
    selectedCourses,
    contentCourse,
    getExperiencesPath,
    pathwayBadges,
  ]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showCommon={true}
    >
      {courseSelector.pathwayDetailGeneralContent && (
        <React.Fragment>
          <AdminCoverSection>
            <img
              src="https://acreditta-rutas.s3.amazonaws.com/static/route-cover-example.png"
              alt="imagen-de-portada"
            />
            <RouteInfoWrapper>
              <p
                style={{
                  maxWidth: '95%',
                  textAlign: 'center',
                  fontFamily: 'NotoSansJPBold',
                  fontSize: '40px',
                  lineHeight: '68px',
                  color: '#ffffff',
                }}
              >
                {courseSelector.pathwayDetailGeneralContent.name}
              </p>
              <ButtonWrapper>
                <Button label="Asignar esta Ruta" widthCustom={378} onClick={assignRoute} />
              </ButtonWrapper>
              <RouteSubTitle>¿Conoces a alguien que le gustaría esta ruta?</RouteSubTitle>
              <RouteShareSectionWrapper>
                <RouteShareTitle
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${ENVIRONMENT_URL}${NonAuthenticatedUrls.path}/${pathwayId}`
                    );
                    toast('Enlace copiado');
                  }}
                >
                  ¡Compártela!
                </RouteShareTitle>
                {visible && <icons.UploadFileIcon />}
              </RouteShareSectionWrapper>
            </RouteInfoWrapper>
          </AdminCoverSection>
          <AdminLearningPathPreviewDescription
            creator={courseSelector.pathwayDetailGeneralContent.organization}
            start={courseSelector.pathwayDetailGeneralContent.start_date}
            content={courseSelector.pathwayDetailGeneralContent.course_count}
            description={courseSelector.pathwayDetailGeneralContent.description}
            duration={courseSelector.pathwayDetailGeneralContent.duration}
            users={courseSelector.pathwayDetailGeneralContent.user_count}
            level={courseSelector.pathwayDetailGeneralContent.level}
            language={courseSelector.pathwayDetailGeneralContent.language}
            methodology={courseSelector.pathwayDetailGeneralContent.methodology}
            isPrivate={courseSelector.pathwayDetailGeneralContent.is_private}
            image={courseSelector.pathwayDetailGeneralContent.photo}
          />
        </React.Fragment>
      )}
      {courseSelector.pathwayDetailSkillsContent &&
        (courseSelector.pathwayDetailSkillsContent?.length !== 0 ? (
          <AdminSkillsList skills={courseSelector.pathwayDetailSkillsContent} />
        ) : (
          <div></div>
        ))}
      {courseSelector.pathwayDetailBadges &&
        (courseSelector.pathwayDetailBadges?.length !== 0 ? (
          <AdminBadgesList
            badges={courseSelector.pathwayDetailBadges}
            dateStart={courseSelector.pathwayDetailGeneralContent?.start_date}
            pathwayId={pathwayId}
            userType={1}
          />
        ) : (
          <div>
            <br />
          </div>
        ))}
      {courseSelector.selectedCourseList && (
        <CoursesWrappedSection>
          <CoursesSectionTitle>
            {courseSelector.selectedCourseList.length} Cursos en esta ruta
          </CoursesSectionTitle>
          <CoursesCardWrapper>
            <CoursesCardsPathwayDescription
              courses={courseSelector.selectedCourseList}
              allowSelection={false}
              section={true}
            />
          </CoursesCardWrapper>
        </CoursesWrappedSection>
      )}
      {courseSelector.pathwayDetailContentCourse && (
        <AdminRouteCoursesList coursesList={courseSelector.pathwayDetailContentCourse} />
      )}
      {admin.experiencesPathListView && (
        <AdminRouteCoursesList experiencesList={admin.experiencesPathListView} />
      )}
      {!notVisible && (
        <RecomendedCoursesWrappedSection>
          {currentPageCourses && (
            <RecomendedCoursesCardWrapper>
              <RecomendedCoursesSectionTitle>
                Otros usuarios también vieron estos contenidos
              </RecomendedCoursesSectionTitle>
              <CoursesCards courses={currentPageCourses} />
              <Pagination
                currentPage={currentPage}
                totalCount={recomendedCourses.length}
                pageSize={2}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
            </RecomendedCoursesCardWrapper>
          )}
        </RecomendedCoursesWrappedSection>
      )}
    </CommonPageLayout>
  );
};

export default AdminLearningPathPreview;
