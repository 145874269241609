import * as React from 'react';
import { FormGroup } from 'reactstrap';
import { CheckboxCont, FormLabelText } from './styles';

interface CheckBoxProps {
  name: string;
  error: Record<string, any>;
  complement?: React.ReactNode;
  value: any;
  onChange: (val: any) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({ name, error, complement, onChange, value }) => {
  return (
    <React.Fragment>
      {error[name]?.type && <FormLabelText>{error[name]?.message}</FormLabelText>}
      <FormGroup>
        <CheckboxCont>
          <div className="custom-control custom-checkbox ">
            <input
              name={name}
              className="custom-control-input"
              id="customCheck1"
              type="checkbox"
              value={value}
              onChange={onChange}
            />

            <label className="custom-control-label" htmlFor="customCheck1" />
            <span>{complement && complement}</span>
          </div>
        </CheckboxCont>
      </FormGroup>
    </React.Fragment>
  );
};

export default CheckBox;
