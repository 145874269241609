import AdminOnboardingWizard from 'components/organisms/AdminOnboardingWizardEdit';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import * as React from 'react';
import { CheckSession } from '../../../utils/Routing/AuthUserGuard';
import { AdminDataThunk } from '../../../actions/Thunks/UserThunk';
import toast from 'react-hot-toast';

import { useParams } from 'react-router-dom';

const OnboardingWizardSteps = (): JSX.Element => {
  let { pathwayId } = useParams<{ pathwayId: string }>();
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const adminData = React.useCallback(() => {
    if (user.userInfo && user.userInfo.user_type.code === 'ADMIN') {
      CheckSession().then((result) => {
        if (result) {
          dispatch(AdminDataThunk()).then((admin) => {
            if (AdminDataThunk.rejected.match(admin)) {
              toast.error(admin.payload as string);
            }
          });
        }
      });
    }
  }, [dispatch, user.userInfo]);

  React.useEffect(() => {
    adminData();
  }, [adminData]);

  return <AdminOnboardingWizard pathwayId={pathwayId} />;
};

export default OnboardingWizardSteps;
