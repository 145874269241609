import { useContext } from 'react';
import { OPTIONS } from './OptionMenuData';
import AcredittaText from 'components/atoms/AcredittaText';
import { ConfigureOrganizationContext } from 'pages/Admin/ConfigureOrganizationProfile/Context';

const COLORS = {
  active: '#da291c',
  inactive: '#002057',
};

const ConfigureOrganizationMenu = () => {
  const { setCurrentOption, currentOption } = useContext(ConfigureOrganizationContext);

  return (
    <div className="row" style={{ marginTop: '50px' }}>
      {OPTIONS.map((item) => (
        <div
          className={`col optiondiv text-center ${item.borderLeft ? 'boderleft' : ''}`}
          onClick={() => setCurrentOption(item.slug)}
          key={item.id}
        >
          <item.IconOption fill={currentOption === item.slug ? COLORS.active : COLORS.inactive} />
          <AcredittaText variant="h4" align="center">
            {item.name}
          </AcredittaText>
        </div>
      ))}
    </div>
  );
};

export default ConfigureOrganizationMenu;
