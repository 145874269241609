import { manageErrors } from 'helpers/manage-error';
import { useCallback, useEffect, useState } from 'react';
import { getBadgesTemplate } from 'services/badges.service';

interface SelectProps {
  label: string;
  value: number;
  img?: string;
}

const useGetBadgesTemplates = () => {
  const [data, setData] = useState<SelectProps[]>([]);
  const [initialData, setInitialData] = useState<SelectProps[]>([]);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const resp = await getBadgesTemplate();
        let mapData = resp.data.map((item: any) => ({
          value: item.uuid,
          label: item.name,
          img: item.image_url,
        }));
        setData(mapData);
        setInitialData(mapData);
        setFetching(false);
      } catch (e) {
        setFetching(false);
        manageErrors(e);
      }
    })();
  }, []);

  const filterData = useCallback(
    (elements: any[]) => {
      setData(initialData.filter((x: any) => elements.indexOf(x.value) < 0));
    },
    [initialData]
  );

  return { data, fetching, filterData };
};

export default useGetBadgesTemplates;
