import * as React from 'react';
import AsyncSelect, { Props } from 'react-select/async';
import Select from 'react-select';
import { customStyles, ErrorMessage, styledTheme } from './styles';

interface InputSelectProps extends Props<any, boolean> {
  loadOptions?: (inputValue: string) => void;
  asyncSelect?: boolean;
  error: Record<string, any>;
  name: string;
  value?: any
}

const InputSelect: React.FC<InputSelectProps> = ({
  loadOptions,
  asyncSelect,
  error,
  name,
  defaultOptions,
  value,
  ...rest
}) => {
  return (
    <React.Fragment>
      {asyncSelect ? (
        <AsyncSelect
          cacheOptions
          defaultOptions={defaultOptions}
          loadOptions={loadOptions}
          theme={styledTheme}
          styles={customStyles}
          value={value}
          {...rest}
        />
      ) : (
        <Select theme={styledTheme} styles={customStyles} {...rest} />
      )}
      {error[name]?.type && <ErrorMessage>{error[name]?.message}</ErrorMessage>}
    </React.Fragment>
  );
};

export default InputSelect;
