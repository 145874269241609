import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 243px;
  height: 310px;
  background: #ffffff;
  box-shadow: 0 10px 30px 5px rgba(138, 149, 158, 0.15);
  border-radius: 8px;
  cursor: pointer;
`;

export const CourseTitle = styled(Label)`
  width: 90%;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin-left: 10px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${device.bigMobile} {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const CourseTextItem = styled(Label)`
  font-size: 14px;
  line-height: 16px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 2px;
  margin-left: 4px;
  @media ${device.laptops} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const CoursePrice = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 16px;
  line-height: 15px;
  color: ${PRIMARY_COLOR};
  text-align: center;
`;

export const CardInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 10px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 110px;
  object-fit: cover;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: 1px;
`;

export const CourseTitleWrapper = styled.div`
  width: 100%;
`;

export const CompanyNameWrapper = styled.div<{
  $backgroundColor: string;
}>`
  position: absolute;
  right: 25px;
  bottom: 128px;
  background-color: ${(props) =>
    props.$backgroundColor ? `#${props.$backgroundColor}` : `${PRIMARY_COLOR}`};
  width: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px;
`;

export const CompanyName = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 12px;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 0;
  text-align: center;
`;

export const RatingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  background-color: #003da6;
  padding: 0px 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const RouteInfoTItle = styled.div`
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 13px;
  line-height: 25px;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const RouteInfo = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: ${PRIMARY_COLOR};
  }
  svg {
    margin-right: 4px;
  }
`;
