import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import styled from 'styled-components';

export const SkillCardContainer = styled.div`
  width: auto;
  height: 32px;
  background: #ebebeb;
  border-radius: 8px;
  text-align: center;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  margin: 0px 10px 10px 0px;
  cursor: pointer;
  svg {
    position: relative;
    top: -15px;
    right: -15px;
  }
`;

export const SkilName = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
`;
