import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import {
  FormContainer,
  ButtonWrapper,
  InputsLabel,
  InputLabelWrapper,
  InputWrapper,
  Warning,
} from './styles';
import icons from 'assets/Icon';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import TextInput from 'components/atoms/TextInput';
import Button from 'components/atoms/Button';
import InputSelect from 'components/atoms/InputSelect';
import TextPasswordInput from 'components/atoms/TextPasswordInput';
import { useAppDispatch } from 'hooks/redux';
import { GetNumberEmployeeThunk, GetSectorThunk } from 'actions/Thunks/AuthThunk';
import toast from 'react-hot-toast';
// import PhoneInput from 'react-phone-input-2';
import TextPhoneInput from 'components/atoms/TextPhoneInput';
import PasswordChecklist from 'react-password-checklist';

interface SingUpFormProps {
  control: Control<SingUpForm>;
  submitLogin: (loginInfo: SingUpForm) => void;
  errors: Record<string, any>;
  handleSubmit: UseFormHandleSubmit<SingUpForm>;
  loading: boolean;
}

const SingUpForm: React.FC<SingUpFormProps> = ({
  control,
  submitLogin,
  errors,
  handleSubmit,
  loading,
}) => {
  const dispatch = useAppDispatch();

  const [check, setCheck] = React.useState<boolean>(true);
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [passwordValid, setPasswordValid] = React.useState(false);

  const loadEmployee = () => {
    return new Promise((resolve) => {
      dispatch(GetNumberEmployeeThunk()).then((countries) => {
        if (GetNumberEmployeeThunk.fulfilled.match(countries)) {
          resolve(countries.payload);
        } else {
          toast.error(countries.payload as string);
        }
      });
    });
  };

  const loadSector = () => {
    return new Promise((resolve) => {
      dispatch(GetSectorThunk()).then((sector) => {
        if (GetSectorThunk.fulfilled.match(sector)) {
          resolve(sector.payload);
        } else {
          toast.error(sector.payload as string);
        }
      });
    });
  };

  const setCheckBox = () => {
    setCheck(!check);
  };

  return (
    <FormContainer onSubmit={handleSubmit(submitLogin)}>
      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              label="Nombres *"
              icon={<icons.UserIcon />}
              name="first_name"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="first_name"
          rules={{ required: 'Nombre requerido' }}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              label="Apellidos *"
              icon={<icons.UserIcon />}
              name="last_name"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="last_name"
          rules={{ required: 'Apellido requerido' }}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextPhoneInput
              label="Teléfono corporativo"
              icon={<icons.PhoneIcon />}
              name="phone_number"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="phone_number"
        />
      </FormGroup>

      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              label="Email Institucional *"
              icon={<icons.EmailBlueIcon />}
              type="email"
              name="email"
              placeholder="Ingresar tu email corporativo  "
              value={value}
              onChange={onChange}
              error={errors}
            />
          )}
          name="email"
          rules={{ required: 'El email corporativo es requerido' }}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextPasswordInput
              label="Contraseña *"
              icon={<icons.PasswordIcon />}
              type="password"
              name="password"
              placeholder="Ingresar contraseña"
              value={value}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                onChange(evt);
                setPassword(evt.target.value);
              }}
              error={errors}
            />
          )}
          name="password"
          rules={{ required: 'La contraseña es requerida' }}
        />
      </FormGroup>
      <div style={{ margin: '10px auto 20px', width: '100%' }}>
        <PasswordChecklist
          rules={['minLength', 'number', 'capital', 'match']}
          minLength={8}
          value={password}
          valueAgain={passwordConfirm}
          onChange={(isValid) => setPasswordValid(isValid)}
          messages={{
            minLength: 'La contraseña tiene más de 8 caracteres.',
            number: 'La contraseña tiene un número.',
            capital: 'La contraseña tiene una letra mayúscula.',
            match: 'Las contraseñas coinciden.',
          }}
        />
      </div>
      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextPasswordInput
              label="Repite la Contraseña *"
              icon={<icons.PasswordIcon />}
              type="password"
              name="password_copy"
              placeholder="Ingresar contraseña"
              value={value}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                onChange(evt);
                setPasswordConfirm(evt.target.value);
              }}
              error={errors}
            />
          )}
          name="password_copy"
          rules={{ required: 'La contraseña es requerida' }}
        />
      </FormGroup>

      {/* <div style={{ width: '100%', borderBottom: '2px solid rgba(0,0,0,.1)' }}></div> */}
      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              label="Nombre Organización *"
              icon={<icons.OrganizationIcon />}
              className="is-invalid"
              name="organizationName"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="organizationName"
          rules={{ required: 'El nombre de organización es requerida' }}
        />
      </FormGroup>

      <FormGroup>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextInput
              label="Rol / Cargo *"
              icon={<icons.RoleIcon />}
              name="accreditedJobTitle"
              value={value}
              onChange={(value) => onChange(value)}
              error={errors}
            />
          )}
          name="accreditedJobTitle"
          rules={{ required: 'El cargo es requerido' }}
        />
      </FormGroup>

      <InputWrapper>
        <InputLabelWrapper>
          <icons.OrganizationIcon />
          <InputsLabel>Sector de la organización</InputsLabel>
        </InputLabelWrapper>
      </InputWrapper>
      <FormGroup>
        <Controller
          control={control}
          name="organization.sector_id"
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <InputSelect
                onChange={(value) => {
                  onChange(value);
                }}
                value={value}
                loadOptions={loadSector}
                asyncSelect={true}
                placeholder="Seleccionar sector"
                error={errors}
                name="organization.sector_id"
                defaultOptions
              />
            </div>
          )}
        />
      </FormGroup>

      <InputWrapper>
        <InputLabelWrapper>
          <icons.RoleIcon />
          <InputsLabel>¿Cuántas personas deseas acreditar?</InputsLabel>
        </InputLabelWrapper>
      </InputWrapper>
      <FormGroup>
        <Controller
          control={control}
          name="number_employee"
          render={({ field: { onChange, value } }) => (
            <div style={{ width: '100%' }}>
              <InputSelect
                onChange={(value) => onChange(value)}
                value={value}
                loadOptions={loadEmployee}
                asyncSelect={true}
                placeholder="Seleccionar cantidad"
                error={errors}
                name="number_employee"
                defaultOptions
              />
            </div>
          )}
        />
      </FormGroup>
      <FormGroup check style={{ marginLeft: '1.3em' }}>
        <Input type="checkbox" onChange={() => setCheckBox()} />{' '}
        <Label check>
          He leído y acepto los
          <a
            href="https://acreditta.com/wp-content/uploads/2022/02/TOS-Terminos-de-Uso-Servicio-Acreditta-1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Términos y Condiciones de uso </strong>
          </a>
          y
          <a
            href="https://acreditta.com/wp-content/uploads/2022/05/POLITICA-DE-DATOS-ACREDITTA-1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> Políticas de Privacidad </strong>
          </a>
        </Label>
      </FormGroup>
      <Warning>
        <p>Los campos marcados con * son obligatorios</p>
      </Warning>
      <FormGroup check row>
        <ButtonWrapper>
          <Button label={'Crear'} loading={loading} disabled={check || !passwordValid} />
        </ButtonWrapper>
      </FormGroup>
    </FormContainer>
  );
};

export default SingUpForm;
