import AcredittaText from '../../AcredittaText';
import styles from './styles.module.css';

interface Props {
  item: any;
  position: number;
}

const MostViewedBadgeItem = ({ item, position }: Props) => {
  const color = ['#F6C700', '#C0C0C0', '#DD7945'];

  return (
    <div className={styles.rootDiv}>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-sm-12 col-md-2">
          <AcredittaText variant="h3" align="center" style={{ color: color[position] }}>
            {position + 1}
          </AcredittaText>
        </div>
        <div className="col-sm-12 col-md-7">
          <AcredittaText
            variant="body1"
            color="secondary"
            fontWeight="700"
            className={styles.badgeName}
          >
            <img src={item.image_url} alt="" className={styles.badgeImage} />
            {item.name}
          </AcredittaText>
        </div>
        <div className="col-12 col-md-3">
          <AcredittaText variant="h3" fontWeight="400" align="center">
            {item.get_issue_count} <span className={styles.viewText}>Vistas</span>
          </AcredittaText>
        </div>
      </div>
    </div>
  );
};

export default MostViewedBadgeItem;
