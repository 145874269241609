import * as Sentry from '@sentry/react';
import { barChartAdapterFromAcredittaApi } from 'actions/adapters/chartsAdapters';
import { AdminUrls, API_URL } from 'constants/ApiUrls';
import { BarChartData, MonthlyReportsData } from 'interfaces/analytics';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';
import { HttpRequest } from './HttpRequest';

export async function GetAnalyticDataContent(): Promise<BarChartData | null> {
  try {
    const data = await new HttpRequest().Get<MonthlyReportsData>(
      `${API_URL}${AdminUrls.analyticDataContent}`
    );
    if (data.okay && data.data) {
      return barChartAdapterFromAcredittaApi(data);
    } else {
      throw Error(data.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
