import React, { useEffect } from 'react';
import {
  UploadSelectedType,
  BadgesUploadTypeWrapper,
  WarningImageText,
  BadgeImage,
  BadgeImageWrapper,
  BadgeCoverIconContainer,
  WarningContainer,
  ButtonWrapper,
  InputFileLabel,
  ErrorMessageLabel,
  AdditionalInfoWrapper,
  AdditionalInfoLabel,
  RowStyled,
  ColStyled,
  RowLabel,
  InputSelectContainer,
  FormInputWrapper,
  FormLabelSimpleWrapper,
  FormLabelSimple,
} from './styles';
import icons from 'assets/Icon';
import { Controller } from 'react-hook-form';
import { FormText, Input } from 'reactstrap';
import toast from 'react-hot-toast';
import { S3_ADMIN } from 'constants/index';
import { ImageValidation } from 'utils';
import { UseFormWatch, Control } from 'react-hook-form';
import SimpleCheckBox from 'components/atoms/SimpleCheckBox';
import TextInput from 'components/atoms/TextInput';
import { LearningPathLevelsThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { filterFunction } from 'helpers/selective-filtering';

interface AdminUploadImageInfoBadgeProps {
  watch: UseFormWatch<IUploadNewBadgeTemplate>;
  control: Control<IUploadNewBadgeTemplate>;
  errors: Record<string, any>;
  onSelectType: (type: number) => void;
  onSelectLevel: (type: number) => void;
  onSelectDuration: (type: number) => void;
  onSelectCost: (type: number) => void;
  badgeCloned: any;
}

const AdminUploadImageBadgeTemplate = ({
  watch,
  control,
  errors,
  onSelectType,
  onSelectLevel,
  onSelectDuration,
  onSelectCost,
  badgeCloned,
}: AdminUploadImageInfoBadgeProps): JSX.Element => {
  const file = watch().image;

  const [badgeType, setType] = React.useState<number | null>(null);
  const [levelType, setLevelType] = React.useState<number | null>(null);
  const [durationType, setDurationType] = React.useState<number | null>(null);
  const [costType, setCostType] = React.useState<number | null>(null);
  const [selectedPicture, setSelectedPicture] = React.useState<any>()
  const [inputImage, setInputImage] = React.useState<any>()

  const admin = useAppSelector((state) => state.admin);

  const dispatch = useAppDispatch();

  const cost = [
    { label: 'Pago', value: 1 },
    { label: 'Gratis', value: 2 },
  ];

  const type = [
    { label: 'Aprendizaje', value: 1 },
    { label: 'Experiencia', value: 2 },
    { label: 'Validación', value: 3 },
    { label: 'Certificación', value: 4 },
  ];

  const duration = [
    { label: 'Años', value: 1 },
    { label: 'Días', value: 2 },
    { label: 'Meses', value: 3 },
    { label: 'Horas', value: 4 },
    { label: 'Semanas', value: 5 },
  ];

  const getLevels = React.useCallback(async () => {
    const levels = await dispatch(LearningPathLevelsThunk());
    if (LearningPathLevelsThunk.rejected.match(levels)) {
      toast.error(levels.payload as string);
    }
  }, [dispatch]);

  
  useEffect(() => {
    if (file) {
      return setSelectedPicture(URL.createObjectURL(file));
    } 
    if(inputImage){
      return setSelectedPicture(URL.createObjectURL(inputImage));
    }
    if(badgeCloned && file === undefined){
      return setSelectedPicture(badgeCloned.image.url);
    }
    
    return setSelectedPicture(`${S3_ADMIN}tempalte_badge.png`);
    
  }, [file, badgeCloned, inputImage]);

  const setBadgeType = (type: number) => {
    setType(type);
    onSelectType(type);
  };

  const setBadgeLevelType = (level: number) => {
    setLevelType(level);
    onSelectLevel(level);
  };

  const setBadgeDurationType = (duration: number) => {
    setDurationType(duration);
    onSelectDuration(duration);
  };

  const setBadgeCostType = (cost: number) => {
    setCostType(cost);
    onSelectCost(cost);
  };
  
  useEffect(() => {
    if (badgeCloned) {
      let typeId = filterFunction(badgeCloned.type_category, type)
      setBadgeType(typeId)

      let levelId = filterFunction(badgeCloned.level, admin.levelPath)
      setBadgeLevelType(levelId)
      
      let durationId = filterFunction(badgeCloned.duration_type, duration)
      setBadgeDurationType(durationId)
      
      let costId = filterFunction(badgeCloned.type_cost, cost)
      setBadgeCostType(costId)
    }
    // eslint-disable-next-line
  }, [badgeCloned]);

  React.useEffect(() => {
    getLevels();
  }, [getLevels]);

  return (
    <BadgesUploadTypeWrapper>
      <UploadSelectedType>Nombre de la Insignia</UploadSelectedType>
      <BadgeCoverIconContainer>
        <BadgeImageWrapper>
          <BadgeImage src={selectedPicture} alt="imagen-organizacion" />
        </BadgeImageWrapper>
        <Controller
          control={control}
          render={({ field: { onChange } }) => (
            <ButtonWrapper>
              <InputFileLabel>
                <Input
                  name="photo"
                  onChange={async (value) => {
                    const files = value.target.files;
                    const fileList: File[] = Array.prototype.slice.call(files);
                    if (await ImageValidation(fileList)) {
                      setInputImage(fileList[0])
                      return onChange(fileList[0]);
                    } else {
                      toast.error(
                        'La imagen debe cumplir con los criterios de formato y dimensión'
                      );
                    }
                  }}
                  type="file"
                  accept="image/*"
                />
                <span>Cargar Insignia</span>
              </InputFileLabel>
              {errors['image']?.type && (
                <ErrorMessageLabel>{errors['image']?.message}</ErrorMessageLabel>
              )}
            </ButtonWrapper>
          )}
          name="image"
          rules={{
            required: badgeCloned?.image.url ? false : 'Necesita cargar una imagen',
          }}
        />
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>
            Imagen formato PNG/JPG con dimensión máxima de 1200x1200 pixeles
          </WarningImageText>
        </WarningContainer>
        <WarningContainer>
          <icons.CheckIcon />
          <WarningImageText>Esta imagen será la foto de portada de tu insignia</WarningImageText>
        </WarningContainer>
      </BadgeCoverIconContainer>
      <FormLabelSimpleWrapper>
        <FormLabelSimple for="labels">Información Adicional</FormLabelSimple>
      </FormLabelSimpleWrapper>
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Tipo</AdditionalInfoLabel>
        <RowStyled>
          {type &&
            type.map((type, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => {
                        badgeType === type.value ? setBadgeType(0) : setBadgeType(type.value);
                      }}
                      isChecked={badgeType === type.value}
                      onChange={() => setBadgeType(type.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="type_badge"
                />
                <RowLabel>{type.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Nivel</AdditionalInfoLabel>
        <RowStyled>
          {admin.levelPath &&
            admin.levelPath.map((level, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => {
                        levelType === level.value
                          ? setBadgeLevelType(0)
                          : setBadgeLevelType(level.value);
                      }}
                      isChecked={levelType === level.value}
                      onChange={() => setBadgeLevelType(level.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="level"
                />
                <RowLabel>{level.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Duración</AdditionalInfoLabel>
        <RowStyled>
          {duration &&
            duration.map((duration, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => {
                        durationType === duration.value
                          ? setBadgeDurationType(0)
                          : setBadgeDurationType(duration.value);
                      }}
                      isChecked={durationType === duration.value}
                      onChange={() => setBadgeDurationType(duration.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="duration_type"
                />
                <RowLabel>{duration.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      {durationType !== 0 && durationType !== null ? (
        <FormInputWrapper>
          <InputSelectContainer>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="duration"
                  placeholder="Indica el número"
                  value={value ? value : ''}
                  onChange={(value) => onChange(value)}
                  error={errors}
                  height={38}
                  withError
                  type="number"
                />
              )}
              name="duration"
              rules={{
                validate: {
                  checkHours: (v) => (v && v > 0) || 'La duracion debe ser mayor a cero',
                },
              }}
            />
          </InputSelectContainer>
        </FormInputWrapper>
      ) : (
        ''
      )}
      <AdditionalInfoWrapper>
        <AdditionalInfoLabel>Costo</AdditionalInfoLabel>
        <RowStyled>
          {cost &&
            cost.map((costItem, index) => (
              <ColStyled key={index}>
                <Controller
                  control={control}
                  render={() => (
                    <SimpleCheckBox
                      widthCustom={15}
                      handleCheckElement={() => {
                        costType === costItem.value
                          ? setBadgeCostType(0)
                          : setBadgeCostType(costItem.value);
                      }}
                      isChecked={costType === costItem.value}
                      onChange={() => setBadgeCostType(costItem.value)}
                      type={'checkbox'}
                    />
                  )}
                  name="type_cost"
                />
                <RowLabel>{costItem.label}</RowLabel>
              </ColStyled>
            ))}
        </RowStyled>
      </AdditionalInfoWrapper>
      {costType === 1 && (
        <FormInputWrapper>
          <InputSelectContainer>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  name="cost"
                  placeholder="Indica costo (Dólares Americanos)"
                  value={value ? value : ''}
                  onChange={(value) => onChange(value)}
                  error={errors}
                  height={38}
                  withError
                  type="number"
                />
              )}
              name="cost"
              rules={{
                validate: {
                  checkHours: (v) => (v && v > 0) || 'El costo debe ser mayor a cero',
                },
              }}
            />
          </InputSelectContainer>
          <FormText>El valor de los cursos es en dólares Americanos.</FormText>
        </FormInputWrapper>
      )}
    </BadgesUploadTypeWrapper>
  );
};

export default AdminUploadImageBadgeTemplate;
