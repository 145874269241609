import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Label, Modal } from 'reactstrap';
import styled from 'styled-components';

export const ModalContainer = styled(Modal)`
  max-width: 900px;

  @media ${device.tablet} {
    max-width: 500px;
  }
  @media ${device.bigMobile} {
    max-width: 400px;
    margin: 1.75rem auto;
    top: 10%;
  }
  @media ${device.mediumMobile} {
    max-width: 300px;
  }
  @media ${device.smallMobile} {
    max-width: 250px;
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  align-items: center;
  font-family: NotoSansJPBold, sans-serif;
  font-size: 50px;
  padding: 5%;
`;

export const ModalIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalText = styled(Label)`
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
`;
export const ModalTextFooter = styled(Label)`
  font-family: NotoSansJPBold, sans-serif;
  font-size: 25px;
  text-align: center;
`;
