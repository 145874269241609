import * as React from 'react';
import TextInputMax from 'components/atoms/TextInputMax';
import Button from 'components/atoms/Button';
import {
  PathInfoContainer,
  WarningText,
  FormGroupStyled,
  FormLabelSimpleWrapper,
  FormInputWrapper,
  FormLabelSimple,
  ButtonsContainer,
  ButtonWrapper,
} from './styles';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SelectedCourseListThunk } from 'actions/Thunks/AdminThunk';
import toast from 'react-hot-toast';
import { Control, UseFormHandleSubmit, Controller } from 'react-hook-form';
import { ListBadgesTemplateThunk } from 'actions/Thunks/AdminPathsThunk';

interface LearningPathDetailsProps {
  controlInfo: Control<IUpdatePath>;
  handleSubmitForm: UseFormHandleSubmit<IUpdatePath>;
  submitNewPathForm: (
    formInfo: IUpdatePath,
    skills: IListNewLearningExperienceSkillString[]
  ) => void;
  errorsInfo: Record<string, any>;
  onSelectVisibility: (is_private: boolean) => void;
}

const LearningPathInfo = ({ controlInfo, errorsInfo }: LearningPathDetailsProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const coursesSelector = useAppSelector((state) => state.courses);

  const initListCourseSelected = React.useCallback(async () => {
    if (coursesSelector.pathwayCreated) {
      const courseSelectedList = await dispatch(
        SelectedCourseListThunk(coursesSelector.pathwayCreated.id)
      );
      if (SelectedCourseListThunk.rejected.match(courseSelectedList)) {
        toast.error(courseSelectedList.payload as string);
      }
    }
  }, [coursesSelector.pathwayCreated, dispatch]);

  const initListSkill = React.useCallback(async () => {
    const badgesListTemplate = await dispatch(ListBadgesTemplateThunk());
    if (ListBadgesTemplateThunk.rejected.match(badgesListTemplate)) {
      toast.error(badgesListTemplate.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    initListCourseSelected();
    initListSkill();
  }, [initListCourseSelected, initListSkill]);

  return (
    <PathInfoContainer>
      <WarningText>Tus Datos Personales</WarningText>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Nombre</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Apellidos</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">País</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Ciudad</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Correo Adicional</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <FormGroupStyled row>
        <FormLabelSimpleWrapper>
          <FormLabelSimple for="name">Acreditta URL</FormLabelSimple>
        </FormLabelSimpleWrapper>
        <FormInputWrapper>
          <Controller
            control={controlInfo}
            render={({ field: { onChange, value } }) => (
              <TextInputMax
                name="name"
                placeholder="Nombre de la ruta"
                value={value}
                onChange={(value) => onChange(value)}
                error={errorsInfo}
                height={38}
                withError
              />
            )}
            name="name"
            rules={{
              required: 'Campo requerido',
            }}
          />
        </FormInputWrapper>
      </FormGroupStyled>
      <ButtonsContainer>
        <ButtonWrapper>
          <Button label={'Cancelar'} disabled={false} lightMode={true} widthCustom={150} />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button label={'Guardar'} disabled={false} widthCustom={150} />
        </ButtonWrapper>
      </ButtonsContainer>
    </PathInfoContainer>
  );
};

export default LearningPathInfo;
