import { DownloadBadgeImageCertThunk } from 'actions/Thunks/AdminThunk';
import { useAppDispatch } from 'hooks/redux';
import toast from 'react-hot-toast';
import Icons from 'assets/Icon/index';

interface ShareBadgeImageProps {
  badgeId: string;
  className?: string;
  setLoading?: any
}

const ShareBadgeImageButton = ({ badgeId, className, setLoading }: ShareBadgeImageProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const getImage = async () => {
    setLoading(true)
    const badgeImage = await dispatch(DownloadBadgeImageCertThunk(badgeId));
    if (DownloadBadgeImageCertThunk.fulfilled.match(badgeImage)) {
      setLoading(false)
      toast.success('Se ha descargado la imagen con éxito');
      var a = document.createElement('a');
      a.href = 'data:image/png;base64,' + badgeImage.payload.data.image;
      a.download = badgeImage.payload.data.filename.toString();
      a.click();
    } else {
      setLoading(false)
      toast.error('No se ha podido descargar la imagen');
    }
  };

  return (
    <button onClick={getImage}>
      <div className={className}>
        <Icons.DownloadBlueIcon />
      </div>
      <span>Descargar</span>
    </button>
  );
};

export default ShareBadgeImageButton;
