import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';
import { Card, CardImg, Label } from 'reactstrap';
import styled from 'styled-components';

export const MassiveBadgesCard = styled(Card)`
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.25);
  border-radius: 8px;
  padding: 0 37px;
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  justify-content: center;
  margin: 40px;
  padding: 40px;
  @media ${device.mobile} {
    padding: 0;
  }
`;

export const MassiveBadgesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  margin: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40%;
  }

  @media ${device.tablet} {
    width: 40%;
  }

  @media ${device.mobile} {
    width: 60%;
  }

  @media ${device.smallMobile} {
    width: 70%;
  } ;
`;

export const MassiveBadgesCardImage = styled(CardImg)`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const LabelText = styled(Label)``;

export const UploadFileWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 22px;
`;

export const LabelTitle = styled(Label)`
  color: ${PRIMARY_COLOR};
  font-family: NotoSansJPBold;
  font-size: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin-right: 10px !important;
  }
`;

export const LabelTitleMessage = styled(Label)`
  font-family: NotoSansJPBold;
  color: ${PRIMARY_COLOR};
`;
