import * as React from 'react';
import AdminDnDCourseCardPathway from '../AdminDnDCourseCardPathway';
import { DropZoneRow } from '../styles';
import { useDrop } from 'react-dnd';
import { DragItemTypes } from '../../../../constants';
import { useAppDispatch } from '../../../../hooks/redux';
import {
  addDragAndDropPathwayElement,
  insertFromDragAndDrop,
  pathwayResultCounter,
  removeDragAndDropPathwayElement,
  updateFromDragAndDrop,
} from '../../../../states/slices/course.slice';

interface DropZonePathProps {
  pathOrder: number;
}

const DropZonePath = ({ pathOrder }: DropZonePathProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [dragCourseElement, setDragCourseElement] = React.useState<ISelectedCourseList[]>([]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: DragItemTypes.COURSE,
      drop(item: ISelectedCourseList, monitor) {
        const finalDraggedCourse = [...dragCourseElement, item];

        dispatch(updateFromDragAndDrop(item.name));
        setDragCourseElement(finalDraggedCourse);
        dispatch(
          addDragAndDropPathwayElement({
            index: pathOrder,
            pathElement: finalDraggedCourse,
          })
        );
        dispatch(pathwayResultCounter());

        return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [dragCourseElement]
  );

  const removeDragCard = (cardSelected: ISelectedCourseList) => {
    let newArray = dragCourseElement.filter((item) => {
      return item.name !== cardSelected.name;
    });

    dispatch(insertFromDragAndDrop(cardSelected));
    dispatch(
      removeDragAndDropPathwayElement({
        index: pathOrder,
        courseTitle: cardSelected.name,
      })
    );
    dispatch(pathwayResultCounter());

    setDragCourseElement(newArray);
  };

  const isActiveDropzone = React.useMemo(() => {
    return canDrop && isOver;
  }, [canDrop, isOver]);

  return (
    <DropZoneRow ref={drop} $isActive={isActiveDropzone}>
      {dragCourseElement && (
        <React.Fragment>
          {dragCourseElement.map((course) => (
            <AdminDnDCourseCardPathway
              key={course.name}
              title={course.name}
              duration={course.duration}
              provider={course.provider_name}
              removeCardDragged={() => removeDragCard(course)}
            />
          ))}
        </React.Fragment>
      )}
    </DropZoneRow>
  );
};

export default DropZonePath;
