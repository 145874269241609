import styled from 'styled-components';
import { device } from 'constants/responsiveDevice';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';

export const ExperiencesContainer = styled.div`
  border: 1px solid #53565a;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0px 20px;
  margin: 40px 0px;
`;

export const ExperienceInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 20% 20% 20%;
  align-items: center;
  margin: 15px 0px;
  @media ${device.mediumLaptop} {
    grid-template-columns: 50% 10% 40%;
  }
`;

export const ExperienceNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ExperienceName = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
  margin-bottom: 0px;
  margin-left: 10px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #53565a;
  margin: 0px 10px 2px 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    line-height: 22px;
    color: #53565a;
    margin-left: 5px;
  }
`;
