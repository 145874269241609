import styled from 'styled-components';
import { Label } from 'reactstrap';

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 240px;
  width: 190px;
  background: #ffffff;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
`;

export const BadgeContainerClose = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 240px;
  width: 190px;
  background: rgba(83, 86, 90, 0.2);
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  border-radius: 8px;
`;

export const BadgeTitle = styled(Label)`
  font-style: normal;
  font-size: 16px;
  text-align: center;
  color: #53565a;
  margin: 8px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
`;

export const BadgeCompany = styled.label`
  font-size: 20px;
  color: #53565a;
  font-weight: bold;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
`;

export const ExpiredBadgeCompany = styled.label`
  font-size: 20px;
  color: #002057;
  font-weight: bold;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 170px;
  text-align: center;
`;

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const InfoIconWrapper = styled.div`
  text-align: center;
`;

export const ImageWrapper = styled.div`
  img {
    width: 110px;
    height: 110px;
    cursor: pointer;
  }
`;

export const AceptedBadge = styled.div`
  margin-top: 5px;
`;

export const AceptedBadgeLabel = styled.label`
  font-size: 10px;
  color: #02b148;
  cursor: pointer;
`;

export const ExpiredBadgeLabel = styled.label`
  font-size: 10px;
  color: #da291c;
  cursor: pointer;
`;
