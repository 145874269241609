import styled from 'styled-components';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { Label } from 'reactstrap';

export const CourseContentTitle = styled(Label)`
  margin-top: 60px;
  width: 100%;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  text-align: center;
  color: ${PRIMARY_COLOR};
`;

export const RouteDescription = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  width: 50%;
`;

export const RecomendedCoursesWrappedSection = styled.div`
  background: #f7f7f7;
`;

export const CourseSessionTime = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 14px;
  line-height: 22px;
  color: #53565a;
`;
export const RecomendedCoursesCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CourseItemTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_COLOR};
`;
export const CourseItemContainer = styled.div`
  padding: 0% 5%;
`;
export const CourseItemContainerGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RecomendedCoursesSectionTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  margin: 40px;
`;

export const AdminCoverSection = styled.div`
  height: 500px;
  img {
    width: 100%;
    background-position: center;
    background-size: cover;
  }
`;

export const RouteInfoWrapper = styled.div`
  position: relative;
  top: -400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const RouteTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
`;

export const RouteSubTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
`;

export const RouteShareTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: #ffffff;
  margin: 10px;
`;

export const ButtonWrapper = styled.div`
  margin: 40px 0px;
`;

export const RouteShareSectionWrapper = styled.div`
  color: white;
  svg {
    cursor: pointer;
  }
`;
