import styled from 'styled-components';
import { Label } from 'reactstrap';
import { PRIMARY_COLOR } from 'assets/styles/colors';
import { device } from 'constants/responsiveDevice';

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
`;

export const PersonalInfoContainer = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: space-between;
`;

export const ToolsContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 25px;
    margin: 0px 10px;
    cursor: pointer;
  }
  @media ${device.mobile} {
    svg {
      width: 20px;
      margin: 0px 5px;
    }
  }
`;
export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubTitleLabel = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 30px;
  line-height: 41px;
  color: ${PRIMARY_COLOR};
  @media ${device.mobile} {
    font-size: 17px;
    line-height: 20px;
  }
`;

export const GeneralProgressInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const GeneralProgressInfoImg = styled.div``;
export const GeneralProgressInfoData = styled.div`
  border-bottom: 1px solid ${PRIMARY_COLOR};
  width: 87%;
  display: flex;
  justify-content: space-between;
`;

export const GeneralProgressInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 20px;
`;
export const GeneralProgressInfoContainerInfo = styled.div`
  display: flex;
  flex-direction: rrow;
`;

export const InfoName = styled(Label)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  width: 50%;
  margin: 0px 8px;
  color: ${PRIMARY_COLOR};
  @media ${device.bigMobile} {
    font-size: 14px;
    line-height: 16px;
  }
  @media ${device.mobile} {
    font-size: 9px;
    line-height: 11px;
  }
`;

export const InfoNumber = styled(Label)`
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0px;
  color: ${PRIMARY_COLOR};
  @media ${device.bigMobile} {
    font-size: 25px;
  }
  @media ${device.mobile} {
    font-size: 19px;
  }
`;

export const ProfileInfoImage = styled.img`
  width: 8.875em;
  height: 8.875em;
  object-fit: cover;
  border-radius: 100%;
`;

export const ProfileRoleLabel = styled(Label)`
  font-size: 1em;
  margin: 0;
`;

export const ProfileEmailLabel = styled(Label)`
  font-size: 0.75em;
  margin: 0;
  margin-top: 2px;
`;

export const ProfileOrganizationLogo = styled.img`
  height: 4.125em;
`;

export const BoxRRSS = styled.div`
  display: flex;
  aling-items: center;
`;
