import * as React from 'react';
import {
  CardContainer,
  CardImage,
  CardTitleContainer,
  CardTitleStyled,
  CheckIcon,
  CheckIconWrapper,
} from './styles';

export interface InterestCardProps {
  title: string;
  onSelect: () => void;
  selected: boolean;
  image: string;
}

const InterestCard: React.FC<InterestCardProps> = ({ onSelect, selected, title, image }) => {
  return (
    <CardContainer onClick={onSelect} selected={selected}>
      {selected && (
        <CheckIconWrapper>
          <CheckIcon />
        </CheckIconWrapper>
      )}
      <CardImage src={image} alt="Card image cap" />
      <CardTitleContainer>
        <CardTitleStyled tag="h5">{title}</CardTitleStyled>
      </CardTitleContainer>
    </CardContainer>
  );
};

export default InterestCard;
