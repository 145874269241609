import styled from 'styled-components';
import { Label, NavLink } from 'reactstrap';

export const FooterContactUsContainer = styled.div`
  height: 100%;
  align-self: flex-start;
`;

export const FooterContactUsTitle = styled(Label)`
  font-family: NotoSansJPBold;
  font-size: 16px;
`;

export const FooterContactUsNavLink = styled(NavLink)`
  font-size: 12px;
  padding: 0.5em 0;
`;
