import styled from 'styled-components';
import { responsive } from '../responsive';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AcredittaText from 'components/atoms/AcredittaText';
import CardCarouselItem from '../components/CardCarouselItem';
import CarouselButtons from '../components/CarouselButtons';
import { CardBadgeAddMore } from '../components';
import { BadgeResult } from 'interfaces/badge-list-response';
import { useState } from 'react';
import { useAppSelector } from 'hooks/redux';

const StyleDiv = styled.div`
  border-radius: 25px;
  box-shadow: 0px 10px 30px 5px rgba(138, 149, 158, 0.3);
  padding: 20px 30px;
`;

interface Props {
  data: BadgeResult[];
}

const DEFAULT_BG =
  'linear-gradient(90.8deg, #283C85 0.12%, #51386F 33.93%, #843451 67.23%, #AA303B 100%);';

const CardBadgeCarousel = ({ data }: Props) => {
  const { userInfo } = useAppSelector((state) => state.user);
  const [backgroundHeader] = useState(userInfo?.branding.theme.value || DEFAULT_BG);

  return (
    <StyleDiv style={{ background: backgroundHeader }}>
      <div className="row">
        <div className="col-12 col-md-3">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100%', marginBottom: 10 }}
          >
            <div>
              <AcredittaText variant="h5" color="white" align="center" lineHeight="1em">
                Insignias
              </AcredittaText>
              <AcredittaText variant="h5" color="white" align="center" lineHeight="1em">
                creadas
              </AcredittaText>
              <AcredittaText variant="h5" color="white" align="center" lineHeight="1em">
                recientemente
              </AcredittaText>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-9">
          <div style={{ padding: '0 15px' }}>
            <Carousel
              arrows={false}
              responsive={responsive}
              customButtonGroup={<CarouselButtons />}
              renderButtonGroupOutside={true}
            >
              {data.map((item: any, idx: number) => (
                <CardCarouselItem item={item} key={idx} />
              ))}
              <CardBadgeAddMore />
            </Carousel>
          </div>
        </div>
      </div>
    </StyleDiv>
  );
};

export default CardBadgeCarousel;
