import { Modal } from 'reactstrap';
import styles from './styles.module.css';
import { useEffect, useState } from 'react';
import { ENVIRONMENT_URL_BADGE } from 'utils';
import icons from '../../../assets/Icon/index';
import ShareAsPostButton from './ShareAsPostButton';
import { BadgeDetail } from 'interfaces/badge-detail';
import AcredittaText from 'components/atoms/AcredittaText';
import AcredittaLoader from 'components/atoms/AcredittaLoader';
import { getBadgeDetailsData } from 'actions/ApiClient/BadgesAction';
import ShareOnLinkedinProfileButton from './ShareOnLinkedinProfileButton';

interface Props {
  badgeId: string | undefined;
  isOpen: boolean;
  badgeName: string;
  image: string;
  onClose: () => void;
}

const ModalSharedOnLinkedin = ({ badgeId, isOpen, badgeName, image, onClose }: Props) => {
  const [badge, setBadge] = useState({} as BadgeDetail);
  const publicUrl = `${ENVIRONMENT_URL_BADGE}/insignias/public`;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (badgeId) {
        try {
          setLoading(true);
          const resp = await getBadgeDetailsData(badgeId);
          const { data } = resp;
          setBadge(data.data);
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }
    })();
  }, [badgeId]);

  return (
    <Modal isOpen={isOpen} centered size="lg" toogle={isOpen}>
      <div className={styles.root}>
        <div className={styles.btnCloseContainer}>
          <span onClick={onClose} className="cursor-pointer">
            <AcredittaText variant="h3" color="secondary">
              <icons.CleanSearchIcon />
            </AcredittaText>
          </span>
        </div>

        <AcredittaText variant="h2" color="primary" align="center">
          Compartir en Linkedin
        </AcredittaText>

        <div>
          <img src={image} alt="" className={styles.image} />
        </div>

        <div className="mt-5 mb-4">
          <AcredittaText variant="body1" color="secondary" align="center">
            Selecciona cómo quieres compartir tu insignia&nbsp;
            <span className={styles.badgeName}>{badgeName}</span>
            <span className="ml-2">en Linkedin</span>
          </AcredittaText>
        </div>

        {loading && <AcredittaLoader width={22} height={22} isTextDisabled={true} />}

        {!loading && (
          <div className="row">
            <div className="col-6 pr-0">
              <ShareAsPostButton badgeId={badge.id} uri={publicUrl} />
            </div>
            <div className="col-6 pl-0">
              <ShareOnLinkedinProfileButton badge={badge} uri={publicUrl} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalSharedOnLinkedin;
