import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AccreditedRole,
  AdminRole,
  AuthNavigations,
  NonAuthenticatedUrls,
} from 'constants/NavigationUrls';
import { AWSInitialSetup } from 'utils/CognitoConfigure/CognitoInitialSetup';
import useHotjar from 'react-use-hotjar';
import { useAppSelector } from 'hooks/redux';
import { SentryConfigure } from 'utils/SentryConfigure/SentryConfigure';
import NotFound from 'pages/NotFound';
import { useUserData } from 'hooks/useUserData';
import AdminNavigation from 'navigation/Admin';
import AccreditedNavigation from 'navigation/Accredited';
import { PrivateRoute, PublicRoute } from 'navigation/Router';
import AuthNavigation from 'navigation/Auth';
import useGoogleAnalytics from 'hooks/useGoogleAnalitycs';
import { RoleDefinition } from 'constants/index';
import Unauthorized from 'pages/Auth/Unauthorized';
import { RouteRoleGuard } from 'utils/Routing/RoleGuard';
import BadgeDetails from 'pages/Badges/Unauthorized/BadgeDetail';
import EndPath from 'pages/Badges/Unauthorized/EndPath';
import LearningPathPreviewUnauth from 'pages/Paths/Unauthorized/LearningPathPreview';
import './theme/global.scss';
/** theme for datatables */
import 'theme/datatable-theme';
import 'theme/datatable.css';
import TagManager from 'react-gtm-module';
import AcredittaFullLoader from './components/atoms/AcredittaFullLoader/index';

const App = (): JSX.Element => {
  const { initHotjar } = useHotjar();
  const { userData } = useUserData();

  const user = useAppSelector((state) => state.user);
  const auth = useAppSelector((state) => state.auth);
  const admin = useAppSelector((state) => state.admin);

  useGoogleAnalytics();

  React.useEffect(() => {
    if (user) {
      TagManager.dataLayer({
        dataLayer: {
          user_id: user?.userInfo?.id,
          rol_type: user?.userInfo?.user_type.code,
          org_id: user?.userInfo?.organization?.id,
          org_sector: user?.adminInfo?.sector,
        },
        dataLayerName: 'AcredittaFrontedPage',
      });
    }
  }, [user]);

  const checkRoleNavigation = React.useMemo((): JSX.Element | undefined => {
    if (user.userInfo && user.userRole && user.userInfo.user_type !== undefined) {
      if (user.userInfo.user_type.code === 'ACCREDITED') {
        return (
          <PrivateRoute
            path={Object.values(AccreditedRole)}
            component={AccreditedNavigation}
            requiredRoles={[RoleDefinition.Accredited]}
            userRole={user.userRole}
          />
        );
      } else if (user.userInfo.user_type.code === 'ADMIN') {
        return (
          <PrivateRoute
            path={Object.values(AdminRole)}
            component={AdminNavigation}
            requiredRoles={[RoleDefinition.Admin]}
            userRole={user.userRole}
          />
        );
      } else if (user.userInfo.user_type.code === 'LEAD') {
        // TODO: change with lead flow
        return (
          <PrivateRoute
            path={Object.values(AccreditedRole)}
            component={AccreditedNavigation}
            requiredRoles={[RoleDefinition.Accredited]}
            userRole={user.userRole}
          />
        );
      }
    } else {
      userData();
    }
  }, [user.userInfo, user.userRole, userData]);

  const DefaultRedirectRoute = React.useMemo(() => {
    if (auth.isAuthenticated) {
      if (user.userInfo) {
        if (admin.statusEndPath !== undefined) {
          return RouteRoleGuard(user.userInfo, admin.statusEndPath);
        }
      }
    } else {
      return AuthNavigations.login;
    }
  }, [auth.isAuthenticated, user.userInfo, admin.statusEndPath]);

  // initial user information
  React.useEffect(() => {
    userData();
  }, [userData]);

  // initial configuration setups
  React.useEffect(() => {
    AWSInitialSetup();
    SentryConfigure();
    initHotjar(2578261, 6, false);
  }, [initHotjar]);

  if (!DefaultRedirectRoute) {
    return <AcredittaFullLoader />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path={`${NonAuthenticatedUrls.badges}/:uuid`} component={BadgeDetails} />
        <Route
          path={`${NonAuthenticatedUrls.path}/:pathwayId`}
          component={LearningPathPreviewUnauth}
        />
        <Route path={`${NonAuthenticatedUrls.end_path}/:uuid/:status`} component={EndPath} />
        <Route
          exact={true}
          path="/"
          render={() => (
            <Redirect
              to={DefaultRedirectRoute ? DefaultRedirectRoute : AuthNavigations.unauthorized}
            />
          )}
        />
        <Route path={AuthNavigations.unauthorized} component={Unauthorized} />
        <PublicRoute
          path={[...Object.values(AuthNavigations), ...Object.values(NonAuthenticatedUrls)]}
          component={AuthNavigation}
        />
        {checkRoleNavigation}
        <Route path="*" component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default App;
