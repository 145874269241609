import styled from 'styled-components';
import { PRIMARY_COLOR, BLUE_LIGHT } from 'assets/styles/colors';
import { Label } from 'reactstrap';
import { device } from 'constants/responsiveDevice';

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const StepTitle = styled(Label)`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-family: NotoSansJPBold;
  font-size: 50px;
  line-height: 68px;
  color: ${PRIMARY_COLOR};
  padding-top: 40px;
  @media ${device.ipad} {
    font-size: 40px;
    line-height: 51px;
  }
  @media ${device.mobile} {
    font-size: 30px;
    line-height: 41px;
  }
`;

export const StepSubtitle = styled.h2`
  width: 100%;
  font-size: 16px;
  line-height: normal;
  text-align: center;
  color: ${BLUE_LIGHT};
  padding: 20px 0px;

  @media ${device.mobile} {
    font-size: 12px;
    line-height: 15px;
  }
`;
