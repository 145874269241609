import * as React from 'react';
import CoursesCardsPathwayDescription from 'components/molecules/CoursesCardPathwayDescription';
import icons from 'assets/Icon';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { RecommendedCourseThunk } from 'actions/Thunks/AdminCatalogThunk';
import { toast } from 'react-hot-toast';
import {
  RecomendedCoursesLabel,
  RecomendedCoursesContainer,
  AssignRouteContainer,
  AssignHeader,
  AssignHeaderLabel,
  AssignUsersTypesContainer,
  AssignUsersContainer,
  AssignUser,
  SelectionContainer,
  ButtonWrapper,
  SelectedUsersContainer,
} from './styles';
import Button from 'components/atoms/Button';
import Badge from 'components/atoms/Badge';
import InputSelect from 'components/atoms/InputSelect';
import { arrayObjectIndexOf, ENVIRONMENT_URL_BADGE } from 'utils';
import SkillButton from 'components/atoms/SkillButton';
import { useHistory } from 'react-router';
import {
  AddBagdePathsThunk,
  DetailBadgesThunk,
  ListPathThunk,
} from 'actions/Thunks/AdminPathsThunk';
import { NonAuthenticatedUrls } from 'constants/NavigationUrls';

interface AssignRoutesAssignRoutesProps {
  routeID: string;
}

const AssignRoutesAssignRoutes = ({ routeID }: AssignRoutesAssignRoutesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [selected, setSelected] = React.useState<string>('Ruta creada');
  const [initialOptionsPaths, setOptionsPaths] = React.useState<any[] | null>(null);
  const [usersSelected, setUsers] = React.useState<ISelectOptionValue[]>([]);
  const [urlBadge, setUrlBadge] = React.useState('');

  const courses = useAppSelector((state) => state.catalogCourses);
  const detail = useAppSelector((state) => state.detailBadges);

  const getDetailBadges = React.useCallback(async () => {
    const badgesDetail = await dispatch(DetailBadgesThunk(routeID));
    if (DetailBadgesThunk.rejected.match(badgesDetail)) {
      toast.error(badgesDetail.payload as string);
    } else {
      setUrlBadge(`${ENVIRONMENT_URL_BADGE}${NonAuthenticatedUrls.url_badges}/`);
    }
  }, [routeID, dispatch]);

  const getListPaths = React.useCallback(async () => {
    const pathsList = await dispatch(ListPathThunk());
    if (ListPathThunk.rejected.match(pathsList)) {
      toast.error(pathsList.payload as string);
    } else {
      let paths_array: ISelectOptionValue[] = [];
      pathsList.payload.map((path: any) => {
        let newFormat_user = {
          value: path.id,
          label: path.name,
        };
        return (paths_array = [...paths_array, newFormat_user]);
      });
      setOptionsPaths(paths_array);
    }
  }, [dispatch]);

  const getCoursesRecommendedData = React.useCallback(async () => {
    const recomended = await dispatch(RecommendedCourseThunk(0));
    if (RecommendedCourseThunk.rejected.match(recomended)) {
      toast.error(recomended.payload as string);
    }
  }, [dispatch]);

  const setUsersSelected = (user: ISelectOptionValue) => {
    if (usersSelected) {
      let userHelper = usersSelected;
      const verifiedUser = userHelper.find((userFiltered) => userFiltered.label === user.label);
      if (!verifiedUser) {
        userHelper = [...userHelper, user];
        setUsers(userHelper);
      }
    }
  };

  const deleteUserSelected = (user: ISelectOptionValue) => {
    if (usersSelected) {
      let userHelper = usersSelected;
      const index = arrayObjectIndexOf(userHelper, user.label, 'label');
      if (index > -1) {
        setUsers(userHelper.filter((userFilter) => userFilter.label !== user.label));
      }
    }
  };

  const onSubmitNewUsers = async () => {
    usersSelected.map((user) => {
      const addBadges: IAddBadgePathCreate = {
        addBadge: {
          badge_template: routeID,
          path: user.value,
        },
      };
      dispatch(AddBagdePathsThunk(addBadges.addBadge));
      toast.success('Insignia Asignada Correctamente');
      history.goBack();
      return 0;
    });
  };

  const setUploadSelection = (selection: string) => {
    setSelected(selection);
    setUsers([]);
  };

  React.useEffect(() => {
    getCoursesRecommendedData();
    getDetailBadges();
    getListPaths();
  }, [getCoursesRecommendedData, getDetailBadges, getListPaths]);

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={true}
      showMenuHeader={true}
      showCommon={true}
      showOnboarding={false}
    >
      <div>
        <AssignHeader>
          <icons.GoBackIcon onClick={history.goBack} />
          <AssignHeaderLabel>Vincular esta insignia a una ruta</AssignHeaderLabel>
        </AssignHeader>
        {detail && detail.detailBadges && detail.listPaths && (
          <AssignRouteContainer>
            <div
              style={{
                width: '30%',
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {detail.detailBadges.data.image_url !== null ? (
                <Badge
                  typeBadge={detail.detailBadges.data.accepted_at === null ? true : false}
                  isPublicBadge={detail.detailBadges.data.public}
                  id={detail.detailBadges.data.id}
                  name={detail.detailBadges.data.name}
                  image={detail.detailBadges.data.image_url}
                  company={detail.detailBadges.data.owner.name}
                  badge_organization={true}
                  date={detail.detailBadges.data.accepted_at}
                  dateExpired={detail.detailBadges.data.expires_at}
                  url={urlBadge}
                />
              ) : (
                <Badge
                  typeBadge={detail.detailBadges.data.accepted_at === null ? true : false}
                  isPublicBadge={detail.detailBadges.data.public}
                  id={detail.detailBadges.data.id}
                  name={detail.detailBadges.data.name}
                  image={detail.detailBadges.data.image.url}
                  company={detail.detailBadges.data.owner.name}
                  badge_organization={true}
                  date={detail.detailBadges.data.accepted_at}
                  dateExpired={detail.detailBadges.data.expires_at}
                  url={urlBadge}
                />
              )}
            </div>
            <AssignUsersContainer>
              <AssignUsersTypesContainer>
                <AssignUser
                  onClick={() => setUploadSelection('Ruta')}
                  $selected={selected === 'Ruta'}
                >
                  <icons.UserIcon />
                  <span>Ruta</span>
                </AssignUser>
              </AssignUsersTypesContainer>
              <SelectionContainer>
                <span>{selected}</span>
                {initialOptionsPaths && (
                  <InputSelect
                    onChange={(value) => setUsersSelected(value)}
                    value={{}}
                    options={initialOptionsPaths}
                    placeholder="Buscar..."
                    error={{}}
                    name="users"
                  />
                )}
              </SelectionContainer>
              <SelectedUsersContainer>
                {usersSelected &&
                  usersSelected.map((user, userIndex) => {
                    return (
                      <SkillButton
                        key={userIndex}
                        skill={user.label}
                        handleSkill={() => deleteUserSelected(user)}
                      />
                    );
                  })}
              </SelectedUsersContainer>
              <ButtonWrapper>
                <Button label="Agregar" widthCustom={150} onClick={onSubmitNewUsers} />
              </ButtonWrapper>
            </AssignUsersContainer>
          </AssignRouteContainer>
        )}
        {courses.recommendedCourseT && (
          <RecomendedCoursesContainer>
            <RecomendedCoursesLabel>Cursos Recomendados</RecomendedCoursesLabel>
            {courses.recommendedCourseT.results !== null &&
            courses.recommendedCourseT.results !== undefined ? (
              <CoursesCardsPathwayDescription
                courses={courses.recommendedCourseT.results.slice(0, 5)}
                allowSelection={false}
                section={false}
              />
            ) : (
              <h1>Loading...</h1>
            )}
          </RecomendedCoursesContainer>
        )}
      </div>
    </CommonPageLayout>
  );
};

export default AssignRoutesAssignRoutes;
