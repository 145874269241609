import Button from 'components/atoms/Button';
import * as React from 'react';
import SkillButton from 'components/atoms/SkillButton';
import {
  PageContainer,
  PageTitle,
  PageDescription,
  SkillContainer,
  SkillsListContainer,
  PageItemsContainer,
  SkillTitle,
  SkillsContainer,
  AddMoreSkills,
  AddMoreSkillsContainer,
  ButtonContainer,
  SkillsSelectedConatiner,
  TextInputWrapper,
} from './styles';
import icons from 'assets/Icon';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  CreateInterestOrganizationThunk,
  ListInterestOrganizationThunk,
} from 'actions/Thunks/AdminThunk';
import { toast } from 'react-hot-toast';
import { arrayObjectIndexOf } from 'utils';
import TextInput from 'components/atoms/TextInput';
import { StepWizardProps } from 'react-step-wizard';
import { AdminRole } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';

interface AdminOrganizationSkillsProps extends Partial<StepWizardProps> {
  onNextStep: () => void;
}

const AdminOrganizationSkills = ({ onNextStep }: AdminOrganizationSkillsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => state.admin);
  const user = useAppSelector((state) => state.user);
  const history = useHistory();

  const [skillsSelected, setSkills] = React.useState<ISelectOptionValue[]>([]);
  const [showAddSkillsInput, showSkillsInput] = React.useState<boolean>(false);
  const [newSkillsSelected, setNewSkillsSelected] = React.useState<string>('');
  const [newSkillsArraySelected, setNewSkillsArraySelected] = React.useState<string[]>([]);

  const handleNewSkillsInput = () => showSkillsInput(!showAddSkillsInput);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddSkill();
    }
  };

  const handleAddSkill = () => {
    let helper = newSkillsArraySelected;
    let newSkills = newSkillsSelected.split(',');
    newSkills.map((skill) => {
      return (helper = [...helper, skill]);
    });
    setNewSkillsArraySelected(helper);
    setNewSkillsSelected('');
  };

  const onChange = (event: React.ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setNewSkillsSelected(value);
  };

  const handleNewSkill = (skillName: string) => {
    setNewSkillsArraySelected(newSkillsArraySelected.filter((item) => item !== skillName));
  };

  const handleSkill = (skill: ISelectOptionValue) => {
    if (skillsSelected) {
      let skillHelper = skillsSelected;
      const verifiedSkill = skillHelper.find(
        (skillFiltered) => skillFiltered.label === skill.label
      );
      if (!verifiedSkill) {
        skillHelper = [...skillHelper, skill];
        setSkills(skillHelper);
      } else {
        const index = arrayObjectIndexOf(skillHelper, skill.label, 'label');
        if (index > -1) {
          setSkills(skillHelper.filter((skillFilter) => skillFilter.label !== skill.label));
        }
      }
    }
  };

  const handleSubmit = async () => {
    if (user.adminInfo) {
      if (skillsSelected.length > 0) {
        const interestsIds = skillsSelected.map((skill) => skill.value);
        const createOrganization = await dispatch(
          CreateInterestOrganizationThunk({
            organizationId: user.adminInfo.id,
            organization_interest: interestsIds,
            new_organization_interests: newSkillsArraySelected,
          })
        );
        if (CreateInterestOrganizationThunk.fulfilled.match(createOrganization)) {
          if (createOrganization.payload) {
            toast.success(createOrganization.payload);
            history.push(AdminRole.catalogCourse);
          }
        } else {
          toast.error(createOrganization.payload as string);
        }
      } else {
        toast.error('Debes seleccionar al menos un interes');
      }
    }
  };

  const getInterestOrganization = React.useCallback(async () => {
    const interests = await dispatch(ListInterestOrganizationThunk());
    if (ListInterestOrganizationThunk.rejected.match(interests)) {
      toast.error(interests.payload as string);
    }
  }, [dispatch]);

  React.useEffect(() => {
    getInterestOrganization();
  }, [getInterestOrganization]);

  return (
    <PageContainer>
      <PageItemsContainer>
        <PageTitle>Intereses de tu organización</PageTitle>
      </PageItemsContainer>
      <PageItemsContainer>
        <PageDescription>
          Selecciona al menos una categoría para recibir recomendaciones de cursos según las metas e
          intereses de tu organización
        </PageDescription>
      </PageItemsContainer>

      <SkillsContainer>
        <SkillsListContainer>
          {admin.interestOrganizationList &&
            admin.interestOrganizationList.map((skillName, indexSkill) => {
              return (
                <SkillContainer key={indexSkill}>
                  <SkillTitle>{skillName.label}</SkillTitle>
                  {skillsSelected.filter((skill) => skill.label === skillName.label).length > 0 ? (
                    <icons.DeleteIcon onClick={() => handleSkill(skillName)} />
                  ) : (
                    <icons.AddIcon onClick={() => handleSkill(skillName)} />
                  )}
                </SkillContainer>
              );
            })}
        </SkillsListContainer>
      </SkillsContainer>
      <AddMoreSkillsContainer>
        <AddMoreSkills onClick={handleNewSkillsInput}>Agregar Interés +</AddMoreSkills>
        {showAddSkillsInput && (
          <TextInputWrapper>
            <TextInput
              name="newSkills"
              error={{}}
              searchIcon={<icons.AddIcon onClick={handleAddSkill} />}
              height={42}
              onKeyDown={handleKeyDown}
              value={newSkillsSelected}
              onChange={onChange}
            />
          </TextInputWrapper>
        )}
      </AddMoreSkillsContainer>
      <SkillsSelectedConatiner>
        {skillsSelected &&
          skillsSelected.map((skill, skillIndex) => {
            return (
              <SkillButton
                key={skillIndex}
                skill={skill.label}
                handleSkill={() => handleSkill(skill)}
              />
            );
          })}
        {newSkillsArraySelected &&
          newSkillsArraySelected.map((newSkill, newSkillIndex) => {
            return (
              <SkillButton
                key={newSkillIndex}
                skill={newSkill}
                handleSkill={() => handleNewSkill(newSkill)}
              />
            );
          })}
      </SkillsSelectedConatiner>
      <ButtonContainer>
        <Button label="Siguiente" widthCustom={150} onClick={handleSubmit}></Button>
      </ButtonContainer>
    </PageContainer>
  );
};

export default AdminOrganizationSkills;
