import { AccountsUrls, AdminUrls, API_URL } from 'constants/ApiUrls';
import { HttpRequest } from './HttpRequest';
import * as Sentry from '@sentry/react';
import { GetErrorMessage } from 'utils';
import { logException } from 'utils/GA/analytics';

export async function GetUserData(): Promise<IUserInfo | undefined> {
  try {
    const user = await new HttpRequest().Get<IUserInfo>(`${API_URL}${AccountsUrls.userData}`);
    if (user.okay && user.data) {
      return user.data;
    } else {
      throw Error(user.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetAdminData(): Promise<IAdminInfo> {
  try {
    const admin = await new HttpRequest().Get<IAdminInfo>(
      `${API_URL}${AdminUrls.organizationList}`
    );

    if (admin.okay && admin.data) {
      return admin.data;
    } else {
      throw Error(admin.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function GetValidatePlanPaths(path: string): Promise<any> {
  try {
    const plan = await new HttpRequest().Get<any>(
      `${API_URL}${AdminUrls.validatePlan}?path=${path}`
    );

    if (plan.okay && plan.data) {
      return plan.data;
    } else {
      throw Error(plan.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}

export async function PatchArchivedBadge(data: IArchivedBadge): Promise<string> {
  try {
    const badge = await new HttpRequest().Patch(
      `${API_URL}${AdminUrls.archivedBadge}${data.uuid}/`,
      data.status
    );

    if (badge.okay) {
      return 'Se ha archivado correctamente';
    } else {
      throw Error(badge.message);
    }
  } catch (error) {
    Sentry.captureException(error);
    logException(GetErrorMessage(error), true);
    throw new Error(GetErrorMessage(error));
  }
}

export async function ChangeNumber(newNumber: any): Promise<string> {
  try {
    const changeNumber = await new HttpRequest().Patch(
      `${API_URL}${AccountsUrls.userPhoneNumber}${newNumber.uuid}/`,
      newNumber.new_number
    );
    if (changeNumber.okay) {
      const put = await new HttpRequest().Put(
        `${API_URL}${AdminUrls.validatePlan}1/`,
        newNumber.path
      );
      if (put.okay) {
        return 'Se han creado los intereses correctamente';
      } else {
        throw Error(put.message);
      }
    } else {
      throw Error(changeNumber.message);
    }
  } catch (error) {
    Sentry.captureException(error);

    logException(GetErrorMessage(error), true);

    throw new Error(GetErrorMessage(error));
  }
}
