import * as React from 'react';
import { CoursesListWrapper, CoursesListContainer } from './styles';
import CourseSessions from './CourseSessions';

interface AdminRouteCoursesListProps {
  coursesList?: IPathwayDetailContentCourse[];
  experiencesList?: IPathExperiences[];
}

const AdminRouteCoursesList = ({
  coursesList,
  experiencesList,
}: AdminRouteCoursesListProps): JSX.Element => {
  return (
    <CoursesListContainer>
      {coursesList && coursesList.length > 0 && (
        <CoursesListWrapper>
          {coursesList.map((course, indexCourse) => {
            return <CourseSessions course={course} indexElement={indexCourse} />;
          })}
        </CoursesListWrapper>
      )}
      {experiencesList && experiencesList.length > 0 && (
        <CoursesListWrapper>
          {experiencesList.map((experienceItem, indexExperience) => {
            return <CourseSessions experience={experienceItem} indexElement={indexExperience} />;
          })}
        </CoursesListWrapper>
      )}
    </CoursesListContainer>
  );
};

export default AdminRouteCoursesList;
