import { themeConfig } from 'theme/config';
import LabelTextDiv from './LabelTextDiv';

interface CsvLinkProps {
  label: boolean;
  onEnter: () => void;
  onLeave: () => void;
  position: 'left' | 'right';
}

const CsvLink = ({ label, onEnter, onLeave, position }: CsvLinkProps) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        display: 'inline',
        margin: '0 5px',
        cursor: 'pointer',
        color: themeConfig.colors.primary.blue,
        fontWeight: 'bold',
        textDecoration: 'underline',
      }}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      .csv
      <LabelTextDiv show={label} position={position} />
    </div>
  );
};

export default CsvLink;
