import * as React from 'react';
import icons from 'assets/Icon';
import Button from 'components/atoms/Button';
import { AdminRole } from 'constants/NavigationUrls';
import { useHistory } from 'react-router-dom';
import CommonPageLayout from '../Layout/CommonPageLayout';
import { ModalBodyStyled, ModalTitle, ModalText, ButtonWrapper } from './styles';

const EndPathAcept = (): JSX.Element => {
  const history = useHistory();

  const handleNavegation = () => {
    history.push(AdminRole.catalogCourse);
  };

  return (
    <CommonPageLayout
      showHeader={true}
      showFooter={false}
      childrenMargin={false}
      showMenuHeader={true}
      showMenuHeaderAcredited={false}
      showCommonMenu={false}
      showCommon={true}
      showOnboarding={false}
    >
      <ModalBodyStyled>
        <ModalTitle>¡La ruta ya fue aceptada!</ModalTitle>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '2%',
          }}
        >
          <ModalText>La ruta fue aceptada correctamente</ModalText>
          <ButtonWrapper>
            <Button
              label={'Ir al Incio'}
              widthCustom={300}
              onClick={() => {
                handleNavegation();
              }}
            />
          </ButtonWrapper>
          <icons.Imagen_ruta_ya_finalizada
            style={{
              width: '100%',
              marginRight: '20px',
            }}
          />
        </div>
      </ModalBodyStyled>
    </CommonPageLayout>
  );
};

export default EndPathAcept;
