import * as React from 'react';
import { ButtonProps } from 'reactstrap';
import { CustomButton, LabelContent, LoadingContainer } from './styles';
import ReactLoading from 'react-loading';

interface StyledButtonProps extends ButtonProps {
  loading?: boolean;
  label: string;
  disabled?: boolean;
  lightMode?: boolean;
  widthCustom?: number;
}

/**
 * Global styled button
 * @param widthCustom custom width on px - default 100%
 * @param lightMode button with white background and border highlight
 */
const StyledButton: React.FC<StyledButtonProps> = (props) => {
  const { label, loading, disabled, lightMode, widthCustom, height, ...rest } = props;

  return (
    <React.Fragment>
      <CustomButton
        color="primary"
        disabled={disabled}
        $lightMode={lightMode}
        $widthCustom={widthCustom}
        {...rest}
      >
        {loading ? (
          <LoadingContainer>
            <ReactLoading type="spin" color={'white'} height={20} width={20} />
          </LoadingContainer>
        ) : (
          <LoadingContainer>
            <LabelContent>{label}</LabelContent>
          </LoadingContainer>
        )}
      </CustomButton>
    </React.Fragment>
  );
};

export default StyledButton;
