import AcredittaText from 'components/atoms/AcredittaText';
import styles from '../admin-analytics.module.css';

interface TextChartProps {
  total: number;
  titleFontSize?: number;
  textFontSize?: number;
}

const TextCenterChart = ({ total, titleFontSize = 28, textFontSize = 13 }: TextChartProps) => {
  return (
    <>
      <AcredittaText
        variant="h5"
        className={styles['inner-title']}
        style={{ fontSize: titleFontSize }}
      >
        {total}
      </AcredittaText>
      <AcredittaText
        variant="body1"
        className={styles['inner-text']}
        style={{ fontSize: textFontSize }}
      >
        Insignias
      </AcredittaText>
      <AcredittaText
        variant="body1"
        className={styles['inner-text']}
        style={{ fontSize: textFontSize }}
      >
        Emitidas
      </AcredittaText>
    </>
  );
};

export default TextCenterChart;
