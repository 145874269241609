import icons from 'assets/Icon';
import styles from './styles.module.css';
import AcredittaText from 'components/atoms/AcredittaText';

interface Props {
  uri: string;
  badgeId: string;
}

const ShareAsPostButton = ({ uri, badgeId }: Props) => {
  const handleOptionShareAsPost = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${uri}/${badgeId}`, '_blank');
  };

  return (
    <div className={styles.btnWhite} onClick={handleOptionShareAsPost}>
      <AcredittaText variant="body1" align="center" style={{ color: '#0077b5' }} fontWeight="bold">
        <icons.IconLinkedinBlue />
        <span className="ml-3">Crear una publicación</span>
      </AcredittaText>
    </div>
  );
};

export default ShareAsPostButton;
